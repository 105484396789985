import React, { Component, useRef } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, TabPane, Form, TabContent, Progress, NavLink, NavItem, ModalHeader, ModalBody, Modal, Button, ModalFooter, CardText, CardTitle, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import ReactSpeedometer from "react-d3-speedometer"

import './../../assets/scss/custom/components/_editProperties.scss'
import './../../assets/scss/custom/components/_addProperty.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import { Draggable } from 'react-drag-reorder';
import CurrencyInput from '../../components/Inputs/CurrencyInput';
import Select from "react-select";
import Dropzone, { useDropzone } from 'react-dropzone';
import Flatpickr from "react-flatpickr"
import 'flatpickr/dist/l10n/pt';

import PrimeiroImovel from './../../assets/images/Icons/primeiroimovel.png'
import Triste from './../../assets/images/Icons/triste.png'
import VamosNessa from './../../assets/images/Icons/vamosnessa.png'
import Cafezinho from './../../assets/images/upload/cafezinho.png';
import Contrato from './../../assets/images/upload/contrato.png';
import Corretores from './../../assets/images/upload/corretores.png';
import Instante from './../../assets/images/upload/instante.png';
import Upload from './../../assets/images/upload/upload.png';
import Sucesso from './../../assets/images/upload/sucesso.png';
import { set } from 'lodash';
import { flexibleCompare } from '@fullcalendar/core';
import { ThirtyFpsSelect } from '@mui/icons-material';

require('moment/locale/pt.js');


class AddUnits extends Component {
  constructor(props) {
    super(props);
    this.validateRef = React.createRef();
    this.validate2Ref = React.createRef();
    this.validate3Ref = React.createRef();
    this.validate4Ref = React.createRef();
    this.state = {
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Novo imóvel", link: "#" },
    ],
    activeTab: 1,
    activeTabProgress: 1,
    progressValue: 20,
    selectedEnterprise: [],
    objective:'Sell',
    selectOptionsEnterprise: [],
    selectedFiles: [],
    selectedPlans: [],
    dragPhotos:'',
    dragPlans:'',
    title:'',
    areaPrivativa:'',
    areaTotal:'',
    descricao:'',
    rooms: '',
    suites:'',
    garage:'',
    bathrooms:'',
    photos:[],
    skillsList:'',
    skillsArray:[],
    checkInvalidFeedback:null,
    acceptContract:false,
    isComponentMounted: false,
    update_success:false,
    update_error:false,
    modal_full_properties:false,
    verifyPremium: false,
    uploadPercentage: 0,
    uploading: false,
    dots: '.',
    status:'',
    observacao:'',
    videos: [],
    pay_type: '',
    pay_type_value: '',
    pay_value: '',
    pay_parcels: '',
    pay_observation: '',
    modal_payment:false,
    update_payment_error: false,
    unit_code_edit:'',
    unit_price_edit:'',
    unit_status_edit:'',
    payment_items:[],
    units:[],
    payment_condition_edited:[],
    unitsInputs: [{ sku: '', floor: '', price: '', payment_condition: '', status: '' }],
    }
    this.toggleTab.bind(this);
    this.toggleTabProgress.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.tog_payment = this.tog_payment.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.handleSelectState = this.handleSelectState.bind(this);
    this.toggleFormsAutorization = this.toggleFormsAutorization.bind(this);
  }
  
  addVideoInput = () => {
    const newVideos = [...this.state.videos, ''];
    this.setState({ videos: newVideos });
  }

  updateVideoInput = (index, value) => {
    const updatedVideos = [...this.state.videos];
    updatedVideos[index] = value;
    this.setState({ videos: updatedVideos });
  }

  addInput = () => {
    const newInput = { sku: '', floor: '', price: '', payment_condition: '', status: '' };
    this.setState((prevState) => ({
      unitsInputs: [...prevState.unitsInputs, newInput],
    }));
  };
  
  updateInput = (index, field, value) => {
    this.setState((prevState) => {
      const updatedInputs = [...prevState.unitsInputs];
      updatedInputs[index][field] = value;
      return { unitsInputs: updatedInputs };
    });
  };

  handleInputChange = (e, index, field) => {
    const { name, value } = e.target || e;
    this.setState((prevState) => {
      const updatedUnits = [...prevState.payment_items];
      updatedUnits[index][field] = value;
      return { payment_items: updatedUnits };
    });
  };

  componentDidMount(){
    this.setState({ isComponentMounted: true });

    const id = window.location.search.replace('?id=', '')

    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

          Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-payment-conditions/' + id,
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            this.setState({payment_items: response.data})
          })

    })
    .catch(response =>{
      localStorage.removeItem("history_url")
      setTimeout(() => {
        window.open("/login", '_parent')
      }, 300);
    })
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }

  tog_payment() {
    this.setState(prevState => ({
      modal_payment: !prevState.modal_payment
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
        if (tab >= 1 && tab <= 7) {
            this.setState({
                activeTab: tab
            });
        }
    }
  }

  toggleTabProgress(tab) {
      if (this.state.activeTabProgress !== tab) {
          if (tab >= 1 && tab <= 5) {
              this.setState({
                  activeTabProgress: tab
              });

              if (tab === 1) { this.setState({ progressValue: 20 }) }
              if (tab === 2) { this.setState({ progressValue: 40 }) }
              if (tab === 3) { this.setState({ progressValue: 60 }) }
              if (tab === 4) { this.setState({ progressValue: 80 }) }
              if (tab === 5) { this.setState({ progressValue: 100 }) }
          }
      }
  }

  toggleFormsAutorization() {
    this.setState(prevState => ({
      openFormsAutorization: !prevState.openFormsAutorization
    }));
  }

  
  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedFiles: files }, () => {
      this.setState({photosArray: this.state.selectedFiles.map((file, index) => ({
        file: file,
        position: index
      }))});
    });
    
    this.setState({dragPhotos: (
      <Draggable onPosChange={() => {
        this.setState({photosArray : []})
        setTimeout(() => {
          for(var i = 0; i < this.state.selectedFiles.length; i++){
            this.state.photosArray.push({
              file: this.state.selectedFiles.filter(image => image.name === document.querySelectorAll('.image-send')[i].alt)[0],
              position: i
            })
          }
        }, 500);
        }}>
      {this.state.selectedFiles.map((f, i) => 
          <Col key={i + "-file"} id={f.name} >
          <Card
            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
          >
            <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="120"
                      className="avatar-sm rounded bg-light image-send"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link
                      to="#"
                      className="text-muted fw-bold"
                    >
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                  <i className="ri-close-fill" style={{
                      position:'absolute',
                      top:'10px',
                      fontSize:'20px',
                      backgroundColor:'#da2c3e',
                      color:'#fff',
                      right:'10px',
                      width: '15px',
                      height: '25px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }} onClick={() => {
                      this.removeImage(i)
                    }}></i>
                </Row>
            </div>
          </Card>
          </Col>
      )}
      </Draggable>
    )})
  };

  handleAcceptedPlans = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedPlans: files }, () => {
      this.setState({plansArray: this.state.selectedPlans.map((file, index) => ({
        file: file,
        position: index
      }))});
    });
    
    this.setState({dragPlans: (
      <Draggable onPosChange={() => {
        this.setState({plansArray : []})
        setTimeout(() => {
          for(var i = 0; i < this.state.selectedPlans.length; i++){
            this.state.plansArray.push({
              file: this.state.selectedPlans.filter(image => image.name === document.querySelectorAll('.image-plan')[i].alt)[0],
              position: i
            })
          }
        }, 500);
        }}>
      {this.state.selectedPlans.map((f, i) => 
          <Col key={i + "-file"} id={f.name} >
          <Card
            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
          >
            <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="120"
                      className="avatar-sm rounded bg-light image-plan"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link
                      to="#"
                      className="text-muted fw-bold"
                    >
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                  <i className="ri-close-fill" style={{
                      position:'absolute',
                      top:'10px',
                      fontSize:'20px',
                      backgroundColor:'#da2c3e',
                      color:'#fff',
                      right:'10px',
                      width: '15px',
                      height: '25px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }} onClick={() => {
                      this.removeImagePlan(i)
                    }}></i>
                </Row>
            </div>
          </Card>
          </Col>
      )}
      </Draggable>
    )})
  };

  removeImagePlan(index) {
    const { selectedPlans } = this.state;
    selectedPlans.splice(index, 1);
    this.setState({ selectedPlans });
    this.setState({dragPlans: null})
    setTimeout(() => {
      this.handleAcceptedPlans(this.state.selectedPlans);      
    }, 300);
  }
  


  removeImage(index) {
    const { selectedFiles } = this.state;
    selectedFiles.splice(index, 1);
    this.setState({ selectedFiles });
    this.setState({dragPhotos: null})
    setTimeout(() => {
      this.handleAcceptedFiles(this.state.selectedFiles);      
    }, 300);
  }
  
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };


  optionchange(event) {
		var count = event.target.value.length;
		if (count > 0) {
			this.setState({ disbadge: true });
		} else {
			this.setState({ disbadge: false });
		}
		if (count > 99) {
			this.setState({ advanceclass: "badgecountextra" });
		} else {
			this.setState({ advanceclass: "badgecount" });
		}
		this.setState({ optioncount: event.target.value.length });
	}

  handleSelectState = stateSelected => {
		this.setState({ stateSelected });
    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + stateSelected.value + '/municipios')
    .then(response => {
      const city = []
      for(var i = 0; response.data.length > i; i++){
        city.push({
          label: response.data[i].nome,
          value: response.data[i].nome
        })
      }
      this.setState({cityAddress: city})
    })
	};

  consultaCep = cepValue => {
    const cep = cepValue
      if (cep?.length !== 8) {
      this.setState({logradouro: ''});
      this.setState({bairro: ''});
      this.setState({stateSelected: ''})
      this.setState({citySelected: ''});
      }else{
        fetch('https://viacep.com.br/ws/' + cep + '/json/')
        .then((res) => res.json())
        .then((data) => {
            if (data.erro === true) {
              this.setState({logradouro: ''});
              this.setState({bairro: ''});
              this.setState({stateSelected: ''})
              this.setState({citySelected: ''});
            } else {
              this.setState({logradouro: data.logradouro});
              this.setState({bairro: data.bairro});
              this.setState({stateSelected: {
                value:data.uf,
                label:data.uf
              }})
              this.setState({citySelected: {
                value:data.localidade,
                label:data.localidade
              }});
            }
          })
      }  
  }


  createSlug(title) {
    let slug = title.toLowerCase().replace(/[^\w\s-]/g, '');
  
    slug = slug.replace(/\s+/g, '-');
  
    return slug;
  }

  render() {

    const { dragPhotos, dragPlans, citySelected, stateSelected, selectedEnterprise, selectOptionsEnterprise} = this.state;

    return (
        <div className="page-content" id="addImovel">
        <Container fluid={true}>
          <Breadcrumbs title="Novo imóvel" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Preencha o maior número de informações possível.</h4>

                  <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 1 })}
                            onClick={() => {
                                this.setState({ activeTabProgress: 1 })
                                this.toggleTabProgress(1)
                            }}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">Sobre o tipo</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 2 })}
                            onClick={() => {
                              if(this.state.title === '' 
                                || this.state.areaPrivativa === ''
                                || this.state.areaTotal === ''
                                || this.state.rooms === ''
                                || this.state.suites === ''
                                || this.state.bathrooms === ''
                                || this.state.garage === ''
                                || this.state.descricao === ''){
                                  this.validateRef.current.handleSubmit()
                                  this.setState({ activeTabProgress: 1 })
                                }else{
                                  this.setState({ activeTabProgress: 2 })
                                  this.toggleTabProgress(2)
                                }
                            }}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">Características</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 3 })}
                            onClick={() => {
                              if(this.state.title === '' 
                                || this.state.areaPrivativa === ''
                                || this.state.areaTotal === ''
                                || this.state.rooms === ''
                                || this.state.suites === ''
                                || this.state.bathrooms === ''
                                || this.state.garage === ''
                                || this.state.descricao === ''){
                                  this.validateRef.current.handleSubmit()
                                  this.setState({ activeTabProgress: 1 })
                                }else{
                                  this.setState({ activeTabProgress: 3 })
                                  this.toggleTabProgress(3)
                                }
                              }}
                            >
                            <span className="step-number">03</span>
                            <span className="step-title">Mídias</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 4 })}
                            onClick={() => {
                              if(this.state.title === '' 
                                || this.state.areaPrivativa === ''
                                || this.state.areaTotal === ''
                                || this.state.rooms === ''
                                || this.state.suites === ''
                                || this.state.bathrooms === ''
                                || this.state.garage === ''
                                || this.state.descricao === ''){
                                  this.validateRef.current.handleSubmit()
                                  this.setState({ activeTabProgress: 1 })
                                }else{
                                  this.setState({ activeTabProgress: 4 })
                                  this.toggleTabProgress(4)
                                }
                            }}
                          >
                            <span className="step-number">04</span>
                            <span className="step-title">Unidades</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 5 })}
                            onClick={() => {
                              if(this.state.title === '' 
                                || this.state.areaPrivativa === ''
                                || this.state.areaTotal === ''
                                || this.state.rooms === ''
                                || this.state.suites === ''
                                || this.state.bathrooms === ''
                                || this.state.garage === ''
                                || this.state.descricao === ''){
                                  this.validateRef.current.handleSubmit()
                                  this.setState({ activeTabProgress: 1 })
                                }else{
                                  this.setState({ activeTabProgress: 5 })
                                  this.toggleTabProgress(5)
                                }
                            }}
                          >
                            <span className="step-number">05</span>
                            <span className="step-title">Sucesso!</span>
                          </NavLink>
                        </NavItem>
                      </ul>


                      <div id="bar" className="mt-4">
                          <Progress color="success" striped animated value={this.state.progressValue} />
                      </div>

                      <div style={{marginBottom: '2rem'}}>
                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                            {this.state.activeTabProgress !== 1 &&
                              <li className={this.state.activeTabProgress === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTabProgress(this.state.activeTabProgress - 1); }}>Anterior</Link></li>
                            }
                            {this.state.activeTabProgress !== 6 &&
                              <li className={this.state.activeTabProgress === 6 ? "next disabled" : "next"}><Link to="#" onClick={() => { 
                                if (this.state.isComponentMounted) {
                                if(this.state.activeTabProgress === 1){
                                  if(localStorage.getItem('access') !== 'A'){
                                    if(this.state.title === '' 
                                    || this.state.areaPrivativa === ''
                                    || this.state.areaTotal === ''
                                    || this.state.rooms === ''
                                    || this.state.suites === ''
                                    || this.state.bathrooms === ''
                                    || this.state.garage === ''
                                    || this.state.descricao === ''){
                                      this.validateRef.current.handleSubmit()
                                    }else{
                                      window.scrollTo(0,0)
                                      this.setState({ activeTabProgress: 2 })
                                      this.toggleTabProgress(this.state.activeTabProgress + 1)
                                    }
                                  }else{
                                    this.setState({ activeTabProgress: 2 })
                                    this.toggleTabProgress(this.state.activeTabProgress + 1)
                                  }
                                }else if(this.state.activeTabProgress === 2){

                                    window.scrollTo(0,0)
                                    this.setState({ activeTabProgress: 3 })
                                    this.toggleTabProgress(this.state.activeTabProgress + 1)
                                }else if(this.state.activeTabProgress === 3){
                                  if (this.state.isComponentMounted) {
                                    window.scrollTo(0,0)
                                    this.setState({ activeTabProgress: 4 })
                                    this.toggleTabProgress(this.state.activeTabProgress + 1)
                                  }
                                }else if(this.state.activeTabProgress === 4){
                                  if (this.state.isComponentMounted) {
                                    window.scrollTo(0,0)
                                    this.setState({ activeTabProgress: 5 })
                                    this.toggleTabProgress(this.state.activeTabProgress + 1)
                                  }
                                }else{
                                  window.scrollTo(0,0)
                                  this.setState({ activeTabProgress: 6 })
                                  this.toggleTabProgress(this.state.activeTabProgress + 1)
                                }
                              }}}>Próximo</Link></li>
                            }
                        </ul>
                      </div>

                      <TabContent activeTab={this.state.activeTabProgress} className="twitter-bs-wizard-tab-content">
                        <TabPane tabId={1}>
                              <div>
                              <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                              {localStorage.getItem('access') === 'A' &&
                                <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre a construtora</h5></Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                          <Label className="form-label">Construtora</Label>
                                          <Select
                                            value={selectedEnterprise}
                                            placeholder="Selecione"
                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                            onChange={(e) => this.setState({ selectedEnterprise: e })}
                                            options={selectOptionsEnterprise}
                                            classNamePrefix="select2-selection"
                                          />
                                        </Col>
                                    </Row>
                                  </Container>
                                  }
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o tipo de unidade</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={6}>
                                        <Label htmlFor="tituloImovel"  className="col-md-12 col-form-label">Tipo de apartamento</Label>
                                        <select value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} className="form-control">
                                          <option value=''>Escolha um tipo</option>
                                          <option value='Apartamento tipo 1'>Apartamento tipo 1</option>
                                          <option value='Apartamento tipo 2'>Apartamento tipo 2</option>
                                          <option value='Apartamento tipo 3'>Apartamento tipo 3</option>
                                          <option value='Apartamento tipo 4'>Apartamento tipo 4</option>
                                          <option value='Apartamento tipo 5'>Apartamento tipo 5</option>
                                          <option value='Apartamento tipo 6'>Apartamento tipo 6</option>
                                          <option value='Apartamento tipo 7'>Apartamento tipo 7</option>
                                          <option value='Apartamento tipo 8'>Apartamento tipo 8</option>
                                          <option value='Apartamento tipo 9'>Apartamento tipo 9</option>
                                          <option value='Apartamento tipo 10'>Apartamento tipo 10</option>
                                          <option value='Apartamento tipo duplex'>Apartamento tipo duplex</option>
                                          <option value='Apartamento tipo garden (diferenciado)'>Apartamento tipo garden (diferenciado)</option>
                                          <option value='Cobertura 1'>Cobertura 1</option>
                                          <option value='Cobertura 2'>Cobertura 2</option>
                                          <option value='Cobertura 3'>Cobertura 3</option>
                                          <option value='Cobertura 4'>Cobertura 4</option>
                                          <option value='Cobertura 5'>Cobertura 5</option>
                                          <option value='Cobertura Duplex'>Cobertura Duplex</option>
                                          <option value='Cobertura Triplex'>Cobertura Triplex</option>
                                        </select>
                                      </Col>
                                      <Col md={3}>
                                      <AvGroup>
                                        <Label className="col-md-12 col-form-label">Área privativa</Label>
                                        <AvField
                                          name="areaPrivativa"
                                          placeholder="em m²"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.areaPrivativa}
                                          onChange={(e) => {
                                            this.setState({areaPrivativa: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                      <Col md={3}>
                                      <AvGroup>
                                        <Label className="col-md-12 col-form-label">Área total</Label>
                                        <AvField
                                          name="areaTotal"
                                          placeholder="em m²"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.areaTotal}
                                          onChange={(e) => {
                                            this.setState({areaTotal: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                      <Col md={2}>
                                      <AvGroup>
                                        <Label className="col-md-12 col-form-label">Quartos</Label>
                                        <AvField
                                          name="quartos"
                                          placeholder="Nº de quartos"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.rooms}
                                          onChange={(e) => {
                                            this.setState({rooms: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                      <Col md={2}>
                                      <AvGroup>
                                        <Label className="col-md-12 col-form-label">Suítes</Label>
                                        <AvField
                                          name="suites"
                                          placeholder="Nº de suítes"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.suites}
                                          onChange={(e) => {
                                            this.setState({suites: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                      <Col md={2}>
                                      <AvGroup>
                                        <Label className="col-md-12 col-form-label">Vagas de garagem</Label>
                                        <AvField
                                          name="garagem"
                                          placeholder="Nº de vagas de garagem"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.garage}
                                          onChange={(e) => {
                                            this.setState({garage: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                      <Col md={2}>
                                      <AvGroup>
                                        <Label className="col-md-12 col-form-label">Banheiros</Label>
                                        <AvField
                                          name="banheiros"
                                          placeholder="Nº de banheiros"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.bathrooms}
                                          onChange={(e) => {
                                            this.setState({bathrooms: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Descrição do tipo de unidade</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                      <AvGroup>
                                        <Label htmlFor="descricaoImovel" className="col-md-12 col-form-label">Descrição</Label>
                                        <AvField
                                          name="descricaoImovel"
                                          placeholder="Descreva seu imóvel com o máximo de informações e detalhes que acredite ser importantes."
                                          type="textarea"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          rows="10"
                                          validate={{ required: { value: true } }}
                                          value={this.state.descricao}
                                          onChange={(e) => this.setState({descricao: e.target.value})}
                                          id="descricaoImovel"
                                        />
                                        </AvGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                              </AvForm>
                              </div>
                          </TabPane>
                          <TabPane tabId={2}>
                            <Form className="cardBody">
                              <Container>
                                <Row style={{margin:'10px 0'}}>
                                  <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Características do tipo de unidade (ex.: Churrasqueira, Hidro...)</h5></Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <Label className="col-md-12 col-form-label">Inserir características</Label>
                                    <Input
                                        type="textarea"
                                        className="caracteristicas"
                                        rows="8"
                                        defaultValue={this.state.skillsList}
                                        onChange={(e) => this.setState({skillsList: e.target.value})}
                                    />
                                    <Card style={{padding: '15px', backgroundColor:'#f4f4f4', marginTop: '20px'}}>
                                      <Row>
                                        <Col className="col-auto">
                                          <i className="ri-error-warning-line" style={{fontSize:'30px'}}></i>
                                        </Col>
                                        <Col>
                                          <p>Adicione uma característica por linha.</p><p>Utilize a tecla Enter para trocar de linha.</p>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                  <Col md={6}>
                                    
                                  </Col>
                                </Row>
                              </Container>
                            </Form>
                          </TabPane>
                          <TabPane tabId={3}>
                              <div>
                                <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                  <Row style={{margin:'10px 0'}}>
                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Fotos do tipo de unidade</h5></Col>
                                  </Row>
                                  <Form>
                                    <Dropzone
                                      accept={{
                                        "image/*": [".png", ".jpeg", ".jpg", '.heic'],
                                      }}
                                      onDrop={acceptedFiles =>
                                        this.handleAcceptedFiles(acceptedFiles)
                                      }
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>Para adicionar fotos arraste ou clique aqui.</h4>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    {dragPhotos &&
                                      <>
                                        <div style={{margin:'20px 0'}}>
                                          <p style={{fontSize:'20px', fontWeight:'600'}}>Ordene suas fotos. As 3 primeiras recebem maior destaque.</p>
                                        </div>
                                      </>
                                    }
                                    <div
                                      className="dropzone-previews mt-3"
                                      id="file-previews"
                                    >
                                      {dragPhotos}
                                    </div>
                                  </Form>
                                </Container>
                                <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                  <Row style={{margin:'10px 0'}}>
                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Plantas do tipo de unidade</h5></Col>
                                  </Row>
                                  <Form>
                                    <Dropzone
                                      accept={{
                                        "image/*": [".png", ".jpeg", ".jpg", '.heic'],
                                      }}
                                      onDrop={acceptedPlans =>
                                        this.handleAcceptedPlans(acceptedPlans)
                                      }
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>Para adicionar plantas arraste ou clique aqui.</h4>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    {dragPlans &&
                                      <>
                                        <div style={{margin:'20px 0'}}>
                                          <p style={{fontSize:'20px', fontWeight:'600'}}>Ordene suas plantas. As 3 primeiras recebem maior destaque.</p>
                                        </div>
                                      </>
                                    }
                                    <div
                                      className="dropzone-previews mt-3"
                                      id="file-previews"
                                    >
                                      {dragPlans}
                                    </div>
                                  </Form>
                                </Container>
                                <Container>
                                  <Row style={{margin:'10px 0'}}>
                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Vídeos do tipo de unidade</h5></Col>
                                  </Row>
                                  <Row>
                                    <Col xs={12}>
                                      <Button type="button" onClick={this.addVideoInput}>
                                        Adicionar vídeo
                                      </Button>
                                    </Col>
                                    {this.state.videos.map((video, index) => (
                                      <Col md={6} key={index} style={{marginTop:'20px'}}>
                                        <Input
                                          type="text"
                                          placeholder="Link vídeo"
                                          value={video}
                                          onChange={(e) => this.updateVideoInput(index, e.target.value)}
                                        />
                                      </Col>
                                    ))}
                                  </Row>
                                </Container>
                              </div>
                          </TabPane>
                          <TabPane tabId={4}>
                              <div className="row justify-content-center">
                                <Form className="cardBody">
                                  <Container>
                                    <Row style={{paddingTop:'20px'}}>
                                      <Col md={2} style={{paddingBottom:'0'}}>
                                        <Label>Código</Label>
                                      </Col>
                                      <Col md={2} style={{paddingBottom:'0'}}>
                                        <Label>Valor</Label>
                                      </Col>
                                      <Col md={2} style={{paddingBottom:'0'}}>
                                        <Label>Status</Label>
                                      </Col>
                                      <Col md={2} style={{paddingBottom:'0'}}>
                                        <Label>Pagamento</Label>
                                      </Col>
                                    </Row>
                                    {this.state.unitsInputs.map((input, index) => (
                                      <Row  key={index}>
                                        <Col md={2} style={{paddingBottom:'0'}}>
                                          <Input
                                            type="text"
                                            placeholder="Código da unidade"
                                            value={input.sku}
                                            onChange={(e) => this.updateInput(index, 'sku', e.target.value)}
                                          />
                                        </Col>
                                        <Col md={2} style={{paddingBottom:'0'}}>
                                          <CurrencyInput defaultValue={input.price} onChange={(e) => this.updateInput(index, 'price', e.target.value)}/>
                                        </Col>
                                        <Col md={2} style={{paddingBottom:'0'}}>
                                          <select className="form-control" value={input.status} onChange={(e) => this.updateInput(index, 'status', e.target.value)}>
                                          <option value={null}>Selecione</option>
                                            <option value="1">Disponível</option>
                                            <option value="2">Reservado</option>
                                            <option value="3">Vendido</option>
                                            <option value="0">Indispónível</option>
                                          </select>
                                        </Col>
                                        <Col md={2} style={{paddingBottom:'0'}}>
                                          {/* <Input
                                            type="text"
                                            placeholder="ex.: 120x sem entrada"
                                            value={input.payment_condition}
                                            onChange={(e) => this.updateInput(index, 'payment_condition', e.target.value)}
                                          /> */}
                                          <Button
                                            style={{marginTop:'5px'}}
                                            className="btn-sm"
                                            color="light"
                                            onClick={() => {
                                              if(input.sku !== ''
                                              && input.sku
                                              && input.status !== ''
                                              && input.status
                                              && input.price !== ''
                                              && input.price){
                                                this.tog_payment()
                                                this.setState({unit_code_edit: input.sku})
                                                this.setState({unit_price_edit: input.price})
                                                this.setState({unit_status_edit: input.status})
                                              }else{
                                                this.setState({update_payment_error: true})
                                              }
                                            }}
                                          >Ver condições</Button>
                                        </Col>
                                      </Row>                                        
                                      ))}
                                      <Row>
                                        <Col xs={12} style={{display:'flex', justifyContent: 'flex-start', margin:'20px 0 20px'}}>
                                          <Button type="button" onClick={this.addInput} style={{display:'flex', alignItem: 'center'}}>
                                            <i className="ri-add-circle-line"></i>Adicionar unidade
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </Form>
                              </div>
                          </TabPane>
                          <TabPane tabId={5}>
                              <div className="row justify-content-center">
                                  <Col lg="6">
                                      <div className="text-center">
                                          <div className="mb-4">
                                              <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                          </div>
                                          <div>
                                              <h5>Confirmar cadastro</h5>
                                              <div className="container-text-muted">
                                                <p className="text-muted">Você pode voltar as etapas e conferir se está tudo certo</p>
                                              </div>
                                          </div>
                                          <div>
                                            <Button  onClick={() => {
                                              document.getElementById('load').classList.add('active')
                                                  if(this.state){

                                                    const url = new URL(window.location.href);
                                                    const id = url.searchParams.get("id");

                                                    const data = new FormData();
                                                    data.append('title', this.state.title)
                                                    data.append('private_area', this.state.areaPrivativa)
                                                    data.append('total_area', this.state.areaTotal)
                                                    data.append('description', this.state.descricao)
                                                    data.append('rooms', this.state.rooms)
                                                    data.append('suites', this.state.suites)
                                                    data.append('garage', this.state.garage)
                                                    data.append('bathrooms', this.state.bathrooms)
                                                    data.append('property_enterprise_id', id)
                                               
                                                    this.setState({skillsArray: this.state.skillsList.split('\n')})

                                                    let skillsRequest = Promise.resolve();
                                                    let photosRequest = Promise.resolve();
                                                    let plansRequest = Promise.resolve();
                                                    let unitsRequest = Promise.resolve();
                                                    let videosRequest = Promise.resolve();

                                                    const allRequests = [];

                                                     Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types', data, {
                                                       headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                                     })
                                                     .then(response => {

                                                        if(this.state.skillsList !== ''){
                                                          
                                                          const dataEnterpriseSkills = new FormData();
                                                
                                                          for(var i = 0; this.state.skillsArray.length > i; i++){
                                                            dataEnterpriseSkills.append(`detail[${i}][detail]`, this.state.skillsArray[i])
                                                            dataEnterpriseSkills.append(`detail[${i}][property_enterprise_type_id]`, response.data.data)
                                                          }
                                                
                                                          skillsRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-details', dataEnterpriseSkills, {
                                                            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                                          })
                                                        }

                                                        if(this.state.videos.length > 0){
                                                          const dataEnterpriseVideos = new FormData();

                                                          for(var i = 0; this.state.videos.length > i; i++){
                                                            dataEnterpriseVideos.append(`video[${i}][url]`, this.state.videos[i])
                                                            dataEnterpriseVideos.append(`video[${i}][position]`, i)
                                                            dataEnterpriseVideos.append(`video[${i}][property_enterprise_type_id]`, response.data.data)
                                                          }

                                                          videosRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-videos', dataEnterpriseVideos, {
                                                            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                                          })
                                                        }
                                                          
                                                        if(this.state.unitsInputs.length > 0){
                                                          const dataEnterpriseUnits = new FormData();

                                                          for(var i = 0; this.state.unitsInputs.length > i; i++){
                                                            dataEnterpriseUnits.append(`unit[${i}][slug]`, this.createSlug(this.state.title + this.state.unitsInputs[i].sku))
                                                            dataEnterpriseUnits.append(`unit[${i}][sku]`, this.state.unitsInputs[i].sku)
                                                            dataEnterpriseUnits.append(`unit[${i}][floor]`, 0)
                                                            dataEnterpriseUnits.append(`unit[${i}][price]`, parseInt(this.state.unitsInputs[i].price.replace('R$ ', '').replace(',', '').replace(/\./g,'') / 10))
                                                            dataEnterpriseUnits.append(`unit[${i}][offer_price]`, 0)
                                                            dataEnterpriseUnits.append(`unit[${i}][payment_condition]`, this.state.unitsInputs[i].payment_condition)
                                                            dataEnterpriseUnits.append(`unit[${i}][status]`, this.state.unitsInputs[i].status)
                                                            dataEnterpriseUnits.append(`unit[${i}][property_enterprise_type_id]`, response.data.data)
                                                          }

                                                          unitsRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-units', dataEnterpriseUnits, {
                                                            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                                          })
                                                        }


                                                        if(this.state.photosArray.length > 0){
                                                          const dataEnterprisePhotos = new FormData();
                                                
                                                          for(let i = 0; i < this.state.photosArray.length; i++){
                                                            dataEnterprisePhotos.append(`photo[${i}][file]`, this.state.photosArray[i].file)
                                                            dataEnterprisePhotos.append(`photo[${i}][position]`, this.state.photosArray[i].position)
                                                            dataEnterprisePhotos.append(`photo[${i}][property_enterprise_type_id]`, response.data.data)
                                                          }
                                                
                                                          photosRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-photos', dataEnterprisePhotos, {
                                                            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                                          })
                                                        }

                                                        if(this.state.plansArray.length > 0){
                                                          const dataEnterprisePlans = new FormData();
                                                
                                                          for(let i = 0; i < this.state.plansArray.length; i++){
                                                            dataEnterprisePlans.append(`plan[${i}][file]`, this.state.plansArray[i].file)
                                                            dataEnterprisePlans.append(`plan[${i}][position]`, this.state.plansArray[i].position)
                                                            dataEnterprisePlans.append(`plan[${i}][property_enterprise_type_id]`, response.data.data)
                                                          }
                                                
                                                          plansRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-plans', dataEnterprisePlans, {
                                                            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                                          })
                                                        }

                                                      allRequests.push(skillsRequest, photosRequest, videosRequest, plansRequest, unitsRequest);

                                                      return Promise.all(allRequests);

                                                     })
                                                      .then(responses => {
                                                        document.getElementById('load').classList.remove('active');
                                                        this.setState({ update_success: true });
                                                      })
                                                      .catch(error => {
                                                        document.getElementById('load').classList.remove('active');
                                                        this.setState({ update_error: true });
                                                      });
                                                   }
                                            }}>Tudo certo! Pode cadastrar</Button>
                                          </div>
                                      </div>
                                  </Col>
                              </div>
                          </TabPane>
                      </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

            {/* Sucesso na atualização */}

            {this.state.update_success ? (

              <SweetAlert
                title="Unidade cadastrada!"
                success
                showCancel
                confirmBtnText="Cadastrar outra unidade"
                cancelBtnText="Ver empreendimento"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  const url = new URL(window.location.href);
                  const id = url.searchParams.get("id");
                  this.setState({ success_msg: false })
                  setTimeout(() => {
                    window.open('./../nova-unidade?id=' + id, '_parent')
                  }, 100);
                }}
                onCancel={() => {
                  this.setState({ success_msg: false })
                  setTimeout(() => {
                    window.open('./../dashboard', '_parent')
                  }, 100);
                }}
              >
              Agora você precisa cadastrar as unidades.
          </SweetAlert>
            ) : null}

            {
            this.state.uploading ? (
              <div id="load" className="load" style={{display: 'flex', flexDirection: 'column'}}>
                <div className='container-background'>
                  <div className='container-uploading'>
                    <div className='container-phrase-uploading'>
                      {(() => {
                          if (this.state.uploadPercentage < 20) {
                            return(
                              <div className='phrase-text'>
                                  <img className='upload-image' src={Upload} alt='ícone fazendo um upload' /> 
                                  <div className='phrase-details'>
                                    <p>Fazendo upload do material</p><span className='dots'>{this.state.dots}</span>
                                  </div>
                              </div>
                            )
                        } else if (this.state.uploadPercentage < 40) {
                            return(
                              <div className='phrase-text'> 
                                <img className='upload-image' src={Corretores} alt='ícone corretores' /> 
                                <div className='phrase-details'>
                                  <p>Disponibilizando seu imóvel para milhares de corretores</p><span className='dots'>{this.state.dots}</span>
                                </div>
                              </div>
                            ) 
                        } else if (this.state.uploadPercentage < 60) {
                          return(
                            <div className='phrase-text'>
                              <img className='upload-image' src={Contrato} alt='ícone contrato parceria' />
                              <div className='phrase-details'> 
                                <p>Adicionando seus dados ao contrato de parceria</p><span className='dots'>{this.state.dots}</span>
                              </div>
                            </div>
                          )
                        } else if (this.state.uploadPercentage < 80) {
                          return(
                            <div className='phrase-text'>
                              <img className='upload-image' src={Cafezinho} alt='ícone passando cafezinho' /> 
                              <div className='phrase-details'> 
                                <p>Passando um cafezinho</p><span className='dots'>{this.state.dots}</span>
                              </div>
                            </div>
                          ) 
                        } else if (this.state.uploadPercentage < 100) {
                          return(
                            <div className='phrase-text'>
                                <img className='upload-image' src={Instante} alt='Só um instante' />
                                <div className='phrase-details'> 
                                  <p>Só mais um instante</p><span className='dots'>{this.state.dots}</span>
                                </div>
                            </div>
                          ) 
                        } else {
                          return(
                            <div className='phrase-text'>
                              <img className='upload-image' src={Sucesso} alt='ícone de cadastro com successo' />
                              <div className='phrase-details'> 
                                <p>Sucesso! Cadastro de imóvel concluído</p>
                              </div>
                            </div>
                          ) 
                        }
                      })()}
                    </div>
                    <Spinner className="me-2" color="success" style={{ width: '60px',height: '60px', fontSize: '1.3rem', marginTop: '4rem' }}/>
                    <p className='quantify-percentage'>{this.state.uploadPercentage}%</p>
                  </div>
                </div>
              </div>
            ) : (
            <div id="load" className="load">
              <Spinner className="me-2" color="success" />
            </div>
            )
          }

          {/* Editar condições de pagamento de unidade */}

          <Modal
            isOpen={this.state.modal_payment}
            toggle={this.tog_payment}
            centered={true}
            scrollable={true}
            size="lg"
          >
            <ModalHeader toggle={() => this.setState({ modal_payment: false })}>
              Condições de pagamento
            </ModalHeader>
            <ModalBody>
              <Form className="cardBody">
                <Container style={{padding:'20px 20px 0', marginBottom: '15px'}}>
                  {/* {this.state.payment_items.length > 0 && */}
                    <Row>
                      <Col xs={12}>
                        <Card style={{padding: '15px', backgroundColor:'#f4f4f4'}}>
                          <Row>
                            <Col className="col-auto">
                              <i className="ri-error-warning-line" style={{fontSize:'30px'}}></i>
                            </Col>
                            <Col>
                              <p>As condições de pagamento cadastradas no empreendimento, são pré-cadastradas para todas unidades.</p>
                              <p><b>Você pode criar condições de pagamento diferentes para cada unidade.</b></p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    {this.state.payment_items.map((item, index) => (
                      <Row key={index}>
                        <Col md={3}>
                          <Label>Tipo de condição</Label>
                          <select className="form-control" value={item.type} onChange={(e) => this.handleInputChange(e, index, 'pay_type')}>
                            <option value={null}>Selecione</option>
                            <option value="entrance">Entrada</option>
                            <option value="reinforcement">Reforço</option>
                            <option value="parcels">Parcelamento</option>
                            <option value="keys">Entrega de chaves</option>
                          </select>
                        </Col>
                        <Col md={2}>
                          <Label>R$ / %</Label>
                          <select className="form-control" value={item.type_value} onChange={(e) => (e) => this.handleInputChange(e, index, 'type_value')}>
                            <option value={null}>Selecione</option>
                            <option value="currency">Valor</option>
                            <option value="percent">Porcentagem</option>
                          </select>
                        </Col>
                        <Col md={2}>
                          
                          {item.type_value === 'currency' ?
                            <>
                              <Label>Valor</Label>
                              <CurrencyInput defaultValue={item.value} onChange={(e) => this.handleInputChange(e, index, 'value')}/>
                            </>
                          :
                          <>
                            <Label>Porcentagem</Label>
                            <Input
                              type="number"
                              placeholder=""
                              value={item.value}
                              onChange={(e) => this.handleInputChange(e, index, 'pay_value')}
                            />
                          </>
                          }
                        </Col>
                        <Col md={2}>
                          <Label>Parcelas</Label>
                          <Input
                            type="number"
                            placeholder="Nº de parcelas"
                            value={item.parcels}
                            onChange={(e) => this.handleInputChange(e, index, 'parcels')}
                          />
                        </Col>
                        <Col md={3}>
                          <Label>Observações</Label>
                          <Input
                            type="text"
                            placeholder="Observações"
                            value={item.observation}
                            onChange={(e) => this.handleInputChange(e, index, 'observation')}
                          />
                        </Col>
                      </Row>
                    ))
                  }
                </Container>
              </Form>
              <Button onClick={() => {
                const id = window.location.search.replace('?id=', '')
                for(var i = 0; this.state.payment_items.length < i; i++){
                  const novaCondicao = {
                    type: this.state.payment_items[i].type,
                    type_value: this.state.payment_items[i].type_value,
                    value: this.state.payment_items[i].value,
                    parcels: this.state.payment_items[i].parcels,
                    observation: this.state.payment_items[i].observation,
                    unit_sku: this.state.unit_code_edit
                  };
                  this.state.payment_condition_edited.push(novaCondicao)
                }
                setTimeout(() => {
                  Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-payment-conditions/' + id,
                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                  .then(response => {
                    this.setState({payment_items: response.data})
                  })
                }, 500);
              }}>Salvar</Button>
            </ModalBody>
          </Modal>

            {/* Unidade sem SKU */}

            {this.state.update_payment_error ? (
            <SweetAlert
              title="A unidade precisa ter código, valor e status antes de ter condições de pagamento."
              timeout={3000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ update_payment_error: false })}
            ></SweetAlert>
            ) : null}

          {/* Falha na atualização */}

          {this.state.update_error ? (
            <SweetAlert
              title="O cadastro falhou, revise os campos e tente novamente."
              timeout={4000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ update_error: false })}
            ></SweetAlert>
            ) : null}
      </div>
    );
  }
}

export default AddUnits;