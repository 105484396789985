import styles from '../styles/AboutSecond.module.css';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import AboutSecondMobile from './AboutSecondMobile';
import ContentEditable from "react-contenteditable";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Dropzone from 'react-dropzone';

const About = ({data}) => {
  const Image1 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910160/imagens_site/01_scvymp.jpg'
  const Image2 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/02_xgiimb.jpg'
  const Image3 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/03_e9vkzl.jpg' 
  const Image4 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/04_xsg1ak.jpg'
  const Image5 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/05_ihhakf.jpg'
  const Image6 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/06_cczyec.jpg'
  const Image7 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/07_ferbmj.jpg'
  const Image8 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/08_xfpa1t.jpg'
  const Image9 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/09_nc7xre.jpg'
  const Image10 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/10_nv5ebk.jpg' 
  const Image11 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/11_czopvn.jpg'
  const Image12 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/12_m9zhdi.jpg'
  const Image13 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/13_p9suxq.jpg'
  const Image14 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/14_rs5kae.jpg'
  const Image15 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/15_h1nynp.jpg'  
  const Image16 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/16_jgg85v.jpg'
  const Image17 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/17_svhtnj.jpg'
  const Image18 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910163/imagens_site/18_siq9wr.jpg'
  const Image19 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910163/imagens_site/19_m5xuxr.jpg'
  const Image20 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/20_afgcus.jpg'
  const [aboutMoreTog, setAboutMoreTog ] = useState(false)
  const [ highSecondTitle, setHighTitle] = useState('Sobre nós')
  const [ highSecondTitleEnabled, setHighTitleEnabled] = useState(true)
  const [bg, setBg] = useState()
  const [modalLarge, setModalLarge] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState(null);
  const defaultImages = [
    { src: Image1 },
    { src: Image2 },
    { src: Image3 },
    { src: Image4 },
    { src: Image5 },
    { src: Image6 },
    { src: Image7 },
    { src: Image8 },
    { src: Image9 },
    { src: Image10 },
    { src: Image11 },
    { src: Image12 },
    { src: Image13 },
    { src: Image14 },
    { src: Image15 },
    { src: Image16 },
    { src: Image17 },
    { src: Image18 },
    { src: Image19 },
    { src: Image20 },
  ];

  const [isMobile, setIsMobile] = useState(false);
  const isMobileQuery = useMediaQuery({ query: `(max-width: 767px)` });

  function handleBannerFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const fileAsBase64String = reader.result.split(',')[1];
      localStorage.setItem('image_about', fileAsBase64String);
    });
    reader.readAsDataURL(file);
  }

  function isBase64(str) {
    try {
      atob(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    setIsMobile(isMobileQuery);

    const imageAbout = localStorage.getItem('image_about');
    if (imageAbout && imageAbout.slice(0, 4) !== 'http') {
      if(localStorage.getItem('image_about')){
        const fileAsBase64String = localStorage.getItem('image_about');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
      
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
      
          const blob = new Blob([array], { type: 'application/octet-stream' });
      
          const file = new File([blob], 'nome-do-arquivo.extensão');
      
          setBg(file) ;
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      }
    } else {
      setBg(imageAbout);
    }

    if(localStorage.getItem('about_title')){
      setHighTitle(localStorage.getItem('about_title'))
    }


  }, [isMobileQuery]);

  if (isMobile) {
    return <AboutSecondMobile data={data} />;
  }

  return(
    <section className={styles.bg}
      style={localStorage.getItem('image_about') && localStorage.getItem('image_about').slice(0,4) !== 'http' ? {backgroundImage: 'url(' + (bg ? URL.createObjectURL(bg) : '') + ')', position:'relative'} : {backgroundImage: `url(${bg})`} }
    >
      <div className={styles.actionIconsFirst}>
        <label className="bg" onClick={() => setModalLarge(!modalLarge)}><span>Inserir imagem</span><i className="ri-image-add-fill"></i></label>
      </div>
      <div className={styles.container_profile}>
        <img src={data?.profile_photo} alt="" className={styles.people} />

        <div>
          <div className={styles.titleContainer}>
          <ContentEditable
              html={highSecondTitle}
              className={styles.titleEdit}
              id='aboutTitleEdit'
              disabled={highSecondTitleEnabled}
              onChange={(e) => {
                setHighTitle(e.target.value)
                localStorage.setItem('about_title', e.target.value)
              }}
              onClick={(e) => {
                if(highSecondTitle === 'Sobre nós' && !highSecondTitleEnabled){
                  setHighTitle('')
                  localStorage.setItem('about_title', '')
                }
              }}
            />
            <div className={styles.actionIcons}>
              <label className="title">
                <span onClick={() => {
                  if(!highSecondTitleEnabled){
                    setHighTitleEnabled(true)
                    if(highSecondTitle === ''){
                      setHighTitle('Sobre nós')
                      localStorage.setItem('about_title', 'Sobre nós')
                    }
                  }else{
                    setHighTitleEnabled(false)
                    setTimeout(() => {
                      document.getElementById('aboutTitleEdit').focus()
                      if(highSecondTitle === 'Sobre nós'){
                        setHighTitle('')
                        localStorage.setItem('about_title', '')
                      }
                    }, 300);
                  }
                }}>{highSecondTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
                <i className="ri-text" onClick={() => {
                  if(!highSecondTitleEnabled){
                    setHighTitleEnabled(true)
                    if(highSecondTitle === ''){
                      setHighTitle('Sobre nós')
                      localStorage.setItem('about_title', 'Sobre nós')
                    }
                  }else{
                    setHighTitleEnabled(false)
                    setTimeout(() => {
                      document.getElementById('aboutTitleEdit').focus()
                      if(highSecondTitle === 'Sobre nós'){
                        setHighTitle('')
                        localStorage.setItem('about_title', '')
                      }
                    }, 300);
                  }
                }}></i>
              </label>
            </div>
          </div>
          {!aboutMoreTog ?
            <p className={styles.text}><pre-line>{data?.about}</pre-line></p>
            :
            <p className={styles.textExpand}><pre-line>{data?.about}</pre-line></p>
          }
          <div className={styles.aboutMore} onClick={() => {
            setAboutMoreTog(!aboutMoreTog)
          }}>{!aboutMoreTog ? 'Ver mais' : 'Ver menos'}</div>
        </div>
      </div>

      <Modal
        size="lg"
        isOpen={modalLarge}
        toggle={() => {
          setModalLarge(!modalLarge);
        }}
      >
        <ModalHeader toggle={() => setModalLarge(!modalLarge)}>
            Escolha uma imagem para o banner        
        </ModalHeader>
        <ModalBody>
          <Dropzone
            onDrop={acceptedFiles => {
              setBg(acceptedFiles[0]);
              handleBannerFileChange({ target: { files: acceptedFiles } });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                  </div>
                  <h4>Para adicionar uma imagem arraste ou clique aqui.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <p className={styles.title_options}>Ou escolha uma das imagens padrão:</p>
          <div className={styles.imageOptions}>
            {defaultImages.map((image, index) => (
              <label key={index} 
                onClick={() => {
                  setSelectedLabel(index); 
                  setModalLarge(false); 
                }} 
                className={selectedLabel === index ? styles.selected : ''}
              >
                <img src={image.src} alt={image.name} className={styles.imagePreview} />
                <input type="radio" name="imageOption" value={image.src} onChange={(e) => {localStorage.setItem('image_about', e.target.value) ;setBg(e.target.value)}} />
              </label>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </section>
  )
}

export default About;