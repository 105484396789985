import { useEffect, useState } from 'react';
import './styles.scss';
import Axios from 'axios';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import logoDark from "../../../assets/images/logo-dark.png";
import Bg from '../../../assets/images/agradecimento.png';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Whats from '../../../components/Whats/Whats';
import PayProcess from './../../../assets/images/Icons/processando.png';
import PayFail from './../../../assets/images/Icons/negado.png';
import PaySuccess from './../../../assets/images/Icons/concluido.png';

export default function CompletePayment(){
  const location = useLocation()

  const params = new URLSearchParams(location.search);
  const transaction = params.get('id');
  const method = params.get('method');
  const [paymentStatus, setPaymentStatus] = useState([])
  const [paymentHistoryStatus, setPaymentHistoryStatus] = useState([])

  useEffect(() => {
    if(method && method === 'pix'){
      Axios.get(process.env.REACT_APP_API + '/api/auth/payment-feedback-history/' + transaction, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
      }).then(response => {
        // console.log(response.data)
        setPaymentHistoryStatus(response.data)
      })
    }else{
      Axios.get(process.env.REACT_APP_API + '/api/auth/payment-feedback/' + transaction, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
      }).then(response => {
        setPaymentStatus(response.data.data[0])
        console.log(response.data)
        Axios.get(process.env.REACT_APP_API + '/api/auth/payment-feedback-history/' + response.data.data[0].charge.id, {
          headers:{
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem('token')
          }
        }).then(response => {
          setPaymentHistoryStatus(response.data)
          console.log(response.data)
        })
      })
    }
  }, [])

  return(
    <div className="checkout-cart-section">
    <Container>
      <Row className="top-bar">
        <Col className="col-auto">
          <img src={logoDark}/>
        </Col>
        <Col className="col-auto">
          <span>Checkout Seguro</span>
          <i className="ri-lock-fill"></i>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row className="reverse">
        <Col md={12}>
          <Row>
            <Col xs={12}>
              <div className="thank-you-title"  style={{backgroundImage: `url('${Bg}')`}}>
                <h1>Um pequeno valor na fatura,</h1>
                <h1>um grande passo para o sucesso.</h1>
              </div>
            </Col>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="thank-you-status">
                    {paymentHistoryStatus?.last_transaction?.transaction_type === 'pix' &&
                      <>
                        {paymentHistoryStatus?.last_transaction?.status === 'waiting_payment' && 
                          <>
                            <img src={PayProcess}/>
                            <h3>Aguardando confirmação de pagamento</h3>
                            <p>Te avisaremos por e-mail quando ele for concluído.</p>
                            <a href="/login" target="_blank"><Button>Acessar Fast Sale</Button></a>
                          </>
                        }
                        {paymentHistoryStatus?.last_transaction?.status === 'paid' && 
                          <>
                            <img src={PaySuccess}/>
                            <h3>Pagamento concluído</h3>
                            <p>Sua assinatura já está ativa.</p>
                            <a href="/login" target="_blank"><Button>Acessar Fast Sale</Button></a>
                          </>
                        }
                        {paymentHistoryStatus?.last_transaction?.status === 'failed' && 
                          <>
                            <img src={PayFail}/>
                            <h3>O pagamento falhou</h3>
                            <p>Algo deu errado com seu pagamento, tente novamente ou entre em contato com nosso suporte.</p>
                            <a href="https://wa.me/554741081865" target="_blank"><Button>Falar com o suporte</Button></a>
                          </>
                        }
                        {paymentHistoryStatus?.last_transaction?.status === 'canceled' && 
                          <>
                            <img src={PayFail}/>
                            <h3>O pagamento falhou</h3>
                            <p>Algo deu errado com seu pagamento, tente novamente ou entre em contato com nosso suporte.</p>
                            <a href="https://wa.me/554741081865" target="_blank"><Button>Falar com o suporte</Button></a>
                          </>
                        }
                        {paymentHistoryStatus?.last_transaction?.status === 'processing' && 
                          <>
                            <img src={PayProcess}/>
                            <h3>Aguardando confirmação de pagamento</h3>
                            <p>Te avisaremos por e-mail quando ele for concluído.</p>
                            <a href="/login" target="_blank"><Button>Acessar Fast Sale</Button></a>
                          </>
                        }
                      </>
                    }


                    {(paymentHistoryStatus?.last_transaction?.transaction_type === 'credit_card'
                    && paymentHistoryStatus?.last_transaction?.status === 'not_authorized') ?
                      <>
                        <img src={PayFail}/>
                        <h3>Pagamento falhou</h3>
                        <p>O pagamento não foi autorizado pela sua operadora de cartão. Faremos novas tentativas, mas você pode alterar o cartão e tentar novamente.</p>
                        <a href="https://wa.me/554741081865" target="_blank"><Button>Falar com o suporte</Button></a>
                      </>
                      :
                      <>
                      {paymentStatus.charge?.status === 'paid' && <img src={PaySuccess}/>}
                      {(paymentStatus.charge?.status === 'canceled' || paymentStatus.charge?.status === 'failed' || paymentStatus.charge?.status === 'expired') && <img src={PayFail}/>}
                      {(paymentStatus.charge?.status === 'waiting_payment' || paymentStatus.charge?.status === 'pending_review' || paymentStatus.charge?.status === 'processing') && <img src={PayFail}/>}
                      {paymentStatus.charge?.status === 'paid' && <h3>Pagamento concluído</h3>}
                      {paymentStatus.charge?.status === 'waiting_payment' && <h3>Aguardando pagamento</h3>}
                      {paymentStatus.charge?.status === 'canceled' && <h3>Pagamento cancelado</h3>}
                      {paymentStatus.charge?.status === 'failed' && <h3>Pagamento falhou</h3>}
                      {paymentStatus.charge?.status === 'refunded' && <h3>Pagamento reembolsado</h3>}
                      {paymentStatus.charge?.status === 'pending_review' && <h3>Aguardando revisão</h3>}
                      {paymentStatus.charge?.status === 'expired' && <h3>Fatura expirada</h3>}
                      {paymentStatus.charge?.status === 'processing' && <h3>Pagamento em processamento</h3>}

                      {(paymentStatus.charge?.status === 'pending_review'
                      || paymentStatus.charge?.status === 'processing') &&
                        <>
                          <p>Te avisaremos por e-mail quando ele for concluído.</p>
                          <a href="/login"><Button>Acessar Fast Sale</Button></a>
                        </>
                      }
                      {(paymentStatus.charge?.status === 'canceled'
                      || paymentStatus.charge?.status === 'waiting_payment'
                      || paymentStatus.charge?.status === 'failed'
                      || paymentStatus.charge?.status === 'expired') &&
                        <>
                          <p>Algo deu errado com seu pagamento, tente novamente ou entre em contato com nosso suporte.</p>
                          <a href="https://wa.me/554741081865" target="_blank"><Button>Falar com o suporte</Button></a>
                        </>
                      }
                      {(paymentStatus.charge?.status === 'paid') &&
                        <>
                          <p>Sua assinatura já está ativa.</p>
                          <a href="/login"><Button>Acessar Fast Sale</Button></a>
                        </>
                      }
                      </>
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    <Whats/>
  </div>
  )
}