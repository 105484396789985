import styles from '../styles/SearchHighlights.module.css';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Icon from '@mdi/react';
import { mdiHomeCity, mdiCarBack, mdiBedKingOutline } from '@mdi/js';
import { mdiCurrencyUsd } from '@mdi/js';
import { mdiMagnify } from '@mdi/js';
import SearchSecondMobile from './SearchSecondMobile';
import { ChromePicker } from 'react-color';
import CurrencyInput from '../../../components/Inputs/CurrencyInput';


export default function SearchHighlights() {
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [showPickerButton, setShowPickerButton] = useState(false);
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [showPickerTextButton, setShowPickerTextButton] = useState(false);


  const [isMobile, setIsMobile] = useState(false);
  const isMobileQuery = useMediaQuery({ query: `(max-width: 767px)` });
  const [showOptionsLocation, setShowOptions] = useState(false);
  const [showOptionsType, setShowOptionsType] = useState(false);
  const [showOptionsStatus, setShowOptionsStatus] = useState(false);
  const [index, setIndex] = useState(0);
  const words = ['Digite o nome do empreendimento', 'Buscar por código do imóvel'];
  const [typing, setTyping] = useState(false);
  const [valorProposta, setValorProposta] = useState('');

  const handleTyping = (e) => {
    if (e.target.value === '') {
      setTyping(false);
    } else {
      setTyping(true);
    }
  };

  const handleButtonLocation = () => {
    setShowOptions(!showOptionsLocation);
  };

  const handleButtonType = () => {
    setShowOptionsType(!showOptionsType);
  };

  const handleButtonStatus = () => {
    setShowOptionsStatus(!showOptionsStatus);
  };

  const handleMouseLeaveLocation = () => {
    setShowOptions(false);
    setShowOptionsType(false);
    setShowOptionsStatus(false);
  };

  useEffect(() => {
    if(localStorage.getItem('home_button_text_color')){
      setColorTextButton(JSON.parse(localStorage.getItem('home_button_text_color')))
    }
    if(localStorage.getItem('home_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('home_button_color')))
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 6000);
    return () => clearTimeout(timeout);
  }, [index, words.length]);

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery]);

  if (isMobile) {
    return <SearchSecondMobile />;
  }

  return(
      <div className={styles.container_inputs}>
        <div onMouseLeave={handleMouseLeaveLocation}>
          <button onClick={handleButtonLocation} className={`${styles.options_button}  ${showOptionsLocation ? styles.first : styles.first_active}`}>
            <span><Icon path={mdiHomeCity} size={.9}/></span> Categoria
          </button>
          {showOptionsLocation && (
            <div className={styles.options_container}>
              <label>
                <input type="checkbox" /> Apartamento
              </label>
              <label>
                <input type="checkbox" /> Casa
              </label>
              <label>
                <input type="checkbox" /> Comercial
              </label>
              <label>
                <input type="checkbox" /> Outros
              </label>
            </div>
          )}
        </div>
        <div onMouseLeave={handleMouseLeaveLocation}>
          <button onClick={handleButtonType} className={styles.options_button}> 
            <span><Icon path={mdiCarBack} size={.9}/>Vagas</span> 
          </button>
          {showOptionsType && (
            <div className={styles.options_container}>
              <label>
                <input type="checkbox" /> 1 vaga
              </label>
              <label>
                <input type="checkbox" /> 2 vagas
              </label>
              <label>
                <input type="checkbox" /> 3 vagas
              </label>
              <label>
                <input type="checkbox" /> 4 vagas
              </label>
              <label>
                <input type="checkbox" /> 5+ vagas
              </label>
            </div>
          )}
        </div>
        <div onMouseLeave={handleMouseLeaveLocation}>
          <button onClick={handleButtonStatus} className={styles.options_button}>
            <span><Icon path={mdiBedKingOutline} size={.9}/> Quartos </span> 
          </button>
          {showOptionsStatus && (
            <div className={styles.options_container}>
              <label>
                <input className={styles.input} type="checkbox" /> 1 quarto
              </label>
              <label>
                <input type="checkbox" /> 2 quartos
              </label>
              <label>
                <input type="checkbox" /> 3 quartos
              </label>
              <label>
                <input type="checkbox" /> 4 quartos
              </label>
              <label> 
                <input type="checkbox" /> +5 quartos
              </label>
            </div>
          )}
        </div>
        <div className={styles.options_button}>
          <Icon path={mdiCurrencyUsd} size={.9}/>
          <CurrencyInput defaultValue={valorProposta} onChange={(e) => setValorProposta(e.target.value)} />

        </div>
        <div className={`${styles.input_text} ${styles.text_container}`}>
          <Icon path={mdiMagnify} size={1} className={styles.lupa}/>
          <input
            className={`${styles.text_animation} ${typing ? styles.pause_animation : ''}`}
            type="text"
            placeholder={words[index]}
            onChange={handleTyping}
          />
        </div>
        <div className={styles.search}>
          <div className={styles.container_button}>
            <button style={{
                backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
              }}>Buscar
            </button>
            <div className={styles.actionIcons}>
              <label className="title">
                <i className="ri-brush-fill" onClick={() => {
                  setShowPickerButton(!showPickerButton)
                  setShowPickerTextButton(false)
                  }}></i>
                <i className="ri-font-color" onClick={() => {
                  setShowPickerTextButton(!showPickerTextButton)
                  setShowPickerButton(false)
                  }}></i>
                {showPickerButton && (
                  <div className={styles.colorPickerButton}>
                    <i className="ri-close-fill closeIcon" onClick={() => setShowPickerButton(!showPickerButton)}></i>
                    <ChromePicker
                      color={colorButton ?? { r: 0, g: 0, b: 0, a: 1 }}
                      onChange={(e) => {
                        setColorButton(e.rgb)
                        localStorage.setItem('home_button_color', JSON.stringify(e.rgb))
                      }}
                    />
                    </div>
                  )}
                  {showPickerTextButton && (
                  <div className={styles.colorPickerButton}>
                    <i className="ri-close-fill closeIcon" onClick={() => setShowPickerTextButton(!showPickerTextButton)}></i>
                    <ChromePicker
                      color={colorTextButton ?? { r: 0, g: 0, b: 0, a: 1 }}
                      onChange={(e) => {
                        setColorTextButton(e.rgb)
                        localStorage.setItem('home_button_text_color', JSON.stringify(e.rgb))
                      }}
                    />
                    </div>
                  )}
              </label>
            </div>
          </div>
        </div>
      </div>
  )
}