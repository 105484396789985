import React, { Component } from 'react';
import styles from './../Home/styles/Home.module.css';

import { Row, Col, Input, Button, Alert, Container, Label, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLogin, apiError } from '../../store/actions';

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import Whats from '../../components/Whats/Whats';
import SweetAlert from 'react-bootstrap-sweetalert';
import Axios from 'axios';
import PhoneInput from '../Home/components/PhoneInput';
import EmailInput from '../Home/components/EmailInput';
import CnpjInput from '../Home/components/CnpjInput';
import CpfInput from '../Home/components/CpfInput';
import CepInput from '../Home/components/CepInput';
import CreciInput from '../Home/components/CreciInput';
import CreciJInput from '../Home/components/CreciJInput';
import GoogleLogo from './../../assets/images/login-cliente-google.jpg';
import { AuthComponent } from './Login/components/authComponent';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            modal_center: false,
            basic:false,
            name:'',
            phone:'',
            email:'',
            cnpj:'',
            cpf:'',
            cep:'',
            street:'',
            district:'',
            complement:'',
            origin_response:'',
            origin_response_other:'',
            city:'',
            state:'',
            password:'',
            isMobile:false,
            isMobileQuery:{ query: `(max-width: 767px)` },
            confirmPassword:'',
            creci_j:'',
            creci:'',
            type:'',
            endereco:[],
            registerSuccess:false,
            registerError:false,
            userType:'',
            modalMap:false,
            showPass:false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createRegister = this.createRegister.bind(this);
        this.handleBrokerClick = this.handleBrokerClick.bind(this);
        this.handleRealStateClick = this.handleRealStateClick.bind(this);
        this.tog_center = this.tog_center.bind(this);
        this.handleGoogleLogin = this.handleGoogleLogin.bind(this)
    }

    openModal = () => this.setState({modalMap : !this.state.modalMap});

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center
        }));
        this.removeBodyCss();
    }

    handleSubmit(event, values) {
        this.props.checkLogin(values, this.props.history);
    }

    componentDidMount() {
        this.props.apiError("");
        document.body.classList.add("auth-body-bg");

    }

    componentWillUnmount() {
        document.body.classList.remove("auth-body-bg");
    }

    createRegister = () => {
        if (
            this.state.name === '' ||
            this.state.phone === '' ||
            this.state.email === '' ||
            this.state.origin_response === '' ||
            (this.state.origin_response=== 'Outros' && this.state.origin_response_other === '') ||
            this.state.cep === '' ||
          (this.state.userType === 'C' && this.state.cpf === '') ||
          (this.state.userType === 'I' && this.state.cnpj === '') ||
          this.state.password === '' ||
          this.state.confirmPassword === ''
        ) {
            this.setState({registerError : true});
          return;
        }
   
       if (this.state.password !== this.state.confirmPassword) {
         this.setState({registerError : true});
         return;
       }
     
       document.getElementById('load').classList.add('active');
       
       if (this.state.userType === 'C') {
         const data = new FormData();
         data.append('name', this.state.name);
         data.append('phone', this.state.phone);
         data.append('email', this.state.email);
         data.append('cpf', this.state.cpf.replace(/[.-]/g, ''));
         data.append('cep', this.state.cep);
         data.append('street', this.state.endereco.logradouro)
         data.append('district', this.state.endereco.bairro)
         data.append('complement', this.state.endereco.complemento)
         data.append('city', this.state.endereco.cidade)
         data.append('state', this.state.endereco.estado)
         data.append('password', this.state.password);
         data.append('type', this.state.userType)
         data.append('creci', this.state.creci)
         data.append('status', 3)
   
         Axios.post(
           process.env.REACT_APP_API + '/api/register/brokers',
           data,
           { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
         )
           .then((response) => {

            const data2 = new FormData()
            data2.append('user_id', response.data.id)
            data2.append('user_type', 'C')
            if(this.state.origin_response === 'Outros'){
                data2.append('response', this.state.origin_response_other)
            }else{
                data2.append('response', this.state.origin_response)
            }

            Axios.post(
                process.env.REACT_APP_API + '/api/register/new-feedback',
                data2,
                { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
              ).then((resp => {
                this.setState({ModalMap : false}); 
                this.setState({registerSuccess : true});
                document.getElementById('load').classList.remove('active');
              }))
           })
           .catch((error) => {
            this.setState({registerError : true});
            document.getElementById('load').classList.remove('active');
           });
       } else if (this.state.userType === 'I') {
         const data = new FormData();
         data.append('name', this.state.name);
         data.append('phone', this.state.phone);
         data.append('email', this.state.email);
         data.append('cnpj', this.state.cnpj.replace(/[.-\/]/g, '').replace('-', ''));
         data.append('cep', this.state.cep);
         data.append('street', this.state.endereco.logradouro)
         data.append('district', this.state.endereco.bairro)
         data.append('complement', this.state.endereco.complemento)
         data.append('city', this.state.endereco.cidade)
         data.append('state', this.state.endereco.estado)
         data.append('password', this.state.password);
         data.append('creci_j', this.state.creci_j)
         data.append('type', this.state.userType)
         data.append('status', 3)
        
         Axios.post(
           process.env.REACT_APP_API + '/api/register/real-estates',
           data,
           { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
         )
         .then((response) => {

            const data2 = new FormData()
            data2.append('user_id', response.data.id)
            data2.append('user_type', 'I')
            if(this.state.origin_response === 'Outros'){
                data2.append('response', this.state.origin_response_other)
            }else{
                data2.append('response', this.state.origin_response)
            }

            Axios.post(
                process.env.REACT_APP_API + '/api/register/new-feedback',
                data2,
                { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
              ).then((resp => {
                this.setState({ModalMap : false}); 
                this.setState({registerSuccess : true});
                document.getElementById('load').classList.remove('active');
              }))
           })
           .catch((error) => {
            this.setState({registerError : true});
            document.getElementById('load').classList.remove('active');
           });
       }
     };

     handleBrokerClick() {
        this.setState({userType : 'C'});
        // setImageSrc({ broker: BrokerActive, realState: RealStateNoActive });
      }
    
      handleRealStateClick() {
        this.setState({userType : 'I'});
        //   setImageSrc({ broker: BrokerNoActive, realState: RealStateActive });
      }
    
    //   useEffect(() => {
    //     setIsMobile(isMobileQuery);
    //   }, [this.isMobileQuery]);
    
    //   useEffect(() => {
    //     api
    //       .get('/api/public/get-count')
    //       .then((response) => {
    //         setProperties(response.data.properties);
    //       })
    //   }, [])
    
      handlePhoneChange = (phone) => {
        this.setState({phone: phone});
      };
    
      handleEmailChange = (newEmail) => {
        this.setState({email: newEmail});
      };
    
      handleEmailExists = (isExist) => {
        this.setState({emailIsExist: isExist});
      };
    
      handleCpfChange = (event) => {
        this.setState({cpf: event.target.value});
      };
    
      handleCnpjChange = (event) => {
        this.setState({cnpj: event.target.value});
      };
    
      handleCepChange = (value) => {
        this.setState({cep: value});
      };
    
      handleEnderecoChange = (value) => {
        this.setState({endereco: value});
      };

      handleCreciChange = (newCreci) => {
        this.setState({creci: newCreci});
      };
    
      handleCreciExists = (isExist) => {
        this.setState({creciIsExist: isExist});
      };

      handleCreciJChange = (newCreciJ) => {
        this.setState({creci_j: newCreciJ});
      };
    
      handleCreciJExists = (isExist) => {
        this.setState({creciJIsExist: isExist});
      };

      handleCpfChange = (newCpf) => {
        this.setState({cpf: newCpf});
      };
    
      handleCpfExists = (isExist) => {
        this.setState({cpfIsExist: isExist});
      };

      handleCnpjChange = (newCnpj) => {
        this.setState({cnpj: newCnpj});
      };
    
      handleCnpjExists = (isExist) => {
        this.setState({cnpjIsExist: isExist});
      };

      handleGoogleLogin = () => {
        // window.location.href = process.env.REACT_APP_API + '/api/login/facebook';
        window.location.href = process.env.REACT_APP_API + '/api/login/google';
      };

    render() {

        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={12}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={3}>
                                                <div>
                                                    <div className="text-center">
                                                    <div>
                                                        <Link to="/dashboard" class="">
                                                            <img src={logodark} alt="" height="20" class="auth-logo logo-dark mx-auto" />
                                                            <img src={logolight} alt="" height="20" class="auth-logo logo-light mx-auto" />
                                                        </Link>
                                                    </div>

                                                        <h4 className="font-size-18 mt-4">Bem vindo!</h4>
                                                        <p className="text-muted">Faça login para acessar a Fast Sale.</p>
                                                    </div>


                                                    {this.props.loginError && this.props.loginError ? 
                                                        <Modal
                                                            isOpen={this.state.modal_center}
                                                            toggle={this.tog_center}
                                                            centered={true}
                                                            >
                                                            <ModalHeader toggle={() => this.setState({ modal_center: false })}>
                                                                Houve um problema com sua tentativa de login:
                                                            </ModalHeader>
                                                            <ModalBody>
                                                            <p>{this.props.loginError}</p>
                                                            </ModalBody>
                                                        </Modal>
                                                    : null}

                                                    <span style={{display:'block', height:'20px'}}></span>

                                                    <AuthComponent/>


                                                    {/* <div className="p-2 mt-5">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit} >

                                                            <div className="auth-form-group-custom mb-4" style={{width:'310px', margin:'auto'}}>
                                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="username">Email</Label>
                                                                <AvField
                                                                    name="username"
                                                                    value={this.state.username}
                                                                    errorMessage="Campo obrigatório."
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="username"
                                                                    validate={{ email: true, required: true }}
                                                                    placeholder="E-mail"
                                                                />
                                                            </div>

                                                            <div className="auth-form-group-custom mb-4" style={{width:'310px', margin:'auto'}}>
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="userpassword">Senha</Label>
                                                                <AvField
                                                                    name="password"
                                                                    value={this.state.password}
                                                                    errorMessage="Campo obrigatório."
                                                                    type={!this.state.showPass ? "password" : "text"}
                                                                    className="form-control"
                                                                    id="userpassword"
                                                                    placeholder="Senha"
                                                                />
                                                                {!this.state.showPass ?
                                                                    <i className="show-pass ri-eye-line" onClick={() => this.setState({showPass: !this.state.showPass})}></i>                                                                
                                                                :
                                                                    <i className="show-pass ri-eye-off-line" onClick={() => this.setState({showPass: !this.state.showPass})}></i>
                                                                }
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Button
                                                                    color="primary"
                                                                    className="w-md waves-effect waves-light"
                                                                    type="submit"
                                                                    style={{width:'310px', fontSize:'16px'}}
                                                                >Entrar</Button>
                                                            </div>

                                                            <div
                                                                className="mt-4 text-center"
                                                                style={{display:'flex', justifyContent:'center'}}
                                                            >
                                                                <button
                                                                    className='google-login-button'
                                                                    onClick={this.handleGoogleLogin}
                                                                    style={{
                                                                        display:'flex',
                                                                        alignItems:'center',
                                                                        backgroundColor:'#fff',
                                                                        padding:'4px 8px 4px 4px',
                                                                        borderRadius:'4px',
                                                                        border:'solid 1px #cdcdcd',
                                                                        color:'#fff',
                                                                        fontSize:'16px',
                                                                        fontWeight:700,
                                                                        gap:'20px',
                                                                        width: '310px'
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={GoogleLogo}
                                                                        style={{
                                                                            width:'40px',
                                                                            height:'35px',
                                                                            paddingRight:'3px',
                                                                            marginRight:'15px',
                                                                            borderRight: 'solid 1px #333'
                                                                        }}
                                                                    />
                                                                    <p style={{color:'#333'}}>Entrar com o Google</p>
                                                                </button>
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Link to="/recuperar-senha" className="text-muted"><i className="mdi mdi-lock me-1"></i> Esqueceu a senha?</Link>
                                                            </div>
                                                        </AvForm>
                                                    </div> */}

                                                    <div className="mt-5 text-center">
                                                        <p>Ainda não tem conta? <Link to="/registre-se" className="fw-medium text-primary"> Registre-se </Link> </p>
                                                        <p>Fast Sale Imóveis</p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col> */}
                        </Row>
                    </Container>
                </div>
                <Whats/>
                {this.state.basic ? (
                    <SweetAlert
                        title="Credenciais inválidas."
                        onConfirm={() => this.setState({ basic: false })}
                    ></SweetAlert>
                ) : null}

                <Modal isOpen={this.state.modalMap} toggle={this.state.openModal} className={
                    !this.state.isMobile ? "modal-dialog-centered" : "modal-fullscreen"
                    } size='lg'>
                        <ModalHeader toggle={this.state.openModal}>
                        
                        </ModalHeader>
                        <ModalBody>
                        <div>
                        <div className={styles.modal_title}>Falta só mais um pouco para concluir seu cadastro!</div>
                            <div className={styles.container_input}>
                            <div className={styles.container_inputs_modal}>
                                <label>Nome</label>
                                <input 
                                type="text" 
                                value={this.state.name}
                                onChange={(e) => this.setState({name: e.target.value})}
                                required
                                />
                            </div>
                            <div className={styles.container_inputs_modal}>
                                <label className={styles.input_phone}>Telefone</label>
                                <PhoneInput phoneNumber={this.state.phone} onPhoneChange={this.handlePhoneChange} />
                            </div>

                            <div>
                            <div className={styles.container_inputs_modal}>
                                <label className={styles.input_phone}>Email</label>
                                <EmailInput onEmailChange={this.handleEmailChange} onExists={this.handleEmailExists}/>
                            </div>
                                <p className={styles.what_perfil}>Selecione seu perfil:</p>
                                <div className={styles.container_button_perfil}>
                                <div className={this.state.userType === 'C' ? styles.button_broken_active : styles.button_broken} onClick={this.handleBrokerClick}>
                                    {/* <img src={imageSrc.broker} alt='Broker Active' className={styles.icon_broker}/> */}
                                    Corretor
                                </div>

                                <div className={this.state.userType === 'I' ? styles.button_real_state_active : styles.button_real_state} onClick={this.handleRealStateClick}>
                                    {/* <img src={imageSrc.realState} alt="Real State Active" className={styles.icon_real_state}/> */}
                                    Imobiliária
                                </div>
                                </div>
                            </div>
                            {/* <div className={styles.container_inputs_modal_second}> */}
                            {this.state.userType === 'I' && (
                                <div className={styles.container_inputs_modal}>
                                    <label className={styles.input_phone}>CNPJ</label>
                                    <CnpjInput onCnpjChange={this.handleCnpjChange} onExists={this.handleCnpjExists}/>
                                </div>
                                )}
                            {this.state.userType === 'C' &&(
                            <div className={styles.container_inputs_modal}>
                                <label className={styles.input_phone}>CPF</label>
                                <CpfInput onCpfChange={this.handleCpfChange} onExists={this.handleCpfExists}/>
                            </div>
                            )}
                            {this.state.userType === 'C' && 
                                <div className={styles.container_inputs_modal}>
                                    <label className={styles.input_phone}>CRECI</label>
                                    <CreciInput onCreciChange={this.handleCreciChange} onExists={this.handleCreciExists}/>
                                </div>
                            }
                            {this.state.userType === 'I' && 
                                <div className={styles.container_inputs_modal}>
                                    <label className={styles.input_phone}>CRECI Jurídico</label>
                                    <CreciJInput onCreciJChange={this.handleCreciJChange} onExists={this.handleCreciJExists}/>
                                </div>
                            }
                            {/* </div> */}
                            <div className={styles.container_inputs_modal}>
                                <label className={styles.input_phone}>CEP</label>
                                <CepInput onCepChange={this.handleCepChange} onEnderecoChange={this.handleEnderecoChange} />
                            </div>

                            <div className={styles.container_inputs_modal} style={{width:'100%'}}>
                                <label className={styles.input_phone}>Como conheceu a Fast Sale?</label>
                                <select className="form-control" onChange={(e) => this.setState({origin_response: e.target.value})}>
                                    <option value=''>Selecione</option>
                                    <option value='Google'>Google</option>
                                    <option value='Instagram'>Instagram</option>
                                    <option value='Indicação'>Indicação</option>
                                    <option value='YouTube'>YouTube</option>
                                    <option value='Notícias'>Notícias</option>
                                    <option value='Outros'>Outros</option>
                                </select>
                            </div>

                            {this.state.origin_response === 'Outros' &&
                                <div className={styles.container_inputs_modal} style={{marginTop:'10px', width:'100%'}}>
                                    <textarea 
                                        type="textarea" 
                                        value={this.state.origin_response_other}
                                        style={{width:'100%', borderRadius:'5px', borderColor:"#cdcdcd", padding:'5px'}}
                                        rows="3"
                                        placeholder="Escreva como nos conheceu"
                                        onChange={(e) => this.setState({origin_response_other: e.target.value})}
                                    />
                                </div>
                            }

                            <div className={styles.container_inputs_modal}>
                                <label className={styles.input_phone}>Crie sua senha</label>
                                <input 
                                    type="password" 
                                    value={this.state.password}
                                    onChange={(e) => this.setState({password: e.target.value})}
                                    required
                                />
                            </div>
                            <div className={styles.container_inputs_modal}>
                                <label className={styles.input_phone}>Confirme sua senha</label>
                                <input
                                    type="password" 
                                    value={this.state.confirmPassword}
                                    onChange={(e) => this.setState({confirmPassword: e.target.value})}
                                    required
                                />
                            </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                            {this.state.userType === 'C' &&
                                ((this.state.emailIsExist === ''
                                && this.state.cpfIsExist === ''
                                && this.state.creciIsExist === '') ?
                                <Button
                                id="finalizar-cadastro" 
                                className={styles.button_confirm}
                                color="success" onClick={this.createRegister}
                                >
                                Cadastre-se gratuitamente
                                </Button>
                                :
                                <Button
                                id="finalizar-cadastro" 
                                className={styles.button_confirm}
                                color="success"
                                disabled
                                >
                                Cadastre-se gratuitamente
                                </Button>)
                            }
                            {this.state.userType === 'I' &&
                                ((this.state.emailIsExist === ''
                                && this.state.cnpjIsExist === ''
                                && this.state.creciJIsExist === '') ?
                                <Button
                                id="finalizar-cadastro" 
                                className={styles.button_confirm}
                                color="success" onClick={this.createRegister}
                                >
                                Cadastre-se gratuitamente
                                </Button>
                                :
                                <Button
                                id="finalizar-cadastro" 
                                className={styles.button_confirm}
                                color="success"
                                disabled
                                >
                                Cadastre-se gratuitamente
                                </Button>)
                            }

                        </div>
                        </ModalBody>
                    </Modal>
                    
                    {this.state.registerError && (
                        <SweetAlert 
                        warning 
                        onConfirm={() => this.setState({registerError: false})}
                        >
                        {this.state.userType === '' &&  <p>Por favor, informe seu perfil.</p>}
                        {this.state.password !== this.state.confirmPassword &&  <p>Sua senha e confirmação da senha não coincidem.</p>}
                        {(this.state.email === '' || (this.state.origin_response=== 'Outros' && this.state.origin_response_other === '') || this.state.origin_response === '' || this.state.phone === '' || this.state.name === '' || this.state.cep === '') &&  <p>Por favor, preencha todos os campos.</p>}
                        </SweetAlert>
                    )}

                    {this.state.registerSuccess && (
                        <SweetAlert
                        success
                        confirmBtnText="Fazer login"
                        onConfirm={() => {this.setState({registerSuccess: false}); window.open("/login", '_parent')}}
                        >
                        <p style={{ fontWeight: 600, fontSize: '1.2rem' }}>Cadastro realizado com sucesso!</p>
                        </SweetAlert>
                    )}  

                    <div id="load" className="load">
                        <Spinner className="me-2" color="success" />
                    </div>
            </React.Fragment>
        );
    }
}

// const mapStatetoProps = () => {
//     this.setState({basic: true})
// }

const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
    this.setState({basic: true})
}


export default withRouter(connect(mapStatetoProps, { checkLogin, apiError })(Login));