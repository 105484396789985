import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import './styles.scss';
import { useState } from 'react';
import Logo from "../../../assets/images/logo-dark.png";
import { Button, Card, Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import PasswordChecklist from "react-password-checklist";
import Axios from 'axios';
import { Link } from "react-router-dom";
import Dropzone from 'react-dropzone';

export const RegisterStep4 = ({userData, newCallback}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const user = userData?.userId || searchParams.get('user');
  const type = userData?.userType || searchParams.get('type');
  const token = userData?.token || searchParams.get('token');

  const [loading, setLoading] = useState(false)

  const [registerError, setRegisterError] = useState(false)

  const [selectedRg, setSelectedRg] = useState([])
  const [selectedCnpj, setSelectedCnpj] = useState([])

  const history = useHistory()

  const sendRegister = () => {
    setLoading(true)
    const url = type === 'C' ? '' : type === 'I' ? '' : type === 'T' ? 'construction-lead-update-docs/' : ''

    console.log(selectedCnpj)
    console.log(selectedRg)

    const data = new FormData()
    data.append('photo_cnpj', selectedCnpj[0])
    data.append('photo_owner_rg', selectedRg[0])
    
    Axios.post(
      process.env.REACT_APP_API + '/api/register/' + url + user,
      data,
      { headers: { Authorization: 'Bearer ' + token } }
    )
      .then((response) => {
        if (newCallback) {
          newCallback(5)
        } else {
          history.push('/agradecimento-cadastro-construtora')
        }
      })
      .catch((error) => {
        setLoading(false)
        setRegisterError(true)
      });
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleAcceptedDocs = files => {
    files.map(file => {
      let preview;
      if (file.type === 'application/pdf') {
        preview = null; 
      } else {
        preview = URL.createObjectURL(file);
      }
  
      return Object.assign(file, {
        preview,
        fileType: file.type,
        formattedSize: formatBytes(file.size)
      });
    });
  
    setSelectedCnpj(files)
  };

  const handleAcceptedDocsRg = files => {
    files.map(file => {
      let preview;
      if (file.type === 'application/pdf') {
        preview = null; 
      } else {
        preview = URL.createObjectURL(file);
      }
  
      return Object.assign(file, {
        preview,
        fileType: file.type,
        formattedSize: formatBytes(file.size)
      });
    });
  
    setSelectedRg(files);
  };

  return(
      <div className="register-section">
        <div className="register-wrapper">
          {loading ? 
            <Spinner className="me-2" color="success" />
          :
          <Container>
            <Row>
              <Col className="logo-container"><img src={Logo} alt="Fast Sale"/></Col>
            </Row>
            <Row className="step-title">
              <Col xs={12}>
                <h1>Envie seus documentos</h1>
                <h3>Passo 4 de 4</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Dropzone
                  onDrop={acceptedFiles =>
                  handleAcceptedDocs(acceptedFiles)}
                  accept="image/*"
                  maxFiles={1}
                  maxSize={5000000}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                        <div className="dz-message needsclick" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                        </div>
                        <h4 style={{fontSize:'18px'}}>Adicione aqui o cartão cnpj.</h4>
                        </div>
                    </div>
                  )}
                </Dropzone>
                    <div
                        className="dropzone-previews mt-3"
                        id="auth-previews"
                    >
                    {selectedCnpj.map((f, i) => {
                    return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                        <div className="p-2">
                            <Row className="align-items-center">
                            <Col className="col-auto">
                                {f.fileType === 'application/pdf' ? (
                                <i className="ri-file-search-fill" style={{fontSize:'40px', color:'#333'}}></i>
                                ) : (
                                <img
                                    data-dz-thumbnail=""
                                    height="40"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                />
                                )}
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted fw-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </Col>
              <Col xs={12}>
                <Dropzone
                  onDrop={acceptedFiles =>
                  handleAcceptedDocsRg(acceptedFiles)}
                  accept="image/*"
                  maxFiles={1}
                  maxSize={5000000}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                      <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                      </div>
                      <h4 style={{fontSize:'18px'}}>Adicione aqui uma foto do RG ou CNH do proprietário.</h4>
                      </div>
                  </div>
                  )}
                </Dropzone>
                  <div
                    className="dropzone-previews mt-3"
                    id="auth-previews"
                  >
                    {selectedRg.map((f, i) => {
                    return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                        <div className="p-2">
                            <Row className="align-items-center">
                            <Col className="col-auto">
                                {f.fileType === 'application/pdf' ? (
                                <i className="ri-file-search-fill" style={{fontSize:'40px', color:'#333'}}></i>
                                ) : (
                                <img
                                    data-dz-thumbnail=""
                                    height="40"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                />
                                )}
                            </Col>
                            <Col>
                                <Link
                                  to="#"
                                  className="text-muted fw-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                            </Col>
                            </Row>
                        </div>
                      </Card>
                      )
                    })}
                  </div>
              </Col>
            </Row>
            <Row className="send-container">
              <Col xs={12}>
                {!(selectedRg.length > 0 && selectedCnpj.length > 0) ?
                    <Button disabled>Os documentos são obrigatórios</Button>
                  :
                    <Button onClick={sendRegister}>Finalizar</Button>
                }
              </Col>
            </Row>
          </Container>
          }
        </div>
  
        {registerError ? (
          <SweetAlert
            title="Seu cadastro falhou, fale com nosso suporte."
            showCloseButton={true}
            showConfirm={true}
            error
            onConfirm={() => {
              setTimeout(() => {
                window.open('https://wa.me/554741081865', '_blank')
              }, 0);
            }}
          ></SweetAlert>
        ) : null}
  
      </div>
  )
}