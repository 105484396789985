import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";


class RevenueAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
        series: [{
          name: new Date().getFullYear(),
          type: 'column',
          data: []
        }, {
          name: new Date().getFullYear() - 1,
          type: 'line',
          data: []
        }],
        options: {
            chart: {
                toolbar: {
                    show: false,
                }
            },
            stroke: {
                width: [0, 3],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '20%',
                },
            },
            dataLabels: {
                enabled: false,
            },

            legend: {
                show: false,
            },
            colors: ['#5664d2', '#1cbb8c'],
            labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            // labels:['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12','13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
        },
            janAtual:0,
            fevAtual:0,
            marAtual:0,
            abrAtual:0,
            maiAtual:0,
            junAtual:0,
            julAtual:0,
            agoAtual:0,
            setAtual:0,
            outAtual:0,
            novAtual:0,
            dezAtual:0,
            janPassado:0,
            fevPassado:0,
            marPassado:0,
            abrPassado:0,
            maiPassado:0,
            junPassado:0,
            julPassado:0,
            agoPassado:0,
            setPassado:0,
            outPassado:0,
            novPassado:0,
            dezPassado:0,
            mesAtual:[],
            mesPassado:[],
            semanaAtual:[],
            semanaPassada:[],
        }
        this.changeViewsYear = this.changeViewsYear.bind(this)
        this.changeViewsMonth = this.changeViewsMonth.bind(this)
        this.changeViewsWeek = this.changeViewsWeek.bind(this)
    }

     componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.changeViewsWeek()
        }
    }

    // componentDidMount(){
    //     setTimeout(() => {
    //         this.changeViewsWeek()
    //     }, 1000);
    // }


        changeViewsYear(){

            this.setState({ series: [{
                name: new Date().getFullYear(),
                type: 'column',
                data: []
            }, {
                name: new Date().getFullYear() - 1,
                type: 'line',
                data: []
            }]})

            if(this.props.ano_atual?.[1] && this.props.ano_atual?.[1] !== 0){
                 let janAtual = this.state.janAtual;
                 const valores = Object.values(this.props.ano_atual[1]);
                 for (let i = 0; i < valores.length; i++) {
                  janAtual += parseInt(valores[i]);
                 }
                 this.setState({janAtual});
           }
           if(this.props.ano_atual?.[2] && this.props.ano_atual?.[2] !== 0){
                let fevAtual = this.state.fevAtual;
                const valores = Object.values(this.props.ano_atual[2]);
                for (let i = 0; i < valores.length; i++) {
                 fevAtual += parseInt(valores[i]);
                }
                this.setState({fevAtual});
          }
          if(this.props.ano_atual?.[3] && this.props.ano_atual?.[3] !== 0){
            let marAtual = this.state.marAtual;
            const valores = Object.values(this.props.ano_atual[3]);
            for (let i = 0; i < valores.length; i++) {
             marAtual += parseInt(valores[i]);
            }
            this.setState({marAtual});
           }
           if(this.props.ano_atual?.[4] && this.props.ano_atual?.[4] !== 0){
               let abrAtual = this.state.abrAtual;
               const valores = Object.values(this.props.ano_atual[4]);
               for (let i = 0; i < valores.length; i++) {
                abrAtual += parseInt(valores[i]);
               }
               this.setState({abrAtual});
           }
           if(this.props.ano_atual?.[5] && this.props.ano_atual?.[5] !== 0){
               let maiAtual = this.state.maiAtual;
               const valores = Object.values(this.props.ano_atual[5]);
               for (let i = 0; i < valores.length; i++) {
                maiAtual += parseInt(valores[i]);
               }
               this.setState({maiAtual});
           }
           if(this.props.ano_atual?.[6] && this.props.ano_atual?.[6] !== 0){
               let junAtual = this.state.junAtual;
               const valores = Object.values(this.props.ano_atual[6]);
               for (let i = 0; i < valores.length; i++) {
                junAtual += parseInt(valores[i]);
               }
               this.setState({junAtual});
           }
           if(this.props.ano_atual?.[7] && this.props.ano_atual?.[7] !== 0){
               let julAtual = this.state.julAtual;
               const valores = Object.values(this.props.ano_atual[7]);
               for (let i = 0; i < valores.length; i++) {
                julAtual += parseInt(valores[i]);
               }
               this.setState({julAtual});
           }
           if(this.props.ano_atual?.[8] && this.props.ano_atual?.[8] !== 0){
               let agoAtual = this.state.agoAtual;
               const valores = Object.values(this.props.ano_atual[8]);
               for (let i = 0; i < valores.length; i++) {
                agoAtual += parseInt(valores[i]);
               }
               this.setState({agoAtual});
           }
           if(this.props.ano_atual?.[9] && this.props.ano_atual?.[9] !== 0){
               let setAtual = this.state.setAtual;
               const valores = Object.values(this.props.ano_atual[9]);
               for (let i = 0; i < valores.length; i++) {
                setAtual += parseInt(valores[i]);
               }
               this.setState({setAtual});
           }
           if(this.props.ano_atual?.[10] && this.props.ano_atual?.[10] !== 0){
               let outAtual = this.state.outAtual;
               const valores = Object.values(this.props.ano_atual[10]);
               for (let i = 0; i < valores.length; i++) {
                outAtual += parseInt(valores[i]);
               }
               this.setState({outAtual});
           }
           if(this.props.ano_atual?.[11] && this.props.ano_atual?.[11] !== 0){
               let novAtual = this.state.novAtual;
               const valores = Object.values(this.props.ano_atual[11]);
               for (let i = 0; i < valores.length; i++) {
                novAtual += parseInt(valores[i]);
               }
               this.setState({novAtual});
           }
           if(this.props.ano_atual?.[12] && this.props.ano_atual?.[12] !== 0){
               let dezAtual = this.state.dezAtual;
               const valores = Object.values(this.props.ano_atual[12]);
               for (let i = 0; i < valores.length; i++) {
                dezAtual += parseInt(valores[i]);
               }
               this.setState({dezAtual});
           }


           if(this.props.ano_passado?.[1] && this.props.ano_passado?.[1] !== 0){
                let janPassado = this.state.janPassado;
                const valores = Object.values(this.props.ano_passado[1]);
                for (let i = 0; i < valores.length; i++) {
                  janPassado += parseInt(valores[i]);
                }
                this.setState({janPassado});
          }
          if(this.props.ano_passado?.[2] && this.props.ano_passado?.[2] !== 0){
            let fevPassado = this.state.fevPassado;
            const valores = Object.values(this.props.ano_passado[2]);
            for (let i = 0; i < valores.length; i++) {
                fevPassado += parseInt(valores[i]);
            }
            this.setState({fevPassado});
         }
         if(this.props.ano_passado?.[3] && this.props.ano_passado?.[3] !== 0){
           let marPassado = this.state.marPassado;
           const valores = Object.values(this.props.ano_passado[3]);
           for (let i = 0; i < valores.length; i++) {
             marPassado += parseInt(valores[i]);
           }
           this.setState({marPassado});
          }
          if(this.props.ano_passado?.[4] && this.props.ano_passado?.[4] !== 0){
              let abrPassado = this.state.abrPassado;
              const valores = Object.values(this.props.ano_passado[4]);
              for (let i = 0; i < valores.length; i++) {
                abrPassado += parseInt(valores[i]);
              }
              this.setState({abrPassado});
          }
          if(this.props.ano_passado?.[5] && this.props.ano_passado?.[5] !== 0){
              let maiPassado = this.state.maiPassado;
              const valores = Object.values(this.props.ano_passado[5]);
              for (let i = 0; i < valores.length; i++) {
                maiPassado += parseInt(valores[i]);
              }
              this.setState({maiPassado});
          }
          if(this.props.ano_passado?.[6] && this.props.ano_passado?.[6] !== 0){
              let junPassado = this.state.junPassado;
              const valores = Object.values(this.props.ano_passado[6]);
              for (let i = 0; i < valores.length; i++) {
                junPassado += parseInt(valores[i]);
              }
              this.setState({junPassado});
          }
          if(this.props.ano_passado?.[7] && this.props.ano_passado?.[7] !== 0){
              let julPassado = this.state.julPassado;
              const valores = Object.values(this.props.ano_passado[7]);
              for (let i = 0; i < valores.length; i++) {
                julPassado += parseInt(valores[i]);
              }
              this.setState({julPassado});
          }
          if(this.props.ano_passado?.[8] && this.props.ano_passado?.[8] !== 0){
              let agoPassado = this.state.agoPassado;
              const valores = Object.values(this.props.ano_passado[8]);
              for (let i = 0; i < valores.length; i++) {
                agoPassado += parseInt(valores[i]);
              }
              this.setState({agoPassado});
          }
          if(this.props.ano_passado?.[9] && this.props.ano_passado?.[9] !== 0){
              let setPassado = this.state.setPassado;
              const valores = Object.values(this.props.ano_passado[9]);
              for (let i = 0; i < valores.length; i++) {
                setPassado += parseInt(valores[i]);
              }
              this.setState({setPassado});
          }
          if(this.props.ano_passado?.[10] && this.props.ano_passado?.[10] !== 0){
              let outPassado = this.state.outPassado;
              const valores = Object.values(this.props.ano_passado[10]);
              for (let i = 0; i < valores.length; i++) {
                outPassado += parseInt(valores[i]);
              }
              this.setState({outPassado});
          }
           if(this.props.ano_passado?.[11] && this.props.ano_passado?.[11] !== 0){
               let novPassado = this.state.novPassado;
               const valores = Object.values(this.props.ano_passado[11]);
               for (let i = 0; i < valores.length; i++) {
                 novPassado += parseInt(valores[i]);
               }
               this.setState({novPassado});
           }
          if(this.props.ano_passado?.[12] && this.props.ano_passado?.[12] !== 0){
              let dezPassado = this.state.dezPassado;
              const valores = Object.values(this.props.ano_passado[12]);
              for (let i = 0; i < valores.length; i++) {
                dezPassado += parseInt(valores[i]);
              }
              this.setState({dezPassado});
          }

          setTimeout(() => {
            this.setState({ series: [{
                name: new Date().getFullYear(),
                type: 'column',
                data: [this.state.janAtual, this.state.fevAtual, this.state.marAtual, this.state.abrAtual, this.state.maiAtual, this.state.junAtual, this.state.julAtual, this.state.agoAtual, this.state.setAtual, this.state.outAtual, this.state.novAtual, this.state.dezAtual]
              }, {
                name: new Date().getFullYear() - 1,
                type: 'line',
                data: [this.state.janPassado, this.state.fevPassado, this.state.marPassado, this.state.abrPassado, this.state.maiPassado, this.state.junPassado, this.state.julPassado, this.state.agoPassado, this.state.setPassado, this.state.outPassado, this.state.novPassado, this.state.dezPassado]
              }]})
          }, 500);


              this.setState({options: {
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
                stroke: {
                    width: [0, 3],
                    curve: 'smooth'
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '20%',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
    
                legend: {
                    show: false,
                },
                colors: ['#5664d2', '#1cbb8c'],
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            }})
        }

        changeViewsMonth(){
            this.state.mesAtual = []
            this.state.mesPassado = []

            if(this.props.ano_atual?.[(new Date().getMonth() + 1)]){
                for (const [key, value] of Object.entries(this.props.ano_atual?.[(new Date().getMonth() + 1)])) {
                    this.state.mesAtual.push(parseInt(`${value}`))
           }}

           if(this.props.ano_atual?.[(new Date().getMonth())]){
            for (const [key, value] of Object.entries(this.props.ano_atual?.[(new Date().getMonth())])) {
                this.state.mesPassado.push(parseInt(`${value}`))
            }}

            this.setState({ series: [{
                name: 'Atual',
                type: 'column',
                data: this.state.mesAtual
            }, {
                name: 'Anterior',
                type: 'line',
                data: this.state.mesPassado
            }]})

            this.setState({options: {
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
                stroke: {
                    width: [0, 3],
                    curve: 'smooth'
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '20%',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
    
                legend: {
                    show: false,
                },
                colors: ['#5664d2', '#1cbb8c'],
                labels:  ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12','13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
            }})
        }

        changeViewsWeek(){
            this.state.semanaAtual = []
            this.state.semanaPassada = []
            this.state.mesAtual = []
            this.state.mesPassado = []

            const today = new Date()
            const lastWeekDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
            const lastMonthDay = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
          
            if (this.props.ano_atual?.[today.getMonth() + 1]) {
              for (const [key, value] of Object.entries(this.props.ano_atual[today.getMonth() + 1])) {
                if (parseInt(key) <= today.getDate() && parseInt(key) > today.getDate() - 7) {
                  this.state.semanaAtual.push(parseInt(`${value}`))
                }
                if (parseInt(key) <= lastWeekDay.getDate() && parseInt(key) > lastWeekDay.getDate() - 7) {
                  this.state.semanaPassada.push(parseInt(`${value}`))
                }
                if (parseInt(key) > today.getDate() - 7) {
                  this.state.mesAtual.push(parseInt(`${value}`))
                }
              }
            }
          
            if (this.props.ano_atual?.[lastMonthDay.getMonth() + 1]) {
              for (const [key, value] of Object.entries(this.props.ano_atual[lastMonthDay.getMonth() + 1])) {
                if (parseInt(key) <= lastWeekDay.getDate() && parseInt(key) > lastWeekDay.getDate() - 7) {
                  this.state.semanaPassada.push(parseInt(`${value}`))
                }
                if (parseInt(key) > lastWeekDay.getDate() - 7) {
                  this.state.mesPassado.push(parseInt(`${value}`))
                }
              }
            }
            
            if(parseInt(new Date().getDate() - 14) < 1 && parseInt(new Date().getDate() - 14) > (-7)){
                const semAtual = this.state.mesAtual.slice((this.state.mesAtual.length - 7), this.state.mesAtual.length)
                const semPassada = (this.state.mesPassado.slice((this.state.mesPassado.length - (parseInt(new Date().getDate() - 14) * -1)), this.state.mesPassado.length)).concat(this.state.mesAtual.slice(this.state.mesAtual.length >= 14 ? (this.state.mesAtual.length - 14) : 0, (this.state.mesAtual.length - 7)))

                semAtual.map((dia, index) => {
                    this.state.semanaAtual.push(dia)
                })
                semPassada.map((dia, index) => {
                    this.state.semanaPassada.push(dia)
                })
            }else if (parseInt(new Date().getDate() - 14) < (-7)){
                const semAtual = this.state.mesPassado.slice((this.state.mesPassado.length - (this.state.mesPassado.length - (this.state.mesPassado.length - (7 - this.state.mesAtual.length)))), this.state.mesPassado.length)
                const semPassada = this.state.mesPassado.slice(0, (this.state.mesPassado.length - (this.state.mesPassado.length - (this.state.mesPassado.length - (7 - this.state.mesAtual.length)))))
                
                this.state.semanaAtual = []
                this.state.semanaPassada = []

                semAtual.map((dia, index) => {
                     this.state.semanaAtual.push(dia)
                })

                semPassada.map((dia, index) => {
                    this.state.semanaPassada.push(dia)
                })

                 this.state.mesAtual.map((dia, index) => {
                     this.state.semanaAtual.push(dia)
                })

            }else{
                const semAtual = this.state.mesAtual.slice((this.state.mesAtual.length - 7), this.state.mesAtual.length)
                const semPassada = (this.state.mesAtual.slice((this.state.mesAtual.length - 14), (this.state.mesAtual.length - 7)))

                semAtual.map((dia, index) => {
                    this.state.semanaAtual.push(dia)
                })
                semPassada.map((dia, index) => {
                    this.state.semanaPassada.push(dia)
                })
            }

            this.setState({ series: [{
                name: 'Atual',
                type: 'column',
                data: this.state.semanaAtual
            }, {
                name: 'Anterior',
                type: 'line',
                data: this.state.semanaPassada
            }]})


            if(new Date().getDay() === 0){
                var weekLabel = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom']
            }
            if(new Date().getDay() === 1){
                var weekLabel = ['Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom', 'Seg']
            }
            if(new Date().getDay() === 2){
                var weekLabel = ['Qua', 'Qui', 'Sex', 'Sáb', 'Dom', 'Seg', 'Ter']
            }
            if(new Date().getDay() === 3){
                var weekLabel = ['Qui', 'Sex', 'Sáb', 'Dom', 'Seg', 'Ter', 'Qua']
            }
            if(new Date().getDay() === 4){
                var weekLabel = ['Sex', 'Sáb', 'Dom', 'Seg', 'Ter', 'Qua', 'Qui']
            }
            if(new Date().getDay() === 5){
                var weekLabel = ['Sáb', 'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex']
            }
            if(new Date().getDay() === 6){
                var weekLabel = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
            }


            this.setState({options: {
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
                stroke: {
                    width: [0, 3],
                    curve: 'smooth'
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '20%',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
    
                legend: {
                    show: false,
                },
                colors: ['#5664d2', '#1cbb8c'],
                labels:  weekLabel,
            }})
        }


    render() {
        return (
            <React.Fragment>
                <Card style={{ maxHeight: "400px", minHeight: "400px", overflow: 'hidden auto' }}>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" type="button" onClick={this.changeViewsWeek}>Semana</Button>
                                <Button size="sm" color="light" active type="button" onClick={this.changeViewsMonth}>Mês</Button>
                                <Button size="sm" color="light" type="button" onClick={this.changeViewsYear} id="yearFilter">Ano</Button>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-4">Visualizações dos seus imóveis</h4>
                        <div>
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="280" />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default RevenueAnalytics;