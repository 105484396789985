import { useState } from 'react';
import { Draggable } from 'react-drag-reorder';
import { Row } from 'reactstrap';

const UnitsPlansOrder= ({imoveis,onFormDataChange, deleteImage, count}) => {
  const [update, setUpdate] = useState([]);
  const [orders, setOrders] = useState([]);

  function getChangedPos(currentPos, newPos) {
    const id = window.location.search.replace('?', '');

    const listUpdate = [];
    const listOrders = [];


    setTimeout(() => {
      const listImages = document.querySelectorAll('.plan');
      for (var i = 0; i < listImages.length; i++) {
        listUpdate.push( listImages[i].id )
        listOrders.push( i )
      }
      setOrders(listOrders)
      setUpdate(listUpdate)
      
      onFormDataChange(listOrders, listUpdate)
    }, 300);
  };


  return (
    <>
      <Row className="imagesContainer" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', display:'grid'}}>
      {imoveis &&
        <Draggable key={count} onPosChange={getChangedPos}>
          {imoveis?.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1).map((photo, index) => (
            <div className="container-images">
              <div className="propertyImage">
                <a href={photo?.url} target="_blank" rel="noreferrer">
                  <img id={photo?.id}  className="plan" src={photo?.url}></img>
                </a>
                <i className="ri-close-fill" style={{
                  position:'absolute',
                  top:'8px',
                  right:'8px',
                  zIndex:'99',
                  backgroundColor:'#da2c3e',
                  width:'20px',
                  height:'20px',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  color:'#fff',
                  cursor:'pointer',
                }} onClick={() => deleteImage(photo.id)}></i>
              </div>
            </div>
          ))}
        </Draggable>
        }
      </Row>
    </>
  )
};

export default UnitsPlansOrder;


