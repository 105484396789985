import axios from 'axios';
import Swal from 'sweetalert2';
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper"

// Gets the logged in user data from local session 
const getLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    if (user)
        return JSON.parse(user);
    return null;
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

// Register Method
const postRegister = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }
        }
        throw message;
    });
}

// Login Method
const postLogin = async (url, data) => {
    try {
        const response = await axios.post(url, data);
        localStorage.setItem("authUser", JSON.stringify(response));
        localStorage.setItem('access', response.data.type)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('id', response.data.id)
        localStorage.setItem('pageActive', response.data.page_active)
        localStorage.setItem('user_name', response.data.user_name)
        localStorage.setItem('level', response.data.level)
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    } catch (err) {
        if(err.response.data === 'no_docs'){
            const message = "Seu acesso foi bloqueado por falta de documentos.";
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: message,
                confirmButtonColor: '#3E5E3F',
                confirmButtonText: 'Enviar documentos agora'
            }).then((result) => {
                setTimeout(() => {
                    window.open('/enviar-documentos', '_parent')
                }, 100);
            })
        }else{
            // if(err.response.data.error === 'Senha')
            const message = err.response.data.error;
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: message,
                confirmButtonColor: '#3E5E3F',
                confirmButtonText: 'Ok'
            });
        }
        throw err;
    }
}

// postForgetPwd 
const postForgetPwd = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

// get Events
export const getEvents = () =>
    get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
    del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

export { getLoggedInUser, isUserAuthenticated, postRegister, postLogin, postForgetPwd }