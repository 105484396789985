import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

const id = window.location.search.replace('?id=', '')

class EnterpriseAddress extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      selectedUf: null,
      selectedCity: null,
      selectOptionsUf: [],
      statesAddress: [],
      selectOptionsCity: [],
      stateSelected: '',
      citySelected:'',
      cep:'',
      logradouro:'',
      complemento:'',
      numero:'',
      bairro:'',
      pais:'Brasil',
      }
      this.updateEnterprise = this.updateEnterprise.bind(this);
    }

    componentDidMount(){
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {

        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            this.setState({enterprise: response.data})

            this.setState({
                cep: response.data.cep,
                complemento: response.data.complement,
                pais: response.data.country,
                bairro: response.data.district,
                numero: response.data.number,
                logradouro: response.data.street,
              })
    
              this.setState({stateSelected: {
                value:response.data.state,
                label:response.data.state
              }})
              this.setState({citySelected: {
                value:response.data.city,
                label:response.data.city
              }});

              Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados',)
              .then(response => {
                const states = []
                for(var i = 0; response.data.length > i; i++){
                  states.push({
                    value: response.data[i].sigla,
                    label: response.data[i].nome
                  })
                }
                this.setState({statesAddress: states})
              })
         })

        })
        .catch(response =>{
            setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
            })  
    }

    consultaCep = cepValue => {
        const cep = cepValue
          if (cep?.length !== 8) {
          this.setState({logradouro: ''});
          this.setState({bairro: ''});
          this.setState({stateSelected: ''})
          this.setState({citySelected: ''});
          }else{
            fetch('https://viacep.com.br/ws/' + cep + '/json/')
            .then((res) => res.json())
            .then((data) => {
                if (data.erro === true) {
                  this.setState({logradouro: ''});
                  this.setState({bairro: ''});
                  this.setState({stateSelected: ''})
                  this.setState({citySelected: ''});
                } else {
                  this.setState({logradouro: data.logradouro});
                  this.setState({bairro: data.bairro});
                  this.setState({stateSelected: {
                    value:data.uf,
                    label:data.uf
                  }})
                  this.setState({citySelected: {
                    value:data.localidade,
                    label:data.localidade
                  }});
                }
              })
          }  
      }

      updateEnterprise(event) {
        document.getElementById('load').classList.add('active')

        const data = new FormData();
        data.append('cep', this.state.cep)
        data.append('street', this.state.logradouro)
        data.append('number', this.state.numero)
        data.append('district', this.state.bairro)
        data.append('complement', this.state.complemento)
        data.append('state', this.state.stateSelected.value)
        data.append('city', this.state.citySelected.value)
        data.append('country', 'Brasil')

        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/'+ id, data, {
        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
            document.getElementById('load').classList.remove('active')
        })
   }

    render(){
        const {enterprise, statesAddress, cityAddress, citySelected, stateSelected } = this.state;

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <AvForm className="cardBody needs-validation"  ref={this.validate2Ref}>
                                        <Container style={{marginTop:'30px'}}>
                                            <Row style={{margin:'10px 0'}}>
                                            <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Endereço</h5></Col>
                                            </Row>
                                            <Row>
                                            <Col md={4}>
                                                <Label htmlFor="cep" className="col-md-12 col-form-label">Cep</Label>
                                                <AvField
                                                name="cep"
                                                placeholder="Cep"
                                                type="text"
                                                errorMessage="Campo obrigatório."
                                                className="form-control"
                                                value={this.state.cep}
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    this.consultaCep(e.target.value.replace('-', '').replace('.', ''))
                                                    this.setState({cep: e.target.value})
                                                }}
                                                id="cep"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Label htmlFor="rua" className="col-md-12 col-form-label">Rua</Label>
                                                <AvField
                                                name="rua"
                                                placeholder="Rua, Avenida..."
                                                type="text"
                                                errorMessage="Campo obrigatório."
                                                className="form-control"
                                                value={this.state.logradouro}
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    this.setState({logradouro: e.target.value})
                                                }}
                                                id="rua"
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <Label htmlFor="numero" className="col-md-12 col-form-label">Número</Label>
                                                <AvField
                                                name="numero"
                                                placeholder="Número"
                                                type="text"
                                                errorMessage="Campo obrigatório."
                                                className="form-control"
                                                value={this.state.numero}
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    this.setState({numero: e.target.value})
                                                }}
                                                id="numero"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Label htmlFor="bairro" className="col-md-12 col-form-label">Bairro</Label>
                                                <AvField
                                                name="bairro"
                                                placeholder="Bairro"
                                                type="text"
                                                errorMessage="Campo obrigatório."
                                                className="form-control"
                                                value={this.state.bairro}
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    this.setState({bairro: e.target.value})
                                                }}
                                                id="bairro"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Label htmlFor="complemento" className="col-md-12 col-form-label">Complemento</Label>
                                                <Input type="text" placeholder="Complemento" id="complemento" value={this.state.complemento} onChange={(e) => this.setState({complemento: e.target.value})} />
                                            </Col>
                                            <Col md={4}>
                                                <Label htmlFor="estado" className="col-md-12 col-form-label">Estado</Label>
                                                <Select
                                                value={stateSelected}
                                                placeholder="Selecione"
                                                noOptionsMessage={(inputValue) => `Sem resultados`}
                                                onChange={this.handleSelectState}
                                                options={statesAddress}
                                                classNamePrefix="select2-selection"
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Label htmlFor="cidade" className="col-md-12 col-form-label">Cidade</Label>
                                                <Select
                                                value={citySelected}
                                                placeholder="Selecione"
                                                noOptionsMessage={(inputValue) => `Sem resultados`}
                                                onChange={(e) => {
                                                    this.setState({ citySelected: e})
                                                }}
                                                options={cityAddress}
                                                classNamePrefix="select2-selection"
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Label htmlFor="pais" className="col-md-12 col-form-label">País</Label>
                                                <AvField
                                                name="pais"
                                                placeholder="País"
                                                type="text"
                                                errorMessage="Campo obrigatório."
                                                className="form-control"
                                                value={this.state.pais}
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    this.setState({pais: e.target.value})
                                                }}
                                                id="pais"
                                                />
                                            </Col>
                                            </Row>
                                        </Container>
                                        <Container>
                                        <   Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                                <Col className="col-auto">
                                                    <Button className="btn-dark" onClick={() => {
                                                        setTimeout(() => {
                                                            window.open('./../empreendimento?id=' + id, '_parent')
                                                        }, 100);
                                                    }}>Voltar</Button>
                                                </Col>
                                                <Col className="col-auto">
                                                    <Button onClick={this.updateEnterprise}>Salvar</Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>
            </div>
        )
    }

}

export default EnterpriseAddress;