import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, CardTitle, CardText, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_integration.scss'

import moment from 'moment';
import { saveAs } from 'file-saver';
import ReactSelect from 'react-select';
import Select from "react-select";
import SweetAlert from 'react-bootstrap-sweetalert';
require('moment/locale/pt.js');


class PartnershipList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      accepted:[],
      receive:[],
      filteredItems:[],
      viewportRecebidos:[
        {
          dataField: 'mobile',
          text: 'Contratos'
        },
      ],
      viewportAceitos:[
        {
          dataField: 'mobile',
          text: 'Contratos'
        },
      ],
      filteredAceitos:[],
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Parcerias", link: "#" },
    ],
    }
  }

  componentDidMount(){
    document.getElementById('load').classList.add('active')
    Axios.post(process.env.REACT_APP_API + '/api/auth/prop-contracts-no-pag', {
      user_id: localStorage.getItem('id'),
      type: localStorage.getItem('access')
    }, 
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      this.setState({receive: response.data})
      document.getElementById('load').classList.remove('active')
    })

    Axios.post(process.env.REACT_APP_API + '/api/auth/own-contracts-no-pag', {
      user_id: localStorage.getItem('id'),
      type: localStorage.getItem('access')
    }, 
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      this.setState({accepted: response.data})
      document.getElementById('load').classList.remove('active')
    })

    if(window.screen.width > 768){
      this.setState({viewportRecebidos: [
        {
          dataField: 'sku',
          text: 'Código'
        },
        {
          dataField: "title",
          text: "Imóvel"
        },
        {
          dataField: "created_at",
          text: "Data"
        },
        {
          dataField: "profile_photo",
          text: ""
        },
        {
          dataField: "name",
          text: "Corretor"
        },
        {
          dataField: "id_parceria",
          text: "Baixar termo"
        },
      ]})
      this.setState({viewportAceitos: [
        {
          dataField: 'sku',
          text: 'Código'
        },
        {
          dataField: "title",
          text: "Imóvel"
        },
        {
          dataField: "created_at",
          text: "Data"
        },
        {
          dataField: "id_parceria",
          text: "Baixar termo"
        },
      ]})
    }
  }

  render() {

    const items = []

    this.state.receive.map((imovel, index) => {
      imovel.parcerias?.map((parceria, i) => {
        items.push({
          sku: imovel.sku,
          title: imovel.title,
          slug: imovel.slug,
          created_at: moment(parceria.created_at).format('DD/MM/YYYY'),
          name: parceria.name,
          profile_photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + parceria.profile_photo + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'40px', height: '40px', borderRadius:'50%'}}></div>,
          id_parceria: <Button  onClick={() => {
            document.getElementById('load').classList.add('active')
            const axiosOptions = {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('token')
              }
            }
            Axios.get(process.env.REACT_APP_API + '/api/auth/contract-pdf/' + parceria.id_parceria, axiosOptions)
            .then((response) => {
              const blob = new Blob([response.data], {
                type: 'application/octet-stream'
              })
              const filename = 'Parceria:' + imovel?.sku + '(' + parceria.name + ').pdf'
              saveAs(blob, filename)
              document.getElementById('load').classList.remove('active')
            })
            .catch((e) => {

            })
       }}>Baixar</Button>,
        mobile:<Container style={{padding:'0'}}>
        <Row>
          <Col className="col-auto"><div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + parceria.profile_photo + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'60px', height: '60px', borderRadius:'5px'}}></div></Col>
          <Col>
            <Row>
              <Col xs={12}>{moment(parceria.created_at).format('DD/MM/YYYY')}</Col>
              <Col xs={12}>{parceria?.name}</Col>
            </Row>
          </Col>
        </Row>
        <Row style={{marginTop:'10px'}}>
          <Col>Imóvel: {imovel.sku}</Col>
        </Row>
        <Row>
          <Col>{imovel.title}</Col>
        </Row>
        <Row style={{marginTop:'10px'}}>
          <Col><Button  onClick={() => {
          const axiosOptions = {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": "Bearer " + localStorage.getItem('token')
            }
          }
          Axios.get(process.env.REACT_APP_API + '/api/auth/contract-pdf/' + parceria.id_parceria, axiosOptions)
          .then((response) => {
            const blob = new Blob([response.data], {
              type: 'application/octet-stream'
            })
            const filename = 'Parceria:' + imovel?.sku + '(' + parceria.name + ').pdf'
            saveAs(blob, filename)
          })
          .catch((e) => {

          })
      }}>Baixar contrato</Button></Col>
        </Row>
      </Container>,
        })
      })
    })

    const aceitos = []

    this.state.accepted.map((imovel, index) => {
        aceitos.push({
          sku: imovel.sku,
          title: imovel.title,
          slug: imovel.slug,
          created_at: imovel.created_at,
          name: imovel.name,
          profile_photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + imovel.profile_photo + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'40px', height: '40px', borderRadius:'50%'}}></div>,
          id_parceria: <Button  onClick={() => {
            document.getElementById('load').classList.add('active')
            const axiosOptions = {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('token')
              }
            }
            Axios.get(process.env.REACT_APP_API + '/api/auth/contract-pdf/' + imovel.id_parceria, axiosOptions)
            .then((response) => {
              const blob = new Blob([response.data], {
                type: 'application/octet-stream'
              })
              const filename = 'Parceria:' + imovel?.sku + '(' + imovel.name + ').pdf'
              saveAs(blob, filename)
              document.getElementById('load').classList.remove('active')
            })
            .catch((e) => {

            })
       }}>Baixar</Button>,
        mobile:<Container style={{padding:'0'}}>
        <Row>
          <Col className="col-auto"><div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + imovel.profile_photo + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'60px', height: '60px', borderRadius:'5px'}}></div></Col>
          <Col>
            <Row>
              <Col xs={12}>{moment(imovel.created_at).format('DD/MM/YYYY')}</Col>
              <Col xs={12}>{imovel?.name}</Col>
            </Row>
          </Col>
        </Row>
        <Row style={{marginTop:'10px'}}>
          <Col>Imóvel: {imovel.sku}</Col>
        </Row>
        <Row>
          <Col>{imovel.title}</Col>
        </Row>
        <Row style={{marginTop:'10px'}}>
          <Col><Button  onClick={() => {
          const axiosOptions = {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": "Bearer " + localStorage.getItem('token')
            }
          }
          Axios.get(process.env.REACT_APP_API + '/api/auth/contract-pdf/' + imovel.id_parceria, axiosOptions)
          .then((response) => {
            const blob = new Blob([response.data], {
              type: 'application/octet-stream'
            })
            const filename = 'Parceria:' + imovel?.sku + '(' + imovel.name + ').pdf'
            saveAs(blob, filename)
          })
          .catch((e) => {

          })
    }}>Baixar contrato</Button></Col>
        </Row>
      </Container>,
        })
    })

    console.log(items)

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: 'Todos', value: items.length
        }]
    };

    return (
        <div className="page-content" id="partnershipPage">
        <Container fluid={true}>
          <Breadcrumbs title="Parcerias" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{marginBottom:'20px'}}>
                    <Col>
                      <h5>Contratos de parceria feitos em meus imóveis</h5>
                    </Col>
                  </Row>
                  <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                    <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                    <Input
                    placeholder="Buscar por corretor"
                    onChange={(e) => this.setState({filteredItems: items.filter(contrato => contrato.name.toLowerCase().includes(e.target.value.toLowerCase()))})}
                    >
                    </Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                  <BootstrapTable
                    keyField='id'
                    data={this.state.filteredItems.length > 0 ? this.state.filteredItems : items}
                    columns={this.state.viewportRecebidos}
                    // expandRow={expandRow}
                    pagination={paginationFactory(options)}
                    // selectRow={selectRow}
                  />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{marginBottom:'20px'}}>
                    <Col>
                      <h5>Contratos de parceria feitos por mim</h5>
                    </Col>
                  </Row>
                  <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                    <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                    <Input
                    placeholder="Buscar código"
                    onChange={(e) => this.setState({filteredAceitos: aceitos.filter(contrato => contrato.sku.toLowerCase().includes(e.target.value.toLowerCase()))})}
                    >
                    </Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                  <BootstrapTable
                    keyField='id'
                    data={this.state.filteredAceitos.length > 0 ? this.state.filteredAceitos : aceitos}
                    columns={this.state.viewportAceitos}
                    // expandRow={expandRow}
                    pagination={paginationFactory(options)}
                    // selectRow={selectRow}
                  />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}


export default PartnershipList;