import { Input, Label } from 'reactstrap';
import './styles.scss';
import { useEffect, useState } from 'react';

export const NameInput = ({handleInputChange, handleInputFocus}) => {
  const [inputValue, setInputValue] = useState('')

  const handleChange = (event) => {
    setInputValue(event.target.value)
    handleInputChange(event)
  };


  return(
    <fieldset>
      <Label>Nome do titular do cartão</Label>
      <Input
        type="text"
        name="name"
        placeholder="Nome"
        value={inputValue}
        onChange={handleChange}
        onFocus={handleInputFocus}
      />
    </fieldset>
  )
}