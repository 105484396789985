import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, CardTitle, CardText, Button, Modal, ModalHeader, ModalBody, FormGroup, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'
import Flatpickr from "react-flatpickr"
import { saveAs } from 'file-saver';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_integration.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import Select from "react-select";
import SweetAlert from 'react-bootstrap-sweetalert';
import NoPremium from '../../components/noPremium/noPremium';
require('moment/locale/pt.js');


class AnalyticsExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imovel:[],
      imoveis:[],
      geralFinalData:null,
      geralInicialData:null,
      imovelFinalData:null,
      imovelInicialData:null,
      selectOptionsRealEstate:[],
      selectedRealEstate:[],
      selectOptionsBroker:[],
      selectedBroker:[],
      verifyPremium:null,
      users: [],
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Relatórios", link: "#" },
    ],
    }
  }


  componentDidMount(){
      document.getElementById('load').classList.add('active')
      
      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {

          Axios.get(process.env.REACT_APP_API + '/api/auth/all-users', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        })
        .then(response => {
            var users = this.state.users.slice();
        
            users.push({
                value: 'novousuario',
                label: 'Novo Usuário'
            });
        
            for (var i = 0; i < response.data.length; i++) {
                users.push({
                    value: response.data[i].id,
                    label: response.data[i].name,
                    type: response.data[i].type
                });
            }
        
            this.setState({ users: users });
        });

          localStorage.getItem('access') === 'A' ?
            Axios.post(process.env.REACT_APP_API + '/api/auth/all-properties', {},
            { headers: { Authorization: 'Bearer ' + localStorage.getItem('token')}})
            .then(response => {
                this.state.imoveis.push({
                  value: 'todos',
                  label: 'Todos imóveis'
                })
                for(var i = 0; response.data.length > i; i++){
                    this.state.imoveis.push({
                      value: response.data[i].id,
                      label: response.data[i].sku + " - " + response.data[i].title
                    })
                  }
                  setTimeout(() => {
                    document.getElementById('load').classList.remove('active')
                  }, 300);
            })
          :
            Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
              type: localStorage.getItem('access'),
              user_id: localStorage.getItem('id')
            }, {
              headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
            })
            .then(response => {
              if(response.data.premium === 1) {
                this.setState({verifyPremium: true})
              } else {
                this.setState({verifyPremium: false})
              }
              Axios.post(process.env.REACT_APP_API + '/api/auth/user-properties/' + localStorage.getItem('id') , {
                type: localStorage.getItem('access')
                },
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  console.log(response.data)
                    for(var i = 0; response.data.length > i; i++){
                      this.state.imoveis.push({
                        value: response.data[i].id,
                        label: response.data[i].sku + " - " + response.data[i].title
                      })
                    }
                    setTimeout(() => {
                      document.getElementById('load').classList.remove('active')
                    }, 300);
                })
            })
            .catch(response => {
              if(localStorage.getItem('access') === 'P'){
                Axios.post(process.env.REACT_APP_API + '/api/auth/user-properties/' + localStorage.getItem('id') , {
                  type: localStorage.getItem('access')
                  },
                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                  .then(response => {
                    console.log(response.data)
                      for(var i = 0; response.data.length > i; i++){
                        this.state.imoveis.push({
                          value: response.data[i].id,
                          label: response.data[i].sku + " - " + response.data[i].title
                        })
                      }
                      setTimeout(() => {
                        document.getElementById('load').classList.remove('active')
                      }, 300);
                  })
              }else{
                this.setState({verifyPremium: false})
                document.getElementById('load').classList.remove('active')
              }
            })

            Axios.get(process.env.REACT_APP_API + '/api/auth/brokers-select',
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              for(var i = 0; response.data?.length > i; i++){
                this.state.selectOptionsBroker.push({
                  value: response.data[i].id,
                  label: response.data[i].name
                })
              }
              })
            Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-select',
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              for(var i = 0; response.data?.length > i; i++){
                this.state.selectOptionsRealEstate.push({
                  value: response.data[i].id,
                  label: response.data[i].name
                })
              }
            })
        })
        .catch(response =>{
          localStorage.removeItem("history_url")
          setTimeout(() => {
            window.open("/login", '_parent')
          }, 300);
        })
  }

  render() {

    const locale = {
      weekdays: {
        shorthand: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        longhand: [
          'Domingo',
          'Segunda-feira',
          'Terça-feira',
          'Quarta-feira',
          'Quinta-feira',
          'Sexta-feira',
          'Sábado'
        ]
      },
      months: {
        shorthand: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        longhand: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro'
        ]
      },
      firstDayOfWeek: 0,
      rangeSeparator: ' até ',
      weekAbbreviation: 'Sem',
      scrollTitle: 'Rolagem para aumentar',
      toggleTitle: 'Clique para alternar',
      amPM: ['AM', 'PM'],
      yearAriaLabel: 'Ano',
      time_24hr: true
    };

    const {selectOptionsRealEstate, selectedRealEstate, selectOptionsBroker, selectedBroker} = this.state;

    return (
        <div className="page-content" id="AnalyticsExport">
        <Container fluid={true}>
          <Breadcrumbs title="Relatórios" breadcrumbItems={this.state.breadcrumbItems} />
          {localStorage.getItem('access') === 'A' || localStorage.getItem('access') === 'P' || this.state.verifyPremium ? (
            <>
              {localStorage.getItem('access') === 'A' &&
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row style={{marginBottom:'15px'}}>
                          <Col>
                            <h3 style={{fontSize:'18px'}}>Selecione Corretor ou Imobiliária (Admin)</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Label className="form-label">Corretor captador</Label>
                            <Select
                              value={selectedBroker}
                              placeholder="Selecione"
                              noOptionsMessage={(inputValue) => `Sem resultados`}
                              onChange={(e) => this.setState({ selectedBroker: e })}
                              options={selectOptionsBroker}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                          <Col md={6}>
                            <Label className="form-label">Imobiliária captadora</Label>
                            <Select
                              value={selectedRealEstate}
                              placeholder="Selecione"
                              noOptionsMessage={(inputValue) => `Sem resultados`}
                              onChange={(e) => this.setState({ selectedRealEstate: e })}
                              options={selectOptionsRealEstate}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row style={{marginBottom:'15px'}}>
                        <Col>
                          <h3 style={{fontSize:'18px'}}>Relatório Geral</h3>
                        </Col>
                      </Row>
                      <Row style={{alignItems:'center'}}>
                        <Col lg={3} md={6} xs={12}>
                          <FormGroup className="mb-4">
                            <Label>Data inicial</Label>
                            <InputGroup>
                            <Flatpickr
                                className="form-control d-block"
                                placeholder="Selecione um dia"
                                options={{
                                altInput: true,
                                altFormat: "j, F, Y",
                                dateFormat: "Y-m-d",
                                }}
                                locale= {locale}
                                onChange={(e) => {
                                  this.setState({geralInicialData : e})
                                }}
                            />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                          <FormGroup className="mb-4">
                            <Label>Data final</Label>
                            <InputGroup>
                            <Flatpickr
                                className="form-control d-block"
                                placeholder="Selecione um dia"
                                options={{
                                altInput: true,
                                altFormat: "j, F, Y",
                                dateFormat: "Y-m-d",
                                }}
                                locale= {locale}
                                onChange={(e) => {
                                  this.setState({geralFinalData : e})
                                }}
                            />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg={2} md={6} xs={12}>
                          <Button onClick={() => {
                            document.getElementById('load').classList.add('active')
                            const axiosOptions = {
                              responseType: 'arraybuffer',
                              headers: {
                                'Content-Type': 'application/json',
                                "Authorization": "Bearer " + localStorage.getItem('token')
                              }
                            }
                            const data = new FormData()
                            data.append('user_id', localStorage.getItem('access') === 'A' ? (selectedBroker.value ? selectedBroker.value : selectedRealEstate.value ) : localStorage.getItem('id'))
                            data.append('type', localStorage.getItem('access') === 'A' ? (selectedBroker.value ? 'C' : 'I') : localStorage.getItem('access'))
                            if(this.state.geralInicialData && this.state.geralFinalData){
                              data.append('init_date', moment(this.state.geralInicialData[0].toString()).format('YYYY-MM-DD'))
                              data.append('final_date', moment(this.state.geralFinalData[0].toString()).format('YYYY-MM-DD'))
                            }
                            Axios.post(process.env.REACT_APP_API + '/api/auth/generate-report/', data,
                            axiosOptions).then(response => {
                              const blob = new Blob([response.data], {
                                type: 'application/octet-stream'
                              })
                              const filename = 'Relatório(' + localStorage.getItem('access') === 'A' ? (selectedBroker.label ? selectedBroker.label : selectedRealEstate.label ) : localStorage.getItem('user_name') + ').pdf'
                              saveAs(blob, filename)
                              document.getElementById('load').classList.remove('active')
                            })
                          }}>Gerar relatório</Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-auto" style={{marginTop:'15px'}}>
                          <Card color="dark" className="text-white-50">
                            <CardBody style={{padding: '0.75rem 0.75rem'}}>
                                <CardTitle className="text-white" style={{marginBottom:'0'}}><i className="mdi mdi-alert-circle-outline me-3"></i>Atenção</CardTitle>
                                <CardText>Selecione o intervalo de data, ou deixe em branco para todo o período.</CardText>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row style={{marginBottom:'15px'}}>
                        <Col>
                          <h3 style={{fontSize:'18px'}}>Relatório por imóvel</h3>
                        </Col>
                      </Row>
                      <Row style={{alignItems:'center'}}>
                        <Col lg={3} md={6} xs={12}>
                          <FormGroup className="mb-4">
                            <Label>Data inicial</Label>
                            <InputGroup>
                            <Flatpickr
                                className="form-control d-block"
                                placeholder="Selecione um dia"
                                options={{
                                altInput: true,
                                altFormat: "j, F, Y",
                                dateFormat: "Y-m-d",
                                }}
                                locale= {locale}
                                onChange={(e) => {
                                  this.setState({imovelInicialData: e})
                                }}
                            />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                          <FormGroup className="mb-4">
                            <Label>Data final</Label>
                            <InputGroup>
                            <Flatpickr
                                className="form-control d-block"
                                placeholder="Selecione um dia"
                                options={{
                                altInput: true,
                                altFormat: "j, F, Y",
                                dateFormat: "Y-m-d",
                                }}
                                locale= {locale}
                                onChange={(e) => {
                                  this.setState({imovelFinalData: e})
                                }}
                            />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg={4} md={6} xs={12} style={{marginTop:'0', marginBottom:'20px'}}>
                          <Label>Imóvel:</Label>
                          <Select
                          value={this.state.imovel}
                          placeholder="Selecione"
                          noOptionsMessage={(inputValue) => `Sem resultados`}
                          onChange={(e) => {
                              this.setState({ imovel: e })
                          }}
                          options={this.state.imoveis}
                          classNamePrefix="select2-selection"
                          />
                        </Col>
                        <Col lg={2} md={6} xs={12}>
                          {this.state.imovel.value ?
                            <Button onClick={() => {
                              document.getElementById('load').classList.add('active')
                              const axiosOptions = {
                                responseType: 'arraybuffer',
                                headers: {
                                  'Content-Type': 'application/json',
                                  "Authorization": "Bearer " + localStorage.getItem('token')
                                }
                              }
                              if(this.state.imovelInicialData){
                                const data = {
                                  init_date: moment(this.state.imovelInicialData[0].toString()).format('YYYY-MM-DD'),
                                  final_date: moment(this.state.imovelFinalData[0].toString()).format('YYYY-MM-DD'),
                                }
                                Axios.post(process.env.REACT_APP_API + '/api/auth/generate-report/' + this.state.imovel.value, data , axiosOptions).then(response => {
                                  const blob = new Blob([response.data], {
                                    type: 'application/octet-stream'
                                  })
                                  const filename = 'Relatório(' + moment(this.state.imovelInicialData.toString()).format('YYYY-MM-DD') + 'até' + moment(this.state.imovelFinalData.toString()).format('YYYY-MM-DD') + ').pdf'
                                  saveAs(blob, filename)
                                  document.getElementById('load').classList.remove('active')
                                })
                              }else{
                                const data = {}
                                Axios.post(process.env.REACT_APP_API + '/api/auth/generate-report/' + this.state.imovel.value, data , axiosOptions).then(response => {
                                  const blob = new Blob([response.data], {
                                    type: 'application/octet-stream'
                                  })
                                  const filename = 'Relatório('+ this.state.imovel.label +').pdf'
                                  saveAs(blob, filename)
                                  document.getElementById('load').classList.remove('active')
                                })
                              }
                            }}>Gerar relatório</Button>
                            :
                          <Button disabled>Gerar relatório</Button>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-auto" style={{marginTop:'15px'}}>
                          <Card color="dark" className="text-white-50">
                            <CardBody style={{padding: '0.75rem 0.75rem'}}>
                                <CardTitle className="text-white" style={{marginBottom:'0'}}><i className="mdi mdi-alert-circle-outline me-3"></i>Atenção</CardTitle>
                                <CardText>Selecione o intervalo de data, ou deixe em branco para todo o período.</CardText>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {
                localStorage.getItem('access') === 'A' &&
                <>
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <Row style={{marginBottom:'15px'}}>
                            <Col>
                              <h3 style={{fontSize:'18px'}}>Relatório geral por usuário</h3>
                            </Col>
                          </Row>
                          <Row style={{alignItems:'center'}}>
                            {/* <Col lg={3} md={6} xs={12}>
                              <FormGroup className="mb-4">
                                <Label>Data inicial</Label>
                                <InputGroup>
                                <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Selecione um dia"
                                    options={{
                                    altInput: true,
                                    altFormat: "j, F, Y",
                                    dateFormat: "Y-m-d",
                                    }}
                                    locale= {locale}
                                    onChange={(e) => {
                                      this.setState({imovelInicialData: e})
                                    }}
                                />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col lg={3} md={6} xs={12}>
                              <FormGroup className="mb-4">
                                <Label>Data final</Label>
                                <InputGroup>
                                <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Selecione um dia"
                                    options={{
                                    altInput: true,
                                    altFormat: "j, F, Y",
                                    dateFormat: "Y-m-d",
                                    }}
                                    locale= {locale}
                                    onChange={(e) => {
                                      this.setState({imovelFinalData: e})
                                    }}
                                />
                                </InputGroup>
                              </FormGroup>
                            </Col> */}
                            <Col md={6} xs={12} style={{ marginTop: '0', marginBottom: '20px' }}>
                              <Label>Usuário:</Label>
                              <Select
                                value={this.state.user}
                                placeholder="Selecione"
                                noOptionsMessage={(inputValue) => `Sem resultados`}
                                onChange={(e) => {
                                  this.setState({ user: e });
                                }}
                                options={this.state.users}
                                getOptionLabel={(user) => user.label}
                                getOptionValue={(user) => user.value}
                                classNamePrefix="select2-selection"
                              />
                            </Col>
                            <Col md={6} xs={12}>
                              {this.state.user ?
                                <Button onClick={() => {
                                  document.getElementById('load').classList.add('active');
                                  const axiosOptions = {
                                    responseType: 'arraybuffer',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      "Authorization": "Bearer " + localStorage.getItem('token')
                                    }
                                  };
                                  if (this.state.imovelInicialData) {
                                    const data = {
                                      init_date: moment(this.state.imovelInicialData[0].toString()).format('YYYY-MM-DD'),
                                      final_date: moment(this.state.imovelFinalData[0].toString()).format('YYYY-MM-DD'),
                                      user_id: this.state.user.value,
                                      type: this.state.user.type
                                    };
                                    Axios.post(process.env.REACT_APP_API + '/api/auth/generate-user-report', data, axiosOptions)
                                      .then(response => {
                                        const blob = new Blob([response.data], {
                                          type: 'application/octet-stream'
                                        });
                                        const filename = 'Relatório(' + moment(this.state.imovelInicialData.toString()).format('YYYY-MM-DD') + 'até' + moment(this.state.imovelFinalData.toString()).format('YYYY-MM-DD') + ').csv';
                                        saveAs(blob, filename);
                                        document.getElementById('load').classList.remove('active');
                                      });
                                  } else {
                                    const data = {
                                      user_id: this.state.user.value,
                                      type: this.state.user.type
                                    };
                                    Axios.post(process.env.REACT_APP_API + '/api/auth/generate-user-report', data, axiosOptions)
                                      .then(response => {
                                        const blob = new Blob([response.data], {
                                          type: 'application/octet-stream'
                                        });
                                        const filename = 'Relatório(' + this.state.imovel.label + ').csv';
                                        saveAs(blob, filename);
                                        document.getElementById('load').classList.remove('active');
                                      });
                                  }
                                }}>Gerar relatório
                                </Button>
                                :
                              <Button disabled>Gerar relatório</Button>
                              }
                            </Col>
                          </Row>
                          {/* <Row>
                            <Col className="col-auto" style={{marginTop:'15px'}}>
                              <Card color="dark" className="text-white-50">
                                <CardBody style={{padding: '0.75rem 0.75rem'}}>
                                    <CardTitle className="text-white" style={{marginBottom:'0'}}><i className="mdi mdi-alert-circle-outline me-3"></i>Atenção</CardTitle>
                                    <CardText>Selecione o intervalo de data, ou deixe em branco para todo o período.</CardText>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row> */}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <Row style={{marginBottom:'15px'}}>
                            <Col>
                              <h3 style={{fontSize:'18px'}}>Relatório geral de imóvel</h3>
                            </Col>
                          </Row>
                          <Row style={{alignItems:'center'}}>
                            {/* <Col lg={3} md={6} xs={12}>
                              <FormGroup className="mb-4">
                                <Label>Data inicial</Label>
                                <InputGroup>
                                <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Selecione um dia"
                                    options={{
                                    altInput: true,
                                    altFormat: "j, F, Y",
                                    dateFormat: "Y-m-d",
                                    }}
                                    locale= {locale}
                                    onChange={(e) => {
                                      this.setState({imovelInicialData: e})
                                    }}
                                />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col lg={3} md={6} xs={12}>
                              <FormGroup className="mb-4">
                                <Label>Data final</Label>
                                <InputGroup>
                                <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Selecione um dia"
                                    options={{
                                    altInput: true,
                                    altFormat: "j, F, Y",
                                    dateFormat: "Y-m-d",
                                    }}
                                    locale= {locale}
                                    onChange={(e) => {
                                      this.setState({imovelFinalData: e})
                                    }}
                                />
                                </InputGroup>
                              </FormGroup>
                            </Col> */}
                            <Col md={6} xs={12} style={{marginTop:'0', marginBottom:'20px'}}>
                              <Label>Imóvel:</Label>
                              <Select
                              value={this.state.imovel}
                              placeholder="Selecione"
                              noOptionsMessage={(inputValue) => `Sem resultados`}
                              onChange={(e) => {
                                  this.setState({ imovel: e })
                              }}
                              options={this.state.imoveis}
                              classNamePrefix="select2-selection"
                              />
                            </Col>
                            <Col md={6} xs={12}>
                              {this.state.imovel.value ?
                                <Button onClick={() => {
                                  document.getElementById('load').classList.add('active')
                                  const axiosOptions = {
                                    responseType: 'arraybuffer',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      "Authorization": "Bearer " + localStorage.getItem('token')
                                    }
                                  }
                                  if(this.state.imovelInicialData){
                                    const data = {
                                      init_date: moment(this.state.imovelInicialData[0].toString()).format('YYYY-MM-DD'),
                                      final_date: moment(this.state.imovelFinalData[0].toString()).format('YYYY-MM-DD'),
                                      property_id: this.state.imovel.value
                                    }
                                    Axios.post(process.env.REACT_APP_API + '/api/auth/generate-property-report', data , axiosOptions).then(response => {
                                      const blob = new Blob([response.data], {
                                        type: 'application/octet-stream'
                                      })
                                      const filename = 'Relatório(' + moment(this.state.imovelInicialData.toString()).format('YYYY-MM-DD') + 'até' + moment(this.state.imovelFinalData.toString()).format('YYYY-MM-DD') + ').csv'
                                      saveAs(blob, filename)
                                      document.getElementById('load').classList.remove('active')
                                    })
                                  }else{
                                    const data = {
                                      property_id: this.state.imovel.value
                                    }
                                    Axios.post(process.env.REACT_APP_API + '/api/auth/generate-property-report', data , axiosOptions).then(response => {
                                      const blob = new Blob([response.data], {
                                        type: 'application/octet-stream'
                                      })
                                      const filename = 'Relatório('+ this.state.imovel.label +').csv'
                                      saveAs(blob, filename)
                                      document.getElementById('load').classList.remove('active')
                                    })
                                  }
                                }}>Gerar relatório</Button>
                                :
                              <Button disabled>Gerar relatório</Button>
                              }
                            </Col>
                          </Row>
                          {/* <Row>
                            <Col className="col-auto" style={{marginTop:'15px'}}>
                              <Card color="dark" className="text-white-50">
                                <CardBody style={{padding: '0.75rem 0.75rem'}}>
                                    <CardTitle className="text-white" style={{marginBottom:'0'}}><i className="mdi mdi-alert-circle-outline me-3"></i>Atenção</CardTitle>
                                    <CardText>Selecione o intervalo de data, ou deixe em branco para todo o período.</CardText>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row> */}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              }
            </>
          ) : (
            <NoPremium />
          )
          }
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}


export default AnalyticsExport;