import { AvField, AvForm } from 'availity-reactstrap-validation'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useEffect, useRef, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import Select from "react-select";
import './styles.css'
import Axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import PrimeiroImovel from './../../assets/images/Icons/primeiroimovel.png'
import Triste from './../../assets/images/Icons/triste.png'
import VamosNessa from './../../assets/images/Icons/vamosnessa.png'

export const NewAddProperty = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Fast Sale", link: "/dashboard" },
    { title: "Novo imóvel", link: "#" },
  ])

  const formRef = useRef(null);

  const [ownerName, setOwnerName] = useState('')
  const [commission, setCommission] = useState('')
  const [propertyType, setPropertyType] = useState(null)
  const [title, setTitle] = useState('')
  const [sellContract, setSellContract] = useState('')
  const [cep, setCep] = useState('')
  const [street, setStreet] = useState('')
  const [number, setNumber] = useState('')
  const [district, setDistrict] = useState('')
  const [country, setCountry] = useState('Brasil')
  const [complement, setComplement] = useState('')
  const [enterprise, setEnterprise] = useState('')
  const [unit, setUnit] = useState('')
  const [floor, setFloor] = useState('')

  const [verifyPremium, setVerifyPremium] = useState(false)
  const [pCount, setPCount] = useState([])
  const [pCountEnabled, setPCountEnabled] = useState([])
  const [imoveis, setImoveis] = useState([])
  const [modalFullProperties, setModalFullProperties] = useState(true)
  const [realEstateTeam, setRealEstateTeam] = useState([])
  const [teamActive, setTeamActive] = useState(false)
  const [selectOptionsBrokerByRealEstate, setSelectOptionsBrokerByRealEstate] = useState([])


  const [stateSelected, setStateSelected] = useState([])
  const [citySelected, setCitySelected] = useState([])
  const [cityAddress, setCityAddress] = useState([])
  const [statesAddress, setStatesAddress] = useState([])

  const [selectedBroker, setSelectedBroker] = useState([])
  const [selectOptionsBroker, setSelectOptionsBroker] = useState([])
  const [selectedRealEstate, setSelectedRealEstate] = useState([])
  const [selectOptionsRealEstate, setSelectOptionsRealEstate] = useState([])
  const [selectedConstruction, setSelectedConstruction] = useState([])
  const [selectOptionsConstructions, setSelectOptionsConstructions] = useState([])
  const [selectedOwner, setSelectedOwner] = useState([])
  const [selectOptionsOwner, setSelectOptionsOwner] = useState([])


  useEffect(() => {
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        if(localStorage.getItem('access') === 'C'){
          Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-team/by-broker/' + localStorage.getItem('id'),
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            setRealEstateTeam(response.data.realEstateTeam)
            setTeamActive(true)
          })
          
        }

        if(localStorage.getItem('access') === 'I'){
          Axios.get(process.env.REACT_APP_API + '/api/auth/real_estate_teams/by_real_estate/' + localStorage.getItem('id'),
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              for(var i = 0; response.data?.realEstateTeams?.length > i; i++){
                selectOptionsBrokerByRealEstate.push({
                  value: response.data.realEstateTeams[i]?.broker.id,
                  label: response.data.realEstateTeams[i]?.broker.name
              })
            }
          })
        }

        if(localStorage.getItem('access') !== 'T' && localStorage.getItem('access') !== 'A' && localStorage.getItem('access') !== 'S'){
          Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
            type:localStorage.getItem('access'),
            user_id:localStorage.getItem('id')
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(res => {
            if(res.data.premium === 1) {
              setVerifyPremium(true)
            } else {
              setVerifyPremium(false)
            }
          })
        }else{
          setVerifyPremium(true)
        }

        if(localStorage.getItem('access') !== 'T' && localStorage.getItem('access') !== 'A' && localStorage.getItem('access') !== 'S'){
          Axios.post(process.env.REACT_APP_API + '/api/auth/get-user-props-pcount', {
            type: (teamActive) ? 'I' : localStorage.getItem('access'),
            user_id: (teamActive) ? realEstateTeam.real_estate_id : localStorage.getItem('id')
          }, 
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            const newPCount = Array.from({ length: resp.data.p_count }, (_, i) => i);
            const newImoveis = Array.from({ length: resp.data.imoveis }, (_, i) => i);
            const newPCountEnabled = Array.from({ length: resp.data.p_count - resp.data.imoveis }, (_, i) => i);
          
            setPCount(newPCount);
            setImoveis(newImoveis);
            setPCountEnabled(newPCountEnabled);

            setModalFullProperties(true)
          })
        }

      }).catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }, [])

  useEffect(() => {
    if(localStorage.getItem('access') === 'A'){
      Axios.get(process.env.REACT_APP_API + '/api/auth/owners-select',
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setSelectOptionsOwner(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
        })
        Axios.get(process.env.REACT_APP_API + '/api/auth/brokers-select',
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setSelectOptionsBroker(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
          })
          Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions',
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            setSelectOptionsConstructions(response.data?.map(item => ({
              value: item.id,
              label: item.name
            })))
          })
        Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-select',
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setSelectOptionsRealEstate(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
        })
    }
  }, [])

  useEffect(() => {
    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
    .then(response => {
      const states = []
      for(var i = 0; response.data.length > i; i++){
        states.push({
          value: response.data[i].sigla,
          label: response.data[i].nome
        })
      }
      setStatesAddress(states)
    })
    if(localStorage.getItem('access') === 'C' || localStorage.getItem('access') === 'I'){
      setSellContract('FS Parc')
    }
    if(localStorage.getItem('access') === 'T' || localStorage.getItem('access') === 'S'){
      setSellContract('Construtora')
    }
  },[])

  const [advanceClass, setAdvanceClass] = useState('')
  const [disbadge, setDisbadge] = useState(false)
  const [optionCount, setOptionCount] = useState(null)
  const [createSuccess, setCreateSuccess] = useState(false)
  const [createError, setCreateError] = useState(false)
  const [responseId, setResponseId] = useState(null)

  const handleTitleLength = (event) => {
		var count = event.target.value.length;
		if (count > 0) {
			setDisbadge(true)
		} else {
			setDisbadge(false)
		}
		if (count > 99) {
			setAdvanceClass("badgecountextra")
		} else {
			setAdvanceClass("badgecount")
		}
		setOptionCount(event.target.value.length)
	}

  const consultaCep = (cepValue) => {
    const cep = cepValue
      if (cep?.length !== 8) {
        setStreet('');
        setDistrict('');
        setStateSelected('')
        setCitySelected('');
      }else{
        fetch('https://viacep.com.br/ws/' + cep + '/json/')
        .then((res) => res.json())
        .then((data) => {
            if (data.erro === true) {
              setStreet('');
              setDistrict('');
              setStateSelected({stateSelected: ''})
              setCitySelected({citySelected: ''});
            } else {
              setStreet(data.logradouro);
              setDistrict(data.bairro);
              setStateSelected({
                value:data.uf,
                label:data.uf
              })
              setCitySelected({
                value:data.localidade,
                label:data.localidade
              });
            }
          })
      }  
  }

  const handleSelectState = stateSelected => {
		setStateSelected(stateSelected)
    setCitySelected([])
    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + stateSelected.value + '/municipios')
    .then(response => {
      const city = []
      for(var i = 0; response.data.length > i; i++){
        city.push({
          label: response.data[i].nome,
          value: response.data[i].nome
        })
      }
      setCityAddress(city)
    })
	};

  const handleCreate = () => {
    document.getElementById('load').classList.add('active')
    const data = new FormData();
    data.append('owner_name', ownerName)
    data.append('commission_percentage_on_sale', commission)
    data.append('category_id', propertyType)
    data.append('title', title)
    data.append('status', 1)
    data.append('fs_property', 1)
    data.append('personal_page', 1)
    data.append('sell_contract_type', sellContract)
    data.append('enterprise', enterprise)
    data.append('cep', cep)
    data.append('city', citySelected?.value)
    data.append('state', stateSelected?.value)
    data.append('country', country)
    data.append('street', street)
    data.append('district', district)
    data.append('number', number) 
    data.append('complement', complement)
    data.append('floor', floor)
    data.append('unities', unit)
    data.append('workflow', 20)

    if(teamActive){
      data.append('real_estate_id', realEstateTeam.real_estate_id)
    }

    if(selectOptionsBrokerByRealEstate.length > 0 && selectedBroker.value){
      data.append('broker_id', selectedBroker.value)
    }

    if(localStorage.getItem('access') !== 'A'){
      data.append('type', localStorage.getItem('access') === 'S' ? 'T' : localStorage.getItem('access'))
      data.append('user_id', localStorage.getItem('access') === 'S' ? localStorage.getItem('cosntruction_id') : localStorage.getItem('id'))
    }else{
      if(selectedBroker.value){
        data.append('type', 'C')
        data.append('broker_id', selectedBroker.value)
        data.append('user_id', selectedBroker.value)
      }
      if(selectedRealEstate.value){
        data.append('type', 'I')
        data.append('real_estate_id', selectedRealEstate.value)
        data.append('user_id', selectedRealEstate.value)
      }
      if(selectedConstruction.value){
        data.append('type', 'T')
        data.append('construction_id', selectedConstruction.value)
        data.append('user_id', selectedConstruction.value)
      }
      if(selectedOwner.value){
        data.append('type', 'P')
        data.append('owner_id', selectedOwner.value)
        data.append('user_id', selectedOwner.value)
      }
    }

      Axios.post(process.env.REACT_APP_API + '/api/auth/property', data, {
        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
      })
      .then(response => {
        setResponseId(response.data.property_id)
        setCreateSuccess(true)
        document.getElementById('load').classList.remove('active')
      }).catch(error => {
        setCreateError(true)
        document.getElementById('load').classList.remove('active')
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(cep === ''
      || street === ''
      || number === ''
      || district === ''
      || citySelected.value === ''
      || stateSelected === ''
      || country === ''
      || title === ''
      || propertyType === ''){
        formRef.current.submit()
      }else{
        handleCreate()
      }
  };

  return(
    <div className="page-content" id="newAddProperty">
      <Container fluid={true}>
        <Breadcrumbs title="Novo imóvel" breadcrumbItems={breadcrumbItems} />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <AvForm className="cardBody needs-validation" innerRef={formRef} onValidSubmit={handleSubmit}>
                  {localStorage.getItem('access') !== 'T' &&
                  <Container>
                    <Row><Col>Sobre a Captação</Col></Row>
                    <Row>
                      {localStorage.getItem('access') === 'A' &&
                        <Col md={4}>
                          <Label htmlFor="tipoContrato" className="col-md-12 col-form-label">Contrato</Label>
                          <select className="form-control" value={sellContract} onChange={(e) => setSellContract(e.target.value)}>
                            <option value="0">Selecione uma opção</option>
                            <option value="FS Prop">Fast Sale Proprietário</option>
                            <option value="FS Corr">Fast Sale Corretor</option>
                            <option value="FS Imob (antigo)">Fast Sale Imobiliária (antigo)</option>
                            <option value="FS Imob">Fast Sale Imobiliária</option>
                            <option value="FS Parc">Parceria 50 / 50</option>
                            <option value="FS Parc (sc)">Parceria 0 / 100</option>
                          </select>
                        </Col>
                      }
                      {(localStorage.getItem('access') === 'I' && selectOptionsBrokerByRealEstate.length > 0) &&
                        <Col md={4}>
                          <Label className="form-label">Corretor captador</Label>
                          <Select
                            value={selectedBroker}
                            placeholder="Selecione"
                            noOptionsMessage={(inputValue) => `Sem resultados`}
                            onChange={(e) => setSelectedBroker(e)}
                            options={selectOptionsBrokerByRealEstate}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                      }
                      {localStorage.getItem('access') === 'A' &&
                        <Col md={4}>
                          <Label className="form-label">Corretor captador</Label>
                          <Select
                            value={selectedBroker}
                            placeholder="Selecione"
                            noOptionsMessage={(inputValue) => `Sem resultados`}
                            onChange={(e) => setSelectedBroker(e)}
                            options={selectOptionsBroker}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                      }
                      {localStorage.getItem('access') === 'A' &&
                        <Col md={4}>
                          <Label className="form-label">Imobiliária captadora</Label>
                          <Select
                            value={selectedRealEstate}
                            placeholder="Selecione"
                            noOptionsMessage={(inputValue) => `Sem resultados`}
                            onChange={(e) => setSelectedRealEstate(e)}
                            options={selectOptionsRealEstate}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                      }
                      {localStorage.getItem('access') === 'A' &&
                        <Col md={4}>
                          <Label className="form-label">Construtora</Label>
                          <Select
                            value={selectedConstruction}
                            placeholder="Selecione"
                            noOptionsMessage={(inputValue) => `Sem resultados`}
                            onChange={(e) => setSelectedConstruction(e)}
                            options={selectOptionsConstructions}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                      }
                      {localStorage.getItem('access') === 'A' &&
                        <Col md={4}>
                          <Label className="form-label">Proprietário</Label>
                          <Select
                            value={selectedOwner}
                            placeholder="Selecione"
                            noOptionsMessage={(inputValue) => `Sem resultados`}
                            onChange={(e) => setSelectedOwner(e)}
                            options={selectOptionsOwner}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                      }
                      <Col md={4}>
                        <Label>Nome do proprietário</Label>
                        <Input
                          type='text'
                          value={ownerName}
                          onChange={(e) => setOwnerName(e.target.value)}
                        ></Input>
                      </Col>
                    </Row>
                  </Container>
                  }
                  <Container>
                    <Row><Col>Sobre o imóvel</Col></Row>
                    <Row>
                      <Col md={3}>
                        <Label>Comissão</Label>
                        <Input
                          type='number'
                          placeholder='%'
                          value={commission}
                          onChange={(e) => setCommission(e.target.value)}
                        ></Input>
                        {localStorage.getItem('access') !== 'T' &&
                          <small><b>Coloque a comissão total.</b> Baseado na porcentagem da comissão acima, o corretor captador (você) ficará com 50% no ato da venda. Os outros 50% são destinados ao corretor parceiro que vender o imóvel.</small>
                        }
                      </Col>
                      <Col md={4}>
                        <Label>Tipo do imóvel</Label>
                        <AvField type="select" name="select" id="select" className="form-control"
                          value={propertyType}
                          name="type"
                          validate={{ required: { value: true } }}
                          errorMessage="Campo obrigatório."
                          onChange={(e) => setPropertyType(e.target.value)}>
                            <option value={null}>Escolha uma opção</option>
                            <option value={12}>Apartamento</option>
                            <option value={14}>Casa</option>
                            <option value={34}>Comercial</option>
                            <option value={22}>Terreno</option>
                            <option value={35}>Outros (Sítio, Fazenda, Área Logística, ...)</option>
                        </AvField>  
                      </Col>
                      <Col md={5}>
                        <Label>Título do imóvel</Label>
                        <AvField
                          placeholder="Título do imóvel"
                          type="text"
                          maxLength="100"
                          name="title"
                          errorMessage="Campo obrigatório."
                          className="form-control"
                          validate={{ required: { value: true } }}
                          value={title}
                          onChange={(e) => {
                            handleTitleLength(e)
                            setTitle(e.target.value)
                          }}
                        />
                        {disbadge ? (
                          <span className={advanceClass}>
                            <span className="badge badge-success">Você digitou {optionCount} de 100 caracteres permitidos.</span>
                          </span>
                        ) : null}
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12}>
                      <Label>Endereço</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Label htmlFor="cep" className="col-md-12 col-form-label">Cep</Label>
                        <AvField
                          name="cep"
                          placeholder="Cep"
                          type="text"
                          errorMessage="Campo obrigatório."
                          className="form-control"
                          value={cep}
                          validate={{ required: { value: true } }}
                          onChange={(e) => {
                            consultaCep(e.target.value.replace('-', '').replace('.', ''))
                            setCep(e.target.value)
                          }}
                          id="cep"
                        />
                      </Col>
                      <Col md={6}>
                        <Label htmlFor="rua" className="col-md-12 col-form-label">Rua</Label>
                        <AvField
                          name="rua"
                          placeholder="Rua, Avenida..."
                          type="text"
                          errorMessage="Campo obrigatório."
                          className="form-control"
                          value={street}
                          validate={{ required: { value: true } }}
                          onChange={(e) => setStreet(e.target.value)}
                          id="rua"
                        />
                      </Col>
                      <Col md={2}>
                        <Label htmlFor="numero" className="col-md-12 col-form-label">Número</Label>
                        <AvField
                          name="numero"
                          placeholder="Número"
                          type="text"
                          errorMessage="Campo obrigatório."
                          className="form-control"
                          value={number}
                          validate={{ required: { value: true } }}
                          onChange={(e) => setNumber(e.target.value)}
                          id="numero"
                        />
                      </Col>
                      <Col md={6}>
                        <Label htmlFor="bairro" className="col-md-12 col-form-label">Bairro</Label>
                        <AvField
                          name="bairro"
                          placeholder="Bairro"
                          type="text"
                          errorMessage="Campo obrigatório."
                          className="form-control"
                          value={district}
                          validate={{ required: { value: true } }}
                          onChange={(e) => setDistrict(e.target.value)}
                          id="bairro"
                        />
                      </Col>
                      <Col md={6}>
                        <Label htmlFor="complemento" className="col-md-12 col-form-label">Complemento</Label>
                          <Input
                            type="text"
                            placeholder="Complemento"
                            id="complemento"
                            value={complement}
                            onChange={(e) => setComplement(e.target.value)}
                          />
                      </Col>
                      <Col md={4}>
                        <Label htmlFor="estado" className="col-md-12 col-form-label">Estado</Label>
                        <Select
                          value={stateSelected}
                          placeholder="Selecione"
                          noOptionsMessage={(inputValue) => `Sem resultados`}
                          onChange={handleSelectState}
                          options={statesAddress}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                      <Col md={4}>
                        <Label htmlFor="cidade" className="col-md-12 col-form-label">Cidade</Label>
                        <Select
                          value={citySelected}
                          placeholder="Selecione"
                          noOptionsMessage={(inputValue) => `Sem resultados`}
                          onChange={(e) => setCitySelected(e)}
                          options={cityAddress}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                      <Col md={4}>
                        <Label htmlFor="pais" className="col-md-12 col-form-label">País</Label>
                        <AvField
                          name="pais"
                          placeholder="País"
                          type="text"
                          errorMessage="Campo obrigatório."
                          className="form-control"
                          value={country}
                          validate={{ required: { value: true } }}
                          onChange={(e) => setCountry(e.target.value)}
                          id="pais"
                        />
                      </Col>
                      <Col md={6}>
                        <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Nome do Empreendimento <small>(Apenas usuários premium podem visualizar essa informação)</small></Label>
                          <Input
                          type="text"
                          placeholder="Nome do emprendimento"
                          id="nomeEmpreendimento" 
                          value={enterprise}
                          onChange={(e) => setEnterprise(e.target.value)}/>
                      </Col>
                      {(propertyType == 12 || propertyType == 34) ?
                        <Col md={3}>
                          <Label htmlFor="unidade" className="col-md-12 col-form-label">Unidade <small>(nunca é divulgado)</small></Label>
                          <AvField
                            name="unidade"
                            placeholder="Unidade apto."
                            type="text"
                            errorMessage="Campo obrigatório."
                            className="form-control"
                            value={unit}
                            validate={{ required: { value: true } }}
                            onChange={(e) => setUnit(e.target.value)}
                            id="unidade"
                          />
                        </Col>
                      :
                        <></>
                      }
                      {(propertyType == 12 || propertyType == 34) ?
                          <Col md={3}>
                            <Label htmlFor="unidade" className="col-md-12 col-form-label">Andar <small>(nunca é divulgado)</small></Label>
                            <AvField
                              name="andar"
                              placeholder="Andar apto."
                              type="text"
                              errorMessage="Campo obrigatório."
                              className="form-control"
                              value={floor}
                              validate={{ required: { value: true } }}
                              onChange={(e) => setFloor(e.target.value)}
                              id="andar"
                            />
                          </Col>
                        :
                          <></>
                        }
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} className="button-container">
                        <Button type="submit">Criar imóvel</Button>
                      </Col>
                    </Row>
                  </Container>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {createError ? (
        <SweetAlert
          title="O cadastro falhou, revise os campos e tente novamente."
          timeout={4000}
          showCloseButton={false}
          showConfirm={false}
          error
          onConfirm={() => setCreateError(false)}
        ></SweetAlert>
      ) : null}

      {createSuccess ? (
        <SweetAlert
          title="Seu imóvel foi criado, agora complete com o máximo de informações possíveis."
          showCloseButton={false}
          showConfirm={true}
          success
          onConfirm={() => {
            setTimeout(() => {
              window.open('/editar-imovel?' + responseId, '_parent')
            }, 100);
          }}
        ></SweetAlert>
      ) : null}

      <div id="load" className="load">
        <Spinner className="me-2" color="success" />
      </div>

      {(localStorage.getItem('access') !== 'A' && localStorage.getItem('access') !== 'T' && localStorage.getItem('access') !== 'S')  &&
        <Modal
            isOpen={modalFullProperties}
            toggle={() => setModalFullProperties(!modalFullProperties)}
            centered={true}
        >
            <ModalHeader toggle={() => {
              if(imoveis.length === pCount.length){
                setTimeout(() => {
                  window.open('/dashboard', '_parent')
                }, 300);
              }else{
                setModalFullProperties(!modalFullProperties)
              }
            }}
            style={{justifyContent:'center'}}
            >
            <h3 style={{fontSize:'20px', fontWeight:'600', color:'#3E5E3F'}}>Adicionar novo imóvel</h3>
            </ModalHeader>
            <ModalBody>        
              <Row style={{marginLeft:'0', marginTop:'0'}}>
                <Col xs={12}>
                  {imoveis.length === pCount.length &&
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'20px', flexDirection:'column'}}>
                      <Row style={{alignItems:'center', justifyContent:'center'}}>
                        <Col xs={12} style={{marginTop:'20px', textAlign:'center'}}>
                          <img src={Triste}></img>
                        </Col>
                        <Col xs={12} style={{marginTop:'30px'}}>
                          <p style={{fontSize:'16px', textAlign:'center'}}><b style={{fontWeight:'600'}}>Você alcançou o limite de imóveis.</b></p>
                        </Col>
                        <Col xs={12} style={{marginTop:'20px'}}>
                          <p style={{fontSize:'14px', textAlign:'center'}}>Quer adicionar mais imóveis?</p>
                        </Col>
                        {verifyPremium ?
                        <>
                          <Col xs={12} style={{marginTop:'10px', marginBottom:'20px', textAlign:'center'}}>
                            <Button onClick={() => {
                              setTimeout(() => {
                                window.open('https://wa.me/554741081865', '_blank')
                              }, 300);
                            }}>Sim, quero fazer um upgrade no meu plano!</Button>
                          </Col>
                        </>
                        :
                        <>
                          <Col xs={12} style={{marginTop:'10px', marginBottom:'20px', textAlign:'center'}}>
                            <Button onClick={() => {
                              setTimeout(() => {
                                window.open('/home', '_parent')
                              }, 300);
                            }}>Sim, quero assinar o Plano Premium!</Button>
                          </Col>
                        </>
                        }
                      </Row>
                    </div>
                  }
                  {imoveis.length <= (pCount.length - 1) &&
                    (imoveis.length === 0 ?
                      (verifyPremium ? 
                        (<div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'20px', flexDirection:'column'}}>
                          <Row style={{alignItems:'center', justifyContent:'center'}}>
                            <Col xs={12} style={{marginTop:'20px', textAlign:'center'}}>
                              <img src={PrimeiroImovel}></img>
                            </Col>
                            <Col xs={12} style={{marginTop:'30px'}}>
                              <p style={{fontSize:'16px', textAlign:'center'}}>Você está dicionando<br></br><b style={{fontWeight:'600'}}>seu primeiro imóvel como {localStorage.getItem('access') === 'C' ? 'corretor' : 'imobilária'} Premium</b><br></br>e dando um grande passo para alavancar suas vendas!</p>
                            </Col>
                            <Col xs={12} style={{marginTop:'30px'}}>
                              <p style={{fontSize:'14px', textAlign:'center'}}>Lembre-se: No seu plano, você ainda pode colocar mais {pCount.length - imoveis.length} imóveis.</p>
                            </Col>
                          </Row>
                        </div>)
                        :
                        (<div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'20px', flexDirection:'column'}}>
                          <Row style={{alignItems:'center', justifyContent:'center'}}>
                            <Col xs={12} style={{marginTop:'20px', textAlign:'center'}}>
                              <img src={PrimeiroImovel}></img>
                            </Col>
                            <Col xs={12} style={{marginTop:'30px'}}>
                              <p style={{fontSize:'16px', textAlign:'center'}}>Você está adicionando<br></br><b style={{fontWeight:'600'}}>seu primeiro imóvel na Fast Sale</b><br></br>e dando um grande passo para alavancar suas vendas!</p>
                            </Col>
                            <Col xs={12} style={{marginTop:'30px'}}>
                              <p style={{fontSize:'14px', textAlign:'center'}}>Lembre-se: No seu plano, você ainda pode colocar mais {pCount.length - imoveis.length} imóveis.</p>
                            </Col>
                          </Row>
                        </div>))
                    :
                      (<div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'20px', flexDirection:'column'}}>
                        <Row style={{alignItems:'center', justifyContent:'center'}}>
                          <Col xs={12} style={{marginTop:'20px', textAlign:'center'}}>
                            <img src={VamosNessa}></img>
                          </Col>
                          <Col xs={12} style={{marginTop:'30px'}}>
                            <p style={{fontSize:'18px', textAlign:'center'}}>Vamos nessa!</p>
                          </Col>
                          <Col xs={12} style={{marginTop:'30px'}}>
                            <p style={{fontSize:'14px', textAlign:'center'}}>Lembre-se: No seu plano, você ainda pode colocar mais {pCount.length - imoveis.length} {(pCount.length - imoveis.length) === 1 ? 'imóvel' : 'imóveis'}.</p>
                          </Col>
                        </Row>
                      </div>))
                  }
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
                <Row style={{justifyContent:'flex-end', alignItems:'flex-end'}}>
                {imoveis.length === pCount.length ?
                  <Col className="col-auto">
                    <Button className="btn-light" color="#333" onClick={(e) => {
                        if(imoveis.length === pCount.length){
                          setTimeout(() => {
                            window.open('/dashboard', '_parent')
                          }, 300);
                        }else{
                          setModalFullProperties(false)
                        }
                    }}>Cancelar</Button>
                  </Col>
                :
                  <Col className="col-auto">
                    <Button className="btn-light" color="#333" onClick={(e) => {
                        setModalFullProperties(false)
                    }}>Continuar</Button>
                  </Col>
                }
                </Row>
            </ModalFooter>
        </Modal>
        }
    </div>
  )
}