import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { Button } from 'reactstrap';

const CpfInput = ({ onCpfChange, onExists }) => {
  const [cpf, setCpf] = useState('');
  const [isExist, setIsExist] = useState('');

  const handleCPFChange = (value) => {
    onCpfChange(value)
    setCpf(value)
  };

  const handleBlur = () => {
    if(cpf && cpf !== ''){
      verifyCpf(cpf);
    }
  };

  const verifyCpf = (cpf) => {
    axios
      .post(`${process.env.REACT_APP_API}/api/cpf/verify`, {
        cpf: cpf.replace(/[.-]/g, ''),
        user_type: 'B'
      })
      .then((response) => {
        if(response.data.exists === true){
          setIsExist('CPF já cadastrado como corretor.')
        }else{
          setIsExist('')
        }
        
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    onExists(isExist);
  }, [isExist]);

  return (
    <>
    <InputMask
      mask="999.999.999-99"
      placeholder="000.000.000-00"
      value={cpf}
      onChange={(e) => handleCPFChange(e.target.value)}
      onBlur={handleBlur}
      inputMode="numeric"
    />
    {isExist !== '' && (
      <div style={{ textAlign: 'center' }}>
        <p style={{ color: 'red' }}>{isExist}<br></br>Faça login, ou utilize outro CPF.</p>
        <Button
          onClick={() => {
            window.open('./login', '_parent');
          }}
        >
          Fazer login
        </Button>
      </div>
    )}
    </>
  );
};

export default CpfInput;