import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import './styles.scss';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export const ForPremiumUser = ({open, onClose}) => {
    return(
    <Modal
        isOpen={open}
        toggle={onClose}
        size="xl"
        centered={true}
        scrollable={true}
    >
        <ModalHeader toggle={onClose} style={{padding:'0 80px'}}>
            <h3 style={{fontSize:'18px'}}>Ops</h3>
        </ModalHeader>
        <ModalBody>
            <Label>Esta é uma função apenas para assinantes</Label>

            <Row style={{marginTop:'20px', justifyContent:'center'}}>
                {/* <Col className="col-auto" onClick={onClose}><Button color="dark">Cancelar</Button></Col> */}
                <Col className="col-auto"><a href="https://api.whatsapp.com/send?phone=554741081865&text=Quero%20ser%20assinante%20Fast%20Sale" target="_blank"><Button>Quero assinar!</Button></a></Col>
            </Row>
        </ModalBody>
    </Modal>
    )
}