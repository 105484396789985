import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

function FiltersSection1({applyCount}) {

  const[typeOption, setTypeOption] = useState([])
  const[count, setCount] = useState(0)

  useEffect(() => {
    if(typeOption.includes('Apartamento')){
      document.getElementById('apartment').classList.add('active')
    }else{
      document.getElementById('apartment').classList.remove('active')
    }
    if(typeOption.includes('Casa')){
      document.getElementById('house').classList.add('active')
    }else{
      document.getElementById('house').classList.remove('active')
    }
    if(typeOption.includes('Comercial')){
      document.getElementById('commercial').classList.add('active')
    }else{
      document.getElementById('commercial').classList.remove('active')
    }
    if(typeOption.includes('Outros')){
      document.getElementById('outros').classList.add('active')
    }else{
      document.getElementById('outros').classList.remove('active')
    }
  }, [count])

   useEffect(() => {
    document.getElementById('house').classList.remove('active')
    document.getElementById('apartment').classList.remove('active')
    document.getElementById('commercial').classList.remove('active')
    document.getElementById('outros').classList.remove('active')
    if(localStorage.getItem('filtro-categoria')){
      if(localStorage.getItem('filtro-categoria').split(',').includes('Apartamento')){
        document.getElementById('apartment').classList.add('active')
      }
      if(localStorage.getItem('filtro-categoria').split(',').includes('Casa')){
        document.getElementById('house').classList.add('active')
      }
      if(localStorage.getItem('filtro-categoria').split(',').includes('Comercial')){
        document.getElementById('commercial').classList.add('active')
      }
      if(localStorage.getItem('filtro-categoria').split(',').includes('Outros')){
        document.getElementById('outros').classList.add('active')
      }
    }
   }, [applyCount]);

  return (
    <Container className="sectionFilter1">
      {/* <Row>
        <Col>
          <h3>Categoria</h3>
        </Col>
      </Row> */}
      <Row style={{gap:'20px 0', marginBottom:'10px', marginTop:'10px'}}>
        <Col xs={6}>
          <button className="option" id='apartment' onClick={(e) => {
            if(typeOption.includes('Apartamento')){
              var index = typeOption.indexOf('Apartamento')
              if (index > -1) {
                typeOption.splice(index, 1)
              }
            }else{
              typeOption.push('Apartamento')
              localStorage.setItem('filtro-categoria', typeOption)
            }
            setCount(count + 1)
            }}>
            <div className="icon"></div>
            <p>Apartamento</p>
          </button>
        </Col>
        <Col xs={6}>
          <button className="option" id='house' onClick={(e) => {
            if(typeOption.includes('Casa')){
              var index = typeOption.indexOf('Casa')
              if (index > -1) {
                typeOption.splice(index, 1)
              }
            }else{
              typeOption.push('Casa')
              localStorage.setItem('filtro-categoria', typeOption)
            }
            setCount(count + 1)
            }}>
            <div className="icon"></div>
            <p>Casa</p>
          </button>
        </Col>
        <Col xs={6}>
          <button className="option" id='commercial' onClick={(e) => {
            if(typeOption.includes('Comercial')){
              var index = typeOption.indexOf('Comercial')
              if (index > -1) {
                typeOption.splice(index, 1)
              }
            }else{
              typeOption.push('Comercial')
              localStorage.setItem('filtro-categoria', typeOption)
            }
            setCount(count + 1)
            }}>
            <div className="icon"></div>
            <p>Comercial</p>
          </button>
        </Col>
        <Col xs={6}>
          <button className="option" id='outros' onClick={(e) => {
            if(typeOption.includes('Outros')){
              var index = typeOption.indexOf('Outros')
              if (index > -1) {
                typeOption.splice(index, 1)
              }
            }else{
              typeOption.push('Outros')
              localStorage.setItem('filtro-categoria', typeOption)
            }
            setCount(count + 1)
            }}>
            <div className="icon"></div>
            <p>Outros</p>
          </button>
        </Col>
      </Row>
    </Container>
  );
}

export default FiltersSection1;