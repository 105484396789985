import logo from '../../../assets/images/logo-dark.png'
import Whats from '../../../components/Whats/Whats';
import './styles.css';
// import fireworks from '../../../../../assets/images/logo-dark.png'

function CheckoutMensagem(){

    return(
        <div className="checkout">
            <div className="header">
                <div className="header-content">
                    <img src={logo} />
                </div>
            </div>
            <div className="checkout-content">
                <div className="container-msg">
                    <div className="checkout-mensagem">
                        <img src={logo} alt="" srcset="" />
                        <h1>Um pequeno valor na fatura,</h1>
                        <h1>um grande passo para o sucesso.</h1>
                        <div style={{padding:'15px 0'}}>
                            <p>A administradora do seu cartão já fez uma pré-reserva do valor e agora a gente vai analisar os dados do seu pagamento, tá?</p>
                            <p style={{fontWeight:'600', fontSize:'16px'}}>Pode deixar que te avisamos por e-mail.</p>
                        </div>
                        <a href="./../login">Acessar a plataforma Fast Sale</a>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="footer-content">
                    <p>FAST SALE TECNOLOGIA LTDA</p>
                    <p>39.985.590/0001-94</p>
                </div>
            </div>
            <Whats/>
        </div>
    )
}

export default CheckoutMensagem;