import { useState } from 'react';
import { useEffect } from 'react';
// import { api } from '../../../Services/api';
import './styles.css';
import Axios from 'axios';
import { Col } from 'reactstrap';

function ProfileToolOwner({user}) {

  const [activeCaptation, setActiveCaptation] = useState(0)
  const [greeting, setGreeting] = useState('')

  const [newScheduling, setNewScheduling] = useState()
  const [newViews, setNewViews] = useState()

  useEffect(() => {
    const date = new Date()

   if(date.getHours() < 13){
     setGreeting('Bom dia!')
   }
   if(date.getHours() > 12 && date.getHours() < 19){
     setGreeting('Boa tarde!')
   }
   if(date.getHours() > 18){
     setGreeting('Boa noite!')
   }
  }, [activeCaptation])

  useEffect(() => {
    // if(user && localStorage.getItem('access') === 'P'){
      Axios.post(process.env.REACT_APP_API + '/api/auth/owner-counts', {
        user_id: localStorage.getItem('id'),
        type: 'P'
      }, 
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        console.log(response.data)
        setNewScheduling(response.data.schedulings.length)
        setNewViews(response.data.visualizations.length)
      })
    // }
  }, [])

  return (
      <div className="ProfileToolOwner">
          <div className="container">
            <div className="row">
              <Col xs={4}>
                <div className="profile-photo">
                  <div className="user-photo"></div>
                </div>
              </Col>
              <Col xs={8}>
                <div className="profile-name">
                    <p>{greeting}</p>
                    <h3 style={{fontSize:'16px'}}>{user.name}</h3>
                </div>
              </Col>
            </div>
            <div className="row">
              <div className="col" style={{margin:'10px 0'}}>
                <p>Enquanto você não estava, seu imóvel teve novas interações</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <small>Visualizações</small>
              </div>
              <div className="col interactions">
                <span>+{newViews}</span>
              </div>
            </div>
            <div className="row" style={{marginTop:'10px'}}>
              <div className="col">
                <small>Visitas Agendadas</small>
              </div>
              <div className="col interactions">
                <span>+{newScheduling}</span>
              </div>
            </div>
          </div>
      </div>
    );
  }
  
export default ProfileToolOwner;  