import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

const id = window.location.search.replace('?id=', '')

class TypeInformation extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      areaPrivativa:'',
      areaTotal:'',
      descricao:'',
      rooms:'',
      suites:'',
      garage:'',
      bathrooms:'',
      }
      this.updateEnterprise = this.updateEnterprise.bind(this);
    }

    componentDidMount(){
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types/' + id, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            this.setState({enterprise: response.data})

            this.setState({
                areaPrivativa:response.data.private_area,
                areaTotal:response.data.total_area,
                descricao:response.data.description,
                rooms:response.data.rooms,
                suites:response.data.suites,
                garage: response.data.garage,
                bathrooms: response.data.bathrooms,
              })
         })
        })
         .catch(response =>{
            setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
            })  
    }

      updateEnterprise(event) {
        if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
            this.setState({error_auth:true})
        }else{
        document.getElementById('load').classList.add('active')

        const data = new FormData();
        data.append('private_area', this.state.areaPrivativa ? this.state.areaPrivativa : 0)
        data.append('total_area', this.state.areaTotal ? this.state.areaTotal : 0)
        data.append('description', this.state.descricao ? this.state.descricao : '')
        data.append('rooms', this.state.rooms ? this.state.rooms : 0)
        data.append('suites', this.state.suites ? this.state.suites : 0)
        data.append('garage', this.state.garage ? this.state.garage : 0)
        data.append('bathrooms', this.state.bathrooms ? this.state.bathrooms : 0)

        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types-update/'+ id, data, {
        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
            document.getElementById('load').classList.remove('active')
            setTimeout(() => {
                window.open('./../empreendimento-tipo?id=' + id, '_parent')
            }, 100);
        })
    }
   }

    render(){
        const { enterprise } = this.state;

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                            <Container style={{marginTop:'20px'}}>
                                                <Row style={{margin:'10px 0'}}>
                                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o tipo de unidade</h5></Col>
                                                </Row>
                                                <Row>
                                                <Col md={2}>
                                                <AvGroup>
                                                    <Label className="col-md-12 col-form-label">Área privativa</Label>
                                                    <AvField
                                                    name="areaPrivativa"
                                                    placeholder="em m²"
                                                    type="number"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.areaPrivativa}
                                                    onChange={(e) => {
                                                        this.setState({areaPrivativa: e.target.value})
                                                    }}
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                <Col md={2}>
                                                <AvGroup>
                                                    <Label className="col-md-12 col-form-label">Área total</Label>
                                                    <AvField
                                                    name="areaTotal"
                                                    placeholder="em m²"
                                                    type="text"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.areaTotal}
                                                    onChange={(e) => {
                                                        this.setState({areaTotal: e.target.value})
                                                    }}
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                <Col md={2}>
                                                <AvGroup>
                                                    <Label className="col-md-12 col-form-label">Quartos</Label>
                                                    <AvField
                                                    name="quartos"
                                                    placeholder="Nº de quartos"
                                                    type="number"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.rooms}
                                                    onChange={(e) => {
                                                        this.setState({rooms: e.target.value})
                                                    }}
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                <Col md={2}>
                                                <AvGroup>
                                                    <Label className="col-md-12 col-form-label">Suítes</Label>
                                                    <AvField
                                                    name="suites"
                                                    placeholder="Nº de suítes"
                                                    type="number"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.suites}
                                                    onChange={(e) => {
                                                        this.setState({suites: e.target.value})
                                                    }}
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                <Col md={2}>
                                                <AvGroup>
                                                    <Label className="col-md-12 col-form-label">Vagas de garagem</Label>
                                                    <AvField
                                                    name="garagem"
                                                    placeholder="Nº de vagas de garagem"
                                                    type="number"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.garage}
                                                    onChange={(e) => {
                                                        this.setState({garage: e.target.value})
                                                    }}
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                <Col md={2}>
                                                <AvGroup>
                                                    <Label className="col-md-12 col-form-label">Banheiros</Label>
                                                    <AvField
                                                    name="banheiros"
                                                    placeholder="Nº de banheiros"
                                                    type="number"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.bathrooms}
                                                    onChange={(e) => {
                                                        this.setState({bathrooms: e.target.value})
                                                    }}
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                </Row>
                                            </Container>
                                            <Container>
                                                <Row style={{margin:'10px 0'}}>
                                                <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Descrição do tipo de unidade</h5></Col>
                                                </Row>
                                                <Row>
                                                <Col md={12}>
                                                <AvGroup>
                                                    <Label htmlFor="descricaoImovel" className="col-md-12 col-form-label">Descrição</Label>
                                                    <AvField
                                                    name="descricaoImovel"
                                                    placeholder={`Ex.:Nosso modelo de planta de apartamento é um exemplo de luxo e funcionalidade. Cada detalhe foi cuidadosamente planejado para atender às necessidades de um estilo de vida contemporâneo e exigente.\nEste apartamento apresenta uma área ampla e aberta, com uma espaçosa sala de estar que se conecta a uma cozinha gourmet. As janelas panorâmicas oferecem vistas deslumbrantes da cidade e inundam o espaço com luz natural. O piso de madeira de alta qualidade proporciona uma sensação de calor e elegância.\nA cozinha é um sonho para os amantes da culinária, equipada com aparelhos de última geração, armários elegantes e uma ilha central que também serve como uma área de refeições. A sala de estar é perfeita para relaxar ou receber convidados, com acesso direto a uma varanda privativa.\nO quarto principal é um refúgio de tranquilidade, com amplo espaço, armários embutidos e um banheiro privativo com acabamentos de alto padrão. Os quartos adicionais são ideais para família ou hóspedes, com opções versáteis de layout.\nEste modelo de planta de apartamento é um exemplo de como o design inteligente pode combinar perfeitamente com a estética sofisticada. Oferece o equilíbrio ideal entre estilo e conforto, proporcionando um lar excepcional para aqueles que buscam o melhor em qualidade de vida urbana.`}
                                                    type="textarea"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    rows="10"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.descricao}
                                                    onChange={(e) => this.setState({descricao: e.target.value})}
                                                    id="descricaoImovel"
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                </Row>
                                            </Container>
                                            <Container>
                                                <Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                                    <Col className="col-auto">
                                                        <Button className="btn-dark" onClick={() => {
                                                            setTimeout(() => {
                                                                window.open('./../empreendimento-tipo?id=' + id, '_parent')
                                                            }, 100);
                                                        }}>Voltar</Button>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={this.updateEnterprise}>Salvar</Button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                    </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                {this.state.error_auth ? (
                <SweetAlert
                    error
                    title='Ops'
                    onConfirm={() => this.setState({ error_auth: false })}
                >
                    Você não tem permissão para esta ação.
                </SweetAlert>
            ) : null}
            </div>
        )
    }

}

export default TypeInformation;