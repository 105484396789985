import { Input, Label } from 'reactstrap';
import './styles.scss';
import { useEffect, useState } from 'react';

export const ParcelsInput = ({handleInputChange, product = null, recurrence = null, discount = null}) => {
  const [inputValue, setInputValue] = useState('')
  const [numberParcels, setNumberParcels] = useState(0)
  const [parcelsOptions, setParcelsOptions] = useState([])

  const handleChange = (event) => {
    setInputValue(event.target.value);

    handleInputChange(event)
  };

  useEffect(() => {
    if(recurrence && product){
      const numberParcels = (() => {
        switch (recurrence) {
          case 'month': return 1;
          case 'third': return 3;
          case 'six': return 6;
          case 'year': return 12;
          default: return 0;
        }
      })()

      if(numberParcels > 1){

        const newPrice = (parseFloat(product.price) * 12) - ((parseFloat(product.price) * 12) * ((product.product_plans[0].card_year_discount / 10000)));

        const options = [];
        for (let i = 1; i <= numberParcels; i++) {
          options.push({
            value: i,
            label: `${i}x de ${(discount ? ((newPrice) / i - (discount / 100) * (newPrice) / i) : (newPrice) / i).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`
          });
        }
  
        setParcelsOptions(options);

      }else{
        setParcelsOptions({
          value:1,
          label:'1x de ' + (discount ? ((discount / 100) * product.price) : product.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
        })
      }
    }
  }, [recurrence, product, discount])

  return(
    product && recurrence && parcelsOptions.length > 0 &&
    <fieldset>
      <Label>Número de parcelas</Label>
      <Input
        type="select"
        name="parcels"
        value={inputValue}
        onChange={handleChange}
      >
        {parcelsOptions.map((item, index) => (
          <option value={item.value}>{item.label}</option>
        ))}
      </Input>
    </fieldset>
  )
}