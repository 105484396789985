import Axios from 'axios';
import './styles.scss';
import Select from "react-select";
import React, { useEffect, useState } from 'react';
import { Button, Input, Label, Spinner, InputGroup } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import Flatpickr from "react-flatpickr"
import 'flatpickr/dist/l10n/pt';
import "flatpickr/dist/themes/airbnb.css";

require('moment/locale/pt.js');

export const NewCoupon = ({open, onClose}) => {

    const flatpickrRefStart = React.createRef();

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [discount, setDiscount] = useState(0)
    const [status, setStatus] = useState([])
    const [userId, setUserId] = useState([])
    const [userType, setUserType] = useState([])
    const [usersList, setUsersList] = useState([])
    const [loading, setLoading] = useState(false)
    const [validCreate, setValidCreate] = useState(false)
    const [error_dlg , setError_dlg] = useState(false)
    const [maturity, setMaturity] = useState('')

    const userTypes = [
        {label:'Corretor', value:'C'},
        {label:'Imobiliária', value:'I'},
        {label:'Construtora', value:'T'},
        {label:'Administrativo', value:'A'},
    ]

    const handleCreateCoupon = () => {
        Axios.post(process.env.REACT_APP_API + '/api/auth/new-coupon', {
            name:name,
            description:description,
            discount:discount,
            status:status.value || 0,
            user_id:userId.value,
            user_type:userType.value,
            maturity:maturity
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                handleClear()
                onClose()
            }).catch(response => {
                setError_dlg(true)
            })
    }

    const handleClear = () => {
        setName('')
        setDescription('')
        setDiscount(0)
        setStatus([])
        setUserId([])
        setUserType([])
        setUsersList([])
        setMaturity(null)
    }

    useEffect(() => {
        if(name !== '' && description !== '' && discount !== 0 && userId.value && userType.value){
            setValidCreate(true)
        }
    }, [name, description, discount, status, userId, userType])

    const handleUsers = (e) => {
        setLoading(true)
        Axios.get(process.env.REACT_APP_API + '/api/auth/active-users',
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            setUsersList(response.data?.map(item => ({
                value: item.id,
                label: item.name
              })))
              setLoading(false)
          })
      }
    
      const handleBrokers = (e) => {
        setLoading(true)
        Axios.get(process.env.REACT_APP_API + '/api/auth/brokers-select',
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
              setUsersList(response.data?.map(item => ({
                value: item.id,
                label: item.name
              })))
              setLoading(false)
          })
      }
    
      const handleRealEstates = (e) => {
        setLoading(true)
        Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-select',
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            setUsersList(response.data?.map(item => ({
                value: item.id,
                label: item.name
              })))
              setLoading(false)
          })
      }
    
      const handleConstruction = (e) => {
        setLoading(true)
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions',
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            setUsersList(response.data?.map(item => ({
                value: item.id,
                label: item.name
              })))
              setLoading(false)
          })
      }
    

    useEffect(() => {
        if(userType.value === 'A'){
            handleUsers()
        }
        if(userType.value === 'C'){
            handleBrokers()
        }
        if(userType.value === 'I'){
            handleRealEstates()
        }
        if(userType.value === 'T'){
            handleConstruction()
        }
    }, [userType])


    return(
        <>
            <div className={`add-new-coupon ${open ? 'active' : ''}`}>
                <span className="close-modal" onClick={onClose}><i className="ri-close-line"></i></span>

                <div className="input-container">
                    <Label>Nome (Cód. Cupom)</Label>
                    <Input type="text" value={name} onChange={(e) => setName(e.target.value.toUpperCase())}></Input>
                </div>

                <div className="input-container">
                    <Label>Breve descrição</Label>
                    <Input type="text" value={description} onChange={(e) => setDescription(e.target.value)}></Input>
                </div>

                <div className="input-container">
                    <Label>Desconto</Label>
                    <Input type="number" value={discount} onChange={(e) => {
                        if(e.target.value >= 0){
                            setDiscount(e.target.value)
                        }
                    }}></Input>
                </div>

                <div className="input-container">
                    <Label>Dono do cupom</Label>

                    <Select
                        value={userType}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => setUserType(e)}
                        options={userTypes}
                        classNamePrefix="select2-selection"
                    />

                    <span>
                        {loading ?
                            <Spinner/>
                        :
                        usersList.length > 0 &&
                            <Select
                            value={userId}
                            placeholder="Selecione"
                            noOptionsMessage={(inputValue) => `Sem resultados`}
                            onChange={(e) => setUserId(e)}
                            options={usersList}
                            classNamePrefix="select2-selection"
                            />
                        }
                    </span>
                </div>

                <div className="input-container">
                    <Label>Status</Label>
                    
                    <Select
                        value={status}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => setStatus(e)}
                        options={[{
                            label:'Ativo',
                            value:1
                        },{
                            label:'Rascunho',
                            value:0
                        }]}
                        classNamePrefix="select2-selection"
                    />
                </div>

                <div className="input-container">
                    <Label htmlFor="validade" className="col-md-12 col-form-label">Validade</Label>
                    <InputGroup>
                        <Flatpickr
                            className="form-control d-block"
                            id="validade"
                            placeholder="Selecione"
                            options={{
                                altInput: true,
                                altFormat: "j, F, Y",
                                dateFormat: "Y-m-d",
                                locale: 'pt'
                            }}
                            ref={flatpickrRefStart} 
                            onChange={(e) => {
                                setMaturity(moment(e.toString()).format('DD-MM-YYYY'))
                            }}
                        />
                    </InputGroup>
                </div>

                <div className="coupon-buttons-container">
                    {validCreate ?
                        <Button onClick={handleCreateCoupon}>Criar cupom</Button>
                        :
                        <Button disabled>Todos campos são obrigatórios</Button>
                    }
                </div>
            </div>
            <div className={`add-new-coupon-overlay ${open ? 'active' : ''}`} onClick={onClose}></div>

            {error_dlg ? (
                <SweetAlert
                    error
                    title="O cupom não pode ser criado."
                    onConfirm={() => setError_dlg(false)}
                >
                    Já existe um cupom com esse nome.
                </SweetAlert>
            ) : null}
        </>
    )
}

// $validated = $request->validate([
//     'name' => 'required|string|max:255',
//     'description' => 'nullable|string',
//     'discount' => 'required|numeric',
//     'status' => 'required|boolean',
//     'user_id' => 'nullable|integer',
//     'user_type' => 'nullable|string|max:255',
// ]);