import {useEffect, useState} from 'react';
import { Col, Container, Label, Row } from 'reactstrap';

function valuetext(value) {
  return `${value}°C`;
}

function FiltersSection4({imoveis, applyCount}) {

  const[areaMinVariation, setAreaMinVariation] = useState();
  const[areaMaxVariation, setAreaMaxVariation] = useState();

  const [allImoveis, setAllImoveis] = useState([])

  const [count, setCount] = useState(0)

    useEffect(() => {
      if(count === 0 && imoveis.length > 0){
        setAllImoveis(imoveis)
        setAreaMinVariation(parseInt((imoveis?.sort(function(a, b){return a?.endorsed_measures - b?.endorsed_measures})?.slice(0,1))[0]?.endorsed_measures))
        setAreaMaxVariation(parseInt((imoveis?.sort(function(a, b){return a?.endorsed_measures - b?.endorsed_measures})?.slice((imoveis.sort(function(a, b){return a?.endorsed_measures - b?.endorsed_measures})?.length - 1), imoveis?.sort(function(a, b){return a?.endorsed_measures - b?.endorsed_measures})?.length))[0]?.endorsed_measures))
        setCount(count => count + 1)
      }
    },[imoveis])

    useEffect(() => {
      setAreaMinVariation('')
      setAreaMaxVariation('')
    }, [applyCount])

  return (
    <Container className="sectionFilter4">
      <Row>
        <Col xs={6} className="colInputPrice" style={{marginTop:'10px'}}>
          <Label className="form-label">de m²</Label>
          <input
            type="number"
            value={areaMinVariation}
            inputMode="numeric"
            onChange={(e) => {
              setAreaMinVariation(e.target.value)
              localStorage.setItem('min_area_range_filter', e.target.value)
              }}
          />
        </Col>
        <Col xs={6} className="colInputPrice" style={{marginTop:'10px'}}>
          <Label className="form-label">até m²</Label>
          <input
            type="number"
            value={areaMaxVariation}
            inputMode="numeric"
            onChange={(e) => {
              setAreaMaxVariation(e.target.value)
              localStorage.setItem('max_area_range_filter', e.target.value)
              }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default FiltersSection4;