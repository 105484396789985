import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, Button, CardHeader, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, CardText } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_singleProperty.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fire from './../../assets/images/Icons/fogooutlet.png';
import SingleImovelCardCarousel from './Carousel';
import Maps from './Maps';
import SingleAction from './UnitAction';
import ContractUse from './ContractUse';
import { saveAs } from 'file-saver';

import Integrar from './../../assets/images/popups/integrar.png'
import IntegrarMobile from './../../assets/images/popups/integrarMobile.png'
import Download from './../../assets/images/popups/download.png'
import Compartilhar from './../../assets/images/popups/compartilhar.png'

import CanalPro from './../../assets/images/Icons/canalpro.png'
import ChavesMao from './../../assets/images/Icons/chavesnamao.png'
import SeuSite from './../../assets/images/Icons/seusite.png'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/navigation";
// import "swiper/scrollbar.css"

import { Navigation } from "swiper"
import ImovelCard from '../../components/ImovelCard/ImovelCard';

import NoPremiumProperty from '../../components/noPremium/noPremiumProperty';
import NoDocsProperty from '../../components/noPremium/noDocsProperty';
import UnitAction from './UnitAction';
import Lightbox from 'react-image-lightbox';

require('moment/locale/pt.js');

class SingleUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imovel:[],
      col1: true,
			col2: false,
			col3: false,
			col5: false,
      modal_large: false,
      favoritado: false,
      acceptContract: false,
      favoritos:[],
      relacionados:[],
      modal_integration: false,
      modal_premium: false,
      modal_plantas: false,
      modal_download: false,
      modal_share: false,
      verifyContract: false,
      verifyPremium: false,
      integrationChannel:null,
      integrationType:0,
      allPhotos:[],
      integration_success:false,
      origem:null,
      integration_error:false,
      verifyFit: false,
      url:"",
      photoIndex: 0,
      verifyMktFs: true,
      verifyDocs: true,
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Imóveis", link: "/imoveis" },
        { title: '', link: "#" },
    ],
    }
    this.handleContractClosed = this.handleContractClosed.bind(this);
    this.tog_large = this.tog_large.bind(this);
    this.tog_integration = this.tog_integration.bind(this)
    this.tog_premium = this.tog_premium.bind(this)
    this.tog_plantas = this.tog_plantas.bind(this)
    this.tog_download = this.tog_download.bind(this)
    this.tog_share = this.tog_share.bind(this)
    this.t_col1 = this.t_col1.bind(this);
		this.t_col2 = this.t_col2.bind(this);
		this.t_col3 = this.t_col3.bind(this);
		this.t_col4 = this.t_col4.bind(this);
		this.t_col5 = this.t_col5.bind(this);
  }


  componentDidMount(){
    let slug = this.props.match.params

    const searchOwnerProperty = window.location.search;

      if(searchOwnerProperty === '?owner'){
        this.setState({url : "/api/auth/property-slug-pending/"})
      }else{
        this.setState({url: "/api/auth/enterprise/enterprise-units/"})
      }

      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {

          Axios.post(process.env.REACT_APP_API + this.state.url + slug.slug, {},
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            this.setState({imovel: response.data})

            this.setState((prevState) => {
              const allPhotos = response.data.photos || [];
              // const enterprisePhotos = response.data.enterprise_photos || [];
              const allPlans = response.data.plans || [];
              // const enterprisePlans = response.data.enterprise_plans || [];
            
              const updatedPhotosAndPlans = prevState.allPhotos
              .concat(allPlans)
              .concat(allPhotos.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1));

              return {
                allPhotos: updatedPhotosAndPlans
              };
            });
            localStorage.setItem('view_property_sku', response.data.sku)

            Axios.post(process.env.REACT_APP_API + '/api/auth/related-properties-units/',{
                category:12,
                city:response.data.address_properties.city,
                district:response.data.address_properties.district,
              },
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(resp => {
              this.setState({relacionados : resp.data})
            })

            //Verifica usuário sem documentos
            if((localStorage.getItem('status') === '3' || localStorage.getItem('status') === '2') && (localStorage.getItem('access') === 'C' || localStorage.getItem('access') === 'I')){
              this.setState({verifyDocs: false})
            }


            this.setState({breadcrumbItems: [
              { title: "Fast Sale", link: "/dashboard" }, 
              { title: "Imóveis", link: "/imoveis" },
              { title: response.data.sku, link: "#" },
            ]})


            const data = new FormData()
            if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
            if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
            data.append('enterprise_unit_id', response.data.id)
            data.append('enterprise_id', response.data.enterprise_id)
            data.append('type_action', 'view')
    
            Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-units-actions', data,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    

          })
          .catch(response =>{
            localStorage.removeItem("history_url")
             setTimeout(() => {
               window.open("/404", '_parent')
             }, 300);
          })


          Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
            type:localStorage.getItem('access'),
            user_id:localStorage.getItem('id')
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(res => {
            if(localStorage.getItem('access') === 'A' || localStorage.getItem('access') === 'T'){
              this.setState({verifyPremium: true})
            }else{
              if(res.data.premium === 1) {
                this.setState({verifyPremium: true})
              } else if(res.data.premium === 2) {
                this.setState({verifyFit: true})
              }
            }
          })

        })
        .catch(response =>{
          setTimeout(() => {
            window.open("/login", '_parent')
          }, 300);
        })
  }


  handleFavoritarClick = () => {
    const { favoritado, imovel } = this.state;
    const endpoint = favoritado
      ? "/api/auth/favorite-delete"
      : "/api/auth/favorite";
    this.setState({ favoritado: !favoritado });
    Axios.post(process.env.REACT_APP_API + endpoint, {
      user_id: parseInt(localStorage.getItem("id")),
      type: localStorage.getItem("access"),
      property_id: imovel.id
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    })
  };

  t_col1() {
		this.setState({ col1: !this.state.col1, col2: false, col3: false, col4: false, col5: false });
	}
	t_col2() {
		this.setState({ col2: !this.state.col2, col1: false, col3: false, col4: false, col5: false });
	}
	t_col3() {
		this.setState({ col3: !this.state.col3, col1: false, col2: false, col4: false, col5: false });
	}
  t_col4() {
		this.setState({ col4: !this.state.col4, col1: false, col2: false, col3: false, col5: false });
	}
	t_col5() {
		this.setState({ col5: !this.state.col5, col1: false, col2: false, col3: false, col4: false });
	}

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large
    }));
    this.removeBodyCss();
  }
  tog_plantas() {
    this.setState(prevState => ({
      modal_plantas: !prevState.modal_plantas
    }));
    this.removeBodyCss();
  }
  tog_integration(){
    this.setState(prevState => ({
      modal_integration: !prevState.modal_integration
    }));
    this.removeBodyCss();
  }
  tog_premium(){
    this.setState(prevState => ({
      modal_premium: !prevState.modal_premium
    }));
    this.removeBodyCss();
  }
  tog_download(){
    this.setState(prevState => ({
      modal_download: !prevState.modal_download
    }));
    this.removeBodyCss();
  }
  tog_share(){
    this.setState(prevState => ({
      modal_share: !prevState.modal_share
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  handleContractClosed() {
    this.setState({ acceptContract: false });
  }

  handleContractAccepted() {
    this.setState({acceptContract: false})
    this.setState(prevState => ({
      modal_integration: !prevState.modal_integration
    }));
    this.removeBodyCss();
    Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
      type:localStorage.getItem('access'),
      user_id:localStorage.getItem('id'),
      property_id:this.state.imovel.id
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(resp => {
      this.setState({verifyContract: true})
    })
    .catch(resp => {
      this.setState({verifyContract: false})
    })
  }

  handleContractAcceptedFotos() {
    this.setState({acceptContract: false})
    Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
      type:localStorage.getItem('access'),
      user_id:localStorage.getItem('id'),
      property_id:this.state.imovel.id
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(resp => {
      this.setState({verifyContract: true})
    })
    .catch(resp => {
      this.setState({verifyContract: false})
    })
  }

  handleContractAcceptedCaptador() {
    this.setState({acceptContract: false})
    Axios.post(process.env.REACT_APP_API + '/api/auth/log-talks', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      property_id: this.state.imovel?.id
    },
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        setTimeout(() => {
          window.open(`https://api.whatsapp.com/send?phone=55` + (this.state.imovel?.broker?.phone ? this.state.imovel?.broker?.phone : this.state.imovel?.real_estate?.phone ? this.state.imovel?.real_estate?.phone : '4741081865' )?.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, "") + `&text=Olá,%20tenho%20interesse%20no%20` + this.state.imovel?.title + " (" + this.state.imovel.sku + ')', "_blank")
        }, 300);
      })
      Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
        type:localStorage.getItem('access'),
        user_id:localStorage.getItem('id'),
        property_id:this.state.imovel.id
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(resp => {
        this.setState({verifyContract: true})
      })
      .catch(resp => {
        this.setState({verifyContract: false})
      })
  }

  handleContractAcceptedDownload() {
    this.setState({acceptContract: false})
    if(!this.state.verifyPremium && !this.state.verifyFit){
      //fazer o download COM logo
      this.tog_download()
    }else{
      //fazer o download SEM logo
      document.getElementById('load').classList.add('active')
      const axiosOptions = {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        }
      }
      Axios.post(process.env.REACT_APP_API + '/api/download-media/' + this.state.imovel?.id, {
          type:localStorage.getItem('access'),
          user_id:localStorage.getItem('id'),
        }, axiosOptions)
        .then((response) => {
          const blob = new Blob([response.data], {type: 'application/octet-stream'})
          const filename = this.state.imovel?.title + '(' + this.state.imovel?.sku + ').zip'
          saveAs(blob, filename)
          document.getElementById('load').classList.remove('active')
          const data = new FormData()
          data.append('type', localStorage.getItem('access'));
          data.append('user_id', localStorage.getItem('id'))
          Axios.post(process.env.REACT_APP_API + '/api/download-count/' + this.state.imovel?.id, data)
          document.getElementById('load').classList.remove('active')
        })
        .catch((e) => {
          document.getElementById('load').classList.remove('active')
        })
        Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', { //Contrato de parceria 
          type:localStorage.getItem('access'),
          user_id:localStorage.getItem('id'),
          property_id:this.state.imovel.id
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(resp => {
          this.setState({verifyContract: true})
        })
        .catch(resp => {
          this.setState({verifyContract: false})
        })
    }
  }

  handleContractAcceptedCompartilhar() {
    this.setState({acceptContract: false})
    if(!this.state.verifyPremium && !this.state.verifyFit){
      //fazer o download COM logo
      this.tog_share()
    }else{
      //fazer o download SEM logo
      Axios.post(process.env.REACT_APP_API + '/api/auth/log-shares', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        property_id: this.state.imovel?.id
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setTimeout(() => {
            window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(window.location.href.replace('?owner', '').replace(this.state.imovel?.slug, '').replace('imovel', 'compartilhar-imovel') + '?ce29=' + localStorage.getItem('access') + '&ba05a=' + localStorage.getItem('id') + '&83acd=' + this.state.imovel?.id ), '_blank');
          }, 300);
        })
        Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
          type:localStorage.getItem('access'),
          user_id:localStorage.getItem('id'),
          property_id:this.state.imovel.id
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(resp => {
          this.setState({verifyContract: true})
        })
        .catch(resp => {
          this.setState({verifyContract: false})
        })
    }
  }

  handleVerifyContract = (value) => {
    this.setState({ verifyContract: value });
    
  }

  handleButtonReleaseAll = () => {
    this.setState({acceptContract: false})
    Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
        type:localStorage.getItem('access'),
        user_id:localStorage.getItem('id'),
        property_id:this.state.imovel?.id
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      this.setState({verifyContract: true})
    })
    .catch(response => {
      this.setState({verifyContract: false})
    })
}

  render() {

    const {imovel, favoritado, verifyDocs, verifyContract, verifyPremium, verifyMktFs} = this.state;

    return (
      (verifyMktFs && verifyDocs) ?
      <div className="page-content singleProperty">
        <Container fluid={true}>
          <Breadcrumbs title={(imovel.sell_contract_type === 'FS Parc' ? 'Parceria'
          : imovel.sell_contract_type === 'FS Parc (sc)' ? 'Parceria' : 'Fast Sale')} breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{marginBottom:'24px'}}>
                    <Col style={{padding:'0'}}>
                      <SingleImovelCardCarousel photos={this.state.allPhotos} tour={imovel?.tour_360}  fit={this.state.verifyFit} premium={this.state.verifyPremium} onContractAcceptFotos={this.handleContractAcceptedFotos.bind(this)} imovel={imovel}/>
                      <div style={{
                        width:'50px',
                        height:'50px',
                        borderRadius:'50%',
                        backgroundColor:'#fff',
                        backgroundPosition:'center center',
                        backgroundSize:'cover',
                        backgroundImage:`url(${imovel?.construction?.profile_photo})`,
                        boxShadow:'0 1px 5px rgba(0,0,0,0.7)',
                        position:'absolute',
                        bottom:'10px',
                        left:'10px',
                        zIndex:999,
                      }}></div>
                    </Col>
                  </Row>
                  <Row style={{justifyContent:'space-between'}}>
                    <Col md={6} lg={7}>
                      <Row>
                        <Col xs={12}>
                          <p className='sku' style={{display:'flex', alignItems:'center'}}>
                            {/* <i
                              className={favoritado ? "ri-heart-fill" : "ri-heart-line"}
                              style={{ color: "#0f6e43", fontSize:'22px', marginRight:'8px' }}
                              onClick={this.handleFavoritarClick}
                            /> */}
                            {imovel.sku} | {imovel.address_properties?.enterprise}
                          </p>
                          <div style={{display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                            {imovel?.opportunity === 1 &&
                              <img style={{width:'30px', marginTop: '8px', marginRight:'5px'}} src={Fire}></img>
                            }
                            <h1>{imovel.title}</h1>
                          </div>
                          <div className='place'>
                            <i className="ri-map-pin-2-fill"></i>
                            <p>{imovel?.address_properties?.street}, nº {imovel?.address_properties?.number}, {imovel?.address_properties?.district}, {imovel?.address_properties?.city} / {imovel?.address_properties?.state}</p>
                          </div>
                        </Col> 
                        <Col xs={12}>
                          <Row style={{maxWidth:'600px'}}>
                            {(imovel.dorms_number && imovel.dorms_number !== '0') ?
                            <Col className="highFeature col-auto">
                                <i className="ri-hotel-bed-fill"></i>
                                <p>{imovel.dorms_number} quartos</p>
                              </Col>
                              :
                              <></>
                            }
                            {(((!imovel.dorms_number || imovel.dorms_number === '0') && (imovel.suites_number && imovel.suites_number !== '0'))) ?
                              <Col className="highFeature col-auto">
                                <i className="ri-hotel-bed-fill"></i>
                                <p>{imovel.suites_number} suítes</p>
                              </Col>
                              :
                              <></>
                            }
                            {(imovel.parking_spots && imovel.parking_spots !== '0') ?
                              <Col className="highFeature col-auto">
                                <i className="ri-car-fill"></i>
                                <p>{imovel.parking_spots} vagas</p>
                              </Col>
                              :
                              <></>
                            }
                            <Col className="highFeature col-auto">
                              <i className="ri-ruler-fill"></i>
                              <p>{(imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                                (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                                (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                              } m²</p>
                            </Col>
                            <Col className="highFeature">
                              <Button onClick={this.tog_large} className="btn-map">
                                <p style={{color:'#333', fontWeight:'600', fontSize:'16px'}}>Mapa</p>
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <div className="button-items">
                              <Button onClick={() => this.setState({modal_plantas: true})}>Ver plantas</Button>
                              {imovel.accept_download === 1 && 
                                <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                  const data = new FormData()
                                  if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
                                  if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
                                  data.append('enterprise_unit_id', imovel.id)
                                  data.append('enterprise_id', imovel.enterprise_id)
                                  data.append('type_action', 'download')
                          
                                  Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-units-actions', data,
                                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                  .then(response => {
                                      document.getElementById('load').classList.add('active')
                                      const axiosOptions = {
                                        responseType: 'arraybuffer',
                                        headers: {
                                          'Content-Type': 'application/json',
                                          "Authorization": "Bearer " + localStorage.getItem('token')
                                        }
                                      }
                                      Axios.post(process.env.REACT_APP_API + '/api/download-unit-media/' + imovel?.type_id, {
                                          type:localStorage.getItem('access'),
                                          user_id:localStorage.getItem('id'),
                                        }, axiosOptions)
                                        .then((response) => {
                                          const blob = new Blob([response.data], {type: 'application/octet-stream'})
                                          const filename = imovel?.title + '(' + imovel?.sku + ').zip'
                                          saveAs(blob, filename)
                                          document.getElementById('load').classList.remove('active')
                                        })
                                        .catch((e) => {
                                          document.getElementById('load').classList.remove('active')
                                        })
                                  })
                                  }}>
                                  Baixar material <i className="ri-download-2-line align-middle ms-2"></i>
                                </Button>
                              }
                              <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                  const data = new FormData()
                                  if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
                                  if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
                                  data.append('enterprise_unit_id', imovel.id)
                                  data.append('enterprise_id', imovel.enterprise_id)
                                  data.append('type_action', 'share')
                          
                                  Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-units-actions', data,
                                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                      .then(response => {
                                         setTimeout(() => {
                                           window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(window.location.href.replace('?owner', '').replace(imovel?.slug, '').replace('unidade', 'compartilhar-unidade') + '?ce29=' + localStorage.getItem('access') + '&ba05a=' + localStorage.getItem('id') + '&83acd=' + imovel?.id ), '_blank');
                                         }, 300);
                                      })
                                }}>
                                Compartilhar <i className="ri-share-forward-fill align-middle ms-2"></i>
                              </Button>
                              <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                const data = new FormData()
                                if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
                                if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
                                data.append('enterprise_unit_id', imovel.id)
                                data.append('enterprise_id', imovel.enterprise_id)
                                data.append('type_action', 'integration')
                        
                                Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-units-actions', data,
                                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                .then(response => {
                                  this.tog_integration()
                                })
                                }}>
                                Integrar <i className="ri-send-plane-line align-middle ms-2"></i>
                              </Button>
                              <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                  const data = new FormData()
                                  if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
                                  if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
                                  data.append('enterprise_unit_id', imovel.id)
                                  data.append('enterprise_id', imovel.enterprise_id)
                                  data.append('type_action', 'contact')
                          
                                  Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprises-units-actions', data,
                                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                  .then(response => {
                                    setTimeout(() => {
                                      window.open(`https://api.whatsapp.com/send?phone=55` + imovel?.construction?.phone + `&text=Olá,%20vi%20esse%20imóvel%20na%20Fast%20Sale: ` + imovel?.title + " - " + imovel.address_properties.city + " (" + imovel.sku + ') - https://fastsaleimoveis.com.br/unidade/' + imovel.slug, "_blank")
                                    }, 300);
                                  })
                                }}>
                                Falar com a construtora <i className="ri-whatsapp-line align-middle ms-2"></i>
                              </Button>
                            </div>
                          </Row>
                          <Row style={{justifyContent:'center'}}>
                            {window.screen.width <= 768 &&
                              <UnitAction handleVerifyContract={this.handleVerifyContract} premium={this.state.verifyPremium} imovel={imovel}/>
                            }
                          </Row>
                          <Row>
                            <div id="accordion">
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col1} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingOne">
                                    <h6 className="m-0 font-14">
                                      <h3>Sobre o imóvel</h3>
                                      <div className="text_muted"><p>Veja mais informações sobre esse imóvel</p></div>
                                      <i className={this.state.col1 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col1}>
                                  <CardBody>
                                  <pre-line>{imovel.description}</pre-line>
                                    <div style={{margin:'15px 0 10px'}}><h3 style={{fontSize:'14px', fontWeight:'600'}}>Espaços e medidas</h3></div>
                                    <div className="container-medidas">
                                      {(imovel?.endorsed_measures && imovel?.endorsed_measures !== '0') && <p>Área privativa: <b>{imovel?.endorsed_measures + 'm²'}</b></p>}
                                      {(imovel?.build_measures && imovel?.build_measures !== '0' && imovel?.terrain_measures !== '0.00') &&<p>Área construída: <b>{imovel?.build_measures + 'm²'}</b></p>}
                                      {(imovel?.terrain_measures && imovel?.terrain_measures !== '0' && imovel?.terrain_measures !== '0.00') &&<p>Área total: <b>{imovel?.terrain_measures + 'm²'}</b></p>}
                                    </div>
                                  </CardBody>
                                </Collapse>
                              </Card>
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col5} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingFive">
                                    <h6 className="m-0 font-14">
                                      <h3>Sobre o empreendimento</h3>
                                      <div className="text_muted"><p>Veja mais informações sobre o empreendimento</p></div>
                                      <i className={this.state.col1 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col5}>
                                  <CardBody>
                                  <pre-line>{imovel.enter_description}</pre-line>
                                  </CardBody>
                                </Collapse>
                              </Card>
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col2} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingTwo">
                                    {/* <h6 className="m-0 font-14">
                                      {" "}Outras características{" "}
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6> */}
                                    <h6 className="m-0 font-14">
                                      <h3>Outras características</h3>
                                      <div className="text_muted"><p>Características e tags do imóvel</p></div>
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col2}>
                                  <CardBody className="tags">
                                    {imovel?.features?.filter(feature => feature.show_on_venture === 1).map((feat, index) => (
                                      <li key={index}>{feat.detail}</li>
                                    ))}
                                    {imovel?.tag?.map((tag, index) => (
                                      <li key={index}>{tag.detail}</li>
                                    ))}
                                    {imovel?.enterprise_tag?.map((tag, index) => (
                                      <li key={index}>{tag.detail}</li>
                                    ))}
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              {(imovel?.accept_car === 1 || imovel?.accept_property === 1 || imovel?.real_state_financing === 1) &&
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col4} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingTwo">
                                    {/* <h6 className="m-0 font-14">
                                      {" "}Outras características{" "}
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6> */}
                                    <h6 className="m-0 font-14">
                                      <h3>Condições de negociação</h3>
                                      <div className="text_muted"><p>Condições para negociação do imóvel</p></div>
                                      <i className={this.state.col4 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col4}>
                                  <CardBody className="negotiation">
                                  <ul style={{listStyle:'none', padding:'0'}}>
                                    {imovel?.accept_car === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita carro</li>
                                    }
                                    {imovel?.accept_property === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita imóveis</li>
                                    }
                                    {imovel?.real_state_financing === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita Fin. Imob.</li>
                                    }
                                  </ul>
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              }
                              {(imovel?.video?.length > 0 || imovel?.enterprise_video?.length > 0) &&
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col3} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingThree">
                                    <h6 className="m-0 font-14">
                                      <h3>Vídeos</h3>
                                      <div className="text_muted"><p>Assista vídeos do imóvel</p></div>
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col3}>
                                  <CardBody style={{gap:'20px', display:'flex', flexWrap:'wrap'}}>
                                    {imovel?.video?.length > 0 && imovel?.video?.map((video, index) => (
                                      <video key={index} width="400" controls>
                                        <source src={video.url} type="video/mp4"></source>
                                        Seu navegador não suporte vídeos.
                                      </video>
                                    ))}
                                    {imovel?.enterprise_video.length > 0 && imovel?.enterprise_video?.map((video, index) => (
                                      <video key={index} width="400" controls>
                                        <source src={video.url} type="video/mp4"></source>
                                        Seu navegador não suporte vídeos.
                                      </video>
                                    ))}
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              }
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} lg={5} style={{justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                      {window.screen.width > 768 &&
                        <UnitAction handleVerifyContract={this.handleVerifyContract} premium={this.state.verifyPremium} imovel={imovel}/>
                      }
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="col-lg-12">
                      <div style={{padding:'40px 20px 0px'}}><h4 style={{fontSize:'22px'}}>Imóveis semelhantes</h4></div>
                    </Col>
                    <Col className="col-lg-12" style={{padding:'0'}}>
                      <Swiper
                      slidesPerView={"auto"}
                      spaceBetween={0}
                      navigation={true}
                      modules={[Navigation]}
                      className="SwiperImoveis"
                      >
                        
                          {this.state.relacionados?.map((imovel, index) => (
                              <SwiperSlide><ImovelCard  imovel={imovel} premium={this.state.verifyPremium ? 1 : 0}  favoritos={this.state.favoritos}/></SwiperSlide>
                          ))}
                          <SwiperSlide><div className="moreAll" onClick={() => {
                          localStorage.setItem('paginaAtual', '1')
                          setTimeout(() => {
                              window.open("/imoveis", '_parent')
                          }, 300);
                      }}>Ver mais imóveis</div></SwiperSlide>
                      </Swiper>
                    </Col>
                  </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* PopUp Google Maps */}
          <Modal
            size="lg"
            centered={true}
            isOpen={this.state.modal_large}
            toggle={this.tog_large}
          >
            <ModalHeader toggle={() => this.setState({ modal_large: false })}>
                Localização aproximada do imóvel
            </ModalHeader>
            <ModalBody>
              <Maps latI={imovel?.latitude} lngI={imovel?.longitude} zoomLevel={13}/>
            </ModalBody>
          </Modal>


          {/* PopUp Integração */}
          <Modal
            centered={true}
            isOpen={this.state.modal_integration}
            toggle={this.tog_integration}
          >
            <ModalHeader toggle={() => this.setState({ modal_integration: false })} style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <div className="popupText">
                {/* <p>Escolha o canal para <span style={{cursor:'initial', textDecoration:'none'}}>enviar o imóvel</span></p> */}
                <p>Integrar</p>
              </div>
              {/* integrationChannel:null,
              integrationType:null, */}
            </ModalHeader>
            <ModalBody>
              {/* <Container className="integrationOptions">
                <Row style={{justifyContent:'center'}}>
                  <Col className="col-auto" style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
                    <img src={CanalPro} onClick={(e) => {
                      this.setState({integrationChannel: 1})
                      document.getElementById('canalPro').click()
                    }}></img>
                    <Input className="form-check-input" type="radio" name="exampleRadios" id="canalPro" value="option2" onClick={(e) => {
                      this.setState({integrationChannel: 1})
                    }}/>
                  </Col>
                  <Col className="col-auto" style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
                    <img src={ChavesMao} onClick={(e) => {
                      this.setState({integrationChannel: 2})
                      document.getElementById('chavesNaMao').click()
                    }}></img>
                    <Input className="form-check-input" type="radio" name="exampleRadios" id="chavesNaMao" value="option2"  onClick={(e) => {
                      this.setState({integrationChannel: 2})
                    }}/>
                  </Col>
                  {(localStorage.getItem('your_page_id') && localStorage.getItem('your_page_id') !== 'null' && localStorage.getItem('your_page_id') !== 'undefined') ?
                    <Col className="col-auto" style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
                      <img src={SeuSite} onClick={(e) => {
                        this.setState({integrationChannel: 3})
                        document.getElementById('seuSite').click()
                      }}></img>
                      <Input className="form-check-input" type="radio" name="exampleRadios" id="seuSite" value="option2"  onClick={(e) => {
                        this.setState({integrationChannel: 3})
                      }}/>
                    </Col>
                    :
                    <Col className="col-auto" style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
                      <img src={SeuSite} onClick={(e) => {
                        this.setState({integrationChannel: 4})
                        document.getElementById('seuSiteNone').click()
                      }}></img>
                      <Input className="form-check-input" type="radio" name="exampleRadios" id="seuSiteNone" value="option2"  onClick={(e) => {
                        this.setState({integrationChannel: 4})
                      }}/>
                    </Col>
                  }
                </Row>
              </Container> */}
              <p>Está funcionalidade estará disponível em breve.</p>
              <Container>
                {this.state.integrationChannel === 1 &&
                  <Row style={{marginTop:'20px', justifyContent:'space-between'}}>
                    <Label>Escolha o tipo de anúncio para o <b style={{color:'#3E5E3F', fontSize:'16px'}}>Canal Pro</b></Label>
                    <select className="form-control" value={this.state.integrationType} onChange={(e) => this.setState({integrationType: e.target.value})}>
                      <option value={0}>Simples</option>
                      <option value={1}>Destaque</option>
                      <option value={2}>Super Destaque</option>
                    </select>
                  </Row>
                }
                {this.state.integrationChannel === 3 &&
                  <Row style={{marginTop:'20px', justifyContent:'space-between'}}>
                    <Label>Escolha o tipo de anúncio para <b style={{color:'#3E5E3F', fontSize:'16px'}}>Seu Site FS</b></Label>
                    <select className="form-control" value={this.state.integrationType} onChange={(e) => this.setState({integrationType: e.target.value})}>
                      <option value={0}>Simples</option>
                      <option value={1}>Destaque</option>
                    </select>
                  </Row>
                }
                {this.state.integrationChannel === 4 &&
                  <Row style={{marginTop:'20px', justifyContent:'space-between'}}>
                    <Card color="dark" className="text-white-50">
                      <CardBody>
                          <CardTitle className="text-white"><i className="mdi mdi-alert-outline me-3"></i>Atenção</CardTitle>
                          <CardText>Antes de adicionar imóveis ao seu site você precisa definir um layout, para isso acesse o construtor "Seu site FS" no menu e conclua todas as etapas.</CardText>
                          <CardText>Antes de adicionar imóveis ao seu site você precisa definir um layout, para isso acesse o construtor "Seu site FS" no menu e conclua todas as etapas.</CardText>
                      </CardBody>
                    </Card>
                  </Row>
                }
              </Container>
            </ModalBody>
            <ModalFooter>
                <div className="popupButtons" style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button type="button" color="light" onClick={() => this.setState({ modal_integration: false })}>Fechar</Button>
                  {/* {(this.state.integrationChannel && this.state.integrationChannel !== 4) ?
                    <Button type="button" onClick={() => {
                      document.getElementById('load').classList.add('active')
                      if(this.state.integrationChannel === 3){
                        const data = new FormData()
                        data.append('properties[]', imovel?.id)
                        data.append('type', localStorage.getItem('access'))
                        data.append('personal_page_id', localStorage.getItem('your_page_id'))
                        data.append('destaque', this.state.integrationType)
                        data.append('add_or_remove', 1)
                        Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/add-property', data,
                        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                        .then(response => {
                          this.setState({ modal_integration: false })
                          document.getElementById('load').classList.remove('active')
                          this.setState({integration_success: true})
                        })
                        .catch(response => {
                          this.setState({ modal_integration: false })
                          document.getElementById('load').classList.remove('active')
                          this.setState({integration_error: true})
                        })
                      }else{
                        const data = new FormData()
                        data.append('property_id', imovel?.id)
                        data.append('user_id', localStorage.getItem('id'))
                        data.append('type', localStorage.getItem('access'))
                        data.append('platform', this.state.integrationChannel)
                        data.append('integration_type', this.state.integrationType)
  
                        Axios.post(process.env.REACT_APP_API + '/api/auth/add-integration', data,
                        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                        .then(response => {
                          this.setState({ modal_integration: false })
                          document.getElementById('load').classList.remove('active')
                          this.setState({integration_success: true})
                        })
                        .catch(response => {
                          this.setState({ modal_integration: false })
                          document.getElementById('load').classList.remove('active')
                          this.setState({integration_error: true})
                        })
                      }
                    }}>Integrar</Button>
                    :
                    <Button type="button" disabled>Integrar (escolha um canal)</Button>
                  } */}
                </div>
            </ModalFooter>
          </Modal>

          {/* Sucesso na integração */}

          {this.state.integration_success ? (
            <SweetAlert
              title="Imóvel integrado com sucesso"
              timeout={2000}
              //                         style={{
              //   position: "absolute",
              //   top:"0px",
              //   right:"0px"
              // }}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => this.setState({ integration_success: false })}
            ></SweetAlert>
            ) : null}

          {/* Falha na integração */}

          {this.state.integration_error ? (
            <SweetAlert
              title="O imóvel não pode ser integrado, tente novamente."
              timeout={2000}
              // style={{
              //   position: "absolute",
              //   top:"50%",
              //   right:"50%"
              // }}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ integration_error: false })}
            ></SweetAlert>
            ) : null}


          {/* PopUp Plantas */}
          {this.state.modal_plantas ? (
                  
                  <Lightbox
                    mainSrc={imovel.plans[this.state.photoIndex]?.url}
                    nextSrc={imovel.plans[(this.state.photoIndex + 1) % imovel.plans.length]?.url}
                    prevSrc={imovel.plans[(this.state.photoIndex + imovel.plans.length - 1) % imovel.plans.length]?.url}
                    onCloseRequest={() => this.setState({ modal_plantas: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (this.state.photoIndex + imovel.plans.length - 1) % imovel.plans.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (this.state.photoIndex + 1) % imovel.plans.length,
                      })
                    }
                  />
            
                ) : null}

          {/* PopUp Não Premium Integração */}
          <Modal
            centered={true}
            isOpen={this.state.modal_premium}
            toggle={this.tog_premium}
          >
            <ModalHeader toggle={() => this.setState({ modal_premium: false })}>
              <div className="popupText">
                <p>Para integrar os imóveis da Fast Sale com outros portais em apenas um clique, <span>assine o plano Premium</span></p>
              </div>
            </ModalHeader>
            <ModalBody toggle={() => this.setState({ modal_premium: false })}>
              <div className="popupImage">
                {window.screen.width > 768 &&
                  <img src={Integrar}></img>
                }
                {window.screen.width <= 768 &&
                  <img src={IntegrarMobile}></img>
                }
              </div>
              <ModalFooter>
                <div className="popupButtons">
                  <Button onClick={() => {
                    setTimeout(() => {
                      window.open("/home", '_parent')
                    }, 300)
                  }}>Quero ser Premium!</Button>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>


          {/* PopUp Premium Download */}   
          <Modal
            centered={true}
            isOpen={this.state.modal_download}
            toggle={this.tog_download}
          >
            <ModalHeader toggle={() => this.setState({ modal_download: false })} style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <div className="popupText">
                <p>Esse arquivo .zip contém as fotos<br></br> <span style={{cursor:'initial', textDecoration:'none'}}>com marca d'água</span></p>
              </div>
            </ModalHeader>
            <ModalBody toggle={() => this.setState({ modal_download: false })}>
              <div className="popupImage">
                <img src={Download}></img>
              </div>
              <div>
                <p className="popupTextContent">Para baixar as fotos <span>sem marca'água</span> <br></br> assine um de nossos planos</p>
              </div>
            </ModalBody>
            <ModalFooter>
                <div className="popupButtons" style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button className="comlogo" onClick={() => {
                    document.getElementById('load').classList.add('active')
                    const axiosOptions = {
                      responseType: 'arraybuffer',
                      headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + localStorage.getItem('token')
                      }
                    }
                    Axios.post(process.env.REACT_APP_API + '/api/download-media/' + imovel?.id, {
                        type:localStorage.getItem('access'),
                        user_id:localStorage.getItem('id'),
                      }, axiosOptions)
                      .then((response) => {
                        const blob = new Blob([response.data], {type: 'application/octet-stream'})
                        const filename = imovel?.title + '(' + imovel?.sku + ').zip'
                        saveAs(blob, filename)
                        document.getElementById('load').classList.remove('active')
                        const data = new FormData()
                        data.append('type', localStorage.getItem('access'));
                        data.append('user_id', localStorage.getItem('id'))
                        Axios.post(process.env.REACT_APP_API + '/api/download-count/' + imovel?.id, data)
                        document.getElementById('load').classList.remove('active')
                        this.setState({ modal_download: false })
                      })
                      .catch((e) => {
                        document.getElementById('load').classList.remove('active')
                      })
                    }}>Baixar com marca d'água</Button>
                  <Button onClick={() => {
                    setTimeout(() => {
                      window.open("/home", '_parent')
                    }, 300)
                  }}>Quero ser Premium!</Button>
                </div>  
              </ModalFooter>
          </Modal>

          {/* PopUp Premium Compartilhamento */}   
          <Modal
            centered={true}
            isOpen={this.state.modal_share}
            toggle={this.tog_share}
          >
            <ModalHeader toggle={() => this.setState({ modal_share: false })} style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <div className="popupText">
                <p>Você está compartilhando o imóvel<br></br> <span style={{cursor:'initial', textDecoration:'none'}}>com marca d'água nas fotos</span></p>
              </div>
            </ModalHeader>
            <ModalBody toggle={() => this.setState({ modal_share: false })}>
              <div className="popupImage">
                <img src={Compartilhar}></img>
              </div>
              <div className="popupText">
                <p>Para compartilhar o imóvel <span style={{cursor:'initial', textDecoration:'none'}}>sem marca d'água nas fotos</span> assine um de nossos planos</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="popupButtons" style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button  className="comlogo" onClick={() => {
                  Axios.post(process.env.REACT_APP_API + '/api/auth/log-shares', {
                    type: localStorage.getItem('access'),
                    user_id: localStorage.getItem('id'),
                    property_id: imovel?.id
                    }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(response => {
                      setTimeout(() => {
                        window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(window.location.href.replace(imovel?.slug, '').replace('?owner', '').replace('imovel', 'compartilhar-imovel') + '?ce29=' + localStorage.getItem('access') + '&ba05a=' + localStorage.getItem('id') + '&83acd=' + imovel?.id ), '_blank');
                      }, 300);
                    })
                  }}>Compartilhar com marca d'água</Button>
                  <Button onClick={() => {
                    setTimeout(() => {
                      window.open("/home", '_parent')
                    }, 300)
                  }}>Quero ser Premium!</Button>
                </div>
              </ModalFooter>
          </Modal>


          <div id="load" className="load">
            <Spinner className="me-2" color="success" />
          </div>
        </Container>
      </div>
       :
       verifyDocs ?
       <div className="page-content">
        <Container fluid={true}>
          <NoPremiumProperty/>
        </Container>
       </div>
       :
       <div className="page-content">
       <Container fluid={true}>
         <NoDocsProperty/>
       </Container>
      </div>
    );
  }
}

export default SingleUnits;