import { useState } from 'react';
import { AuthComponent } from '../../../../Authentication/Login/components/authComponent';
import { RegisterStep2 } from '../../../../Authentication/Register/step2';
import { RegisterStep3 } from '../../../../Authentication/Register/step3';
import { RegisterStep4 } from '../../../../Authentication/Register/step4';
import './styles.scss';
import { NewRegister } from '../../../../Authentication/Register';

export const RegisterModal = ({open, onClose}) => {
  const [step, setStep] = useState(1)
  const [userData, setUserData] = useState(null)

  const handleRegisterStep = (data) => {
    setUserData(data);
    setStep(prevStep => prevStep + 1);
  }

  const handleUpdateStep = (e) => {
    setStep(e);
  }

  return(
    <>
      <div className={`register-modal ${open ? 'active' : ''}`}>
        <span className="close-modal" onClick={onClose}><i className="ri-close-fill "></i></span>
        {step === 1 &&
          <NewRegister newCallback={handleRegisterStep}/>
        }
        {step === 2 &&
          <RegisterStep2 userData={userData} newCallback={handleUpdateStep}/>
        }
        {step === 3 &&
          <RegisterStep3 userData={userData} newCallback={handleUpdateStep}/>
        }
        {step === 4 &&
          <RegisterStep4 userData={userData} newCallback={handleUpdateStep}/>
        }
        {step === 5 &&
          <div className="completed-register">
            <h3>Cadastro concluído</h3>
            <p>Faça login para continuar sua compra.</p>
            <button onClick={onClose}>Ok</button>
          </div>
        }
      </div>
      <div onClick={onClose} className={`register-modal-overlay ${open ? 'active' : ''}`}></div>
    </>
  )
}