import { Col, Row, Container } from 'reactstrap'
import styles from '../styles/FastSale.module.css'
import Arrow from '../assets/up-arroww.png'

const FastSale = () => {
  return(
    <Container className={styles.container_fast_sale}>
        <Row style={{alignItems: 'center', justifyContent: 'center'}}>
          <Col md={6} xl={4}>
            <h1 className={styles.title}>Parceria é a chave!</h1>
            <p className={styles.text}>
              Assista o vídeo e <br /> entenda como funciona <span><img className={styles.arrow} src={Arrow} alt="Arrow" /></span>
            </p>
          </Col>
          <Col md={6} xl={4}>
            <div>
              <iframe className={styles.video_home_fast_sale} title="video4" src={"https://www.youtube.com/embed/dXopyZVrC_Y"}></iframe> 
            </div>
          </Col>
        </Row>
    </Container>
  )
}

export default FastSale