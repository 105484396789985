import { Component } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';

import './../../assets/scss/custom/components/_noPremium.scss';

class NoDocsProperty extends Component {

  render(){
  return (
    <Row>
      <Col>
        <Card style={{height:'80vh'}}>
          <div id="noPremiumOverlay">
            <i className='ri-lock-2-fill'></i>
            <p>Valide seu cadastro para visualizar todas as informações do imóvel.</p>
            <Button onClick={() => {
              window.open('./editar-perfil', '_parent')
            }}>Validar agora</Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
}

export default NoDocsProperty;
