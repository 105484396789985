import {Component} from 'react'

import './../../assets/scss/custom/components/_filters.scss'
import { Button, Card, CardBody, CardHeader, Col, Collapse, Input, Label, Row } from 'reactstrap'
import Axios from 'axios';
import Select from "react-select";

const urlSection = window.location.search.replace('?', '')

class EnterprisesFilters extends Component {

  constructor(props) {
    super(props)
    this.state = {
      moreFilters:false,
      moreFiltersIcon:'ri-add-line',
      citiesOptions:[],
      constructionOptions:[],
      enterprisesOptions:[],
      citySelected:[],
      constructionSelected:[],
      enterpriseSelected:[]
    }
  }

  componentDidMount(){
    Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/filter-options',
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
        this.setState({citiesOptions: response.data.cities.filter(city => city.label !== null)})
        this.setState({constructionOptions: response.data.constructions})
        this.setState({enterprisesOptions: response.data.enterprises})
    })
  }

  sendSearch = () => {
    const { citySelected, constructionSelected, enterpriseSelected } = this.state;
    this.props.onSearch(citySelected.value, constructionSelected.value, enterpriseSelected.label);
  }

  render(){
    return (
        <div className="Filters">
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div style={{display: 'flex',justifyContent: 'space-between' ,alignItems: 'center'}}>
                {this.props.onToogle ? (
                    <h5 className="mb-0">Filtros</h5>
                  ) : (
                    <h5 className="mb-0 none-text-filter">Filtros</h5>
                  )
                }
                {
                  this.props.onToogle ? (
                      <i className="ri-close-line" onClick={this.props.toogle} style={{cursor: "pointer", fontSize: "1.3rem"}}></i>
                    ) : (
                      <i className="ri-filter-2-fill" onClick={this.props.toogle} style={{cursor: "pointer", fontSize: "1.3rem"}}></i>
                  )
                }
              </div>
            </CardHeader>
            {this.props.onToogle &&
              <CardBody>
                <div>
                  <Label className="form-label">Cidade</Label>
                  <Select
                      value={this.state.citySelected}
                      placeholder="Selecione"
                      noOptionsMessage={(inputValue) => `Sem resultados`}
                      onChange={(e) => this.setState({ citySelected: e })}
                      options={this.state.citiesOptions}
                      classNamePrefix="select2-selection"
                  />
                  <small style={{
                    textDecoration:'underline',
                    marginTop:'5px',
                    cursor:'pointer'
                  }} onClick={() => {
                    this.setState({citySelected: []})
                    setTimeout(() => {
                      this.sendSearch()
                    }, 300);
                    }}>Limpar</small>
                </div>
                <div style={{marginTop:'20px'}}>
                  <Label className="form-label">Construtora</Label>
                  <Select
                      value={this.state.constructionSelected}
                      placeholder="Selecione"
                      noOptionsMessage={(inputValue) => `Sem resultados`}
                      onChange={(e) => this.setState({ constructionSelected: e })}
                      options={this.state.constructionOptions}
                      classNamePrefix="select2-selection"
                  />
                  <small style={{
                    textDecoration:'underline',
                    marginTop:'5px',
                    cursor:'pointer'
                  }} onClick={() => {
                    this.setState({constructionSelected: []})
                    setTimeout(() => {
                      this.sendSearch()
                    }, 300);
                    }}>Limpar</small>
                </div>
                {urlSection === '' &&
                <div style={{marginTop:'20px'}}>
                  <Label className="form-label">Empreendimento</Label>
                  <Select
                      value={this.state.enterpriseSelected}
                      placeholder="Selecione"
                      noOptionsMessage={(inputValue) => `Sem resultados`}
                      onChange={(e) => this.setState({ enterpriseSelected: e })}
                      options={this.state.enterprisesOptions}
                      classNamePrefix="select2-selection"
                  />
                  <small style={{
                    textDecoration:'underline',
                    marginTop:'5px',
                    cursor:'pointer'
                  }} onClick={() => {
                    this.setState({enterpriseSelected: []})
                    setTimeout(() => {
                      this.sendSearch()
                    }, 300);
                    }}>Limpar</small>
                </div>
                }
                <div style={{marginTop:'20px', textAlign:'center'}}>
                  <Button onClick={this.sendSearch}>Pesquisar</Button>
                </div>
              </CardBody>
            }
          </Card>
        </div>
      )
  }

}
export default EnterprisesFilters;