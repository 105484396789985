import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Container, Spinner, Input, Button, Badge } from "reactstrap";
import Axios from 'axios'

import Breadcrumbs from "../../components/Common/Breadcrumb"
import './../../assets/scss/custom/components/_editProperties.scss'
import './../../assets/scss/custom/components/_addProposal.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';

class listProposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suasPropostas: [],
      emSeusImoveis:[],
      emSeusImoveisViewport:[
        {
          dataField: "mobile",
          text: "Imóveis"
        },
      ],
      suasPropostasViewport:[
        {
          dataField: "mobile",
          text: "Imóveis"
        },
      ],
      emSeusImoveisFiltered:[],
      suasPropostasFiltered:[],
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Propostas", link: "#" },
    ],
    }
  }


  componentDidMount(){
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        Axios.post(process.env.REACT_APP_API + '/api/auth/user-proposals-by-properties/' + localStorage.getItem('id'), {
          type: localStorage.getItem('access')
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          this.setState({emSeusImoveis: response.data})
        })

        Axios.post(process.env.REACT_APP_API + '/api/auth/user-proposals/', {
          user_id: localStorage.getItem('id'),
          type: localStorage.getItem('access')
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          this.setState({suasPropostas: response.data})
        })
    
        if(window.screen.width > 768){
          this.setState({ emSeusImoveisViewport:[
            {
              dataField: 'sku',
              text: 'Código'
            },
            {
              dataField: "one_photo",
              text: "Foto"
            },
            {
              dataField: "title",
              text: "Imóvel"
            },
            {
              dataField: "created_at",
              text: "Data"
            },
            {
              dataField: "status",
              text: "Status"
            },
            {
              dataField: "id_proposta",
              text: "Ver"
            },
          ]
          })
          this.setState({suasPropostasViewport:[
            {
              dataField: 'sku',
              text: 'Código'
            },
            {
              dataField: "one_photo",
              text: "Foto"
            },
            {
              dataField: "title",
              text: "Imóvel"
            },
            {
              dataField: "created_at",
              text: "Data"
            },
            {
              dataField: "status",
              text: "Status"
            },
            {
              dataField: "id_proposta",
              text: "Ver"
            },
          ]})
        }

      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }



  render() {
    const {suasPropostas, emSeusImoveis} = this.state;

    const suasPropostasItems = []

    this.state.suasPropostas.map((proposta, index) => {
      suasPropostasItems.push({
        sku: proposta.sku,
        title: proposta.title,
        slug: proposta.slug,
        created_at: moment(proposta.created_at).format('DD/MM/YYYY'),
        one_photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + proposta.one_photo.small_image + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'50px', height: '50px', borderRadius:'5px'}}></div>,
        id_proposta: <i className="ri-eye-fill" style={{fontSize:'22px', cursor:'pointer'}} onClick={() => {
          setTimeout(() => {
            window.open("/proposta?" + proposta.id_proposta, '_parent')
          }, 300);
        }}></i>,
        status: proposta.status === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
        : proposta.status === 1 ? <Badge className="bg-danger me-1 rounded-pill">Formalizada</Badge>
        : proposta.status === 2 ? <Badge className="bg-danger me-1 rounded-pill">Cancelada</Badge>
        : proposta.status === 3 ? <Badge className="bg-danger me-1 rounded-pill">Negada</Badge>
        : <Badge className="bg-success me-1 rounded-pill">Aceita</Badge>,
        mobile:<Container style={{padding:'0'}}>
        <Row>
          <Col>
            Data de criação: {moment(proposta.created_at).format('DD/MM/YYYY')}
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col className="col-auto">
            <img src={proposta?.one_photo.small_image} style={{
              objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
            }}></img>
          </Col>
          <Col>
          <p style={{lineHeight:'1.2'}}>{proposta?.sku}</p>
          <small>{proposta?.title}</small>
          </Col>
        </Row>
        <Row style={{marginTop:'15px', justifyContent:'space-between'}}>
          <Col className="col-auto">
            Status: {proposta.status === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
              : proposta.status === 1 ? <Badge className="bg-info me-1 rounded-pill">Formalizada</Badge>
              : proposta.status === 2 ? <Badge className="bg-danger me-1 rounded-pill">Cancelada</Badge>
              : proposta.status === 3 ? <Badge className="bg-danger me-1 rounded-pill">Negada</Badge>
              : <Badge className="bg-success me-1 rounded-pill">Aceita</Badge>}
          </Col>
          <Col className="col-auto" style={{textAlign:'right'}}><i className="ri-eye-fill" onClick={() => {
             localStorage.setItem('property_id_proposal', proposta.id)
             setTimeout(() => {
                  window.open("/proposta?" + proposta.id_proposta , '_parent')
              }, 300);
            }}></i></Col>
        </Row>
      </Container>,
      })
    })


    const emSeusImoveisItems = []

    this.state.emSeusImoveis.map((imovel, index) => {
      imovel.proposals?.map((proposta, i) => {
        emSeusImoveisItems.push({
          sku: imovel.sku,
          title: imovel.title,
          slug: imovel.slug,
          created_at: moment(proposta.created_at).format('DD/MM/YYYY'),
          one_photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + imovel.one_photo.small_image + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'50px', height: '50px', borderRadius:'5px'}}></div>,
          id_proposta: <i className="ri-eye-fill" style={{fontSize:'22px', cursor:'pointer'}} onClick={() => {
            localStorage.setItem('property_id_proposal', proposta.id)
            setTimeout(() => {
              window.open("/proposta?" + proposta.id, '_parent')
            }, 300);
          }}></i>,
          status: proposta.status === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
          : proposta.status === 1 ? <Badge className="bg-info me-1 rounded-pill">Formalizada</Badge>
          : proposta.status === 2 ? <Badge className="bg-danger me-1 rounded-pill">Cancelada</Badge>
          : proposta.status === 3 ? <Badge className="bg-danger me-1 rounded-pill">Negada</Badge>
          : <Badge className="bg-success me-1 rounded-pill">Aceita</Badge>,
          mobile:<Container style={{padding:'0'}}>
            <Row>
              <Col>
                Data de criação: {moment(proposta.created_at).format('DD/MM/YYYY')}
              </Col>
            </Row>
            <Row style={{marginTop:'15px'}}>
              <Col className="col-auto">
                <img src={imovel?.one_photo.small_image} style={{
                  objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
                }}></img>
              </Col>
              <Col>
              <p style={{lineHeight:'1.2'}}>{imovel?.sku}</p>
              <small>{imovel?.title}</small>
              </Col>
            </Row>
            <Row style={{marginTop:'15px', justifyContent:'space-between'}}>
              <Col className="col-auto">
                Status: {proposta.status === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
                  : proposta.status === 1 ? <Badge className="bg-info me-1 rounded-pill">Formalizada</Badge>
                  : proposta.status === 2 ? <Badge className="bg-danger me-1 rounded-pill">Cancelada</Badge>
                  : proposta.status === 3 ? <Badge className="bg-danger me-1 rounded-pill">Negada</Badge>
                  : <Badge className="bg-success me-1 rounded-pill">Aceita</Badge>}
              </Col>
              <Col className="col-auto" style={{textAlign:'right'}}><i className="ri-eye-fill" onClick={() => {
                 localStorage.setItem('property_id_proposal', proposta.id)
                 setTimeout(() => {
                      window.open("/proposta?" + proposta.id_proposta , '_parent')
                  }, 300);
                }}></i></Col>
            </Row>
          </Container>
        })
      })
    })



    const emSeusImoveisOptions = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: 'Todos', value: emSeusImoveis.length
        }]
    };

    const suasPropostasOptions = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: 'Todos', value: suasPropostas.length
        }]
    };

    return (
        <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Propostas" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{marginBottom:'20px'}}>
                    <Col>
                      <h5>Propostas feitas em seus imóveis</h5>
                    </Col>
                  </Row>
                  <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                    <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                    <Input
                    placeholder="Buscar por código do imóvel"
                    onChange={(e) => this.setState({ emSeusImoveisFiltered: emSeusImoveisItems.filter(contrato => contrato.sku.toLowerCase().includes(e.target.value.toLowerCase()))})}
                    >
                    </Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                  <BootstrapTable
                    keyField='id'
                    data={this.state.emSeusImoveisFiltered.length > 0 ? this.state.emSeusImoveisFiltered : emSeusImoveisItems}
                    columns={this.state.emSeusImoveisViewport}
                    // expandRow={expandRow}
                    pagination={paginationFactory(emSeusImoveisOptions)}
                    // selectRow={selectRow}
                  />
                  </div>
                </CardBody>
              </Card>
              <Card>  
                <CardBody>
                  <Row style={{marginBottom:'20px'}}>
                    <Col>
                      <h5>Propostas feitas por você</h5>
                    </Col>
                  </Row>
                  <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                    <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                    <Input
                    placeholder="Buscar por código do imóvel"
                    onChange={(e) => this.setState({suasPropostasFiltered: suasPropostasItems.filter(contrato => contrato.sku.toLowerCase().includes(e.target.value.toLowerCase()))})}
                    >
                    </Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                  <BootstrapTable
                    keyField='id'
                    data={this.state.suasPropostasFiltered.length > 0 ? this.state.suasPropostasFiltered : suasPropostasItems}
                    columns={this.state.suasPropostasViewport}
                    // expandRow={expandRow}
                    pagination={paginationFactory(suasPropostasOptions)}
                    // selectRow={selectRow}
                  />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default listProposal;