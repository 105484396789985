import React, { useState, useEffect } from 'react';

const PhoneInput = ({ phoneNumber, onPhoneChange }) => {
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');

  useEffect(() => {
    if (phoneNumber) {
      formatPhoneNumber(phoneNumber);
    }
  }, [phoneNumber]);

  const formatPhoneNumber = (inputPhoneNumber) => {
    const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, '');
    const limitedPhoneNumber = numericPhoneNumber.slice(0, 11);
    const areaCode = limitedPhoneNumber.slice(0, 2);
    const firstDigits = limitedPhoneNumber.slice(2, 7);
    const lastDigits = limitedPhoneNumber.slice(7, 11);

    let formattedPhoneNumber = '';

    if (areaCode) {
      formattedPhoneNumber += `(${areaCode}`;
    }

    if (firstDigits) {
      formattedPhoneNumber += `) ${firstDigits}`;
    }

    if (lastDigits) {
      formattedPhoneNumber += `-${lastDigits}`;
    }

    setFormattedPhoneNumber(formattedPhoneNumber);

    if (limitedPhoneNumber.length > 9) {
      onPhoneChange(limitedPhoneNumber);
    }
  };

  const handleInputChange = (event) => {
    const inputPhoneNumber = event.target.value;
    formatPhoneNumber(inputPhoneNumber);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Backspace') {
      setFormattedPhoneNumber((prevPhoneNumber) => prevPhoneNumber.slice(0, -1));
    }
  };

  return (
    <input
      type="text"
      value={formattedPhoneNumber}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="(00) 00000-0000"
      inputMode="numeric"
    />
  );
};

export default PhoneInput;