import './styles.scss';
import Bg from './../../../assets/images/agradecimento.png';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Logo from "../../../assets/images/logo-light.png";
import { Button } from 'reactstrap';

export const ThankYouConstruction = () => {
  const history = useHistory()

  return(
    <div className="thank-you-container" style={{backgroundImage: `url('${Bg}')`}}>
      <div className="thank-you-content">
        <Link to="/home" className="logo"><img src={Logo} height="20" alt="logo" /></Link>
        <div className="thank-you-title">
          <h4>Cadastro realizado!</h4>
          <p>Seu cadastro está em análise, em até 24 horas você receberá um aviso em seu e-mail.</p>
          <Button onClick={() => {
            history.push('/home')
          }}>Voltar</Button>
        </div>
      </div>
    </div>
  )
}