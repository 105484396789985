import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, CardTitle, CardText, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { createTheme, Pagination, ThemeProvider } from '@mui/material';

import './../../assets/scss/custom/components/_integration.scss'

import moment from 'moment';
import { saveAs } from 'file-saver';
import ReactSelect from 'react-select';
import Select from "react-select";
import SweetAlert from 'react-bootstrap-sweetalert';
require('moment/locale/pt.js');


class PartnershipListAdm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      accepted:[],
      page:1,
      searchTerm:'',
      pages:1,
      parcerias:[],
      filtleredItems:[],
      filtleredAceitos:[],
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Parcerias", link: "#" },
    ],
    }
  }

  componentDidMount(){
    document.getElementById('load').classList.add('active')

    if(localStorage.getItem('searchTerm')){
      this.setState({searchTerm: localStorage.getItem('searchTerm')})
    }

    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        if(localStorage.getItem('access') === 'A'){

        Axios.get(process.env.REACT_APP_API + '/api/auth/all-contracts-no-pag?page=' + (localStorage.getItem('paginaAtual') ? localStorage.getItem('paginaAtual') : this.state.page) + '&search=' + (localStorage.getItem('searchTerm') ? localStorage.getItem('searchTerm') : this.state.searchTerm),  
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          this.setState({parcerias: response.data.data})
          this.setState({pages: response.data.last_page})
          document.getElementById('load').classList.remove('active')
        })

      }else{
        setTimeout(() => {
          window.open("/dashboard", '_parent')
        }, 300);
      }

      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }

  handleResults = (page) => {
    Axios.get(process.env.REACT_APP_API + '/api/auth/all-contracts-no-pag?page=' + page + '&search=' + (localStorage.getItem('searchTerm') ? localStorage.getItem('searchTerm') : this.state.searchTerm),  
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      this.setState({parcerias: response.data.data})
      this.setState({pages: response.data.last_page})
      document.getElementById('load').classList.remove('active')
    })
  }

  render() {
    const data = {
      columns: [
        {
          dataField: 'sku',
          text: 'Código'
        },
        {
          dataField: "title",
          text: "Imóvel"
        },
        {
          dataField: "created_at",
          text: "Data"
        },
        {
          dataField: "profile_photo",
          text: ""
        },
        {
          dataField: "name",
          text: "Corretor"
        },
        {
          dataField: "id_parceria",
          text: "Baixar termo"
        },
      ],
    };


    const items = []

    this.state.parcerias.sort(function(a,b){
      return new Date(b.created_at) - new Date(a.created_at)}).map((imovel, index) => {
        items.push({
          sku: imovel?.properties?.sku,
          title: imovel?.properties?.title,
          slug: imovel?.properties?.slug,
          created_at: moment(imovel?.created_at).format('DD/MM/YYYY'),
          name: imovel?.broker?.name ? imovel?.broker?.name : imovel?.real_estate?.name,
          profile_photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + (imovel?.broker?.profile_photo ? imovel?.broker?.profile_photo : imovel?.real_estate?.profile_photo) + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'40px', height: '40px', borderRadius:'50%'}}></div>,
          id_parceria: <Button  onClick={() => {
            document.getElementById('load').classList.add('active')
            const axiosOptions = {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem('token')
              }
            }
            Axios.get(process.env.REACT_APP_API + '/api/auth/contract-pdf/' + imovel.id, axiosOptions)
            .then((response) => {
              const blob = new Blob([response.data], {
                type: 'application/octet-stream'
              })
              const filename = 'Parceria:' + imovel?.properties.sku + '(' + (imovel?.broker?.name ? imovel?.broker?.name : imovel?.real_estate?.name) + ').pdf'
              saveAs(blob, filename)
              document.getElementById('load').classList.remove('active')
            })
            .catch((e) => {

            })
       }}>Baixar</Button>,
      })
    })


    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: 'Todos', value: items.length
        }]
    };

    const theme = createTheme({
      palette: {
      verde: {
          main: '#3E5E3F',
          contrastText: '#fff',
          },
      },
  });

    return (
        <div className="page-content" id="partnershipPage">
        <Container fluid={true}>
          <Breadcrumbs title="Parcerias" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                    <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                      <Input
                      value={this.state.searchTerm}
                      placeholder="Corr., Imob., Imóvel ou Código"
                      onChange={(e) => {
                        localStorage.setItem('searchTerm', e.target.value)
                        this.setState({searchTerm: e.target.value})
                      }}
                      >
                      </Input>
                    </Col>
                    <Col className="col-auto">
                      <Button onClick={() => {
                          this.handleResults(1)
                      }}>Buscar</Button>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                  <BootstrapTable
                    keyField='id'
                    data={this.state.filtleredItems.length > 0 ? this.state.filtleredItems : items}
                    columns={data.columns}
                    // expandRow={expandRow}
                    // pagination={paginationFactory(options)}
                    // selectRow={selectRow}
                  />
                  </div>
                    <ThemeProvider theme={theme}>
                      <Pagination
                          count={this.state.pages}
                          color="verde"
                          size="small" 
                          page={this.state.page}
                          style={{margin:'20px 0', display:'flex', justifyContent:'flex-end'}}
                          onChange={(event, value) => {
                              this.setState({page: value})
                              this.handleResults(value)
                              window.scrollTo(0,0)
                          }}
                      />
                  </ThemeProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}


export default PartnershipListAdm;