import axios from 'axios';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';

const CepInput = ({ onCepChange,onEnderecoChange }) => {
  const [cep, setCep] = useState('');

  const handleCepChange = (event) => {
    const newCep = event.target.value.replace(/\D/g, '');
    setCep(newCep);
    onCepChange(newCep);
  };

  const buscaCep = (cep) => {
    if (cep.length === 8) {
      axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => {
          const endereco = {
            cep: cep,
            logradouro: res.data.logradouro,
            complemento: res.data.complemento,
            bairro: res.data.bairro,
            cidade: res.data.localidade,
            estado: res.data.uf
          };
          onEnderecoChange(endereco);
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleBlur = () => {
    buscaCep(cep);
  };

  return (
    <InputMask
      mask="99999-999"
      value={cep}
      onChange={handleCepChange}
      onBlur={handleBlur}
      inputMode="numeric"
    />
  );
};

export default CepInput;