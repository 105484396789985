import {useState, useEffect} from 'react';
import { Col, Container, Row } from 'reactstrap';

function FiltersSection6({active, applyCount}) {

  const options = [
    {
      'title':'1',
      'value':'1'
    },
    {
      'title':'2',
      'value':'2'
    },
    {
      'title':'3',
      'value':'3'
    },
    {
      'title':'4',
      'value':'4'
    },
    {
      'title':'5+',
      'value':'5'
    }
  ]

  const[typeOption, setTypeOption] = useState([])
  const[count, setCount] = useState(0)

  useEffect(() => {
    document.getElementById('1garages').classList.remove('active')
    document.getElementById('2garages').classList.remove('active')
    document.getElementById('3garages').classList.remove('active')
    document.getElementById('4garages').classList.remove('active')
    document.getElementById('5garages').classList.remove('active')
    if(localStorage.getItem('filtro-garagens')){
        if(localStorage.getItem('filtro-garagens').split(',').includes('1')){
          document.getElementById('1garages').classList.add('active')
        }
        if(localStorage.getItem('filtro-garagens').split(',').includes('2')){
          document.getElementById('2garages').classList.add('active')
        }
        if(localStorage.getItem('filtro-garagens').split(',').includes('3')){
          document.getElementById('3garages').classList.add('active')
        }
        if(localStorage.getItem('filtro-garagens').split(',').includes('4')){
          document.getElementById('4garages').classList.add('active')
        }
        if(localStorage.getItem('filtro-garagens').split(',').includes('5')){
          document.getElementById('5garages').classList.add('active')
        }
    }
}, [applyCount]);

  useEffect(() => {
    if(typeOption.includes('1')){
      document.getElementById('1garages').classList.add('active')
    }else{
      document.getElementById('1garages').classList.remove('active')
    }
    if(typeOption.includes('2')){
      document.getElementById('2garages').classList.add('active')
    }else{
      document.getElementById('2garages').classList.remove('active')
    }
    if(typeOption.includes('3')){
      document.getElementById('3garages').classList.add('active')
    }else{
      document.getElementById('3garages').classList.remove('active')
    }
    if(typeOption.includes('4')){
      document.getElementById('4garages').classList.add('active')
    }else{
      document.getElementById('4garages').classList.remove('active')
    }
    if(typeOption.includes('5')){
      document.getElementById('5garages').classList.add('active')
    }else{
      document.getElementById('5garages').classList.remove('active')
    }
  }, [count])

  return (
    <Container className="sectionFilter6">
      <Row>
        <Col className="garages-container">
          {options.map((option, index) => (
            <div key={index} className="option" id={option.value + 'garages'} onClick={(e) => {
              if(typeOption.includes(option.value)){
                var index = typeOption.indexOf(option.value)
                if (index > -1) {
                  typeOption.splice(index, 1)
                }
              }else{
                typeOption.push(option.value)
                localStorage.setItem('filtro-garagens', typeOption )
              }
              setCount(count + 1)
              }}>{option.title}</div>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default FiltersSection6;