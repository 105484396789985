import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import FastLogo from './../../assets/images/logo_fast.png';

class SingleActionPublic extends Component {

  render(){
    return(
      <div id="actionContainer">
        <Row>
          <Col>
          {this.props.imovel.sell_price ?
          <>
            <div className="offer"><h3>{(parseFloat(this.props.imovel.sale_value) / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3></div>
            <div className="price"><h3>{(parseFloat(this.props.imovel.sell_price) / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3></div>
          </>
          :
          <div className="price"><h3>{(parseFloat(this.props.imovel.sale_value) / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3></div>
          }
          </Col>
        </Row>
        <Row>
          {(this.props.imovel?.building_tax_value && this.props.imovel?.building_tax_value !== 0 && this.props.imovel?.building_tax_value !== '0' && this.props.imovel?.building_tax_value !== null) &&
            <Col xs={12} className="condominium" style={{padding:'0'}}>
              <small>Condomínio</small>
              <p><span>R$</span>{(this.props.imovel?.building_tax_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}<small>/mês</small></p>
            </Col>
          }
          {(this.props.imovel?.city_tax_value && this.props.imovel?.city_tax_value !== 0 && this.props.imovel?.city_tax_value !== '0' && this.props.imovel?.city_tax_value !== null) &&
            <Col xs={12} className="iptu" style={{padding:'0'}}>
              <small>IPTU</small>
              <p><span>R$</span>{(this.props.imovel?.city_tax_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}<small>/ano</small></p>
            </Col>
          }
        </Row>
        {this.props.access !== 'P' ?
            <Row id="broker">
              <Col xs={12}>
                <div className="image" style={{backgroundImage:'url(' + this.props.user?.profile_photo + ')'}}></div>
              </Col>
              <Col xs={12}>
                <h3>{this.props.user.name}</h3>
                {this.props.user.creci && <p>CRECI: <b>{this.props.user.creci}</b></p>}
                {this.props.user.creci_j && <p>CRECI: <b>{this.props.user.creci_j}</b></p>}
              </Col>
              <Col>
                <div className="phone"><i className="ri-phone-fill"></i><p>{this.props.user?.phone}</p></div>
                <div className="email"><i className="ri-mail-fill"></i><p>{this.props.user?.email}</p></div>
              </Col>
            </Row>
          :
            <Row id="broker">
              <Col xs={12}>
                <div className="image" style={{backgroundImage:'url(' + FastLogo + ')'}}></div>
              </Col>
              <Col xs={12}>
                <h3>Fast Sale Imóveis</h3>
                <p>CRECI: <b>6635J</b></p>
              </Col>
              <Col>
                <div className="phone"><i className="ri-phone-fill"></i><p>(47) 4108-1865</p></div>
                {/* <div className="email"><i className="ri-mail-fill"></i><p>administrativo@fastsaleimoveis.com.br</p></div> */}
              </Col>
            </Row>
          }

        <Row style={{marginTop:'15px'}}>
          {this.props.access !== 'P' ?
            <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                  setTimeout(() => {
                    window.open(`https://api.whatsapp.com/send?phone=55` + (this.props.user.phone ? this.props.user.phone : '4741081865' )?.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, "") + `&text=Olá,%20tenho%20interesse%20no%20` + this.props.imovel?.title + " (" + this.props.imovel.sku + ')', "_blank")
                  }, 300);
                }}>
              Chamar no Whatsapp <i className="ri-whatsapp-line align-middle ms-2"></i>
            </Button>
          :
            <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                  setTimeout(() => {
                    window.open(`https://api.whatsapp.com/send?phone=554741081865&text=Olá,%20tenho%20interesse%20no%20` + this.props.imovel?.title + " (" + this.props.imovel.sku + ')', "_blank")
                  }, 300);
                }}>
              Chamar no Whatsapp <i className="ri-whatsapp-line align-middle ms-2"></i>
            </Button>
          }

        </Row>
      </div>
    )
  }
}

  export default SingleActionPublic;