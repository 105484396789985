import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';

const EmailInput = ({ onEmailChange, onExists, value }) => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isExist, setIsExist] = useState('');

  const handleEmailChange = (event) => {
    const inputEmail = event;
    onEmailChange(inputEmail)
    setEmail(inputEmail);
    setIsEmailValid(true);
  };

  const handleBlur = () => {
    if (!isValidEmail(email)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
      verifyEmail(email);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const verifyEmail = (email) => {
    axios
      .get(`${process.env.REACT_APP_API}/api/email/verify?email=${email}`)
      .then((response) => {
        console.log(response.data);
        if(response.data.exists === true){
          if(response.data.table === 'Broker'){
            setIsExist('Este e-mail já está cadastrado para um corretor.')
          }
          if(response.data.table === 'RealEstate'){
            setIsExist('Este e-mail já está cadastrado para uma imobiliária.')
          }
          if(response.data.table === 'Owner'){
            setIsExist('Este e-mail já está cadastrado para um proprietário.')
          }
        }else{
          setIsExist('')
        }
        
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    onExists(isExist);
  }, [isExist]);

  useEffect(() => {
    if(value && value !== ''){
      handleEmailChange(value)
    }
  }, [value])

  return (
    <div>
      <input
        type="text"
        value={email}
        onChange={(e) => handleEmailChange(e.target.value)}
        onBlur={handleBlur}
        placeholder="Digite seu e-mail"
      />
      {isExist !== '' &&
        <div style={{textAlign:'center'}}>
          <p style={{ color: 'red' }}>{isExist}<br></br>Faça login, ou utilize outro e-mail.</p>
          <Button onClick={() => {
            window.open('./login', '_parent')
          }}>Fazer login</Button>
        </div>
      }
      {!isEmailValid && <p style={{ color: 'red' }}>Por favor, insira um e-mail válido.</p>}
    </div>
  );
};

export default EmailInput;