import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'
import Select from "react-select";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_editProperties.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
require('moment/locale/pt.js');

class editOutletAdm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imoveis: [],
      unidades: [],
      imoveisAdd: [],
      imovelAdd: [],
      unidadesAdd:[],
      unidadeAdd:[],
      allImoveis: [],
      filterImoveis:[],
      filterUnidades:[],
      search:'',
      confirm_both: false,
      confirm_both_unit: false,
      success_dlg: false,
      error_dlg:false,
      dynamic_title: "",
      dynamic_description: "",
      delete_id:"",
      delete_sku:"",
      statusFilter:'Todos',
      workflowFilter:'Todos',
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Outlet", link: "#" },
    ],
    }
  }


  componentDidMount(){

    document.getElementById('load').classList.add('active')
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        if(localStorage.getItem('access') === 'A'){
            
          Axios.get(process.env.REACT_APP_API + '/api/auth/opportunity' ,
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            this.setState({filterImoveis: resp.data})

            this.setState({imoveis: resp.data}, () => {

            setTimeout(() => {
              document.getElementById('load').classList.remove('active')
            }, 300);
           })
          })

          Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-units-opportunity' ,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(resp => {
              this.setState({filterUnidades: resp.data})
  
              this.setState({unidades: resp.data}, () => {
  
              setTimeout(() => {
                document.getElementById('load').classList.remove('active')
              }, 300);
             })
            })

          Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-units' ,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              console.log(response.data)
              for(var i = 0; response.data.length > i; i++){
                this.state.unidadesAdd.push({
                  value: response.data[i].id,
                  label: response.data[i].enterprise?.title + " | " + response.data[i].title
                })
              }
             })


          Axios.post(process.env.REACT_APP_API + '/api/public/property-filter-no-pagination', {workflow:['7']},
          { headers: { Authorization: 'Bearer ' + localStorage.getItem('token')}})
          .then(response => {
              for(var i = 0; response.data.length > i; i++){
                  this.state.imoveisAdd.push({
                    value: response.data[i].id,
                    label: response.data[i].sku + " - " + response.data[i].title
                  })
                }
          })

        }else{
          setTimeout(() => {
            window.open("/dashboard", '_parent')
          }, 300);
        }
    })
    .catch(response =>{
      localStorage.removeItem("history_url")
      setTimeout(() => {
        window.open("/login", '_parent')
      }, 300);
    })
  }

  render() {
    const data = {
      columns: [
        {
          dataField: "status",
          text: "Status"
        },
        {
          dataField: 'sku',
          text: 'Código'
        },
        {
          dataField: "photo",
          text: "Imagem"
        },
        {
          dataField: "title",
          text: "Nome"
        },
        {
          dataField: "sale_value",
          text: "De"
        },
        {
          dataField: "offer_value",
          text: "Por"
        },
        {
          dataField: "discount",
          text: "%"
        },
        {
          dataField: "workflow",
          text: "WorkFlow"
        },
        {
          dataField: "view",
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => { 
              setTimeout(() => {
                window.open('./imovel/' + row.slug, '_blank' )
              }, 300);
            },
          },
          text: "Ver"
        },
        {
          dataField: "delete",
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => { 
              this.setState({ confirm_both: true })
              this.setState({ delete_id: row.id })
              this.setState({ delete_sku: row.sku })
            },
          },
          text: "Excluir"
        },
      ],
    };

    const dataUnit = {
      columns: [
        {
          dataField: "status",
          text: "Status"
        },
        {
          dataField: 'sku',
          text: 'Código'
        },
        {
          dataField: "photo",
          text: "Imagem"
        },
        {
          dataField: "enterprise",
          text: "Empreendimento"
        },
        {
          dataField: "title",
          text: "Nome"
        },
        {
          dataField: "sale_value",
          text: "De"
        },
        {
          dataField: "offer_value",
          text: "Por"
        },
        {
          dataField: "discount",
          text: "%"
        },
        {
          dataField: "view",
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => { 
              setTimeout(() => {
                window.open('./unidade/' + row.slug, '_blank' )
              }, 300);
            },
          },
          text: "Ver"
        },
        {
          dataField: "delete",
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => { 
              this.setState({ confirm_both_unit: true })
              this.setState({ delete_id: row.id })
              this.setState({ delete_sku: row.sku })
            },
          },
          text: "Excluir"
        },
      ],
    };

    const {imoveis, filterImoveis, unidades, filterUnidades} = this.state;

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos imóveis', value: imoveis.length
        }]

    };

    const optionsUnit = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos imóveis', value: unidades.length
        }]

    };

    // const selectRow = {
    //   mode: 'checkbox',
    //   clickToSelect: true
    // };

    const imoveisObject = []

    {imoveis?.sort(function(a,b){
      return new Date(b.created_at) - new Date(a.created_at)}).map(imovel => {
      const novoImovel = {
        id: imovel?.id,
        view: <i className="ri-eye-fill" style={{color:'#3E5E3F', fontSize:'18px', cursor:'pointer'}}></i>,
        delete: <i className="ri-delete-bin-6-line"></i>,
        sku: imovel?.sku,
        title: imovel?.title,
        created_at: moment(imovel?.created_at).format('DD/MM/YYYY'),
        photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + (imovel?.photos.filter(photo => photo.order === 0)[0].small_image) + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'55px', height: '55px', borderRadius:'5px'}}></div>,
        sale_value:(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}),
        offer_value:(imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}),
        discount: (100 - ((imovel?.sell_price) / (imovel?.sale_value / 100))).toLocaleString({minimumFractionDigits: 2}, {maximumFractionDigits: 2}) + '%',
        workflow: imovel?.workflow === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
        : imovel?.workflow === 1 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fotos</Badge>
        : imovel?.workflow === 2 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Aut. Venda</Badge>
        : imovel?.workflow === 3 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Prop.</Badge>
        : imovel?.workflow === 4 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.workflow === 11 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fora do padrão</Badge>
        : imovel?.workflow === 5 ? <Badge className="bg-dark me-1 rounded-pill" style={{color:'#333'}}>Repetido</Badge>
        : imovel?.workflow === 6 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (ext)</Badge>
        : imovel?.workflow === 8 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (fs)</Badge>
        : imovel?.workflow === 9 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.workflow === 10 ? <Badge className="badge-soft-dark rounded-pill me-1">Bloqueado</Badge>
        : imovel?.workflow === 13 ? <Badge className="bg-warning rounded-pill me-1">Pendente Atualização</Badge>
        : imovel?.workflow === 14 ? <Badge className="bg-danger rounded-pill me-1">Bloqueado Atualização</Badge>
        : <Badge className="bg-success me-1 rounded-pill">Aprovado</Badge>,
        status: imovel?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>,
        slug: imovel?.slug
      }
      imoveisObject.push(novoImovel); 
    })}

    const unidadesObject = []

    {unidades?.sort(function(a,b){
      return new Date(b.created_at) - new Date(a.created_at)}).map(imovel => {
      const novaUnidade = {
        id: imovel?.id,
        view: <i className="ri-eye-fill" style={{color:'#3E5E3F', fontSize:'18px', cursor:'pointer'}}></i>,
        delete: <i className="ri-delete-bin-6-line"></i>,
        sku: imovel?.sku,
        title: imovel?.title,
        enterprise: imovel.enterprise?.title,
        created_at: moment(imovel?.created_at).format('DD/MM/YYYY'),
        photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + (imovel?.enterprise_photos?.sort((a, b) => a.order - b.order)[0]?.small_image) + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'55px', height: '55px', borderRadius:'5px'}}></div>,
        sale_value:(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}),
        offer_value:(imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}),
        discount: imovel?.sell_price ? ((100 - ((imovel?.sell_price) / (imovel?.sale_value / 100))).toLocaleString({minimumFractionDigits: 2}, {maximumFractionDigits: 2}) + '%') : '0%',
        status: imovel?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>,
        slug: imovel?.slug
      }
      unidadesObject.push(novaUnidade); 
    })}



    return (
        <div className="page-content" id="editarImoveisAdm">
        <Container fluid={true}>
          <Breadcrumbs title="Outlet" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  
                  <Row style={{justifyContent:'space-between', marginBottom: '20px'}}>
                    <Col lg={4} md={6} xs={12} style={{display:'flex', alignItems:'center', gap:'10px'}}>
                        <Label>Imóvel:</Label>
                        <Select
                        value={this.state.imovelAdd}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                            this.setState({ imovelAdd: e })
                        }}
                        options={this.state.imoveisAdd}
                        classNamePrefix="select2-selection"
                        className="addImovel"
                        />
                        <Button onClick={() => {
                          const data = new FormData()
                          data.append('opportunity', 1)
                          Axios.post(process.env.REACT_APP_API + '/api/auth/property-dash/' +  this.state.imovelAdd.value, data,
                          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                          .then(response => {
                            setTimeout(() => {
                              window.location.reload()
                            }, 300);
                          })
                        }}>Add</Button>
                    </Col>
                    <Col className="col-auto searchOutletListContainer" style={{display:'flex', flexDirection:'row'}}>
                      <Input
                      className="searchOutletList"
                      placeholder="Buscar código ou imóvel"
                      onChange={(e) => this.setState({imoveis: filterImoveis.filter(imovel => imovel?.sku?.toLowerCase().includes(e.target.value.toLowerCase())
                      || imovel?.title?.toLowerCase().includes(e.target.value.toLowerCase())
                      )})}></Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">

                  <BootstrapTable
                    keyField='id'
                    data={imoveisObject}
                    columns={data.columns}
                    // expandRow={expandRow}
                    pagination={paginationFactory(options)}
                    // selectRow={false}
                  />
                  </div>

                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  
                  <Row style={{justifyContent:'space-between', marginBottom: '20px'}}>
                    <Col lg={4} md={6} xs={12} style={{display:'flex', alignItems:'center', gap:'10px'}}>
                        <Label>Und. Empreend.:</Label>
                        <Select
                        value={this.state.unidadeAdd}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                            this.setState({ unidadeAdd: e })
                        }}
                        options={this.state.unidadesAdd}
                        classNamePrefix="select2-selection"
                        className="addImovel"
                        />
                        <Button onClick={() => {
                          Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-unit/' + this.state.unidadeAdd.value, {
                            unit:{
                              id:this.state.unidadeAdd.value,
                              opportunity:1
                            }
                          },
                          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                          .then(response => {
                            setTimeout(() => {
                              window.location.reload()
                            }, 300);
                          })
                        }}>Add</Button>
                    </Col>
                    <Col className="col-auto searchOutletListContainer" style={{display:'flex', flexDirection:'row'}}>
                      <Input
                      className="searchOutletList"
                      placeholder="Buscar código ou unidade"
                      onChange={(e) => this.setState({unidades: filterUnidades.filter(imovel => imovel?.sku?.toLowerCase().includes(e.target.value.toLowerCase())
                      || imovel?.title?.toLowerCase().includes(e.target.value.toLowerCase())
                      )})}></Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">

                  <BootstrapTable
                    keyField='id'
                    data={unidadesObject}
                    columns={dataUnit.columns}
                    // expandRow={expandRow}
                    pagination={paginationFactory(optionsUnit)}
                    // selectRow={false}
                  />
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {this.state.confirm_both_unit ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>{
              Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-unit/' + this.state.delete_id, {
                unit:{
                  id:this.state.delete_id,
                  opportunity:0
                }
              },
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({
                  confirm_both: false,
                  success_dlg: true,
                  dynamic_title: "Deletado",
                  dynamic_description: "A unidade não está mais no Outlet."
                })
              })
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "A unidade ainda está no Outlet."
              })
            }
          >
            <p className="alertText">Tem certeza que deseja retirar a unidade <b>{this.state.delete_sku}</b> do Outlet?</p>
          </SweetAlert>
        ) : null}
        {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>{
              const data = new FormData()
              data.append('opportunity', 0)
              Axios.post(process.env.REACT_APP_API + '/api/auth/property-dash/' +  this.state.delete_id, data,
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({
                  confirm_both: false,
                  success_dlg: true,
                  dynamic_title: "Deletado",
                  dynamic_description: "O imóvel não está mais no Outlet."
                })
              })
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "O imóvel ainda está no Outlet."
              })
            }
          >
            <p className="alertText">Tem certeza que deseja retirar o imóvel <b>{this.state.delete_sku}</b> do Outlet?</p>
          </SweetAlert>
        ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => {
                this.setState({ success_dlg: false })
                window.location.reload()
              }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

          {this.state.error_dlg ? (
            <SweetAlert
              error
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ error_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default editOutletAdm;