import { Col, Label, Input, Row, Container, Button, Spinner } from "reactstrap";
import './styles.scss';
import { useState, useEffect } from 'react';
import PasswordChecklist from "react-password-checklist";
import EmailInput from '../../../Home/components/EmailInput';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from "react-select";
import Axios from 'axios';

export const NewUserModal = ({open, onClose, handleUpdate, adm = false}) => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [truePassword, setTruePassword] = useState(false)
  const [emailIsExist, setEmailIsExist] = useState('0')
  const [type, setType] = useState('')
  const [loading, setLoading] = useState(false)
  const [registerError, setRegisterError] = useState(false)
  const [selectOptionsConstructions,setSelectOptionsConstructions] = useState([])
  const [selectedConstruction, setSelectedConstruction] = useState([])

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail)
  };

  const handleEmailExists = (isExist) => {
    setEmailIsExist(isExist)
  };

  useEffect(() => {
    if(adm){
      Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions',
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setSelectOptionsConstructions(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
        })
    }
  }, [adm])

  const handleCreate = () => {
    setLoading(true)
    const data = new FormData()
    data.append('name', name)
    data.append('email', email)
    data.append('password', password)
    data.append('role', type)
    if(adm){
      data.append('construction_id', selectedConstruction.value)
    }else{
      data.append('construction_id', localStorage.getItem('id'))
    }
    
    Axios.post(
      process.env.REACT_APP_API + '/api/register/sub-user',
      data,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
    )
      .then((response) => {
        setLoading(false)
        onClose()
        handleUpdate()
        handleClear()
      })
      .catch((response) => {
        setRegisterError(true)
      })
  }

  const handleClear = () => {
    setName('')
    setEmail('')
    setPassword('')
    setConfirmPassword('')
    setTruePassword(false)
    setEmailIsExist('0')
    setType('')
  }

  return(
    <>
      <div className={`new-user-modal ${open ? 'active' : ''}`}>
        <span className="close-modal" onClick={onClose}><i className="ri-close-line"></i></span>

        <div className="new-user-modal-title">Adicionar novo usuário</div>
        {loading ? 
          <Spinner className="me-2" color="success" />
        :
        <>
        <div className="new-user-modal-body">
          <Container>
            {adm && <Row style={{marginBottom:'10px'}}>
              <Col md={6}>
                <Label className="form-label">Construtora</Label>
                <Select
                  value={selectedConstruction}
                  placeholder="Selecione"
                  noOptionsMessage={(inputValue) => `Sem resultados`}
                  onChange={(e) => setSelectedConstruction(e)}
                  options={selectOptionsConstructions}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>}
            <Row>
              <Col md={6}>
                <fieldset>
                  <Label>Nome</Label>
                  <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </fieldset>
              </Col>
              <Col md={6}>
                <fieldset>
                  <Label>Email</Label>
                  <EmailInput simpleVerification={true} value={email} onEmailChange={handleEmailChange} onExists={handleEmailExists}/>
                </fieldset>
              </Col>
            </Row>
            <Row className="type-container">
              <Col xs={12}>
                <Label>Selecione a permissão</Label>
              </Col>
              <Col xs={12} md={4}>
                <div
                  className={`type-button ${type === 'view' ? 'active' : ''}`}
                  onClick={() => setType('view')}
                >
                  <i className="ri-eye-line"></i>Apenas visualizar
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div
                  className={`type-button ${type === 'admin' ? 'active' : ''}`}
                  onClick={() => setType('admin')}
                >
                  <i className="ri-edit-line"></i>Fazer edições
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <fieldset>
                  <Label>Crie sua senha</Label>
                  <Input 
                    type="password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </fieldset>
              </Col>
              <Col md={6}>
                <fieldset>
                  <Label>Confirme sua senha</Label>
                  <Input
                    type="password" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </fieldset>
              </Col>
              <Col xs={12}>
                <PasswordChecklist
                  rules={["minLength","specialChar","number","capital","match"]}
                  minLength={8}
                  iconSize={16}
                  value={password}
                  valueAgain={confirmPassword}
                  onChange={(isValid) => setTruePassword(isValid)}
                  messages={{
                      minLength: "Ao menos 8 caracteres.",
                      specialChar: "Ao menos um caractere especial.",
                      number: "Ao menos um número.",
                      capital: "Ao menos uma letra maiúscula.",
                      match: "Suas senhas coincidem.",
                  }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="new-user-modal-actions">
          <Button className="close-modal-button" onClick={onClose}>Cancelar</Button>
          {(type !== '' && email !== '' && name !== '' && emailIsExist === '' && truePassword === true) ?
            <Button onClick={handleCreate}>Criar usuário</Button>
            :
            <Button disabled>Preencha todos os campos</Button>
          }
        </div>
        </>
        }
      </div>
      <div className={`new-user-modal-overlay ${open ? 'active' : ''}`} onClick={onClose}></div>

      {registerError ? (
        <SweetAlert
          title="Seu cadastro falhou, fale com nosso suporte."
          showCloseButton={true}
          showConfirm={true}
          error
          onConfirm={() => {
            setTimeout(() => {
              window.open('https://wa.me/554741081865', '_blank')
            }, 0);
          }}
        ></SweetAlert>
      ) : null}
    </>
  )
}