import styles from '../../styles/mobile/ImobilesThirdMobile.module.css';
import Icon from '@mdi/react';
import { mdiMagnify, mdiHomeOutline, mdiCarBack, mdiBedKingOutline, mdiCurrencyUsd, mdiFilterMultiple, mdiPencilRulerOutline } from '@mdi/js';
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import FooterThind from '../desktop/FooterThird';
import ContentEditable from "react-contenteditable";
import { ChromePicker } from 'react-color';

const ImobilesThirdImobile = () => {
  const [logo, setLogo] = useState();
  const [bg, setBg] = useState()
  const [modalLarge, setModalLarge] = useState(false)
  const [homeThirdTitle, setThirdHomeTitle] = useState('Insira um título para seu site')
  const [homeThirdTitleEnabled, setThirdHomeTitleEnabled] = useState(true)
  const [colorTitle, setColorTitle] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [showPicker, setShowPicker] = useState(false);
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [openNav, setOpenNav] = useState(false)

  function isBase64(str) {
    try {
      atob(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function handleFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // converte o arquivo em uma string Base64
      const fileAsBase64String = reader.result.split(',')[1];

      // armazena a string Base64 no estado
      localStorage.setItem('nav_logo', fileAsBase64String);

      localStorage.setItem('imobiles_banner', fileAsBase64String);
    });
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    const navLogo = localStorage.getItem('nav_logo');
    if (navLogo && navLogo.slice(0, 4) !== 'http') {
      if (localStorage.getItem('nav_logo')) {
        const fileAsBase64String = localStorage.getItem('nav_logo');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
  
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
  
          const blob = new Blob([array], { type: 'application/octet-stream' });
  
          const file = new File([blob], 'nome-do-arquivo.extensão');
  
          setLogo(file);
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      }
    } else {
      setLogo(navLogo);
    }

    const imobilesBanner = localStorage.getItem('imobiles_banner');
    if (imobilesBanner && imobilesBanner.slice(0, 4) !== 'http') {
      if(localStorage.getItem('imobiles_banner')){
        const fileAsBase64String = localStorage.getItem('imobiles_banner');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
      
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
      
          const blob = new Blob([array], { type: 'application/octet-stream' });
      
          const file = new File([blob], 'nome-do-arquivo.extensão');
      
          setBg(file) ;
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      }
    } else {
      setBg(localStorage.getItem('imobiles_banner'))
    }

    if(localStorage.getItem('home_button_text_color')){
      setColorTitle(JSON.parse(localStorage.getItem('home_button_text_color')))
    }
    if(localStorage.getItem('home_title')){
      setThirdHomeTitle(localStorage.getItem('home_title'))
    }
    if(localStorage.getItem('home_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('home_button_color')))
    }
    if(localStorage.getItem('home_button_text_color')){ 
      setColorTextButton(JSON.parse(localStorage.getItem('home_button_text_color')))
    }
  }, [])

  const toggleNav = () => {
    setOpenNav(!openNav)
  }

  return(
    <section>
      <nav>
        <div className={styles.bg} 
          style={localStorage.getItem('imobiles_banner') && localStorage.getItem('imobiles_banner').slice(0,4) !== 'http' ? {backgroundImage: 'url(' + (bg ? URL.createObjectURL(bg) : '') + ')', position:'relative'} : {backgroundImage: `url(${bg})`} }>
          <div className={styles.actionIcons}>
            <label className="bg" onClick={() => setModalLarge(!modalLarge)}><span>Inserir imagem</span><i className="ri-image-add-fill"></i></label>
          </div>
          <div className={styles.bg_logo}>
              {logo ? (
                <img src={(logo && logo.slice(0, 4) !== "http") ? URL.createObjectURL(logo) : logo}
                  alt=""
                  className={styles.logo}
                />
              ) : (
                <div className={styles.semLogo}>Sua logo</div>
              )}
            <label for="logo" className={styles.inputLogo}><i className="ri-camera-fill"></i></label>
            <input id="logo" name="logo" type="file" style={{display:'none'}} onChange={(e) => {setLogo(e.target.files[0]); handleFileChange(e)}} />
        </div>

        <div className={styles.title}>
        <ContentEditable
          html={homeThirdTitle}
          className={styles.titleEdit}
          id='titleEdit'
          disabled={homeThirdTitleEnabled}
          style={{
            color: `rgba(${colorTitle?.r}, ${colorTitle?.g}, ${colorTitle?.b}, ${colorTitle?.a})`,
          }}
          onChange={(e) => {
            setThirdHomeTitle(e.target.value)
            localStorage.setItem('home_title', e.target.value)
          }}
          onClick={(e) => {
            if(homeThirdTitle === 'Insira um título para seu site' && !homeThirdTitleEnabled){
              setThirdHomeTitle('')
              localStorage.setItem('home_title', '')
            }
          }}
        />
        <div className={styles.actionIconsTitle}>
          <label className="title">
            <span onClick={() => {
              if(!homeThirdTitleEnabled){
                setThirdHomeTitleEnabled(true)
                if(homeThirdTitle === ''){
                  setThirdHomeTitle('Insira um título para seu site')
                  localStorage.setItem('home_title', 'Insira um título para seu site')
                }
              }else{
                setThirdHomeTitleEnabled(false)
                setTimeout(() => {
                  document.getElementById('titleEdit').focus()
                  if(homeThirdTitle === 'Insira um título para seu site'){
                    setThirdHomeTitle('')
                    localStorage.setItem('home_title', '')
                  }
                }, 300);
              }
            }}>{homeThirdTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
            <i className="ri-text" onClick={() => {
              if(!homeThirdTitleEnabled){
                setThirdHomeTitleEnabled(true)
                if(homeThirdTitle === ''){
                  setThirdHomeTitle('Insira um título para seu site')
                  localStorage.setItem('home_title', 'Insira um título para seu site')
                }
              }else{
                setThirdHomeTitleEnabled(false)
                setTimeout(() => {
                  document.getElementById('titleEdit').focus()
                  if(homeThirdTitle === 'Insira um título para seu site'){
                    setThirdHomeTitle('')
                    localStorage.setItem('home_title', '')
                  }
                }, 300);
              }
            }}></i>
            <i className="ri-font-color" onClick={() => setShowPicker(!showPicker)}></i>
             {showPicker && (
              <div className={styles.colorPickerTitle}>
                <i className="ri-close-fill closeIcon" onClick={() => setShowPicker(!showPicker)}></i>
                <ChromePicker
                  color={colorTitle}
                  onChange={(e) => {
                    setColorTitle(e.rgb)
                    localStorage.setItem('home_button_text_color', JSON.stringify(e.rgb))
                  }}
                />
                </div>
              )}
          </label>
        </div>
          {/* <h1>Encontre aqui os melhores imóveis da cidade</h1> */}
        </div>
      </div>

        <div className={styles.container_filter}>
          <button onClick={toggleNav}
          style={{
            backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
            color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
          }}>Filtros <Icon path={mdiFilterMultiple} size={1} /></button>
        </div>

        <div className={`${styles.container_search} ${openNav ? '' : styles.hide}`}>
          <div className={styles.search_imobile}>
            <Icon path={mdiMagnify} size={1} className={styles.icon_lupa} />
          <input type="text" placeholder='Digite o nome do empreendimento'/>
          </div>

          <div className={styles.type_imobile}>
            <Icon path={mdiHomeOutline} size={1} className={styles.icon_house}/>
            <select>
              <option value="" selected>Tipo do imóvel</option>
            </select>
          </div>

          <div className={styles.container_vacancies_bedrooms}>
            <div className={styles.search_vacancies}>
              <Icon path={mdiCarBack} size={1} className={styles.icon_car}/>
              <select>
                <option value="" selected>Vagas</option>
              </select>
            </div>

            <div>
              <div className={styles.search_bedrooms}>
              <Icon path={mdiBedKingOutline} size={1} className={styles.icon_bedroom}/>
                <select>
                  <option value="" selected>Quartos</option>
                </select>
              </div>
            </div>
          </div>

          <div className={styles.search_price}>
            <Icon path={mdiCurrencyUsd} size={1} className={styles.icon_price} />
            <input type="text" placeholder='Digite o nome do empreendimento'/>
          </div>

          <div className={styles.container_button_nav}>
            <div>
              <button className={styles.close}>Fechar</button>
            </div>

            <div>
              <button className={styles.search}>Buscar</button>
            </div>
          </div>
        </div>
      </nav>

      <div>
        <h1 className={styles.title}>Nossos Imóveis</h1>

        <div className={styles.container_carousel}>
          <Swiper
            slidesPerView={1}
            spaceBetween={60}
            loop={true}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className={styles.container_imobile}>
                <div className={styles.bg_teste}></div>
                <div className={styles.container_config}>
                  <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                  <p className={styles.price}>R$<span>4.000.000,00</span></p>

                  <div className={styles.container_config_imobile}>
                    <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                    <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                    <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                  </div>
                  <div className={styles.details}></div>
                  <div className={styles.container_view}>
                    <p>Balneário Camboriú</p> 
                    <p className={styles.view}
                      style={{
                        backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                        color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                      }}
                    >Ver mais</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container_imobile}>
                <div className={styles.bg_teste}></div>
                <div className={styles.container_config}>
                  <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                  <p className={styles.price}>R$<span>4.000.000,00</span></p>

                  <div className={styles.container_config_imobile}>
                    <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                    <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                    <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                  </div>
                  <div className={styles.details}></div>
                  <div className={styles.container_view}>
                    <p>Balneário Camboriú</p> 
                    <p className={styles.view}
                    style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}
                    >Ver mais</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container_imobile}>
                <div className={styles.bg_teste}></div>
                <div className={styles.container_config}>
                  <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                  <p className={styles.price}>R$<span>4.000.000,00</span></p>

                  <div className={styles.container_config_imobile}>
                    <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                    <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                    <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                  </div>
                  <div className={styles.details}></div>
                  <div className={styles.container_view}>
                    <p>Balneário Camboriú</p> 
                    <p className={styles.view}>Ver mais</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container_imobile}>
                <div className={styles.bg_teste}></div>
                <div className={styles.container_config}>
                  <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                  <p className={styles.price}>R$<span>4.000.000,00</span></p>

                  <div className={styles.container_config_imobile}>
                    <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                    <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                    <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                  </div>
                  <div className={styles.details}></div>
                  <div className={styles.container_view}>
                    <p>Balneário Camboriú</p> 
                    <p className={styles.view}
                    style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}
                    >Ver mais</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default ImobilesThirdImobile;