import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Col, Card, CardBody, Row, Container, Button, Label, Badge } from "reactstrap";
import { NewUserModal } from './components/newUserModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import Axios from 'axios';

import './styles.scss';
import { EditUserModal } from './components/editUserModal';

export const ConstructionUsers = () => {
  const breadcrumbItems = [
    { title: "Fast Sale", link: "/dashboard" },
    { title: "Usuários", link: "#" },
  ]
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [editId, setEditId] = useState(null)
  const [subUser, setSubUser] = useState([])
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [deleteError, setDeleteError] = useState(false)
  const [dynamicTitle, setDynamicTitle] = useState('')
  const [dynamicDescription, setDynamicDescription] = useState('')
  const [selectedName, setSelectedName] = useState('')

  useEffect(() => {
    handleGet()
  }, [])

  const handleGet = () => {

    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        Axios.get(
          process.env.REACT_APP_API + '/api/auth/subusers/construction/' + localStorage.getItem('id'),
          { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
        )
          .then((response) => {
            setSubUser(response.data)
          })
      }).catch(response => {
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }

  const handleUpdate = () => {
    handleGet()
  }

  const handleEdit = (e) => {
    setEditId(e)
    setOpenEdit(true)
  }

  const handleDelete = (e, name) => {
    setConfirmDelete(true)
    setEditId(e)
    setSelectedName(name)
  }

  return(
    <div className="page-content" id="partnershipPage">
      <Container fluid={true}>
        <Breadcrumbs title="Usuários" breadcrumbItems={breadcrumbItems} />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Container className="sub-user-title">
                  <Row>
                    <Col className="col-auto"><h3>Usuários e permissões de acesso</h3></Col>
                    <Col className="col-auto"><Button onClick={() => setOpen(true)}><i className="ri-user-add-line"></i>Novo usuário</Button></Col>
                  </Row>
                </Container>
                <Container className="sub-user-list">
                  <Row className="sub-user-list-title">
                    <Col xs={3}><Label>Nome</Label></Col>
                    <Col xs={4}><Label>E-mai</Label></Col>
                    <Col xs={3}><Label>Permissão</Label></Col>
                    <Col xs={1} style={{textAlign:"center"}}><Label>Editar</Label></Col>
                    <Col xs={1} style={{textAlign:"center"}}><Label>Excluir</Label></Col>
                  </Row>
                  {subUser.length > 0 ? subUser.map((item, index) => (
                    <Row key={index} className="sub-user-list-user">
                      <Col xs={12} md={3}><p>{item.name}</p></Col>
                      <Col xs={12} md={4}><p>{item.email}</p></Col>
                      <Col xs={4} md={3}>{item.role === 'admin' ? 
                        <Badge size={'lg'} className="bg-dark me-1 rounded-pill">Criar e editar</Badge>
                        :
                        <Badge className="bg-dark me-1 rounded-pill">Visualizar</Badge>
                      }</Col>
                      <Col xs={2} md={1} style={{textAlign:"center"}}>
                        <span onClick={() => handleEdit(item.id)}><i className="ri-edit-line"></i></span>
                      </Col>
                      <Col xs={2} md={1} style={{textAlign:"center"}}>
                        <span onClick={() => handleDelete(item.id, item.name)}><i className="ri-delete-bin-line" style={{color:'#da2c3e'}}></i></span>
                      </Col>
                    </Row>
                  ))
                :
                <p>Você ainda não adicionou nenhum usuário.</p>
                }
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <NewUserModal handleUpdate={handleUpdate} open={open} onClose={() => setOpen(false)}/>
      <EditUserModal handleUpdate={handleUpdate} selected={editId} open={openEdit} onClose={() => setOpenEdit(false)}/>

      {confirmDelete ? (
        <SweetAlert
          title="Atenção!"
          warning
          showCancel
          confirmBtnText="Tenho certeza"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() =>{
            Axios.post(process.env.REACT_APP_API + '/api/auth/delete-subuser/' +  editId, {},
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              setDeleteError(false)
              setDeleteSuccess(true)
              setDynamicTitle("Deletado")
              setDynamicDescription("Usuário deletado.")
            })
          }}
          onCancel={() =>{
            setDeleteError(true)
            setDeleteSuccess(false)
            setDynamicTitle("Cancelado")
            setDynamicDescription("O usuário ainda está cadastrado.")
          }}
        >
          <p className="alertText">Você tem certeza que deseja deletar o usuário <b>{selectedName}</b>?</p>
        </SweetAlert>
      ) : null}

      {deleteSuccess ? (
        <SweetAlert
          success
          title={dynamicTitle}
          onConfirm={() => {
            setDeleteSuccess(false)
            window.location.reload()
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}

      {deleteError ? (
        <SweetAlert
          error
          title={dynamicTitle}
          onConfirm={() => {
            setConfirmDelete(false)
            setDeleteError(false)
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}
    </div>
  )
}