import styles from '../../styles/mobile/HighlightsThirdMobile.module.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Icon from '@mdi/react';
import { mdiPencilRulerOutline, mdiCarBack, mdiBedKingOutline } from '@mdi/js';
import { useState, useEffect } from 'react';
import ContentEditable from "react-contenteditable";
import { ChromePicker } from 'react-color';

const HighlightsThirdMobile = () => {
  const [ highTitle, setHighTitle] = useState('Destaques')
  const [ highTitleEnabled, setHighTitleEnabled] = useState(true)
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [showPickerButton, setShowPickerButton] = useState(false);
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [showPickerTextButton, setShowPickerTextButton] = useState(false);

  useEffect(() => {
    if(localStorage.getItem('high_title')){
      setHighTitle(localStorage.getItem('high_title'))
    }
    if(localStorage.getItem('high_button_text_color')){
      setColorTextButton(JSON.parse(localStorage.getItem('high_button_text_color')))
    }
    if(localStorage.getItem('high_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('high_button_color')))
    }
    
  }, []);

  return(
    <section>
        <div className={styles.titleContainer}>
          <ContentEditable
            html={highTitle}
            className={styles.titleEdit}
            id='highTitleEdit'
            disabled={highTitleEnabled}
            onChange={(e) => {
              setHighTitle(e.target.value)
              localStorage.setItem('high_title', e.target.value)
            }}
            onClick={(e) => {
              if(highTitle === 'Destaques' && !highTitleEnabled){
                setHighTitle('')
                localStorage.setItem('high_title', '')
              }
            }}
          />
          <div className={styles.actionIcons}>
            <label className="title">
              <span onClick={() => {
                if(!highTitleEnabled){
                  setHighTitleEnabled(true)
                  if(highTitle === ''){
                    setHighTitle('Destaques')
                    localStorage.setItem('high_title', 'Destaques')
                  }
                }else{
                  setHighTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('highTitleEdit').focus()
                    if(highTitle === 'Destaques'){
                      setHighTitle('')
                      localStorage.setItem('high_title', '')
                    }
                  }, 300);
                }
              }}>{highTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
              <i className="ri-text" onClick={() => {
                if(!highTitleEnabled){
                  setHighTitleEnabled(true)
                  if(highTitle === ''){
                    setHighTitle('Destaques')
                    localStorage.setItem('high_title', 'Destaques')
                  }
                }else{
                  setHighTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('highTitleEdit').focus()
                    if(highTitle === 'Destaques'){
                      setHighTitle('')
                      localStorage.setItem('high_title', '')
                    }
                  }, 300);
                }
              }}></i>
            </label>
          </div>
      </div>
      {/* <h1 className={styles.title}>Destaques</h1> */}

      <div className={styles.container_carousel}>
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className={styles.container_imobile}>
              <div className={styles.bg}></div>
              <div className={styles.container_config}>
                <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                <p className={styles.price}>R$<span>4.000.000,00</span></p>

                <div className={styles.container_config_imobile}>
                  <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                  <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                  <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                </div>
                <div className={styles.details}></div>
                <div className={styles.container_view}>
                  <p>Balneário Camboriú</p> 
                  <p className={styles.view}
                    style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}
                  >Ver mais</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container_imobile}>
              <div className={styles.bg}></div>
              <div className={styles.container_config}>
                <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                <p className={styles.price}>R$<span>4.000.000,00</span></p>

                <div className={styles.container_config_imobile}>
                  <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                  <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                  <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                </div>
                <div className={styles.details}></div>
                <div className={styles.container_view}>
                  <p>Balneário Camboriú</p> 
                  <p className={styles.view}
                    style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}
                  >Ver mais</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container_imobile}>
              <div className={styles.bg}></div>
              <div className={styles.container_config}>
                <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                <p className={styles.price}>R$<span>4.000.000,00</span></p>

                <div className={styles.container_config_imobile}>
                  <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                  <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                  <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                </div>
                <div className={styles.details}></div>
                <div className={styles.container_view}>
                  <p>Balneário Camboriú</p> 
                  <p className={styles.view}
                    style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}
                  >Ver mais</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container_imobile}>
              <div className={styles.bg}></div>
              <div className={styles.container_config}>
                <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                <p className={styles.price}>R$<span>4.000.000,00</span></p>

                <div className={styles.container_config_imobile}>
                  <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                  <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                  <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                </div>
                <div className={styles.details}></div>
                <div className={styles.container_view}>
                  <p>Balneário Camboriú</p> 
                  <p className={styles.view}
                    style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}
                  >Ver mais</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className={styles.container_button}>
        <button>Ver todos os imóveis</button>
        <div className={styles.actionIcons}>
          <label className="title">
            <i className="ri-brush-fill" onClick={() => {
              setShowPickerButton(!showPickerButton)
              setShowPickerTextButton(false)
              }}></i>
            <i className="ri-font-color" onClick={() => {
              setShowPickerTextButton(!showPickerTextButton)
              setShowPickerButton(false)
              }}></i>
            {showPickerButton && (
              <div className={styles.colorPickerButton}>
                <i className="ri-close-fill closeIcon" onClick={() => setShowPickerButton(!showPickerButton)}></i>
                <ChromePicker
                  color={colorButton ?? {r: 0, g: 0, b: 0, a: 1}}
                  onChange={(e) => {
                    setColorButton(e.rgb)
                    localStorage.setItem('high_button_color', JSON.stringify(e.rgb))
                  }}
                />
                </div>
              )}
              {showPickerTextButton && (
              <div className={styles.colorPickerButton}>
                <i className="ri-close-fill closeIcon" onClick={() => setShowPickerTextButton(!showPickerTextButton)}></i>
                <ChromePicker
                  color={colorTextButton ?? {r: 0, g: 0, b: 0, a: 1}}
                  onChange={(e) => {
                    setColorTextButton(e.rgb)
                    localStorage.setItem('high_button_text_color', JSON.stringify(e.rgb))
                  }}
                />
                </div>
              )}
          </label>
        </div>
      </div>
    </section>
  )
}

export default HighlightsThirdMobile;