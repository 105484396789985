import Axios from 'axios';
import React, { Component } from 'react';
import Select from "react-select";
import { Col, Card, CardBody, Button, Spinner, Row, CardHeader, Input, Label, Form, Container } from "reactstrap";
import CurrencyInput from '../../components/Inputs/CurrencyInput';

let selectOptions = []

class UnitsUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
          SelectedEnterprises:[],
          units:[],
        //   isLoading: false,
        }
      }

       componentDidMount(){
         document.getElementById('loadTotal').classList.add('active')
         Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-by-select/' + localStorage.getItem('id'),
         {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
         .then(response => {

            for(var i = 0; response.data.length > i; i++){
                selectOptions.push({
                label: response.data[i].title,
                value: response.data[i].id
            })}

           setTimeout(() => {
             document.getElementById('loadTotal').classList.remove('active')
           }, 300);
         })
       }

       handleSelect = SelectedEnterprises => {
		    this.setState({ SelectedEnterprises });

            Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + SelectedEnterprises.value + '/types' ,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {

                const allUnits = response.data.reduce((accumulator, type) => {
                    if (type.units) {
                        accumulator.push(...type.units);
                    }
                    return accumulator;
                }, []);
        
                this.setState({ units: allUnits });
            })
        };

        handleInputChange = (e, index, field) => {
            const { name, value } = e.target || e;
            this.setState((prevState) => {
              const updatedUnits = [...prevState.units];
              updatedUnits[index][field] = value;
              return { units: updatedUnits };
            });
          };

          handleClick = (unit) => {
            const updatedUnits = this.state.units.map((u) =>
                u.id === unit.id ? { ...u, isLoading: true } : u
            );
            this.setState({ units: updatedUnits });
    
            Axios.post(
                process.env.REACT_APP_API +
                    '/api/auth/enterprise/enterprise-properties-types-unit/' +
                    unit.id,
                {
                    unit,
                },
                { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                .finally(() => {
                    const updatedUnits = this.state.units.map((u) =>
                        u.id === unit.id ? { ...u, isLoading: false } : u
                    );
                    this.setState({ units: updatedUnits });
                });
        };

    render() {

        const {SelectedEnterprises, units} = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col md={12} style={{ height: "auto", position:'relative'  }}>
                        <Card style={{height:'100%'}}>
                            <CardBody>
                                <div style={{
                                    marginBottom:'20px'
                                }}><h3 style={{
                                    fontSize:'18px'
                                }}>Atalho gestão de unidades</h3></div>
                                <Label className="col-md-12 col-form-label">Selecione um empreendimento</Label>
                                <Select
                                    value={SelectedEnterprises}
                                    placeholder="Selecione"
                                    noOptionsMessage={(inputValue) => `Sem resultados`}
                                    onChange={this.handleSelect}
                                    options={selectOptions}
                                    classNamePrefix="select2-selection"
                                />
                                <Row>
                                    <Col xs={12} style={{height:'480px', overflow:'auto', position:'relative'}}>
                                        {units.length > 0 ?
                                        (units?.map((unit, index) => (
                                            <Form className="cardBody" key={index}>
                                            <Container style={{padding:'20px 20px 0', marginBottom: '15px'}}>
                                            <Row>
                                                <Col md={3}>
                                                <Label>Código</Label>
                                                <Input
                                                    type="text"
                                                    placeholder="Código da unidade"
                                                    value={unit.sku}
                                                    onChange={(e) => this.handleInputChange(e, index, 'sku')}
                                                />
                                                </Col>
                                                <Col md={4}>
                                                <Label>Valor</Label>
                                                <CurrencyInput defaultValue={unit.price} onChange={(e) => this.handleInputChange( e, index, 'price')}/>
                                                </Col>
                                                <Col md={3}>
                                                <Label>Status</Label>
                                                <select className="form-control" value={unit.status} onChange={(e) => this.handleInputChange(e, index, 'status')}>
                                                <option value={null}>Selecione</option>
                                                    <option value="1">Disponível</option>
                                                    <option value="2">Reservado</option>
                                                    <option value="3">Vendido</option>
                                                    <option value="0">Indispónível</option>
                                                </select>
                                                </Col>
                                                <Col className="col-auto" style={{display:'flex', alignItems:'center'}}>
                                                {unit.isLoading ? (
                                                    <Spinner className="me-2" color="primary" />
                                                ) : (
                                                    <Button
                                                        onClick={() => this.handleClick(unit)}
                                                        disabled={unit.isLoading}
                                                    >
                                                        Salvar
                                                    </Button>
                                                )}
                                                </Col>
                                            </Row>
                                            </Container>
                                            </Form>
                                        )))
                                        :
                                        <div style={{
                                            margin:'30px 10px',
                                            textAlign:'center',
                                            position:'absolute',
                                            top:0,
                                            bottom:0,
                                            left:0,
                                            right:0,
                                            display:'flex',
                                            backgroundColor:'#f4f4f4',
                                            alignItems:'center',
                                            justifyContent:'center'
                                        }}><p>Selecione um empreendimento para administrar suas unidades.</p></div>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                            <div id="loadTotal" className="load" style={{position:'absolute', zIndex:'99'}}>
                                <Spinner className="me-2" color="success" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default UnitsUpdate;