import FastShowCaseItajai from './../../assets/images/popups/fast-showcase-itajai.png';

function PromoPage (){

  return(
    <div style={{
      width:'100%',
      maxWidth:'580px',
      margin:'auto'
    }}>
      <div style={{
        width:'100%',
        borderRadius:'8px',
        backgroundImage:`url('${FastShowCaseItajai}')`,
        backgroundPosition:'top center',
        backgroundSize:'cover',
        height:'440px'
      }}></div>

      <div style={{
        display:'flex',
        flexDirection:'column',
        gap:'20px',
        justifyContent:'center',
        alignItems:'center',
        margin:'20px'
      }}>
        <button
          style={{
            width:'300px',
            backgroundColor:'#3E5E3F',
            color:'#fff',
            border:'none',
            borderRadius:'10px',
            padding:'5px 0',
            fontSize:'18px'
          }}
          onClick={() => {
            setTimeout(() => {
              window.open('/checkout/corretor?plan=1', '_parent')
            }, 100);
          }}
        >Sou um corretor</button>
        <button
          style={{
            width:'300px',
            backgroundColor:'#3E5E3F',
            color:'#fff',
            border:'none',
            borderRadius:'10px',
            padding:'5px 0',
            fontSize:'18px'
          }}
          onClick={() => {
            setTimeout(() => {
              window.open('/checkout/imobiliaria?plan=1', '_parent')
            }, 100);
          }}
        >Represento uma imobiliária</button>
      </div>
    </div>
  )
}

export default PromoPage;