import styles from '../styles/Filter.module.css';
import Apartament from '../assets/building4.png';
import House from '../assets/building.png';
import Shop from '../assets/building3.png';
import Terrain from '../assets/building2.png';
import Icon from '@mdi/react';
import { mdiFilterOutline } from '@mdi/js';
import { useState, useEffect } from 'react';
import CurrencyInput from '../../../components/Inputs/CurrencyInput';
import ContentEditable from "react-contenteditable";


const Filter = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedVacancies, setSelectedVacancies] = useState([]);
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const [valorProposta, setValorProposta] = useState('');
  const [ highSecondTitle, setHighSecondTitle] = useState('Destaques')
  const [ highSecondTitleEnabled, setHighSecondTitleEnabled] = useState(true)

  useEffect(() => {
    if(localStorage.getItem('high_imobiles_second_title')){
      setHighSecondTitle(localStorage.getItem('high_imobiles_second_title'))
    }
  }, []);

  const selectCategory = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((item) => item !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const selectVacancy = (vacancy) => {
    if (selectedVacancies.includes(vacancy)) {
      setSelectedVacancies(selectedVacancies.filter((item) => item !== vacancy))
    } else {
      setSelectedVacancies([...selectedVacancies, vacancy])
    }
  };

  const selectBedrooms = (bedrooms) => {
    if (selectedBedrooms.includes(bedrooms)) {
      setSelectedBedrooms(selectedBedrooms.filter((item) => item !== bedrooms))
    } else {
      setSelectedBedrooms([...selectedBedrooms, bedrooms])
    }
  };

  return(
    <>
      {/* <h1>Destaques</h1>     */}
      <div className={styles.titleContainer}>
        <ContentEditable
            html={highSecondTitle}
            className={styles.titleEdit}
            id='highTitleEdit'
            disabled={highSecondTitleEnabled}
            onChange={(e) => {
              setHighSecondTitle(e.target.value)
              localStorage.setItem('high_imobiles_second_title', e.target.value)
            }}
            onClick={(e) => {
              if(highSecondTitle === 'Destaques' && !highSecondTitleEnabled){
                setHighSecondTitle('')
                localStorage.setItem('high_imobiles_second_title', '')
              }
            }}
          />
          <div className={styles.actionIcons}>
            <label className="title">
              <span onClick={() => {
                if(!highSecondTitleEnabled){
                  setHighSecondTitleEnabled(true)
                  if(highSecondTitle === ''){
                    setHighSecondTitle('Destaques')
                    localStorage.setItem('high_imobiles_second_title', 'Destaques')
                  }
                }else{
                  setHighSecondTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('highTitleEdit').focus()
                    if(highSecondTitle === 'Destaques'){
                      setHighSecondTitle('')
                      localStorage.setItem('high_imobiles_second_title', '')
                    }
                  }, 300);
                }
              }}>{highSecondTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
              <i className="ri-text" onClick={() => {
                if(!highSecondTitleEnabled){
                  setHighSecondTitleEnabled(true)
                  if(highSecondTitle === ''){
                    setHighSecondTitle('Destaques')
                    localStorage.setItem('high_imobiles_second_title', 'Destaques')
                  }
                }else{
                  setHighSecondTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('highTitleEdit').focus()
                    if(highSecondTitle === 'Destaques'){
                      setHighSecondTitle('')
                      localStorage.setItem('high_imobiles_second_title', '')
                    }
                  }, 300);
                }
              }}></i>
            </label>
          </div>
      </div>

      <div className={styles.container_search}>
        <p className={styles.filters}>
          Filtrar
          <Icon path={mdiFilterOutline} size={.9} />
        </p>
        <div className={styles.container_filter}>
          <div>
            <div className={styles.category}>
              <p>Categoria</p>
              <div>
                <div className={styles.contariner_first}>
                  <div className={`${styles.category_content} ${selectedCategories.includes('apartamento') ? styles.selected : ''}`} 
                  onClick={() => selectCategory('apartamento')}>
                    <img img src={Apartament} alt="" className={styles.icon}/>
                    <p>Apartamento</p>
                  </div>
                  <div className={`${styles.category_content} ${selectedCategories.includes('casa') ? styles.selected : ''}`}
                  onClick={() => selectCategory('casa')}>
                    <img img src={House} alt="" className={styles.icon} />
                    <p>Casa</p>
                  </div>
                </div>

                <div className={styles.contariner_first}>
                  <div className={`${styles.category_content} ${selectedCategories.includes('comercial') ? styles.selected : ''}`} 
                  onClick={() => selectCategory('comercial')}>
                    <img img src={Shop} alt="" className={styles.icon} />
                    <p>Comercial</p>
                  </div>
                  <div className={`${styles.category_content} ${selectedCategories.includes('outros') ? styles.selected : ''}`} 
                  onClick={() => selectCategory('outros')}>
                    <img img src={Terrain} alt="" className={styles.icon}/>
                    <p>Outros</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.vacancies}>
              <p>Vagas</p>
              <div className={styles.numbers}>
                <span className={selectedVacancies.includes('one') ? styles.selected : ''} onClick={() => selectVacancy('one')}>1</span>
                <span className={selectedVacancies.includes('two') ? styles.selected : ''} onClick={() => selectVacancy('two')}>2</span>
                <span className={selectedVacancies.includes('three') ? styles.selected : ''} onClick={() => selectVacancy('three')}>3</span>
                <span className={selectedVacancies.includes('four') ? styles.selected : ''} onClick={() => selectVacancy('four')}>4</span>
                <span className={selectedVacancies.includes('five') ? styles.selected : ''} onClick={() => selectVacancy('five')}>5+</span>
              </div>
            </div>

            <div className={styles.bedrooms}>
              <p>Quartos</p>
              <div className={styles.numbers}>
                <span className={selectedBedrooms.includes('one') ? styles.selected : ''} onClick={() => selectBedrooms('one')}>1</span>
                <span className={selectedBedrooms.includes('two') ? styles.selected : ''} onClick={() => selectBedrooms('two')}>2</span>
                <span className={selectedBedrooms.includes('three') ? styles.selected : ''} onClick={() => selectBedrooms('three')}>3</span>
                <span className={selectedBedrooms.includes('four') ? styles.selected : ''} onClick={() => selectBedrooms('four')}>4</span>
                <span className={selectedBedrooms.includes('five') ? styles.selected : ''} onClick={() => selectBedrooms('five')}>5+</span>
              </div>
            </div>

            <div className={styles.value}>
              <p>Valor máximo</p>
              <CurrencyInput defaultValue={valorProposta} onChange={(e) => setValorProposta(e.target.value)} />
            </div>

          </div>
            <div className={styles.container_buton}>
              <button className={styles.button_filter}>Filtrar</button>
            </div>
        </div>
      </div>
    </>
  )
}

export default Filter