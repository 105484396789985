import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import { Breadcrumbs, CircularProgress } from '@mui/material';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import './NewProperties.css'
import { saveAs } from 'file-saver';

function NewProperties(){

    const [items, setItems] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [formated, setFromated] = useState([]);

    useEffect(() => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/last-six-properties', 
        {headers:{"Authorization": "Bearer " + token}})
        .then(res => setItems(res.data))
    }, [token]);

    const breadcrumbItems = [
         {title: "Fast Sale", link: "/dashboard"} ,
         {title: "Parcerias", link: "#"} 
    ];

    const options = {
        // pageStartIndex: 0,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        sizePerPageList:
          [{
            text: '5', value: 5
          }, {
            text: '10', value: 10
          }, {
            text: 'Todos', value: items.length
          }]
      };

    const data = {
        columns: [
          {
            dataField: 'sku',
            text: 'Código'
          },
          {
            dataField: "title",
            text: "Imóvel"
          },
          {
            dataField: "url",
            text: "Foto de Capa"
          },
          {
            dataField: "url_prop",
            text: "url"
          },
        ],
    };

    function coppyUrl(url){
        navigator.clipboard.writeText(url);
    }

    useEffect(() => {
        items.map((item) => {
            setFromated(formated => [...formated,{
                sku: item.sku,
                title: item.title,
                url: <div className='img-six-properties'><img src={item.one_photo.url} /></div>,
                url_prop: <button onClick={() => coppyUrl('https://fastsaleimoveis.com.br/imovel/' + item.slug)}>Copiar</button>,
            }])
        })
    }, [items])

    function download(){
        window.scrollTo(0,0)
        document.querySelector('.modal-download').classList.toggle('open-modal');
        Axios.get(process.env.REACT_APP_API + '/api/auth/download-one-photos', 
        {   
            headers:{
                "Authorization": "Bearer " + token,
                'Content-Type': 'application/json'
            },
            responseType: 'arraybuffer',
        })
        .then((response) => {
            const blob = new Blob([response.data], {
              type: 'application/octet-stream'
            })
            const filename = 'imagens.zip';
            saveAs(blob, filename);
            document.querySelector('.modal-download').classList.toggle('open-modal');
        }).catch(res => document.querySelector('.modal-download').classList.toggle('open-modal'));
    }


    return(
        <div className="page-content" id="partnershipPage">
            <Container fluid={true}>
            <Breadcrumbs title="Parcerias" breadcrumbItems={breadcrumbItems} />
            <Row>
                <Col>
                <Card>
                    <CardBody>
                        <div className='align-btn'>
                            <Button onClick={download}>Baixar fotos</Button>
                        </div>
                        <div className="table-responsive" id='table-six-properties'>
                            <BootstrapTable
                                keyField='id'
                                data={formated}
                                columns={data.columns}
                                // expandRow={expandRow}
                                
                                // selectRow={selectRow}
                            />
                        </div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            </Container>
            <div className="modal-download">
                <div className="modal-download-container">
                    <CircularProgress />
                </div>
            </div>
        </div>
    )
}

export default NewProperties;