import styles from '../styles/Footer.module.css';
import Logo from '../assets/logo_fast_nova_cor.png';
import LogoFast from '../assets/Fast Sale Horizontal.png';
import Icon from '@mdi/react';
import { mdiInstagram, mdiFacebook, mdiYoutube } from '@mdi/js';
import { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useEffect } from 'react';


const Footer = ({data}) => {

  const [logoFooter, setLogoFooter] = useState()
  const [colorText, setColorText] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [showPickerText, setShowPickerText] = useState(false);
  const [colorBg, setColorBg] = useState({r: 0, g: 0, b: 0, a: 1});
  const [showPickerBg, setShowPickerBg] = useState(false);
  const [modalCenter, setModalCenter] = useState(false)
  const [selectedSocial, setSelectedSocial] = useState([])
  const [instagram, setInstagram] = useState('')
  const [facebook, setFacebook] = useState('')
  const [youtube, setYoutube] = useState('')

  function handleFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // converte o arquivo em uma string Base64
      const fileAsBase64String = reader.result.split(',')[1];

      // armazena a string Base64 no estado
      localStorage.setItem('footer_logo', fileAsBase64String);
    });
    reader.readAsDataURL(file);
  }

  function isBase64(str) {
    try {
      atob(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const footerLogo = localStorage.getItem('footer_logo');
    if (footerLogo && footerLogo.slice(0, 4) !== 'http') {
      if(localStorage.getItem('footer_logo')){
        const fileAsBase64String = localStorage.getItem('footer_logo');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
      
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
      
          const blob = new Blob([array], { type: 'application/octet-stream' });
      
          const file = new File([blob], 'nome-do-arquivo.extensão');
      
          setLogoFooter(file) ;
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      } 
    } else {
      setLogoFooter(footerLogo)
    }
    if(localStorage.getItem('footer_color_text')){
      setColorText(JSON.parse(localStorage.getItem('footer_color_text')))
    }
    if(localStorage.getItem('footer_color_bg')){
      setColorBg(JSON.parse(localStorage.getItem('footer_color_bg')))
    }
    if(localStorage.getItem('selected_social')){
      setSelectedSocial(JSON.parse(localStorage.getItem('selected_social')))
    }
    if(localStorage.getItem('instagram')){
      setInstagram(localStorage.getItem('instagram'))
    }
    if(localStorage.getItem('facebook')){
      setFacebook(localStorage.getItem('facebook'))
    }
    if(localStorage.getItem('youtube')){
      setYoutube(localStorage.getItem('youtube'))
    }
  }, [])

  useEffect(() => {
    if(selectedSocial.length > 0 && modalCenter){
      setTimeout(() => {
        if(selectedSocial.includes('instagram')){
          document.getElementById('instagram').classList.add('active')
        }else{
          document.getElementById('instagram').classList.remove('active')
        }
        if(selectedSocial.includes('youtube')){
          document.getElementById('youtube').classList.add('active')
        }else{
          document.getElementById('youtube').classList.remove('active')
        }
        if(selectedSocial.includes('facebook')){
          document.getElementById('facebook').classList.add('active')
        }else{
          document.getElementById('facebook').classList.remove('active')
        }
      }, 500);
    }
  }, [selectedSocial, modalCenter]);

  return(
    <footer>
      <div className={styles.bg} style={{
          backgroundColor: `rgba(${colorBg?.r}, ${colorBg?.g}, ${colorBg?.b}, ${colorBg?.a})`
        }}>

        <div className={styles.actionIcons}>
          <label className="title">
            <i className="ri-font-color" onClick={() => {
                setShowPickerText(!showPickerText)
                }}></i>
                {showPickerText && (
                <div className={styles.colorPickerText}>
                  <i className="ri-close-fill closeIcon" onClick={() => {
                    setShowPickerText(!showPickerText)
                    setShowPickerBg(false)
                    }}></i>
                  <ChromePicker
                    color={colorText ?? { r: 0, g: 0, b: 0, a: 1 }}
                    onChange={(e) => {
                      setColorText(e.rgb)
                      localStorage.setItem('footer_color_text', JSON.stringify(e.rgb))
                    }}
                  />
                  </div>
                )}
                <i className="ri-brush-fill" onClick={() => {
                  setShowPickerBg(!showPickerBg)
                  setShowPickerText(false)
                  }}></i>
                  {showPickerBg && (
                  <div className={styles.colorPickerBg}>
                    <i className="ri-close-fill closeIcon" onClick={() => setShowPickerBg(!showPickerBg)}></i>
                    <ChromePicker
                      color={colorBg ?? { r: 255, g: 255, b: 255, a: 1 }}
                      onChange={(e) => {
                        setColorBg(e.rgb)
                        localStorage.setItem('footer_color_bg', JSON.stringify(e.rgb))
                      }}
                    />
                    </div>
                  )}
              </label>
        </div>

        <div className={styles.container_info}>

          <div className={styles.container_logo}>
            {
              logoFooter ? (
                <img src={(logoFooter && logoFooter.slice(0, 4) !== "http") ? URL.createObjectURL(logoFooter) : logoFooter}
                alt=""
                className={styles.logo}
                />
              ) : (
                <div className={styles.semLogo}>Sua logo</div>
            )}
            <label for="logoFooter" className={styles.inputLogo}><i className="ri-camera-fill"></i></label>
            <input id="logoFooter" name="logoFooter" type="file" style={{display:'none'}} onChange={(e) => {setLogoFooter(e.target.files[0]); handleFileChange(e)}} />
          </div>

          <div className={styles.info}>
            <p style={{
                  color: `rgba(${colorText?.r}, ${colorText?.g}, ${colorText?.b}, ${colorText?.a})`
              }}>({data?.phone?.slice(0,2)}) {data?.phone?.slice(2,data?.phone?.length)}</p>
            <p className={styles.address} style={{
                  color: `rgba(${colorText?.r}, ${colorText?.g}, ${colorText?.b}, ${colorText?.a})`
              }}>{data?.street}</p>
            <p style={{
                  color: `rgba(${colorText?.r}, ${colorText?.g}, ${colorText?.b}, ${colorText?.a})`
              }}>nº {data?.number}, {data?.complement} - {data?.district}</p>
            <p style={{
                  color: `rgba(${colorText?.r}, ${colorText?.g}, ${colorText?.b}, ${colorText?.a})`
              }}>{data?.city}-{data?.state}</p>
            <div className={styles.nets}>
              <div className={styles.addSocialLinks} 
                onClick={() => {
                  setModalCenter(!modalCenter)
                }}>Inserir redes sociais
              </div>
              {
                instagram && 
                <Icon path={mdiInstagram} size={1}  
                  style={{
                    color: `rgba(${colorText?.r}, ${colorText?.g}, ${colorText?.b}, ${colorText?.a})`
                  }}
                />
              }
              {
                youtube && 
                <Icon path={mdiYoutube} size={1} 
                  style={{
                    color: `rgba(${colorText?.r}, ${colorText?.g}, ${colorText?.b}, ${colorText?.a})`
                  }}
                />
              }
              {
                facebook && 
                <Icon path={mdiFacebook} size={1} 
                  style={{
                    color: `rgba(${colorText?.r}, ${colorText?.g}, ${colorText?.b}, ${colorText?.a})`
                  }}
                />
              }
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalCenter}
        centered={true}
        toggle={() => {
          setModalCenter(!modalCenter);
        }}
      >
        <ModalHeader toggle={() => setModalCenter(!modalCenter)}>
            Link para redes sociais      
        </ModalHeader>
        <ModalBody>
          <Row style={{marginBottom:'15px'}}>
            <Col><h3 style={{fontSize:'16px'}}>Selecione as redes sociais que serão exibidas:</h3></Col>
          </Row>

          <Row>
            <Col className="col-auto">
              <div id="instagram" onClick={() => {
                if (selectedSocial.includes('instagram')) {
                  setSelectedSocial(selectedSocial.filter(item => item !== 'instagram'));
                  localStorage.setItem('selected_social', JSON.stringify(selectedSocial.filter(item => item !== 'instagram')))
                } else {
                  setSelectedSocial([...selectedSocial, 'instagram']);
                  localStorage.setItem('selected_social', JSON.stringify([...selectedSocial, 'instagram']))
                }
              }}><i className="ri-instagram-line"></i></div>
            </Col>
            <Col className="col-auto">
              <div id="youtube" onClick={() => {
                if (selectedSocial.includes('youtube')) {
                  setSelectedSocial(selectedSocial.filter(item => item !== 'youtube'));
                  localStorage.setItem('selected_social', JSON.stringify(selectedSocial.filter(item => item !== 'youtube')))
                } else {
                  setSelectedSocial([...selectedSocial, 'youtube']);
                  localStorage.setItem('selected_social', JSON.stringify([...selectedSocial, 'youtube']))
                }
              }}><i className="ri-youtube-line"></i></div>
            </Col>
            <Col className="col-auto">
              <div id="facebook" onClick={() => {
                if (selectedSocial.includes('facebook')) {
                  setSelectedSocial(selectedSocial.filter(item => item !== 'facebook'));
                  localStorage.setItem('selected_social', JSON.stringify(selectedSocial.filter(item => item !== 'facebook')))
                } else {
                  setSelectedSocial([...selectedSocial, 'facebook']);
                  localStorage.setItem('selected_social', JSON.stringify([...selectedSocial, 'facebook']))
                }
              }}><i className="ri-facebook-box-line"></i></div>
            </Col>
          </Row>
          
          <Row>
            {selectedSocial.includes('instagram') &&
              <Col  xs={12} style={{marginTop:'15px'}}>
                <Label style={{alignItems:'center', gap:'5px', display:'flex'}}><i className="ri-instagram-line"></i>Link do Instagram (completa)</Label>
                <Input placeholder='https://' value={instagram} onChange={(e) => {
                  setInstagram(e.target.value)
                  localStorage.setItem('instagram', e.target.value)
                  }}></Input>
              </Col>
            }
            {selectedSocial.includes('youtube') &&
              <Col  xs={12} style={{marginTop:'15px'}}>
                <Label style={{alignItems:'center', gap:'5px', display:'flex'}}><i className="ri-youtube-line"></i>Link do YouTube (completa)</Label>
                <Input placeholder='https://' value={youtube} onChange={(e) => {
                  setYoutube(e.target.value)
                  localStorage.setItem('youtube', e.target.value)
                  }}></Input>
              </Col>
            }
            {selectedSocial.includes('facebook') &&
              <Col  xs={12} style={{marginTop:'15px'}}>
                <Label style={{alignItems:'center', gap:'5px', display:'flex'}}><i className="ri-facebook-box-line"></i>Link do Facebook (completa)</Label>
                <Input placeholder='https://' value={facebook} onChange={(e) => {
                  setFacebook(e.target.value)
                  localStorage.setItem('facebook', e.target.value)
                  }}></Input>
              </Col>
            }
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setModalCenter(!modalCenter)}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </footer>
  )
}

export default Footer