import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withNamespaces } from "react-i18next";

// users
import userDefault from './../../../assets/images/users/default-user-image.png';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }


    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    render() {

        // let username = localStorage.getItem("user_name");
        // if (localStorage.getItem("authUser")) {
        //     const obj = JSON.parse(localStorage.getItem("authUser"));
        //     // const uNm = obj.user_name.split(" ")[0];
        //     // username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
        // }

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                        <img className="rounded-circle header-profile-user me-1" src={this.props.user.profile_photo ? this.props.user.profile_photo : userDefault}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = userDefault;
                        }} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ms-1 text-transform">{this.props.user.name?.split(' ')?.slice(0,1)}</span>
                        <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end" >
                        {(localStorage.getItem('access') !== 'A' && localStorage.getItem('access') !== 'P') &&
                            <DropdownItem href="./../editar-perfil"><i className="ri-user-line align-middle me-1"></i> {this.props.t('Perfil')}</DropdownItem>
                        }
                        {/* <DropdownItem href="#"><i className="ri-wallet-2-line align-middle me-1"></i> {this.props.t('My Wallet')}</DropdownItem>
                        <DropdownItem className="d-block" href="#"><span className="badge badge-success float-end mt-1">11</span><i className="ri-settings-2-line align-middle me-1"></i> {this.props.t('Settings')}</DropdownItem> */}
                        {this.props.term && <DropdownItem href="/contrato-plano" target="_blank"><i className="ri-bill-line align-middle me-1"></i> Contrato plano</DropdownItem>}
                        <DropdownItem href="./politicas"><i className="ri-bill-line align-middle me-1"></i> {this.props.t('Políticas de uso')}</DropdownItem>
                        
                        <DropdownItem divider />
                        <DropdownItem className="text-danger" href="/logout"><i className="ri-shut-down-line align-middle me-1 text-danger"></i> {this.props.t('Sair')}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(ProfileMenu);
