import styles from '../styles/AboutSecondMobile.module.css';
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useEffect } from 'react';
import ContentEditable from "react-contenteditable";

const AboutSecondMobile = ({data}) => {
  const [aboutMoreTog, setAboutMoreTog ] = useState(false)
  const userImage = localStorage.getItem('user_image');
  const [ highSecondTitle, setHighTitle] = useState('Sobre nós')
  const [ highSecondTitleEnabled, setHighTitleEnabled] = useState(true)
  const [bg, setBg] = useState()
  const [modalLarge, setModalLarge] = useState(false)
  
  function handleBannerFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const fileAsBase64String = reader.result.split(',')[1];
      localStorage.setItem('image_about', fileAsBase64String);
    });
    reader.readAsDataURL(file);
  }

  function isBase64(str) {
    try {
      atob(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const imageAbout = localStorage.getItem('image_about');
    if (imageAbout && imageAbout.slice(0, 4) !== 'http') {
      if(localStorage.getItem('image_about')){
        const fileAsBase64String = localStorage.getItem('image_about');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
      
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
      
          const blob = new Blob([array], { type: 'application/octet-stream' });
      
          const file = new File([blob], 'nome-do-arquivo.extensão');
      
          setBg(file) ;
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      }
    } else {
      setBg(imageAbout);
    }

    if(localStorage.getItem('about_title')){
      setHighTitle(localStorage.getItem('about_title'))
    }

  }, []);

  return(
    <section className={styles.bg}
      style={localStorage.getItem('image_about') && localStorage.getItem('image_about').slice(0,4) !== 'http' ? {backgroundImage: 'url(' + (bg ? URL.createObjectURL(bg) : '') + ')', position:'relative'} : {backgroundImage: `url(${bg})`} }
    >
      <div className={styles.actionIconsFirst}>
        <label className="bg" onClick={() => setModalLarge(!modalLarge)}><span>Inserir imagem</span><i className="ri-image-add-fill"></i></label>
      </div>
      <div className={styles.container}>
        <div className={styles.about}> 
          <img src={userImage} alt="" />
          <div className={styles.titleContainer}>
            <ContentEditable
              html={highSecondTitle}
              className={styles.titleEdit}
              id='aboutTitleEdit'
              disabled={highSecondTitleEnabled}
              onChange={(e) => {
                setHighTitle(e.target.value)
                localStorage.setItem('about_title', e.target.value)
              }}
              onClick={(e) => {
                if(highSecondTitle === 'Sobre nós' && !highSecondTitleEnabled){
                  setHighTitle('')
                  localStorage.setItem('about_title', '')
                }
              }}
            />
            <div className={styles.actionIcons}>
              <label className="title">
                <span onClick={() => {
                  if(!highSecondTitleEnabled){
                    setHighTitleEnabled(true)
                    if(highSecondTitle === ''){
                      setHighTitle('Sobre nós')
                      localStorage.setItem('about_title', 'Sobre nós')
                    }
                  }else{
                    setHighTitleEnabled(false)
                    setTimeout(() => {
                      document.getElementById('aboutTitleEdit').focus()
                      if(highSecondTitle === 'Sobre nós'){
                        setHighTitle('')
                        localStorage.setItem('about_title', '')
                      }
                    }, 300);
                  }
                }}>{highSecondTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
                <i className="ri-text" onClick={() => {
                  if(!highSecondTitleEnabled){
                    setHighTitleEnabled(true)
                    if(highSecondTitle === ''){
                      setHighTitle('Sobre nós')
                      localStorage.setItem('about_title', 'Sobre nós')
                    }
                  }else{
                    setHighTitleEnabled(false)
                    setTimeout(() => {
                      document.getElementById('aboutTitleEdit').focus()
                      if(highSecondTitle === 'Sobre nós'){
                        setHighTitle('')
                        localStorage.setItem('about_title', '')
                      }
                    }, 300);
                  }
                }}></i>
              </label>
            </div>
          </div>
        </div>    
          {!aboutMoreTog ?
            <p className={styles.text}><pre-line>{data?.about}</pre-line></p>
            :
            <p className={styles.textExpand}><pre-line>{data?.about}</pre-line></p>
          }
          <div className={styles.aboutMore} onClick={() => {
            setAboutMoreTog(!aboutMoreTog)
          }}>{!aboutMoreTog ? 'Ver mais' : 'Ver menos'}</div>
      </div>

      <Modal
        size="lg"
        isOpen={modalLarge}
        toggle={() => {
          setModalLarge(!modalLarge);
        }}
      >
        <ModalHeader toggle={() => setModalLarge(!modalLarge)}>
            Escolha uma imagem para o banner        
        </ModalHeader>
        <ModalBody>
          <input id="bg" name="bg" type="file" onChange={(e) => {setBg(e.target.files[0]); handleBannerFileChange(e)}} />
        </ModalBody>
      </Modal>
    </section>
  )
}

export default AboutSecondMobile