import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Container, CardHeader, Button } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb"
import Whats from '../../components/Whats/Whats';
import Footer from '../Home/components/Footer';
import HeaderMobile from '../Home/components/Header';

import './../../assets/scss/custom/components/_policy.scss'
import Axios from 'axios';

class ContractPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term:null
    };
    this.handleDownload = this.handleDownload.bind(this);
  }
  

  componentDidMount(){
    Axios.get(process.env.REACT_APP_API + '/api/auth/buy-plan-term?user_id=' + localStorage.getItem('id') + '&user_type=' + localStorage.getItem('access'),
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response =>{
          this.setState({term: response.data.data})
      })
  }

  handleDownload(){
    const { term } = this.state;

    if (term) {
      Axios.get(process.env.REACT_APP_API + '/api/auth/buy-plan-term/' + term.id + '/pdf', {
          headers: {"Authorization": "Bearer " + localStorage.getItem('token')},
          responseType: 'blob'
      })
      .then((pdfResponse) => {
          const fileURL = window.URL.createObjectURL(new Blob([pdfResponse.data]));
          
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', 'contrato_' + term.id + '.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      })
    }
  }

  render() {
    return (
      <>
        <div className="page-content" id="policy" style={{padding:'120px 0 40px'}}>
        <Container fluid={false}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h3>CONTRATO DE LICENÇA DE USO DE SOFTWARE SAAS</h3>
                      {this.state.term?.product_id === 7 && <h5>PREMIUM CORRETORES</h5>}
                      {this.state.term?.product_id === 8 && <h5>START CORRETORES</h5>}
                      {this.state.term?.product_id === 9 && <h5>PREMIUM IMOBILIÁRIAS</h5>}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div dangerouslySetInnerHTML={{__html: this.state.term?.contract_html}}></div>
                    </Col>
                  </Row>
                  <Row style={{marginTop:'40px'}}>
                    <Col>
                      <Button onClick={this.handleDownload}>Fazer download</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      </>
    );
  }
}


export default ContractPlan;