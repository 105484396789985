import { Input, Label } from 'reactstrap';
import './styles.scss';
import { useState } from 'react';

export const CvcInput = ({handleInputChange, handleInputFocus}) => {
  const [inputValue, setInputValue] = useState('')

  const handleChange = (event) => {
    setInputValue(event.target.value)
    handleInputChange(event)
  };

  return(
    <fieldset>
      <Label>CVC</Label>
      <Input
        type="text"
        name="cvc"
        inputmode="numeric"
        placeholder="CVC"
        value={inputValue}
        onChange={handleChange}
        onFocus={handleInputFocus}
        maxLength={3}
      />
    </fieldset>
  )
}