import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Button } from "reactstrap";
import Axios from 'axios';

class MiniWidgets extends Component {

    state = {
        greeting:'',
        totalBrokers:null,
        p_count:[],
        imoveis:[],
        realEstateTeam:[],
        teamActive:false
    }

    componentDidMount(){
        const date = new Date()

        if(date.getHours() < 13){
            this.setState({greeting: 'Bom dia, '})
        }
        if(date.getHours() > 12 && date.getHours() < 19){
          this.setState({greeting: 'Boa tarde, '})
        }
        if(date.getHours() > 18){
            this.setState({greeting: 'Boa noite, '})
        }

        Axios.get(process.env.REACT_APP_API + '/api/public/get-count')
        .then((response) => {
          this.setState({totalBrokers : parseInt(response.data.brokers) + parseInt(response.data.real_estates)})
        })

        Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-team/by-broker/' + localStorage.getItem('id'),
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          this.setState({realEstateTeam: response.data.realEstateTeam})
          this.setState({teamActive: true})
        })

        Axios.post(process.env.REACT_APP_API + '/api/auth/get-user-props-pcount', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id')
          }, 
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            for(var i = 0; i < resp.data.p_count; i++){
              this.state.p_count.push(i)
            }
            for(var i = 0; i < resp.data.imoveis; i++){
              this.state.imoveis.push(i)
            }
          })
    }

    render() {
        return (
            <React.Fragment>
                <Col md={4} style={{ overflow: 'hidden' }}>
                    <Card style={{ maxHeight: "150px", minHeight: "150px", overflow: 'hidden' }}>
                        <CardBody>
                            <Row style={{alignItems:'center', maxHeight: "110px", minHeight: "110px"}}>
                                <Col className="col-auto">
                                    <img src={localStorage.getItem('user_image')} style={{objectFit:'cover', width:'75px', height:'75px', borderRadius:'50%'}}></img>
                                </Col>
                                <Col>
                                    <p>{this.state.greeting}{localStorage.getItem('user_name').split(' ')?.slice(0,1)}!</p>
                                    <p style={{fontSize:'16px', maxWidth:'400px'}}>Você pode receber até <span style={{fontSize:'16px', fontWeight:'600'}}>{(parseInt(localStorage.getItem('user_comission') / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))}</span> por suas captações ativas</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={8} style={{ overflow: 'hidden' }}>
                    <Row>
                        {this.props.reports.map((report, key) =>
                        <Col key={key} md={4}>
                            <Card style={{ maxHeight: "150px", minHeight: "150px", overflow: 'hidden' }}>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">{report.title}</p>
                                            <h4 className="mb-0 font-size-16 ">{report.value}</h4>
                                        </div>
                                        <div className="text-primary">
                                            <i className={report.icon + " font-size-24"}></i>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1">+ {report.rate}</span>
                                        <span className="text-muted ms-2">{report.desc}</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        )}
                        {this.state.teamActive && this.state.realEstateTeam.approved === 1 ?
                            <Col md={4}>
                                <Card style={{ maxHeight: "150px", minHeight: "150px", overflow: 'hidden' }}>
                                    <CardBody style={{backgroundColor:"#333", textAlign:'center', display:'flex', flexDirection:'column', justifyContent:'center', gap:'15px'}}>
                                        <h3 style={{color:'#fff', fontSize:'18px'}}>Você é membro do time da imobiliária {this.state?.realEstateTeam?.real_estate?.name}</h3>
                                        <Button onClick={() => {
                                            Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-team-delete/' +  this.state?.realEstateTeam.id, {},
                                            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                            .then(response => {
                                            setTimeout(() => {
                                                window.location.reload()
                                            }, 300);
                                            })
                                        }}>Sair do time</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        :
                        <Col md={4}>
                            {this.props.premium ?
                                <Card style={{ maxHeight: "150px", minHeight: "150px", overflow: 'hidden' }}>
                                    <CardBody style={{backgroundColor:"#333", textAlign:'center', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                        {(this.state.p_count.length - this.state.imoveis.length) < 1 ?
                                        <>
                                            <h3 style={{fontSize:'16px', color:'#fff', fontWeight:'300'}}>Você atingiu o limite de imóveis, faça um upgrade para colocar mais imóveis.</h3>
                                            <Button style={{marginTop:'20px'}} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('https://wa.me/554741081865', '_blank')
                                                }, 300);
                                            }}>Quero colocar mais imóveis</Button>
                                        </>
                                        :
                                        <>
                                            <h3 style={{fontSize:'16px', color:'#fff', fontWeight:'300'}}>Você ainda pode adicionar {(this.state.p_count.length - this.state.imoveis.length)} imóveis</h3>
                                            <Button style={{marginTop:'20px'}} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./novo-imovel', '_parent')
                                                }, 300);
                                            }}>Adicionar novo Imóvel</Button>
                                        </>
                                        }
                                    </CardBody>
                                </Card>
                            :
                                <Card style={{ maxHeight: "150px", minHeight: "150px", overflow: 'hidden' }}>
                                    <CardBody style={{backgroundColor:"#333", textAlign:'center', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                        <h3 style={{fontSize:'16px', color:'#fff', fontWeight:'300'}}>Seja Premium e venda seus imóveis em parceria com {this.state.totalBrokers} corretores</h3>
                                        <Button style={{marginTop:'20px'}} onClick={() => {
                                            setTimeout(() => {
                                                window.open('./home', '_parent')
                                            }, 300);
                                        }}>Benefícios de ser Premium</Button>
                                    </CardBody>
                                </Card>
                            }

                        </Col>
                        }

                    </Row>
                </Col>
            </React.Fragment>
        );
    }
}

export default MiniWidgets;