import { Input, Label } from 'reactstrap';
import './styles.scss';
import { useEffect, useState } from 'react';

export const DateInput = ({handleInputChange, handleInputFocus}) => {
  const [inputValue, setInputValue] = useState('')

  const formatDate = (inputValue) => {
    const cleanValue = inputValue.replace(/\D/g, '');

    let formattedValue = '';
    for (let i = 0; i < cleanValue.length; i++) {
      if (i === 2) {
        formattedValue += '/';
      }
      formattedValue += cleanValue.charAt(i);
    }

    return formattedValue.trim().slice(0, 5);
  };

  const handleChange = (event) => {
    const formattedValue = formatDate(event.target.value)
    setInputValue(formattedValue)
    handleInputChange(event)
  };

  return(
    <fieldset>
      <Label>Val.</Label>
      <Input
        type="text"
        name="expiry"
        inputmode="numeric"
        placeholder="Validade"
        value={inputValue}
        onChange={handleChange}
        onFocus={handleInputFocus}
      />
    </fieldset>
  )
}