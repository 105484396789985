const reasons = [
  {
    title: 'Mais produtos na carteira',
    description: 'Tenha sempre à mão o produto que seu cliente procura.'
  },
  {
    title: 'Venda em rede',
    description: 'Milhares de corretores anunciando seus imóveis em sites e portais.'
  },
  {
    title: 'Receba novas Leads',
    description: 'Vendendo em parceria seus imóveis alcançam milhares de clientes qualificados todos os dias.'
  }
];

export default reasons;