import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, CardImg, Row, CardTitle, CardText, Badge } from "reactstrap";

import './../../assets/scss/custom/components/_horizontalCard.scss'
import './../../assets/scss/custom/components/_imovelCard.scss';

import Outlet from './../../assets/images/Icons/fogooutlet.png';

class HorizontalCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }


    render() {
        return (
            <React.Fragment>
              <Link to={"../imovel/" + this.props.imovel?.slug} target="_parent">
                <div className="horizontalCard" onClick={() => {
                  setTimeout(() => {
                    window.open("../imovel/" + this.props.imovel?.slug, '_parent')
                  }, 300);
                }}>
                    <Row className="no-gutters align-items-center">
                        <Col xs={4} style={{position:'relative'}}>
                          {this.props.imovel?.one_photo ?
                            <CardImg className="img-fluid img-horizontal-card" src={this.props.imovel?.one_photo?.medium_image} alt="Skote"/>
                            :
                            <CardImg className="img-fluid img-horizontal-card" src={this.props.imovel?.photos?.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1)[0]?.medium_image} alt="Skote" />
                          }
                          {this.props.imovel?.opportunity === 1 &&
                            <img className="outletIcon" src={Outlet}></img>
                          }
                        </Col>
                        <Col xs={8}>
                            <CardBody style={{padding:'0', justifyContent:'space-between', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                                <Badge className="bg-light me-1 rounded-pill" style={{color:'#333', marginBottom:'5px'}}>{this.props.imovel?.sku}</Badge>
                                <CardTitle>{this.props.imovel?.title}</CardTitle>
                                <Row style={{width:'100%'}}>
                                    <Col>
                                    {this.props.imovel?.sell_price && this.props.imovel?.sell_price !== 0 ?
                                      <div className="offer-container">
                                        <div className="old-price"><span>R${(this.props.imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                                        <div className="offer-price"><small>R$</small><span>{(this.props.imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                                      </div>
                                    :
                                      <div className="offer-container">
                                        <div className="offer-price"><small>R$</small><span>{(this.props.imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                                      </div>
                                    }
                                    </Col>
                                    <Col style={{display:'flex', alignItems:'flex-end', justifyContent:'flex-end', paddingRight: '0'}}>
                                        <div className="viewProperty"><i className="ri-eye-line"></i><span>Ver imóvel</span></div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Col>
                    </Row>
                </div>
              </Link>
            </React.Fragment>
        );
    }
}

export default HorizontalCard;