import React, { Component } from "react";
import { Row, Col, Button, Alert, Container, Label, Input, Card, Spinner } from "reactstrap";
import PasswordChecklist from "react-password-checklist";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { registerUser, registerUserFailed, apiError } from '../../store/actions';

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from 'axios';

// import images
import logodark from "../../assets/images/logo-dark.png";
import ReactSelect from 'react-select';
import Select from "react-select";
import Dropzone from 'react-dropzone';

import './../../assets/scss/custom/components/_register.scss';
import SweetAlert from 'react-bootstrap-sweetalert';

class RegisterThankYou extends Component {
    constructor(props) {
        super(props);
        this.validateRef = React.createRef();
        this.validateRef2 = React.createRef();
        this.state = {
            usuarioTipo: null,
            selectedCreci:[],
            selectedRg:[],
            email: null,
            telefone: null,
            nome: null,
            rg: null,
            cpf: null,
            cep: null,
            logradouro: null,
            numero: null,
            bairro: null,
            complemento: null,
            cidade:[],
            estado:[],
            pais:'Brasil',
            cidadeLista: [],
            estadoLista: [],
            senha:'',
            confirmacaoSenha:'',
            truePassword:false,
            razaoSocial:null,
            cnpj:null,
            creciJ:null,
            creci:null,
            creciPhoto:null,
            rgPhoto:null,
            steps:0,
            register_error:false,
            register_success:false,
        }
    }

    componentDidMount(){
        Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(response => {
          const states = []
          for(var i = 0; response.data.length > i; i++){
            states.push({
              value: response.data[i].sigla,
              label: response.data[i].nome
            })
          }
          this.setState({estadoLista: states})
        })
      }
    

    handleSelectState = estado => {
		this.setState({ estado });
    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + estado.value + '/municipios')
    .then(response => {
      const city = []
      for(var i = 0; response.data.length > i; i++){
        city.push({
          label: response.data[i].nome,
          value: response.data[i].nome
        })
      }
      this.setState({cidadeLista: city})
    })
	};


  consultaCep = cepValue => {
    const cep = cepValue
     if (cep?.length !== 8) {
      this.setState({logradouro: ''});
      this.setState({bairro: ''});
      this.setState({estado: ''})
      this.setState({cidade: ''});
     }else{
       fetch('https://viacep.com.br/ws/' + cep + '/json/')
        .then((res) => res.json())
        .then((data) => {
            if (data.erro === true) {
              this.setState({logradouro: ''});
              this.setState({bairro: ''});
              this.setState({estado: ''})
              this.setState({cidade: ''});
            } else {
              this.setState({logradouro: data.logradouro});
              this.setState({bairro: data.bairro});
              this.setState({estado: {
                value:data.uf,
                label:data.uf
              }})
              this.setState({cidade: {
                value:data.localidade,
                label:data.localidade
              }});
            }
          })
     }  
  }

    handleAcceptedDocs = files => {
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: this.formatBytes(file.size)
          })
        );
    
        this.setState({ selectedCreci: files });
      };

      handleAcceptedDocsRg = files => {
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: this.formatBytes(file.size)
          })
        );
    
        this.setState({ selectedRg: files });
      };

      formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      };


    render() {
        const {estadoLista, cidadeLista, cidade, estado} = this.state;
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="#" className="logo"><img src={logodark} height="20" alt="logo" /></Link>
                                                        </div>
                                                        <h4 className="font-size-18 mt-4">Cadastro realizado!</h4>
                                                        <p className="text-muted">Tudo certo com seu cadastro.</p>
                                                            <Container>
                                                                <Row style={{margin:'20px -10px'}}>
                                                                    <Col xs={12}>
                                                                        <p>Seu cadastro está em análise, em até 24 horas você receberá um aviso em seu e-mail.</p>
                                                                    </Col>
                                                                    <Col xs={12} style={{marginTop:'20px'}}>
                                                                        <Button onClick={() => {
                                                                            setTimeout(() => {
                                                                                window.open("/home", '_parent')
                                                                            }, 300);
                                                                        }}>Voltar</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        <div className="mt-5 text-center">
                                                            <p>Fast Sale Imóveis</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>
        );
    }
}


export default RegisterThankYou;
