import styles from '../../styles/desktop/ImobileThird.module.css';
import Teste from '../../assets/foto_fake.jpg';
import Icon from '@mdi/react';
import { mdiShareAll, mdiCarBack, mdiBedKingOutline, mdiPencilRuler, mdiPencilRulerOutline, mdiRulerSquare } from '@mdi/js';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from 'swiper';
import FooterThind from './FooterThird';
import { useState, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import ContentEditable from "react-contenteditable";
import Maps from '../../../../pages/SingleProperty/Maps';
import { useMediaQuery } from 'react-responsive';
import ImobileThirdMobile from '../mobile/ImobileThirdMobile';

const ImobileThird = () => {
  const [logo, setLogo] = useState();
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [showPickerButton, setShowPickerButton] = useState(false);
  const [showPickerTextButton, setShowPickerTextButton] = useState(false);
  const [ highTitle, setHighTitle] = useState('Destaques')
  const [ highTitleEnabled, setHighTitleEnabled] = useState(true)
  const [isMobile, setIsMobile] = useState(false);
  const isMobileQuery = useMediaQuery({ query: `(max-width: 767px)` });

  function handleFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // converte o arquivo em uma string Base64
      const fileAsBase64String = reader.result.split(',')[1];

      // armazena a string Base64 no estado
      localStorage.setItem('nav_logo', fileAsBase64String);

      localStorage.setItem('imobiles_banner', fileAsBase64String);
    });
    reader.readAsDataURL(file);
  }

  function isBase64(str) {
    try {
      atob(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const navLogo = localStorage.getItem('nav_logo');
    if (navLogo && navLogo.slice(0, 4) !== 'http') {
      if (localStorage.getItem('nav_logo')) {
        const fileAsBase64String = localStorage.getItem('nav_logo');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
  
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
  
          const blob = new Blob([array], { type: 'application/octet-stream' });
  
          const file = new File([blob], 'nome-do-arquivo.extensão');
  
          setLogo(file);
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      }
    } else {
      setLogo(navLogo);
    }
    
    if(localStorage.getItem('imobile_second_button_text_color')){
      setColorTextButton(JSON.parse(localStorage.getItem('imobile_second_button_text_color')))
    }
    if(localStorage.getItem('imobile_second_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('imobile_second_button_color')))
    }
    if(localStorage.getItem('high_imobile_third_title')){
      setHighTitle(localStorage.getItem('high_imobile_third_title'))
    }
    if(localStorage.getItem('high_third_button_text_color')){
      setColorTextButton(JSON.parse(localStorage.getItem('high_third_button_text_color')))
    }
    if(localStorage.getItem('high_third_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('high_third_button_color')))
    }

  }, []);

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery]);

  if (isMobile) {
    return <ImobileThirdMobile />;
  }

  return(
    <>
      <div className={styles.container_logo}>
        <div className={styles.bg_logo}>
          {logo ? (
            <img src={(logo && logo.slice(0, 4) !== "http") ? URL.createObjectURL(logo) : logo}
              alt=""
              className={styles.logo}
            />
          ) : (
            <div className={styles.semLogo}>Sua logo</div>
          )}
          <label for="logo" className={styles.inputLogo}><i className="ri-camera-fill"></i></label>
          <input id="logo" name="logo" type="file" style={{display:'none'}} onChange={(e) => {setLogo(e.target.files[0]); handleFileChange(e)}} />
        </div>
      </div>
      <section>
        <div className={styles.container_imgs}>
          <img src={Teste} alt="" />
          <img src={Teste} alt="" />
          <img src={Teste} alt="" />
        </div>

        <div className={styles.container_config_imobile_about}>
          <div className={styles.bg_config}>
            <div className={styles.container_house}>
              <p className={styles.name}>Casa de alto padrão no condomínio Caledônia em Camboriú</p>

              <div className={styles.config}>
                <span className={styles.icons_imobile}><Icon path={mdiBedKingOutline} size={.8} /> 3 suítes</span>
                <span className={styles.icons_imobile}><Icon path={mdiCarBack} size={.8} /> 2 vagas</span>
                <span className={styles.icons_imobile}><Icon path={mdiRulerSquare} size={.8} />127m</span>
              </div>

              <p className={styles.location_imobile}>Balneário Camboriú</p>

              <div className={styles.container_buttons_imobile}>
                <button style={{
                backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                }}>Quero mais informações</button>
              <div className={styles.actionIcons}>
                <label className="title">
                  <i className="ri-brush-fill" onClick={() => {
                    setShowPickerButton(!showPickerButton)
                    setShowPickerTextButton(false)
                    }}></i>
                  <i className="ri-font-color" onClick={() => {
                    setShowPickerTextButton(!showPickerTextButton)
                    setShowPickerButton(false)
                    }}></i>
                  {showPickerButton && (
                    <div className={styles.colorPickerButton}>
                      <i className="ri-close-fill closeIcon" onClick={() => setShowPickerButton(!showPickerButton)}></i>
                      <ChromePicker
                        color={colorButton ?? {r: 0, g: 0, b: 0, a: 1}}
                        onChange={(e) => {
                          setColorButton(e.rgb)
                          localStorage.setItem('imobile_second_button_color', JSON.stringify(e.rgb))
                        }}
                      />
                      </div>
                    )}
                    {showPickerTextButton && (
                    <div className={styles.colorPickerButton}>
                      <i className="ri-close-fill closeIcon" onClick={() => setShowPickerTextButton(!showPickerTextButton)}></i>
                      <ChromePicker
                        color={colorTextButton ?? {r: 0, g: 0, b: 0, a: 1}}
                        onChange={(e) => {
                          setColorTextButton(e.rgb)
                          localStorage.setItem('imobile_second_button_text_color', JSON.stringify(e.rgb))
                        }}
                      />
                      </div>
                    )}
                </label>
              </div>


                <button className={styles.share} style={{
                  backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                  color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                  }}>Compartilhe <Icon path={mdiShareAll} size={.8} />
                </button>
                <div className={styles.actionIcons}>
                  <label className="title">
                    <i className="ri-brush-fill" onClick={() => {
                      setShowPickerButton(!showPickerButton)
                      setShowPickerTextButton(false)
                      }}></i>
                    <i className="ri-font-color" onClick={() => {
                      setShowPickerTextButton(!showPickerTextButton)
                      setShowPickerButton(false)
                      }}></i>
                    {showPickerButton && (
                      <div className={styles.colorPickerButton}>
                        <i className="ri-close-fill closeIcon" onClick={() => setShowPickerButton(!showPickerButton)}></i>
                        <ChromePicker
                          color={colorButton ?? {r: 0, g: 0, b: 0, a: 1}}
                          onChange={(e) => {
                            setColorButton(e.rgb)
                            localStorage.setItem('imobile_second_button_color', JSON.stringify(e.rgb))
                          }}
                        />
                        </div>
                      )}
                      {showPickerTextButton && (
                      <div className={styles.colorPickerButton}>
                        <i className="ri-close-fill closeIcon" onClick={() => setShowPickerTextButton(!showPickerTextButton)}></i>
                        <ChromePicker
                          color={colorTextButton ?? {r: 0, g: 0, b: 0, a: 1}}
                          onChange={(e) => {
                            setColorTextButton(e.rgb)
                            localStorage.setItem('imobile_second_button_text_color', JSON.stringify(e.rgb))
                          }}
                        />
                        </div>
                      )}
                  </label>
                </div>
              </div>
            </div>

            <div className={styles.container_price}>
              <p className={styles.price_imobile}>R$<span>4.000.000,00</span></p>
              <p className={styles.others}>Condomínio: R$1.100,00</p>
              <p className={styles.others}>IPTU: R$2.000,00</p>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.container_config_imobile_about}>
            <div className={styles.bg_config}>
              <div className={styles.bg_text}>
                <p className={styles.about_title}>Sobre o Imóvel</p>
                <p className={styles.about_imobile}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio quasi dicta labore magnam, similique doloribus animi quos 
                  hic voluptatibus perspiciatis tenetur, eius sunt cumque ducimus reprehenderit est aliquid esse laborum?
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio quasi dicta labore magnam, similique doloribus animi quos 
                  hic voluptatibus perspiciatis tenetur, eius sunt cumque ducimus reprehenderit est aliquid esse laborum?
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio quasi dicta labore magnam, similique doloribus animi quos 
                  hic voluptatibus perspiciatis tenetur, eius sunt cumque ducimus reprehenderit est aliquid esse laborum?
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.container_maps}>
          <div className={styles.maps}>
            <Maps latI={-26.990299595751235} lngI={-48.638515207944316} zoomLevel={13}/>
          </div>
        </div>

        <div>
          {/* <h1 className={styles.more_imobiles}>Imóveis semelhantes a este</h1> */}
          <div className={styles.titleContainer}>
          <ContentEditable
              html={highTitle}
              className={styles.titleEdit}
              id='highTitleEdit'
              disabled={highTitleEnabled}
              onChange={(e) => {
                setHighTitle(e.target.value)
                localStorage.setItem('high_imobile_third_title', e.target.value)
              }}
              onClick={(e) => {
                if(highTitle === 'Destaques' && !highTitleEnabled){
                  setHighTitle('')
                  localStorage.setItem('high_imobile_third_title', '')
                }
              }}
            />
            <div className={styles.actionIcons}>
              <label className="title">
                <span onClick={() => {
                  if(!highTitleEnabled){
                    setHighTitleEnabled(true)
                    if(highTitle === ''){
                      setHighTitle('Destaques')
                      localStorage.setItem('high_imobile_third_title', 'Destaques')
                    }
                  }else{
                    setHighTitleEnabled(false)
                    setTimeout(() => {
                      document.getElementById('highTitleEdit').focus()
                      if(highTitle === 'Destaques'){
                        setHighTitle('')
                        localStorage.setItem('high_imobile_third_title', '')
                      }
                    }, 300);
                  }
                }}>{highTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
                <i className="ri-text" onClick={() => {
                  if(!highTitleEnabled){
                    setHighTitleEnabled(true)
                    if(highTitle === ''){
                      setHighTitle('Destaques')
                      localStorage.setItem('high_imobile_third_title', 'Destaques')
                    }
                  }else{
                    setHighTitleEnabled(false)
                    setTimeout(() => {
                      document.getElementById('highTitleEdit').focus()
                      if(highTitle === 'Destaques'){
                        setHighTitle('')
                        localStorage.setItem('high_imobile_third_title', '')
                      }
                    }, 300);
                  }
                }}></i>
              </label>
            </div>
        </div>
          
          <div className={styles.container_carousel}>
          <Swiper
            slidesPerView={3}
            spaceBetween={-40}
            loop={true}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className={styles.container_imobile}>
                <div className={styles.bg}></div>
                <div className={styles.container_config}>
                  <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                    <p className={styles.price}>R$<span>4.000.000,00</span></p>

                  <div className={styles.container_config_imobile}>
                    <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                    <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                    <span><Icon path={mdiRulerSquare} size={.8} color={'#000'}/>152m</span>
                  </div>
                  <div className={styles.details}></div>
                  <div className={styles.container_view}>
                    <p>Balneário Camboriú</p> 
                    <p className={styles.view}  style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}>Ver mais</p> 
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container_imobile}>
                <div className={styles.bg}></div>
                <div className={styles.container_config}>
                  <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                    <p className={styles.price}>R$<span>4.000.000,00</span></p>

                  <div className={styles.container_config_imobile}>
                    <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                    <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                    <span><Icon path={mdiRulerSquare} size={.8} color={'#000'}/>152m</span>
                  </div>
                  <div className={styles.details}></div>
                  <div className={styles.container_view}>
                    <p>Balneário Camboriú</p> 
                    <p className={styles.view}  style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}>Ver mais</p> 
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container_imobile}>
                <div className={styles.bg}></div>
                <div className={styles.container_config}>
                  <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                    <p className={styles.price}>R$<span>4.000.000,00</span></p>

                  <div className={styles.container_config_imobile}>
                    <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                    <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                    <span><Icon path={mdiRulerSquare} size={.8} color={'#000'}/>152m</span>
                  </div>
                  <div className={styles.details}></div>
                  <div className={styles.container_view}>
                    <p>Balneário Camboriú</p> 
                    <p className={styles.view}  style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}>Ver mais</p> 
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container_imobile}>
                <div className={styles.bg}></div>
                <div className={styles.container_config}>
                  <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                    <p className={styles.price}>R$<span>4.000.000,00</span></p>

                  <div className={styles.container_config_imobile}>
                    <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                    <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                    <span><Icon path={mdiRulerSquare} size={.8} color={'#000'}/>152m</span>
                  </div>
                  <div className={styles.details}></div>
                  <div className={styles.container_view}>
                    <p>Balneário Camboriú</p> 
                    <p className={styles.view}  style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}>Ver mais</p> 
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        </div>
      </section>
    </>
  )
}

export default ImobileThird;