import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from "../../components/Inputs/CurrencyInput";
import CreatableSelect from 'react-select/creatable';

const id = window.location.search.replace('?id=', '')

class TypeSkills extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      selectedSkill:[],
      selectedOption: [],
      tagsOptions:[],
      tags:[],
      skillsList:'',
      skillsArray:[],
      }
      this.updateEnterprise = this.updateEnterprise.bind(this);
    }


    componentDidMount(){
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types/' + id, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            const enterprise = response.data;
            const details = enterprise.details.map(item => item.detail);

            const skillsList = ["Acabamento em gesso","Acessibilidade para PNE","Alarme", "Aquecimento a gás","Armário embutido","Banheira hidromassagem","Banheiro auxiliar","Banheiro social","Banheiro de serviço","Churrasqueira","Circuito TV","Closet","Cozinha","Cozinha americana","Quarto de serviço","Despensa","Escritório","Espera para split","Fechadura com senha na porta de entrada","Gás individual","Hidromassagem","Hidrômetro individual","Home office","Infraestrutura para água quente","Interfone", "Internet","Lareira","Lavabo","Living","Mezanino","Móveis Planejados","Piso aquecido nos banheiros","Porcelanato","Sacada","Sala de estar","Sala de jantar","Varanda","Varanda gourmet","Vista panorâmica","Área de serviço"];

            const selectedSkill = details.filter(detail => skillsList.includes(detail));
            const tags = details.filter(detail => !skillsList.includes(detail)).map(tag => ({ name: tag, value: tag }));

            this.setState({
                enterprise,
                selectedSkill,
                tags,
                selectedOption: tags.map(tag => ({ label: tag.name, value: tag.value }))
            });
         })
        })
        .catch(response =>{
            setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
            })  
    }

      updateEnterprise(event) {
        if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
            this.setState({error_auth:true})
        }else{
        document.getElementById('load').classList.add('active')
                            
        const dataEnterpriseSkills = new FormData();

        const combinedDetails = [
            ...this.state.selectedSkill.map(skill => ({ detail: skill })),
            ...this.state.tags.map(tag => ({ detail: tag.name }))
        ];
 
        combinedDetails.forEach((item, index) => {
            dataEnterpriseSkills.append(`detail[${index}][detail]`, item.detail);
            dataEnterpriseSkills.append(`detail[${index}][property_enterprise_type_id]`, id);
        });

        if(this.state.selectedSkill.length > 0){
            Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-details', dataEnterpriseSkills, {
              headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
            }).then(response => {
                document.getElementById('load').classList.remove('active')
                
                setTimeout(() => {
                    window.open('./../empreendimento-tipo?id=' + id, '_parent')
                }, 100);
            })
          }
        }
   }

   handleInputChange = (e) => {
        const inputValue = e.target.value;

        const formattedValue = inputValue.replace(/[;.,]/g, '\n');

        this.setState({ skillsList: formattedValue });
    }

    handleCreate = async (inputValue) => {
        const { selectedOption } = this.state;
    
        try {
            const createResponse = await Axios.post(
                `${process.env.REACT_APP_API}/api/tag/`,
                { name: inputValue },
                { headers: { "Authorization": "Bearer " + localStorage.getItem('token') } }
            );
    
            await new Promise(resolve => setTimeout(resolve, 500));
    
            const fetchResponse = await Axios.post(
                `${process.env.REACT_APP_API}/api/tag/${inputValue}`,
                {},
                { headers: { "Authorization": "Bearer " + localStorage.getItem('token') } }
            );
    
            await new Promise(resolve => setTimeout(resolve, 500));
    
            const newTags = fetchResponse.data.filter(tag => tag?.name === inputValue).map(tag => ({
                name: tag.name,
                value: tag.id
            }));
    
            this.setState({
                selectedOption: [
                    ...selectedOption,
                    ...newTags.map(tag => ({ label: tag.name, value: tag.value }))
                ]
            });
    
            this.setState(state => ({
                tags: [...state.tags, ...newTags]
            }));
        } catch (error) {
            console.error(error);
        }
    };
    
    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
    
        const newTags = selectedOption
            .filter(option => !this.state.tags.some(tag => tag.value === option.value))
            .map(option => ({ name: option.label, value: option.value }));
    
            const removedTags = this.state.tags.filter(tag => !selectedOption.some(option => option.value === tag.value));

            this.setState(state => ({
                tags: state.tags.filter(tag => !removedTags.includes(tag)).concat(newTags)
            }));
    };

    loadOptions = async (inputValue, callback) => {
        try {
            const response = await Axios.post(
                `${process.env.REACT_APP_API}/api/tag/${inputValue}`,
                {},
                { headers: { "Authorization": "Bearer " + localStorage.getItem('token') } }
            );
    
            const options = response.data.map(item => ({
                value: item.id,
                label: item.name
            }));
    
            callback(options);
        } catch (error) {
            console.error(error);
        }
    };

    render(){
        const {enterprise } = this.state;

        const skillsList = ["Acabamento em gesso","Acessibilidade para PNE","Alarme", "Aquecimento a gás","Armário embutido","Banheira hidromassagem","Banheiro auxiliar","Banheiro social","Banheiro de serviço","Churrasqueira","Circuito TV","Closet","Cozinha","Cozinha americana","Quarto de serviço","Despensa","Escritório","Espera para split","Fechadura com senha na porta de entrada","Gás individual","Hidromassagem","Hidrômetro individual","Home office","Infraestrutura para água quente","Interfone", "Internet","Lareira","Lavabo","Living","Mezanino","Móveis Planejados","Piso aquecido nos banheiros","Porcelanato","Sacada","Sala de estar","Sala de jantar","Varanda","Varanda gourmet","Vista panorâmica","Área de serviço"];

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                            <Container style={{marginTop:'20px'}}>
                                                <Row style={{margin:'10px 0'}}>
                                                <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Características do tipo</h5></Col>
                                                </Row>
                                                <Row>
                                                <Col md={12}>
                                                    <div className="check-list-container">
                                                        {skillsList.map((skill, index) => (
                                                            <div key={index} className="input-check-container">
                                                                <input
                                                                    type="checkbox"
                                                                    name={skill}
                                                                    id={skill}
                                                                    checked={this.state.selectedSkill.includes(skill)}
                                                                    onChange={() => {
                                                                        let updatedSkills;
                                                                        if (this.state.selectedSkill.includes(skill)) {
                                                                            updatedSkills = this.state.selectedSkill.filter(selected => selected !== skill);
                                                                        } else {
                                                                            updatedSkills = [...this.state.selectedSkill, skill];
                                                                        }
                                                                        this.setState({ selectedSkill: updatedSkills });
                                                                    }}
                                                                />
                                                                <label htmlFor={skill}>{skill}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Col>
                                                </Row>
                                                <Row style={{margin:'10px 0'}}>
                                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Outras características</h5></Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{paddingBottom: '20px'}}>
                                                        <CreatableSelect
                                                            value={this.state.selectedOption}
                                                            onChange={this.handleChange}
                                                            options={this.state.tagsOptions}
                                                            formatCreateLabel={(inputValue) => `Criar nova tag: ${inputValue}`}
                                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                                            onInputChange={(inputValue) => {
                                                                this.loadOptions(inputValue, (newOptions) => {
                                                                    this.setState({ tagsOptions: newOptions });
                                                                });
                                                            }}
                                                            onCreateOption={this.handleCreate}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            placeholder="Busque uma característica ou crie uma nova"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Container>
                                            <Container>
                                                <Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                                    <Col className="col-auto">
                                                        <Button className="btn-dark" onClick={() => {
                                                            setTimeout(() => {
                                                                window.open('./../empreendimento-tipo?id=' + id, '_parent')
                                                            }, 100);
                                                        }}>Voltar</Button>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={this.updateEnterprise}>Salvar</Button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                {this.state.error_auth ? (
                <SweetAlert
                    error
                    title='Ops'
                    onConfirm={() => this.setState({ error_auth: false })}
                >
                    Você não tem permissão para esta ação.
                </SweetAlert>
            ) : null}
            </div>
        )
    }

}

export default TypeSkills;