import styles from '../../styles/mobile/AboutThirdMobile.module.css';
import { useState, useEffect } from 'react';
import ContentEditable from "react-contenteditable";

const AboutThirdMobile = ({data}) => {
  const [aboutMoreTog, setAboutMoreTog ] = useState(false)
  const [highTitle, setHighTitle] = useState('Sobre nós')
  const [highTitleEnabled, setHighTitleEnabled] = useState(true)
  console.log(data)

  useEffect(() => {
    if(localStorage.getItem('about_title')){
      setHighTitle(localStorage.getItem('about_title'))
    }

  }, []);

  return(
    <section className={styles.container_about}>
      <div className={styles.container_details}>
        <div className={styles.details}></div>
      </div>

      <div className={styles.bg}>
        <img src={data?.profile_photo} alt=""  className={styles.people}/>

        <div className={styles.container_text}>
          <div className={styles.text}>
            <div className={styles.titleContainer}>
              <ContentEditable
                html={highTitle}
                className={styles.titleEdit}
                id='aboutTitleEdit'
                disabled={highTitleEnabled}
                onChange={(e) => {
                  setHighTitle(e.target.value)
                  localStorage.setItem('about_title', e.target.value)
                }}
                onClick={(e) => {
                  if(highTitle === 'Sobre nós' && !highTitleEnabled){
                    setHighTitle('')
                    localStorage.setItem('about_title', '')
                  }
                }}
              />
              <div className={styles.actionIcons}>
                <label className="title">
                  <span onClick={() => {
                    if(!highTitleEnabled){
                      setHighTitleEnabled(true)
                      if(highTitle === ''){
                        setHighTitle('Sobre nós')
                        localStorage.setItem('about_title', 'Sobre nós')
                      }
                    }else{
                      setHighTitleEnabled(false)
                      setTimeout(() => {
                        document.getElementById('aboutTitleEdit').focus()
                        if(highTitle === 'Sobre nós'){
                          setHighTitle('')
                          localStorage.setItem('about_title', '')
                        }
                      }, 300);
                    }
                  }}>{highTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
                  <i className="ri-text" onClick={() => {
                    if(!highTitleEnabled){
                      setHighTitleEnabled(true)
                      if(highTitle === ''){
                        setHighTitle('Sobre nós')
                        localStorage.setItem('about_title', 'Sobre nós')
                      }
                    }else{
                      setHighTitleEnabled(false)
                      setTimeout(() => {
                        document.getElementById('aboutTitleEdit').focus()
                        if(highTitle === 'Sobre nós'){
                          setHighTitle('')
                          localStorage.setItem('about_title', '')
                        }
                      }, 300);
                    }
                  }}></i>
                </label>
            </div>
          </div>
            {!aboutMoreTog ?
              <p className={styles.text}><pre-line>{data?.about}</pre-line></p>
              :
              <p className={styles.textExpand}><pre-line>{data?.about}</pre-line></p>
            }
          </div>
        </div>

        {!aboutMoreTog ?
            <p className={styles.text}><pre-line>{data?.about}</pre-line></p>
            :
            <p className={styles.textExpand}><pre-line>{data?.about}</pre-line></p>
        }
        
        <div className={styles.aboutMore} onClick={() => {
            setAboutMoreTog(!aboutMoreTog)
          }}>{!aboutMoreTog ? 'Ver mais' : 'Ver menos'}
        </div>
      </div>
    </section>
  )
}

export default AboutThirdMobile;