import Axios from 'axios';
import React, { Component } from 'react';
import { Col, Card, CardBody, Button, Spinner, Row } from "reactstrap";

class PendentItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
          imobiliarias_pendentes:null,
          corretores_pendentes:null,
          imoveis_pendentes:null,
          agendamentos_pendentes:null,
        }
      }

      componentDidMount(){
        for(var i = 0; document.querySelectorAll('.loadPendent').length > i; i++){
            document.querySelectorAll('.loadPendent')[i].classList.add('active')
        }

        Axios.get(process.env.REACT_APP_API + '/api/auth/dashboard/get-count',
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          this.setState({imobiliarias_pendentes: response.data.imobiliarias_pendentes})
          this.setState({corretores_pendentes: response.data.corretores_pendentes})
          this.setState({imoveis_pendentes: response.data.imoveis_pendentes})
          this.setState({agendamentos_pendentes: response.data.agendamentos_pendentes})
          this.setState({propostas_pendentes: response.data.propostas_pendentes})
          this.setState({empreendimentos_pendentes: response.data.empreendimentos_pendentes})

          setTimeout(() => {
             for(var i = 0; document.querySelectorAll('.loadPendent').length > i; i++){
                 document.querySelectorAll('.loadPendent')[i].classList.remove('active')
             }
          }, 300);
        })
      }


    render() {
        return (
            <React.Fragment>
            <Row>
                <Col md={3} style={{ maxHeight: "177px", minHeight: "177px", overflow: 'hidden auto', position:'relative'  }}>
                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Imóveis Pendentes</p>
                                    <h4 className="mb-0 font-size-20 " style={{display:'flex', justifyContent:'space-between'}}>{this.state.imoveis_pendentes} <Button onClick={() =>{
                                        setTimeout(() => {
                                            window.open('./administrar-imoveis', '_parent')
                                        }, 300);
                                    }}>Ver</Button></h4>
                                </div>
                            </div>
                        </CardBody>
                        <div className="loadPendent load" style={{position:'absolute', zIndex:'99'}}>
                            <Spinner className="me-2" color="success" />
                        </div>
                    </Card>
                </Col>
                <Col md={3} style={{ maxHeight: "177px", minHeight: "177px", overflow: 'hidden auto', position:'relative' }}>
                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Empreendimentos Pendentes</p>
                                    <h4 className="mb-0 font-size-20 " style={{display:'flex', justifyContent:'space-between'}}>{this.state.empreendimentos_pendentes} <Button onClick={() =>{
                                        setTimeout(() => {
                                            window.open('./administrar-empreendimentos', '_parent')
                                        }, 300);
                                    }}>Ver</Button></h4>
                                </div>
                            </div>
                        </CardBody>
                        <div className="loadPendent load" style={{position:'absolute', zIndex:'99'}}>
                            <Spinner className="me-2" color="success" />
                        </div>
                    </Card>
                </Col>
                <Col md={3} style={{ maxHeight: "177px", minHeight: "177px", overflow: 'hidden auto', position:'relative' }}>
                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Corretores Pendentes</p>
                                    <h4 className="mb-0 font-size-20 " style={{display:'flex', justifyContent:'space-between'}}>{this.state.corretores_pendentes} <Button onClick={() =>{
                                        setTimeout(() => {
                                            window.open('./administrar-usuarios', '_parent')
                                        }, 300);
                                    }}>Ver</Button></h4>
                                </div>
                            </div>
                        </CardBody>
                        <div className="loadPendent load" style={{position:'absolute', zIndex:'99'}}>
                            <Spinner className="me-2" color="success" />
                        </div>
                    </Card>
                </Col>
                <Col md={3} style={{ maxHeight: "177px", minHeight: "177px", overflow: 'hidden auto', position:'relative' }}>
                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Imobiliárias Pendentes</p>
                                    <h4 className="mb-0 font-size-20 " style={{display:'flex', justifyContent:'space-between'}}>{this.state.imobiliarias_pendentes} <Button onClick={() =>{
                                        setTimeout(() => {
                                            window.open('./administrar-usuarios', '_parent')
                                        }, 300);
                                    }}>Ver</Button></h4>
                                </div>
                            </div>
                        </CardBody>
                        <div className="loadPendent load" style={{position:'absolute', zIndex:'99'}}>
                            <Spinner className="me-2" color="success" />
                        </div>
                    </Card>
                </Col>
                <Col md={3} style={{ maxHeight: "177px", minHeight: "177px", overflow: 'hidden auto', position:'relative' }}>
                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Construtoras Pendentes</p>
                                    <h4 className="mb-0 font-size-20 " style={{display:'flex', justifyContent:'space-between'}}>0 <Button onClick={() =>{
                                        setTimeout(() => {
                                            window.open('./administrar-usuarios', '_parent')
                                        }, 300);
                                    }}>Ver</Button></h4>
                                </div>
                            </div>
                        </CardBody>
                        <div className="loadPendent load" style={{position:'absolute', zIndex:'99'}}>
                            <Spinner className="me-2" color="success" />
                        </div>
                    </Card>
                </Col>
                <Col md={3} style={{ maxHeight: "177px", minHeight: "177px", overflow: 'hidden auto', position:'relative' }}>
                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Propostas Pendentes</p>
                                    <h4 className="mb-0 font-size-20 " style={{display:'flex', justifyContent:'space-between'}}>{this.state.propostas_pendentes} <Button onClick={() =>{
                                        setTimeout(() => {
                                            window.open('./administrar-propostas', '_parent')
                                        }, 300);
                                    }}>Ver</Button></h4>
                                </div>
                            </div>
                        </CardBody>
                        <div className="loadPendent load" style={{position:'absolute', zIndex:'99'}}>
                            <Spinner className="me-2" color="success" />
                        </div>
                    </Card>
                </Col>
                <Col md={3} style={{ maxHeight: "177px", minHeight: "177px", overflow: 'hidden auto', position:'relative' }}>
                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Agendamentos Pendentes</p>
                                    <h4 className="mb-0 font-size-20 " style={{display:'flex', justifyContent:'space-between'}}>{this.state.agendamentos_pendentes} <Button onClick={() =>{
                                        setTimeout(() => {
                                            window.open('./agenda', '_parent')
                                        }, 300);
                                    }}>Ver</Button></h4>
                                </div>
                            </div>
                        </CardBody>
                        <div className="loadPendent load" style={{position:'absolute', zIndex:'99'}}>
                            <Spinner className="me-2" color="success" />
                        </div>
                    </Card>
                </Col>
            </Row>
            </React.Fragment>
        );
    }
}

export default PendentItems;