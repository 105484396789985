import { Input, Label } from 'reactstrap';
import './styles.scss';
import { useEffect, useState } from 'react';

export const CpfInput = ({handleInputChange}) => {
  const [inputValue, setInputValue] = useState('')

  const formatCpf = (inputValue) => {
    const cleanValue = inputValue.replace(/\D/g, '');

    let formattedValue = '';
    for (let i = 0; i < cleanValue.length; i++) {
      if (i === 3 || i === 6) {
        formattedValue += '.';
      } else if (i === 9) {
        formattedValue += '-';
      }
      formattedValue += cleanValue.charAt(i);
    }

    return formattedValue.trim().slice(0, 14);
  };

  const handleChange = (event) => {
    const formattedValue = formatCpf(event.target.value);
    setInputValue(formattedValue);

    handleInputChange(event);
  };


  return(
    <fieldset>
      <Label>CPF do titular do cartão</Label>
      <Input
        type="text"
        name="cpf"
        placeholder="000.000.000/00"
        value={inputValue}
        onChange={handleChange}
        maxLength={14}
      />
    </fieldset>
  )
}