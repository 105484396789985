import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import { Link } from "react-router-dom";
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, ModalBody, ModalHeader, Progress, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from "../../components/Inputs/CurrencyInput";
import Dropzone, { useDropzone } from 'react-dropzone';
import { Draggable } from "react-drag-reorder";
import EnterprisePhotosOrder from '../editProperties/enterprisePhotosOrder';
import UnitsPhotosOrder from "../editProperties/unitsPhotosOrder";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const id = window.location.search.replace('?id=', '')

class TypePhotos extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      photosOrder:null,
      dragPhotos:'',
      photosArray:[],
      selectedFiles: [],
      progressBars: [],
      deleteSuccess:false,
      errors:[],
      deleteError:false,
      count:0,
      sendImageError:false,

      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000
      }
      this.updateEnterprise = this.updateEnterprise.bind(this);
      this.updatePositions = this.updatePositions.bind(this);
      this.deleteImage = this.deleteImage.bind(this);
      this.showToast.bind(this);
    }

    showToast(type, title, message = "") {
      var positionClass = "toast-bottom-right";
      
      toastr.options = {
        positionClass: positionClass,
        timeOut: this.state.timeOut,
        extendedTimeOut: this.state.extendedTimeOut,
        closeButton: true,
        progressBar: true,
        newestOnTop: true,
        showEasing: this.state.showEasing,
        hideEasing: this.state.hideEasing,
        showMethod: this.state.showMethod,
        hideMethod: this.state.hideMethod,
        showDuration: this.state.showDuration,
        hideDuration: this.state.hideDuration
      };
    
      if (type === "info") {
        toastr.info(message, title);
      } else if (type === "warning") {
        toastr.warning(message, title);
      } else if (type === "error") {
        toastr.error(message, title);
      } else {
        toastr.success(message, title);
      }
    }    

    componentDidMount(){
      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types/' + id, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            this.setState({
                enterprise: response.data,
                skillsEditList: response.data.details
            })
         })
        })
        .catch(response =>{
            setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
            })  
    }

      formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      };

      handleFormData = (orders, update) => {
        const data = new FormData();
        for(let i = 0; i < update.length; i++){
          data.append('order[]', orders[i])
          data.append('id[]', update[i])
        }
        this.setState({ photosOrder: data });

        this.updatePositions()
      };
      
      handleAcceptedFiles = files => {
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: this.formatBytes(file.size)
          })
        );
    
        this.setState({ selectedFiles: files }, () => {
          this.setState({photosArray: this.state.selectedFiles.map((file, index) => ({
            file: file,
            position: index
          }))});
        });

        this.updateEnterprise()
      };


   updateEnterprise(event) {
    document.getElementById('load').classList.add('active')
    const { photosArray } = this.state;
    const totalPhotos = photosArray.length;
    this.setState({ progressBars: new Array(totalPhotos).fill(0) });

    if(this.state.photosArray.length > 0){

      photosArray.forEach((photo, index) => {
        const dataEnterprisePhotos = new FormData();
        dataEnterprisePhotos.append(`photo[0][file]`, photo.file);
        dataEnterprisePhotos.append(`photo[0][position]`, photo.position);
        dataEnterprisePhotos.append(`photo[0][property_enterprise_type_id]`, id);

        setTimeout(() => {
          Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-photos', dataEnterprisePhotos, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
            onUploadProgress: event => {
                const progress = Math.round(100 * event.loaded) / event.total;
                const updatedProgressBars = [...this.state.progressBars];
                updatedProgressBars[index] = progress;
                this.setState({ progressBars: updatedProgressBars }, () => {
                  this.forceUpdate();
              });
            }
        }).then(response => {
            if (index === totalPhotos - 1) {
                document.getElementById('load').classList.remove('active')

                Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types/' + id, {
                  headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                }).then(response => {
                   this.setState({
                       enterprise: response.data,
                   })
                   this.setState({count : this.state.count + 1})
                   this.setState({photosArray : [], selectedFiles: []})
                })
            }
        }).catch(error => {
          const updatedErrors = [...this.state.errors];
          updatedErrors[index] = { isError: true, message: 'Erro no envio da imagem' };
          this.setState({ errors: updatedErrors });
          this.setState({sendImageError: true})
        });
        }, 500);

      });
    }
}

   updatePositions(){
    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
      this.setState({error_auth:true})
    }else{
      document.getElementById('load').classList.add('active')
      Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/update-types-photos', this.state.photosOrder, {
          headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
      }).then(response => {
        document.getElementById('load').classList.remove('active')
        this.showToast('success', 'Fotos reordenadas com sucesso!')
      }).catch(response => {
        document.getElementById('load').classList.remove('active')
        this.showToast('error', 'Houve um problema com a ordenação, tente novamente ou informe o suporte.')
    })
    }
  }

  deleteImage(photo_id) {
    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
      this.setState({error_auth:true})
  }else{
    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-type-photo/delete/' + photo_id, {},
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        this.setState({deleteSuccess: true})

        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types/' + id, {
          headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
           this.setState({
               enterprise: response.data,
           })
           this.setState({count : this.state.count + 1})
        })
      })              
      .catch(response => {
        this.setState({deleteError: true})
      })
    }
   }

    render(){
        const {enterprise, dragPhotos} = this.state;

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                          <Container style={{paddingBottom:'20px', paddingTop:'20px', marginTop:'20px'}}>
                                            <Row style={{margin:'10px 0'}}>
                                              <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Fotos do tipo de unidade</h5></Col>
                                            </Row>
                                            <Form>
                                            {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') &&
                                              <Dropzone
                                                accept={{
                                                  "image/*": [".png", ".jpeg", ".jpg", '.heic'],
                                                }}
                                                onDrop={acceptedFiles =>
                                                  this.handleAcceptedFiles(acceptedFiles)
                                                }
                                              >
                                                {({ getRootProps, getInputProps }) => (
                                                  <div className="dropzone">
                                                    <div
                                                      className="dz-message needsclick"
                                                      {...getRootProps()}
                                                    >
                                                      <input {...getInputProps()} />
                                                      <div className="mb-3">
                                                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                      </div>
                                                      <h4>Para adicionar fotos arraste ou clique aqui.</h4>
                                                      <small>Tamanho máximo do arquivo de 8 mb.</small>
                                                    </div>
                                                  </div>
                                                )}
                                              </Dropzone>
                                              }
                                              {enterprise?.photos && enterprise?.photos.length > 0 &&
                                                <>
                                                    <div style={{margin:'20px 0'}}>
                                                      <p style={{fontSize:'20px', fontWeight:'600'}}>Exclua as fotos que não são deste tipo e ordene suas fotos. As 3 primeiras recebem maior destaque.</p>
                                                    </div>
                                                </>
                                                }
                                              <div
                                                className="dropzone-previews mt-3"
                                                id="file-previews"
                                              >
                                              {this.state.selectedFiles.map((f, i) => 
                                                  <Col key={i + "-file"} id={f.name} >
                                                    <Card
                                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    >
                                                      <div className="p-2">
                                                          <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                              <img
                                                                data-dz-thumbnail=""
                                                                height="120"
                                                                className="avatar-sm rounded bg-light image-send"
                                                                alt={f.name}
                                                                src={f.preview}
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Link
                                                                to="#"
                                                                className="text-muted fw-bold"
                                                              >
                                                                {f.name}
                                                              </Link>
                                                              <p className="mb-0">
                                                                <strong>{f.formattedSize}</strong>
                                                                {this.state.errors[i] ? (
                                                                    <div style={{ color: 'red' }}>{this.state.errors[i].message}</div>
                                                                ) : (
                                                                    <Progress value={this.state.progressBars[i]}/>
                                                                )}
                                                              </p>
                                                            </Col>
                                                          </Row>
                                                      </div>
                                                    </Card>
                                                    </Col>
                                                )}
                                              </div>
                                              <UnitsPhotosOrder
                                                onFormDataChange={this.handleFormData}
                                                imoveis={enterprise?.photos}
                                                count={this.state.count}
                                                deleteImage={this.deleteImage}
                                              />
                                            </Form>
                                          </Container>
                                            <Container>
                                              <Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                                <Col className="col-auto">
                                                    <Button className="btn-dark" onClick={() => {
                                                        setTimeout(() => {
                                                            window.open('./../empreendimento-tipo?id=' + id, '_parent')
                                                        }, 100);
                                                    }}>Voltar</Button>
                                                </Col>
                                                {/* <Col className="col-auto">
                                                    <Button onClick={this.updatePositions}>Salvar ordenação</Button>
                                                </Col> */}
                                              </Row>
                                            </Container>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                              {/* Sucesso na atualização */}

              {this.state.deleteSuccess ? (
                <SweetAlert
                  title="Imagem excluída"
                  timeout={1500}
                  showCloseButton={false}
                  showConfirm={false}
                  success
                  onConfirm={() => this.setState({deleteSuccess: !this.state.deleteSuccess})}
                ></SweetAlert>
                ) : null}

              {/* Falha na atualização */}

              {this.state.deleteError ? (
                <SweetAlert
                  title="A exclusão falhou, tente novamente."
                  timeout={1500}
                  showCloseButton={false}
                  showConfirm={false}
                  error
                  onConfirm={() => this.setState({deleteError: !this.state.deleteError})}
                ></SweetAlert>
                ) : null}

            {/* Falha envio de imagem */}

            {this.state.sendImageError ? (
              <SweetAlert
                title="Algumas imagens não puderam ser enviadas."
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                error
                onConfirm={() => {
                  document.getElementById('load').classList.remove('active')
                  this.setState({sendImageError: !this.state.sendImageError})
                }}
              ></SweetAlert>
              ) : null}

              {this.state.error_auth ? (
                <SweetAlert
                    error
                    title='Ops'
                    onConfirm={() => this.setState({ error_auth: false })}
                >
                    Você não tem permissão para esta ação.
                </SweetAlert>
              ) : null}
            </div>
        )
    }

}

export default TypePhotos;