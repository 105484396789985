import React, { Component } from "react"

import { Row, Col, Card, CardBody, Button, Container, Badge, Spinner } from "reactstrap"
import { createTheme, Pagination, ThemeProvider } from '@mui/material';
import "@fullcalendar/bootstrap/main.css"
import './style.scss'
import Axios from 'axios'
import ImovelCardFS from "../ImovelCardFS";
import 'swiper/css';
import "swiper/css/navigation";
import Filters from '../../../components/Filters/Filters'

class CatalogPropertiesResult extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: "Fast Sale", link: "/dashboard" },
                { title: "Imóveis", link: "#" },
            ],
            modal_scroll: false,
            visible: false,
            verifyPremium: 0,
            sortResults: localStorage.getItem('filtro-sort') ? localStorage.getItem('filtro-sort') : 'newest',
            searchContractType: localStorage.getItem('search-contract-type') ? localStorage.getItem('search-contract-type') : 'Todos Contratos',
            imoveis: [],
            currentPage: 1,  
            itemsPerPage: 15,  
            favoritos:[],
            modal_large: false,
            page: localStorage.getItem('paginaAtual') ? localStorage.getItem('paginaAtual') : 1,
            search: '',
            atualPage:1,
            dataFilter:[],
            oportunidade:[],
            imoveisCarregando:null,
            pages: 1,
            imoveisSelecionados: [],
            showFilter:{
                filter_lg: 4,
                filter_xl: 3,
                body_lg: 8,
                body_xl: 9,
            },
            onToogle:true,
        }
        this.buttonRef = React.createRef();
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.tog_scroll = this.tog_scroll.bind(this);
        this.tog_large = this.tog_large.bind(this);
    }

    tog_large() {
        this.setState(prevState => ({
            modal_large: !prevState.modal_large
        }));
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    tog_scroll() {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll
        }));
        // this.removeBodyCss();
    }

    show() {
        this.setState({ visible: true });
    }

    hide() {
        this.setState({ visible: false });
    }

    componentDidMount = () => {
        document.getElementById('load').classList.add('active')
        setTimeout(() => {
            window.scrollTo(0,0)
        }, 300);

        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {

            if(localStorage.getItem('access') === 'A'){
                this.setState({verifyPremium: 1})
                this.handlePremiumPropertyFilter();
            }else{
                Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
                type:localStorage.getItem('access'),
                user_id:localStorage.getItem('id')
                }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(res => {
                this.setState({verifyPremium: 1})
                this.handlePremiumPropertyFilter();
                })
                .catch(res => {
                this.setState({verifyPremium: 0})
                this.handlePremiumPropertyFilter();
                })
            }

            Axios.post(process.env.REACT_APP_API + '/api/auth/my-favorite', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id')
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                response.data.map((imovel, index) => {
                    return this.state.favoritos.push(imovel.property_id)
                })
            })
        })
        .catch(response =>{
            localStorage.removeItem("history_url")
                setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
        })

    }

    handlePremiumPropertyFilter = () => {
        const input = document.getElementById("inputText");
        input.addEventListener("keydown", this.handleKeyDown);
        const data = {
            categories: (localStorage.getItem('filtro-categoria') !== null && localStorage.getItem('filtro-categoria').length !== 0) ? localStorage.getItem('filtro-categoria').split(",") : 0,
            localization: (localStorage.getItem('filtro-cidades') !== null && localStorage.getItem('filtro-cidades').length !== 0) ? localStorage.getItem('filtro-cidades').split(",") : 0,
            dorms_number: localStorage.getItem('filtro-quartos') !== null ? (localStorage.getItem('filtro-quartos') !== '' ? localStorage.getItem('filtro-quartos').split(",") : 0) : 0,
            parking_spots: localStorage.getItem('filtro-garagens') !== null ? (localStorage.getItem('filtro-garagens') !== '' ? localStorage.getItem('filtro-garagens').split(",") : 0) : 0,
            real_state_financing:localStorage.getItem('filtro-negocio')?.includes('Financiamento Bancário') ? 1 : 0,
            accept_object_exchange:localStorage.getItem('filtro-negocio')?.includes('Permuta Objeto') ? 1 : 0,
            accept_properties:localStorage.getItem('filtro-negocio')?.includes('Permuta Imóvel') ? 1 : 0,
            min_value:localStorage.getItem('min_price_range_filter') ? localStorage.getItem('min_price_range_filter') : '',
            max_value:localStorage.getItem('max_price_range_filter') ? localStorage.getItem('max_price_range_filter') : '',
            min_area:localStorage.getItem('min_area_range_filter') ? localStorage.getItem('min_area_range_filter') : '',
            max_area:localStorage.getItem('max_area_range_filter') ? localStorage.getItem('max_area_range_filter') : '',
            search: (decodeURI(window.location.search).replace('?', '') !== 'outlet' && decodeURI(window.location.search).replace('?', '') !== 'premium' && decodeURI(window.location.search).replace('?', '') !== 'favoritos') ? decodeURI(window.location.search.replace('?', '').replace(/-/g, ' ')) : '',
            filter:decodeURI(this.state.sortResults),
            workflow:[7],
            accept_car:localStorage.getItem('filtro-negocio')?.includes('Permuta Automóvel') ? 1 : 0
        }
        this.setState({dataFilter: data})
        // if(this.state.searchContractType === 'Todos Contratos'){
            document.getElementById('all').classList.add('active')
        // }
        if(this.state.verifyPremium){
              Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/select-properties', data, 
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                  this.setState({imoveis: response.data})
                  setTimeout(() => {
                      document.getElementById('load').classList.remove('active')
                  }, 300);
          })
        }
    }
    

    componentWillUnmount() {
        const input = document.getElementById("inputText");
        input.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(event) {
        if (event.keyCode === 13) { // Verifica se a tecla pressionada é "Enter"
          event.preventDefault(); // Impede que a página recarregue
          if (this.buttonRef.current) { // Verifica se a referência do botão está definida
            setTimeout(() => {
                this.buttonRef.current.click(); // Dispara o clique no botão
            }, 300);
            console.log(this.buttonRef.current)
        }
        }
    }

    toogleFilters() {
        if(this.state.showFilter.filter_lg === 4){
            this.setState({onToogle: false})
            this.setState({
                showFilter:{
                    filter_lg: 1,
                    filter_xl: 1,
                    body_lg: 11,
                    body_xl: 11,
                }
            })
        }else{
            this.setState({onToogle: true})
            this.setState({
                showFilter:{
                    filter_lg: 4,
                    filter_xl: 3,
                    body_lg: 8,
                    body_xl: 9,
                }
            })
        }
    }

    handleImovelClick = (imovel) => {
      console.log('Selecionando imovel:', imovel)
      if(this.state.imoveisSelecionados.some(i => i === imovel.id)) {
        console.log('Imovel já selecionado, descarmando...')
        this.setState(prevState => ({
          imoveisSelecionados: prevState.imoveisSelecionados.filter(i => i !== imovel.id)
        }), () => console.log('Imoveis selecionados depois de desmarcar:', this.state.imoveisSelecionados))
      } else {
        console.log('Imovel nao selecionado, selecionando...')
        this.setState(prevState => ({
          imoveisSelecionados: [...prevState.imoveisSelecionados, imovel.id]
        }), () => console.log('Imoveis selecionados depois de selecionar:', this.state.imoveisSelecionados))
      }
    };


    getImoveisDaPaginaAtual() {
      const indexOfLastImovel = this.state.currentPage * this.state.itemsPerPage;
      const indexOfFirstImovel = indexOfLastImovel - this.state.itemsPerPage;
      const currentImoveis = this.state.imoveis.slice(indexOfFirstImovel, indexOfLastImovel);

      return currentImoveis;
    }

    getTotalPages() {
      return Math.ceil(this.state.imoveis.length / this.state.itemsPerPage);
    }

    handleSelectAll = () => {
      const allImovelIds = this.state.imoveis.map(imovel => imovel.id);
      this.setState({ imoveisSelecionados: allImovelIds });
    };
    
    handleDeselectAll = () => {
      this.setState({ imoveisSelecionados: [] });
    };


    render() {
        const theme = createTheme({
            palette: {
            verde: {
                main: '#3E5E3F',
                contrastText: '#fff',
                },
            },
        });
        const {imoveis, sortResults, showFilter} = this.state;
        return (
            <React.Fragment>
                <div>
                    <Container fluid={true} style={{padding: '0'}}>
                        <Row>
                            <Col lg={showFilter.filter_lg} xl={showFilter.filter_xl}>
                                <Filters imoveis={imoveis} toogle={this.toogleFilters.bind(this)} onToogle={this.state.onToogle} premium={this.state.verifyPremium} redirectToImoveis={false}/>
                            </Col>

                            <Col lg={showFilter.body_lg} xl={showFilter.body_xl}>
                              <Card>
                                  <CardBody>
                                  <Row style={{marginLeft: '.1rem', marginRight: '.1rem'}}>
                                    <div className='atention'>
                                      <i style={{color:"#FFFFFF", fontWeight: 900}} className="mdi mdi-alert-circle-outline me-3"></i>
                                      <span className='atention_link' style={{color:"#FFFFFF", fontWeight: 900}}>Atenção</span>
                                      <p>Ao adicionar imóveis ao seu site, você estará aceitando o contrato de parceria.</p>
                                    </div>
                                  </Row>
                                      <Row>
                                        <Col md="8">
                                          <Row>
                                            <Col className="col-auto">
                                              <Button onClick={this.handleSelectAll} className="button-select-all-imobiles">Selecionar todos os imóveis</Button>
                                            </Col>
                                            <Col className="col-auto">
                                              {
                                                this.state.imoveisSelecionados.length > 0 ? (
                                                  <Button onClick={this.handleDeselectAll} className="button-no-select">Desfazer seleção</Button>
                                                ) : (
                                                  <></>
                                                )
                                              }
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md="4" className="continer-button-add">
                                          <Button 
                                                type="button"
                                                onClick={() => this.props.adicionarImoveisSelecionados(this.state.imoveisSelecionados)} 
                                                color="primary" 
                                                className="waves-effect waves-light"
                                                data-toggle="modal"
                                                data-target=".bs-example-modal-sm">
                                                Adicionar para meu site
                                          </Button>
                                        </Col>
                                      </Row>
                                    <div>
                                      <Row>
                                      <Col className="sortContainer" md={6} style={{marginTop: '1rem', marginLeft: '-.5rem'}}>
                                            <Row className="sortContainerMobile" style={{justifyContent:'flex-start', paddingRight: '15px', flexDirection: 'row', flexWrap:'wrap', alignItems: 'center', gap:'10px', width:'100%'}}>
                                                <p className="col-auto">Ordenar por:</p>  
                                                <select value={sortResults} className="form-control" style={{width:'140px'}} onChange={(e) => {
                                                    localStorage.setItem('filtro-sort', e.target.value)
                                                    window.location.reload()
                                                    }}>
                                                    <option value="newest" selected>Mais novos</option>
                                                    <option value="older">Mais antigos</option>
                                                    <option value="lower-price">Menor preço</option>
                                                    <option value="higger-price">Maior preço</option>
                                                    <option value="lower-area">Menor área</option>
                                                    <option value="higger-area">Maior área</option>
                                                    {/* <option value="name">Ordem alfabética</option> */}
                                                </select>
                                            </Row>
                                        </Col>
                                        <Col md="6">
                                          {decodeURI(window.location.search) &&
                                            <Badge className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                              window.location.search = "";
                                              }}>{decodeURI(window.location.search).replace('?', '')}<i className="ri-close-circle-line"></i>
                                            </Badge>
                                          }
                                          {(localStorage.getItem('filtro-categoria') !== null && 
                                            localStorage.getItem('filtro-categoria') !== '') &&
                                            localStorage.getItem('filtro-categoria')?.split(",").map((categoria, index) => (
                                              <Badge key={index} className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                                  var categorias = localStorage.getItem('filtro-categoria')?.split(",")
                                                  var index = categorias.indexOf(categoria)
                                                  if (index > -1) {
                                                      categorias.splice(index, 1)
                                                  }
                                                  localStorage.setItem('filtro-categoria', categorias)
                                                  window.location.reload()
                                                }}>{categoria}<i className="ri-close-circle-line"></i>
                                              </Badge>
                                            ))
                                          }

                                          {(localStorage.getItem('min_price_range_filter') !== null
                                            && localStorage.getItem('min_price_range_filter') !== '') &&
                                            <Badge className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                              localStorage.removeItem('min_price_range_filter')
                                              window.location.reload()
                                              }}>min.{(localStorage.getItem('min_price_range_filter') / 100)
                                              .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<i className="ri-close-circle-line"></i>
                                            </Badge>
                                          }

                                          {(localStorage.getItem('max_price_range_filter') !== null
                                            && localStorage.getItem('max_price_range_filter') !== '') &&
                                            <Badge className="badge-soft-dark me-1 filterActive" onClick={() => {
                                              localStorage.removeItem('max_price_range_filter')
                                              window.location.reload()
                                              }}>max.{(localStorage.getItem('max_price_range_filter') / 100)
                                              .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<i className="ri-close-circle-line"></i>
                                            </Badge>
                                          }

                                          {(localStorage.getItem('min_area_range_filter') !== null
                                            && localStorage.getItem('min_area_range_filter') !== '') &&
                                            <Badge className="badge-soft-dark me-1 filterActive" onClick={() => {
                                              localStorage.removeItem('min_area_range_filter')
                                              window.location.reload()
                                            }}>min.{localStorage.getItem('min_area_range_filter')} m²<i className="ri-close-circle-line"></i>
                                            </Badge>
                                          }

                                          {(localStorage.getItem('max_area_range_filter') !== null
                                            && localStorage.getItem('max_area_range_filter') !== '') &&
                                            <Badge className="badge-soft-dark me-1 filterActive" onClick={() => {
                                              localStorage.removeItem('max_area_range_filter')
                                              window.location.reload()
                                              }}>max.{localStorage.getItem('max_area_range_filter')} m²<i className="ri-close-circle-line"></i>
                                            </Badge>
                                          }

                                          {(localStorage.getItem('filtro-cidades') !== null
                                              && localStorage.getItem('filtro-cidades') !== '') &&
                                              localStorage.getItem('filtro-cidades')?.split(",").map((cidade, index) => (
                                              <Badge key={index} className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                                    var cidades = localStorage.getItem('filtro-cidades')?.split(",")
                                                    var index = cidades.indexOf(cidade)
                                                    if (index > -1) {
                                                        cidades.splice(index, 1)
                                                    }
                                                    localStorage.setItem('filtro-cidades', cidades)
                                                    window.location.reload()
                                                  }}>{cidade}<i className="ri-close-circle-line"></i>
                                              </Badge>
                                            ))
                                          }
                                                    
                                          {(localStorage.getItem('filtro-quartos') !== null
                                            && localStorage.getItem('filtro-quartos') !== '') &&
                                            localStorage.getItem('filtro-quartos')?.split(",").map((quarto, index) => (
                                            <Badge key={index} className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                              var quartos = localStorage.getItem('filtro-quartos')?.split(",")
                                              var index = quartos.indexOf(quarto)
                                              if (index > -1) {
                                                  quartos.splice(index, 1)
                                              }
                                              localStorage.setItem('filtro-quartos', quartos)
                                              window.location.reload()
                                              }}>{quarto} quartos<i className="ri-close-circle-line"></i>
                                            </Badge>
                                            ))
                                          }

                                          {(localStorage.getItem('filtro-garagens') !== null
                                            && localStorage.getItem('filtro-garagens') !== '') &&
                                            localStorage.getItem('filtro-garagens')?.split(",").map((garagem, index) => (
                                            <Badge key={index} className="badge-soft-dark me-1 filterActive" onClick={() => {
                                                var garagens = localStorage.getItem('filtro-garagens')?.split(",")
                                                var index = garagens.indexOf(garagem)
                                                if (index > -1) {
                                                    garagens.splice(index, 1)
                                                }
                                                localStorage.setItem('filtro-garagens', garagens)
                                                window.location.reload()
                                                }}>{garagem} vagas<i className="ri-close-circle-line"></i>
                                            </Badge>
                                            ))
                                          }

                                          {(localStorage.getItem('filtro-negocio') !== null
                                            && localStorage.getItem('filtro-negocio') !== '') &&
                                            localStorage.getItem('filtro-negocio')?.split(",").map((negocio, index) => (
                                              <Badge key={index} className="badge-soft-dark me-1 filterActive" onClick={() => {
                                                  var negocios = localStorage.getItem('filtro-negocio')?.split(",")
                                                      var index = negocios.indexOf(negocio)
                                                      if (index > -1) {
                                                      negocios.splice(index, 1)
                                                      }
                                                  localStorage.setItem('filtro-negocio', negocios)
                                                  window.location.reload()
                                                }}>{negocio}<i className="ri-close-circle-line"></i>
                                              </Badge>
                                            ))
                                          } 

                                          {(decodeURI(window.location.search)
                                            || (localStorage.getItem('filtro-categoria') && localStorage.getItem('filtro-categoria') !== '')
                                            || (localStorage.getItem('filtro-cidades') && localStorage.getItem('') !== '')
                                            || (localStorage.getItem('max_price_range_filter') && localStorage.getItem('') !== '')
                                            || (localStorage.getItem('min_price_range_filter') && localStorage.getItem('') !== '')
                                            || (localStorage.getItem('filtro-quartos') && localStorage.getItem('') !== '')
                                            || (localStorage.getItem('filtro-garagens') && localStorage.getItem('') !== '')
                                            || (localStorage.getItem('filtro-negocio') && localStorage.getItem('') !== '')
                                            || (localStorage.getItem('min_area_range_filter') && localStorage.getItem('') !== '')
                                            || (localStorage.getItem('max_area_range_filter') && localStorage.getItem('') !== '')) &&
                                            <Badge className="badge-soft-dark me-1 filterActive" style={{backgroundColor:"black", color:'white'}} onClick={() => {
                                              localStorage.removeItem('filtro-categoria')
                                              localStorage.removeItem('filtro-cidades')
                                              localStorage.removeItem('filtro-estados')
                                              localStorage.removeItem('max_price_range_filter')
                                              localStorage.removeItem('min_price_range_filter')
                                              localStorage.removeItem('filtro-quartos')
                                              localStorage.removeItem('filtro-garagens')
                                              localStorage.removeItem('filtro-negocio')
                                              localStorage.setItem('filtro-sort', 'newest')
                                              localStorage.removeItem('search-contract-type')
                                              localStorage.removeItem('min_area_range_filter')
                                              localStorage.removeItem('max_area_range_filter')
                                              window.location.reload(
                                                  window.history.pushState(null, document.title, window.location.pathname + '')
                                              )
                                              }}>limpar filtros
                                            </Badge>
                                          }
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col className="tabList">
                                            <div>
                                                <div id="all" className="tab all active" onClick={() => {
                                                    localStorage.setItem('search-contract-type', 'Todos Contratos')
                                                    localStorage.setItem('paginaAtual', '1')
                                                    setTimeout(() => {
                                                        window.location.reload()
                                                    }, 300);
                                                }}>
                                                </div>
                                            </div>
                                        </Col>
                                      </Row>

                                      {imoveis.length > 0 ?
                                        <Row className="catalogResult" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))', display:'grid', gap: '10px'}}>
                                          {console.log(this.getImoveisDaPaginaAtual())}
                                          {
                                            this.getImoveisDaPaginaAtual().map((imovel, index) => (
                                              <Col key={index} style={{padding:'0', justifyContent:'center', display: 'flex', alignItems: 'center'}}>
                                                  <ImovelCardFS  
                                                      imovel={imovel} 
                                                      favoritos={this.state.favoritos} 
                                                      premium={this.state.verifyPremium} 
                                                      handleImovelClick={this.handleImovelClick}
                                                      isSelected={this.state.imoveisSelecionados.includes(imovel.id)}
                                                  />
                                              </Col>
                                            ))
                                          }
                                        </Row>
                                        :
                                          <Row>
                                              <Col style={{padding:'20px', textAlign:'center'}}>{this.state.imoveisCarregando}</Col>
                                          </Row>
                                      }

                                      <Row>
                                          <Col>
                                              <ThemeProvider theme={theme}>
                                              <Pagination
                                              count={this.getTotalPages()}
                                              color="verde"
                                              size="small" 
                                              page={this.state.currentPage}
                                              style={{margin:'20px 0', display:'flex', justifyContent:'flex-end'}}
                                              onChange={(event, value) => {
                                                this.setState({ currentPage: value })
                                              }}
                                            />
                                              </ThemeProvider>
                                          </Col>
                                      </Row>
                                    </div>
                                  </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    <div id="load" className="load">
                        <Spinner className="me-2" color="success" />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default (CatalogPropertiesResult)