import { useEffect, useState } from 'react';
import './styles.scss';
import Logo from "../../../assets/images/logo-dark.png";
import { Button, Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import PhoneInput from './components/form/phoneInput';
import EmailInput from './components/form/emailInput';
import Axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const NewRegister = ({newCallback}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const nameParam = searchParams.get('name');
  const phoneParam = searchParams.get('phone');
  const afType = searchParams.get('aftype');
  const afOrigin = searchParams.get('aforigin');
  const afId = searchParams.get('afid');

  useEffect(() => {
    if(nameParam){
      setName(nameParam)
    }
    if(phoneParam){
      setPhone(phoneParam)
    }
  }, [nameParam, phoneParam])

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [type, setType] = useState('')
  
  const [loading, setLoading] = useState((false))
  const [registerError, setRegisterError] = useState(false)
  const [emailIsExist, setEmailIsExist] = useState('0')

  const history = useHistory()

  
  const handleEmailChange = (newEmail) => {
    setEmail(newEmail)
  };

  const handleEmailExists = (isExist) => {
    setEmailIsExist(isExist)
  };

  const sendRegister = () => {
    setLoading(true)
    const url = type === 'C' ? 'brokers-lead-store' : type === 'I' ? 'real-estates-lead-store' : type === 'T' ? 'construction-lead-store' : ''

    const data = new FormData()
    data.append('name', name)
    data.append('email', email)
    data.append('phone', phone)
    data.append('status', 5)
    data.append('premium', 0)
    
    Axios.post(
      process.env.REACT_APP_API + '/api/register/' + url,
      data,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
    )
      .then((response) => {
        const userData = {
          userId: response.data.user.id,
          userType: type,
          token: response.data.token,
        };
        
        if(afType && afId && afOrigin){
          const affiliate ={
            user_type: type,
            user_id: response.data.user.id,
            affiliate_type: afType,
            affiliate_id: afId,
            origin: afOrigin
          }
  
          Axios.post(
            process.env.REACT_APP_API + '/api/register/affiliate-indicator',
            affiliate,
            { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
          ).then(response => {
            if (newCallback) {
              newCallback(userData);
            } else {
              history.push('/registro-passo-2?user=' + response.data.user.id + '&type=' + type + '&token=' + response.data.token);
            }
          }).catch((error) => {
            if (newCallback) {
              newCallback(userData);
            } else {
              history.push('/registro-passo-2?user=' + response.data.user.id + '&type=' + type + '&token=' + response.data.token);
            }
          })
        }else{
          if (newCallback) {
            newCallback(userData);
          } else {
            history.push('/registro-passo-2?user=' + response.data.user.id + '&type=' + type + '&token=' + response.data.token);
          }
        }
        
      })
      .catch((error) => {
        setLoading(false)
        setRegisterError(true)
      });
  }

  return(
    <div className="register-section">
      <div className="register-wrapper">
        {loading ? 
          <Spinner className="me-2" color="success" />
        :
        <Container>
          <Row>
            <Col className="logo-container"><img src={Logo} alt="Fast Sale"/></Col>
          </Row>
          <Row className="step-title">
            <Col xs={12}>
              <h1>Cadastre-se</h1>
              <h3>Passo 1 de 3</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <fieldset>
                <Label>Nome completo</Label>
                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </fieldset>
            </Col>
            <Col xs={12}>
              <fieldset>
                <Label>Telefone</Label>
                <PhoneInput phoneNumber={phone} onPhoneChange={(e) => setPhone(e)} />
              </fieldset>
            </Col>
            <Col xs={12}>
              <fieldset>
                <Label>Email</Label>
                <EmailInput value={email} onEmailChange={handleEmailChange} onExists={handleEmailExists}/>
              </fieldset>
            </Col>
          </Row>
          <Row className="type-container">
            <Col xs={12}>
              <Label>Selecione seu perfil</Label>
            </Col>
            <Col xs={12} md={4}>
              <div
                className={`type-button ${type === 'C' ? 'active' : ''}`}
                onClick={() => setType('C')}
              >
                <i className="ri-user-line"></i>Corretor
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div
                className={`type-button ${type === 'I' ? 'active' : ''}`}
                onClick={() => setType('I')}
              >
                <i className="ri-team-line"></i>Imobiliária
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div
                className={`type-button ${type === 'T' ? 'active' : ''}`}
                onClick={() => setType('T')}
              >
                <i className="ri-community-line"></i>Construtora
              </div>
            </Col>
          </Row>
          <Row className="send-container">
            <Col xs={12}>
              {(type !== '' && email !== '' && name !== '' && phone !== '' && emailIsExist === '') ?
                <Button onClick={sendRegister}>Próximo</Button>
                :
                <Button disabled>Preencha todos os campos</Button>
              }
            </Col>
          </Row>
        </Container>
        }
      </div>

      {registerError ? (
        <SweetAlert
          title="Seu cadastro falhou, fale com nosso suporte."
          showCloseButton={true}
          showConfirm={true}
          error
          onConfirm={() => {
            setTimeout(() => {
              window.open('https://wa.me/554741081865', '_blank')
            }, 0);
          }}
        ></SweetAlert>
      ) : null}

    </div>
  )
}