import React, { Component, useRef } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, TabPane, Form, TabContent, Progress, NavLink, NavItem, ModalHeader, ModalBody, Modal, Button, ModalFooter, CardText, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import ReactSpeedometer from "react-d3-speedometer"

import './../../assets/scss/custom/components/_editProperties.scss'
import './../../assets/scss/custom/components/_addProperty.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import { Draggable } from 'react-drag-reorder';
import CurrencyInput from '../../components/Inputs/CurrencyInput';
import Select from "react-select";
import Dropzone, { useDropzone } from 'react-dropzone';
import { saveAs } from 'file-saver';
import PrimeiroImovel from './../../assets/images/Icons/primeiroimovel.png'
import Triste from './../../assets/images/Icons/triste.png'
import VamosNessa from './../../assets/images/Icons/vamosnessa.png'
import Cafezinho from './../../assets/images/upload/cafezinho.png';
import Contrato from './../../assets/images/upload/contrato.png';
import Corretores from './../../assets/images/upload/corretores.png';
import Instante from './../../assets/images/upload/instante.png';
import Upload from './../../assets/images/upload/upload.png';
import Sucesso from './../../assets/images/upload/sucesso.png';

require('moment/locale/pt.js');

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.validateRef = React.createRef();
    this.validate2Ref = React.createRef();
    this.validate3Ref = React.createRef();
    this.validate4Ref = React.createRef();
    this.state = {
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Novo imóvel", link: "#" },
    ],
    activeTab: 1,
    activeTabProgress: 1,
    progressValue: 10,
    selectedBroker: [],
    selectedRealEstate: [],
    selectedConstruction:[],
    selectedOwner: [],
    selectedUf: null,
    selectedCity: null,
    objective:'Sell',
    selectOptionsBroker: [],
    selectOptionsRealEstate: [],
    selectOptionsConstructions: [],
    selectOptionsOwner: [],
    selectOptionsUf: [],
    selectOptionsCity: [],
    selectedFiles: [],
    selectOptionsBrokerByRealEstate: [],
    selectedAuth: [],
    selectedDocs: [],
    stateSelected: '',
    citySelected:'',
    statesAddress: [],
    cityAddress: [],
    logradouro:'',
    dragPhotos:'',
    bairro:'',
    condominioValor:'',
    valor:'',
    valorPromocional:'',
    iptuValor:'',
    marinhaValor:'',
    tipoContrato:'FS Parc',
    comissao:'',
    nomeProprietario:'',
    emailProprietario:'',
    telefoneProprietario: '',
    tipoImovel:'',
    matricula:'',
    registro:'',
    title:'',
    andar:'',
    areaTerreno:'',
    areaConstruida:'',
    areaPrivativa:'',
    descricao:'',
    nomeResponsavel:'',
    contatoResponsavel: '',
    cep:'',
    numero:'',
    complemento:'',
    unidade:'',
    pais:'Brasil',
    empreendimento:'',
    construcao:'',
    torres:'',
    andares:'',
    unidadesAndar:'',
    featuresEmpreendList:[],
    nGaragens:'',
    nQuartos:'',
    nSuites:'',
    nDemiSuites:'',
    nBanheiros:'',
    nLavabos:'',
    nElevadores:'',
    nPavimentos:'',
    featuresList:[],
    video:'',
    tour:'',
    photos:[],
    tags:[],
    selectedOption: [],
    tagsOptions:[],
    tag:'',
    tagList:[],
    checkInvalidFeedback:null,
    acceptContract:false,
    isComponentMounted: false,
    quality: 0,
    cepQuality:0,
    logradouroQuality:0,
    numberQuality:0,
    bairroQuality:0,
    paisQuality:0,
    empreendimentoQuality:0,
    construcaoQuality:0,
    torresQuality:0,
    andaresQuality:0,
    unidadesAndarQuality:0,
    estadosQuality:0,
    cidadesQuality:0,
    financiamento:false,
    permutaImovel:false,
    permutaAutomovel:false,
    varandaGourmet:false,
    saunaPriv:false,
    sacada:false,
    planejado:false,
    pisoPorcelanato:false,
    pisoLaminado:false,
    pisoMadeira:false,
    piscinaPriv:false,
    mobiliado:false,
    miniCampo:false,
    livingIntegrado:false,
    interfone:false,
    homeOffice:false,
    hidro:false,
    gesso:false,
    gasEncanado:false,
    espacoGourmet:false,
    despensa:false,
    deposito:false,
    depEmpregada:false,
    decorado:false,
    cozinhaAmericana:false,
    copa :false,
    closet:false,
    churrasqueiraCarvao:false,
    blindex:false,
    banheira:false,
    armariosDormitorio:false,
    armariosCozinha:false,
    armarioEmb:false,
    areaExterna:false,
    areaServico:false,
    alarme:false,
    empChurrasqueira:false,
    empEspacoGourmet:false,
    empHallDecorado:false,
    empJacuzzi:false,
    empMiniCampo:false,
    empPiscina:false,
    empSalaoFestas:false,
    empSauna:false,
    update_success:false,
    update_error:false,
    modal_full_properties:false,
    p_count : [],
    p_count_enabled : [],
    imoveis : [],
    verifyPremium: false,
    photosArray:[],
    fsProperty: 0,
    personalPage: 0,
    accept_download: true,
    modal_forms_autorization: false,
    openFormsAutorization: false,
    proprietarioEmail:'',
    proprietarioNome:'',
    proprietarioEstadoCivil:'',
    proprietarioProfissao:'',
    proprietarioRg:'',
    proprietarioCpf:'',
    proprietarioEndereco:'',
    proprietarioImovel:'',
    proprietarioImovelMatricula:'',
    proprietarioImovelValor:'',
    proprietarioImovelComissao:'',
    user: [],
    uploadPercentage: 0,
    uploading: false,
    dots: '.',
    status:null,
    observacao:'',
    premiumActive:null,
    saldoPremium:null,
    capta:null,
    realEstateTeam:[],
    teamActive:false,
    fastAcademy:'',
    about:null,
    }
    this.toggleTab.bind(this);
    this.toggleTabProgress.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.tog_full_properties = this.tog_full_properties.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.handleSelectState = this.handleSelectState.bind(this);
    this.toggleFormsAutorization = this.toggleFormsAutorization.bind(this);
  }

  componentDidMount(){
    this.setState({ isComponentMounted: true });

    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        document.getElementById('accept_download').click()
        
        if(localStorage.getItem('access') === 'C'){
          Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-team/by-broker/' + localStorage.getItem('id'),
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            this.setState({realEstateTeam: response.data.realEstateTeam})
            this.setState({teamActive: true})
          })
          
          Axios.get(process.env.REACT_APP_API + '/api/auth/brokers/' + localStorage.getItem('id'),
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            this.setState({user: resp.data})
            this.setState({status: resp.data.status})
            this.setState({observacao: resp.data.workflow_observation})
            this.setState({premiumActive: resp.data.premium})
            this.setState({saldoPremium: resp.data.premium_dates.date_end})
            this.setState({capta: resp.data.p_count})
            this.setState({fastAcademy: resp.data.fast_academy})
            this.setState({about: resp.data.about ? resp.data.about : this.state.exempleText})
          })
        }
        if(localStorage.getItem('access') === 'I'){
          Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates/' + localStorage.getItem('id'),
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            this.setState({user: resp.data})

            this.setState({status: resp.data.status})
            this.setState({observacao: resp.data.workflow_observation})
            this.setState({premiumActive: resp.data.premium})
            this.setState({saldoPremium: resp.data.premium_dates.date_end})
            this.setState({capta: resp.data.p_count})
            this.setState({fastAcademy: resp.data.fast_academy})
            this.setState({about: resp.data.about ? resp.data.about : this.state.exempleText})
          })
        }

        if(localStorage.getItem('access') !== 'T' && localStorage.getItem('access') !== 'A'){
        Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
          type:localStorage.getItem('access'),
          user_id:localStorage.getItem('id')
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(res => {
          if(res.data.premium === 1) {
            this.setState({verifyPremium: true})
          } else {
            this.setState({verifyPremium: false})
          }
        })
      }else{
        this.setState({verifyPremium: true})
      }


      if(localStorage.getItem('access') === 'I'){
        Axios.get(process.env.REACT_APP_API + '/api/auth/real_estate_teams/by_real_estate/' + localStorage.getItem('id'),
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            console.log(response.data.realEstateTeams)

            for(var i = 0; response.data?.realEstateTeams?.length > i; i++){
              this.state.selectOptionsBrokerByRealEstate.push({
                value: response.data.realEstateTeams[i]?.broker.id,
                label: response.data.realEstateTeams[i]?.broker.name
              })
            }
          })
      }


        Axios.get(process.env.REACT_APP_API + '/api/auth/owners-select',
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          for(var i = 0; response.data?.length > i; i++){
            this.state.selectOptionsOwner.push({
              value: response.data[i].id,
              label: response.data[i].name
            })
          }
        })
        Axios.get(process.env.REACT_APP_API + '/api/auth/brokers-select',
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          for(var i = 0; response.data?.length > i; i++){
            this.state.selectOptionsBroker.push({
              value: response.data[i].id,
              label: response.data[i].name
            })
          }
          })
          Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions',
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            for(var i = 0; response.data?.length > i; i++){
              this.state.selectOptionsConstructions.push({
                value: response.data[i].id,
                label: response.data[i].name
              })
            }
          })
        Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-select',
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          for(var i = 0; response.data?.length > i; i++){
            this.state.selectOptionsRealEstate.push({
              value: response.data[i].id,
              label: response.data[i].name
            })
          }
        })

          if(localStorage.getItem('access') !== 'T' && localStorage.getItem('access') !== 'A'){
            Axios.post(process.env.REACT_APP_API + '/api/auth/get-user-props-pcount', {
              type: (this.state.teamActive) ? 'I' : localStorage.getItem('access'),
              user_id: (this.state.teamActive) ? this.state.realEstateTeam.real_estate_id : localStorage.getItem('id')
            }, 
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(resp => {
              for(var i = 0; i < resp.data.p_count; i++){
                this.state.p_count.push(i)
              }
              for(var i = 0; i < resp.data.imoveis; i++){
                this.state.imoveis.push(i)
              }
              for(var i = 0; i < (resp.data.p_count - resp.data.imoveis); i++){
                this.state.p_count_enabled.push(i)
              }
              this.setState(prevState => ({
                modal_full_properties: !prevState.modal_full_properties
              }));
              this.removeBodyCss();
            })
          }


          Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
          .then(response => {
            const states = []
            for(var i = 0; response.data.length > i; i++){
              states.push({
                value: response.data[i].sigla,
                label: response.data[i].nome
              })
            }
            this.setState({statesAddress: states})
          })

    })
    .catch(response =>{
      localStorage.removeItem("history_url")
      setTimeout(() => {
        window.open("/login", '_parent')
      }, 300);
    })
  }


  loadOptions = async (inputValue, callback) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_API + `/api/tag/${inputValue}`, {}, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}});
        const results = response.data;
        const options = results.map((item) => ({
            value: item.id,
            label: item.name,
        }));
        callback(options);
    } catch (error) {
        console.log(error);
    }
};

  handleCreate = (inputValue) => {

    const { selectedOption } = this.state;

    Axios.post(process.env.REACT_APP_API + `/api/tag/`, {name: inputValue},
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      setTimeout(() => {
        Axios.post(process.env.REACT_APP_API + `/api/tag/` + inputValue, {},
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(resp => {
          setTimeout(() => {
            resp.data.filter(tag => tag?.name === inputValue).map((tagAdd) => {
              this.setState({selectedOption: [...selectedOption, {label: tagAdd?.name, value: tagAdd?.id}]})
              this.state.tags.push(tagAdd?.id)
              })
          }, 500);
        })
      }, 500);
    })
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    for(var i = 0; i < selectedOption.length; i++){
      if(!this.state.tags.includes(selectedOption[i].value)){
        this.state.tags.push(selectedOption[i].value);
      }
    }
  };

  handleQuality = value => {
    if(this.state.cep !== '' && this.state.cep.length > 5){
      this.setState({ cepQuality: 10 });
    }else{
      this.setState({ cepQuality: 0 });
    }
    if(this.state.logradouro !== ''){
      this.setState({ logradouroQuality: 10 });
    }else{
      this.setState({ logradouroQuality: 0 });
    }
    if(this.state.number !== ''){
      this.setState({ numberQuality: 10 });
    }else{
      this.setState({ numberQuality: 0 });
    }
    if(this.state.bairro !== ''){
      this.setState({ bairroQuality: 10 });
    }else{
      this.setState({ bairroQuality: 0 });
    }
    if(this.state.pais !== ''){
      this.setState({ paisQuality: 10 });
    }else{
      this.setState({ paisQuality: 0 });
    }
    if(this.state.empreendimento !== ''){
      this.setState({ empreendimentoQuality: 10 });
    }else{
      this.setState({ empreendimentoQuality: 0 });
    }
    if(this.state.construcao !== ''){
      this.setState({ construcaoQuality: 10 });
    }else{
      this.setState({ construcaoQuality: 0 });
    }
    if(this.state.torres !== ''){
      this.setState({ torresQuality: 10 });
    }else{
      this.setState({ torresQuality: 0 });
    }
    if(this.state.andares !== ''){
      this.setState({ andaresQuality: 10 });
    }else{
      this.setState({ andaresQuality: 0 });
    }
    if(this.state.unidadesAndar !== ''){
      this.setState({ unidadesAndarQuality: 10 });
    }else{
      this.setState({ unidadesAndarQuality: 0 });
    }
    if(this.state.citySelected.value !== ''){
      this.setState({ estadosQuality: 10 });
    }else{
      this.setState({ estadosQuality: 0 });
    }
    if(this.state.stateSelected !== ''){
      this.setState({ cidadesQuality: 10 });
    }else{
      this.setState({ cidadesQuality: 0 });
    }
    this.setState({quality:
      (this.state.cidadesQuality
        + this.state.estadosQuality
        + this.state.cepQuality
        + this.state.logradouroQuality
        + this.state.numberQuality
        + this.state.bairroQuality
        + this.state.paisQuality
        + this.state.empreendimentoQuality
        + this.state.construcaoQuality
        + this.state.torresQuality
        + this.state.andaresQuality
        + this.state.unidadesAndarQuality) > 100 ?
          100 : (this.state.cidadesQuality
          + this.state.estadosQuality
          + this.state.cepQuality
          + this.state.logradouroQuality
          + this.state.numberQuality
          + this.state.bairroQuality
          + this.state.paisQuality
          + this.state.empreendimentoQuality
          + this.state.construcaoQuality
          + this.state.torresQuality
          + this.state.andaresQuality
          + this.state.unidadesAndarQuality)
    })
  };

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }

  tog_full_properties() {
    if(this.state.imoveis.length === this.state.p_count.length){
      setTimeout(() => {
        window.open('/dashboard', '_parent')
      }, 300);
    }else{
      this.setState(prevState => ({
        modal_full_properties: !prevState.modal_full_properties
      }));
      this.setState({ modal_center: true }) 
      this.removeBodyCss();
    }
  }

    tog_forms_autorization() {
    this.setState(prevState => ({
      modal_forms_autorization: !prevState.modal_forms_autorization
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
        if (tab >= 1 && tab <= 7) {
            this.setState({
                activeTab: tab
            });
        }
    }
  }

  toggleTabProgress(tab) {
      if (this.state.activeTabProgress !== tab) {
          if (tab >= 1 && tab <= 7) {
              this.setState({
                  activeTabProgress: tab
              });

              if (tab === 1) { this.setState({ progressValue: 10 }) }
              if (tab === 2) { this.setState({ progressValue: 25 }) }
              if (tab === 3) { this.setState({ progressValue: 40 }) }
              if (tab === 4) { this.setState({ progressValue: 55 }) }
              if (tab === 5) { this.setState({ progressValue: 70 }) }
              if (tab === 6) { this.setState({ progressValue: 85 }) }
              if (tab === 7) { this.setState({ progressValue: 100 }) }
          }
      }
  }

  toggleFormsAutorization() {
    this.setState(prevState => ({
      openFormsAutorization: !prevState.openFormsAutorization
    }));
  }

  
  // for(let i = 0; i < this.state.selectedFiles.length; i++){
                                                
                                                
  //   data1.append('photo[]', {
  //     file: this.state.selectedFiles[i],
  //     position:'0'
  //   })
  // }
  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedFiles: files }, () => {
      this.setState({photosArray: this.state.selectedFiles.map((file, index) => ({
        file: file,
        position: index
      }))});
    });
    
    this.setState({dragPhotos: (
      <Draggable onPosChange={() => {
        this.setState({photosArray : []})
        setTimeout(() => {
          for(var i = 0; i < this.state.selectedFiles.length; i++){
            this.state.photosArray.push({
              file: this.state.selectedFiles.filter(image => image.name === document.querySelectorAll('.image-send')[i].alt)[0],
              position: i
            })
          }
        }, 500);
        }}>
      {this.state.selectedFiles.map((f, i) => 
          <Col key={i + "-file"} id={f.name} >
          <Card
            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
          >
            <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="120"
                      className="avatar-sm rounded bg-light image-send"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link
                      to="#"
                      className="text-muted fw-bold"
                    >
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                  <i className="ri-close-fill" style={{
                      position:'absolute',
                      top:'10px',
                      fontSize:'20px',
                      backgroundColor:'#da2c3e',
                      color:'#fff',
                      right:'10px',
                      width: '15px',
                      height: '25px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }} onClick={() => {
                      this.removeImage(i)
                    }}></i>
                </Row>
            </div>
          </Card>
          </Col>
      )}
      </Draggable>
    )})
  };

  removeImage(index) {
    const { selectedFiles } = this.state;
    selectedFiles.splice(index, 1);
    this.setState({ selectedFiles });
    this.setState({dragPhotos: null})
    setTimeout(() => {
      this.handleAcceptedFiles(this.state.selectedFiles);      
    }, 300);
  }

  handleAcceptedAuth = files => {
    files.map(file => {
      let fileType;
      if (file.type === 'application/pdf') {
        fileType = 'pdf';
      } else {
        fileType = file.type;
      }
  
      return Object.assign(file, {
        fileType,
        formattedSize: this.formatBytes(file.size)
      });
    });
  
    this.setState({ selectedAuth: files });
  };

  handleAcceptedDocs = files => {
    files.map(file => {
      let fileType;
      if (file.type === 'application/pdf') {
        fileType = 'pdf';
      } else {
        fileType = file.type;
      }
  
      return Object.assign(file, {
        fileType,
        formattedSize: this.formatBytes(file.size)
      });
    });
  
    this.setState({ selectedDocs: files });
  };
  

  // handleAcceptedDocs = files => {
  //   files.map(file =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: this.formatBytes(file.size)
  //     })
  //   );

  //   this.setState({ selectedDocs: files });
  // };
  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };


  optionchange(event) {
		var count = event.target.value.length;
		if (count > 0) {
			this.setState({ disbadge: true });
		} else {
			this.setState({ disbadge: false });
		}
		if (count > 99) {
			this.setState({ advanceclass: "badgecountextra" });
		} else {
			this.setState({ advanceclass: "badgecount" });
		}
		this.setState({ optioncount: event.target.value.length });
	}

  handleSelectState = stateSelected => {
		this.setState({ stateSelected });
    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + stateSelected.value + '/municipios')
    .then(response => {
      const city = []
      for(var i = 0; response.data.length > i; i++){
        city.push({
          label: response.data[i].nome,
          value: response.data[i].nome
        })
      }
      this.setState({cityAddress: city})
    })
	};

  consultaCep = cepValue => {
    const cep = cepValue
      if (cep?.length !== 8) {
      this.setState({logradouro: ''});
      this.setState({bairro: ''});
      this.setState({stateSelected: ''})
      this.setState({citySelected: ''});
      }else{
        fetch('https://viacep.com.br/ws/' + cep + '/json/')
        .then((res) => res.json())
        .then((data) => {
            if (data.erro === true) {
              this.setState({logradouro: ''});
              this.setState({bairro: ''});
              this.setState({stateSelected: ''})
              this.setState({citySelected: ''});
            } else {
              this.setState({logradouro: data.logradouro});
              this.setState({bairro: data.bairro});
              this.setState({stateSelected: {
                value:data.uf,
                label:data.uf
              }})
              this.setState({citySelected: {
                value:data.localidade,
                label:data.localidade
              }});
            }
          })
      }  
  }


  render() {

    const { dragPhotos, selectOptionsBrokerByRealEstate, statesAddress, cityAddress, citySelected, stateSelected, tagsOptions, tags, selectedOption, selectedConstruction, selectedOwner, selectedRealEstate, selectedBroker, selectOptionsBroker, selectOptionsConstructions, selectOptionsRealEstate, selectOptionsOwner, user} = this.state;

    const Phone = props => (
      <InputMask
        mask="(99) 99999-9999"
        value={props.value}
        className="form-control input-color"
        placeholder="(99) 99999-9999"
        style={{height:'37px'}}
        onChange={props.onChange}
      >
        {inputProps => (
          <MaterialInput {...inputProps} type="tel" disableUnderline />
        )}
      </InputMask>
    );

    return (
        <div className="page-content" id="addImovel">
        <Container fluid={true}>
          <Breadcrumbs title="Novo imóvel" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Preencha o maior número de informações possível.</h4>

                  <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 1 })}
                            onClick={() => {
                              if(this.state.acceptContract) {
                                this.setState({ activeTabProgress: 1 })
                              }
                            }}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">Termos</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 2 })}
                            onClick={() => {
                              if(this.state.activeTabProgress === 2 || this.state.acceptContract){
                                this.setState({ activeTabProgress: 2 })
                                if (this.state.isComponentMounted) {
                                  if(localStorage.getItem('access') !== 'A'){
                                    if(this.state.tipoImovel === '' 
                                    || this.state.title === '' 
                                    || this.state.descricao === ''){
                                      window.scrollTo(0,0)
                                      this.validateRef.current.handleSubmit()
                                    }
                                  }
                                }
                              } else if(!this.state.acceptContract) {
                                this.setState({checkInvalidFeedback : 'Você precisa aceitar os termos para continuar.'})
                              }
                            }}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">Sobre o imóvel</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 3 })}
                            onClick={() => {
                                if(this.state.acceptContract && localStorage.getItem('access') !== 'A') {
                                  if(this.state.tipoImovel !== '' && this.state.title !== '' && this.state.descricao !== '') {
                                    this.setState({ activeTabProgress: 3 })
                                  } else {
                                    this.validateRef.current.handleSubmit()
                                    window.scrollTo(0,0)
                                    this.setState({checkInvalidFeedback : 'Você precisa aceitar os termos para continuar.'})
                                  }
                                } else if(!this.state.acceptContract) {
                                  this.setState({checkInvalidFeedback : 'Você precisa aceitar os termos para continuar.'})
                                }
                              }}
                            >
                            <span className="step-number">03</span>
                            <span className="step-title">Condições</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 4 })}
                            onClick={() => {
                              if(this.state.acceptContract && localStorage.getItem('access') !== 'A') {
                                if(this.state.tipoImovel !== '' && this.state.title !== '' && this.state.descricao !== '') {
                                  if(this.state.activeTabProgress === 4){
                                    if(this.state.cep === ''
                                    || this.state.logradouro === ''
                                    || this.state.numero === ''
                                    || this.state.bairro === ''
                                    || citySelected.value === ''
                                    || stateSelected === ''
                                    || this.state.country === ''){
                                      window.scrollTo(0,0)
                                      this.validate2Ref.current.handleSubmit()
                                    }else{
                                      if(localStorage.getItem('add_property_category') === '12' || localStorage.getItem('add_property_category') === '34'){
                                        if(this.state.unidade === '' || this.state.andar === ''){
                                          window.scrollTo(0,0)
                                          this.validate2Ref.current.handleSubmit()
                                        }else{
                                          this.setState({ activeTabProgress: 4 })
                                        }
                                      }else{
                                        this.setState({ activeTabProgress: 4 })
                                      }
                                    }
                                  } else{
                                    this.setState({ activeTabProgress: 4 }) 
                                  }
                                } else {
                                  window.scrollTo(0,0)
                                  this.validateRef.current.handleSubmit()
                                  this.setState({checkInvalidFeedback : 'Preencha todas as informações do imóvel para continuar.'})
                                }
                              } else if(!this.state.acceptContract) {
                                this.setState({checkInvalidFeedback : 'Você precisa aceitar os termos para continuar.'})
                              }
                            }}
                          >
                            <span className="step-number">04</span>
                            <span className="step-title">Endereço</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 5 })}
                            onClick={() => {
                              if(this.state.activeTabProgress === 6) {
                                this.setState({ activeTabProgress: 5 })
                              }
                              else if(this.state.activeTabProgress === 4 
                              && this.state.cep !== '' 
                              && this.state.logradouro !== '' 
                              && this.state.numero !== '' 
                              && this.state.bairro !== '' 
                              && citySelected.value !== '' 
                              && stateSelected !== '' 
                              && this.state.country !== ''){
                                this.setState({ activeTabProgress: 5 })
                              } else {
                                window.scrollTo(0,0)
                                this.setState({checkInvalidFeedback : 'Preencha todas as informações do endereço para continuar.'})
                              }
                            }}
                          >
                            <span className="step-number">05</span>
                            <span className="step-title">Características</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 6 })}
                            onClick={() => {
                              if(this.state.activeTabProgress === 7) {
                                this.setState({ activeTabProgress: 6 })
                              }
                              else if(this.state.activeTabProgress === 5 
                                && this.state.cep !== '' 
                                && this.state.logradouro !== '' 
                                && this.state.numero !== '' 
                                && this.state.bairro !== '' 
                                && citySelected.value !== '' 
                                && stateSelected !== '' 
                                && this.state.country !== ''){
                                this.setState({ activeTabProgress: 6 })
                              } else {
                                window.scrollTo(0,0)
                                this.setState({checkInvalidFeedback : 'Preencha todas as características para continuar.'})
                              }
                            }}
                          >
                            <span className="step-number">06</span>
                            <span className="step-title">Mídias</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 7 })}
                            onClick={() => {
                              if(this.state.activeTabProgress === 6 
                                && this.state.cep !== '' 
                                && this.state.logradouro !== '' 
                                && this.state.numero !== '' 
                                && this.state.bairro !== '' 
                                && citySelected.value !== '' 
                                && stateSelected !== '' 
                                && this.state.country !== ''){
                                this.setState({ activeTabProgress: 7 })
                              } else {
                                window.scrollTo(0,0)
                                this.setState({checkInvalidFeedback : 'Preencha todas as informações das mídias para continuar.'})
                              }
                            }}
                          >
                            <span className="step-number">07</span>
                            <span className="step-title">Sucesso!</span>
                          </NavLink>
                        </NavItem>
                      </ul>


                      <div id="bar" className="mt-4">
                          <Progress color="success" striped animated value={this.state.progressValue} />
                      </div>

                      <div style={{marginBottom: '2rem'}}>
                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                            {this.state.activeTabProgress !== 1 &&
                              <li className={this.state.activeTabProgress === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTabProgress(this.state.activeTabProgress - 1); }}>Anterior</Link></li>
                            }
                            {this.state.activeTabProgress !== 7 &&
                              <li className={this.state.activeTabProgress === 7 ? "next disabled" : "next"}><Link to="#" onClick={() => { 
                                if(this.state.activeTabProgress === 1){
                                  if(this.state.acceptContract){
                                    window.scrollTo(0,0)
                                    this.toggleTabProgress(this.state.activeTabProgress + 1)
                                  }else{
                                    window.scrollTo(0,0)
                                    this.setState({checkInvalidFeedback : 'Você precisa aceitar os termos para continuar.'})
                                  }
                                }else if(this.state.activeTabProgress === 2){
                                  if (this.state.isComponentMounted) {
                                    if(localStorage.getItem('access') !== 'A'){
                                      if(this.state.tipoImovel === '' 
                                      || this.state.title === '' 
                                      || this.state.descricao === ''){
                                        window.scrollTo(0,0)
                                        this.validateRef.current.handleSubmit()
                                      }else{
                                        window.scrollTo(0,0)
                                        this.toggleTabProgress(this.state.activeTabProgress + 1)
                                      }
                                    }else{
                                      window.scrollTo(0,0)
                                      this.toggleTabProgress(this.state.activeTabProgress + 1)
                                    }
                                  }
                                }else if(this.state.activeTabProgress === 3){
                                  if (this.state.isComponentMounted) {
                                    window.scrollTo(0,0)
                                    this.toggleTabProgress(this.state.activeTabProgress + 1)
                                  }
                                }else if(this.state.activeTabProgress === 4){
                                  if (this.state.isComponentMounted) {
                                    if(this.state.cep === ''
                                    || this.state.logradouro === ''
                                    || this.state.numero === ''
                                    || this.state.bairro === ''
                                    || citySelected.value === ''
                                    || stateSelected === ''
                                    || this.state.country === ''){
                                      window.scrollTo(0,0)
                                      this.validate2Ref.current.handleSubmit()
                                    }else{
                                      if(localStorage.getItem('add_property_category') === '12' || localStorage.getItem('add_property_category') === '34'){
                                        if(this.state.unidade === '' || this.state.andar === ''){
                                          window.scrollTo(0,0)
                                          this.validate2Ref.current.handleSubmit()
                                        }else{
                                          window.scrollTo(0,0)
                                          this.toggleTabProgress(this.state.activeTabProgress + 1)
                                        }
                                      }
                                      window.scrollTo(0,0)
                                      this.toggleTabProgress(this.state.activeTabProgress + 1)
                                    }
                                  }
                                }else{
                                  window.scrollTo(0,0)
                                  this.toggleTabProgress(this.state.activeTabProgress + 1)
                                }
                              }}>Próximo</Link></li>
                            }
                        </ul>
                      </div>

                      <TabContent activeTab={this.state.activeTabProgress} className="twitter-bs-wizard-tab-content">
                        <TabPane tabId={1}>
                              <div>
                                <AvForm className="cardBody needs-validation">
                                  <Container style={{paddingTop:'10px', paddingBottom:'10px'}}>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Termos e condições de uso</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <Card style={{padding: '15px', maxHeight:'360px', overflowY:'auto'}}>
                                          <p><b>Termos de uso para cadastro de imóveis em uma plataforma de marketplace</b></p>
                                          <p>Aceitação dos termos de uso</p>
                                          <p>Ao cadastrar um imóvel em nossa plataforma de marketplace, você concorda com estes termos de uso e todas as políticas e diretrizes que regem nosso site.</p>
                                          <p>Cadastro de imóveis</p>
                                          <p>Para cadastrar um imóvel em nossa plataforma, você deve ser um corretor de imóveis registrado e ter permissão do proprietário do imóvel para anunciar a venda. O imóvel deve estar em conformidade com todas as leis e regulamentações aplicáveis.</p>
                                          <p>Comissão</p>
                                          <p>A comissão padrão para a venda de um imóvel cadastrado em nossa plataforma é de 6% do valor total da venda. Esta comissão será dividida meio a meio entre o corretor que cadastrou o imóvel e o corretor que efetuou a venda.</p>
                                          <p>Responsabilidades</p>
                                          <p>Você é responsável por todas as informações fornecidas ao cadastrar um imóvel em nossa plataforma. Você também é responsável por garantir que todas as informações fornecidas sejam precisas e verdadeiras. Nós nos reservamos o direito de remover imóveis de nossa plataforma se as informações fornecidas forem imprecisas ou enganosas.</p>
                                          <p>Uso dos dados</p>
                                          <p>Ao cadastrar um imóvel em nossa plataforma, você nos autoriza a usar as informações fornecidas para promover e divulgar o imóvel em nossa plataforma. Você também nos autoriza a compartilhar as informações com outros corretores de imóveis que participam de nosso marketplace.</p>
                                          <p>Alterações nos termos de uso</p>
                                          <p>Nós nos reservamos o direito de alterar estes termos de uso a qualquer momento. Todas as alterações serão publicadas em nosso site e você será notificado por e-mail.</p>
                                          <p>Rescisão</p>
                                          <p>Nós nos reservamos o direito de rescindir seu acesso à nossa plataforma a qualquer momento, sem aviso prévio, se violar estes termos de uso ou qualquer política ou diretriz relacionada.</p>
                                          <p>Lei aplicável</p>
                                          <p>Estes termos de uso serão regidos pelas leis do Brasil. Qualquer litígio relacionado a estes termos de uso será resolvido exclusivamente nos tribunais brasileiros.</p>
                                        </Card>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="form-check mb-3">
                                          <input className="form-check-input" type="checkbox" value={this.state.acceptContract} id="termos" onClick={(e) => this.setState({acceptContract : e.target.checked})} />
                                          <label className="form-check-label" htmlFor="termos">
                                            Declaro que li e estou de acordo com os termos acima.
                                          </label>
                                          {(this.state.checkInvalidFeedback && !this.state.acceptContract) &&
                                            <p style={{color:'red'}}>{this.state.checkInvalidFeedback}</p>
                                          }
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </AvForm>
                              </div>
                          </TabPane>
                          <TabPane tabId={2}>
                              <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                              {(localStorage.getItem('access') === 'I' && selectOptionsBrokerByRealEstate.length > 0) &&
                                 <Container>
                                 <Row style={{margin:'10px 0'}}>
                                   <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre a captação</h5></Col>
                                 </Row>
                                 <Row>
                                  <Col md={4}>
                                    <Label className="form-label">Corretor captador</Label>
                                    <Select
                                      value={selectedBroker}
                                      placeholder="Selecione"
                                      noOptionsMessage={(inputValue) => `Sem resultados`}
                                      onChange={(e) => this.setState({ selectedBroker: e })}
                                      options={selectOptionsBrokerByRealEstate}
                                      classNamePrefix="select2-selection"
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Label htmlFor="nomeProprietario" className="col-md-12 col-form-label">Nome do proprietário</Label>
                                    <Input type="text" placeholder="Nome do proprietário" value={this.state.nomeProprietario}  onChange={(e) => this.setState({nomeProprietario: e.target.value})}/>
                                  </Col>
                                 </Row>
                               </Container>
                              }
                              {localStorage.getItem('access') === 'C' &&
                                <Container>
                                  <Row style={{margin:'10px 0'}}>
                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre a captação</h5></Col>
                                  </Row>
                                  <Row>
                                  <Col md={4}>
                                    <Label htmlFor="nomeProprietario" className="col-md-12 col-form-label">Nome do proprietário</Label>
                                    <Input type="text" placeholder="Nome do proprietário" value={this.state.nomeProprietario}  onChange={(e) => this.setState({nomeProprietario: e.target.value})}/>
                                  </Col>
                                  </Row>
                                </Container>
                              }
                              {localStorage.getItem('access') === 'A' &&
                                <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o proprietário</h5></Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                          <Label className="form-label">Corretor captador</Label>
                                          <Select
                                            value={selectedBroker}
                                            placeholder="Selecione"
                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                            onChange={(e) => this.setState({ selectedBroker: e })}
                                            options={selectOptionsBroker}
                                            classNamePrefix="select2-selection"
                                          />
                                        </Col>
                                        <Col md={4}>
                                          <Label className="form-label">Imobiliária captadora</Label>
                                          <Select
                                            value={selectedRealEstate}
                                            placeholder="Selecione"
                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                            onChange={(e) => this.setState({ selectedRealEstate: e })}
                                            options={selectOptionsRealEstate}
                                            classNamePrefix="select2-selection"
                                          />
                                        </Col>
                                        <Col md={4}>
                                        <Label className="form-label">Construtora</Label>
                                          <Select
                                            value={selectedConstruction}
                                            placeholder="Selecione"
                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                            onChange={(e) => this.setState({ selectedConstruction: e })}
                                            options={selectOptionsConstructions}
                                            classNamePrefix="select2-selection"
                                          />
                                      </Col>
                                        <Col md={4}>
                                          <Label className="form-label">Proprietário</Label>
                                          <Select
                                            value={selectedOwner}
                                            placeholder="Selecione"
                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                            onChange={(e) => this.setState({ selectedOwner: e })}
                                            options={selectOptionsOwner}
                                            classNamePrefix="select2-selection"
                                          />
                                        </Col>
                                        <Col md={4}>
                                          <Label htmlFor="nomeProprietario" className="col-md-12 col-form-label">Nome do proprietário</Label>
                                          <Input type="text" placeholder="Apenas para proprietários não cadastrados" value={this.state.nomeProprietario}  onChange={(e) => this.setState({nomeProprietario: e.target.value})}/>
                                        </Col>
                                        <Col md={4}>
                                          <Label htmlFor="tipoContrato" className="col-md-12 col-form-label">Contrato</Label>
                                          {/* <Input type="text" defaultValue="placeholder" id="tipoContrato" /> */}
                                          <select className="form-control" value={this.state.tipoContrato} onChange={(e) => this.setState({tipoContrato: e.target.value})}>
                                            <option value="0">Selecione uma opção</option>
                                            <option value="FS Prop">Fast Sale Proprietário</option>
                                            <option value="FS Corr">Fast Sale Corretor</option>
                                            <option value="FS Imob (antigo)">Fast Sale Imobiliária (antigo)</option>
                                            <option value="FS Imob">Fast Sale Imobiliária</option>
                                            <option value="FS Parc">Parceria 50 / 50</option>
                                            <option value="FS Parc (sc)">Parceria 0 / 100</option>
                                          </select>
                                        </Col>
                                        <Col md={4}>
                                          <Label htmlFor="comissao" className="col-md-12 col-form-label">Comissão</Label>
                                          <Input type="number" placeholder="%" id="comissao" value={this.state.comissao}  onChange={(e) => this.setState({comissao: e.target.value})}/>
                                        </Col>
                                    </Row>
                                  </Container>
                                  }
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o imóvel</h5></Col>
                                    </Row>
                                    <Row>
                                      {localStorage.getItem('access') !== 'A' &&
                                        <Col md={3}>
                                          <Label htmlFor="comissao" className="col-md-12 col-form-label">Comissão </Label>
                                          <Input type="number" placeholder="%" id="comissao" value={this.state.comissao}  onChange={(e) => this.setState({comissao: e.target.value})}/>
                                          {localStorage.getItem('access') !== 'T' && <small>Baseado na porcentagem da comissão acima, o corretor captador (você) ficará com 50% no ato da venda. Os outros 50% são destinados ao corretor parceiro que vender o imóvel.</small>}
                                        </Col>
                                      }
                                      <Col md={3}>
                                        <Label htmlFor="tipoImovel" className="col-md-12 col-form-label">Tipo do imóvel</Label>
                                        <AvField type="select" name="select" id="select" className="form-control"
                                        value={this.state.tipoImovel}
                                        validate={{ required: { value: true } }}
                                        errorMessage="Campo obrigatório."
                                        onChange={(e) => {
                                          this.setState({tipoImovel: e.target.value})
                                          localStorage.setItem('add_property_category', e.target.value)
                                          }}>
                                          <option value="36">Escolha uma opção</option>
                                          <option value="12">Apartamento</option>
                                          <option value="14">Casa</option>
                                          <option value="34">Comercial</option>
                                          <option value="22">Terreno</option>
                                          <option value="35">Outros (Sítio, Fazenda, Área Logística, ...)</option>
                                        </AvField>  
                                      </Col>
                                      <Col md={6}>
                                        <Label htmlFor="tituloImovel" className="col-md-12 col-form-label">Título do imóvel</Label>
                                        <AvField
                                          name="alloptions"
                                          placeholder="Título do imóvel"
                                          type="text"
                                          maxLength="100"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.title}
                                          onChange={(e) => {
                                            this.optionchange(e)
                                            this.setState({title: e.target.value})
                                          }}
                                          id="alloptions"
                                        />
                                        {this.state.disbadge ? (
                                          <span className={this.state.advanceclass}>
                                            <span className="badge badge-success">Você digitou {this.state.optioncount} de 100 caracteres permitidos.</span>
                                          </span>
                                        ) : null}
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="matriculaImovel" className="col-md-12 col-form-label">Matrícula</Label>
                                        <Input type="text" placeholder="Matrícula" id="matriculaImovel" value={this.state.matricula} onChange={(e) => this.setState({matricula: e.target.value})} />
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="registroImovel" className="col-md-12 col-form-label">Registro Imob.</Label>
                                        <Input type="text" placeholder="Registro imobiliário" id="registroImovel" value={this.state.registro} onChange={(e) => this.setState({registro: e.target.value})} />
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor="condominio" className="col-md-12 col-form-label">Condomínio</Label>
                                        <CurrencyInput defaultValue={this.state.condominioValor} onChange={(e) => this.setState({condominioValor: e.target.value})}/>
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor="iptu" className="col-md-12 col-form-label">IPTU</Label>
                                        <CurrencyInput defaultValue={this.state.iptuValor} onChange={(e) => this.setState({iptuValor: e.target.value})}/>
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor="marinha" className="col-md-12 col-form-label">Tx. marinha</Label>
                                        <CurrencyInput defaultValue={this.state.marinhaValor} onChange={(e) => this.setState({marinhaValor: e.target.value})}/>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Medidas</h5></Col>
                                    </Row>
                                    <Row>
                                      {
                                        this.state.tipoImovel !== '12' ? 
                                          <Col md={4}>
                                            <Label htmlFor="areaTerreno" className="col-md-12 col-form-label">Área do terreno</Label>
                                              <Input type="number" placeholder="em m²" id="areaTerreno"  value={this.state.areaTerreno} onChange={(e) => this.setState({areaTerreno: e.target.value})}/>
                                          </Col>
                                        :
                                          <></>
                                      }
                                      {
                                        this.state.tipoImovel === '22' ?
                                        <></>
                                        :
                                        <>
                                          <Col md={4}>
                                            <Label htmlFor="areaConstruida" className="col-md-12 col-form-label">Área construída</Label>
                                              <Input type="number" placeholder="em m²" id="areaConstruida"  value={this.state.areaConstruida} onChange={(e) => this.setState({areaConstruida: e.target.value})}/>
                                          </Col>
                                          <Col md={4}>
                                            <Label htmlFor="areaPrivativa" className="col-md-12 col-form-label">Área privativa</Label>
                                            <Input type="number" placeholder="em m²" id="areaPrivativa"  value={this.state.areaPrivativa} onChange={(e) => this.setState({areaPrivativa: e.target.value})}/>
                                          </Col>
                                        </>
                                      }
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Descrição do imóvel</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <Label htmlFor="descricaoImovel" className="col-md-12 col-form-label">Descrição</Label>
                                        <AvField
                                          name="descricaoImovel"
                                          placeholder="Descreva seu imóvel com o máximo de informações e detalhes que acredite ser importantes."
                                          type="textarea"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          rows="10"
                                          validate={{ required: { value: true } }}
                                          value={this.state.descricao}
                                          onChange={(e) => this.setState({descricao: e.target.value})}
                                          id="descricaoImovel"
                                        />
                                      </Col>
                                    </Row>
                                  </Container>
                                  {/* <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Responsável pela visita</h5></Col>
                                    </Row>
                                  </Container> */}
                                  {/* <button type="submit" id="form1" style={{visibility:'hidden', height:'2px'}} onClick={() => {}}></button> */}
                              </AvForm>
                          </TabPane>
                          <TabPane tabId={3}>
                              <div>
                                  <Form className="cardBody">
                                    <Container>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Valor</h5></Col>
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                          <Label htmlFor="iptu" className="col-md-12 col-form-label">Valor</Label>
                                          <CurrencyInput defaultValue={this.state.valor} onChange={(e) => this.setState({valor: e.target.value})} />
                                        </Col>
                                        {
                                          localStorage.getItem('access') === 'A' ? 
                                            <Col md={4}>
                                              <Label htmlFor="marinha" className="col-md-12 col-form-label">Valor promocional</Label>
                                              <CurrencyInput defaultValue={this.state.valorPromocional} onChange={(e) => this.setState({valorPromocional: e.target.value})}/>
                                            </Col> 
                                            : 
                                            <></>
                                        }
                                      </Row>
                                    </Container>
                                    <Container>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Condições</h5></Col>
                                      </Row>
                                      <Row style={{gap:'10px', paddingBottom:'15px'}}>
                                        {/* <Col sm={6} lg={3}>
                                          <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="avista" />
                                          <Label className="form-check-label" htmlFor="avista">
                                              À vista?
                                          </Label>
                                        </Col> */}
                                        <Col sm={6} lg={3}>
                                          <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="financiamento"
                                          value={this.state.financiamento} onClick={(e) => this.setState({financiamento : e.target.checked})}
                                          />
                                          <Label className="form-check-label" htmlFor="financiamento">
                                              Aceita financiamento?
                                          </Label>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                          <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox"id="permutaImovel"
                                          value={this.state.permutaImovel} onClick={(e) => this.setState({permutaImovel : e.target.checked})}
                                          />
                                          <Label className="form-check-label" htmlFor="permutaImovel">
                                              Aceita permuta imóvel?
                                          </Label>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                          <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="permutaAutomovel"
                                          value={this.state.permutaAutomovel} onClick={(e) => this.setState({permutaAutomovel : e.target.checked})}
                                          />
                                          <Label className="form-check-label" htmlFor="permutaAutomovel">
                                              Aceita permuta automóvel?
                                          </Label>
                                        </Col>
                                        {/* <Col md={3}>
                                          <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="permutaObjeto" />
                                          <Label className="form-check-label" htmlFor="permutaObjeto">
                                              Aceita permuta objeto?
                                          </Label>
                                        </Col> */}
                                      </Row>
                                    </Container>
                                  </Form>
                              </div>
                          </TabPane>
                          <TabPane tabId={4}>
                              <div className="row justify-content-center">
                                  <AvForm className="cardBody" ref={this.validate2Ref}>
                                  {/* <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Qualidade do endereço</h5></Col>
                                      <Col>
                                          
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="col-auto">
                                        <ReactSpeedometer 
                                          minValue="0"
                                          maxValue="100"
                                          startColor="#da2c3e"
                                          endColor="#3E5E3F"
                                          maxSegmentLabels={5}
                                          segments={1000}
                                          value={this.state.quality}
                                        />
                                      </Col>
                                      <Col style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                                        <div style={{marginBottom:'10px'}}>
                                          <h3 style={{fontWeight:'600', fontSize:'14px'}}>Não se preocupe, os dados estão seguros!</h3>
                                        </div>  
                                          <li>Serão divulgados apenas os dados: cidade e localização aproximada do imóvel.</li>
                                          <li>O restante dos dados serão divulgados após o aceite do termo de parceria.</li>
                                          <li>A unidade do imóvel (no caso de apartamento) só é divulgado mediante proposta oficializada.</li>

                                          <Card style={{marginTop: '10px', padding: '5px', backgroundColor:'#3E5E3F'}}>
                                            <Row>
                                              <Col className="col-auto">
                                                <i className="ri-error-warning-line" style={{fontSize:'30px', color:'#fff'}}></i>
                                              </Col>
                                              <Col style={{display:'flex', alignItems:'center'}}>
                                                <p style={{color:'#fff'}}>A qualidade do seu cadastro interfere na performance do seu imóvel na Fs e nos portais de anúncio integrados.</p>
                                              </Col>
                                            </Row>
                                          </Card>
                                      </Col>
                                    </Row>
                                  </Container> */}
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Endereço</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Label htmlFor="cep" className="col-md-12 col-form-label">Cep</Label>
                                        <AvField
                                          name="cep"
                                          placeholder="Cep"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.cep}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.consultaCep(e.target.value.replace('-', '').replace('.', ''))
                                            this.setState({cep: e.target.value})
                                            this.handleQuality(e)
                                          }}
                                          id="cep"
                                        />
                                      </Col>
                                      <Col md={6}>
                                        <Label htmlFor="rua" className="col-md-12 col-form-label">Rua</Label>
                                        <AvField
                                          name="rua"
                                          placeholder="Rua, Avenida..."
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.logradouro}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({logradouro: e.target.value})
                                            this.handleQuality(e)
                                          }}
                                          id="rua"
                                        />
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor="numero" className="col-md-12 col-form-label">Número</Label>
                                        <AvField
                                          name="numero"
                                          placeholder="Número"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.numero}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({numero: e.target.value})
                                            this.handleQuality(e)
                                          }}
                                          id="numero"
                                        />
                                      </Col>
                                      <Col md={6}>
                                        <Label htmlFor="bairro" className="col-md-12 col-form-label">Bairro</Label>
                                        <AvField
                                          name="bairro"
                                          placeholder="Bairro"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.bairro}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({bairro: e.target.value})
                                            this.handleQuality(e)
                                          }}
                                          id="bairro"
                                        />
                                      </Col>
                                      <Col md={6}>
                                        <Label htmlFor="complemento" className="col-md-12 col-form-label">Complemento</Label>
                                          <Input type="text" placeholder="Complemento" id="complemento" value={this.state.complemento} onChange={(e) => this.setState({complemento: e.target.value})} />
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="estado" className="col-md-12 col-form-label">Estado</Label>
                                        <Select
                                          value={stateSelected}
                                          placeholder="Selecione"
                                          noOptionsMessage={(inputValue) => `Sem resultados`}
                                          onChange={this.handleSelectState}
                                          options={statesAddress}
                                          classNamePrefix="select2-selection"
                                        />
                                          {/* <Input type="text" placeholder="Estado" id="estado" /> */}
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="cidade" className="col-md-12 col-form-label">Cidade</Label>
                                        <Select
                                          value={citySelected}
                                          placeholder="Selecione"
                                          noOptionsMessage={(inputValue) => `Sem resultados`}
                                          onChange={(e) => {
                                            this.setState({ citySelected: e})
                                            this.handleQuality(e)
                                          }}
                                          options={cityAddress}
                                          classNamePrefix="select2-selection"
                                        />
                                          {/* <Input type="text" placeholder="Cidade" id="cidade" /> */}
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="pais" className="col-md-12 col-form-label">País</Label>
                                        <AvField
                                          name="pais"
                                          placeholder="País"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.pais}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({pais: e.target.value})
                                            this.handleQuality(e)
                                          }}
                                          id="pais"
                                        />
                                      </Col>
                                      <Col md={6}>
                                        <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Nome do Empreendimento <small>(Apenas usuários premium podem visualizar essa informação)</small></Label>
                                          <Input type="text" placeholder="Nome do emprendimento" id="nomeEmpreendimento"  value={this.state.empreendimento} onChange={(e) => {
                                            this.setState({empreendimento: e.target.value})
                                            this.handleQuality(e)
                                            }}/>
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="unidade" className="col-md-12 col-form-label">Unidade <small>(nunca é divulgado)</small></Label>
                                        {(localStorage.getItem('add_property_category') === '12' || localStorage.getItem('add_property_category') === '34') ?
                                          <AvField
                                          name="unidade"
                                          placeholder="Unidade apto."
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.unidade}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({unidade: e.target.value})
                                          }}
                                          id="unidade"
                                        />
                                        :
                                        <Input type="text" placeholder="Unidade apto." id="unidade" value={this.state.unidade} onChange={(e) => this.setState({unidade: e.target.value})} />
                                        }
                                      </Col>
                                      <Col md={3}>
                                      <Label htmlFor="unidade" className="col-md-12 col-form-label">Andar <small>(nunca é divulgado)</small></Label>
                                      {(localStorage.getItem('add_property_category') === '12' || localStorage.getItem('add_property_category') === '34') ?
                                          <AvField
                                          name="andar"
                                          placeholder="Andar apto."
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.andar}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({andar: e.target.value})
                                          }}
                                          id="andar"
                                        />
                                        :
                                        <Input type="text" placeholder="Andar apto." id="andar" value={this.state.andar} onChange={(e) => this.setState({andar: e.target.value})} />
                                        }
                                      </Col>
                                    </Row>
                                  </Container>
                                  {/* <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o empreendimento</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={8}>
                                        <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Nome do Empreendimento</Label>
                                          <Input type="text" placeholder="Nome do emprendimento" id="nomeEmpreendimento"  value={this.state.empreendimento} onChange={(e) => {
                                            this.setState({empreendimento: e.target.value})
                                            this.handleQuality(e)
                                            }}/>
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="anoConstrucao" className="col-md-12 col-form-label">Ano de Construção</Label>
                                          <Input type="number" placeholder="Ano de construção" id="anoConstrucao"  value={this.state.construcao} onChange={(e) => {
                                            this.setState({construcao: e.target.value})
                                            this.handleQuality(e)
                                            }}/>
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="nTorres" className="col-md-12 col-form-label">Nº de torres</Label>
                                          <Input type="number" placeholder="Nº de torres" id="nTorres"  value={this.state.torres} onChange={(e) => {
                                            this.setState({torres: e.target.value})
                                            this.handleQuality(e)
                                            }}/>
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="nAndares" className="col-md-12 col-form-label">Nº de andares</Label>
                                          <Input type="number" placeholder="Nº de andares" id="nAndares"  value={this.state.andares} onChange={(e) => {
                                            this.setState({andares: e.target.value})
                                            this.handleQuality(e)
                                            }}/>
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="nPorAndar" className="col-md-12 col-form-label">Nº de unidades por andar</Label>
                                          <Input type="number" placeholder="Nº de unidades por andar" id="nPorAndar"  value={this.state.unidadesAndar} onChange={(e) => {
                                            this.setState({unidadesAndar: e.target.value})
                                            this.handleQuality(e)
                                            }}/>
                                      </Col>
                                    </Row>
                                  </Container> */}
                                  </AvForm>
                              </div>
                          </TabPane>
                          <TabPane tabId={5}>
                              <div className="row justify-content-center">
                                  <Form className="cardBody">
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Características principais</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={3}>
                                        <Label htmlFor="nQuartos" className="col-md-12 col-form-label">Quartos</Label>
                                          <Input type="number" placeholder="Nº de quartos" id="nQuartos"  value={this.state.nQuartos} onChange={(e) => this.setState({nQuartos: e.target.value})}/>
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="nSuites" className="col-md-12 col-form-label">Suítes</Label>
                                          <Input type="number" placeholder="Nº de suítes" id="nSuites"  value={this.state.nSuites} onChange={(e) => this.setState({nSuites: e.target.value})}/>
                                      </Col>
                                      {localStorage.getItem('access') === 'A' &&
                                        <Col md={3}>
                                          <Label htmlFor="nDemiSuites" className="col-md-12 col-form-label">Demi Suítes</Label>
                                            <Input type="number" placeholder="Nº de demi suítes" id="nDemiSuites"  value={this.state.nDemiSuites} onChange={(e) => this.setState({nDemiSuites: e.target.value})}/>
                                        </Col>
                                      }
                                      <Col md={3}>
                                        <Label htmlFor="nBanheiros" className="col-md-12 col-form-label">Banheiros</Label>
                                          <Input type="number" placeholder="Nº de banheiros" id="nBanheiros"  value={this.state.nBanheiros} onChange={(e) => this.setState({nBanheiros: e.target.value})}/>
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="nGaragens" className="col-md-12 col-form-label">Garagens</Label>
                                          <Input type="number" placeholder="Nº de garagens" id="nGaragens"  value={this.state.nGaragens} onChange={(e) => this.setState({nGaragens: e.target.value})}/>
                                      </Col>
                                      {localStorage.getItem('access') === 'A' &&
                                        <>
                                          <Col md={3}>
                                            <Label htmlFor="nLavabos" className="col-md-12 col-form-label">Lavabos</Label>
                                              <Input type="number" placeholder="Nº de lavabos" id="nLavabos"  value={this.state.nLavabos} onChange={(e) => this.setState({nLavabos: e.target.value})}/>
                                          </Col>
                                          <Col md={3}>
                                            <Label htmlFor="nElevadores" className="col-md-12 col-form-label">Elevadores</Label>
                                              <Input type="number" placeholder="Nº de elevadores" id="nElevadores"  value={this.state.nElevadores} onChange={(e) => this.setState({nElevadores: e.target.value})}/>
                                          </Col>
                                          <Col md={3}>
                                            <Label htmlFor="nPavimentos" className="col-md-12 col-form-label">Pavimentos</Label>
                                              <Input type="number" placeholder="Nº de pavimentos" id="nPavimentos"  value={this.state.nPavimentos} onChange={(e) => this.setState({nPavimentos: e.target.value})}/>
                                          </Col>
                                        </>  
                                      }
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Outras características</h5></Col>
                                    </Row>
                                    <Row>
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="alarme"
                                        value={this.state.alarme} onClick={(e) => this.setState({alarme : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="alarme">
                                            Alarme
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="areaServico"
                                        value={this.state.areaServico} onClick={(e) => this.setState({areaServico : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="areaServico">
                                        Área de Serviço
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="areaExterna"
                                        value={this.state.areaExterna} id="termos" onClick={(e) => this.setState({areaExterna : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="areaExterna">
                                        Área Externa
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="armarioEmb"
                                        value={this.state.armarioEmb} onClick={(e) => this.setState({armarioEmb : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="armarioEmb">
                                        Armários Embutidos
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="armariosCozinha"
                                        value={this.state.armariosCozinha} onClick={(e) => this.setState({armariosCozinha : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="armariosCozinha">
                                        Armários na Cozinha
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="armariosDormitorio"
                                        value={this.state.armariosDormitorio} onClick={(e) => this.setState({armariosDormitorio : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="armariosDormitorio">
                                        Armários no Dormitório\imoveis
                                        </Label>
                                      </Col> */}
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="banheira"
                                        value={this.state.banheira} onClick={(e) => this.setState({banheira : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="banheira">
                                        Banheira
                                        </Label>
                                      </Col>
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="blindex"
                                        value={this.state.blindex} onClick={(e) => this.setState({blindex : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="blindex">
                                        Blindex
                                        </Label>
                                      </Col> */}
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="churrasqueiraCarvao"
                                        value={this.state.churrasqueiraCarvao} onClick={(e) => this.setState({churrasqueiraCarvao : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="churrasqueiraCarvao">
                                        Churrasqueira à Carvão
                                        </Label>
                                      </Col>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox"  id="closet"
                                        value={this.state.closet} onClick={(e) => this.setState({closet : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="closet">
                                        Closet
                                        </Label>
                                      </Col>
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="copa"
                                        value={this.state.copa} onClick={(e) => this.setState({copa : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="copa">
                                        Copa
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="cozinhaAmericana"
                                        value={this.state.cozinhaAmericana} onClick={(e) => this.setState({cozinhaAmericana : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="cozinhaAmericana">
                                        Cozinha Americana
                                        </Label>
                                      </Col> */}
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="decorado"
                                        value={this.state.decorado} onClick={(e) => this.setState({decorado : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="decorado">
                                        Decorado
                                        </Label>
                                      </Col>
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="depEmpregada"
                                        value={this.state.depEmpregada} onClick={(e) => this.setState({depEmpregada : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="depEmpregada">
                                        Dependência para Emp.
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="deposito"
                                        value={this.state.deposito} onClick={(e) => this.setState({deposito : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="deposito">
                                        Depósito
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="despensa"
                                        value={this.state.despensa} onClick={(e) => this.setState({despensa : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="despensa">
                                        Despensa
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="espacoGourmet"
                                        value={this.state.espacoGourmet} onClick={(e) => this.setState({espacoGourmet : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="espacoGourmet">
                                        Espaço gourmet
                                        </Label>
                                      </Col> */}
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="gasEncanado"
                                        value={this.state.gasEncanado} onClick={(e) => this.setState({gasEncanado : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="gasEncanado">
                                        Gás Encanado
                                        </Label>
                                      </Col>
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="gesso"
                                        value={this.state.gesso} onClick={(e) => this.setState({gesso : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="gesso">
                                        Gesso
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="hidro"
                                        value={this.state.hidro} onClick={(e) => this.setState({hidro : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="hidro">
                                        Hidro
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="homeOffice"
                                        value={this.state.homeOffice} onClick={(e) => this.setState({homeOffice : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="homeOffice">
                                        Home Office
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="interfone"
                                        value={this.state.interfone} onClick={(e) => this.setState({interfone : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="interfone">
                                        Interfone
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="livingIntegrado"
                                        value={this.state.livingIntegrado} onClick={(e) => this.setState({livingIntegrado : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="livingIntegrado">
                                        Living Integrado
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="miniCampo"
                                        value={this.state.miniCampo} onClick={(e) => this.setState({miniCampo : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="miniCampo">
                                        Mini Campo
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="mobiliado"
                                        value={this.state.mobiliado} onClick={(e) => this.setState({mobiliado : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="mobiliado">
                                        Mobiliado
                                        </Label>
                                      </Col> */}
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="piscinaPriv"
                                        value={this.state.piscinaPriv} onClick={(e) => this.setState({piscinaPriv : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="piscinaPriv">
                                        Piscina Privativa
                                        </Label>
                                      </Col>
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="pisoMadeira"
                                        value={this.state.pisoMadeira} onClick={(e) => this.setState({pisoMadeira : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="pisoMadeira">
                                        Piso de Madeira
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="pisoLaminado"
                                        value={this.state.pisoLaminado} onClick={(e) => this.setState({pisoLaminado : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="pisoLaminado">
                                        Piso Laminado
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="pisoPorcelanato"
                                        value={this.state.pisoPorcelanato} onClick={(e) => this.setState({pisoPorcelanato : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="pisoPorcelanato">
                                        Piso Porcelanato
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="planejado"
                                        value={this.state.planejado} onClick={(e) => this.setState({planejado : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="planejado">
                                        Planejado
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="sacada"
                                        value={this.state.sacada} onClick={(e) => this.setState({sacada : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="sacada">
                                        Sacada
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="saunaPriv"
                                        value={this.state.saunaPriv} onClick={(e) => this.setState({saunaPriv : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="saunaPriv">
                                        Sauna Privativa
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="varandaGourmet"
                                        value={this.state.varandaGourmet} onClick={(e) => this.setState({varandaGourmet : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="varandaGourmet">
                                        Varanda Gourmet
                                        </Label>
                                      </Col> */}
                                    </Row>
                                  </Container> 
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Características do empreendimento</h5></Col>
                                    </Row>
                                    <Row>
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="empChurrasqueira"
                                        value={this.state.empChurrasqueira} onClick={(e) => this.setState({empChurrasqueira : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="empChurrasqueira">
                                            Churrasqueira
                                        </Label>
                                      </Col> */}
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="empEspacoGourmet"
                                        value={this.state.empEspacoGourmet} onClick={(e) => this.setState({empEspacoGourmet : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="empEspacoGourmet">
                                            Espaço Gourmet
                                        </Label>
                                      </Col>
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="empHallDecorado"
                                        value={this.state.empHallDecorado} onClick={(e) => this.setState({empHallDecorado : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="empHallDecorado">
                                            Hall Decorado
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="empJacuzzi"
                                        value={this.state.empJacuzzi} onClick={(e) => this.setState({empJacuzzi : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="empJacuzzi">
                                            Jacuzzi
                                        </Label>
                                      </Col> */}
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="empMiniCampo"
                                        value={this.state.empMiniCampo} onClick={(e) => this.setState({empMiniCampo : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="empMiniCampo">
                                            Mini Campo
                                        </Label>
                                      </Col> */}
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="empPiscina"
                                        value={this.state.empPiscina} onClick={(e) => this.setState({empPiscina : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="empPiscina">
                                            Piscina
                                        </Label>
                                      </Col>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="empSalaoFestas"
                                        value={this.state.empSalaoFestas} onClick={(e) => this.setState({empSalaoFestas : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="empSalaoFestas">
                                            Salão de Festas
                                        </Label>
                                      </Col>
                                      {/* <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="empSauna"
                                        value={this.state.empSauna} onClick={(e) => this.setState({empSauna : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="empSauna">
                                            Sauna
                                        </Label>
                                      </Col> */}
                                    </Row>
                                  </Container>
                                  <Container>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Outras características</h5></Col>
                                      </Row>
                                      <Row>
                                        <Col style={{paddingBottom: '20px'}}>
                                          <CreatableSelect
                                              value={selectedOption}
                                              onChange={this.handleChange}
                                              options={tagsOptions}
                                              formatCreateLabel={(inputValue) => `Criar nova tag: ${inputValue}`}
                                              noOptionsMessage={(inputValue) => `Sem resultados`}
                                              onInputChange={(inputValue) => {
                                                  this.loadOptions(inputValue, (newOptions) => {
                                                      this.setState({ tagsOptions: newOptions });
                                                  });
                                              }}
                                              onCreateOption={this.handleCreate}
                                              isClearable={true}
                                              isMulti={true}
                                              placeholder="Busque uma tag ou crie uma nova"
                                          />
                                        </Col>
                                      </Row>
                                    </Container>
                                  </Form>
                              </div>
                          </TabPane>
                          <TabPane tabId={6}>
                              <div className="row justify-content-center">
                                  <Form className="cardBody">
                                    {/* <Container>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Tags (palavras chave)</h5></Col>
                                      </Row>
                                      <Row>
                                      <Col>
                                        <CreatableSelect
                                            value={selectedOption}
                                            onChange={this.handleChange}
                                            options={tagsOptions}
                                            formatCreateLabel={(inputValue) => `Criar nova tag: ${inputValue}`}
                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                            onInputChange={(inputValue) => {
                                                this.loadOptions(inputValue, (newOptions) => {
                                                    this.setState({ tagsOptions: newOptions });
                                                });
                                            }}
                                            onCreateOption={this.handleCreate}
                                            isClearable={true}
                                            isMulti={true}
                                            placeholder="Busque uma tag ou crie uma nova"
                                        />
                                      </Col>
                                      </Row>
                                    </Container> */}
                                    <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Fotos do imóvel</h5></Col>
                                    </Row>
                                    <Form>
                                      <Dropzone
                                        accept={{
                                          "image/*": [".png", ".jpeg", ".jpg", '.heic'],
                                        }}
                                        onDrop={acceptedFiles =>
                                          this.handleAcceptedFiles(acceptedFiles)
                                        }
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                              </div>
                                              <h4>Para adicionar fotos arraste ou clique aqui.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      {dragPhotos &&
                                        <>
                                          <div style={{margin:'20px 0'}}>
                                            <p style={{fontSize:'20px', fontWeight:'600'}}>Ordene suas fotos. As 3 primeiras recebem maior destaque.</p>
                                          </div>
                                          <Card style={{padding: '15px', backgroundColor:'#f4f4f4'}}>
                                            <Row>
                                              <Col className="col-auto">
                                                <i className="ri-error-warning-line" style={{fontSize:'30px'}}></i>
                                              </Col>
                                              <Col>
                                                <p>Recomendamos que a ordem seja conforme uma visita no imóvel, exemplo:</p>
                                                <p>Sala <i className="ri-arrow-right-line"></i> Cozinha <i className="ri-arrow-right-line"></i> Quartos <i className="ri-arrow-right-line"></i> Banheiros <i className="ri-arrow-right-line"></i> Etc</p>
                                              </Col>
                                            </Row>
                                          </Card>
                                        </>
                                      }
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      >
                                        {dragPhotos}
                                      </div>
                                    </Form>
                                    <Row>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="accept_download"
                                        value={this.state.accept_download} onClick={(e) => this.setState({accept_download : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="accept_download">
                                          Permitir que outros corretores façam o download das fotos e anunciem.
                                        </Label>
                                      </Col>
                                      <Col xs={12}>
                                        <div id='step1' className='atentionClass'>
                                          <i style={{color:"#FFFFFF", fontWeight: 900}} className="mdi mdi-alert-circle-outline me-3"></i>
                                          <span className='atention_link' style={{color:"#FFFFFF", fontWeight: 900}}>Atenção</span>
                                          <p className='text-download'>
                                            Para impulsionar suas vendas, permita que os outros corretores Fast Sale baixem as fotos e anunciem em qualquer lugar.
                                            <span className='text-span-dowloand'> Para fazer o download o corretor precisa aceitar o termo de parceria.</span>
                                          </p>
                                          <p className='text-download'>Caso prefira bloquear essa função, desmarque a opção acima.</p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Autorização de venda</h5></Col>
                                    </Row>
                                    <Row style={{marginBottom: '20px'}}>
                                      <Dropzone
                                        onDrop={acceptedFiles =>
                                          this.handleAcceptedAuth(acceptedFiles)
                                        }
                                        maxFiles={1}
                                        maxSize={5000000}
                                        multiple={false}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                              </div>
                                              <h4>Para adicionar autorização de venda arraste ou clique aqui.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div
                                          className="dropzone-previews mt-3"
                                          id="auth-previews"
                                      >
                                      {this.state.selectedAuth.map((f, i) => {
                                        return (
                                          <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                          >
                                            <div className="p-2">
                                              <Row className="align-items-center">
                                                <Col className="col-auto">
                                                  {f.fileType === 'pdf' ? (
                                                    <i className="ri-file-search-fill" style={{fontSize:'40px', color:'#333'}}></i>
                                                  ) : (
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="40"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  )}
                                                </Col>
                                                <Col>
                                                  <Link
                                                    to="#"
                                                    className="text-muted fw-bold"
                                                  >
                                                    {f.name}
                                                  </Link>
                                                  <p className="mb-0">
                                                    <strong>{f.formattedSize}</strong>
                                                  </p>
                                                </Col>
                                              </Row>
                                            </div>
                                          </Card>
                                        );
                                      })}
                                      </div>
                                    </Row>
                                    <div className='new-forms-autorization'>
                                      <p>A autorização de venda é uma comprovação que o corretor está autorizado a trabalhar o imóvel do proprietário.</p>
                                      <Button onClick={() => this.setState({modal_forms_autorization: true})}>Formas de autorização de venda válidas</Button>
                                    </div>
                                    <Modal
                                      isOpen={this.state.modal_forms_autorization}
                                      toggle={() => this.setState({ modal_forms_autorization: false })}
                                      centered={true}
                                      size="xl"
                                    >
                                      <ModalHeader className='title'>
                                        Formas de autorização de venda válidas
                                        <i className='ri-close-line button-close-modal-forms-autorization' onClick={() => this.setState({ modal_forms_autorization: false })}></i>
                                      </ModalHeader>
                                      <ModalBody className='subtitle'>
                                        <Container>
                                          <Row style={{margin:'10px 0'}}>
                                            <Col style={{padding:'0'}}>
                                              <h5 className='forms-autorization-title'>1. Autorização simples via WhatsApp.</h5>
                                              <p className='forms-autorization-text'>
                                                Um print de WhatsApp onde mostra a conversa entre o corretor captador e o proprietário, na imagem precisa constar 
                                                o proprietário dizendo que autoriza o(a) corretor(a) a divulgar o imóvel na plataforma Fast Sale.
                                              </p>
                                              <p className='forms-autorization-text'>Exemplo, envie a mensagem a seguir e solicite para que o proprietário lhe envie preenchida:</p>
                                              <p className='forms-autorization-text'>AUTORIZAÇÃO DE VENDA FAST SALE SEM EXCLUSIVIDADE</p>
                                              <p>
                                                Eu, XXX, autorizo o(a) Corretor(a) XXX, com CRECI XXX, a divulgar na plataforma Fast Sale, bem como vender meu imóvel situado na rua XXX n XX apto XX, CEP XXXXX-XXX, Bairro XXX, 
                                                Cidade XXX, Estado XX. No valor de R$ XXXXX com 6% de comissão na venda. 
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row style={{margin:'10px 0'}}>
                                            <Col style={{padding:'0'}}>
                                              <h5 className='forms-autorization-title'>2. Autorização formalizada e assinada via e-mail ou física.</h5>
                                              <p className='forms-autorization-text'>
                                                É um arquivo em PDF padrão Fast Sale, onde preenchido com todas as informações do imóvel, do proprietário e do corretor captador. Ele deve constar a assinatura do proprietário e do(a) corretor(a).
                                              </p>
                                              <p className='forms-autorization-link' onClick={this.toggleFormsAutorization}>CRIE O ARQUIVO DA AUTORIZAÇÃO CLICANDO AQUI</p>
                                              {this.state.openFormsAutorization ? (
                                                <>
                                                  <Row style={{marginBottom:'10px'}}>
                                                    <Col>
                                                      <h3 style={{fontSize:'14px', fontWeight:'600'}}>Como funciona</h3>
                                                    </Col>
                                                  </Row>
                                                  <Row className="infoAuthorizationModal">
                                                    <Col>
                                                      <ul>
                                                        <li>1º Preencha as informações abaixo</li>
                                                        <li>2º Clique no botão "Gerar autorização"</li>
                                                        <li>3º Será iniciado o download da autorização</li>
                                                      </ul>
                                                    </Col>
                                                  </Row>
                                                  <Row style={{marginTop:'10px'}}>
                                                    <Col>
                                                      <h3 style={{fontSize:'14px', fontWeight:'600'}}>Sobre o proprietário</h3>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col xs={12}>
                                                      <Label style={{fontSize:'12px', fontWeight:'600'}}>E-mail</Label>
                                                      <Input type="email" value={this.state.proprietarioEmail} onChange={(e) => this.setState({proprietarioEmail: e.target.value})}></Input>
                                                    </Col>
                                                    <Col xs={12}>
                                                      <Label style={{fontSize:'12px', fontWeight:'600'}}>Nome completo</Label>
                                                      <Input type="text" value={this.state.proprietarioNome} onChange={(e) => this.setState({proprietarioNome: e.target.value})}></Input>
                                                    </Col>
                                                    <Col xs={6}>
                                                      <Label style={{fontSize:'12px', fontWeight:'600'}}>Rg</Label>
                                                      <Input type="number" value={this.state.proprietarioRg} onChange={(e) => this.setState({proprietarioRg: e.target.value})}></Input>
                                                    </Col>
                                                    <Col xs={6}>
                                                      <Label style={{fontSize:'12px', fontWeight:'600'}}>Cpf</Label>
                                                      <Input type="number" value={this.state.proprietarioCpf} onChange={(e) => this.setState({proprietarioCpf: e.target.value})}></Input>
                                                    </Col>
                                                    <Col xs={12}>
                                                      <Label style={{fontSize:'12px', fontWeight:'600'}}>Endereço proprietário</Label>
                                                      <Input type="text" value={this.state.proprietarioEndereco} onChange={(e) => this.setState({proprietarioEndereco: e.target.value})}></Input>
                                                    </Col>
                                                    <Col xs={12}>
                                                      <Label style={{fontSize:'12px', fontWeight:'600'}}>Descrição e endereço do imóvel</Label>
                                                      <Input type="text" value={this.state.proprietarioImovel} onChange={(e) => this.setState({proprietarioImovel: e.target.value})}></Input>
                                                    </Col>
                                                    <Col xs={12}>
                                                      <Label style={{fontSize:'12px', fontWeight:'600'}}>Valor do imóvel</Label>
                                                      <Input type="text" value={this.state.proprietarioImovelValor} onChange={(e) => this.setState({proprietarioImovelValor: e.target.value})}></Input>
                                                    </Col>
                                                    <Col xs={6}>
                                                      <Label style={{fontSize:'12px', fontWeight:'600'}}>Matrícula do imóvel</Label>
                                                      <Input type="text" value={this.state.proprietarioImovelMatricula} onChange={(e) => this.setState({proprietarioImovelMatricula: e.target.value})}></Input>
                                                    </Col>
                                                    <Col xs={6}>
                                                      <Label style={{fontSize:'12px', fontWeight:'600'}}>Porcentagem de comissão</Label>
                                                      <Input type="text" value={this.state.proprietarioImovelComissao} onChange={(e) => this.setState({proprietarioImovelComissao: e.target.value})}></Input>
                                                    </Col>
                                                  </Row>
                                                  <Row style={{backgroundColor:'#f1ce41', margin:'10px 0 0', borderRadius:'5px', alignItems:'center'}}>
                                                    <Col xs={1}><i className="ri-information-line" style={{fontSize:'20px'}}></i></Col>
                                                    <Col xs={11}><p style={{fontSize:'12px', fontWeight:'600', lineHeight:'1'}}>Estas informações são utilizadas apenas para criação do contrato, e não serão armazenadas em nosso banco de dados</p></Col>
                                                  </Row>
                                                  <button type="button" className="btn btn-light me-2" onClick={() => this.setState({ modal_center: false })}>Fechar</button>
                                                  <button type="submit" className="btn btn-success save-event" onClick={() => {
                                                    document.getElementById('load').classList.add('active')
                                                    const data = new FormData();
                                                    data.append('ownerName', this.state.proprietarioNome)
                                                    data.append('ownerCpfCnpj', this.state.proprietarioCpf)
                                                    data.append('ownerAddress', this.state.proprietarioEndereco)
                                                    data.append('ownerEmail', this.state.proprietarioEmail)
                                                    data.append('userName', user?.name)
                                                    data.append('userCpfCnpj', localStorage.getItem('access') === 'C' ? user.cpf : user.cnpj)
                                                    data.append('userCity', user?.city)
                                                    data.append('userState', user?.state)
                                                    data.append('userStreet', user?.street)
                                                    data.append('userNumber', user?.number)
                                                    data.append('userDistrict', user?.district)
                                                    data.append('userComplement', user?.complement)
                                                    data.append('userCountry', user?.country)
                                                    data.append('userCreci', localStorage.getItem('access') === 'C' ? user.creci : user.creci_j)
                                                    data.append('userEmail', user?.email)
                                                    data.append('propertyDescription', this.state.proprietarioImovel)
                                                    data.append('propertyRegistry', this.state.proprietarioImovelMatricula)
                                                    data.append('propertyValue', this.state.proprietarioImovelValor)
                                                    data.append('percentage', this.state.proprietarioImovelComissao)
                                                    
                                                    const axiosOptions = {
                                                      responseType: 'arraybuffer',
                                                      headers: {
                                                        'Content-Type': 'application/json',
                                                        "Authorization": "Bearer " + localStorage.getItem('token')
                                                      }
                                                    }


                                                    Axios.post(process.env.REACT_APP_API + '/api/auth/gerar-pdf-autorizacao', data, axiosOptions,
                                                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                      .then(response => {
                                                        const blob = new Blob([response.data], {
                                                          type: 'application/octet-stream'
                                                      })
                                                      const filename = 'autorizacao_venda_imovel.pdf'
                                                      saveAs(blob, filename)
                                                      setTimeout(() => {
                                                        this.setState({ modal_center: false })
                                                        document.getElementById('load').classList.remove('active')
                                                      }, 2000);
                                                    })
                                                    .catch(response => {
                                                      this.setState({auth_error: true})
                                                      document.getElementById('load').classList.remove('active')
                                                    })
                                                  }}>Gerar autorização</button>
                                                </>
                                              ): (
                                                <></>
                                              )} 
                                            </Col>
                                          </Row>
                                          <Row style={{margin:'10px 0'}}>
                                            <Col style={{padding:'0'}}>
                                              <h5 className='forms-autorization-title'>3. Autorização padrão do corretor/imobiliária.</h5>
                                              <p className='forms-autorization-text'>
                                                Caso o(a) corretor(a) já tenha uma Autorização sendo ela formal ou não com o Proprietário, sempre considerando que precisa constar todas as informações corretas, basta apenas enviar para verificação.
                                              </p>
                                            </Col>
                                            </Row>
                                        </Container>
                                      </ModalBody>
                                    </Modal>
                                  </Container>
                                  <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Arquivos adicionais <small>*para sua segurança, não envie arquivos que contenham os dados do proprietário do imóvel</small></h5></Col>
                                    </Row>
                                    <Row style={{marginBottom: '20px'}}>
                                      <Dropzone
                                        onDrop={acceptedFiles =>
                                          this.handleAcceptedDocs(acceptedFiles)
                                        }
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                              </div>
                                              <h4>Para adicionar arquivos arraste ou clique aqui.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div
                                          className="dropzone-previews mt-3"
                                          id="docs-previews"
                                        >
                                        {this.state.selectedDocs.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    {f.fileType === 'pdf' ? (
                                                      <i className="ri-file-search-fill" style={{fontSize:'40px', color:'#333'}}></i>
                                                    ) : (
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="40"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                    )}
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted fw-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{f.formattedSize}</strong>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                        </div>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Vídeo (Youtube)</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <Input type="text" placeholder="Link vídeo" id="linkVideo"   value={this.state.video} onChange={(e) => this.setState({video: e.target.value})}/>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Tour 360º</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <Input type="text" placeholder="Link tour 360º" id="linkTour"   value={this.state.tour} onChange={(e) => this.setState({tour: e.target.value})}/>
                                      </Col>
                                    </Row>
                                  </Container>
                                  </Form>
                              </div>
                          </TabPane>
                          <TabPane tabId={7}>
                              <div className="row justify-content-center">
                                  <Col lg="6">
                                      <div className="text-center">
                                          <div className="mb-4">
                                              <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                          </div>
                                          <div>
                                              <h5>Confirmar cadastro</h5>
                                              <div className="container-text-muted">
                                                <p className="text-muted">Você pode voltar as etapas e conferir se está tudo certo</p>
                                              </div>
                                          </div>
                                          <div>
                                            <Button  onClick={(e) => {
                                              document.getElementById('load').classList.add('active')
                                              e.preventDefault();
                                              const data1 = new FormData()
                                              data1.append('accept_download', this.state.accept_download ? 1 : 0)
                                              data1.append('title', this.state.title)
                                              data1.append('status', 1)
                                              data1.append('objective', this.state.objective)
                                              data1.append('sell_contract_type', localStorage.getItem('access') === 'T' ? "Construtora" : this.state.tipoContrato)
                                              if(this.state.comissao){data1.append('commission_percentage_on_sale', this.state.comissao)}
                                              data1.append('category_id', this.state.tipoImovel)
                                              data1.append('registry', this.state.matricula)
                                              data1.append('real_estate_registration', this.state.registro)
                                              data1.append('owner_name', this.state.nomeProprietario)
                                              data1.append('sale_value', this.state.valor?.replace('R$ ', '').replace(',', '').replace(/\./g,''))
                                              data1.append('sell_price', this.state.valorPromocional?.replace('R$ ', '').replace(',', '').replace(/\./g,''))
                                              data1.append('building_tax_value', this.state.condominioValor?.replace('R$ ', '').replace(',', '').replace(/\./g,''))
                                              data1.append('city_tax_value', this.state.iptuValor?.replace('R$ ', '').replace(',', '').replace(/\./g,''))
                                              data1.append('fee_marina', this.state.marinhaValor?.replace('R$ ', '').replace(',', '').replace(/\./g,''))
                                              data1.append('real_state_financing', this.state.financiamento ? 1 : 0)
                                              data1.append('accept_property', this.state.permutaImovel ? 1 : 0)
                                              data1.append('accept_car', this.state.permutaAutomovel ? 1 : 0)
                                              data1.append('terrain_measures', this.state.areaTerreno?.replace(',', '.'))
                                              data1.append('endorsed_measures', this.state.areaPrivativa?.replace(',', '.'))
                                              data1.append('build_measures', this.state.areaConstruida?.replace(',', '.'))
                                              data1.append('description', this.state.descricao)
                                              data1.append('parking_spots', this.state.nGaragens ? this.state.nGaragens : 0)
                                              data1.append('suites_number', this.state.nSuites ? this.state.nSuites : 0)
                                              data1.append('semi_suites', this.state.nDemiSuites ? this.state.nDemiSuites : 0)
                                              data1.append('bathroom_number', this.state.nBanheiros ? this.state.nBanheiros : 0)
                                              data1.append('n_washrooms', this.state.nLavabos ? this.state.nLavabos : 0)
                                              data1.append('elevator_number', this.state.nElevadores ? this.state.nElevadores : 0)
                                              data1.append('floor_number', this.state.nPavimentos ? this.state.nPavimentos : 0)
                                              data1.append('dorms_number', this.state.nQuartos ? this.state.nQuartos : 0)
                                              data1.append('fs_property', 1)
                                              data1.append('personal_page', 1)

                                              data1.append('tour_360', this.state.tour)
                                              data1.append('youtube_video', this.state.video)

                                              for(let i = 0; i < this.state.photosArray.length; i++){
                                                data1.append(`photo[${i}][file]`, this.state.photosArray[i].file)
                                                data1.append(`photo[${i}][position]`, this.state.photosArray[i].position)
                                              }

                                              let totalSize = 0;
                                              this.state.selectedFiles.forEach((file) => {
                                                totalSize += file.size;
                                              });
              
                                              // Tamanho Médio do Arquivo
                                              let averageFileSize = parseInt(totalSize / this.state.selectedFiles.length) / 1000;
              
                                              const simulateUpload = (averageFileSize) => {
                                                return new Promise((resolve) => {
                                                  const uploadInterval = setInterval(() => {
                                                    this.setState((prevState) => {
                                                    this.setState({ uploading: true });
                                                      let nextPercentage = prevState.uploadPercentage + 1;
                                                      if (nextPercentage >= 99) {
                                                        clearInterval(uploadInterval);
                                                        resolve(); 
                                                        return { uploadPercentage: 99 };
                                                      } else {
                                                        return { uploadPercentage: nextPercentage };
                                                      }
                                                    });
                                                  }, averageFileSize);
                                                });
                                              }

                                              for(let i = 0; i < this.state.selectedDocs.length; i++){
                                                data1.append('docs[]', this.state.selectedDocs[i])
                                              }
                                              for(let i = 0; i < this.state.tags.length; i++){
                                                data1.append('tags[]', this.state.tags[i])
                                              }
                                              if(this.state.selectedAuth.length > 0){
                                                data1.append('auth_photo', this.state.selectedAuth[0])
                                              }
                                              data1.append('decorated', this.state.decorado ? 1 : 0)
                                              // data1.append('has_furniture', this.state.mobiliado ? 1 : 0)
                                              // data1.append('planned', this.state.planejado ? 1 : 0)
                                              // data1.append('housekeeping_dependency', this.state.depEmpregada ? 1 : 0)
                                              // data1.append('service_area', this.state.areaServico ? 1 : 0)
                                              // data1.append('closets_bedrooms', this.state.armariosDormitorio ? 1 : 0)
                                              //   if(localStorage.getItem('sell_contract_type') === 'FS Prop'){
                                              //     if(localStorage.getItem('owner_id') !== ''){
                                              //     data1.append('user_id', parseInt(localStorage.getItem('owner_id')))
                                              //     data1.append('type', 'P')
                                              //     }else{
                                              //       data1.append('user_id', 302)
                                              //       data1.append('type', 'P')
                                              //     }
                                              //   }
                                              //   if(localStorage.getItem('sell_contract_type') === 'FS Parc'){
                                              //     if(localStorage.getItem('broker_id')){
                                              //       data1.append('user_id', parseInt(localStorage.getItem('broker_id')))
                                              //       data1.append('type', 'C')
                                              //     }else{
                                              //       data1.append('user_id', parseInt(localStorage.getItem('real_estate_id')))
                                              //       data1.append('type', 'I')
                                              //     }
                                              //   }
                                              // }else{

                                              
                                              if(this.state.teamActive){
                                                data1.append('real_estate_id', this.state.realEstateTeam.real_estate_id)
                                              }

                                              if(selectOptionsBrokerByRealEstate.length > 0 && selectedBroker.value){
                                                data1.append('broker_id', selectedBroker.value)
                                              }

                                              if(localStorage.getItem('access') !== 'A'){
                                                data1.append('type', localStorage.getItem('access'))
                                                data1.append('user_id', localStorage.getItem('id'))
                                              }else{
                                                if(selectedBroker.value){
                                                  data1.append('type', 'C')
                                                  data1.append('broker_id', selectedBroker.value)
                                                  data1.append('user_id', selectedBroker.value)
                                                }else{
                                                  data1.append('type', 'I')
                                                  data1.append('real_estate_id', selectedRealEstate.value)
                                                  data1.append('user_id', selectedRealEstate.value)
                                                }
                                              }
                                              
                                              // data1.append('responsible_visit_name', this.state.nomeResponsavel)
                                              // data1.append('responsible_visit_phone', this.state.contatoResponsavel)
                                              data1.append('enterprise', this.state.empreendimento)
                                              data1.append('cep', this.state.cep)
                                              data1.append('city', citySelected?.value)
                                              data1.append('state', stateSelected?.value)
                                              data1.append('country', this.state.pais)
                                              data1.append('street', this.state.logradouro)
                                              data1.append('district', this.state.bairro)
                                              data1.append('number', this.state.numero) 
                                              data1.append('complement', this.state.complemento)
                                              data1.append('floor', this.state.andar)
                                              data1.append('unities', this.state.unidade)
                                              // data1.append('construction_year', this.state.construcao)
                                              // data1.append('n_towers', this.state.torres)
                                              // data1.append('n_units_floor', this.state.unidadesAndar)
                                              // data1.append('n_floors', this.state.andares)

                                              simulateUpload(averageFileSize)
                                                Axios.post(process.env.REACT_APP_API + '/api/auth/property', data1, {
                                                  headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                                                })
                                                .then(response => {
                                                  this.setState({ uploadPercentage: 100 });
                                                  setTimeout(() => {
                                                    this.setState({ update_success: true, uploadPercentage: 100, uploading: false });
                                                    document.getElementById('load').classList.remove('active');
                                                  }, 2000);
                                                  const data4 = new FormData()
                                                  // data4.append('features[]', this.state.blindex ? 6 : 0)
                                                  data4.append('features[]', this.state.churrasqueiraCarvao ? 52 : 0)
                                                  // data4.append('features[]', this.state.cozinhaAmericana ? 9 : 0)
                                                  // data4.append('features[]', this.state.gesso ? 31 : 0)
                                                  // data4.append('features[]', this.state.despensa ? 10 : 0)
                                                  // data4.append('features[]', this.state.areaExterna ? 70 : 0)
                                                  // data4.append('features[]', this.state.varandaGourmet ? 37 : 0)
                                                  // data4.append('features[]', this.state.armariosCozinha ? 18 : 0)
                                                  data4.append('features[]', this.state.closet ? 20 : 0)
                                                  // data4.append('features[]', this.state.espacoGourmet ? 36 : 0)
                                                  // data4.append('features[]', this.state.hidro ? 23 : 0)
                                                  // data4.append('features[]', this.state.livingIntegrado ? 35 : 0)
                                                  // data4.append('features[]', this.state.interfone ? 11 : 0)
                                                  // data4.append('features[]', this.state.alarme ? 26 : 0)
                                                  // data4.append('features[]', this.state.sacada ? 16 : 0)
                                                  data4.append('features[]', this.state.banheira ? 28 : 0)
                                                  data4.append('features[]', this.state.piscinaPriv ? 1 : 0)
                                                  // data4.append('features[]', this.state.saunaPriv ? 71 : 0)
                                                  // data4.append('features[]', this.state.pisoLaminado ? 25 : 0)
                                                  // data4.append('features[]', this.state.pisoMadeira ? 14 : 0)
                                                  // data4.append('features[]', this.state.pisoPorcelanato ? 34 : 0)
                                                  // data4.append('features[]', this.state.homeOffice ? 50 : 0)
                                                  // data4.append('features[]', this.state.copa ? 29 : 0)
                                                  // data4.append('features[]', this.state.deposito ? 30 : 0)
                                                  // data4.append('features[]', this.state.armarioEmb ? 5 : 0)
                                                  data4.append('features[]', this.state.gasEncanado ? 22 : 0)
                                                  // data4.append('features[]', this.state.miniCampo ? 59 : 0)
                                                  data4.append('features[]', this.state.empPiscina ? 24 : 0)
                                                  // data4.append('features[]', this.state.empSauna ? 75 : 0)
                                                  // data4.append('features[]', this.state.empHallDecorado ? 33 : 0)
                                                  data4.append('features[]', this.state.empEspacoGourmet ? 72 : 0)
                                                  // data4.append('features[]', this.state.empChurrasqueira ? 8 : 0)
                                                  // data4.append('features[]', this.state.empJacuzzi ? 73 : 0)
                                                  // data4.append('features[]', this.state.empMiniCampo ? 74 : 0)
                                                  data4.append('features[]', this.state.empSalaoFestas ? 15 : 0)
                                                  data4.append('property_id', response.data.property_id)
                                                  Axios.post(process.env.REACT_APP_API + '/api/feature-property', data4,
                                                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                  .then(res => {
                                                    document.getElementById('load').classList.remove('active')
                                                    this.setState({update_success: true})
                                                    // window.open(`./editar-imovel?` + response.data.property_id, "_parent")


                                                    setTimeout(() => {
                                                      window.open("../imovel/" + response.data.slug + '?owner', '_parent')
                                                    }, 300);

                                                  })
                                                  .catch(res => {
                                                    document.getElementById('load').classList.remove('active')
                                                    this.setState({update_error: true})
                                                  })
                                                })
                                                .catch(res => {
                                                  document.getElementById('load').classList.remove('active')
                                                  this.setState({update_error: true})
                                                })
                                            }}>Tudo certo! Pode cadastrar</Button>
                                          </div>
                                      </div>
                                  </Col>
                              </div>
                          </TabPane>
                      </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
          <Modal
            isOpen={this.state.modal_center}
            toggle={this.tog_center}
            centered={true}
          >
            <ModalHeader toggle={() => this.setState({ modal_center: false })}
            style={{justifyContent:'center'}}
            >
              <Row>
                <Col className="col-auto">
                  <i className="ri-error-warning-line" style={{fontSize:'30px', color:'#3E5E3F'}}></i>
                </Col>
                <Col style={{display:'flex', alignItems:'center'}}>
                  <h3 style={{fontSize:'20px', fontWeight:'600', color:'#3E5E3F'}}>Se liga nas dicas!</h3>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              <ul style={{paddingLeft:'10px', maxWidth:'340px', margin:'auto'}}>
                <li style={{fontSize:'16px', fontWeight:'500', marginBottom:'15px', lineHeight:'1.2'}}>Preencha o maior número de informações possíveis para ser melhor rankeado nos portais.</li>
                <li style={{fontSize:'16px', fontWeight:'500', marginBottom:'15px', lineHeight:'1.2'}}>Escolha a melhor foto de capa (primeira foto).</li>
                <li style={{fontSize:'16px', fontWeight:'500', marginBottom:'15px', lineHeight:'1.2'}}>Adicione no mínimo 5 fotos.</li>
                <li style={{fontSize:'16px', fontWeight:'500', marginBottom:'35px', lineHeight:'1.2'}}>Dê preferência para fotos na <span>horizontal</span>.</li>
              </ul>
              <Row style={{justifyContent: 'center'}}>
                <Button  onClick={() => this.setState({ modal_center: false })}>
                  Continuar
                </Button>
              </Row>

            </ModalBody>
          </Modal>


        {localStorage.getItem('access') !== 'A' &&
          <Modal
              isOpen={this.state.modal_full_properties}
              toggle={this.tog_full_properties}
              centered={true}
          >
              <ModalHeader toggle={() => {
                if(this.state.imoveis.length === this.state.p_count.length){
                  setTimeout(() => {
                    window.open('/dashboard', '_parent')
                  }, 300);
                }else{
                  this.setState({ modal_full_properties: false })
                  this.setState({modal_center: true})
                }
              }}
              style={{justifyContent:'center'}}
              >
              <h3 style={{fontSize:'20px', fontWeight:'600', color:'#3E5E3F'}}>Adicionar novo imóvel</h3>
              </ModalHeader>
              <ModalBody>
                <Row style={{marginLeft:'0', marginTop:'0'}}>
                  <Col xs={12}>
                    {this.state.imoveis.length === this.state.p_count.length &&
                      <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'20px', flexDirection:'column'}}>
                        <Row style={{alignItems:'center', justifyContent:'center'}}>
                          <Col xs={12} style={{marginTop:'20px', textAlign:'center'}}>
                            <img src={Triste}></img>
                          </Col>
                          <Col xs={12} style={{marginTop:'30px'}}>
                            <p style={{fontSize:'16px', textAlign:'center'}}><b style={{fontWeight:'600'}}>Você alcançou o limite de imóveis.</b></p>
                          </Col>
                          <Col xs={12} style={{marginTop:'20px'}}>
                            <p style={{fontSize:'14px', textAlign:'center'}}>Quer adicionar mais imóveis?</p>
                          </Col>
                          {this.state.verifyPremium ?
                          <>
                            <Col xs={12} style={{marginTop:'10px', marginBottom:'20px', textAlign:'center'}}>
                              <Button onClick={() => {
                                setTimeout(() => {
                                  window.open('https://wa.me/554741081865', '_blank')
                                }, 300);
                              }}>Sim, quero fazer um upgrade no meu plano!</Button>
                            </Col>
                          </>
                          :
                          <>
                            <Col xs={12} style={{marginTop:'10px', marginBottom:'20px', textAlign:'center'}}>
                              <Button onClick={() => {
                                setTimeout(() => {
                                  window.open('/home', '_parent')
                                }, 300);
                              }}>Sim, quero assinar o Plano Premium!</Button>
                            </Col>
                          </>
                          }
                        </Row>
                      </div>
                    }
                    {this.state.imoveis.length <= (this.state.p_count.length - 1) &&
                      (this.state.imoveis.length === 0 ?
                        (this.state.verifyPremium ? 
                          (<div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'20px', flexDirection:'column'}}>
                            <Row style={{alignItems:'center', justifyContent:'center'}}>
                              <Col xs={12} style={{marginTop:'20px', textAlign:'center'}}>
                                <img src={PrimeiroImovel}></img>
                              </Col>
                              <Col xs={12} style={{marginTop:'30px'}}>
                                <p style={{fontSize:'16px', textAlign:'center'}}>Você está dicionando<br></br><b style={{fontWeight:'600'}}>seu primeiro imóvel como {localStorage.getItem('access') === 'C' ? 'corretor' : 'imobilária'} Premium</b><br></br>e dando um grande passo para alavancar suas vendas!</p>
                              </Col>
                              <Col xs={12} style={{marginTop:'30px'}}>
                                <p style={{fontSize:'14px', textAlign:'center'}}>Lembre-se: No seu plano, você ainda pode colocar mais {this.state.p_count.length - this.state.imoveis.length} imóveis.</p>
                              </Col>
                            </Row>
                          </div>)
                          :
                          (<div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'20px', flexDirection:'column'}}>
                            <Row style={{alignItems:'center', justifyContent:'center'}}>
                              <Col xs={12} style={{marginTop:'20px', textAlign:'center'}}>
                                <img src={PrimeiroImovel}></img>
                              </Col>
                              <Col xs={12} style={{marginTop:'30px'}}>
                                <p style={{fontSize:'16px', textAlign:'center'}}>Você está dicionando<br></br><b style={{fontWeight:'600'}}>seu primeiro imóvel na Fast Sale</b><br></br>e dando um grande passo para alavancar suas vendas!</p>
                              </Col>
                              <Col xs={12} style={{marginTop:'30px'}}>
                                <p style={{fontSize:'14px', textAlign:'center'}}>Lembre-se: No seu plano, você ainda pode colocar mais {this.state.p_count.length - this.state.imoveis.length} imóveis.</p>
                              </Col>
                            </Row>
                          </div>))
                      :
                        (<div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'20px', flexDirection:'column'}}>
                          <Row style={{alignItems:'center', justifyContent:'center'}}>
                            <Col xs={12} style={{marginTop:'20px', textAlign:'center'}}>
                              <img src={VamosNessa}></img>
                            </Col>
                            <Col xs={12} style={{marginTop:'30px'}}>
                              <p style={{fontSize:'18px', textAlign:'center'}}>Vamos nessa!</p>
                            </Col>
                            <Col xs={12} style={{marginTop:'30px'}}>
                              <p style={{fontSize:'14px', textAlign:'center'}}>Lembre-se: No seu plano, você ainda pode colocar mais {this.state.p_count.length - this.state.imoveis.length} {(this.state.p_count.length - this.state.imoveis.length) === 1 ? 'imóvel' : 'imóveis'}.</p>
                            </Col>
                          </Row>
                        </div>))
                    }
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                  <Row style={{justifyContent:'flex-end', alignItems:'flex-end'}}>
                  {this.state.imoveis.length === this.state.p_count.length ?
                    <Col className="col-auto">
                      <Button className="btn-light" color="#333" onClick={(e) => {
                          if(this.state.imoveis.length === this.state.p_count.length){
                            setTimeout(() => {
                              window.open('/dashboard', '_parent')
                            }, 300);
                          }else{
                            this.setState({ modal_full_properties: false })
                            this.setState({modal_center: true})
                          }
                      }}>Cancelar</Button>
                    </Col>
                  :
                    <Col className="col-auto">
                      <Button className="btn-light" color="#333" onClick={(e) => {
                          this.setState({ modal_full_properties: false })
                          this.setState({modal_center: true})
                      }}>Continuar</Button>
                    </Col>
                  }
                  </Row>
              </ModalFooter>
          </Modal>
        }
          

            {/* Sucesso na atualização */}

            {this.state.update_success ? (
            <SweetAlert
              title="Cadastro realizado com sucesso!"
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => this.setState({ update_success: false })}
            ></SweetAlert>
            ) : null}

{
            this.state.uploading ? (
              <div id="load" className="load" style={{display: 'flex', flexDirection: 'column'}}>
                <div className='container-background'>
                  <div className='container-uploading'>
                    <div className='container-phrase-uploading'>
                      {(() => {
                          if (this.state.uploadPercentage < 20) {
                            return(
                              <div className='phrase-text'>
                                  <img className='upload-image' src={Upload} alt='ícone fazendo um upload' /> 
                                  <div className='phrase-details'>
                                    <p>Fazendo upload do material</p><span className='dots'>{this.state.dots}</span>
                                  </div>
                              </div>
                            )
                        } else if (this.state.uploadPercentage < 40) {
                            return(
                              <div className='phrase-text'> 
                                <img className='upload-image' src={Corretores} alt='ícone corretores' /> 
                                <div className='phrase-details'>
                                  <p>Disponibilizando seu imóvel para milhares de corretores</p><span className='dots'>{this.state.dots}</span>
                                </div>
                              </div>
                            ) 
                        } else if (this.state.uploadPercentage < 60) {
                          return(
                            <div className='phrase-text'>
                              <img className='upload-image' src={Contrato} alt='ícone contrato parceria' />
                              <div className='phrase-details'> 
                                <p>Adicionando seus dados ao contrato de parceria</p><span className='dots'>{this.state.dots}</span>
                              </div>
                            </div>
                          )
                        } else if (this.state.uploadPercentage < 80) {
                          return(
                            <div className='phrase-text'>
                              <img className='upload-image' src={Cafezinho} alt='ícone passando cafezinho' /> 
                              <div className='phrase-details'> 
                                <p>Passando um cafezinho</p><span className='dots'>{this.state.dots}</span>
                              </div>
                            </div>
                          ) 
                        } else if (this.state.uploadPercentage < 100) {
                          return(
                            <div className='phrase-text'>
                                <img className='upload-image' src={Instante} alt='Só um instante' />
                                <div className='phrase-details'> 
                                  <p>Só mais um instante</p><span className='dots'>{this.state.dots}</span>
                                </div>
                            </div>
                          ) 
                        } else {
                          return(
                            <div className='phrase-text'>
                              <img className='upload-image' src={Sucesso} alt='ícone de cadastro com successo' />
                              <div className='phrase-details'> 
                                <p>Sucesso! Cadastro de imóvel concluído</p>
                              </div>
                            </div>
                          ) 
                        }
                      })()}
                    </div>
                    <Spinner className="me-2" color="success" style={{ width: '60px',height: '60px', fontSize: '1.3rem', marginTop: '4rem' }}/>
                    <p className='quantify-percentage'>{this.state.uploadPercentage}%</p>
                  </div>
                </div>
              </div>
            ) : (
            <div id="load" className="load">
              <Spinner className="me-2" color="success" />
            </div>
            )
          }

          {/* Falha na atualização */}

          {this.state.update_error ? (
            <SweetAlert
              title="O cadastro falhou, revise os campos e tente novamente."
              timeout={4000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ update_error: false })}
            ></SweetAlert>
            ) : null}
      </div>
    );
  }
}

export default Step1;