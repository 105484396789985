import {useState, useEffect} from 'react';
import { Col, Container, Row } from 'reactstrap';

function FiltersSection7({active, applyCount}) {

  const options = [
    {
      'title':'Permuta Imóvel',
      'value':'Permuta Imóvel'
    },
    {
      'title':'Permuta Automóvel',
      'value':'Permuta Automóvel'
    },
    {
      'title':'Financiamento Bancário',
      'value':'Financiamento Bancário'
    }
  ]

  useEffect(() => {
    document.getElementById('Permuta Imóvel').classList.remove('active')
    document.getElementById('Permuta Automóvel').classList.remove('active')
    document.getElementById('Financiamento Bancário').classList.remove('active')
    if(localStorage.getItem('filtro-negocio')){
      if(localStorage.getItem('filtro-negocio').split(',').includes('Permuta Imóvel')){
        document.getElementById('Permuta Imóvel').classList.add('active')
      }
      if(localStorage.getItem('filtro-negocio').split(',').includes('Permuta Automóvel')){
        document.getElementById('Permuta Automóvel').classList.add('active')
      }
      if(localStorage.getItem('filtro-negocio').split(',').includes('Financiamento Bancário')){
        document.getElementById('Financiamento Bancário').classList.add('active')
      }
    }
}, [applyCount]);

  const[typeOption, setTypeOption] = useState([])
  const[count, setCount] = useState(0)

  useEffect(() => {
    if(typeOption.includes('Financiamento Bancário')){
      document.getElementById('Financiamento Bancário').classList.add('active')
    }else{
      document.getElementById('Financiamento Bancário').classList.remove('active')
    }
    if(typeOption.includes('Permuta Imóvel')){
      document.getElementById('Permuta Imóvel').classList.add('active')
    }else{
      document.getElementById('Permuta Imóvel').classList.remove('active')
    }
    if(typeOption.includes('Permuta Automóvel')){
      document.getElementById('Permuta Automóvel').classList.add('active')
    }else{
      document.getElementById('Permuta Automóvel').classList.remove('active')
    }
  }, [count])

  return (
    <Container className="sectionFilter7">
      <Row>
        <Col className="negociation-container">
          {options.map((option, index) => (
            <div key={index} className="option" id={option.title} onClick={(e) => {
              if(typeOption.includes(option.value)){
                var index = typeOption.indexOf(option.value)
                if (index > -1) {
                  typeOption.splice(index, 1)
                }
              }else{
                typeOption.push(option.value)
                localStorage.setItem('filtro-negocio', typeOption )
              }
              setCount(count + 1)
              }}>{option.title}</div>
          ))}
        </Col>
      </Row>
    </Container>

  );
}

export default FiltersSection7;