import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import './../../assets/scss/custom/components/_allMaps.scss';


const LocationPin = ({price, slug, text, photo, bed, garage, address}) => (
    text ?
    <div className="cityQtd"><span className="pinCity"><i className="ri-home-2-fill"></i>{text}</span></div>
      :
    <Link to={"/imovel/" + slug} target="_blank">
      <div className="raio">
        <span className="price">R$ {(price / 100000000).toFixed(2)} M</span>
        <div className="imovelCard">
          <div className="cover" style={{backgroundImage: 'url(' + photo?.small_image + ')'}}></div>
          <div className="infos">c
            <div className="bed"><i className="ri-hotel-bed-fill"></i>{bed}</div>
            <div className="garage"><i className="ri-car-fill"></i>{garage}</div>
          </div>
          <div className="address">
            <small>{address?.city}/{address?.state} </small>
          </div>
        </div>
      </div>
    </Link>
)

function AllMaps({ dataFilter }){

  const[latCenter, setLatCenter] = useState(0)
  const[lngCenter, setLngCenter] = useState(0)
  const [zoomVariation, setZoomVariation] = useState(13)
  const [userCity, setUserCity] = useState('Balneário Camboriú')
  const [mapActive, setMapActive] = useState()
  const [countMap, setCountMap] = useState(0)
  const [results, setResults] = useState([])
  const [countFirst, setCountFirst] = useState(0)
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    if(dataFilter && countFirst === 0){
      if(localStorage.getItem('user_city')){
        setUserCity(localStorage.getItem('user_city'))
      }
      Axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + userCity + '&key=' + process.env.REACT_APP_GOOGLE_MAPS_TOKEN)
      .then(response => {
        setLatCenter(response.data.results[0].geometry.location.lat)
        setLngCenter(response.data.results[0].geometry.location.lng)
      })
      Axios.post(process.env.REACT_APP_API + '/api/public/property-filter-no-pagination-for-map', dataFilter,
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        setResults(response.data)
      })
      Axios.get(process.env.REACT_APP_API + '/api/public/cities',
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        setCityList(response.data)
      })
      setCountFirst(count => count + 1)
    }
    }, [dataFilter])

    useEffect(() => {
      if(latCenter !== 0 && results.length > 0){
        setTimeout(() => {
          setCountMap(countMap => countMap + 1)
          document.getElementById('load').classList.remove('active')
        }, 500);
      }
    }, [latCenter, results])

    useEffect(() => {
      if(countMap === 1){
        setMapActive(
          <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_TOKEN }}
          onZoomAnimationStart={(event) => {
            setZoomVariation(event)
          }}
          onChildClick={(childProps) => {
            setLatCenter(childProps.lat)
            setLngCenter(childProps.lng)
          }}
          defaultCenter={{ lat: latCenter, lng: lngCenter }}
          defaultZoom={13}
        >
          {zoomVariation > 12 ?
              results?.map((imovel, index) => (
                  imovel?.latitude &&
                  <LocationPin
                    key={index}
                    lat={parseFloat(imovel?.latitude)}
                    lng={parseFloat(imovel?.longitude)}
                    price={(imovel?.sell_price && imovel?.sell_price !== 0 && imovel?.sell_price !== 'null') ? imovel?.sell_price : imovel?.sale_value}
                    slug={imovel?.slug}
                    photo={imovel?.one_photo}
                    bed={imovel?.parking_spots}
                    garage={imovel?.dorms_number}
                    address={imovel?.address_properties}
                  />
              ))
              :
              cityList?.map((city, index) => (
                <LocationPin
                  lat={parseFloat(city.latitude)}
                  lng={parseFloat(city.longitude)}
                  text={results?.filter(result => result?.address_properties?.city === city?.city)?.length > 99 ?  '+99' : results?.filter(result => result?.address_properties?.city === city?.city)?.length}
              />
            ))
          }
        </GoogleMapReact>
        )
      }
    }, [countMap, zoomVariation])


  useEffect(() => {
    if(results && zoomVariation > 11){
      if(zoomVariation < 13){
        for(var i = 0; document.querySelectorAll('.raio').length > i; i++){
          document.querySelectorAll('.raio')[i].classList.remove('zoom')
          document.querySelectorAll('.raio')[i].classList.remove('zoom2')
        }
      }
      if(zoomVariation > 12 && zoomVariation < 16){
        for(var i = 0; document.querySelectorAll('.raio').length > i; i++){
          document.querySelectorAll('.raio')[i].classList.add('zoom')
          document.querySelectorAll('.raio')[i].classList.remove('zoom2')
        }
      }
      if(zoomVariation > 15){
        for(var i = 0; document.querySelectorAll('.raio').length > i; i++){
          document.querySelectorAll('.raio')[i].classList.add('zoom2')
          document.querySelectorAll('.raio')[i].classList.remove('zoom')
        }
      }
    }
  }, [zoomVariation])

  return(
  (results?.length > 0 && latCenter !== 0) &&
  < div className="map" >
    <div className="google-map all">
      {mapActive}
    </div>
  </div >
  )
}

export default AllMaps;