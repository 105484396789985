import { AvField, AvForm } from 'availity-reactstrap-validation';
import './styles.scss';
import { Button, Label } from 'reactstrap';
import GoogleLogo from '../../../../../assets/images/login-cliente-google.jpg';
import logoDark from "../../../../../assets/images/logo-dark.png";
import logoLight from "../../../../../assets/images/logo-light.png";
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Axios from 'axios';
import Swal from 'sweetalert2';

export const AuthComponent = ({newCallback, checkout = null, callbackCustomUrl = null}) => {

  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [callbackUrl, setCallbackUrl] = useState('');

  const recaptchaRef = useRef();
  const history = useHistory()

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Por favor, complete o reCAPTCHA.',
          confirmButtonColor: '#3E5E3F',
          confirmButtonText: 'Ok'
        });
        return;
      }

    Axios.post(process.env.REACT_APP_API + '/api/login', {
        email: email, password: password
    })
    .then(response => {
        localStorage.setItem('access', response.data.type)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('id', response.data.id)
        localStorage.setItem('pageActive', response.data.page_active)
        localStorage.setItem('user_name', response.data.user_name)
        localStorage.setItem('user_email', response.data.user_email)
        localStorage.setItem('level', response.data.level)

        console.log(callbackCustomUrl)

        if(callbackCustomUrl){
            window.location.reload()
        }else if(newCallback){
            newCallback()
        }else{
            history.push('/dashboard')
        }
    }).catch(error => {
        if(error.response.data.error === 'Sem documentos.'){
            const message = "Seu acesso foi bloqueado por falta de documentos.";
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: message,
                confirmButtonColor: '#3E5E3F',
                confirmButtonText: 'Enviar documentos agora'
            }).then((result) => {
                history.push('/enviar-documentos')
            })
        }else{
            const message = error.response.data.error;
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: message,
                confirmButtonColor: '#3E5E3F',
                confirmButtonText: 'Ok'
            });
        }
    })
  }

  const [product, setProduct] = useState('')
  const [recurrence, setRecurrence] = useState('')

  useEffect(() => {
    if(checkout){
        setProduct(checkout[0])
        setRecurrence(checkout[1])
    }
  }, [checkout])

  useEffect(() => {
    if(callbackCustomUrl){
        setCallbackUrl(callbackCustomUrl)
    }
  }, [callbackCustomUrl])

  const handleGoogleLogin = () => {
    if(product && recurrence){
        window.location.href = process.env.REACT_APP_API + `/api/login/google?source=checkout&product=${product}&recurrence=${recurrence}`;
    }else if(callbackCustomUrl){
        window.location.href = process.env.REACT_APP_API + `/api/login/google?customUrl=${callbackCustomUrl}`;
    }else{
        window.location.href = process.env.REACT_APP_API + `/api/login/google`;
    }
  }

  const handleVerify = (token) => {
    setRecaptchaToken(token)
  };

  return(
    <>
    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >
      <div className="auth-form-group-custom mb-4" style={{width:'310px', margin:'auto'}}>
          <i className="ri-user-2-line auti-custom-input-icon"></i>
          <Label htmlFor="username">Email</Label>
          <AvField
              name="username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              errorMessage="Campo obrigatório."
              type="text"
              className="form-control"
              id="username"
              validate={{ email: true, required: true }}
              placeholder="E-mail"
          />
      </div>

      <div className="auth-form-group-custom mb-4" style={{width:'310px', margin:'auto'}}>
          <i className="ri-lock-2-line auti-custom-input-icon"></i>
          <Label htmlFor="userpassword">Senha</Label>
          <AvField
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              errorMessage="Campo obrigatório."
              type={!showPass ? "password" : "text"}
              className="form-control"
              id="userpassword"
              placeholder="Senha"
          />
          {!showPass ?
              <i className="show-pass ri-eye-line" onClick={() => setShowPass(prevState => !prevState)}></i>                                                                
          :
              <i className="show-pass ri-eye-off-line" onClick={() => setShowPass(prevState => !prevState)}></i>
          }
      </div>

      {email !== '' && password !== '' && password.length > 5 &&
        <div style={{display:'flex', justifyContent:'center', marginTop:'15px'}}>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={handleVerify}
            />
        </div>
      }

      <div className="mt-4 text-center">
        {recaptchaToken ?
            <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
                style={{width:'310px', fontSize:'16px'}}
            >Entrar</Button>
            :
            <Button
                color="secondary"
                className="w-md waves-effect waves-light"
                disabled={true}
                style={{width:'310px', fontSize:'16px'}}
            >Entrar</Button>
        }
      </div>

      <div
          className="mt-4 text-center"
          style={{display:'flex', justifyContent:'center'}}
      >
          <button
              className='google-login-button'
              onClick={handleGoogleLogin}
              style={{
                  display:'flex',
                  alignItems:'center',
                  backgroundColor:'#fff',
                  padding:'4px 8px 4px 4px',
                  borderRadius:'4px',
                  border:'solid 1px #cdcdcd',
                  color:'#fff',
                  fontSize:'16px',
                  fontWeight:700,
                  gap:'20px',
                  width: '310px'
              }}
          >
              <img
                  src={GoogleLogo}
                  style={{
                      width:'40px',
                      height:'35px',
                      paddingRight:'3px',
                      marginRight:'15px',
                      borderRight: 'solid 1px #333'
                  }}
              />
              <p style={{color:'#333'}}>Entrar com o Google</p>
          </button>
      </div>

      <div className="mt-4 text-center">
          <Link to="/recuperar-senha" target="_blank" className="text-muted"><i className="mdi mdi-lock me-1"></i> Esqueceu a senha?</Link>
      </div>

      
      </AvForm>
    </>
  )
}