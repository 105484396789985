import './styles.css';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import Axios from 'axios';
import { Feed } from '../Dashboard/Feed';

export const AdmOrders = () => {

  const breadcrumbItems = [
    { title: "Fast Sale", link: "/dashboard" },
    { title: "Pedidos de imóveis", link: "#" },
  ];

  // useEffect(() => {
  //   Axios.get(process.env.REACT_APP_API + '/api/auth/indicators-list',
  //   {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
  //     .then(response => setIndicators(response.data))
  // }, [])

  return(
    <div className="page-content">
      <Container fluid={true}>
        {breadcrumbItems &&
          <Breadcrumbs title="Pedidos de imóveis" breadcrumbItems={breadcrumbItems} />
        }
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col><Feed/></Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}