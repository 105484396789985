import React, { useState, useEffect } from 'react';

const formatValue = (value) => {
  if (!value) return '';
  const valueOnlyDigits = value.toString().replace(/[^\d]/g, '');
  const integerPart = valueOnlyDigits.slice(0, -2);
  const decimalPart = valueOnlyDigits.slice(-2);
  let formattedValue = '';
  for (let i = 0; i < integerPart.length; i++) {
    formattedValue += integerPart[i];
    if ((integerPart.length - i - 1) % 3 === 0 && i !== integerPart.length - 1) {
      formattedValue += '.';
    }
  }
  return `R$ ${formattedValue},${decimalPart}`;
};

const CurrencyInput = ({defaultValue, onChange}) => {
  const [value, setValue] = useState(formatValue(defaultValue) || '');

  useEffect(() => {
    setValue(formatValue(defaultValue));
  }, [defaultValue]);

  return <input type="text" placeholder="R$ 0,00" className="form-control" value={value} inputMode="numeric" onChange={onChange} />;
};

export default CurrencyInput;
