import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'
import Select from "react-select";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_editProperties.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
require('moment/locale/pt.js');

class editDeleteAdm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasons: [],
      filterReasons: [],
      reasonsInactive: [],
      filterReasonsInactive: [],
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Outlet", link: "#" },
    ],
    }
  }


  componentDidMount(){

    document.getElementById('load').classList.add('active')
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        if(localStorage.getItem('access') === 'A'){

          Axios.get(process.env.REACT_APP_API + '/api/auth/deleted-properties',
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
              this.setState({reasons: resp.data})
              document.getElementById('load').classList.remove('active')
           })
           Axios.get(process.env.REACT_APP_API + '/api/auth/imoveis-inativos',
           {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
           .then(resp => {
            this.setState({reasonsInactive: resp.data})
            document.getElementById('load').classList.remove('active')
            })

          }else{
            setTimeout(() => {
              window.open("/dashboard", '_parent')
            }, 300);
          }
    })
    .catch(response =>{
      localStorage.removeItem("history_url")
      setTimeout(() => {
        window.open("/login", '_parent')
      }, 300);
    })
  }



  render() {
     const data = {
       columns: [
         {
           dataField: "id",
           text: "Id"
         },
         {
          dataField: "date",
          text: "Data"
        },
        {
          dataField: "reason",
          text: "Motivo"
        },
        {
          dataField: "property",
          text: "Imóvel"
        },
        {
          dataField: "sku",
          text: "Código"
        },
        {
          dataField: "user",
          text: "Captador"
        },
       ],
     };

     const data2 = {
      columns: [
        {
          dataField: "id",
          text: "Id"
        },
        {
         dataField: "date",
         text: "Data"
       },
       {
         dataField: "reason",
         text: "Motivo"
       },
       {
         dataField: "property",
         text: "Imóvel"
       },
       {
         dataField: "sku",
         text: "Código"
       },
       {
         dataField: "user",
         text: "Captador"
       },
       {
        dataField: "restore",
        text: "Restaurar"
      },
      ],
    };

    const {reasons, reasonsInactive, filterReasons, filterReasonsInactive} = this.state;

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos resultados', value: this.state.reasonsObject?.length
        }]

    };

    // const selectRow = {
    //   mode: 'checkbox',
    //   clickToSelect: true
    // };

    const reasonsObject = []
    const reasonsInactiveObject = []

    {reasons?.sort(function(a,b){
       return new Date(b.created_at) - new Date(a.created_at)}).map(reason => {
       const novoReason = {
         id: reason?.id,
         date: moment(reason?.created_at).format("DD/MM/YYYY"),
         reason: reason?.reason,
         user: reason?.properties?.broker_id ? reason?.properties?.broker?.name : reason?.properties?.real_estate?.name,
         property: reason?.properties?.title,
         sku: reason?.properties?.sku,
         restore:<span style={{
          width:'40px',
          height:'40px',
          backgroundColor:'#628adc',
          color:'#fff',
          fontSize:'18px',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          cursor:'pointer',
          borderRadius:'8px'
         }} onClick={() => {
          Axios.get(process.env.REACT_APP_API + '/api/auth/property-restore/' + reason?.properties?.id,
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            setTimeout(() => {
              window.location.reload()
            }, 100);
          })
         }}><i className="ri-refresh-line"></i></span>
       }
       if(novoReason.property){
        reasonsObject.push(novoReason);
       }
     })}

     {reasonsInactive?.sort(function(a,b){
      return new Date(b.created_at) - new Date(a.created_at)}).map(reason => {
      const novoReason = {
        id: reason?.id,
        date: moment(reason?.created_at).format("DD/MM/YYYY"),
        reason: reason?.reason,
        user: reason?.broker_id ? reason?.broker?.name : reason?.real_estate?.name,
        property: reason?.property?.title,
        sku: reason?.property?.sku 
      }
      if(novoReason.property){
       reasonsInactiveObject.push(novoReason); 
      }
    })}


    return (
        <div className="page-content" id="deleteImoveisAdm">
        <Container fluid={true}>
          <Breadcrumbs title="Imóveis excluídos e desativados" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  
                  <Row style={{justifyContent:'space-between', marginBottom: '20px'}}>
                    <Col className="col-auto searchOutletListContainer" style={{display:'flex', flexDirection:'row'}}>
                      <Input
                      className="searchOutletList"
                      style={{minWidth:'300px'}}
                      placeholder="Buscar código, título ou captador"
                      onChange={(e) => {
                        const searchTerm = e.target.value.toLowerCase();
                        const filterReasons = reasonsObject.filter(
                          (reason) =>
                            reason?.sku?.toLowerCase().includes(searchTerm) ||
                            reason?.property?.toLowerCase().includes(searchTerm) ||
                            reason?.user?.toLowerCase().includes(searchTerm)
                        );
                      
                        this.setState({ filterReasons });
                      }}></Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                   <div style={{marginBottom:'15px'}}><h3 style={{fontSize:'16px'}}>Imóveis excluídos</h3></div>
                  <BootstrapTable
                    keyField='id'
                    data={filterReasons.length > 0 ? filterReasons : reasonsObject}
                    columns={data2.columns}
                    // expandRow={expandRow}
                    pagination={paginationFactory(options)}
                    // selectRow={false}
                  />
                  </div>

                </CardBody>
                <CardBody>
                  
                  
                <Row style={{justifyContent:'space-between', marginBottom: '20px'}}>
                    <Col className="col-auto searchOutletListContainer" style={{display:'flex', flexDirection:'row'}}>
                      <Input
                      className="searchOutletList"
                      style={{minWidth:'300px'}}
                      placeholder="Buscar código, título ou captador"
                      onChange={(e) => {
                        const searchTerm = e.target.value.toLowerCase();
                        const filterReasonsInactive = reasonsInactiveObject.filter(
                          (reason) =>
                            reason?.sku?.toLowerCase().includes(searchTerm) ||
                            reason?.property?.toLowerCase().includes(searchTerm) ||
                            reason?.user?.toLowerCase().includes(searchTerm)
                        );
                      
                        this.setState({ filterReasonsInactive });
                      }}></Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                   <div style={{marginBottom:'15px'}}><h3 style={{fontSize:'16px'}}>Imóveis desativados</h3></div>
                  <BootstrapTable
                    keyField='id'
                    data={filterReasonsInactive?.length > 0 ? filterReasonsInactive : reasonsInactiveObject}
                    columns={data.columns}
                    // expandRow={expandRow}
                    pagination={paginationFactory(options)}
                    // selectRow={false}
                  />
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default editDeleteAdm;