import React from 'react';
import Lottie from 'lottie-react';
import animationData from './animations.json';

const UnlockAnimation = () => {
  return (
    <Lottie
      animationData={animationData}
      style={{ width: 90, height: 90 }}
      loop={false}
      autoplay={true}
    />
  );
};

export default UnlockAnimation;
