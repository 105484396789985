import Axios from 'axios';
import React, { Component } from 'react';
import { Modal, ModalHeader, Button, ModalFooter, ModalBody } from 'reactstrap';
import SemParceria from '../../assets/images/sem-parceria.png';
import ParceriaAceita from "./../../assets/images/parceria-aceita.png";

class ContractUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_scroll: false,
      imovel:[],
      modal_error_number_contract: false,
      n_contracts_accepted: 0,
      modal_number_contract: false
    };
    this.tog_scroll = this.tog_scroll.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.tog_scroll_contract = this.tog_scroll_contract.bind(this);
    this.closeModalContract = this.closeModalContract.bind(this);
    this.tog_scroll_number_contract = this.tog_scroll_number_contract.bind(this);
    this.closeModalNumberContract = this.closeModalNumberContract.bind(this);
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_scroll() {
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll
    }));
    this.removeBodyCss();
  }

  tog_scroll_contract() {
    this.setState(prevState => ({
      modal_error_number_contract: !prevState.modal_error_number_contract
    }));
    this.removeBodyCss();
  }

  tog_scroll_number_contract() {
    this.setState(prevState => ({
      modal_number_contract: !prevState.modal_number_contract
    }));
    this.removeBodyCss();
  }

  closeModal() {
    this.setState({ modal_scroll: false });
    this.props.onContractClosed();
  }

  closeModalContract() {
    this.setState({ modal_error_number_contract: false });
  }

  closeModalNumberContract() {
    this.setState({ modal_number_contract: false });
  }

  componentDidMount() {
    this.setState({ modal_scroll: this.props.acceptContract });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.acceptContract !== this.props.acceptContract) {
      this.setState({ modal_scroll: this.props.acceptContract });
      this.setState({ imovel: this.props.imovel });
    }
  }

  render() {
    const {imovel} = this.state;
    const { premium, fit } = this.props;
    return (
      <React.Fragment>
          <Modal
            isOpen={this.state.modal_scroll}
            toggle={this.tog_scroll}
            scrollable={true}
            onClosed={this.closeModal}
          >
            <ModalHeader toggle={this.closeModal}>Contrato de Parceria</ModalHeader>
            <ModalBody>
            {(imovel?.sell_contract_type === 'FS Parc' || imovel?.sell_contract_type === 'FS Parc (sc)') ?
              <>
                {
                (imovel?.broker?.name && imovel?.real_estate?.name) ?
                <p>De um lado corretor <b>{localStorage.getItem('user_name')}</b> e do outro corretor captador <b>{imovel?.broker?.name?.split(' ').slice(0,1)} <small>*Sobrenome protegido pela lgpd</small></b>, representando a imobiliária <b>{imovel?.real_estate?.name?.split(' ').slice(0,1)} <small>*Parte do nome protegido pela lgpd</small></b>.</p>
                :
                (!imovel?.broker?.name && imovel?.real_estate?.name) ?
                <p>De um lado corretor <b>{localStorage.getItem('user_name')}</b> e do outro imobiliária captadora <b>{imovel?.real_estate?.name?.split(' ').slice(0,1)} <small>*Parte do nome protegido pela lgpd</small></b>.</p>
                :
                (imovel?.broker?.name && !imovel?.real_estate?.name) &&
                <p>De um lado corretor <b>{localStorage.getItem('user_name')}</b> e do outro corretor captador <b>{imovel?.broker?.name?.split(' ').slice(0,1)} <small>*Sobrenome protegido pela lgpd</small></b>.</p>
                }
                <p>Eu, <b>{localStorage.getItem('user_name')}</b>, declaro que não tenho o imóvel <b>{imovel?.sku}</b> de <b>{
                  (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                  (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                  (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                  }m² ({
                    (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                    (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                    (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                    } metros quadrados)</b> no bairro <b>{imovel?.address_properties?.district}</b> na cidade <b>{imovel?.address_properties?.city}/{imovel?.address_properties?.state}</b> em minha carteira de imóveis e na imobiliária onde trabalho, por isso aceito fazer parceria neste imóvel com o captador <b>{imovel?.broker?.name?.split(' ').slice(0,1)} <small>*Sobrenome protegido pela lgpd</small></b>.</p>
                <p>Declaro que concordo que a assinatura deste termo, formaliza esta parceria comercial e se faz necessária para que eu possa saber mais informações sobre o imóvel como o nome do empreendimento, andar, localização exata e outras características.</p>
                <p><b>1.</b> O corretor captador se compromete a fornecer todas as informações relevantes sobre o imóvel, como condições de negociação, características e histórico, e a manter a disponibilidade agendamento de visitas para esclarecimento de dúvidas.</p>
                <p><b>2.</b> A parceria será de 50% da comissão para cada uma das partes, ou seja, a comissão de venda do imóvel será dividida igualmente entre as partes envolvidas.</p>
                <p><b>3.</b> O corretor vendedor se compromete a agendar visitas e enviar propostas através da plataforma <b>Fast Sale</b>, confirmando suas ações com o corretor captador. Está vedado o contato ou tentativa de contato diretamente com o proprietário do imóvel. Também realizando todas as atividades necessárias para a venda do imóvel, como divulgação, atendimento ao cliente, negociação e fechamento da venda respeitando os princípios da boa-fé.</p>
                <p><b>4.</b> Declaro estar ciente que poderei responder por perdas e danos no ambito civil e penal caso seja comprovada a tentativa de contatar diretamente o proprietário com o intuito de desrespeitar este termo.</p>
                <p><b>5.</b> Declaro, que após o aceite deste termo qualquer negociação realizada por mim ou pela imobiliária que trabalho no imóvel <b>{imovel?.sku}</b> de <b>{
                  (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                  (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                  (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                  }m² ({
                    (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                    (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                    (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                    } metros quadrados)</b> no bairro <b>{imovel?.address_properties?.district}</b> na cidade <b>{imovel?.address_properties?.city}/{imovel?.address_properties?.state}</b>, objeto deste termo, metade da comissão será devida ao corretor captador pela parceria na venda do imóvel servindo esse documento como instrumento de cobrança para comprovação judicial ou extrajudicial.</p>
                <p><b>6.</b> O presente termo tem validade até a conclusão da venda do imóvel ou até a sua retirada da plataforma <b>Fast Sale</b>.</p>
              </>
              :
              <>
                <p>De um lado corretor <b>{localStorage.getItem('user_name')}</b> e do outro <b>Fast Sale Tecnologia LTDA</b>.</p>
                <p>Eu, <b>{localStorage.getItem('user_name')}</b>, declaro que não tenho o imóvel <b>{imovel?.sku}</b> de <b>{
                  (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                  (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                  (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                  }m² ({
                    (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                    (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                    (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                    } metros quadrados)</b> no bairro <b>{imovel?.address_properties?.district}</b> na cidade <b>{imovel?.address_properties?.city}/{imovel?.address_properties?.state}</b> em minha carteira de imóveis e na imobiliária onde trabalho, por isso aceito fazer parceria neste imóvel com a plataforma <b>Fast Sale</b>, captadora.</p>
                <p>Declaro que concordo que a assinatura deste termo, formaliza esta parceria comercial e se faz necessária para que eu possa saber mais informações sobre o imóvel como o nome do empreendimento, andar, localização exata e outras características.</p>
                <p><b>1.</b> A <b>Fast Sale</b> se compromete a fornecer todas as informações relevantes sobre o imóvel, como condições de negociação, características e histórico, e a manter a disponibilidade agendamento de visitas para esclarecimento de dúvidas.</p>
                {imovel?.sell_contract_type === 'FS Prop' &&
                  <p><b>2.</b> A divisão da comissão deste imóvel em específico se dará da seguinte maneira: <b>1% (um por cento)</b> do valor de venda do imóvel para a <b>Fast Sale</b> pela captação, produção do material comercial e disponibilização desse material na plataforma, <b>5% (cinco por cento)</b> para o vendedor.</p>
                }
                {(imovel?.sell_contract_type === 'FS Corr' || imovel?.sell_contract_type === 'FS Imob') &&
                  <p><b>2.</b> A divisão da comissão deste imóvel em específico se dará da seguinte maneira: <b>1% (um por cento)</b> do valor de venda do imóvel para a <b>Fast Sale</b> pela captação, produção do material comercial e disponibilização desse material na plataforma, <b>1% (um por cento)</b> para corretor captador e <b>4% (quatro por cento)</b> para o vendedor.</p>
                }
                {imovel?.sell_contract_type === 'FS Imob (antigo)' &&
                  <p><b>2.</b> A divisão da comissão deste imóvel em específico se dará da seguinte maneira: <b>1% (um por cento)</b> do valor de venda do imóvel para a <b>Fast Sale</b> pela captação, produção do material comercial e disponibilização desse material na plataforma, <b>1% (um por cento)</b> para corretor captador, <b>1% (um por cento)</b> para a imobiliária responsável e <b>3% (três por cento)</b> para o vendedor.</p>
                }
                <p><b>3.</b> O corretor vendedor se compromete a agendar visitas e enviar propostas através da plataforma <b>Fast Sale</b>, confirmando suas ações com o corretor captador. Está vedado o contato ou tentativa de contato diretamente com o proprietário do imóvel. Também realizando todas as atividades necessárias para a venda do imóvel, como divulgação, atendimento ao cliente, negociação e fechamento da venda respeitando os princípios da boa-fé.</p>
                <p><b>4.</b> Declaro estar ciente que poderei responder por perdas e danos no ambito civil e penal caso seja comprovada a tentativa de contatar diretamente o proprietário com o intuito de desrespeitar este termo.</p>
                <p><b>5.</b> Declaro, que após o aceite deste termo qualquer negociação realizada por mim ou pela imobiliária que trabalho no imóvel <b>{imovel?.sku}</b> de <b>{
                  (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                  (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                  (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                  }m² ({
                    (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                    (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                    (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                    } metros quadrados)</b> objeto deste termo, além de seguir a divisão da comissão entre as partes envolvidas e determinada nesse termo, servindo esse documento como instrumento de cobrança para comprovação judicial ou extrajudicial.</p>
                <p><b>6.</b> O presente termo tem validade até a conclusão da venda do imóvel ou até a sua retirada da plataforma <b>Fast Sale</b>.</p>
              </>}
            <ModalFooter>
              <Button type="button" color="light" onClick={() => this.setState({ modal_scroll: false }) }>Cancelar</Button>
              <Button type="button" color="primary" onClick={() => {
                  
                  // (localStorage.getItem('id') === 349 && localStorage.getItem('access') === 'I')
                  Axios.post(process.env.REACT_APP_API + '/api/auth/accept-contract', {
                    type:localStorage.getItem('access'),
                    user_id:localStorage.getItem('id'),
                    property_id:imovel?.id,
                    }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(response => {
                      // if(!premium && !fit) {
                      //   this.setState({ n_contracts_accepted: 0 }); 
                        // if (response.data.n_contracts_accepted < 3) {
                        // this.setState({ modal_number_contract: true });
                        // }
                      // }
                    // this.tog_schedule()
                    if(this.props.origem === 'download'){
                      this.props.onContractAcceptDownload();
                    }else if(this.props.origem === 'compartilhar'){
                      this.props.onContractAcceptCompartilhar();
                    }else if(this.props.origem === 'captador'){
                      this.props.onContractAcceptCaptador();
                    }else if(this.props.origem === 'geral'){
                      this.props.onContractAcceptAll()
                    }else if(this.props.origem === 'fotos'){
                      this.props.onContractClosed()
                      this.props.onContractAcceptFotos()
                    }else{
                      this.props.onContractAccept();
                    }
                    })
                    .catch(response => {
                      this.setState({ modal_error_number_contract: true });
                    })
                }}>Delaro que li e aceito o termo acima.</Button>
            </ModalFooter>                                 
            </ModalBody>
          </Modal>

          <Modal 
            isOpen={this.state.modal_error_number_contract} 
            toggle={this.tog_scroll_contract}
            onClosed={this.closeModalContract}
            centered={true}
          >
            <ModalHeader toggle={this.closeModalContract}>
              <p className='modal-number-contract-title'>Fazer parceria.</p> 
            </ModalHeader>
            <ModalBody>
              <div className='modal-number-contract'>
                <img src={SemParceria} alt='Icone sem parceria'/>
                <h3>Você alcançou o limite de parcerias.</h3>
                <p>Para fazer novas parcerias assine um dos nossos planos.</p>
                <div className='modal-number-buttons'>
                  <button className='modal-button-first' onClick={() => window.open("/home", '_parent')}>Assinar agora</button>
                  <button className='modal-button-second' onClick={() => this.setState({ modal_error_number_contract: false }) }>Não, obrigado.</button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal 
            isOpen={this.state.modal_number_contract} 
            toggle={this.tog_scroll_number_contract}
            onClosed={this.closeModalNumberContract}
            centered={true}
          >
            <ModalHeader toggle={this.closeModalNumberContract}>
              <h3 className='modal-rest-number-title'>Parceria feita</h3>
            </ModalHeader>
            <ModalBody>
              <div className='modal-number-contract'>
                <img className='parceria' src={ParceriaAceita} alt='Icone parceria aceita'/>
                <p className='modal-rest-number'>Restam {3 - this.state.n_contracts_accepted} parcerias no seu plano FREE neste mês.</p>
                <p className='modal-rest-number-text'>Assine um dos planos para liberar <span>parcerias ilimitadas</span> ou aguarde o prôximo mês para realizar novas parcerias.</p>
                <div className='modal-number-buttons'>
                  <button className='modal-button-first' onClick={() => window.open("/home#premium", '_parent')}>Escolher plano.</button>
                  <button className='modal-button-second' onClick={() => this.setState({ modal_number_contract: false }) }>Agora não, obrigado.</button>
                </div>
              </div>
            </ModalBody>
          </Modal>
      </React.Fragment>
    );
  }
}

export default ContractUse;