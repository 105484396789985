import { useEffect, useState } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom/cjs/react-router-dom';

import api from '../services/api';
import { CardPlan } from './cardPlan';

export const NewPlansV2 = () => {
  const [selectedType, setSelectedType] = useState('C')
  const [totalBrokers, setTotalBrokers] = useState(0)
  const [products, setProducts] = useState([])
  const [properties, setProperties] = useState(0)
  

  useEffect(() => {
    api
      .get('/api/public/get-count')
      .then((response) => {
        setTotalBrokers(parseInt(response.data.brokers) + parseInt(response.data.real_estates))
        setProperties(response.data.properties);
      })

    api
      .get('/api/public/list-products')
      .then(response => {
        setProducts(response.data)
      })
  }, []);


  return(
    <>
    <div className="home-plans-select-type">
      <h3>Nossos planos</h3>
      {/* <p>Selecione seu perfil</p>
      <div className="home-plans-type-options">
        <div
          className={`home-plans-type-button ${selectedType === 'C' ? 'active' : ''}`}
          onClick={() => setSelectedType('C')}
        >Corretor</div>
        <div
          className={`home-plans-type-button ${selectedType === 'I' ? 'active' : ''}`}
          onClick={() => setSelectedType('I')}
        >Imobiliária</div>
      </div> */}
      <h2>Para imobiliárias</h2>
    </div>
    <div className="home-plans-section">
      {products.filter(plan => plan.user_type === 'I' && plan.slug !== 'free').map((item, index) => (
        <CardPlan key={index} item={item} horizontal={true} totalBrokers={totalBrokers} properties={properties}/>
      ))}
    </div>
    <div className="home-plans-select-type">
      <h2>Para corretores</h2>
    </div>
    <div className="home-plans-section">
      {products.filter(plan => plan.user_type === 'C').map((item, index) => (
        <CardPlan key={index} item={item} totalBrokers={totalBrokers} properties={properties} />
      ))}
    </div>
    </>
  )
}