import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AnalyticsEnterprises from "../Dashboard/AnalyticsEnterprises";

class EditUnits extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Unidades", link: "#" },
      ],
      }
    }
    render(){

        return(
            <div className="page-content">
              <Container fluid={true}>
                  <Breadcrumbs title="Unidades" breadcrumbItems={this.state.breadcrumbItems} />
                  <Row>
                      <Col>
                          <Card>
                              <CardBody>
                                <AnalyticsEnterprises/>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
              </Container>
          </div>
        )
    }

}

export default EditUnits;