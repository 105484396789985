import Brokers from './components/Brokers';
// import Benefits from './components/Benefits';
import Contracts from './components/Contracts';
import Header from './components/Header';
import Home from './components/Home';
import Premium from './components/Premium';
import Reference from './components/Reference';
import Doubts from './components/Doubts';
import Footer from './components/Footer';
import Easy from './components/Easy';
import Plans from './components/Plans';
import PlansSecond from './components/PlansSecond';
import Bureaucracy from './components/Bureaucracy';
import Partnership from './components/Partnership';
import Whats from '../../components/Whats/Whats';
import Instagram from './components/Instagram';
import FastSale from './components/FastSale';
import ClosePage from './components/ClosePage';
import { FormProvider } from './FormContext';
// import { NewPlans } from './newComponent/plans';
import { NewPlansV2 } from './newPlans/plans';
import { ConstructionBanner } from './constructionBanner';

function HomeIndex() {
  return (
    <FormProvider>
      <div  style={{backgroundColor:'#fff'}}>
        <Header />
          <Home />
          <Contracts />
          <FastSale />
          <Premium />
          <Partnership />
          {/* <Benefits /> */}
          {/* <Plans /> */}
          <Easy />
          <NewPlansV2/>
          {/* <PlansSecond /> */}
          <ConstructionBanner/>
          <Bureaucracy />
          <Reference />
          <Brokers />
          <Doubts />
          {/* <Instagram /> */}
          <Whats/>
        <Footer />
        {/* <ClosePage /> */}
      </div>
    </FormProvider>
  );
}

export default HomeIndex;