import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, Button, CardHeader, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_singleProperty.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fire from './../../assets/images/Icons/fogooutlet.png';
import SingleImovelCardCarousel from './Carousel';
import Maps from './Maps';
import SingleAction from './SingleAction';
import ContractUse from './ContractUse';
import { saveAs } from 'file-saver';
import SingleActionPublic from './SingleActionPublic';
require('moment/locale/pt.js');

class SinglePropertyPublic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imovel:[],
      user:[],
      modal_large: false,
      access:'',
      col1: true,
			col2: false,
			col3: false,
      col4: false,
			col5: true,
    }
    this.t_col1 = this.t_col1.bind(this);
		this.t_col2 = this.t_col2.bind(this);
		this.t_col3 = this.t_col3.bind(this);
		this.t_col4 = this.t_col4.bind(this);
		this.t_col5 = this.t_col5.bind(this);
    this.tog_large = this.tog_large.bind(this);
  }

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  componentDidMount(){
    let params = new URLSearchParams(window.location.search)
    const access = params.get('ce29')
    const userId = params.get('ba05a')
    const propertyId = params.get('83acd')
    Axios.get(process.env.REACT_APP_API + '/api/public/property-share?ce29=' + access + '&ba05a=' + userId + '&83acd=' + propertyId, {
    },{headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      this.setState({imovel: response.data.property})
      this.setState({user: response.data.user})
      console.log(response.data.user)
      localStorage.setItem('view_property_sku', response.data.sku)

      this.setState({access: access})
      })
      .catch(response =>{
        setTimeout(() => {
          window.open("/404", '_parent')
        }, 300);})
  }

  t_col1() {
		this.setState({ col1: !this.state.col1, col2: false, col3: false, col4: false });
	}
	t_col2() {
		this.setState({ col2: !this.state.col2, col1: false, col3: false, col4: false });
	}
	t_col3() {
		this.setState({ col3: !this.state.col3, col1: false, col2: false, col4: false });
	}
  t_col4() {
		this.setState({ col4: !this.state.col4, col1: false, col2: false, col3: false });
	}
	t_col5() {
		this.setState({ col5: !this.state.col5 });
	}


  render() {

    const {imovel, user} = this.state;

    return (
      <div className="singleProperty">
        <ContractUse
          acceptContract={this.state.acceptContract}
          onContractClosed={this.handleContractClosed}
          imovel={imovel}
        />
        <Container >
          <Row style={{backgroundColor:'#fff', justifyContent:'space-between', margin:'10px 0 0', borderBottom:'solid 1px #cdcdcd', padding:'8px 0'}}>
            <Col xs={8} md={3}>
              <Row style={{alignItems:'center'}}>
                <Col className="col-auto">
                  <div style={{width:'40px', height: '40px', borderRadius:'5px', backgroundColor: '#cdcdcd', backgroundImage:'url(' + user?.profile_photo + ')', backgroundPosition: 'center center', backgroundSize: 'cover'}}></div>
                </Col>
                <Col>
                {this.state.access !== 'P' ?
                  <>
                    <h3 style={{fontSize:'15px'}}>{user?.name}</h3>
                    {user?.creci && <p style={{fontSize:'12px'}}>CRECI: {user?.creci}</p>}
                    {user?.creci_j && <p style={{fontSize:'12px'}}>CRECI: {user?.creci_j}</p>}
                  </>
                  :
                  <>
                    <h3 style={{fontSize:'15px'}}>Fast Sale Imóveis</h3>
                    <p style={{fontSize:'12px'}}>CRECI: 6635J</p>
                  </>
                }
                </Col>
              </Row>
            </Col>
            <Col xs={4} md={3} style={{justifyContent:'flex-end', display: 'flex', alignItems: 'center'}}>
              
            {this.state.access !== 'P' ?
              <h3 style={{fontSize:'15px', cursor:'pointer'}} onClick={() => {
                setTimeout(() => {
                  window.open(`https://api.whatsapp.com/send?phone=55` + (user?.phone ? user?.phone : '4741081865' )?.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, "") + `&text=Olá,%20tenho%20interesse%20no%20` + imovel?.title + " (" + imovel.sku + ')', "_blank")
                }, 300);
              }}><i className="ri-whatsapp-line align-middle ms-2" style={{marginRight:'5px'}}></i>{user?.phone}</h3>
            :
            <h3 style={{fontSize:'15px', cursor:'pointer'}} onClick={() => {
              setTimeout(() => {
                window.open(`https://api.whatsapp.com/send?phone=554741081865&text=Olá,%20tenho%20interesse%20no%20` + imovel?.title + " (" + imovel.sku + ')', "_blank")
              }, 300);
            }}><i className="ri-whatsapp-line align-middle ms-2" style={{marginRight:'5px'}}></i>(47) 4108-1865</h3>
            }
              
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{marginBottom:'24px'}}>
                    <Col style={{padding:'0'}}>
                      <SingleImovelCardCarousel photos={imovel?.photos} imovel={imovel} public={1}/>
                    </Col>
                  </Row>
                  <Row style={{justifyContent:'space-between'}}>
                    <Col md={7} lg={8}>
                      <Row>
                        <Col xs={12}>
                          <p className='sku' style={{display:'flex', alignItems:'center'}}>
                          {imovel.sku}
                          </p>
                          <div style={{display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                            {imovel?.opportunity === 1 &&
                              <img style={{width:'30px', marginTop: '8px', marginRight:'5px'}} src={Fire}></img>
                            }
                            <h1>{imovel.title}</h1>
                          </div>
                          <div className='place'>
                            <i className="ri-map-pin-2-fill"></i>
                            <p>{imovel?.address_properties?.city}</p>
                          </div>
                        </Col> 
                        <Col xs={12}>
                          <Row style={{maxWidth:'600px'}}>
                          {(imovel.dorms_number && imovel.dorms_number !== '0') ?
                            <Col className="highFeature col-auto">
                                <i className="ri-hotel-bed-fill"></i>
                                <p>{imovel.dorms_number} quartos</p>
                              </Col>
                              :
                              <></>
                            }
                            {((!imovel.dorms_number || imovel.dorms_number === '0') && (imovel.suites_number && imovel.suites_number !== '0')) ?
                              <Col className="highFeature col-auto">
                                <i className="ri-hotel-bed-fill"></i>
                                <p>{imovel.suites_number} suítes</p>
                              </Col>
                              :
                              <></>
                            }
                            {imovel.parking_spots && imovel.parking_spots !== '0' ?
                              <Col className="highFeature col-auto">
                                <i className="ri-car-fill"></i>
                                <p>{imovel.parking_spots} vagas</p>
                              </Col>
                              :
                              <></>
                            }
                            <Col className="highFeature col-auto">
                              <i className="ri-ruler-fill"></i>
                              <p>{(imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                                (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                                (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                              } m²</p>
                            </Col>
                            <Col className="highFeature">
                              <Button onClick={this.tog_large} className="btn-map">
                                <p style={{color:'#333', fontWeight:'600', fontSize:'16px'}}>Mapa</p>
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <div className="button-items">
                            {this.state.access !== 'P' ?
                              <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                setTimeout(() => {
                                  window.open(`https://api.whatsapp.com/send?phone=55` + (user?.phone ? user?.phone : '4741081865' )?.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, "") + `&text=Olá,%20tenho%20interesse%20no%20` + imovel?.title + " (" + imovel.sku + ')', "_blank")
                                }, 300);
                              }}>
                                Chamar no Whatsapp <i className="ri-whatsapp-line align-middle ms-2"></i>
                              </Button>
                              :
                              <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                setTimeout(() => {
                                  window.open(`https://api.whatsapp.com/send?phone=554741081865&text=Olá,%20tenho%20interesse%20no%20` + imovel?.title + " (" + imovel.sku + ')', "_blank")
                                }, 300);
                              }}>
                                Chamar no Whatsapp <i className="ri-whatsapp-line align-middle ms-2"></i>
                              </Button>
                            }
                            </div>
                          </Row>
                          <Row style={{justifyContent:'center'}}>
                            {window.screen.width <= 768 &&
                             <SingleActionPublic imovel={imovel} user={user} access={this.state.access}/>
                            }
                          </Row>
                          <Row>
                            <div id="accordion">
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col1} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingOne">
                                    <h6 className="m-0 font-14">
                                      <h3>Sobre o imóvel</h3>
                                      <div className="text_muted"><p>Veja mais informações sobre esse imóvel</p></div>
                                      <i className={this.state.col1 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col1}>
                                  <CardBody>
                                    {imovel.description}
                                    <div style={{margin:'15px 0 10px'}}><h3 style={{fontSize:'14px', fontWeight:'600'}}>Espaços e medidas</h3></div>
                                    <div className="container-medidas">
                                      {(imovel?.endorsed_measures && imovel?.endorsed_measures !== '0') && <p>Área privativa: <b>{imovel?.endorsed_measures + 'm²'}</b></p>}
                                      {(imovel?.build_measures && imovel?.build_measures !== '0' && imovel?.terrain_measures !== '0.00') &&<p>Área construída: <b>{imovel?.build_measures + 'm²'}</b></p>}
                                      {(imovel?.terrain_measures && imovel?.terrain_measures !== '0' && imovel?.terrain_measures !== '0.00') &&<p>Área total: <b>{imovel?.terrain_measures + 'm²'}</b></p>}
                                    </div>
                                  </CardBody>
                                </Collapse>
                              </Card>
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col2} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingTwo">
                                    <h6 className="m-0 font-14">
                                      <h3>Outras características</h3>
                                      <div className="text_muted"><p>Características e tags do imóvel</p></div>
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col2}>
                                  <CardBody className="tags">
                                    {imovel?.features?.filter(feature => feature.show_on_venture === 1).map((feat, index) => (
                                      <li key={index}>{feat.name}</li>
                                    ))}
                                    {imovel?.tags?.map((tag, index) => (
                                      <li key={index}>{tag.name}</li>
                                    ))}
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              {(imovel?.accept_car === 1 || imovel?.accept_property === 1 || imovel?.real_state_financing === 1) &&
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col4} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingTwo">
                                    <h6 className="m-0 font-14">
                                      <h3>Condições de negociação</h3>
                                      <div className="text_muted"><p>Condições para negociação do imóvel</p></div>
                                      <i className={this.state.col4 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col4}>
                                  <CardBody className="negotiation">
                                  <ul style={{listStyle:'none', padding:'0'}}>
                                    {imovel?.accept_car === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita carro</li>
                                    }
                                    {imovel?.accept_property === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita imóveis</li>
                                    }
                                    {imovel?.real_state_financing === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita Fin. Imob.</li>
                                    }
                                  </ul>
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              }
                              {(imovel?.youtube_video !== '' && imovel?.youtube_video !== 'null' && imovel?.youtube_video) &&
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col3} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingThree">
                                    <h6 className="m-0 font-14">
                                      <h3>Vídeo</h3>
                                      <div className="text_muted"><p>Assista um vídeo do imóvel</p></div>
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col3}>
                                  <CardBody>
                                    <div className="ratio ratio-16x9">
                                      <iframe title="video4" className="embed-responsive-item" src={"https://www.youtube.com/embed/" + imovel?.youtube_video?.replace('https://youtube.com/shorts/', '')?.replace('https://youtu.be/', '')?.replace('https://www.youtube.com/watch?v=', '')?.split('&').slice(0,1).toString()}></iframe>
                                    </div>
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              }
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5} lg={4} style={{justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                      {window.screen.width > 768 &&
                        <SingleActionPublic imovel={imovel} user={user} access={this.state.access}/>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* PopUp Google Maps */}
          <Modal
            size="lg"
            centered={true}
            isOpen={this.state.modal_large}
            toggle={this.tog_large}
          >
            <ModalHeader toggle={() => this.setState({ modal_large: false })}>
                Localização aproximada do imóvel
            </ModalHeader>
            <ModalBody>
              <Maps latI={imovel?.latitude} lngI={imovel?.longitude} zoomLevel={13}/>
            </ModalBody>
          </Modal>

          <div id="load" className="load">
            <Spinner className="me-2" color="success" />
          </div>
        </Container>
      </div>
    );
  }
}

export default SinglePropertyPublic;