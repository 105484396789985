import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../styles/Header.module.css';
import Logo from './../assets/Fast Sale Horizontal.png';
import { useState } from 'react';
import BrokerNoActive from '../assets/broker_no_active.png'
import RealStateNoActive from '../assets/house_no_active.png'
import BrokerActive from '../assets/broker_active.png'
import RealStateActive from '../assets/house_active.png'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import { useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import PhoneInput from './PhoneInput';
import EmailInput from './EmailInput';
import CnpjInput from './CnpjInput';
import CpfInput from './CpfInput';
import CepInput from './CepInput';
import api from '../services/api';
import CreciInput from './CreciInput';
import CreciJInput from './CreciJInput';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function HeaderMobile() {
  const [isMobile, setIsMobile] = useState(false);
  const isMobileQuery = useMediaQuery({ query: `(max-width: 767px)` });
  const [modalMap, setModalMap] = useState(false);
  const openModal = () => setModalMap(!modalMap);
  const [userType, setUserType] = useState('');
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [cep, setCep] = useState('')
  const [creci, setCRECI] = useState('')
  const [creci_j, setCRECIJ] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [registerSuccess, setRegisterSuccess] = useState(false)
  const [registerError, setRegisterError] = useState(false)
  const [imageSrc, setImageSrc] = useState({ broker: BrokerNoActive, realState: RealStateNoActive });
  const [alertPhone, setAlertPhone] = useState(false);
  const [endereco, setEndereco] = useState({});
  const [emailIsExist, setEmailIsExist] = useState('0')
  const [cpfIsExist, setCpfIsExist] = useState('0')
  const [cnpjIsExist, setCnpjIsExist] = useState('0')
  const [creciIsExist, setCreciIsExist] = useState('0')
  const [creciJIsExist, setCreciJIsExist] = useState('0')
  const [origin_response, setOrigin_response] = useState('')
  const [origin_response_other, setOrigin_response_other] = useState('')

  
  const createRegister = () => {
    if (
      name === '' ||
      phone === '' ||
      email === '' ||
      origin_response === '' ||
      (origin_response=== 'Outros' && origin_response_other === '') ||
      cep === '' ||
      (userType === 'C' && cpf === '') ||
      (userType === 'I' && cnpj === '') ||
      password === '' ||
      confirmPassword === ''
    ) {
      setRegisterError(true);
      return;
    }

   if (password !== confirmPassword) {
     setRegisterError(true);
     return;
   }


 
   document.getElementById('load').classList.add('active');
   
   if (userType === 'C') {
     const data = new FormData();
     data.append('name', name);
     data.append('phone', phone);
     data.append('email', email);
     data.append('cpf', cpf.replace(/[.-]/g, ''));
     data.append('cep', cep);
     data.append('street', endereco.logradouro)
     data.append('district', endereco.bairro)
     data.append('complement', endereco.complemento)
     data.append('city', endereco.cidade)
     data.append('state', endereco.estado)
     data.append('password', password);
     data.append('type', userType)
     data.append('creci', creci)
     data.append('status', 3)

     Axios.post(
       process.env.REACT_APP_API + '/api/register/brokers',
       data,
       { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
     )
       .then((response) => {

        const data2 = new FormData()
        data2.append('user_id', response.data.id)
        data2.append('user_type', 'C')
        if(origin_response === 'Outros'){
            data2.append('response', origin_response_other)
        }else{
            data2.append('response', origin_response)
        }

        Axios.post(
            process.env.REACT_APP_API + '/api/register/new-feedback',
            data2,
            { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
          ).then((resp => {
            setRegisterSuccess(true);
            setModalMap(false); 
            document.getElementById('load').classList.remove('active');
          }))

       })
       .catch((error) => {
         setRegisterError(true);
        document.getElementById('load').classList.remove('active');
       });
   } else if (userType === 'I') {
     const data = new FormData();
     data.append('name', name);
     data.append('phone', phone);
     data.append('email', email);
     data.append('cnpj', cnpj.replace(/[.-\/]/g, '').replace('-', ''));
     data.append('cep', cep);
     data.append('street', endereco.logradouro)
     data.append('district', endereco.bairro)
     data.append('complement', endereco.complemento)
     data.append('city', endereco.cidade)
     data.append('state', endereco.estado)
     data.append('password', password);
     data.append('creci_j', creci_j)
     data.append('type', userType)
     data.append('status', 3)

     Axios.post(
       process.env.REACT_APP_API + '/api/register/real-estates',
       data,
       { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
     )
       .then((response) => {

        const data2 = new FormData()
            data2.append('user_id', response.data.id)
            data2.append('user_type', 'I')
            if(origin_response === 'Outros'){
                data2.append('response', origin_response_other)
            }else{
                data2.append('response', origin_response)
            }

            Axios.post(
                process.env.REACT_APP_API + '/api/register/new-feedback',
                data2,
                { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
              ).then((resp => {
                setRegisterSuccess(true);
                setModalMap(false); 
                document.getElementById('load').classList.remove('active');
              }))



       })
       .catch((error) => {
         setRegisterError(true);
        document.getElementById('load').classList.remove('active');
       });
   }
  };
  

  function handleBrokerClick() {
    setUserType('C');
    setImageSrc({ broker: BrokerActive, realState: RealStateNoActive });
  }

  function handleRealStateClick() {
      setUserType('I');
      setImageSrc({ broker: BrokerNoActive, realState: RealStateActive });
  }

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery]);


  const handlePhoneChange = (phone) => {
    setPhone(phone);
  };

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
  };

  const handleEmailExists = (isExist) => {
    setEmailIsExist(isExist);
  };

  const handleCpfExists = (isExist) => {
    setCpfIsExist(isExist);
  };

  const handleCnpjExists = (isExist) => {
    setCnpjIsExist(isExist);
  };

  const handleCpfChange = (value) => {
    setCpf(value);
  };

  const handleCreciChange = (value) => {
    setCRECI(value);
  };

  const handleCreciJChange = (value) => {
    setCRECIJ(value);
  };

  const handleCreciExists = (isExist) => {
    setCreciIsExist(isExist);
  };

  const handleCreciJExists = (isExist) => {
    setCreciJIsExist(isExist);
  };

  const handleCnpjChange = (value) => {
    setCnpj(value);
  };

  const handleCepChange = (value) => {
    setCep(value);
  };

  const handleEnderecoChange = (value) => {
    setEndereco(value);
  };
  
  const history = useHistory()

  return (
    <div className={styles.container_header}>
        <a href="/home"><img src={Logo} alt='Logo Fast Sales' className={styles.logo}></img></a>
        <div className={styles.menu}>
          <a className={styles.login} href="/login">
            Login
          </a>
          <div onClick={() => {
            history.push('/registre-se')
          }}>
            Cadastre-se
          </div>
        </div>

{modalMap &&
      <Modal isOpen={modalMap} toggle={openModal} className={
      !isMobile ? "modal-dialog-centered" : "modal-fullscreen"
      } size='lg'>
        <ModalHeader toggle={openModal}>
          
        </ModalHeader>
        <ModalBody>
          <div>
          <div className={styles.modal_title}>Falta só mais um pouco para concluir seu cadastro!</div>
            <div className={styles.container_input}>
              <div className={styles.container_inputs_modal}>
                <label>Nome</label>
                <input 
                  type="text" 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className={styles.container_inputs_modal}>
                <label className={styles.input_phone}>Telefone</label>
                <PhoneInput phoneNumber={phone} onPhoneChange={handlePhoneChange} />
              </div>
              <div>
              <div className={styles.container_inputs_modal}>
                <label className={styles.input_phone}>Email</label>
                <EmailInput onEmailChange={handleEmailChange} onExists={handleEmailExists}/>
              </div>
                <p className={styles.what_perfil}>Selecione seu perfil:</p>
                <div className={styles.container_button_perfil}>
                  <div className={userType === 'C' ? styles.button_broken_active : styles.button_broken} onClick={handleBrokerClick}>
                      <img src={imageSrc.broker} alt='Broker Active' className={styles.icon_broker}/>
                      Corretor
                  </div>

                  <div className={userType === 'I' ? styles.button_real_state_active : styles.button_real_state} onClick={handleRealStateClick}>
                      <img src={imageSrc.realState} alt="Real State Active" className={styles.icon_real_state}/>
                      Imobiliária
                  </div>
                </div>
              </div>
              {/* <div className={styles.container_inputs_modal_second}> */}
                    {userType === 'I' && (
                      <div className={styles.container_inputs_modal}>
                        <label className={styles.input_phone}>CNPJ</label>
                        <CnpjInput onCnpjChange={handleCnpjChange} onExists={handleCnpjExists}/>
                      </div>
                    )}
                    {userType === 'C' &&(
                      <div className={styles.container_inputs_modal}>
                        <label className={styles.input_phone}>CPF</label>
                        <CpfInput onCpfChange={handleCpfChange} onExists={handleCpfExists}/>
                      </div>
                    )}
                  {userType === 'C' && 
                      <div className={styles.container_inputs_modal}>
                        <label className={styles.input_phone}>CRECI</label>
                        <CreciInput onCreciChange={handleCreciChange} onExists={handleCreciExists}/>
                      </div>
                  }
                  {userType === 'I' && 
                      <div className={styles.container_inputs_modal}>
                        <label className={styles.input_phone}>CRECI Jurídico</label>
                        <CreciJInput onCreciJChange={handleCreciJChange} onExists={handleCreciJExists}/>
                      </div>
                  }
              {/* </div> */}
              <div className={styles.container_inputs_modal}>
                  <label className={styles.input_phone}>CEP</label>
                  <CepInput onCepChange={handleCepChange} onEnderecoChange={handleEnderecoChange} />
              </div>

              <div className={styles.container_inputs_modal} style={{width:'100%'}}>
                  <label className={styles.input_phone}>Como conheceu a Fast Sale?</label>
                  <select className="form-control" onChange={(e) => setOrigin_response(e.target.value)}>
                      <option value=''>Selecione</option>
                      <option value='Google'>Google</option>
                      <option value='Instagram'>Instagram</option>
                      <option value='Indicação'>Indicação</option>
                      <option value='YouTube'>YouTube</option>
                      <option value='Notícias'>Notícias</option>
                      <option value='Outros'>Outros</option>
                  </select>
              </div>

              {origin_response === 'Outros' &&
                  <div className={styles.container_inputs_modal} style={{marginTop:'10px', width:'100%'}}>
                      <textarea 
                          type="textarea" 
                          value={origin_response_other}
                          style={{width:'100%', borderRadius:'5px', borderColor:"#cdcdcd", padding:'5px'}}
                          rows="3"
                          placeholder="Escreva como nos conheceu"
                          onChange={(e) => setOrigin_response_other(e.target.value)}
                      />
                  </div>
              }


              <div className={styles.container_inputs_modal}>
                <label className={styles.input_phone}>Crie sua senha</label>
                <input 
                    type="password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
              </div>
              <div className={styles.container_inputs_modal}>
                <label className={styles.input_phone}>Confirme sua senha</label>
                <input
                    type="password" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
          {userType === 'C' &&
            ((emailIsExist === ''
            && cpfIsExist === ''
            && creciIsExist === '') ?
            <Button
              id="finalizar-cadastro" 
              className={styles.button_confirm}
              color="success" onClick={createRegister}
            >
              Cadastre-se gratuitamente
            </Button>
            :
            <Button
              id="finalizar-cadastro" 
              className={styles.button_confirm}
              color="success"
              disabled
            >
              Cadastre-se gratuitamente
            </Button>)
            }
            {userType === 'I' &&
            ((emailIsExist === ''
            && cnpjIsExist === ''
            && creciJIsExist === '') ?
            <Button
              id="finalizar-cadastro" 
              className={styles.button_confirm}
              color="success" onClick={createRegister}
            >
              Cadastre-se gratuitamente
            </Button>
            :
            <Button
              id="finalizar-cadastro" 
              className={styles.button_confirm}
              color="success"
              disabled
            >
              Cadastre-se gratuitamente
            </Button>)
            }


          </div>
        </ModalBody>
      </Modal>
}
      {registerError && (
        <SweetAlert 
          warning 
          onConfirm={() => setRegisterError(false)}
        >
          {userType === '' &&  <p>Por favor, informe seu perfil.</p>}
          {password !== confirmPassword &&  <p>Sua senha e confirmação da senha não coincidem.</p>}
          {(email === '' || phone === '' || name === '' || (origin_response=== 'Outros' && origin_response_other === '') || origin_response === '' || cep === '') &&  <p>Por favor, preencha todos os campos.</p>}
        </SweetAlert>
      )}

  {registerSuccess && (
        <SweetAlert
          success
          confirmBtnText="Fazer login"
          onConfirm={() => {setRegisterSuccess(false); window.open("/login", '_parent')}}
        >
          <p style={{ fontWeight: 600, fontSize: '1.2rem' }}>Cadastro realizado com sucesso!</p>
        </SweetAlert>
      )}  
      
      <div id="load" className="load">
        <Spinner className="me-2" color="success" />
      </div>
    </div>
  );
}

export default HeaderMobile;

