import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Container, Spinner, Modal, ModalHeader, ModalBody, Label, Input, ModalFooter, Button } from "reactstrap";
import Axios from 'axios'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import './../../assets/scss/custom/components/_brokerTeam.scss'
import ImovelCard from '../../components/ImovelCard/ImovelCard';
import { saveAs } from 'file-saver';

const id = window.location.search.replace('?', '');

class RealBrokerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      realTeam:[],
      member:[],
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Corretores", link: "#" },
    ],
    };
  }



  componentDidMount(){
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        Axios.get(process.env.REACT_APP_API + '/api/auth/real_estate_teams/by_real_estate/' + localStorage.getItem('id'),
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            this.setState({realTeam: response.data.realEstateTeams})
            this.setState({member: response.data.realEstateTeams.filter(member => member.broker.id == id)[0]?.broker})
        
        })
        

      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }

  formatPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      const cleaned = phoneNumber.toString()?.replace(/\D/g, '');
  
      const formatted = `(${cleaned.substring(0, 2)})${cleaned.substring(2, 7)}-${cleaned.substring(7)}`;
  
      return formatted;
    }
  
    return '';
  }

  sumPrices(properties){
    if (!properties) {
      return 0;
    }
  
    const totalValue = properties.reduce((total, property) => total + property.sale_value, 0);
  
    return totalValue;
  }

  render() {

    const {member} = this.state;

    return (
        <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Perfil corretor" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col lg={4}>
              <Card style={{minHeight:'220px'}}>
                <CardBody>
                    <Row>
                        <Col className="col-auto">
                            <div className="profileCover" style={{backgroundImage:'url(' + member.profile_photo + ')'}}></div>
                        </Col>
                        <Col className="col-auto">
                            <div className="profileInfo">
                                <div className="name">
                                    <h3>{member.name}</h3>
                                    <p>CRECI: {member.creci}</p>
                                </div>
                                <div className="contact">
                                    <p>{this.formatPhoneNumber(member?.phone)}</p>
                                    <p>{member.email}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={8}>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <Row style={{justifyContent:'space-between'}}>
                                    <Col className="col-auto" style={{minHeight:'100%', display:'flex', alignItems:'center'}}>
                                        <h3 style={{fontSize:'16px'}}>Analytics</h3>
                                    </Col>
                                    <Col className="col-auto">
                                        <Button className="relatorio-button" onClick={() => {
                                            document.getElementById('load').classList.add('active');
                                            const axiosOptions = {
                                            responseType: 'arraybuffer',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                "Authorization": "Bearer " + localStorage.getItem('token')
                                            }
                                            };
                                            const data = {
                                                user_id: member?.id,
                                                type: 'C'
                                            };
                                            Axios.post(process.env.REACT_APP_API + '/api/auth/generate-user-report', data, axiosOptions)
                                                .then(response => {
                                                const blob = new Blob([response.data], {
                                                    type: 'application/octet-stream'
                                                });
                                                const filename = 'Relatório(' + member?.name + ').csv';
                                                saveAs(blob, filename);
                                                document.getElementById('load').classList.remove('active');
                                                });
                                        }}><span><i className="ri-download-2-line"></i></span>Relatório</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={6} lg={3} className="infoCol">
                                        <p><i className="ri-home-2-line align-middle me-1"></i>Captações</p>
                                        <h3>{member?.properties?.length}</h3>
                                    </Col>
                                    <Col md={6} lg={3} className="infoCol">
                                        <p><i className="ri-currency-line align-middle me-1"></i>Valor em captações</p>
                                        <h3><span style={{fontSize:'12px'}}>R$</span>{(this.sumPrices(member?.properties) / 100)?.toLocaleString('pt-br', {minimumFractionDigits: 2})}</h3>
                                    </Col>
                                    <Col md={6} lg={3} className="infoCol">
                                        <p><i className="ri-download-2-line align-middle me-1"></i>Download de material</p>
                                        <h3>{member?.recent_downloaded?.length}</h3>
                                    </Col>
                                    <Col md={6} lg={3} className="infoCol infoEnd">
                                        <p><i className="ri-file-text-line align-middle me-1"></i>Contratos de parceria</p>
                                        <h3>{member?.accepted_contracts?.length}</h3>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
              <Card>
                <CardBody>
                    <Row style={{justifyContent:'flex-start'}}>
                        <Col xs={12}>
                            <h3 style={{fontSize:'16px'}}>Imóveis captados</h3>
                        </Col>
                        {member.properties?.map((property, index) => (
                            <Col key={index} className="col-auto" style={{padding:'0', justifyContent:'center', display: 'flex', alignItems: 'center'}}>
                                <ImovelCard favoritos={[]} imovel={property} premium={1}/>
                            </Col>
                        ))}
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default RealBrokerProfile;