import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, CardTitle, CardText, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_integration.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import Select from "react-select";
import SweetAlert from 'react-bootstrap-sweetalert';
require('moment/locale/pt.js');


class PropertyWishlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoritos: [],
      allFavoritos: [],
      addPropertyId:'',
      deletePropertyId:null,
      user:[],
      search:'',
      confirm_both: false,
      success_dlg: false,
      viewport:[
        {
          dataField: 'mobile',
          text: 'Imóveis'
        },
      ],
      error_dlg:false,
      dynamic_title: "",
      dynamic_description: "",
      delete_id:"",
      delete_sku:"",
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Favoritos", link: "#" },
    ],
    }
  }


  componentDidMount(){
      document.getElementById('load').classList.add('active')
      
      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
  
          Axios.post(process.env.REACT_APP_API + '/api/auth/my-favorite', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id')
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                  this.setState({favoritos: response.data})
                  this.setState({allFavoritos: response.data})
    
                  document.getElementById('load').classList.remove('active')

                  if(window.screen.width > 768){
                    this.setState({viewport: [
                      {
                        dataField: "one_photo",
                        text: "Foto"
                      },
                      {
                        dataField: 'sku',
                        text: 'Código'
                      },
                      {
                        dataField: "title",
                        text: "Nome"
                      },
                      {
                        dataField: "created_at",
                        classes:"formatDate",
                        text: "Cadastro"
                      },
                      {
                        dataField: "sale_value",
                        text: "Valor"
                      },
                      // {
                      //   dataField: "workflow",
                      //   text: "WorkFlow"
                      // },
                      {
                        dataField: "edit",
                        events: {
                          onClick: (e, column, columnIndex, row, rowIndex) => { 
                            // localStorage.setItem('edit_property_sku', row.sku)
                            setTimeout(() => {
                               window.open( 'imovel/' + row.slug , '_parent')
                            }, 300);
                           },
                        },
                        text: "Ver"
                      },
                      {
                        dataField: "delete",
                        events: {
                          onClick: (e, column, columnIndex, row, rowIndex) => { 
                            this.setState({ confirm_both: true })
                            this.setState({ delete_id: row.id })
                            this.setState({ delete_sku: row.sku })
                            this.setState({ deleted_channel: row.channel })
                            this.setState({ deletePropertyId: row.id })
                          },
                        },
                        text: "Excluir"
                      },
                    ],
                    })}
              })
              .catch(response => {
                document.getElementById('load').classList.remove('active')
              })
  
        })
        .catch(response =>{
          localStorage.removeItem("history_url")
          setTimeout(() => {
            window.open("/login", '_parent')
          }, 300);
        })
  }

  render() {

    const {favoritos, allFavoritos} = this.state;

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos imóveis', value: favoritos.length
        }]

    };


    const favoritosImoveisObject = []

    {favoritos.map(imovel => {
      const novoImovel = {
        id: imovel?.property_id,
        one_photo: <div style={{backgroundImage:'url(' + imovel?.one_photo + ')', height:'60px', width: '60px', borderRadius:'5px', backgroundPosition:'center center', backgroundSize:'cover'}}></div>,
        edit: <i className="ri-eye-fill"></i>,
        delete: <i className="ri-delete-bin-6-line"></i>,
        sku: imovel?.sku,
        title: imovel?.title,
        channel: 1,
        created_at: moment(imovel?.created_at).format('DD/MM/YYYY'),
        sale_value: (imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}),
        status: imovel?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>,
        slug: imovel?.slug,
        mobile:<Container style={{padding:'0'}}>
        <Row style={{marginTop:'15px'}}>
          <Col className="col-auto">
            <img src={imovel?.one_photo} style={{
              objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
            }}></img>
          </Col>
          <Col>
          <p style={{lineHeight:'1.2'}}>{imovel?.sku}</p>
          <small>{imovel?.title}</small>
          <div style={{marginTop:'10px'}}><p style={{lineHeight:'1.2'}}>R$ {(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</p></div>
          </Col>
        </Row>
        <Row style={{marginTop:'15px', justifyContent:'flex-end'}}>
          <Col className="col-auto" style={{textAlign:'right'}}><i className="ri-eye-fill" onClick={() => {
               setTimeout(() => {
                  window.open("./../imovel/" + imovel?.slug , '_parent')
               }, 300);
            }}></i></Col>
          <Col className="col-auto" style={{textAlign:'right'}}><i className="ri-delete-bin-6-line" onClick={() => {
              this.setState({ confirm_both: true })
              this.setState({ delete_id: imovel?.id })
              this.setState({ delete_sku: imovel?.sku })
              this.setState({ deleted_channel: imovel?.channel })
              this.setState({ deletePropertyId: imovel?.property_id })
          }}></i></Col>
        </Row>
      </Container>,
      }
      favoritosImoveisObject.push(novoImovel); 
    })}

    return (
        <div className="page-content" id="whishlistPage">
        <Container fluid={true}>
          <Breadcrumbs title="Favoritos" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{marginBottom:'20px'}}>
                    <Col>
                      <h5>Imóveis favoritos</h5>
                    </Col>
                  </Row>
                  <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                      <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                        <Input
                        placeholder="Buscar código"
                        onChange={(e) => this.setState({favoritos: allFavoritos.filter(imovel => imovel.sku.toLowerCase().includes(e.target.value.toLowerCase()))})}></Input>
                      </Col>
                    </Row>
                  {favoritos.length < 1 ?
                    <p>Você ainda não favoritou nenhum imóvel.</p>
                  :
                  <>
                    <div className="table-responsive">
                    <BootstrapTable
                      keyField='id'
                      data={favoritosImoveisObject}
                      columns={this.state.viewport}
                      // expandRow={expandRow}
                      pagination={paginationFactory(options)}
                      // selectRow={false}
                    />
                    </div>
                  </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              {this.setState({
                confirm_both: false,
                success_dlg: true,
                dynamic_title: "Deletado",
                dynamic_description: "Seu imóvel foi excluído da lista de integração."
              })
                Axios.post(process.env.REACT_APP_API + '/api/auth/favorite-delete', {
                  user_id:parseInt(localStorage.getItem('id')),
                  type:localStorage.getItem('access'),
                  property_id: this.state.deletePropertyId
                },
                { headers: { Authorization: 'Bearer ' + localStorage.getItem('token')}})
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "Seu imóvel ainda está na lista de integração."
              })
            }
          >
            <p className="alertText">Você tem certeza que deseja excluir: <b>{this.state.delete_sku}</b> dos seus imóveis favoritos?</p>
          </SweetAlert>
        ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => {
                this.setState({ success_dlg: false })
                setTimeout(() => {
                  window.location.reload()
                }, 300);
              }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

          {this.state.error_dlg ? (
            <SweetAlert
              error
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ error_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}


export default PropertyWishlist;