import React, { Component } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import Lightbox from "react-image-lightbox";

import 'swiper/css';
import "swiper/css/navigation";

import { Navigation } from "swiper"
import Axios from 'axios';
import ContractUse from './ContractUse';
import { Button } from 'reactstrap';

class SingleImovelCardCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            count:0,
            allPhotos:[],
            verifyPremium:false,
            contractAccept:'false',
            isGalleryZoom: false,
            acceptContract: false,
            showCustomButton: true,
        }
        this.handleContractClosed = this.handleContractClosed.bind(this);
    }
    
    componentDidUpdate(prevProps) {
      let params = new URLSearchParams(window.location.search)
      const access = params.get('ce29')
      const userId = params.get('ba05a')
      if (prevProps.photos !== this.props.photos) {
        Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
          type:localStorage.getItem('access'),
          user_id:localStorage.getItem('id'),
          property_id:this.props.imovel.id
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          this.setState({ showCustomButton: false })
          if(userId){
            Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
              type:access,
              user_id:userId
              }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              this.setState({verifyPremium: true})
            })
            .catch(response => {
              this.setState({verifyPremium: false})
            })
          }

          this.setState({ allPhotos: this.props.photos.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1) });
        })
        .catch(response => {
          if(this.props.enterprise){
            this.setState({ allPhotos: this.props.photos.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1) });
            this.setState({ showCustomButton: false })
          }else{
            if(this.props.public !== 1){
              this.setState({ allPhotos: this.props.photos.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1).slice(0,3) });
              this.setState({ contractAccept: 'accept' });
            }else{
              if(userId){
                Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
                  type:access,
                  user_id:userId
                  }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  this.setState({verifyPremium: true})
                })
                .catch(response => {
                  this.setState({verifyPremium: false})
                })
              }
              this.setState({ allPhotos: this.props.photos.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1) });
            }
          }
        })
      }
    }
  
    handleContractClosed() {
      this.setState({ acceptContract: false });
      Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
        type:localStorage.getItem('access'),
        user_id:localStorage.getItem('id'),
        property_id:this.props.imovel.id
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        this.setState({ showCustomButton: false, contractAccept: 'accepted' })
        this.setState({ allPhotos: this.props.photos });
      })
      .catch(response => {
        this.setState({ showCustomButton: true })
      })
    }    


    render() {

        const {photoIndex, allPhotos, verifyPremium, showCustomButton} = this.state;
        const { premium, fit } = this.props;

        const customToolbarButtons = showCustomButton ? [
          <Button key="more" onClick={() => {
            this.setState({acceptContract: true})
            }}>
            Ver mais fotos
          </Button>
        ] : [];

        
        return (
            <React.Fragment>
              <ContractUse
                acceptContract={this.state.acceptContract}
                onContractClosed={this.handleContractClosed}
                onContractAcceptFotos={this.props.onContractAcceptFotos}
                imovel={this.props.imovel}
                premium={premium}
                origem={'fotos'}
              />
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                speed={500}
                effect='fade'
                navigation={true}
                breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      initialSlide: 0,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      initialSlide: 0,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                      initialSlide: 0,
                    },
                    1360: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                      initialSlide: 0,
                    },
                  }}
                modules={[Navigation]}
                className="SwiperImovelCard"
                >                   
                    {
                      (this.props.tour && this.props.tour !== '' && this.props.tour !== 'null') &&  
                      <SwiperSlide>
                        <div style={{objectFit: 'cover', height: '420px', width: '100%', borderRadius: '5px'}}>
                          <a href={this.props.tour} target="_blank" rel="noreferrer">
                            <button style={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              border: 'none',
                              opacity: '.7',
                              borderRadius: '0 0 5px 5px',
                              padding: '0 10px'
                            }}>Ver tela cheia</button>
                          </a>
                          <iframe src={this.props.tour} width="100%" height="100%" style={{borderRadius:"15px", border:"none"}}></iframe>
                        </div>
                      </SwiperSlide>
                    }
                    {
                      allPhotos?.map((photos, index) => (
                        <SwiperSlide key={index}>
                          <img 
                            alt='Foto imóvel'
                            src={(premium || fit) ? photos?.large_image : photos?.large_image.replace('default', 'fs')} 
                            className="slidePhoto" 
                            onClick={() => {
                                if(this.state.contractAccept !== 'accept') {
                                  this.setState({ isGalleryZoom: true, photoIndex: index })
                                } else {
                                  Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
                                    type:localStorage.getItem('access'),
                                    user_id:localStorage.getItem('id'),
                                    property_id:this.props.imovel?.id
                                    }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                  .then(response => {
                                    this.setState({ allPhotos: this.props.photos, isGalleryZoom: true, photoIndex: index });
                                  })
                                  .catch(response => {
                                    this.setState({acceptContract: true})
                                  })
                                }
                            }}
                          ></img>
                        </SwiperSlide>
                      ))
                    }
                  {this.state.contractAccept === 'accept' &&
                    <SwiperSlide>
                      <div 
                        className="slidePhoto" 
                        style={{
                          backgroundColor:'#333',
                          color:'#fff',
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          cursor:'pointer',
                          flexDirection:'column'
                        }} 
                        onClick={() => {
                          Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
                            type:localStorage.getItem('access'),
                            user_id:localStorage.getItem('id'),
                            property_id:this.props.imovel?.id
                            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                          .then(response => {
                            this.setState({ allPhotos: this.props.photos });
                          })
                          .catch(response => {
                            this.setState({acceptContract: true})
                          })
                        }}
                      >
                        <i className="ri-camera-fill" style={{fontSize:'30px'}}></i>
                        <p style={{fontSize:'18px'}}>Ver todas fotos ({this.props.photos.length})</p>
                      </div>
                    </SwiperSlide>
                  }
                </Swiper>
                {this.state.isGalleryZoom ? (
                  <Lightbox
                    mainSrc={(premium || fit) ?  allPhotos[photoIndex]?.url : allPhotos[photoIndex]?.url.replace('default', 'fs')}
                    nextSrc={(premium || fit) ?  allPhotos[(photoIndex + 1) % allPhotos.length]?.url : allPhotos[(photoIndex + 1) % allPhotos.length]?.url.replace('default', 'fs')}
                    prevSrc={
                      (premium || fit) ?  allPhotos[(photoIndex + allPhotos.length - 1) % allPhotos.length]?.url : allPhotos[(photoIndex + allPhotos.length - 1) % allPhotos.length]?.url.replace('default', 'fs')
                    }
                    onCloseRequest={() => this.setState({ isGalleryZoom: false })}
                    onMovePrevRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + allPhotos.length - 1) % allPhotos.length
                    })
                    }
                    onMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + 1) % allPhotos.length
                    })
                    }
                  />
                ) : null}
            </React.Fragment>
        );
    }
}

export default SingleImovelCardCarousel;   