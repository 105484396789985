import styles from '../styles/About.module.css';
import People from '../assets/renatosite.png';
import { useEffect } from 'react';
import ContentEditable from "react-contenteditable";
import Axios from 'axios';
import { useState } from 'react';

const About = ({data}) => {

  const [aboutMoreTog, setAboutMoreTog ] = useState(false)
  const [ highTitle, setHighTitle] = useState('Sobre nós')
  const [ highTitleEnabled, setHighTitleEnabled] = useState(true)

  useEffect(() => {

    if(localStorage.getItem('about_title')){
      setHighTitle(localStorage.getItem('about_title'))
    }

  }, []);

  return(
    <section className={styles.bg}>
      <img src={data?.profile_photo} alt="" className={styles.people} />

      <div>
        <div className={styles.titleContainer}>
        <ContentEditable
            html={highTitle}
            className={styles.titleEdit}
            id='aboutTitleEdit'
            disabled={highTitleEnabled}
            onChange={(e) => {
              setHighTitle(e.target.value)
              localStorage.setItem('about_title', e.target.value)
            }}
            onClick={(e) => {
              if(highTitle === 'Sobre nós' && !highTitleEnabled){
                setHighTitle('')
                localStorage.setItem('about_title', '')
              }
            }}
          />
          <div className={styles.actionIcons}>
            <label className="title">
              <span onClick={() => {
                if(!highTitleEnabled){
                  setHighTitleEnabled(true)
                  if(highTitle === ''){
                    setHighTitle('Sobre nós')
                    localStorage.setItem('about_title', 'Sobre nós')
                  }
                }else{
                  setHighTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('aboutTitleEdit').focus()
                    if(highTitle === 'Sobre nós'){
                      setHighTitle('')
                      localStorage.setItem('about_title', '')
                    }
                  }, 300);
                }
              }}>{highTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
              <i className="ri-text" onClick={() => {
                if(!highTitleEnabled){
                  setHighTitleEnabled(true)
                  if(highTitle === ''){
                    setHighTitle('Sobre nós')
                    localStorage.setItem('about_title', 'Sobre nós')
                  }
                }else{
                  setHighTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('aboutTitleEdit').focus()
                    if(highTitle === 'Sobre nós'){
                      setHighTitle('')
                      localStorage.setItem('about_title', '')
                    }
                  }, 300);
                }
              }}></i>
            </label>
          </div>
        </div>
        {!aboutMoreTog ?
          <p className={styles.text}><pre-line>{data?.about}</pre-line></p>
          :
          <p className={styles.textExpand}><pre-line>{data?.about}</pre-line></p>
        }
        <div className={styles.aboutMore} onClick={() => {
          setAboutMoreTog(!aboutMoreTog)
          }}>{!aboutMoreTog ? 'Ver mais' : 'Ver menos'}
        </div>
      </div>
    </section>
  )
}

export default About;