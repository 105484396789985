import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

import premiumBanner from './../../assets/images/banner_dash_seja_premium.jpg'

class MktBanner extends Component {

    render() {
        return (
            <React.Fragment>
                <Card style={{
                    maxHeight: "150px",
                    minHeight: "150px",
                    overflow: 'hidden auto',
                    display:'flex',
                    alignItems:'center', 
                    justifyContent:'center',
                    backgroundColor:'#3E5E3F'}}>
                    {/* <h3 style={{fontSize:'14px', color:'#cdcdcd'}}>Aumente seu faturamento com captações</h3>
                    <h2 style={{fontWeight:'600', color:'#cdcdcd'}}>Assine o <span style={{color:'#fff'}}>Fast Premium</span></h2>
                    <h3 style={{fontSize:'14px', color:'#cdcdcd'}}>e coloque seus imóveis na Fast</h3>
                    <Button className="btn-light" color="#333" style={{marginTop:'5px'}}>Saiba mais</Button> */}
                    <Row>
                        <Col style={{padding:'20px', textAlign:'center'}}>
                            <h2 style={{fontWeight:'600', color:'#cdcdcd'}}>{
                                localStorage.getItem('access') === 'C' && <>Corretor</>
                            }{
                                localStorage.getItem('access') === 'I' && <>Imobiliária</>
                            } <span style={{color:'#fff'}}> Fast Premium</span></h2>
                            <h2 style={{fontWeight:'600', color:'#fff', fontSize:'16px'}}>Você ainda tem XX dias de Fast Premium</h2>
                        </Col>
                    </Row>
                </Card>
            </React.Fragment>
        );
    }
}

export default MktBanner;