import Pauta from './../assets/pauta.png';
import Rede from './../assets/rede.png';
import Leads from './../assets/leads.jpg';

const premium = [
  {
    title: 'Mais produtos na carteira',
    text: 'Tenha sempre à mão o produto que seu cliente procura.',
    backgroundUrl: Pauta,
  },
  {
    title: 'Venda em rede',
    text: 'Milhares de corretores anunciando seus imóveis em sites e portais.',
    backgroundUrl: Rede,
  },
  {
    title: 'Receba novas Leads',
    text: 'Vendendo em parceria seus imóveis alcançam milhares de clientes qualificados todos os dias.',
    backgroundUrl: Leads,
  },
];

export default premium;