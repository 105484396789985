import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, CardImg, Row, CardTitle, CardText, Badge } from "reactstrap";

import './../../assets/scss/custom/components/_horizontalCard.scss'
import './../../assets/scss/custom/components/_imovelCard.scss';

class EnterpriseUnitHorizontalCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }


    render() {
        return (
            <React.Fragment>
              <Link to={"../unidade/" + this.props.imovel?.slug} target="_parent">
                <div className="horizontalCard" onClick={() => {
                  setTimeout(() => {
                    window.open("../unidade/" + this.props.imovel?.slug, '_parent')
                  }, 300);
                }}>
                    <Row className="no-gutters align-items-center">
                        <Col xs={4} style={{position:'relative'}}>
                          {this.props.imovel?.one_photo ?
                            <CardImg className="img-fluid img-horizontal-card" src={this.props.imovel?.property_enterprise_type?.one_photo?.medium_image} alt="Skote"/>
                            :
                            <CardImg className="img-fluid img-horizontal-card" src={this.props.imovel?.property_enterprise_type?.photos?.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1)[0]?.medium_image} alt="Skote" />
                          }
                        </Col>
                        <Col xs={8}>
                            <CardBody style={{padding:'0', justifyContent:'space-between', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                                <Badge className="bg-light me-1 rounded-pill" style={{color:'#333', marginBottom:'5px'}}>{this.props.imovel?.sku}</Badge>
                                <CardTitle style={{fontSize:'16px', fontWeight:'600'}}>{this.props.imovel?.property_enterprise_type?.title}</CardTitle>
                                <Row style={{width:'100%', marginTop:'10px'}}>
                                    <Col>
                                      <div className="price">{(this.props.imovel?.price / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                                    </Col>
                                    <Col className="col-auto" style={{display:'flex', alignItems:'flex-end', justifyContent:'flex-end', paddingRight: '0'}}>
                                        <div className="viewProperty"><i className="ri-eye-line"></i><span>Ver unidade</span></div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Col>
                    </Row>
                </div>
              </Link>
            </React.Fragment>
        );
    }
}

export default EnterpriseUnitHorizontalCard;