import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import { Badge, Button, Card, CardBody, CardText, CardTitle, Col, Container, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from "../../components/Inputs/CurrencyInput";
import * as XLSX from 'xlsx';

const id = window.location.search.replace('?id=', '')

class TypeProfile extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      typeUnit:[],
      erro_send_aprovation:false,
      modal_center:false,
      unitsInputs:[],
      units:[],
      selectedUnitId:'',
      setectedUnitPayment:[],
      photos:'',
      planilha:null,
      toggleSwitch:true,
      paymentCondition:[],
      new_unit_error:false,
      }
      this.tog_center = this.tog_center.bind(this);
      this.createTypeUnit = this.createTypeUnit.bind(this);
      this.createUnit = this.createUnit.bind(this);
    }

    tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center
        }));
        this.removeBodyCss();
    }
    
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    addInput = () => {
        if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
            this.setState({error_auth:true})
          }else{
            const newInput = { sku: '', floor: '', price: '', offer_price: '', payment_condition: '', status: '' };
            this.setState((prevState) => ({
            unitsInputs: [...prevState.unitsInputs, newInput],
            }));
        }
      };

      addPaymentInput = () => {
        if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
            this.setState({error_auth:true})
        }else{
            const newInput = { type: '', type_value: 'percent', value: '', parcels: '', observation: '' };
            this.setState((prevState) => ({
            paymentCondition: [...prevState.paymentCondition, newInput],
            }));
        }
      };
      
      updatePaymentInput = (index, field, value) => {
        this.setState((prevState) => {
          const updatedInputs = [...prevState.paymentCondition];
          updatedInputs[index][field] = value;
          return { paymentCondition: updatedInputs };
        });
      };
      
      updateInput = (index, field, value) => {
        this.setState((prevState) => {
          const updatedInputs = [...prevState.unitsInputs];
          updatedInputs[index][field] = value;
          return { unitsInputs: updatedInputs };
        });
      };

      updateExistInput = (index, field, value) => {
        this.setState((prevState) => {
          const updatedInputs = [...prevState.units];
          updatedInputs[index][field] = value;
          return { units: updatedInputs };
        });
      };
    
      handleInputChange = (e, index, field) => {
        const { name, value } = e.target || e;
        this.setState((prevState) => {
          const updatedUnits = [...prevState.payment_items];
          updatedUnits[index][field] = value;
          return { payment_items: updatedUnits };
        });
      };

    componentDidMount(){
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
         Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types/' + id,
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            console.log(response.data.photos)
          this.setState({
            typeUnit: response.data,
            units:response.data.units,
            photo:response.data?.photos?.sort((a, b) => a.order - b.order)[0]?.url
          })
        })

        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + localStorage.getItem('enterprise_selected_id'), {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
          }).then(response => {
             this.setState({enterprise: response.data})
          })
        })
        .catch(response =>{
            setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
            })  
    }

    createTypeUnit(event) {
        document.getElementById('load').classList.add('active')

        const data = new FormData();
        data.append('title', this.state.title)
        data.append('property_enterprise_id', id)


        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types', data, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
            setTimeout(() => {
                window.open('./../empreendimento-tipo?id=' + response.data.data.id, '_parent')
            }, 300);
         })
   }

   createSlug(title) {
    let slug = title.toLowerCase().replace(/[^\w\s-]/g, '');
  
    slug = slug.replace(/\s+/g, '-');
  
    return slug;
  }


   createUnit(input){
        document.getElementById('load').classList.add('active')
        
        const dataEnterpriseUnits = new FormData();

          dataEnterpriseUnits.append(`unit[0][slug]`, this.createSlug(this.state.enterprise.title + input.sku))
          dataEnterpriseUnits.append(`unit[0][sku]`, input.sku)
          dataEnterpriseUnits.append(`unit[0][floor]`, 0)
          dataEnterpriseUnits.append(`unit[0][price]`, parseInt(input.price.replace('R$ ', '').replace(',', '').replace(/\./g,'')))
          dataEnterpriseUnits.append(`unit[0][offer_price]`, parseInt(input.offer_price.replace('R$ ', '').replace(',', '').replace(/\./g,'')))
          dataEnterpriseUnits.append(`unit[0][payment_condition]`, 0)
          dataEnterpriseUnits.append(`unit[0][status]`, input.status)
          dataEnterpriseUnits.append(`unit[0][property_enterprise_type_id]`, parseInt(id))

        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-types-units', dataEnterpriseUnits, {
          headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {

            const dataPaymentCondition = new FormData();

            for(var i = 0; this.state.enterprise.payment.length > i; i++){
                dataPaymentCondition.append(`condition[${i}][type]`, this.state.enterprise.payment[i].type)
                dataPaymentCondition.append(`condition[${i}][type_value]`, this.state.enterprise.payment[i].type_value)
                if(this.state.enterprise.payment[i].value && this.state.enterprise.payment[i].value !== ''){
                    dataPaymentCondition.append(`condition[${i}][value]`, this.state.enterprise.payment[i].value)
                }
                if(this.state.enterprise.payment[i].currency_value && this.state.enterprise.payment[i].currency_value !== ''){
                    dataPaymentCondition.append(`condition[${i}][currency_value]`, this.state.enterprise.payment[i].currency_value)
                }
                dataPaymentCondition.append(`condition[${i}][parcels]`, this.state.enterprise.payment[i].parcels)
                dataPaymentCondition.append(`condition[${i}][observation]`, this.state.enterprise.payment[i].observation)
                dataPaymentCondition.append(`condition[${i}][property_enterprise_type_unit_id]`, response.data.data[0].id)
            }
    
            Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-type-payment-condition', dataPaymentCondition, {
                headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
            }).then(resp => {
                document.getElementById('load').classList.remove('active')
                this.setState({unitsInputs: []})
    
                Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types/' + id,
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  this.setState({
                    typeUnit: response.data,
                    units:response.data.units,
                    photo:response.data?.photos[0]?.url
                  })
                })
        
                Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + localStorage.getItem('enterprise_selected_id'), {
                    headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                  }).then(response => {
                     this.setState({enterprise: response.data})
                  })
    
            })
            })
   }

   createPayment(input){
    this.setState({toggleSwitch:true})
    document.getElementById('load').classList.add('active')

        const dataPaymentCondition = new FormData();

        dataPaymentCondition.append(`condition[0][type]`, input.type)
        dataPaymentCondition.append(`condition[0][type_value]`, input.type_value)
        if(input.value && input.value !== ''){
            dataPaymentCondition.append(`condition[0][value]`, input.value)
        }
        if(input.currency_value && input.currency_value !== ''){
            dataPaymentCondition.append(`condition[0][currency_value]`, parseInt(input.currency_value.replace('R$ ', '').replace(',', '').replace(/\./g,'')))
        }
        dataPaymentCondition.append(`condition[0][parcels]`, input.parcels)
        dataPaymentCondition.append(`condition[0][observation]`, input.observation)
        dataPaymentCondition.append(`condition[0][property_enterprise_type_unit_id]`, this.state.selectedUnitId)

        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-type-payment-condition', dataPaymentCondition, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
            document.getElementById('load').classList.remove('active')
            this.setState({paymentCondition: []})
            Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types/' + id,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(resp => {
                this.setState({setectedUnitPayment: resp.data?.units?.filter(unit => unit.id === this.state.selectedUnitId)[0]?.payment})
            })
        })
}

   handleClick = (unit) => {
    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
        this.setState({error_auth:true})
    }else{
    const updatedUnits = this.state.setectedUnitPayment.map((u) =>
        u.id === unit.id ? { ...u, isLoading: true } : u
    );
    this.setState({ setectedUnitPayment: updatedUnits });

    Axios.post(
        process.env.REACT_APP_API +
            '/api/auth/enterprise/enterprise-properties-unit-payment/' +
            unit.id,
            unit,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        .finally(() => {
            const updatedUnits = this.state.setectedUnitPayment.map((u) =>
                u.id === unit.id ? { ...u, isLoading: false } : u
            );
            this.setState({ setectedUnitPayment: updatedUnits });
        });
    }
};

handleUnitClick = (unit) => {
    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
        this.setState({error_auth:true})
    }else{
    const updatedUnits = this.state.units.map((u) =>
        u.id === unit.id ? { ...u, isLoading: true } : u
    );
    this.setState({ units: updatedUnits });

    Axios.post(
        process.env.REACT_APP_API +
            '/api/auth/enterprise/enterprise-properties-types-unit/' +
            unit.id,
        {
            unit,
        },
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        .finally(() => {
            const updatedUnits = this.state.units.map((u) =>
                u.id === unit.id ? { ...u, isLoading: false } : u
            );
            this.setState({ units: updatedUnits });
        });
    }
};

handleInputChange = (e, index, field) => {
    const { name, value } = e.target || e;

    this.setState((prevState) => {
        const updatedUnits = [...prevState.setectedUnitPayment];
        updatedUnits[index][field] = value;
        return { setectedUnitPayment: updatedUnits };
        });
  };

  toggleTypeValue = (index) => {
    this.setState((prevState) => {
      const updatedItems = [...prevState.setectedUnitPayment];
      const currentItem = updatedItems[index];
  
      // Alternar entre 'percent' e 'currency' com base no valor atual
      currentItem.type_value = currentItem.type_value === 'percent' ? 'currency' : 'percent';
  
      return { setectedUnitPayment: updatedItems };
    });
  };


    render(){
        const {typeUnit} = this.state;

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={typeUnit?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div id="enterpriseProfile">
                                        <div className="profile">
                                            <div
                                            onClick={this.tog_logo}
                                            style={{
                                                backgroundImage:'url(' + this.state.photo + ')',
                                                backgroundSize:'cover',
                                                backgroundPosition:'center center',
                                                width:'200px', height: '200px',
                                                backgroundColor:'#cdcdcd',
                                                position:'relative',
                                                borderRadius:'8px',
                                                marginBottom:'15px'
                                                }}>
                                                </div>
                                            <h3>{typeUnit?.title}</h3>
                                        </div>
                                        <div className="enterpriseContainerButtons">
                                            <div className={`enterpriseButton informacoes ${
                                                (typeUnit.private_area
                                                    && typeUnit.total_area
                                                    && typeUnit.description
                                                    && typeUnit.rooms
                                                    && typeUnit.suites
                                                    && typeUnit.garage
                                                    && typeUnit.bathrooms) ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../info-tipo-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Informações
                                                {!(typeUnit.private_area
                                                    && typeUnit.total_area
                                                    && typeUnit.description
                                                    && typeUnit.rooms
                                                    && typeUnit.suites
                                                    && typeUnit.garage
                                                    && typeUnit.bathrooms) &&
                                                    <small>*</small>}
                                            </div>
                                            <div className={`enterpriseButton caracteristicas ${
                                                typeUnit.details?.length > 0
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../caracteristicas-tipo-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Características
                                            </div>
                                            <div className={`enterpriseButton fotos ${
                                                typeUnit.photos?.length > 0
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../fotos-tipo-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Fotos do tipo de unidade
                                                {!(typeUnit.photos?.length > 0) && <small>*</small>}
                                            </div>
                                            <div className={`enterpriseButton plantas ${
                                                typeUnit.plans?.length > 0
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../plantas-tipo-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Plantas do tipo de unidade
                                            </div>
                                            <div className={`enterpriseButton videos ${
                                                typeUnit.videos?.length > 0
                                                ? <></> : 'inactive'
                                            }`} onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../videos-tipo-empreendimento?id=' + id, '_parent')
                                                }, 100);
                                            }}>
                                                <span className="buttonIcon"></span>
                                                Vídeos
                                            </div>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col xs={12}><span style={{
                                            width:'100%',
                                            height:'1px',
                                            backgroundColor:'#cdcdcd',
                                            display:'block',
                                            margin:'30px auto 30px'
                                        }}></span></Col>
                                    </Row>
                                    <Row style={{maxWidth:'1240px', margin: '10px auto'}}>
                                      <Col md={6} style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Unidades</h5></Col>   
                                      <Col xs={12}>
                                        <Card style={{padding: '15px', backgroundColor:'#f4f4f4', marginTop: '20px', maxWidth:'640px'}}>
                                            <Row>
                                                <Col className="col-auto">
                                                    <i className="ri-error-warning-line" style={{fontSize:'30px'}}></i>
                                                </Col>
                                                <Col style={{display:'flex', alignItem:'center', height:'100%'}}>
                                                    <p>As condições de pagamento para as unidades, tem como padrão as condições de todo o empreendimento, mas você pode editar de unidades específicas após a criação.</p>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    </Row>
                                    {this.state.units.map((input, index) => (
                                      <Row style={{maxWidth:'1240px', margin: '10px auto'}} key={index}>
                                        <Col md={2} style={{paddingBottom:'0'}}>
                                          <small>Unidade</small>
                                          <Input
                                            type="text"
                                            placeholder="Código da unidade"
                                            value={input.sku}
                                            onChange={(e) => this.updateExistInput(index, 'sku', e.target.value)}
                                          />
                                        </Col>
                                        <Col md={2} style={{paddingBottom:'0'}}>
                                            <small>Valor</small>
                                          <CurrencyInput defaultValue={input.price} onChange={(e) => this.updateExistInput(index, 'price', e.target.value)}/>
                                        </Col>
                                        <Col md={2} style={{paddingBottom:'0'}}>
                                          <small>Valor promocional</small>
                                          <CurrencyInput defaultValue={input.offer_price} onChange={(e) => this.updateExistInput(index, 'offer_price', e.target.value)}/>
                                        </Col>
                                        <Col md={2} style={{paddingBottom:'0'}}>
                                          <small>Status</small>
                                          <select className="form-control" value={input.status} onChange={(e) => this.updateExistInput(index, 'status', e.target.value)}>
                                          <option value={null}>Selecione</option>
                                            <option value="1">Disponível</option>
                                            <option value="2">Reservado</option>
                                            <option value="3">Vendido</option>
                                            <option value="0">Indispónível</option>
                                          </select>
                                        </Col>
                                        <Col className="col-auto" style={{marginTop:'20px'}}>
                                            {input.isLoading ? (
                                                    <Spinner className="me-2" color="primary" />
                                                ) : (
                                                    <Button
                                                        onClick={() => this.handleUnitClick(input)}
                                                        disabled={input.isLoading}
                                                    >
                                                        Salvar
                                                    </Button>
                                                )}
                                        </Col>
                                        <Col className="col-auto" style={{marginTop:'15px'}}>
                                            <div style={{
                                                height:'100%',
                                                width:'100%',
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center'
                                            }}>
                                                <i
                                                onClick={() => {
                                                    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                                                        this.setState({error_auth:true})
                                                    }else{
                                                    Axios.post(
                                                        process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-type-unit/delete/' + input.id,
                                                        {},
                                                        { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                                                        .then(response => {
                                                            Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types/' + id,
                                                            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                            .then(response => {
                                                                this.setState({
                                                                    units:response.data.units
                                                                  })
                                                            })
                                                        })
                                                    }
                                                }}
                                                style={{
                                                    fontSize:'20px',
                                                    backgroundColor:'#cdcdcd',
                                                    borderRadius: '5px',
                                                    padding:'2px 5px',
                                                    cursor:'pointer'
                                                }}
                                                className="ri-delete-bin-line"></i>
                                            </div>
                                        </Col>
                                        <Col md={3} style={{paddingBottom:'0'}}>
                                          <Button
                                            style={{marginTop:'5px'}}
                                            className="btn-sm"
                                            color="light"
                                            onClick={() => {
                                                this.setState({selectedUnitId: input.id})
                                                this.setState({setectedUnitPayment: typeUnit?.units?.filter(unit => unit.id === input.id)[0]?.payment})
                                                setTimeout(() => {
                                                    this.tog_center()
                                                }, 300);
                                                
                                            }}
                                          >Características da condição de pagamento</Button>
                                        </Col>
                                      </Row>                                        
                                      ))}
                                    {this.state.unitsInputs.map((input, index) => (
                                      <Row style={{maxWidth:'1240px', margin: '10px auto'}} key={index}>
                                        <Col md={2} style={{paddingBottom:'0'}}>
                                          <small>Unidade</small>
                                          <Input
                                            type="text"
                                            placeholder="Código da unidade"
                                            value={input.sku}
                                            onChange={(e) => this.updateInput(index, 'sku', e.target.value)}
                                          />
                                        </Col>
                                        <Col md={3} style={{paddingBottom:'0'}}>
                                          <small>Valor</small>
                                          <CurrencyInput defaultValue={input.price} onChange={(e) => this.updateInput(index, 'price', e.target.value)}/>
                                        </Col>
                                        <Col md={3} style={{paddingBottom:'0'}}>
                                          <small>Valor promocional</small>
                                          <CurrencyInput defaultValue={input.offer_price} onChange={(e) => this.updateInput(index, 'offer_price', e.target.value)}/>
                                        </Col>
                                        <Col md={2} style={{paddingBottom:'0'}}>
                                          <small>Status</small>
                                          <select className="form-control" value={input.status} onChange={(e) => this.updateInput(index, 'status', e.target.value)}>
                                          <option value={null}>Selecione</option>
                                            <option value="1">Disponível</option>
                                            <option value="2">Reservado</option>
                                            <option value="3">Vendido</option>
                                            <option value="0">Indispónível</option>
                                          </select>
                                        </Col>
                                        <Col md={2} style={{paddingBottom:'0', paddingTop:'20px'}}>
                                            <Button className="btn-info" onClick={() => {
                                                if(input.sku !== '' && input.price !== '' && input.status !== ''){
                                                    this.createUnit(input)
                                                }else{
                                                    this.setState({new_unit_error: true})
                                                }
                                            }}>Criar</Button>
                                        </Col>
                                      </Row>                                        
                                      ))}
                                      {this.state.unitsInputs.length === 0 &&
                                        <Row style={{maxWidth:'1240px', margin: '10px auto'}}>
                                            <Col xs={12} style={{display:'flex', justifyContent: 'flex-start', margin:'20px 0 20px'}}>
                                            <Button type="button" onClick={this.addInput} style={{display:'flex', alignItem: 'center'}}>
                                                <i className="ri-add-circle-line"></i>Adicionar unidade
                                            </Button>
                                            </Col>
                                        </Row>
                                      }

                                    <Container>
                                        <Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                            <Col className="col-auto">
                                                <Button className="btn-dark" onClick={() => {
                                                setTimeout(() => {
                                                    window.open('./../perfil-empreendimento?id=' + localStorage.getItem('enterprise_selected_id'), '_parent')
                                                }, 100);
                                                }}>Voltar</Button>
                                            </Col>
                                            <Col className="col-auto">
                                                <Button onClick={() => {
                                                    setTimeout(() => {
                                                        window.open('./../perfil-empreendimento?id=' + localStorage.getItem('enterprise_selected_id'), '_parent')
                                                    }, 100);
                                                }}>Salvar</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    isOpen={this.state.modal_center}
                    toggle={this.tog_center}
                    size="xl"
                    centered={true}
                    style={{minWidth:'360px'}}
                >
                    <ModalHeader toggle={() => this.setState({ modal_center: false })}>
                    Condições de pagamento
                    </ModalHeader>
                    <ModalBody>
                    <Form className="cardBody" key={this.state.setectedUnitPayment}>
                        <Container style={{padding:'20px 20px 0', marginBottom: '15px'}}>
                            {this.state.setectedUnitPayment?.length > 0 && this.state.setectedUnitPayment?.map((item, index) => (
                            <Row key={index}>
                                <Col className="col-auto" style={{paddingTop:'25px', minWidth:'40px'}}>
                                    {item.type === 'entrance' && <i className="ri-coins-line" style={{fontSize:'25px'}}></i>}
                                    {item.type === 'reinforcement_6' && <i className="ri-exchange-dollar-line" style={{fontSize:'25px'}}></i>}
                                    {item.type === 'reinforcement_12' && <i className="ri-exchange-dollar-line" style={{fontSize:'25px'}}></i>}
                                    {item.type === 'parcels' && <i className="ri-currency-line" style={{fontSize:'25px'}}></i>}
                                    {item.type === 'keys' && <i className="ri-key-2-line" style={{fontSize:'25px'}}></i>}
                                </Col>
                                <Col md={2}>
                                <Label>Tipo de condição</Label>
                                <select className="form-control" value={item.type} onChange={(e) => this.handleInputChange(e, index, 'type')}>
                                    <option value={null}>Selecione</option>
                                    <option value="entrance">Entrada</option>
                                    <option value="reinforcement_6">Reforço semestral</option>
                                    <option value="reinforcement_12">Reforço anual</option>
                                    <option value="parcels">Parcelamento</option>
                                    <option value="keys">Entrega de chaves</option>
                                </select>
                                </Col>
                                <Col className="col-auto">
                                    <Label>{item.type_value === 'percent' ? 'Porcentagem' : 'Valor'}</Label>
                                    <div className="custom-toggle" onClick={() => this.toggleTypeValue(index)}>
                                        <div className={`active-toggle ${item.type_value}`}></div>
                                    </div>
                                </Col>
                                <Col md={2}>
                                
                                {item.type_value === 'currency' ?
                                    <>
                                    <Label>Valor</Label>
                                    <CurrencyInput defaultValue={item.currency_value} onChange={(e) => this.handleInputChange(e, index, 'currency_value')}/>
                                    </>
                                :
                                <>
                                    <Label>Porcentagem</Label>
                                    <Input
                                    type="number"
                                    placeholder=""
                                    value={item.value}
                                    onChange={(e) => this.handleInputChange(e, index, 'value')}
                                    />
                                </>
                                }
                                </Col>
                                <Col md={2}>
                                <Label>Parcelas</Label>
                                <Input
                                    type="number"
                                    placeholder="Nº de parcelas"
                                    value={item.parcels}
                                    onChange={(e) => this.handleInputChange(e, index, 'parcels')}
                                />
                                </Col>
                                <Col md={2}>
                                <Label>Observações</Label>
                                <Input
                                    type="text"
                                    placeholder="Observações"
                                    value={item.observation !== 'null' ? item.observation : ''}
                                    onChange={(e) => this.handleInputChange(e, index, 'observation')}
                                />
                                </Col>
                                <Col className="col-auto" style={{paddingTop:'20px'}}>
                                    {item.isLoading ? (
                                        <Spinner className="me-2" color="primary" />
                                    ) : (
                                        <Button
                                            onClick={() => this.handleClick(item)}
                                            disabled={item.isLoading}
                                        >
                                            Salvar
                                        </Button>
                                    )}
                                </Col>
                                <Col className="col-auto">
                                    <div style={{
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'center'
                                    }}>
                                        <i
                                        onClick={() => {
                                            if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                                                this.setState({error_auth:true})
                                            }else{
                                            Axios.post(
                                                process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-type-unit-pay/delete/' + item.id,
                                                {},
                                                { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                                                .then(response => {
                                                    Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-types/' + id,
                                                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                    .then(response => {
                                                        // console.log(response.data?.units?.filter(unit => unit.id === this.state.selectedUnitId)[0]?.payment)
                                                        this.setState({setectedUnitPayment: response.data?.units?.filter(unit => unit.id === this.state.selectedUnitId)[0]?.payment})
                                                    })
                                                })
                                            }
                                        }}
                                        style={{
                                            fontSize:'20px',
                                            backgroundColor:'#cdcdcd',
                                            borderRadius: '5px',
                                            padding:'2px 5px',
                                            cursor:'pointer'
                                        }}
                                        className="ri-delete-bin-line"></i>
                                    </div>
                                </Col>
                            </Row>
                            ))
                        }
                            {this.state.paymentCondition?.map((item, index) => (
                                <Row key={index}>
                                    <Col className="col-auto" style={{paddingTop:'25px', minWidth:'50px'}}>
                                        {item.type === 'entrance' && <i className="ri-coins-line" style={{fontSize:'25px'}}></i>}
                                        {item.type === 'reinforcement_6' && <i className="ri-exchange-dollar-line" style={{fontSize:'25px'}}></i>}
                                        {item.type === 'reinforcement_12' && <i className="ri-exchange-dollar-line" style={{fontSize:'25px'}}></i>}
                                        {item.type === 'parcels' && <i className="ri-currency-line" style={{fontSize:'25px'}}></i>}
                                        {item.type === 'keys' && <i className="ri-key-2-line" style={{fontSize:'25px'}}></i>}
                                    </Col>
                                    <Col md={2}>
                                    <Label>Tipo de condição</Label>
                                    <select className="form-control" value={item.type} onChange={(e) => this.updatePaymentInput(index, 'type', e.target.value)}>
                                        <option value={null}>Selecione</option>
                                        <option value="entrance">Entrada</option>
                                        <option value="reinforcement_6">Reforço semestral</option>
                                        <option value="reinforcement_12">Reforço anual</option>
                                        <option value="parcels">Parcelamento</option>
                                        <option value="keys">Entrega de chaves</option>
                                    </select>
                                    </Col>
                                    <Col className="col-auto">
                                        <Label>{this.state.toggleSwitch ? 'Porcentagem' : 'Valor'}</Label>
                                        <div className="custom-toggle" onClick={() => {
                                            this.setState(prevState => ({
                                                toggleSwitch: !prevState.toggleSwitch
                                            }), () => {
                                                this.updatePaymentInput(index, 'type_value', this.state.toggleSwitch ? 'percent' : 'currency');
                                            })}}>
                                            <div className={`active-toggle ${this.state.toggleSwitch ? 'percent' : 'currency'}`}></div>
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                    
                                    {item.type_value === 'currency' ?
                                        <>
                                        <Label>Valor</Label>
                                        <CurrencyInput defaultValue={item.currency_value} onChange={(e) => this.updatePaymentInput(index, 'currency_value', e.target.value)}/>
                                        </>
                                    :
                                    <>
                                        <Label>Porcentagem</Label>
                                        <Input
                                        type="number"
                                        placeholder=""
                                        value={item.value}
                                        onChange={(e) => this.updatePaymentInput(index, 'value', e.target.value)}
                                        />
                                    </>
                                    }
                                    </Col>
                                    <Col md={2}>
                                    <Label>Parcelas</Label>
                                    <Input
                                        type="number"
                                        placeholder="Nº de parcelas"
                                        value={item.parcels}
                                        onChange={(e) => this.updatePaymentInput(index, 'parcels', e.target.value)}
                                    />
                                    </Col>
                                    <Col md={2}>
                                    <Label>Observações</Label>
                                    <Input
                                        type="text"
                                        placeholder="Observações"
                                        value={item.observation}
                                        onChange={(e) => this.updatePaymentInput(index, 'observation', e.target.value)}
                                    />
                                    </Col>
                                    <Col md={2} style={{paddingBottom:'0', paddingTop:'20px'}}>
                                        <Button className="btn-info" onClick={() => this.createPayment(item)}>Criar</Button>
                                    </Col>
                                </Row>
                            ))}
                            <Row style={{margin:'10px 0'}}>
                               {this.state.paymentCondition.length === 0 &&
                                    <Col className="col-auto">
                                        <Button onClick={this.addPaymentInput}>Adicionar nova característica</Button>
                                    </Col>
                               }
                            </Row>
                        </Container>
                    </Form>
                    </ModalBody>
                </Modal>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                {this.state.erro_send_aprovation ? (
                    <SweetAlert
                        title="As informações obrigatórias devem estar preenchidas antes de enviar para aprovação."
                        timeout={3000}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => this.setState({ erro_send_aprovation: false })}
                    ></SweetAlert>
                ) : null}

                {this.state.new_unit_error ? (
                    <SweetAlert
                        title="Todos os campos são obrigatórios."
                        timeout={3000}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => this.setState({ new_unit_error: false })}
                    ></SweetAlert>
                ) : null}

            {this.state.error_auth ? (
                <SweetAlert
                    error
                    title='Ops'
                    onConfirm={() => this.setState({ error_auth: false })}
                >
                    Você não tem permissão para esta ação.
                </SweetAlert>
            ) : null}
            </div>
        )
    }

}

export default TypeProfile;