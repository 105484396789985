import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Container, CardHeader } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb"
import Whats from '../../components/Whats/Whats';
import Footer from '../Home/components/Footer';
import HeaderMobile from '../Home/components/Header';

import './../../assets/scss/custom/components/_policy.scss'

class policyUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    return (
      <>
        <HeaderMobile/>
        <div className="page-content" id="policy" style={{padding:'10px 0 20px'}}>
        <Container fluid={false}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h3>TERMOS DE USO E POLÍTICA DE PRIVACIDADE PARA USUÁRIOS FAST SALE</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>Estes são os “Termos de Uso” e “Política de Privacidade” da plataforma digital da Fast Sale Tecnologia Ltda, pessoa jurídica de direito privado, inscrita no CNPJ nº 39.985.590/0001-94, CRECI nº 6635J/SC, com sede administrativa na Rua 700, nº 489, Esq. 3ª Avenida, Sala nº 2004, Ed. Benvenutti Business Center, Centro, na cidade de Balneário Camboriú/SC, CEP: 88.330-620, que conduzem a utilização da plataforma estando cadastrados ou não.</p>
                      <p>Ao usar, acessar a plataforma Fast Sale, e seus respectivos serviços, você (usuário: proprietário, corretores e imobiliárias) concorda com as “Políticas de Uso e Privacidade”, de modo que a simples faculdade de uso, pressupõe-se a aceitação.</p>
                      <p>A Fast Sale ressalva que poderá a qualquer momento alterar seus “Termos de Uso e Políticas” da plataforma, inclusive em relação a você (usuário) ou, deixar de oferecer, ou negar acesso aos serviços. E o fato de você (usuário) continuar acessando representa a sua ciência e aceite com a devida vinculação aos “Termos de Uso e Políticas” da Plataforma Fast Sale, igualmente, aos considerar-se-á aceito os novos Termos de Uso e condições.</p>
                      <p>Antes de utilizar os Serviços, leia atentamente estes “Termos de Uso e Políticas” da plataforma Fast Sale, que contêm informações importantes sobre a prestação dos serviços da Fast Sale para com seus usuários, bem como seus direitos, suas obrigações e sobre os Serviços prestados, respaldados na legislação vivente.</p>
                      <h5>1. SOBRE A PLATAFORMA FAST SALE:</h5>
                      <p>1.1. A Fast Sale é uma plataforma digital de liquidez imobiliária, que conecta imóveis à venda com uma rede de imobiliárias e corretores credenciados. Responsável pela aproximação das partes através da plataforma, com agilidade e segurança nas transações imobiliárias. </p>
                      <h5>2. DO USO DA PLATAFORMA FAST SALE:</h5>
                      <p>2.1. Você (usuário), ao efetuar seu cadastro e/ou contratar os serviços da Fast Sale, concorda em repassar e manter as informações corretas, completas e atualizadas em sua conta pessoal de usuário. Qualquer alteração nos seus contatos, seja de telefone, whatsapp e e-mail, deverá imediatamente informar a Fast Sale, inclusive em casos de furto e falsários utilizando seus dados e contatos.</p>
                      <p>2.2. Você (usuário) não poderá ceder, nem de qualquer outro modo transferir, sua conta, a nenhuma outra pessoa ou entidade, sendo intransferível o acesso. </p>
                      <p>2.3. Você usuário que é proprietário do imóvel, corretor de imóveis e imobiliária está ciente que ao baixar os materiais da plataforma Fast Sale não poderá repassar para outros profissionais do ramo imobiliário, e que a utilização dos serviços oferecidos pela plataforma Fast Sale é de uso exclusivo do usuário devidamente cadastrado, dentro dos limites permitidos de divulgação (item 3.6 deste termo). Caso seja apurado que houve repasse indevido do material baixado através da plataforma, o usuário poderá incorrer na penalidade de exclusão da plataforma, e ainda obriga-se a indenizar a Fast Sale dos prejuízo derivados dá utilização indevida da plataforma.</p>
                      <p>2.4. A Fast Sale não se responsabiliza por informações incorretas, incompletas, ou desatualizadas, e pelos documentos legais fornecidos, para fechamento de negociações, e conclusão de vendas dos imóveis, estando cientes que a veracidade e originalidade são dos usuários. Ademais, a Fast Sale também não se responsabiliza por danos nos imóveis, nem quando das visitas, mesmo em caso de furto, roubo, danos patrimoniais, conforme “Termos de Uso” e Contratos firmados pela prestação dos serviços ofertados, e inclusive, não será responsável por anúncio dos imóveis que estejam em outras plataformas de venda, mesmo que imobiliárias e corretores de imóveis cadastrados.</p>
                      <h5>3. CONTRATO DE PRESTAÇÃO DE SERVIÇOS DA FAST SALE</h5>
                      <p>3.1. Os usuários (corretores de imóveis e imobiliárias intermediadores) assinarão em conjunto com os proprietários dos imóveis (contratante dos serviços) o “Contrato de Prestação de Serviços” com da Fast Sale, no qual constará as informações necessárias e critérios da negociação, bem como constará a indicação da comissão de aproximadamente 6% (seis por cento) sobre o valor da venda do imóvel, sendo 1% (um por cento) para a Fast Sale, 1% (um por cento) para Corretor de imóveis que captou o imóvel para plataforma e 1% (um por cento) para imobiliária que captou o imóvel para plataforma, se for o caso, e ainda que a comissão restante poderá ser negociada entre o vendedor/comprador diretamente com o corretor/imobiliária que vender o imóvel através da plataforma, contrato este que se complementa aos presentes “Termos e demais Termos de Uso e Políticas”.</p>
                      <p>3.2. Você (usuário) está ciente que o “Contrato de Prestação de Serviço” com a Fast Sale poderá ser assinado pelo: (i) proprietário do imóvel, constante na Matrícula Imobiliária do Ofício de Registro de Imóveis competente; (ii) adquirente do imóvel, com contrato de compra e venda, assinado pelo proprietário/vendedor e adquirente/comprador, com reconhecimento das assinaturas por verdadeira/autenticidade; (iii) procurador, munido de procuração pública específica do imóvel. A validação destes documentos será feita pelo Compliance interno da Fast Sale, seguindo todos os parâmetros da Lei de Proteção de Dados.</p>
                      <p>3.3. O Usuário está ciente que o usuário proprietário do imóvel terá até 5 (cinco) dias para responder sobre as propostas recebidas, o que poderá ser feito via plataforma online da Fast Sale e/ou via whatsapp e e-mail. Você usuário concorda que a Fast Sale pode recusar propostas que não preencham os critérios da negociação que restaram especificados no Contrato com a Fast Sale.</p>
                      <p>3.4. O usuário proprietário de imóvel está ciente que, ao assinar o contrato com a Fast Sale, já está autorizando que seu imóvel fique disponível para anúncio da venda na plataforma Fast Sale, autorizando a venda para toda a rede de corretores de imóveis e imobiliárias cadastrados na plataforma.</p>
                      <p>3.5. A Fast Sale se reserva no direito de se negar a incluir/aceitar o imóvel para a sua plataforma digital, após a avaliação do imóvel.</p>
                      <p>3.6. O usuário proprietário de imóvel está ciente e concorda que, a Fast Sale disponibilizará para os usuários corretores de imóveis e imobiliárias o material visual, os quais poderão anunciar em suas próprias plataformas, contudo, a Fast Sale não é responsável pelos anúncios que estejam em outras plataformas de venda, por edições, alterações, mudanças, alteração das imagens, dados, seja dos critérios da negociação, seja do imóvel, preços, características, entre outros, podendo os usuários corretores de imóveis e imobiliárias responderem e terem que adimplir pelo descumprimento, de acordo com o presente Termo, Termos Adicionais, e Contrato</p>
                      <p>3.7. O usuário corretor de imóveis ou imobiliária está ciente que, após a venda dos imóveis, deverá imediatamente retirar de todas as suas plataformas digitais, toda e qualquer divulgação, anúncios, dos imóveis com negócios concretizados e vendidos, sob pena de multa, caso não retire o anúncio em até 24 horas.</p>
                      <h5>4. FAST SALE ADVERTE</h5>
                      <p>4.1. Se você (usuário) não concorda com estes “Termos de Uso e Políticas” da plataforma, você não pode acessar, não pode usar, e não pode cadastrar-se na plataforma e serviços da Fast Sale. </p>
                      <p>4.2. Se você (usuário) desejar cancelar o seu cadastro e/ou contratação com a Fast Sale, bastará solicitar o encerramento da sua conta com junto a plataforma através do e-mail: juridico2@fastsaleimoveis.com.br, e/ou whasapp (47) 41081865, não mais acessar ou utilizar os nossos serviços. </p>
                      <h5>5. DO USUÁRIO CORRETOR E IMOBILIÁRIA</h5>
                      <p>5.1. Você (usuário) Corretor de imóveis ou imobiliária cadastrados se comprometem a não abordar os proprietários dos imóveis cadastrados da plataforma da Fast Sale, visto parceria na contratação dos serviços oferecidos.</p>
                      <p>5.2. Você (usuário) Corretor de imóveis ou imobiliária cadastrados, estão cientes que ao utilizarem qualquer dos serviços da plataforma Fast Sale, concordam com os “Termos” de parcerias e divisões das comissões indicadas nas contratações e presente nos anúncios dos imóveis da plataforma.</p>
                      <p>5.3. Você (usuário) Corretor de imóveis ou imobiliária cadastrados, concorda que poderá colocar imóveis à venda em parceria, com comissão de corretagem de captação de 1% (um por cento) para o captador e 1% para a Fast Sale, nos “Termos” das contratações.</p>
                      <p>5.4. Você (usuário) Corretor de imóveis ou imobiliária cadastrados, ao acessarem a plataforma, concorda e aceitam com os “Termos” e demais “Termos de Uso e Política de Privacidade” da plataforma Fast Sale, aqui descritos e outros firmados com a Fast Sale, respeitando a ética profissional, leis vigentes, e que ainda usará os serviços para finalidades legítimas.</p>
                      <h5>6. DA PROPRIEDADE INTELECTUAL</h5>
                      <p>6.1. A FAST SALE TECNOLOGIA LTDA é a única detentora de todos os direitos de propriedade intelectual, sendo sua a titularidade e todos os direitos autorais referentes ao Projeto de Desenvolvimento Web objeto da plataforma (incluindo, mas sem limitação a quaisquer imagens, fotografias, animações, vídeos, áudios, músicas, textos, componentes e arquivos incorporados ao software), o material digital e/ou impresso que o acompanha.</p>
                      <h5>7. DA POLÍTICA DE PRIVACIDADE DA FAST SALE:</h5>
                      <p>7.1. Para a Fast Sale, a privacidade e confidencialidade das informações dos seus usuários, das informações dos imóveis, e das informações das negociações, de forma que a Fast Sale não fornecerá suas informações pessoais e dos imóveis, exceto os permitidos por lei e/ou devidamente autorizados pelos usuários contratualmente, quando necessárias para os serviços contratados à ser realizado pela própria Fast Sale.</p>
                      <p>7.2. O usuário da Fast Sale, ao se cadastrarem, alterarem seu cadastro, participarem das negociações e venda de imóveis, disporão de suas informações pessoais, como dos imóveis, e dos documentos legais oficiais para o fim de fechamento dos negócios, eis que legalmente necessárias para o serviço solicitado pelo próprio usuário, de modo que a Fast Sale assegura a privacidade e confidencialidade de todos os dados e documentos que obtiver, não passando tais informações para terceiros.</p>
                      <p>7.3. A Fast Sale assume, neste ato, o dever de total observância à Política de Tratamento e Privacidade de Dados anexa, e de adequação às disposições da Lei no 13.709/2018, denominada Lei Geral de Proteção de Dados Pessoais ou “LGPD”, a qual serve para regulamentar o tratamento dos dados pessoais de pessoas físicas, a fim de proteger seus direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento de sua personalidade.</p>
                      <p>7.4. Você (usuário) está ciente que a Fast Sale em sua plataforma digital, seja através do site, aplicativos, emails, anúncios online, se utiliza de cookies e demais tecnologias que servem de identificação, seja dos próprios usuários, nas autenticações, seja na forma de uso dos usuários, suas preferências, configurações e atitudes com a Fast Sale, seja com a plataforma, seja com os serviços, proporcionando segurança e atendimento personalizado ao perfil de seus usuários e suas necessidades.</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h4>TERMOS ADICIONAIS DE USUÁRIOS CORRETORES DE IMÓVEIS E IMOBILIÁRIAS</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5>1. Termos</h5>
                      <p>1.1. Estes Termos Adicionais de Usuários Corretores de Imóveis e Imobiliárias regem o seu acesso e uso da plataforma da Fast Sale, e se complementa com os Termos de Uso e demais Termos. Você concorda com o presente Termos Adicionais de Usuários Corretores de Imóveis e Imobiliárias, com os Termos de Uso e suas condições, e demais Termos Adicionais.</p>
                      <p>1.2. Os Termos Adicionais de Usuários Corretores de Imóveis e Imobiliárias poderão ser alterados a qualquer momento. A vigência da nova versão atualizada dos Termos Adicionais de Usuários Corretores de Imóveis e Imobiliárias será a partir da sua disponibilização na plataforma da Fast Sale, e o seu uso da plataforma da Fast Sale considerar-se-á sua aceitação das alterações realizadas. Caso você não concorde com os novos regulamentos, deverá cancelar o seu cadastro e não mais acessar ou utilizar os nossos serviços. </p>
                      <p>1.3. A plataforma digital da Fast Sale conecta imóveis à venda com imobiliárias e corretores credenciados. A Fast Sale avalia os imóveis, realiza a produção de fotos e disponibiliza online para os corretores e imobiliárias, filtra as propostas, e faz a amostragem dos imóveis. Os Corretores e Imobiliárias recebem a captação de imóveis através da plataforma online, sem perderem tempo nas ruas, procurando imóveis, tirando fotos, e buscando os proprietários.</p>
                      <p>1.4. A Fast Sale é uma plataforma digital com soluções que ajudam na liquidez imobiliária, bem como economiza o tempo dos Corretores e Imobiliárias, captando imóveis, com material fotográfico de qualidade, e disponibiliza para uma rede de corretores e imobiliárias cadastrados venderem, com acompanhamento durante o processo, desde as visitas, às propostas para fechamento do negócio.</p>
                      <p>1.5. A plataforma digital da Fast Sale conterá uma pauta atualizada, com imóveis para você vender a 5% de comissão, ou comissão que ajustar durante a negociação com o proprietário do imóvel, com preço de acordo com os imóveis anunciados online, ampla divulgação e alcance, com apresentação de imagens de qualidade.</p>
                      <h5>2. Serviços</h5>
                      <p>2.1. Os Serviços integram uma (i) plataforma digital, em que serão (ii) disponibilizados imóveis à venda, que sejam acima de R$250.000,00 (duzentos e cinquenta mil reais), imóveis estes que serão (iii) avaliados por perito certificado, e plataforma online que compara as características do imóvel com outros semelhantes, levando em consideração uma amostragem com o auxíliode tecnologia, garantindo uma avaliação mais precisa e imparcial, (iv) terão produção de material fotográfico, destacando as principais características e diferenciais do imóvel, (v) terão divulgação da venda na rede de corretores e imobiliárias cadastrados, inclusive através dos parceiros, (vi) acompanhamento das visitas ao imóvel, (vii) recebimento das propostas, contatos e ligações, repassando as que estiverem nos critérios de negociação, com margem de 20%, tanto para mais, quanto para menos, (viii) acompanhamento online, desde a divulgação, a quantidade de pessoas que visitaram o imóvel, as propostas recebidas, acompanhamento da venda do imóvel na plataforma, inclusive com o envio de relatórios, com notificações, via plataforma online, whatsapp, e-mail ou ligação, (ix) poderá escolher quem não poderá ter acesso e restringir a divulgação do imóvel, de modo que, se não desejar que determinado corretor de imóveis e/ou imobiliária não anuncie o imóvel, poderá especificar quais e informar o motivo, estando ciente que corretores e imobiliárias cadastrados na plataforma também divulgarão os imóveis para venda.</p>
                      <h5>3. Uso dos Serviços</h5>
                      <p>3.1. Para uso dos serviços da plataforma online, você terá que se cadastrar e manter uma conta pessoal de usuário. Para o registro do seu cadastro e conclusão da sua conta, será necessário informar seus dados pessoais, CRECI válido e regular, e contatos de endereço, CEP, telefone, whatsapp e e-mail. No caso de estagiário, devidamente cadastrado no CRECI, deverá respeitar a legislação da corretagem, e seus atendimentos deverão ser acompanhados de um corretor de imóveis com CRECI válido e regular, e cadastrado na plataforma da Fast Sale.</p>
                      <p>3.2. Você concorda em manter as informações corretas, completas e atualizadas em sua conta pessoal de usuário. Qualquer alteração, seja nos seus dados pessoais, seja nos seus contatos, seja de endereço, CEP, telefone, whatsapp e e-mail, deverá imediatamente informar a Fast Sale, inclusive em casos de furto/roubo e falsários utilizando seus dados e contatos, deverá imediatamente informar a Fast Sale. Se você não mantiver as informações corretas, completas e atualizadas em sua conta, você pode responder por eventuais perdas e danos, e poderá ficar impossibilitado(a) de acessar e usar os serviços, ou a Fast Sale poderá rescindir estes Termos. Você é responsável por todas as atividades realizadas na sua conta e concorda em manter sempre a segurança e confidencialidade do nome de usuário e senha da sua conta de usuário. </p>
                      <p>3.3. Os usuários corretores de imóveis e imobiliárias, após finalizarem o cadastro de sua conta, e passar todas as informações necessárias e documentos legais respectivos, conforme legislação vigente, deverão aguardar o contato da Fast Sale, em até 48hs, para o aceite. A Fast Sale se reserva no direito de aprovar ou não o cadastro, sem necessidade de apresentar o motivo.</p>
                      <h5>4. Uso dos Serviços Premium</h5>
                      <p>4.1. Os usuários corretores de imóveis e imobiliárias premium da plataforma da Fast Sale, serão os que pagarão mensalmente um serviço de assinatura, com opções de planos abrangendo diferentes usos dos serviços, e disponibilizando novas ferramentas exclusivas da plataforma Fast Sale, conforme os Termos de Uso e suas condições, e demais Termos Adicionais.</p>
                      <p>4.2. Os usuários corretores de imóveis e imobiliárias, após finalizarem a contratação do serviço premium da plataforma Fast Sale, e realizarem o primeiro pagamento, deverão aguardar a confirmação da Fast Sale, em até 48hs, para ter o acesso ao serviço premium liberado. </p>
                      <p>4.3. Os usuários corretores de imóveis e imobiliárias premium da plataforma da Fast Sale, que deixarem de realizar o pagamento mensal de 1 (um) mês, terão seu acesso limitado à plataforma premium; e após 2 (dois) meses do não pagamento, serão removidos do serviço premium da plataforma, e retornarão ao acesso de uso comum dos serviços da plataforma Fast Sale.</p>
                      <p>4.4. Os usuários corretores de imóveis e imobiliárias poderão cadastrar imóveis na plataforma online da Fast Sale, desde que sejam usuários premium da plataforma Fast Sale.</p>
                      <h5>5. Cadastro de Imóveis</h5>
                      <p>5.1. Os usuários corretores de imóveis e imobiliárias poderão cadastrar imóveis na plataforma online da Fast Sale, desde que sejam usuários premium da plataforma, e deverão (i) informar os dados e enviar os documentos legais dos imóveis; (ii) informar os dados dos proprietários e enviar os documentos legais dos proprietários; (iii) enviar o Contrato com a Fast Sale, com todas as informações e dados preenchidos, bem como assinado pelo proprietário do imóvel e pelo corretor de imóveis; (iv) enviar os Termos de Uso e demais Termos assinados pelo proprietário do imóvel; (v) informar todos os dados e enviar todos os documentos que sejam pertinentes e legalmente exigidos; (vi) Você declara e garante, a legitimidade das informações prestadas, e dos documentos cedidos, não violando a legislação vigente e nem direito de propriedade de terceiros; (vii) você declara e garante, legitimamente se o imóvel é ou não é regular, com ou sem ônus e ações, com ou sem vícios; (viii) você está ciente que o proprietário do imóvel deverá secadastrar na plataforma digital da Fast Sale, em até 5 (cinco) dias, após a assinatura do Contrato com a Fast Sale, conforme demais Termos; (ix) A Fast Sale pode ou não aceitar o cadastro do imóvel sem justificativa.</p>
                      <h5>6. Contrato com a Fast Sale</h5>
                      <p>6.1. Os usuários corretores de imóveis e imobiliárias assinarão em conjunto com os proprietários dos imóveis o Contrato com a Fast Sale, no qual constará as informações necessárias e critériosda negociação, documentação legalmente exigida, contrato este que se vincula aos presentes Termos e demais Termos.</p>
                      <p>6.2. Você está ciente que o Contrato com a Fast Sale poderá ser assinado pelo: (i) proprietário do imóvel, constante na Matrícula Imobiliária do Ofício de Registro de Imóveis competente; (ii) adquirente do imóvel, com contrato de compra e venda, assinado pelo proprietário/vendedor e adquirente/comprador, com reconhecimento das assinaturas por verdadeira/autenticidade; (iii)procurador, munido de procuração pública específica para venda de imóvel.</p>
                      <p>6.3. No Contrato com a Fast Sale, deverá constar especificamente: (i) dados do imóvel; (ii) dados do proprietário, e do procurador, se o caso; (iii) os critérios da negociação que aceitará, como permuta, permuta em parte, financiamento imobiliário, entre outros; (iv) visitas ao imóvel, se imóvel está alugado ou não, se reside no imóvel, se deixará as chaves com a Fast Sale, ou com o Corretor Cadastrado que trouxe o imóvel para a plataforma, ou com alguém responsável, terceiro de sua confiança, caso as chaves ficarem com alguém responsável, deverá informar nome completo, telefone/whatsapp, e-mail e endereço, e terá como responsabilidade o pagamento dos honorários de cada visita desta pessoa que irá abrir o imóvel; (v) informar semais pessoas possuem as chaves do imóvel; (vi) os documentos apresentados; (vii) estando ciente que tais informações estarão na plataforma online da Fast Sale.</p>
                      <p>6.4. Você está ciente que o proprietário terá até 5 (cinco) dias para responder sobre as propostas recebidas, o que poderá ser feito via plataforma online da Fast Sale, via whatsapp, ou via e-mail. Você concorda que a Fast Sale pode recusar propostas que não preencham o solicitado e critérios da negociação que restaram especificados no Contrato com a Fast Sale.</p>
                      <p>6.5. Você está ciente que o Contrato com a Fast Sale e o proprietário do imóvel, é para a Fast Sale e toda a rede de corretores de imóveis e imobiliárias cadastrados na plataforma online da Fast Sale, para venda, divulgação e preço.</p>
                      <h5>7. Avaliação Imobiliária</h5>
                      <p>7.1. Você está ciente que após a assinatura do Contrato com a Fast Sale, informação de dados, apresentação de todos os documentos necessários, legalmente exigidos, e cadastro do proprietário na plataforma online, a Fast Sale terá de 2 à 5 dias para concluir a avaliação do imóvel.</p>
                      <p>7.2. A Fast Sale se reserva no direito de se negar a incluir/aceitar o imóvel, para a plataforma digital, após a avaliação do imóvel.</p>
                      <p>7.3. Você está ciente que, a avaliação da Fast Sale, a qual é realizada por perito certificado, e plataforma online que compara as características do imóvel com outros semelhantes, levando em consideração amostragem de imóveis com o auxílio de tecnologia, garantindo uma avaliação mais precisa e imparcial, servindo como sugestão, a Fast Sale não se responsabiliza pela venda do imóvel por esse valor, a avaliação realizada serve como sugestão de preço, mas a decisão do preço é do usuário proprietário do imóvel.</p>
                      <h5>8. Material Visual</h5>
                      <p>8.1 Você está ciente que, a produção de material visual para anúncio, será no seguintes parâmetros: (i) a produção de material visual para anúncio é realizada por profissionais terceirizados, cadastrados na Fast Sale, sem vínculo empregatício e sem responsabilidade pelo que realiza; (ii) imóveis avaliados de R$250.000,00 (duzentos e cinquenta mil reais) até R$499.000,00 (quatrocentos e noventa e nove mil reais), a produção fotográfica deverá ser paga pelo usuário proprietário do imóvel, conforme tabela de preço para este serviço; (iii) imóveis avaliados acima de R$500.000,00 (quinhentos mil reais), a produção fotográfica será gratuita, caso desejar produção de vídeo, deverá consultar a disponibilidade do serviço, bem como a tabela de preço da produtora de mídia terceirizada para este serviço; (iv) Prazo para tirar as fotos, é de uma semana, após avaliação do imóvel, Contrato com a Fast Sale assinado, e cadastro do usuário proprietário do imóvel na plataforma, desde que o tempo esteja com sol, e não nublado, e poderá ocorrer no horário das 08h às 18hs; (v) Você está ciente, sob sua responsabilidade, já constar alguém responsável por abrir o imóvel no Contrato com a Fast Sale, bem como o imóvel não deverá ter itens pessoais expostos, não deverá ter itens de valor expostos, o imóvel deverá estar limpo, com iluminação funcionando, caso contrário, e não estando nestes moldes, impossibilitando a realização dos trabalhos para a produção do material fotográfico, poderá ser responsabilizado pelos honorários dos profissionais envolvidos que foram até o imóvel.</p>
                      <p>8.2. A Fast Sale disponibilizará para os usuários corretores e imobiliárias o material fotográfico, os quais poderão anunciar em suas próprias plataformas, estando cientes que não poderão alterar as imagens, dados, seja dos critérios da negociação que restou expresso no Contrato com a Fast Sale, seja do imóvel, preços, características, entre outros, podendo responder e ter que adimplir pelo descumprimento, de acordo com o presente Termos e demais Termos.</p>
                      <p>8.3. Você está ciente que, após a venda dos imóveis, deverá imediatamente retirar de todas as suas plataformas digitais, toda e qualquer divulgação, anúncios, dos imóveis com negócios concretizados e vendidos, sob pena de multa caso não retire o anúncio em até 24 horas. </p>
                      <h5>9. Visitas</h5>
                      <p>9.1. Você está ciente que, poderá ou não abrir o imóvel para as visitas, podendo o proprietário especificar no Contrato com a Fast Sale que deixará as chaves do imóvel com a Fast Sale.</p>
                      <p>9.2. Caso o proprietário especificar no Contrato com a Fast Sale, que deixará as chaves com os usuários corretores de imóveis e imobiliárias que cadastraram o imóvel na plataforma, ficam cientes que: </p>
                      <p>(i) após agendamento de visitas aos imóveis da plataforma online, deverão aguardar a notificação de que o proprietário do imóvel confirmou a realização da visita, através da plataforma digital online; </p>
                      <p>(ii) devem ser estritamente cumpridos os horários das visitas, caso não seja possível abrir o imóvel, e precisar cancelar a visita, avisar com antecedência mínima de 2 horas antes, caso contrário, será responsabilizado pelo pagamento da diligência e deslocamento até o imóvel;</p>
                      <p>(iii) é tolerado atraso no máximo de 15 minutos;</p>
                      <p>(iv) não poderá faltar em 2 (duas) visitas, previamente agendadas, sem aviso com antecedência mínima de 2 horas, caso contrário, será responsabilizado por eventuais perdas e danos, pelo custo de deslocamento dos corretores cadastrados que agendaram a visita, sob pena de redução do comissionamento; </p>
                      <p>(v) a Fast Sale poderá suspender seu direito de divulgação e visitas, poderá reduzir seu comissionamento, poderá solicitar ao proprietário do imóvel as chaves e a autorização do proprietário para a Fast Sale ficar responsável pelas visitas do imóvel; </p>
                      <p>(vi) a quebra de bens nas visitas, entre outros danos que possam ocorrer nas visitas, será de responsabilidade da pessoa que a ocasionou; </p>
                      <p>(vii) é de interesse e responsabilidade dos corretores e imobiliárias o que acontecer nas visitas, inclusive perdas e danos que possam ocorrer;</p>
                      <p>(viii) o proprietário será avisado a cada agendamento de visitas, por meio de notificação via plataforma online e whatsapp.</p>                  
                      <p>9.3. Caso o proprietário especificar no Contrato com a Fast Sale, que deixará as chaves com a Fast Sale, (i) a mesma será responsável por abrir o imóvel nas visitas, e não os usuários corretores de imóveis e imobiliárias, somente a Fast Sale; (ii) o proprietário será avisado a cada agendamento de visitas, por meio de notificação via plataforma online e whatsapp. </p>
                      <h5>10. Propostas</h5>
                      <p>10.1. Você concorda que, das propostas dos imóveis da plataforma online da Fast Sale, (i) só terá acesso aos documentos e dados do proprietário e do imóvel, somente após o aceite da proposta pelo proprietário; (ii) deverá enviar as propostas, assinadas pelos interessados, via plataforma; (iii) deverá enviar os dados dos interessados, seus documentos legais, via plataforma; (iv) a FastSale é quem filtrará as propostas, contatos e ligações, e realizará o contato direto com o proprietário do imóvel repassando as propostas, por meio da plataforma online, e-mail, whatsapp ou ligação; (v) deverá utilizar o modelo da minuta de proposta da Fast Sale, disponível na plataforma, ou a sua própria minuta, desde que contenha as informações propostas e exigidas pela Fast Sale, preencher questionário online de proposta, de acordo com a legislação vigente.</p>
                      <h5>11. Adimplementos</h5>
                      <p>11.1. Você concorda que, (i) o usuário proprietário de imóvel deverá adimplir com a Fast Sale 1% (um por cento) sobre o valor inicial anunciado na plataforma online da Fast Sale, de acordo com o Contrato com a Fast Sale, e o pagamento será devido após o aceite da proposta, ou em caso da não efetivação do negócio, seja por desistência do proprietário, seja por desistência do comprador, o pagamento será devido, independentemente do dia da assinatura do contrato e finalização do negócio, à vista, em dinheiro, ou por meio de Transferência Eletrônica Disponível – TED, ou PIX; (ii) você está ciente e concorda que o pagamento de 1% da Fast Sale é pelo uso dos seus serviços, da plataforma online, avaliação, anúncios, entre outros aqui já constantes nestes Termos Adicionais, e demais Termos Adicionais e de Termos de Uso, não se trata de comissão de corretagem; (iii) o usuário proprietário de imóvel deverá adimplir a comissão de corretagem do corretor de imóveis que realizar a venda do imóvel, e este corretor de imóveis deverá receber 5% de comissão sobre o valor da venda, excluindo valores de permuta de outros imóveis ou outros bens, e o pagamento poderá ser no fechamento do negócio, no dia da assinatura do contrato, poderá ser à vista, em dinheiro, ou por meio de Transferência Eletrônica Disponível – TED ou PIX, ou como o próprio corretor de imóveis/imobiliária ajustar com o vendedor e comprador; (iv) O pagamento da comissão do corretor de imóveis, poderá ser ajustada por este com o vendedor ou comprador, tanto para mais, como para menos, conforme ajuste entre os mesmos; (v) O pagamento da comissão de corretagem, do corretor de imóveisque vender os imóveis, não é de responsabilidade da Fast Sale, e sim, do vendedor ou comprador da negociação do imóvel, conforme ajuste entre vendedor, comprador e o próprio corretor. </p>
                      <p>11.2. Você concorda que, na desistência da venda do imóvel, seja pelo usuário proprietário do imóvel, seja pela parte compradora, terão que pagar a Fast Sale o 1% pelo uso dos seus serviços, sobre o valor inicial disponibilizado na plataforma online da Fast Sale, de acordo com o Contrato com a Fast Sale.</p>
                      <p>11.3. Você concorda que, após 2 (dois) anos o imóvel estar na plataforma online e utilizando dos serviços da Fast Sale, sem ser vendido, poderá a Fast Sale cobrar o 1% (um por cento) pelo uso dos seus serviços dos usuários proprietários de imóveis, sobre o valor inicial disponibilizado na plataforma online da Fast Sale, de acordo com o Contrato com a Fast Sale, sendo de liberalidade da Fast Sale a continuidade do imóvel em sua plataforma e usando os seus serviços, mesmo após dois anos, e a referida cobrança de 1%, sendo possível o parcelamento do 1% em até 12 (doze) vezes, com correção monetária, IGPM ou IPCA, considerando o índice que apresente o maior resultado.</p>
                      <p>11.4. Você concorda que, por liberalidade da Fast Sale, os usuários proprietários de imóvel poderão ter que adimplir a mais, nos casos em que, (i) não responder as propostas recebidas; (ii) querer alterar o valor de venda para mais, do que já especificou no Contrato com a Fast Sale; (iii) não aceitar propostas que estejam dentro dos critérios de negociação, que constou no Contrato com a Fast Sale; (iv) impossibilitar a entrada no imóvel para as visitas; (v) imóvel com ônus e ações; (vi) imóvel irregular; (vii) vícios no imóvel; (viii) podendo a Fast Sale, imediatamente, cancelar estes Termos e demais Termos, seja por estes motivos, ou por qualquer outro, e cobrar, seja pelo uso dos seus serviços, seja pelos encargos gerados ao não honrar com as obrigações expressas nestes Termos e demais Termos, ou ao não cumprir a legislação vigente.</p>
                      <p>11.5. Você concorda que, por liberalidade da Fast Sale, os usuários corretores de imóveis e imobiliárias poderão ter que adimplir, nos casos em que:</p>                  
                      <p>(i) realizar divulgação diferente, publicar dados e informações dos critérios de negociação, salvo aquelas que sejam estritamente necessárias, constantes no Contrato com a Fast Sale; </p>
                      <p>(ii) não confirmar as visitas;</p>
                      <p>(iii) não comparecer em 2 (duas) visitas, previamente agendadas, sem aviso com antecedência mínima de 2 horas, será responsabilizado pelo custo de deslocamento dos corretores cadastrados que agendaram a visita, com o pagamento de uma taxa proporcional ao deslocamento; </p>
                      <p>(iv) não cancelar as visitas com antecedência mínima de 2 horas antes, caso não seja possível comparecer ou abrir o imóvel; </p>
                      <p>(v) impossibilitar entrada no imóvel para as visitas; </p>
                      <p>(vi) não retirar de suas plataformas digitais o anúncio de imóveis com negócios já concluídos, vendidos, sob pena de multa caso não retire o anúncio em até 24 horas; </p>
                      <p>(vii) não atualizar a cada 7 (sete) dias os preços dos imóveis à venda;</p>
                      <p>(viii) se utilizar das informações e documentos da plataforma online da Fast Sale para contatar diretamente os proprietários de imóveis; </p>
                      <p>(ix) desrespeitar os funcionários da Fast Sale, independentemente a função que exerçam;</p>
                      <p>(x) a Fast Sale poderá, imediatamente, cancelar estes Termos e demais Termos, seja por estes motivos, ou por qualquer outro, e cobrar, seja pelo uso dos seus serviços, seja pelos encargos gerados por você ao não honrar com as obrigações expressas nestes Termos e demais Termos, ou ao não cumprir a legislação vigente, com perdas e danos, e lucros cessantes.</p>
                      <p>11.6. Você concorda e está ciente que, adimplementos, cobranças de cumprimento, multas por descumprimento, conforme estes termos e demais termos, a Fast Sale realizará por meio de boleto, TED ou PIX, e em caso de não pagamento, o mesmo será executado por meio de protesto nos Órgãos de proteção ao crédito, e demais medidas extrajudiciais e judiciais que se tornem necessárias.</p>
                      <h5>12. Comissão</h5>
                      <p>12.1. Da Comissão que os usuários corretores de imóveis e imobiliárias receberão da Fast Sale por cadastrar imóveis na plataforma: </p>
                      <p>(i) O Contrato da Fast Sale com o usuário proprietário do imóvel é de 1% do valor inicialmente anunciado do imóvel na plataforma, de modo que os percentuais abaixo, de 10% ou 5%, são sobre este 1% do constante no referido Contrato da Fast Sale; </p>
                      <p>(ii) Os usuários corretores de imóveis e imobiliárias que cadastrarem imóveis na plataforma da Fast Sale, e ficarem responsáveis pelas visitas, conforme item nº 8 “Visitas” acima, receberão da Fast Sale 10% do valor líquido recebido pelo Contrato realizado entre a Fast Sale e o usuário proprietário do imóvel, descontados impostos, taxas, e custos operacionais estipulados pela Fast Sale (produção de fotos, avaliação, e deslocamentos de visitas), com possibilidade de variações, em razão de localidade, tipo de imóvel, fornecedores, entre outros, e desde já os usuários confirmam e aceitam os valores que serão estipulados, sem questionamentos, e cientes que somente receberão se cumpridas as obrigações referidas nestes Termos e Termos Adicionais;</p>
                      <p>(iii) Os usuários corretores de imóveis e imobiliárias que cadastrarem imóveis na plataforma da Fast Sale, e não ficarem responsáveis pelas visitas, seja por sua escolha, por determinação do usuário proprietário do imóvel, ou pela própria Fast Sale, conforme item nº 9 “Visitas” acima, receberão da Fast Sale 5% do valor líquido recebido pelo Contrato realizado entre a Fast Sale e usuário proprietário do imóvel, descontados impostos, taxas, e custos operacionais estipulados pela Fast Sale (produção de fotos, avaliação, e deslocamentos de visitas), com possibilidade de variações, em razão de localidade, tipo de imóvel, fornecedores, entre outros, e desde já os usuários confirmam e aceitam os valores que serão estipulados, sem questionamentos, e cientes que somente receberão se cumpridas as obrigações referidas nestes Termos e Termos Adicionais; </p>
                      <p>(iv) o recebimento do 10% ou 5% e pagamento pela Fast Sale, somente ocorrerá após o próprio recebimento integral do Contrato da Fast Sale, pela Fast Sale do usuário proprietário do imóvel; </p>                  
                      <p>(v) a Fast Sale terá o prazo de até 30 (trinta) dias, para o pagamento do 10% ou 5%, contados a partir do recebimento integral da Fast Sale do usuário proprietário do imóvel;</p>
                      <p>(vi) a Fast Sale não realizará o pagamento do 10% ou 5%, caso não receber integralmente o 1% do Contrato realizado com o usuário proprietário do imóvel;</p>
                      <p>(vii) vencido o prazo de contrato da Fast Sale com o usuário proprietário do imóvel, mesmo o imóvel não sendo vendido, a Fast Sale pagará o 10% ou 5% após o recebimento integral da Fast Sale com o usuário proprietário do imóvel.</p>
                      <p>12.2. Da Comissão que os usuários corretores de imóveis e imobiliárias poderão receber em caso de venda, realizada por algum dos usuários corretores/imobiliárias cadastrados:</p>
                      <p>(i) os usuários corretores de imóveis e imobiliárias que realizarem a venda: receberão do usuário proprietário de imóveis 5% (cinco por cento) do valor da venda do imóvel, excluindo valores de permuta de outros imóveis ou outros bens, ou como o próprio corretor de imóveis/imobiliária ajustar com o vendedor e comprador, durante a negociação da venda do imóvel;</p>
                      <p>(ii) os usuários corretores de imóveis e imobiliárias que cadastraram o imóvel na plataforma: poderão receber 10% do valor da comissão de corretagem da venda do imóvel, a título de comissão de captação/angariação, que receberão dos usuários corretores de imóveis e imobiliárias que realizarem a venda ou do usuário proprietário de imóveis;</p>
                      <p>(iii) os usuários corretores de imóveis e imobiliárias que realizarem a venda de imóveis terão a responsabilidade de constar expressamente, nas negociações e propostas, os dados dos usuários corretores e imobiliárias que cadastraram o imóvel na plataforma da Fast Sale;</p>
                      <p>(iv) a Fast Sale não se responsabiliza pelo pagamento da comissão de captação/angariação aos usuários corretores de imóveis e imobiliárias que cadastraram o imóvel na plataforma, uma vez que essa obrigação é dos usuários corretores de imóveis e imobiliárias que realizarem a venda ou do usuário proprietário de imóveis, bem como a Fast Sale não se responsabiliza pelo pagamento da comissão de corretagem, uma vez que essa obrigação é do usuário proprietário de imóveis ou como o próprio corretor de imóveis/imobiliária ajustar com o vendedor e comprador, durante a negociação da venda do imóvel.</p>
                      <p>13. Você declara e concorda que está ciente dos serviços da Fast Sale, avaliação, material fotográfico, abrir imóveis para as visitas, que a Fast Sale não realiza a corretagem, é o corretor de imóveis que exerce a intermediação da compra, venda, permuta, e faz a mediação da negociação entre vendedor e comprador, de modo que é o corretor de imóveis que executa a corretagem. </p>
                      <p>14. Você não poderá ceder, nem de qualquer outro modo transferir, sua conta a nenhuma outra pessoa ou entidade. Você concorda em cumprir todas as leis aplicáveis, quando usar a plataforma da Fast Sale e os serviços, e que somente poderá usar os serviços para finalidades legítimas. Você declara e garante, a legitimidade dos documentos cedidos e suas assinaturas, as quais deverão ser apostas digitalmente com comprovação da autoria e integridade dos documentos em formato eletrônico, inclusive os que utilizem certificados não emitidos pela ICPBrasil, desde que admitido pelas partes envolvidas como válido ou aceito pela pessoa a quem for oposto o documento.</p>
                      <p>15. Você declara e garante, a legitimidade das informações prestadas, e dos documentos cedidos, não violando a legislação vigente e nem direito de propriedade de terceiros, não podendo se utilizar de dados, informações e documentos sem a concordância da parte envolvida, seja do vendedor, comprador, seja da Fast Sale, não enviar propostas falsas, proprietários falsos, imóveis irregulares, simular, ou de qualquer forma enganar, cientes das responsabilidades cíveis e criminais que poderão lhe recair em virtude de seus próprios atos.</p>
                      <p>16. A Fast Sale, poderá imediatamente, ou quando desejar, encerrar estes Termos Adicionais de Usuários Corretores de Imóveis e Imobiliárias, ou quaisquer serviços, inclusive em relação a você ou, deixar de oferecer, ou negar acesso aos serviços, ou a qualquer parte deles, a qualquer momento, e por qualquer motivo.</p>
                      <p>17. A Fast Sale não será responsável pelo contrato da negociação, e sim os envolvidos na negociação, vendedor, comprador e corretor de imóveis, conforme ajustes entre os mesmos.</p>
                      <p>18. A Fast Sale não será responsável pelas informações prestadas pelos Usuários Corretores de Imóveis e Imobiliárias, e documentos enviados pelos mesmos, seja de cunho pessoal, seja do imóvel, seja do proprietário, estando cientes que a veracidade e originalidade são dos usuários corretores de imóveis e imobiliárias.</p>
                      <p>19. A Fast Sale não será responsável por danos no imóvel, nem quando das visitas de compradores interessados, mesmo em caso de furto, roubo, danos patrimoniais, sendo de responsabilidade dos corretores cadastrados.</p>
                      <p>20. A Fast Sale não será responsável por anúncio de imóvel que esteja em outras plataformas de venda, mesmo que imobiliárias e corretores cadastrados na plataforma.</p>
                      <p>21. A Fast Sale é uma plataforma de liquidez imobiliária, que avalia imóveis, tira fotos, e conecta imóveis à venda com proprietários, compradores, imobiliárias e corretores.</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Whats/>
      </div>
      <Footer/>
      </>
    );
  }
}


export default policyUse;