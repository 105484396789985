import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Container, Button } from "reactstrap";
import Axios from 'axios'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"


import './../../assets/scss/custom/components/_editProperties.scss'
import './../../assets/scss/custom/components/_addProperty.scss'

import SweetAlert from 'react-bootstrap-sweetalert';

class Units extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Tipos e unidades", link: "#" },
    ],
    modal_center: false,
    update_success:false,
    update_error:false,
    enterprises:[],
    }
    this.tog_center = this.tog_center.bind(this);
  }
  

  componentDidMount(){

    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        Axios.get(process.env.REACT_APP_API + '/api/constructions/' + localStorage.getItem('id'),
         {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
         .then(response => {
            this.setState({enterprises: response.data.enterprises})
         })
    })
    .catch(response =>{
      localStorage.removeItem("history_url")
      setTimeout(() => {
        window.open("/login", '_parent')
      }, 300);
    })
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }



  render() {

    const { enterprises } = this.state;

    return (
        <div className="page-content" id="addImovel">
        <Container fluid={true}>
          <Breadcrumbs title="Tipos e unidades" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h3>Seus empreendimentos</h3>
                  {enterprises.length > 0 ?
                  <>
                    <small>Escolha um empreendimento para adicionar unidades.</small>
                    <Row style={{marginTop:'30px'}}>
                    {enterprises.map((enterprise, index) => (
                      <Col key={index} className="col-auto"><li style={{
                        listStyle:'none',
                        backgroundColor:'#f4f4f4',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        height:'40px',
                        borderRadius:'8px',
                        padding:'0 15px',
                        fontSize:'18px',
                        cursor:'pointer',
                        marginBottom:'20px'
                      }} onClick={() => {
                        setTimeout(() => {
                          window.open('./../nova-unidade/?id=' + enterprise.id, '_parent')
                        }, 300);
                      }}>{enterprise.title}</li></Col>
                    ))}
                    </Row>
                  </>
                  :
                  <>
                    <p>Você ainda não tem nenhum empreencimento cadastrado.</p>
                    <Button onClick={() => {
                      setTimeout(() => {
                        window.open('./../novo-empreendimento', '_parent')
                      }, 300);
                    }}>Adicionar Empreendimento</Button>
                  </>
                  }
                  

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

            {/* Sucesso na atualização */}

            {this.state.update_success ? (
            <SweetAlert
              title="Cadastro realizado com sucesso!"
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => this.setState({ update_success: false })}
            ></SweetAlert>
            ) : null}

          {/* Falha na atualização */}

          {this.state.update_error ? (
            <SweetAlert
              title="O cadastro falhou, revise os campos e tente novamente."
              timeout={4000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ update_error: false })}
            ></SweetAlert>
            ) : null}
      </div>
    );
  }
}

export default Units;