import { createContext, useState } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [isFormBlurred, setIsFormBlurred] = useState(false);

  return (
    <FormContext.Provider value={{ isFormBlurred, setIsFormBlurred }}>
      {children}
    </FormContext.Provider>
  );
};