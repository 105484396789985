
import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, Label, Spinner } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from '../../store/actions';

// import images
import logodark from "../../assets/images/logo-dark.png";
import SweetAlert from 'react-bootstrap-sweetalert';
import Axios from 'axios';
import Whats from '../../components/Whats/Whats';

class ForgetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userType:null,
            email:null,
            pass_success:false,
            pass_error:false,
        };
    }


    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="/dashboard" className="logo"><img src={logodark} height="20" alt="logo" /></Link>
                                                        </div>

                                                        <h4 className="font-size-18 mt-4">Recuperar senha</h4>
                                                        <p className="text-muted">Para recuperar sua senha, preencha os campos abaixo.</p>
                                                    </div>

                                                    <Container>
                                                        <Row>
                                                            <Col style={{margin:'10px 0'}}>
                                                                <Label>Você é um(a)...</Label>
                                                                <select style={{height:'50px'}} className="form-control type_user" name="type_user" type="text" value={this.state.userType} onChange={(e) => this.setState({ userType: e.target.value})} required>
                                                                    <option value="0" selected disabled>Escolha uma opção</option>
                                                                    <option value="C">Corretor</option>
                                                                    <option value="I">Imobiliária</option>
                                                                    <option value="T">Construtora</option>
                                                                    <option value="P">Proprietário</option>
                                                                    <option value="A">Administrador</option>
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <AvForm className="form-horizontal">
                                                                    <div className="auth-form-group-custom mb-4">
                                                                        <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                        <Label htmlFor="useremail">Email</Label>
                                                                        <AvField name="useremail"
                                                                        value={this.state.email}
                                                                        type="email"
                                                                        validate={{ email: true, required: true }}
                                                                        className="form-control"
                                                                        id="useremail"
                                                                        placeholder="Seu email"
                                                                        errorMessage="Obrigatório ser um e-mail válido."
                                                                        onChange={(e) => this.setState({email: e.target.value})}
                                                                        />
                                                                    </div>
                                                                </AvForm>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col style={{textAlign:'right'}}>
                                                                <Button onClick={() => {
                                                                    document.getElementById('load').classList.add('active')
                                                                    const data = {
                                                                    email: this.state.email,
                                                                    user_type: this.state.userType
                                                                    }
                                                                    Axios.post(process.env.REACT_APP_API + '/api/forgot-password', data,
                                                                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                                    .then(response => {
                                                                        this.setState({pass_success: true})
                                                                        document.getElementById('load').classList.remove('active')
                                                                    })
                                                                    .catch(response => {
                                                                        this.setState({pass_error: true})
                                                                        document.getElementById('load').classList.remove('active')
                                                                    })
                                                                }}>Enviar</Button>
                                                            </Col>
                                                        </Row>
                                                    </Container>

                                                    <div className="mt-5 text-center">
                                                        <p>Ainda não tem conta? <Link to="/registre-se" className="fw-medium text-primary"> Registre-se </Link> </p>
                                                        <p>Fast Sale Imóveis</p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                        <div id="load" className="load">
                            <Spinner className="me-2" color="success" />
                        </div>
                    </Container>
                        {/* Sucesso no agendamento */}

                        {this.state.pass_success ? (
                            <SweetAlert
                            title="Confira seu e-mail para concluir a alteração!"
                            timeout={4000}
                            showCloseButton={false}
                            showConfirm={false}
                            success
                            onConfirm={() => this.setState({ pass_success: false })}
                            ></SweetAlert>
                            ) : null}

                        {/* Falha no agendamento */}

                        {this.state.pass_error ? (
                            <SweetAlert
                            title="E-mail inválido, verifique e tente novamente."
                            timeout={4000}
                            showCloseButton={false}
                            showConfirm={false}
                            error
                            onConfirm={() => this.setState({ pass_error: false })}
                            ></SweetAlert>
                            ) : null}
                </div>
                <Whats/>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
}

export default withRouter(
    connect(mapStatetoProps, { forgetUser })(ForgetPasswordPage)
);
