import { AuthComponent } from '../../../../Authentication/Login/components/authComponent';
import {Input, Button, Spinner} from 'reactstrap';
import {useState} from 'react';
import './styles.scss';
import Axios from 'axios'

export const PaymentError = ({open, onClose, errors = null, singleError = ''}) => {

  const [cep, setCep] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCep = (e) => {
    const regex = /^[0-9]{0,8}$/;
    if (regex.test(e)) {
      setCep(e);
    }
  }

  const handleSendCep = () => {
    setLoading(true)
    let url = null

    if(localStorage.getItem('access') === 'C'){
      url = 'brokers-update'
    }
    if(localStorage.getItem('access') === 'I'){
      url = 'real-estates-update'
    }
    if(localStorage.getItem('access') === 'T'){
      url = 'enterprise/construction-update'
    }

    const data = new FormData()
    data.append('cep', cep)

    Axios.post(process.env.REACT_APP_API + '/api/auth/' + url + '/' + localStorage.getItem('id'), data,
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setTimeout(() => {
            window.location.reload()
          }, 0);
        }).catch(error => {
          setTimeout(() => {
            window.location.reload()
          }, 0);
        })
  }

  return(
    <>
    {loading ? <Spinner className="me-2" color="success" /> :
      <div className={`error-modal ${open ? 'active' : ''}`}>
        <span className="close-modal" onClick={onClose}><i className="ri-close-fill "></i></span>
        {singleError === 'cep' ?
          <div className="title">
            <h3>Ops!</h3>
            <p style={{textAlign:'center'}}>O CEP cadastrado em sua conta Fast Sale é inválido.</p>
            <p>Por favor, insira um CEP válido (apenas números).</p>
            <Input type="text" value={cep} onChange={(e) => handleCep(e.target.value)}></Input>
            <Button onClick={handleSendCep}>Enviar</Button>
          </div>
        :
        <div className="title">
          <h3>Ops!</h3>
          <p>Houveram problemas com seu pagamento.</p>
          <ul>
          {singleError &&
            <li>
              {singleError === 'fatal' ? 'Verifique os dados do cartão, caso estejam corretos, entre em contato com nosso suporte.' : singleError}
            </li>
          }
          {errors && errors.card?.length > 0 && errors.card?.map((item, index) => (
            <li key={index}>
              {item === 'The number field is not a valid card number' && 'O número do cartão é inválido'}
              {item === 'Could not detect card brand.' && 'Não foi encontrada a bandeira do cartão'}
              {item === 'The card number is required' && 'O número do cartão é obrigatório'}
              {item === 'The card expiration date is invalid.' && 'A data de validade do cartão é inválida.'}
              {item === 'The field exp_month must be between 1 and 12' && 'O campo mês da data de expiração deve estar entre 1 e 12.'}
              {item === 'Card expired.' && 'Este cartão expirou.'}
            </li>
          ))}
          </ul>
        </div>
        }
      </div>
      }
      <div onClick={onClose} className={`error-modal-overlay ${open ? 'active' : ''}`}></div>
    </>
  )
}