import Axios from 'axios';
import { useState } from 'react';
import { Button, Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import Footer from '../Home/components/Footer';
import Whats from '../../components/Whats/Whats';
import Header from '../Home/components/Header';

import BrokerNoActive from './../../pages/Home/assets/broker_no_active.png'
import RealStateNoActive from './../../pages/Home/assets/house_no_active.png'
import BrokerActive from './../../pages/Home/assets/broker_active.png'
import RealStateActive from './../../pages/Home/assets/house_active.png'
import SweetAlert from 'react-bootstrap-sweetalert';

export const UpdateDocs = () => {

    const [rg, setRg] = useState('')
    const [corporateName, setCorporateName] = useState('')
    const [ownerCpf, setOwnerCpf] = useState('')
    const [ownerRg, setOwnerRg] = useState('')
    const [cnpjImage, setCnpjImage] = useState(null)
    const [creciImage, setCreciImage] = useState(null)
    const [creciImageVerso, setCreciImageVerso] = useState(null)
    const [rgImage, setRgImage] = useState(null)
    const [rgImageVerso, setRgImageVerso] = useState(null)
    const [selected, setSelected] = useState(false)
    const [access, setAccess] = useState('')
    const [imageSrc, setImageSrc] = useState({ broker: BrokerNoActive, realState: RealStateNoActive });
    const [email, setEmail] = useState('')
    const [updateSuccess, setUpdateSuccess] = useState(false)
    const [updateError, setUpdateError] = useState(false)

    const sendUpdate = () => {
        document.getElementById('load').classList.add('active')

        const data = new FormData()

        data.append('user_type', access)
        data.append('user_email', email)
        if(access === 'C'){
            data.append('rg', rg)
            data.append('creci_photo', creciImage)
            data.append('rg_photo', rgImage)
            data.append('creci_photo_verso', creciImageVerso)
            data.append('rg_photo_verso', rgImageVerso)
        }
        if(access === 'I'){
            data.append('owner_cpf', ownerCpf)
            data.append('owner_rg', ownerRg)
            data.append('photo_cnpj', cnpjImage)
            data.append('photo_crecij', creciImage)
            data.append('photo_owner', rgImage)
            data.append('photo_crecij_verso', creciImageVerso)
            data.append('photo_owner_verso', rgImageVerso)
        }

        Axios.post(process.env.REACT_APP_API + '/api/user-update-docs/', data)
            .then(response => {
                document.getElementById('load').classList.remove('active')
                setUpdateSuccess(true)
            })
            .catch(response => {
                document.getElementById('load').classList.remove('active')
                setUpdateError(true)
            })
        }

    return(
        <>
        <Header />
        <div className='logoutSendDocs'>
            <div className="container-send-docs-title">
                {selected && <h3>Complete seus dados para validar seu cadastro.</h3>}
                {!selected && <h3>Selecione o tipo de perfil e digite seu e-mail.</h3>}
            </div>
            {!selected &&
                <Container>
                  <Col md={6}>
                    <Label htmlFor="rg" className="col-md-12 col-form-label">Seu perfil</Label>
                    <div className='perfil-container'>
                        <div className={access === 'C' ? 'active option' : 'option'} onClick={() => {
                            setAccess('C')
                            setImageSrc({ broker: BrokerActive, realState: RealStateNoActive })
                            }}>
                            <img src={imageSrc.broker} alt='Broker Active'/>
                            Corretor
                        </div>

                        <div className={access === 'I' ? 'active option' : 'option'} onClick={() => {
                            setAccess('I')
                            setImageSrc({ broker: BrokerNoActive, realState: RealStateActive })
                            }}>
                            <img src={imageSrc.realState} alt="Real State Active"/>
                            Imobiliária
                        </div>
                    </div>
                  </Col>
                  <Col md={6} style={{marginTop:'30px'}}>
                    <Label htmlFor="rg" className="col-md-12 col-form-label">Seu email de acesso</Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email de login"
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Col>
                  <Col xs={12} style={{marginTop:'30px'}}>
                    {email !== '' && access !== '' ?
                        <Button color="primary" onClick={() => setSelected(true)}>Enviar documentos</Button>
                    :
                        <Button color="secondary" disabled>Enviar documentos</Button>
                    }
                  </Col>
                </Container>
            }
            {selected && <Container>
              <Row style={{ gap: '20px' }}>
                {access === 'C' &&
                  <Col md={12}>
                    <Label htmlFor="rg" className="col-md-12 col-form-label">Nº RG</Label>
                    <Input
                      id="rg"
                      name="rg"
                      placeholder="RG"
                      type="text"
                      className="form-control"
                      value={rg}
                      onChange={(e) => setRg(e.target.value)}
                      required
                    />
                  </Col>
                }
                {access === 'I' &&
                <>
                  <Col md={12}>
                    <Label htmlFor="rg" className="col-md-12 col-form-label">Razão Social</Label>
                    <Input
                      id="social"
                      name="social"
                      placeholder="Razão Social"
                      type="text"
                      className="form-control"
                      value={corporateName}
                      onChange={(e) => setCorporateName(e.target.value)}
                      required
                    />
                  </Col>
                  <Col md={5}>
                    <Label htmlFor="rg" className="col-md-12 col-form-label">CPF do proprietário</Label>
                    <Input
                      id="ownerCpf"
                      name="ownerCpf"
                      placeholder="Cpf do proprietário"
                      type="text"
                      className="form-control"
                      value={ownerCpf}
                      onChange={(e) => setOwnerCpf(e.target.value)}
                      required
                    />
                  </Col>
                  <Col md={5}>
                    <Label htmlFor="rg" className="col-md-12 col-form-label">RG do proprietário</Label>
                    <Input
                      id="ownerRg"
                      name="ownerRg"
                      placeholder="Rg do proprietário"
                      type="text"
                      className="form-control"
                      value={ownerRg}
                      onChange={(e) => setOwnerRg(e.target.value)}
                      required
                    />
                  </Col>
                  <Col
                  className="col-auto"
                  style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                >
                  <div
                    style={{
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      minWidth: '320px',
                      maxWidth: '480px',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                  </div>
                  <h4 className="card-title">Cartão CNPJ</h4>
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="cnpjImage"
                      onChange={(e) => setCnpjImage(e.target.files[0])}
                    />
                  </div>
                </Col>
                </>
                }
                <Col
                  className="col-auto"
                  style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                >
                  <div
                    style={{
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      minWidth: '320px',
                      maxWidth: '480px',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                  </div>
                  {access === 'C' &&
                  <h4 className="card-title">Imagem do CRECI (frente)</h4>
                  }
                  {access === 'I' &&
                  <h4 className="card-title">Imagem do CRECI J (frente)</h4>
                  }
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="creciImage"
                      onChange={(e) => setCreciImage(e.target.files[0] )}
                    />
                  </div>
                </Col>
                <Col
                  className="col-auto"
                  style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                >
                  <div
                    style={{
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      minWidth: '320px',
                      maxWidth: '480px',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                  </div>
                  {access === 'C' &&
                  <h4 className="card-title">Imagem do CRECI (verso)</h4>
                  }
                  {access === 'I' &&
                  <h4 className="card-title">Imagem do CRECI J (verso)</h4>
                  }
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="creciImageVerso"
                      onChange={(e) => setCreciImageVerso(e.target.files[0])}
                    />
                  </div>
                </Col>
                <Col
                  className="col-auto"
                  style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                >
                  <div
                    style={{
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      minWidth: '320px',
                      maxWidth: '480px',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                  </div>
                  {access === 'C' &&
                  <h4 className="card-title">Foto do documento (frente do rg, cnh, ...)</h4>
                  }
                  {access === 'I' &&
                  <h4 className="card-title">Foto do documento do proprietário (frente do rg, cnh, ...)</h4>
                  }
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="rgImage"
                      onChange={(e) => setRgImage(e.target.files[0])}
                    />
                  </div>
                </Col>
                <Col
                  className="col-auto"
                  style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                >
                  <div
                    style={{
                      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                      minWidth: '320px',
                      maxWidth: '480px',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                  </div>
                  {access === 'C' &&
                  <h4 className="card-title">Foto do documento (verso do rg, cnh, ...)</h4>
                  }
                  {access === 'I' &&
                  <h4 className="card-title">Foto do documento do proprietário (verso do rg, cnh, ...)</h4>
                  }
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="rgImage"
                      onChange={(e) => setRgImageVerso(e.target.files[0])}
                    />
                  </div>
                </Col>
              </Row>   
            </Container>}
            {selected && <div className="container-send-docs-button">
            {access === 'C' ?
              ((rgImageVerso !== '' &&
              rgImage !== '' &&
              creciImageVerso !== '' &&
              creciImage !== '') ?
              <Button onClick={() => {
                // this.setState({validateDocs: true})
                setTimeout(() => {
                  sendUpdate()
                }, 500);
                    }}>Enviar documentos</Button>
              :
              <Button style={{
                backgroundColor:'#cdcdcd',
                color:'#333',
                cursor:'disabled'
              }} disabled><span style={{color:"#333"}}>Todos os documentos são obrigatórios</span></Button>
              )
            :
              ((rgImageVerso !== '' &&
              rgImage !== '' &&
              creciImageVerso !== '' &&
              creciImage !== '' &&
              cnpjImage !== '') ?
              <Button onClick={() => {
                // this.setState({validateDocs: true})
                setTimeout(() => {
                  sendUpdate()
                }, 500);
                    }}>Enviar documentos</Button>
              :
              <Button style={{
                backgroundColor:'#cdcdcd',
                color:'#333',
                cursor:'disabled'
              }} disabled><span style={{color:"#333"}}>Todos os documentos são obrigatórios</span></Button>
              )
          }
          </div>}
        </div>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>

          {/* Sucesso na edição do usuário */}

          {updateSuccess ? (
            <SweetAlert
              title="Seus documentos foram enviados e serão analisados, você já pode acessar a plataforma."
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => {
                setTimeout(() => {
                    window.open('/login', '_parent')
                }, 100);
              }}
            ></SweetAlert>
            ) : null}

          {/* Falha na edição do usuário */}

          {updateError ? (
            <SweetAlert
              title="Algo deu errado, tente novamente, se o problema persistir, entre em contato com o suporte."
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => {
                setTimeout(() => {
                    window.location.reload()
                }, 100);
              }}
            ></SweetAlert>
            ) : null}
        <Whats/>
        <Footer />
        </>
    )
}