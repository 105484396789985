import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
import 'swiper/css';
import "swiper/css/navigation";
import styles from '../styles/Highlights.module.css';
import { Navigation } from "swiper";
import HighlightsMobile from "./HighlightsMobile";
import Icon from '@mdi/react';
import { mdiBedKingOutline, mdiCar, mdiRulerSquare, mdiMapMarker } from '@mdi/js';
import ContentEditable from "react-contenteditable";
import { ChromePicker } from 'react-color';

export default function Highlights({data}) {
  const [isMobile, setIsMobile] = useState(false);
  const isMobileQuery = useMediaQuery({ query: `(max-width: 767px)` });

  const [ highTitle, setHighTitle] = useState('Destaques')
  const [ highTitleEnabled, setHighTitleEnabled] = useState(true)
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [showPickerButton, setShowPickerButton] = useState(false);
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [showPickerTextButton, setShowPickerTextButton] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileQuery);

    if(localStorage.getItem('high_button_text_color')){
      setColorTextButton(JSON.parse(localStorage.getItem('high_button_text_color')))
    }
    if(localStorage.getItem('high_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('high_button_color')))
    }
    if(localStorage.getItem('high_title')){
      setHighTitle(localStorage.getItem('high_title'))
    }

  }, [isMobileQuery]);

  if (isMobile) {
    return <HighlightsMobile />;
  }

  return (
    <section className={styles.teste}>
      {/* <h1 className={styles.title}>Destaques</h1> */}
      <div className={styles.titleContainer}>
        <ContentEditable
            html={highTitle}
            className={styles.titleEdit}
            id='highTitleEdit'
            disabled={highTitleEnabled}
            onChange={(e) => {
              setHighTitle(e.target.value)
              localStorage.setItem('high_title', e.target.value)
            }}
            onClick={(e) => {
              if(highTitle === 'Destaques' && !highTitleEnabled){
                setHighTitle('')
                localStorage.setItem('high_title', '')
              }
            }}
          />
          <div className={styles.actionIcons}>
            <label className="title">
              <span onClick={() => {
                if(!highTitleEnabled){
                  setHighTitleEnabled(true)
                  if(highTitle === ''){
                    setHighTitle('Destaques')
                    localStorage.setItem('high_title', 'Destaques')
                  }
                }else{
                  setHighTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('highTitleEdit').focus()
                    if(highTitle === 'Destaques'){
                      setHighTitle('')
                      localStorage.setItem('high_title', '')
                    }
                  }, 300);
                }
              }}>{highTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
              <i className="ri-text" onClick={() => {
                if(!highTitleEnabled){
                  setHighTitleEnabled(true)
                  if(highTitle === ''){
                    setHighTitle('Destaques')
                    localStorage.setItem('high_title', 'Destaques')
                  }
                }else{
                  setHighTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('highTitleEdit').focus()
                    if(highTitle === 'Destaques'){
                      setHighTitle('')
                      localStorage.setItem('high_title', '')
                    }
                  }, 300);
                }
              }}></i>
            </label>
          </div>
      </div>
      
      <Swiper 
      slidesPerView={3} 
      navigation={true}
      spaceBetween={-40}
      modules={[Navigation]} 
      loop={true}
      style={{height:'465px'}}
      className="mySwiperCards">
        <SwiperSlide style={{display:'flex', justifyContent:'center'}}>
          <div className={styles.cardContainer}>
            <div className={styles.bg}></div>
            <div className={styles.container_immobile}>
              <div className={styles.title_immobile}>
                <p className={styles.immobile}>Imóvel Exemplo</p>
              </div>
              <div className={styles.config_immobile}>
                <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7}/>3 suítes</span>
                <span className={styles.container_icons}><Icon path={mdiCar} size={.7}/> 3 vagas</span>
                <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7}/>152m</span>
              </div>
              <div className={styles.container_price}>
                  <p style={{borderColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`}}>R$4.000.000,00</p>
              </div>
              <div className={styles.view}>
                <div className={styles.container_location}>
                  <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps} />
                  <p className={styles.location}> Localização</p>
                </div>
                <p className={styles.view_immobile}  style={{
                  backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                  color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                }}>Ver imóvel</p> 
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.cardContainer}>
            <div className={styles.bg}></div>
            <div className={styles.container_immobile}>
              <div className={styles.title_immobile}>
                <p className={styles.immobile}>Imóvel Exemplo</p>
              </div>
              <div className={styles.config_immobile}>
                <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
              </div>
              <div className={styles.container_price}>
                <p style={{borderColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`}}>R$4.000.000,00</p>
              </div>
              <div className={styles.view}>
                <div className={styles.container_location}>
                  <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps} />
                  <p className={styles.location}> Localização</p>
                </div>
                <p className={styles.view_immobile}  style={{
                  backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                  color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                }}>Ver imóvel</p> 
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.cardContainer}>
            <div className={styles.bg}></div>
            <div className={styles.container_immobile}>
              <div className={styles.title_immobile}>
                <p className={styles.immobile}>Imóvel Exemplo</p>
              </div>
              <div className={styles.config_immobile}>
                <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
              </div>
              <div className={styles.container_price}>
                <p style={{borderColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`}}>R$4.000.000,00</p>
              </div>
              <div className={styles.view}>
                <div className={styles.container_location}>
                  <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                  <p className={styles.location}> Localização</p>
                </div>
                <p className={styles.view_immobile}  style={{
                  backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                  color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                }}>Ver imóvel</p> 
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.cardContainer}>
            <div className={styles.bg}></div>
            <div className={styles.container_immobile}>
              <div className={styles.title_immobile}>
                <p className={styles.immobile}>Imóvel Exemplo</p>
              </div>
              <div className={styles.config_immobile}>
                <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
              </div>
              <div className={styles.container_price}>
                <p style={{borderColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`}}>R$4.000.000,00</p>
              </div>
              <div className={styles.view}>
                <div className={styles.container_location}>
                  <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                  <p className={styles.location}> Localização</p>
                </div>
                <p className={styles.view_immobile}  style={{
                  backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                  color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                }}>Ver imóvel</p> 
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className={styles.container_button}>
        <button style={{
          backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
          color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
        }}>Ver todos os imóveis</button>
        <div className={styles.actionIcons}>
          <label className="title">
            <i className="ri-brush-fill" onClick={() => {
              setShowPickerButton(!showPickerButton)
              setShowPickerTextButton(false)
              }}></i>
            <i className="ri-font-color" onClick={() => {
              setShowPickerTextButton(!showPickerTextButton)
              setShowPickerButton(false)
              }}></i>
              {showPickerButton && (
                <div className={styles.colorPickerButton}>
                  <i className="ri-close-fill closeIcon" onClick={() => setShowPickerButton(!showPickerButton)}></i>
                  <ChromePicker
                    color={colorButton ?? {r: 0, g: 0, b: 0, a: 1}}
                    onChange={(e) => {
                      setColorButton(e.rgb)
                      localStorage.setItem('high_button_color', JSON.stringify(e.rgb))
                    }}
                />
                </div>
              )}
              {showPickerTextButton && (
              <div className={styles.colorPickerButton}>
                <i className="ri-close-fill closeIcon" onClick={() => setShowPickerTextButton(!showPickerTextButton)}></i>
                <ChromePicker
                  color={colorTextButton ?? {r: 0, g: 0, b: 0, a: 1}}
                  onChange={(e) => {
                    setColorTextButton(e.rgb)
                    localStorage.setItem('high_button_text_color', JSON.stringify(e.rgb))
                  }}
                />
                </div>
              )}
          </label>
        </div>
      </div>
    </section>
  );
}