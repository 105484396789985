import styles from '../styles/ImobileSecondMobile.module.css'
import Teste from '../assets/foto_fake.jpg';
import Icon from '@mdi/react';
import { mdiBedKingOutline, mdiCarBack, mdiRulerSquare, mdiShare } from '@mdi/js';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from 'swiper';
import { useState, useEffect } from 'react';
import { ChromePicker } from 'react-color';

const ImobileSecondMobile = () => {
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [showPickerButton, setShowPickerButton] = useState(false);
  const [showPickerTextButton, setShowPickerTextButton] = useState(false);
  const [colorSecondButton, setColorSecondButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [colorSecondTextButton, setSecondColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});

  useEffect(() => {
    if(localStorage.getItem('high_button_text_color')){
      setSecondColorTextButton(JSON.parse(localStorage.getItem('high_button_text_color')))
    }
    if(localStorage.getItem('high_button_color')){
      setColorSecondButton(JSON.parse(localStorage.getItem('high_button_color')))
    }
  }, []);

  return(
    <section>
      <div className={styles.container_imgs}>
        <img src={Teste} alt="" />
      </div>
      <div className={styles.container_config}>
        <p className={styles.name}>Casa de alto padrão no condomínio Caledônia em Camboriú</p>
        <div className={styles.config}>
          <div className={styles.container_icons}>
            <p className={styles.config_icon}><Icon path={mdiBedKingOutline} size={.9} /> 3 suítes</p>
            <p className={styles.config_icon}><Icon path={mdiCarBack} size={.9} />2 vagas</p>
            <p className={styles.config_icon}><Icon path={mdiRulerSquare} size={.9} />127 m</p>
          </div>
          <div>
            <p>Camboriú</p>
          </div>
        </div>
        <div>
          <p className={styles.price}>R$4.000.000,00</p>
        </div>
        <div className={styles.iptu}>
          <p>Condomínio: R$1.100,00</p>
          <p>IPTU: R$2.000,00</p>
        </div>

          <div className={styles.container_test_information}>
              <button className={styles.information} style={{
              backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
              color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
              }}>Quero mais informações</button>
          </div>

          <div className={styles.container_test_information}> 
            <button className={styles.share} style={{
            backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
            color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
            }}>Compartilhe <Icon path={mdiShare} size={1} /></button>
          </div>
          <div className={styles.actionIcons3}>
            <label className="title">
              <i className="ri-brush-fill" onClick={() => {
                setShowPickerButton(!showPickerButton)
                setShowPickerTextButton(false)
                }}></i>
              <i className="ri-font-color" onClick={() => {
                setShowPickerTextButton(!showPickerTextButton)
                setShowPickerButton(false)
                }}></i>
              {showPickerButton && (
                <div className={styles.colorPickerButton}>
                  <i className="ri-close-fill closeIcon" onClick={() => setShowPickerButton(!showPickerButton)}></i>
                  <ChromePicker
                    color={colorButton ?? {r: 0, g: 0, b: 0, a: 1}}
                    onChange={(e) => {
                      setColorButton(e.rgb)
                      localStorage.setItem('imobile_second_button_color', JSON.stringify(e.rgb))
                    }}
                  />
                  </div>
                )}
                {showPickerTextButton && (
                <div className={styles.colorPickerButton}>
                  <i className="ri-close-fill closeIcon" onClick={() => setShowPickerTextButton(!showPickerTextButton)}></i>
                  <ChromePicker
                    color={colorTextButton ?? {r: 0, g: 0, b: 0, a: 1}}
                    onChange={(e) => {
                      setColorTextButton(e.rgb)
                      localStorage.setItem('imobile_second_button_text_color', JSON.stringify(e.rgb))
                    }}
                  />
                  </div>
                )}
            </label>
          </div>

        <div>
          <p className={styles.about}>Sobre o Imóvel</p>
          <div className={styles.container_about}>
            <p>- 3 Suítes (Sendo uma Master com Hidro)</p>
            <p>- 3 Banheiros</p>
            <p>- Lavabo</p>
            <p>- Churrasqueira</p>
            <p>- Área de Serviço</p>
            <p>- Living</p>
            <p>- Sala de Jantar</p>
            <p>- Sala de Jantar</p>
            <p>- Cozinha</p>
            <p>- Sacada Integrada</p>
            <p className={styles.characteristics}>Características do Empreendimento:</p>
            <p>- Acessibilidade para Cadeirantes</p>
            <p>- Acessibilidade para Cadeirantes</p>
            <p>- Acessibilidade para Cadeirantes</p>
            <p>- Acessibilidade para Cadeirantes</p>
          </div>
        </div>
        <div>
          {/* <p>MAPA</p> */}
        </div>
      </div>

      <div className={styles.container_carousel}>
        <Swiper 
          slidesPerView={3}
          spaceBetween={0}
          navigation={true} 
          modules={[Navigation]} 
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1
            },
            759: {
              slidesPerView: 3
            }
          }}>
          <SwiperSlide>
            <div className={styles.container}>
              <div className={styles.immobile}>
                <div className={styles.bg}></div>
                <div className={styles.immobile_config}>
                  <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                  <div className={styles.immobile_options}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 3 suítes</span>
                    <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9} /> 152m</span>
                  </div>
                  <div>
                    <span className={styles.immobile_price}>R$4.000.000,00</span>
                  </div>
                  <div className={styles.immobile_view}>
                    <div className={styles.details}></div>
                    <p style={{
                      backgroundColor:`rgba(${colorSecondButton?.r}, ${colorSecondButton?.g}, ${colorSecondButton?.b}, ${colorSecondButton?.a})`,
                      color: `rgba(${colorSecondTextButton?.r}, ${colorSecondTextButton?.g}, ${colorSecondTextButton?.b}, ${colorSecondTextButton?.a})`
                    }}>Ver imóvel</p> 
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className={styles.container}>
              <div className={styles.immobile}>
                <div className={styles.bg}></div>
                <div className={styles.immobile_config}>
                  <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                  <div className={styles.immobile_options}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 3 suítes</span>
                    <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9} /> 152m</span>
                  </div>
                  <div>
                    <span className={styles.immobile_price}>R$4.000.000,00</span>
                  </div>
                  <div className={styles.immobile_view}>
                    <div className={styles.details}></div>
                    <p>Ver imóvel</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container}>
              <div className={styles.immobile}>
                <div className={styles.bg}></div>
                <div className={styles.immobile_config}>
                  <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                  <div className={styles.immobile_options}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 3 suítes</span>
                    <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9} /> 152m</span>
                  </div>
                  <div>
                    <span className={styles.immobile_price}>R$4.000.000,00</span>
                  </div>
                  <div className={styles.immobile_view}>
                    <div className={styles.details}></div>
                    <p style={{
                      backgroundColor:`rgba(${colorSecondButton?.r}, ${colorSecondButton?.g}, ${colorSecondButton?.b}, ${colorSecondButton?.a})`,
                      color: `rgba(${colorSecondTextButton?.r}, ${colorSecondTextButton?.g}, ${colorSecondTextButton?.b}, ${colorSecondTextButton?.a})`
                    }}>Ver imóvel</p> 
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container}>
              <div className={styles.immobile}>
                <div className={styles.bg}></div>
                <div className={styles.immobile_config}>
                  <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                  <div className={styles.immobile_options}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 3 suítes</span>
                    <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9} /> 152m</span>
                  </div>
                  <div>
                    <span className={styles.immobile_price}>R$4.000.000,00</span>
                  </div>
                  <div className={styles.immobile_view}>
                    <div className={styles.details}></div>
                    <p style={{
                      backgroundColor:`rgba(${colorSecondButton?.r}, ${colorSecondButton?.g}, ${colorSecondButton?.b}, ${colorSecondButton?.a})`,
                      color: `rgba(${colorSecondTextButton?.r}, ${colorSecondTextButton?.g}, ${colorSecondTextButton?.b}, ${colorSecondTextButton?.a})`
                    }}>Ver imóvel</p> 
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
    </section>
  )
}

export default ImobileSecondMobile;