import { Input, Label } from 'reactstrap';
import './styles.scss';
import { useEffect, useState } from 'react';

export const NumberCardInput = ({handleInputChange, handleInputFocus}) => {
  const [inputValue, setInputValue] = useState('')

  const formatCardNumber = (inputValue) => {
    const cleanValue = inputValue.replace(/\D/g, '');

    let maskedValue = '';
    for (let i = 0; i < cleanValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        maskedValue += ' ';
      }
      maskedValue += cleanValue.charAt(i);
    }

    return maskedValue.trim();
  };

  const handleChange = (event) => {
    const rawValue = event.target.value;
    const formattedValue = formatCardNumber(rawValue);
    setInputValue(formattedValue);
    handleInputChange(event);
  };

  return(
    <fieldset>
      <Label>Número do cartão</Label>
      <Input
        type="text"
        name="number"
        placeholder="0000 0000 0000 0000"
        value={inputValue}
        onChange={handleChange}
        inputmode="numeric"
        onFocus={handleInputFocus}
        maxLength={19}
      />
    </fieldset>
  )
}