import styles from '../styles/HeaderHighlights.module.css';
import { useState, useEffect } from 'react';
import SearchHighlights from './SearchHighlights';

const HeaderHighlights = () => {
  const [logo, setLogo] = useState();

  function isBase64(str) {
    try {
      atob(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const navLogo = localStorage.getItem('nav_logo');
    if (navLogo && navLogo.slice(0, 4) !== 'http') {
      if (localStorage.getItem('nav_logo')) {
        const fileAsBase64String = localStorage.getItem('nav_logo');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
  
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
  
          const blob = new Blob([array], { type: 'application/octet-stream' });
  
          const file = new File([blob], 'nome-do-arquivo.extensão');
  
          setLogo(file);
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      }
    } else {
      setLogo(navLogo);
    }
  }, [])

  function handleLogoFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const fileAsBase64String = reader.result.split(',')[1];
      localStorage.setItem('nav_logo', fileAsBase64String);
    });
    reader.readAsDataURL(file);
  }

  return(
    <>
      <section>
        <div className={styles.container_logo}>
          {logo ? (
            <img src={(logo && logo.slice(0, 4) !== "http") ? URL.createObjectURL(logo) : logo}
              alt=""
              className={styles.logo}
            />
          ) : (
            <div className={styles.semLogo}>Sua logo</div>
          )}
          <label for="logo" className={styles.inputLogo}><i className="ri-camera-fill"></i></label>
          <input id="logo" name="logo" type="file" style={{display:'none'}} onChange={(e) => {setLogo(e.target.files[0]); handleLogoFileChange(e)}} />
        </div>
      </section>
      <div className={styles.detail}></div>
      <SearchHighlights />
    </>
  )
}

export default HeaderHighlights