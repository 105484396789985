import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Button } from 'reactstrap';
import axios from 'axios';

const CnpjInput = ({ onCnpjChange, onExists }) => {
  const [cnpj, setCnpj] = useState('');
  const [isExist, setIsExist] = useState('');

  const handleCnpjChange = (value) => {
    onCnpjChange(value)
    setCnpj(value)
  };

  const handleBlur = () => {
    if(cnpj && cnpj !== ''){
      verifyCnpj(cnpj);
    }
  };

  const verifyCnpj = (cnpj) => {
    axios
      .post(`${process.env.REACT_APP_API}/api/cnpj/verify`, {
        cnpj: (cnpj && cnpj !== '') && cnpj?.replace(/[.-\/]/g, '')?.replace('-', ''),
        user_type: 'I'
      })
      .then((response) => {
        if(response.data.exists === true){
          setIsExist('CNPJ já cadastrado como imobiliária.')
        }else{
          setIsExist('')
        }
        
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    onExists(isExist);
  }, [isExist]);

  return (
    <>
    <InputMask
      mask="99.999.999/9999-99"
      placeholder="00.000.000/0000-00"
      value={cnpj}
      onChange={(e) => handleCnpjChange(e.target.value)}
      onBlur={handleBlur}
      inputMode="numeric"
    />
    {isExist !== '' && (
      <div style={{ textAlign: 'center' }}>
        <p style={{ color: 'red' }}>{isExist}<br></br>Faça login, ou utilize outro CPF.</p>
        <Button
          onClick={() => {
            window.open('./login', '_parent');
          }}
        >
          Fazer login
        </Button>
      </div>
    )}
    </>
  );
};

export default CnpjInput;
