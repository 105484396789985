import Axios from 'axios';
import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import { Col, Card, CardBody, Button, Spinner, Row, CardHeader, Input, Label } from "reactstrap";

class FSNumbersTotal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data:[],
          month:null,
          year:null
        }
      }

    //   componentDidMount(){
    //     document.getElementById('loadTotal').classList.add('active')
    //     Axios.get(process.env.REACT_APP_API + '/api/auth/dashboard/get-count',
    //     {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    //     .then(response => {
    //       this.setState({data: response.data})

    //       setTimeout(() => {
    //         document.getElementById('loadTotal').classList.remove('active')
    //       }, 300);
    //     })
    //   }

      gerarRelatorio(){
        if(this.state.month){
            document.getElementById('loadTotal').classList.add('active')
            const axiosOptions = {
                responseType: 'arraybuffer',
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization": "Bearer " + localStorage.getItem('token')
                }
              }
              const dada = {
                mes:this.state.month,
                ano:this.state.year ? this.state.year : 2024
              }
            Axios.post(process.env.REACT_APP_API + '/api/auth/relatorio',dada,
            axiosOptions)
            .then(response => {
                const blob = new Blob([response.data], {
                    type: 'application/octet-stream'
                })
                const filename = 'relatório.pdf'
                saveAs(blob, filename)
                setTimeout(() => {
                    document.getElementById('loadTotal').classList.remove('active')
                }, 300);
            })
        }
      }


      generateNewUserReport(){
        // new-users-report/export
        if(this.state.month){
            document.getElementById('loadTotal').classList.add('active')
            const axiosOptions = {
                responseType: 'arraybuffer',
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization": "Bearer " + localStorage.getItem('token')
                }
              }
              const dada = {
                mes:parseInt(this.state.month),
                ano:this.state.year ? parseInt(this.state.year) : 2024
              }
            Axios.post(process.env.REACT_APP_API + '/api/auth/new-users-report/export', dada,
            axiosOptions)
            .then(response => {
                const blob = new Blob([response.data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'new_users_report.csv')
                document.body.appendChild(link);
                link.click();

                link.parentNode.removeChild(link);

                document.getElementById('loadTotal').classList.remove('active')
            })
        }
      }

    render() {

        const {data} = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col md={12} style={{ height: "285px", position:'relative'  }}>
                        <Card style={{height:'100%'}}>
                            <CardBody>
                                {/* <div className="total-user">
                                    <div style={{width:'100%', borderRadius:'5px', backgroundColor:'#f4f4f4', height:'30px', display:'flex', alignItems:'center', padding:'5px 10px', marginBottom:'10px', fontSize:'16px', fontWeight:'600'}}>Total Corretores + Imobiliárias: <span style={{backgroundColor:'#3E5E3F', borderRadius:'20px', color:'#fff', marginLeft:'10px', padding:'0px 10px'}}>{data?.corretores_total + data?.imobiliarias_total}</span></div>
                                    <div style={{width:'100%', borderRadius:'5px', backgroundColor:'#f4f4f4', height:'30px', display:'flex', alignItems:'center', padding:'5px 10px', marginBottom:'10px', fontSize:'16px', fontWeight:'600'}}>Total Corretores Premium: <span style={{backgroundColor:'#3E5E3F', borderRadius:'20px', color:'#fff', marginLeft:'10px', padding:'0px 10px'}}>{data?.corretores_premium}</span></div>
                                    <div style={{width:'100%', borderRadius:'5px', backgroundColor:'#f4f4f4', height:'30px', display:'flex', alignItems:'center', padding:'5px 10px', marginBottom:'10px', fontSize:'16px', fontWeight:'600'}}>Total Imobiliárias Premium: <span style={{backgroundColor:'#3E5E3F', borderRadius:'20px', color:'#fff', marginLeft:'10px', padding:'0px 10px'}}>{data?.imobiliarias_premium}</span></div>
                                    <div style={{width:'100%', borderRadius:'5px', backgroundColor:'#f4f4f4', height:'30px', display:'flex', alignItems:'center', padding:'5px 10px', marginBottom:'10px', fontSize:'16px', fontWeight:'600'}}>Total de imóveis ativos: <span style={{backgroundColor:'#3E5E3F', borderRadius:'20px', color:'#fff', marginLeft:'10px', padding:'0px 10px'}}>{data?.imoveis_total}</span></div>
                                    <div style={{width:'100%', borderRadius:'5px', backgroundColor:'#f4f4f4', height:'30px', display:'flex', alignItems:'center', padding:'5px 10px', marginBottom:'10px', fontSize:'16px', fontWeight:'600'}}>Valor total de imóveis ativos: <span style={{backgroundColor:'#3E5E3F', borderRadius:'20px', color:'#fff', marginLeft:'10px', padding:'0px 10px'}}>{(data?.imoveis_valor / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                                </div> */}
                                <Row style={{gap:'20px 0'}}>
                                    <Col xs={12}>
                                        <h3>Baixar relatório</h3>
                                    </Col>
                                    <Col xs={7}>
                                        <Label>Ano de referência</Label>
                                        <Input
                                            type="number"
                                            placeholder="0000"
                                            value={this.state.year}
                                            onChange={(e) => this.setState({year: e.target.value})}
                                        ></Input>
                                    </Col>
                                    <Col xs={5}>
                                        <Label>Mês de referência</Label>
                                        <Input
                                            type="number"
                                            placeholder="00"
                                            value={this.state.month}
                                            onChange={(e) => this.setState({month: e.target.value})}
                                        ></Input>
                                    </Col>
                                    <Col xs={12} style={{gap:'10px', display:'flex'}}>
                                        {this.state.month ?
                                            <Button onClick={() => this.gerarRelatorio()}>Relatório geral</Button>
                                        :
                                            <Button disabled>Relatório geral</Button>
                                        }
                                        {this.state.month ?
                                            <Button onClick={() => this.generateNewUserReport()}>Novos usuários</Button>
                                        :
                                            <Button disabled>Novos usuários</Button>
                                        }
                                    </Col>
                                    <Col xs={12}>
                                        <Row style={{backgroundColor:'#cdcdcd', margin:'10px 0 0', borderRadius:'5px', alignItems:'center', padding:'7px 0'}}>
                                            <Col xs={1}><i className="ri-information-line" style={{fontSize:'20px'}}></i></Col>
                                            <Col xs={11}><p style={{fontSize:'12px', fontWeight:'600', lineHeight:'1'}}>O relatório geral trás número totais e do mês de referência, os dados começaram a ser coletados em março de 2023, com excessão dos downloads.</p></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                            <div id="loadTotal" className="load" style={{position:'absolute', zIndex:'99'}}>
                                <Spinner className="me-2" color="success" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default FSNumbersTotal;