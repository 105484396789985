import Axios from 'axios';
import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardImg, Row, CardTitle, CardText, Button, Badge } from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";
import './../../assets/scss/custom/components/_horizontalCard.scss'
import HorizontalCard from './HorizontalCard';

class Opportunity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            imoveis:[],
        }
    }

    componentDidMount(){
            Axios.get(process.env.REACT_APP_API + '/api/auth/opportunity',
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                this.setState({imoveis: response.data})
            })
    }

    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card>
                        <CardBody>
                            {/* <Dropdown className="float-end" isOpen={this.state.menu} toggle={() => this.setState({ menu: !this.state.menu })}>
                                <DropdownToggle tag="i" className="darrow-none card-drop" aria-expanded="false">
                                    <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">

                                    <DropdownItem href="">Sales Report</DropdownItem>

                                    <DropdownItem href="">Export Report</DropdownItem>

                                    <DropdownItem href="">Profit</DropdownItem>

                                    <DropdownItem href="">Action</DropdownItem>
                                </DropdownMenu>
                            </Dropdown> */}

                            <h4 className="card-title mb-4">Outlet</h4>

                            <SimpleBar style={{ maxHeight: "350px", minHeight: "350px", overflow:'hidden auto'}}>
                                {this.state.imoveis?.map((imovel, index) => (
                                    <HorizontalCard key={index} imovel={imovel}/>
                                ))}
                            </SimpleBar>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default Opportunity;