import React, { Component, useRef } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, TabPane, Form, TabContent, Progress, NavLink, NavItem, ModalHeader, ModalBody, Modal, Button, ModalFooter, CardText, CardTitle, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import ReactSpeedometer from "react-d3-speedometer"

import './../../assets/scss/custom/components/_editProperties.scss'
import './../../assets/scss/custom/components/_addProperty.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import { Draggable } from 'react-drag-reorder';
import CurrencyInput from '../../components/Inputs/CurrencyInput';
import Select from "react-select";
import Dropzone, { useDropzone } from 'react-dropzone';
import Flatpickr from "react-flatpickr"
import 'flatpickr/dist/l10n/pt';

import PrimeiroImovel from './../../assets/images/Icons/primeiroimovel.png'
import Triste from './../../assets/images/Icons/triste.png'
import VamosNessa from './../../assets/images/Icons/vamosnessa.png'
import Cafezinho from './../../assets/images/upload/cafezinho.png';
import Contrato from './../../assets/images/upload/contrato.png';
import Corretores from './../../assets/images/upload/corretores.png';
import Instante from './../../assets/images/upload/instante.png';
import Upload from './../../assets/images/upload/upload.png';
import Sucesso from './../../assets/images/upload/sucesso.png';
import { set } from 'lodash';
import PhotosOrder from '../editProperties/photosOrder';
import EnterprisePhotosOrder from '../editProperties/enterprisePhotosOrder';
import EnterprisePlansOrder from '../editProperties/enterprisePlansOrder';

require('moment/locale/pt.js');

const enterpriseId = window.location.search?.replace('?', '');

class EditEnterpriseFields extends Component {
  constructor(props) {
    super(props);
    this.validateRef = React.createRef();
    this.validate2Ref = React.createRef();
    this.validate3Ref = React.createRef();
    this.validate4Ref = React.createRef();
    this.flatpickrRef = React.createRef();
    this.state = {
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Novo empreendimento", link: "#" },
    ],
    activeTab: 1,
    enterprise:[],
    activeTabProgress: 1,
    progressValue: 10,
    newEnterpriseId: '',
    selectedEnterprise: [],
    selectedUf: null,
    selectedCity: null,
    objective:'Sell',
    selectOptionsEnterprise: [],
    selectOptionsUf: [],
    selectOptionsCity: [],
    selectedFiles: [],
    selectedPlans: [],
    selectedDocs: [],
    stateSelected: '',
    citySelected:'',
    statesAddress: [],
    cityAddress: [],
    logradouro:'',
    dragPhotos:'',
    dragPlans:'',
    bairro:'',
    comissao:'',
    matricula:'',
    title:'',
    area:'',
    descricao:'',
    cep:'',
    numero:'',
    complemento:'',
    pais:'Brasil',
    andares:'',
    photos:[],
    floors:'',
    types:'',
    units:'',
    previsao:'',
    andamento:'',
    skillsList:'',
    skillsArray:[],
    checkInvalidFeedback:null,
    acceptContract:false,
    isComponentMounted: false,
    update_success:false,
    update_error:false,
    modal_full_properties:false,
    verifyPremium: false,
    uploadPercentage: 0,
    uploading: false,
    dots: '.',
    status:null,
    observacao:'',
    videos: [],
    skillsEditList: [],
    photosOrder:null,
    plansOrder:null,
    editedVideos: [],
    docsEditList: [],
    }
    this.toggleTab.bind(this);
    this.toggleTabProgress.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.optionchange = this.optionchange.bind(this);
    this.handleSelectState = this.handleSelectState.bind(this);
    this.toggleFormsAutorization = this.toggleFormsAutorization.bind(this);
  }
  
  addVideoInput = () => {
    const newVideos = [...this.state.videos, ''];
    this.setState({ videos: newVideos });
  }

  updateVideoInput = (index, value) => {
    const updatedVideos = [...this.state.videos];
    updatedVideos[index] = value;
    this.setState({ videos: updatedVideos });
  }

  componentDidMount(){
    this.setState({ isComponentMounted: true });

    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + enterpriseId,
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          this.setState({
            enterprise: response.data,
            title: response.data.title,
            area: response.data.area,
            cep: response.data.cep,
            comissao: response.data.comission,
            complemento: response.data.complement,
            pais: response.data.country,
            descricao: response.data.description,
            bairro: response.data.district,
            floors: response.data.floors,
            numero: response.data.number,
            andamento: response.data.progress,
            matricula: response.data.register,
            slug: response.data.slug,
            logradouro: response.data.street,
            skillsEditList: response.data.details,
            docsEditList: response.data.docs,
            editedVideos: response.data.videos
          })

          this.setState({stateSelected: {
            value:response.data.state,
            label:response.data.state
          }})
          this.setState({citySelected: {
            value:response.data.city,
            label:response.data.city
          }});

          this.setState({ previsao: response.data.delivery?.replace('-', '/').replace('-', '/') }, () => {
            const flatpickr = this.flatpickrRef.current;
            if (flatpickr) {
              flatpickr.flatpickr.setDate(response.data.delivery?.replace('-', '/').replace('-', '/'), true, 'd-m-Y');
            }
          });
        })

          Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados',)
          .then(response => {
            const states = []
            for(var i = 0; response.data.length > i; i++){
              states.push({
                value: response.data[i].sigla,
                label: response.data[i].nome
              })
            }
            this.setState({statesAddress: states})
          })

    })
    .catch(response =>{
      localStorage.removeItem("history_url")
      setTimeout(() => {
        window.open("/login", '_parent')
      }, 300);
    })
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
        if (tab >= 1 && tab <= 7) {
            this.setState({
                activeTab: tab
            });
        }
    }
  }

  toggleTabProgress(tab) {
      if (this.state.activeTabProgress !== tab) {
          if (tab >= 1 && tab <= 6) {
              this.setState({
                  activeTabProgress: tab
              });

              if (tab === 1) { this.setState({ progressValue: 10 }) }
              if (tab === 2) { this.setState({ progressValue: 25 }) }
              if (tab === 3) { this.setState({ progressValue: 40 }) }
              if (tab === 4) { this.setState({ progressValue: 55 }) }
              if (tab === 5) { this.setState({ progressValue: 75 }) }
              if (tab === 6) { this.setState({ progressValue: 100 }) }
          }
      }
  }

  toggleFormsAutorization() {
    this.setState(prevState => ({
      openFormsAutorization: !prevState.openFormsAutorization
    }));
  }

  handleAcceptedDocs = files => {
    files.map(file => {
      let fileType;
      if (file.type === 'application/pdf') {
        fileType = 'pdf';
      } else {
        fileType = file.type;
      }
  
      return Object.assign(file, {
        fileType,
        formattedSize: this.formatBytes(file.size)
      });
    });
  
    this.setState({ selectedDocs: files });
  };
  
  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedFiles: files }, () => {
      this.setState({photosArray: this.state.selectedFiles.map((file, index) => ({
        file: file,
        position: index
      }))});
    });
    
    this.setState({dragPhotos: (
      <Draggable onPosChange={() => {
        this.setState({photosArray : []})
        setTimeout(() => {
          for(var i = 0; i < this.state.selectedFiles.length; i++){
            this.state.photosArray.push({
              file: this.state.selectedFiles.filter(image => image.name === document.querySelectorAll('.image-send')[i].alt)[0],
              position: i
            })
          }
        }, 500);
        }}>
      {this.state.selectedFiles.map((f, i) => 
          <Col key={i + "-file"} id={f.name} >
          <Card
            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
          >
            <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="120"
                      className="avatar-sm rounded bg-light image-send"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link
                      to="#"
                      className="text-muted fw-bold"
                    >
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                  <i className="ri-close-fill" style={{
                      position:'absolute',
                      top:'10px',
                      fontSize:'20px',
                      backgroundColor:'#da2c3e',
                      color:'#fff',
                      right:'10px',
                      width: '15px',
                      height: '25px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }} onClick={() => {
                      this.removeImage(i)
                    }}></i>
                </Row>
            </div>
          </Card>
          </Col>
      )}
      </Draggable>
    )})
  };

  handleAcceptedPlans = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedPlans: files }, () => {
      this.setState({plansArray: this.state.selectedPlans.map((file, index) => ({
        file: file,
        position: index
      }))});
    });
    
    this.setState({dragPlans: (
      <Draggable onPosChange={() => {
        this.setState({plansArray : []})
        setTimeout(() => {
          for(var i = 0; i < this.state.selectedPlans.length; i++){
            this.state.plansArray.push({
              file: this.state.selectedPlans.filter(image => image.name === document.querySelectorAll('.image-plan')[i].alt)[0],
              position: i
            })
          }
        }, 500);
        }}>
      {this.state.selectedPlans.map((f, i) => 
          <Col key={i + "-file"} id={f.name} >
          <Card
            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
          >
            <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="120"
                      className="avatar-sm rounded bg-light image-plan"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link
                      to="#"
                      className="text-muted fw-bold"
                    >
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                  <i className="ri-close-fill" style={{
                      position:'absolute',
                      top:'10px',
                      fontSize:'20px',
                      backgroundColor:'#da2c3e',
                      color:'#fff',
                      right:'10px',
                      width: '15px',
                      height: '25px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }} onClick={() => {
                      this.removePlans(i)
                    }}></i>
                </Row>
            </div>
          </Card>
          </Col>
      )}
      </Draggable>
    )})
  };


  removeImage(index) {
    const { selectedFiles } = this.state;
    selectedFiles.splice(index, 1);
    this.setState({ selectedFiles });
    this.setState({dragPhotos: null})
    setTimeout(() => {
      this.handleAcceptedFiles(this.state.selectedFiles);      
    }, 300);
  }

  removePlans(index) {
    const { selectedPlans } = this.state;
    selectedPlans.splice(index, 1);
    this.setState({ selectedPlans });
    this.setState({dragPhotos: null})
    setTimeout(() => {
      this.handleAcceptedPlans(this.state.selectedPlans);      
    }, 300);
  }
  
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };


  optionchange(event) {
		var count = event.target.value.length;
		if (count > 0) {
			this.setState({ disbadge: true });
		} else {
			this.setState({ disbadge: false });
		}
		if (count > 99) {
			this.setState({ advanceclass: "badgecountextra" });
		} else {
			this.setState({ advanceclass: "badgecount" });
		}
		this.setState({ optioncount: event.target.value.length });
	}

  handleSelectState = stateSelected => {
		this.setState({ stateSelected });
    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + stateSelected.value + '/municipios')
    .then(response => {
      const city = []
      for(var i = 0; response.data.length > i; i++){
        city.push({
          label: response.data[i].nome,
          value: response.data[i].nome
        })
      }
      this.setState({cityAddress: city})
    })
	};

  consultaCep = cepValue => {
    const cep = cepValue
      if (cep?.length !== 8) {
      this.setState({logradouro: ''});
      this.setState({bairro: ''});
      this.setState({stateSelected: ''})
      this.setState({citySelected: ''});
      }else{
        fetch('https://viacep.com.br/ws/' + cep + '/json/')
        .then((res) => res.json())
        .then((data) => {
            if (data.erro === true) {
              this.setState({logradouro: ''});
              this.setState({bairro: ''});
              this.setState({stateSelected: ''})
              this.setState({citySelected: ''});
            } else {
              this.setState({logradouro: data.logradouro});
              this.setState({bairro: data.bairro});
              this.setState({stateSelected: {
                value:data.uf,
                label:data.uf
              }})
              this.setState({citySelected: {
                value:data.localidade,
                label:data.localidade
              }});
            }
          })
      }  
  }


  createSlug(title) {
    let slug = title.toLowerCase().replace(/[^\w\s-]/g, '');
  
    slug = slug.replace(/\s+/g, '-');
  
    return slug;
  }

  handleFormData = (orders, update) => {
    const data = new FormData();
    for(let i = 0; i < update.length; i++){
      data.append('order[]', orders[i])
      data.append('id[]', update[i])
    }
    this.setState({ photosOrder: data });
  };

  handleFormDataPlans = (orders, update) => {
    const data = new FormData();
    for(let i = 0; i < update.length; i++){
      data.append('position[]', orders[i])
      data.append('id[]', update[i])
    }
    this.setState({ plansOrder: data });
  };

  handleVideoUrlChange = (index, newUrl) => {
    const updatedVideos = [...this.state.editedVideos];
    updatedVideos[index].url = newUrl;
    this.setState({ editedVideos: updatedVideos });
  };

  render() {

    const { dragPhotos, enterprise, dragPlans, statesAddress, cityAddress, citySelected, stateSelected, selectedEnterprise, selectOptionsEnterprise} = this.state;

    const locale = {
      weekdays: {
        shorthand: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        longhand: [
          'Domingo',
          'Segunda-feira',
          'Terça-feira',
          'Quarta-feira',
          'Quinta-feira',
          'Sexta-feira',
          'Sábado'
        ]
      },
      months: {
        shorthand: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        longhand: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro'
        ]
      },
      firstDayOfWeek: 0,
      rangeSeparator: ' até ',
      weekAbbreviation: 'Sem',
      scrollTitle: 'Rolagem para aumentar',
      toggleTitle: 'Clique para alternar',
      amPM: ['AM', 'PM'],
      yearAriaLabel: 'Ano',
      time_24hr: true
    };

    return (
        <div className="page-content" id="addImovel">
        <Container fluid={true}>
          <Breadcrumbs title="Novo empreendimento" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{marginBottom:'30px'}}>
                    <Col md={7}>
                      <h4 className="card-title mb-4">{enterprise?.title}</h4>
                    </Col>
                    <Col md={5} style={{textAlign:'right'}}>
                  <Button  onClick={() => {
                    document.getElementById('load').classList.add('active')

                      const data = new FormData();
                      data.append('title', this.state.title)
                      data.append('construction_id', localStorage.getItem('id'))
                      data.append('units_number', 0)
                      data.append('types_number', 0)
                      data.append('slug', this.createSlug(this.state.title))
                      data.append('register', this.state.matricula)
                      data.append('area', this.state.area)
                      data.append('description', this.state.descricao)
                      data.append('delivery', this.state.previsao)
                      data.append('progress', this.state.andamento)
                      data.append('floors', this.state.floors)
                      data.append('cep', this.state.cep)
                      data.append('street', this.state.logradouro)
                      data.append('number', this.state.numero)
                      data.append('district', this.state.bairro)
                      data.append('complement', this.state.complemento)
                      data.append('state', this.state.stateSelected.value)
                      data.append('city', this.state.citySelected.value)
                      data.append('country', 'Brasil')
                      data.append('comission', this.state.comissao)
                  
                      this.setState({skillsArray: this.state.skillsList.split('\n')})

                      let skillsRequest = Promise.resolve();
                      let photosRequest = Promise.resolve();
                      let videosRequest = Promise.resolve();
                      let plansRequest = Promise.resolve();
                      let docsRequest = Promise.resolve();
                      let updateVideosRequest = Promise.resolve();
                      let updatePhotosRequest = Promise.resolve();
                      let updatePlansRequest = Promise.resolve();

                      const allRequests = [];

                        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/'+ enterpriseId, data, {
                          headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                        })
                        .then(response => {

                           if(this.state.skillsList !== ''){
                            
                             const dataEnterpriseSkills = new FormData();
                  
                             for(var i = 0; this.state.skillsArray.length > i; i++){
                               dataEnterpriseSkills.append(`detail[${i}][detail]`, this.state.skillsArray[i])
                               dataEnterpriseSkills.append(`detail[${i}][property_enterprise_id]`, enterpriseId)
                             }
                  
                             skillsRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-details', dataEnterpriseSkills, {
                               headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                             })
                           }


                           if(this.state.photosArray !== undefined && this.state.photosArray !== []){
                             const dataEnterprisePhotos = new FormData();
                  
                             for(let i = 0; i < this.state.photosArray.length; i++){
                               dataEnterprisePhotos.append(`photo[${i}][file]`, this.state.photosArray[i].file)
                               dataEnterprisePhotos.append(`photo[${i}][position]`, this.state.photosArray[i].position)
                               dataEnterprisePhotos.append(`photo[${i}][property_enterprise_id]`, enterpriseId)
                             }
                  
                             photosRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-photos', dataEnterprisePhotos, {
                               headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                             })
                           }

                           if(this.state.selectedDocs !== undefined && this.state.selectedDocs.length > 0){
                             const dataEnterpriseDocs = new FormData();
                  
                             for(let i = 0; i < this.state.selectedDocs.length; i++){
                               dataEnterpriseDocs.append(`docs[${i}][file]`, this.state.selectedDocs[i])
                               dataEnterpriseDocs.append(`docs[${i}][property_enterprise_id]`, enterpriseId)
                             }
                  
                             docsRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-docs', dataEnterpriseDocs, {
                               headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                             })
                           }

                           if(this.state.plansArray !== undefined && this.state.plansArray !== undefined){
                             const dataEnterprisePlans = new FormData();
                  
                             for(let i = 0; i < this.state.plansArray.length; i++){
                               dataEnterprisePlans.append(`plan[${i}][file]`, this.state.plansArray[i].file)
                               dataEnterprisePlans.append(`plan[${i}][position]`, this.state.plansArray[i].position)
                               dataEnterprisePlans.append(`plan[${i}][property_enterprise_id]`, enterpriseId)
                             }
                  
                             plansRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-plans', dataEnterprisePlans, {
                               headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                             })
                           }

                           if(this.state.videos.length > 0){
                             const dataEnterpriseVideos = new FormData();

                             for(var i = 0; this.state.videos.length > i; i++){
                               dataEnterpriseVideos.append(`video[${i}][url]`, this.state.videos[i])
                               dataEnterpriseVideos.append(`video[${i}][position]`, i)
                               dataEnterpriseVideos.append(`video[${i}][property_enterprise_id]`, enterpriseId)
                             }

                             videosRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property_enterprises_videos', dataEnterpriseVideos, {
                               headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                             })
                         }

                        if(this.state.editedVideos.length > 0){
                          const dataUpdateVideos = new FormData();

                          for(var i = 0; this.state.editedVideos.length > i; i++){
                            dataUpdateVideos.append(`video[${i}][url]`, this.state.editedVideos[i].url)
                            dataUpdateVideos.append(`video[${i}][id]`, this.state.editedVideos[i].id)
                          }

                          updateVideosRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/update-videos/', dataUpdateVideos, {
                            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                          })
                      }

                      if(this.state.photosOrder){
                        updatePhotosRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/update-photos', this.state.photosOrder, {
                          headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                        })
                    }

                    if(this.state.plansOrder){
                      updatePlansRequest = Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/update-plans', this.state.plansOrder, {
                        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                      })
                  }

                        allRequests.push(updatePlansRequest, skillsRequest, updatePhotosRequest, updateVideosRequest, docsRequest, photosRequest, videosRequest, plansRequest);

                        return Promise.all(allRequests);

                        })
                        .then(responses => {
                          document.getElementById('load').classList.remove('active');
                          this.setState({ update_success: true });
                        })
                        .catch(error => {
                          document.getElementById('load').classList.remove('active');
                          this.setState({ update_error: true });
                        });
                  }}>Atualizar</Button>
                    </Col>
                  </Row>
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 1 })}
                            onClick={() => {
                              this.setState({ activeTabProgress: 1 })
                            }}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">Sobre o empreendimento</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 2 })}
                            onClick={() => {
                              this.setState({ activeTabProgress: 2 })
                            }}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">Características</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 3 })}
                            onClick={() => {
                                this.setState({ activeTabProgress: 3 })
                              }}
                            >
                            <span className="step-number">03</span>
                            <span className="step-title">Endereço</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 4 })}
                            onClick={() => {
                              this.setState({ activeTabProgress: 4 }) 
                            }}
                          >
                            <span className="step-number">04</span>
                            <span className="step-title">Fotos</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 5 })}
                            onClick={() => {
                              this.setState({ activeTabProgress: 5 })
                            }}
                          >
                            <span className="step-number">05</span>
                            <span className="step-title">Plantas</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink 
                            className={classnames({ active: this.state.activeTabProgress === 6 })}
                            onClick={() => {
                              this.setState({ activeTabProgress: 6 })
                            }}
                          >
                            <span className="step-number">06</span>
                            <span className="step-title">Arquivos e Vídeos</span>
                          </NavLink>
                        </NavItem>
                      </ul>

                      <TabContent activeTab={this.state.activeTabProgress} className="twitter-bs-wizard-tab-content">
                        <TabPane tabId={1}>
                        <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                              {localStorage.getItem('access') === 'A' &&
                                <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre a construtora</h5></Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                          <Label className="form-label">Construtora</Label>
                                          <Select
                                            value={selectedEnterprise}
                                            placeholder="Selecione"
                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                            onChange={(e) => this.setState({ selectedEnterprise: e })}
                                            options={selectOptionsEnterprise}
                                            classNamePrefix="select2-selection"
                                          />
                                        </Col>
                                    </Row>
                                  </Container>
                                  }
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o empreendimento</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                      <AvGroup>
                                        <Label htmlFor="tituloImovel" className="col-md-12 col-form-label">Nome do Empreendimento</Label>
                                        <AvField
                                          name="title"
                                          placeholder="Título do imóvel"
                                          type="text"
                                          maxLength="100"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.title}
                                          onChange={(e) => {
                                            this.optionchange(e)
                                            this.setState({title: e.target.value})
                                          }}
                                        />
                                        {this.state.disbadge ? (
                                          <span className={this.state.advanceclass}>
                                            <span className="badge badge-success">Você digitou {this.state.optioncount} de 100 caracteres permitidos.</span>
                                          </span>
                                        ) : null}
                                      </AvGroup>
                                      </Col>
                                      <Col md={3}>
                                      <AvGroup>
                                        <Label className="col-md-12 col-form-label">Comissão (%)</Label>
                                        <AvField
                                          name="comissao"
                                          placeholder="Comissão para venda (%)"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.comissao}
                                          onChange={(e) => {
                                            this.setState({comissao: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                      <Col md={3}>
                                      <AvGroup>
                                        <Label className="col-md-12 col-form-label">Incorporação</Label>
                                        <AvField
                                          name="matricula"
                                          placeholder="Nº de incorporação"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.matricula}
                                          onChange={(e) => {
                                            this.setState({matricula: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                      <Col md={3}>
                                      <AvGroup>
                                        <Label htmlFor="areaImovel" className="col-md-12 col-form-label">Área do terreno</Label>
                                        <AvField
                                          name="area"
                                          placeholder="Área em m²"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.area}
                                          onChange={(e) => {
                                            this.setState({area: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                      <Col md={3}>
                                      <AvGroup>
                                        <Label htmlFor="floorsImovel" className="col-md-12 col-form-label">Nº de andares</Label>
                                        <AvField
                                          name="floors"
                                          placeholder="Quantidade de andares"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.floors}
                                          onChange={(e) => {
                                            this.setState({floors: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre a obra</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Label htmlFor="previsao" className="col-md-12 col-form-label">Previsão de entrega</Label>
                                        <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder="Selecione um dia"
                                            defaultValue={this.state.previsao}
                                            options={{
                                            altInput: true,
                                            altFormat: "j, F, Y",
                                            dateFormat: "Y-m-d"
                                            }}
                                            ref={this.flatpickrRef} 
                                            locale= {locale}
                                            onChange={(e) => {
                                                this.setState({ previsao: moment(e.toString()).format('DD-MM-YYYY')})
                                            }}
                                        />
                                        </InputGroup>
                                      </Col>
                                      <Col md={4}>
                                      <AvGroup>
                                        <Label htmlFor="andamento" className="col-md-12 col-form-label">Andamento da obra (%)</Label>
                                        <AvField
                                          name="andamento"
                                          placeholder="em %"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={this.state.andamento}
                                          onChange={(e) => {
                                            this.setState({andamento: e.target.value})
                                          }}
                                        />
                                        </AvGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Descrição do empreendimento</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                      <AvGroup>
                                        <Label htmlFor="descricaoImovel" className="col-md-12 col-form-label">Descrição</Label>
                                        <AvField
                                          name="descricaoImovel"
                                          placeholder="Descreva seu imóvel com o máximo de informações e detalhes que acredite ser importantes."
                                          type="textarea"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          rows="10"
                                          validate={{ required: { value: true } }}
                                          value={this.state.descricao}
                                          onChange={(e) => this.setState({descricao: e.target.value})}
                                          id="descricaoImovel"
                                        />
                                        </AvGroup>
                                      </Col>
                                    </Row>
                                  </Container>
                              </AvForm>
                          </TabPane>
                          <TabPane tabId={2}>
                              <div>
                                <Form className="cardBody">
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Características do empreendimento</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={6}>
                                        <Label className="col-md-12 col-form-label">Inserir características</Label>
                                        <Input
                                            type="textarea"
                                            className="caracteristicas"
                                            rows="8"
                                            defaultValue={this.state.skillsList}
                                            onChange={(e) => this.setState({skillsList: e.target.value})}
                                        />
                                        <Card style={{padding: '15px', backgroundColor:'#f4f4f4', marginTop: '20px'}}>
                                          <Row>
                                            <Col className="col-auto">
                                              <i className="ri-error-warning-line" style={{fontSize:'30px'}}></i>
                                            </Col>
                                            <Col>
                                              <p>Adicione uma característica por linha.</p><p>Utilize a tecla Enter para trocar de linha.</p>
                                            </Col>
                                          </Row>
                                        </Card>
                                      </Col>
                                      <Col md={6}>
                                        <Label className="col-md-12 col-form-label">Características</Label>
                                        {this.state.skillsEditList?.length > 0 &&
                                          this.state.skillsEditList.map((skill, index) => (
                                            <li style={{
                                              backgroundColor:'#cdcdcd',
                                              display:'inline-flex',
                                              alignItems:'center',
                                              gap:'10px',
                                              borderRadius:'15px',
                                              padding:'0 15px',
                                              cursor:'pointer',
                                              margin:'5px'
                                            }} key={index}
                                            onClick={() => {
                                              Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-detail/delete/' + skill.id, {},
                                              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                              const updatedSkills = this.state.skillsEditList.filter(skills => skills.id !== skill.id);
                                              this.setState({ skillsEditList: updatedSkills });
                                            }}
                                            >{skill.detail}<span><i className="ri-close-line" style={{fontSize:'18px'}}></i></span></li>
                                          ))}
                                      </Col>
                                    </Row>
                                  </Container>
                                </Form>
                              </div>
                          </TabPane>
                          <TabPane tabId={3}>
                          <div className="row justify-content-center">
                              <AvForm className="cardBody needs-validation"  ref={this.validate2Ref}>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Endereço</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Label htmlFor="cep" className="col-md-12 col-form-label">Cep</Label>
                                        <AvField
                                          name="cep"
                                          placeholder="Cep"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.cep}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.consultaCep(e.target.value.replace('-', '').replace('.', ''))
                                            this.setState({cep: e.target.value})
                                          }}
                                          id="cep"
                                        />
                                      </Col>
                                      <Col md={6}>
                                        <Label htmlFor="rua" className="col-md-12 col-form-label">Rua</Label>
                                        <AvField
                                          name="rua"
                                          placeholder="Rua, Avenida..."
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.logradouro}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({logradouro: e.target.value})
                                          }}
                                          id="rua"
                                        />
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor="numero" className="col-md-12 col-form-label">Número</Label>
                                        <AvField
                                          name="numero"
                                          placeholder="Número"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.numero}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({numero: e.target.value})
                                          }}
                                          id="numero"
                                        />
                                      </Col>
                                      <Col md={6}>
                                        <Label htmlFor="bairro" className="col-md-12 col-form-label">Bairro</Label>
                                        <AvField
                                          name="bairro"
                                          placeholder="Bairro"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.bairro}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({bairro: e.target.value})
                                          }}
                                          id="bairro"
                                        />
                                      </Col>
                                      <Col md={6}>
                                        <Label htmlFor="complemento" className="col-md-12 col-form-label">Complemento</Label>
                                          <Input type="text" placeholder="Complemento" id="complemento" value={this.state.complemento} onChange={(e) => this.setState({complemento: e.target.value})} />
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="estado" className="col-md-12 col-form-label">Estado</Label>
                                        <Select
                                          value={stateSelected}
                                          placeholder="Selecione"
                                          noOptionsMessage={(inputValue) => `Sem resultados`}
                                          onChange={this.handleSelectState}
                                          options={statesAddress}
                                          classNamePrefix="select2-selection"
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="cidade" className="col-md-12 col-form-label">Cidade</Label>
                                        <Select
                                          value={citySelected}
                                          placeholder="Selecione"
                                          noOptionsMessage={(inputValue) => `Sem resultados`}
                                          onChange={(e) => {
                                            this.setState({ citySelected: e})
                                          }}
                                          options={cityAddress}
                                          classNamePrefix="select2-selection"
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="pais" className="col-md-12 col-form-label">País</Label>
                                        <AvField
                                          name="pais"
                                          placeholder="País"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.pais}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({pais: e.target.value})
                                          }}
                                          id="pais"
                                        />
                                      </Col>
                                    </Row>
                                  </Container>
                              </AvForm>
                              </div>
                          </TabPane>
                          <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <div className=" cardBody">
                                <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                  <Row style={{margin:'10px 0'}}>
                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Fotos do empreendimento</h5></Col>
                                  </Row>
                                  <Form>
                                    <Dropzone
                                      accept={{
                                        "image/*": [".png", ".jpeg", ".jpg", '.heic'],
                                      }}
                                      onDrop={acceptedFiles =>
                                        this.handleAcceptedFiles(acceptedFiles)
                                      }
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>Para adicionar fotos arraste ou clique aqui.</h4>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    {dragPhotos &&
                                      <>
                                        <div style={{margin:'20px 0'}}>
                                          <p style={{fontSize:'20px', fontWeight:'600'}}>Ordene suas fotos. As 3 primeiras recebem maior destaque.</p>
                                        </div>
                                      </>
                                    }
                                    <div
                                      className="dropzone-previews mt-3"
                                      id="file-previews"
                                    >
                                      {dragPhotos}
                                    </div>
                                    <EnterprisePhotosOrder onFormDataChange={this.handleFormData} imoveis={enterprise?.photos}/>
                                  </Form>
                                </Container>
                                </div>
                              </div>
                          </TabPane>
                          <TabPane tabId={5}>
                            <div className="row justify-content-center">
                            <div className=" cardBody">
                                <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                  <Row style={{margin:'10px 0'}}>
                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Plantas do empreendimento</h5></Col>
                                  </Row>
                                  <Form>
                                    <Dropzone
                                      accept={{
                                        "image/*": [".png", ".jpeg", ".jpg", '.heic'],
                                      }}
                                      onDrop={acceptedPlans =>
                                        this.handleAcceptedPlans(acceptedPlans)
                                      }
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>Para adicionar plantas arraste ou clique aqui.</h4>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    {dragPlans &&
                                      <>
                                        <div style={{margin:'20px 0'}}>
                                          <p style={{fontSize:'20px', fontWeight:'600'}}>Ordene suas plantas. As 3 primeiras recebem maior destaque.</p>
                                        </div>
                                      </>
                                    }
                                    <div
                                      className="dropzone-previews mt-3"
                                      id="file-previews"
                                    >
                                      {dragPlans}
                                    </div>
                                  </Form>
                                  <EnterprisePlansOrder onFormDataChange={this.handleFormDataPlans} imoveis={enterprise?.plans}/>
                                </Container>
                                </div>
                              </div>
                          </TabPane>
                          <TabPane tabId={6}>
                              <div className="row justify-content-center">
                              <div className=" cardBody">
                                <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Arquivos adicionais</h5></Col>
                                    </Row>
                                    <Row style={{marginBottom: '20px'}}>
                                      <Dropzone
                                        onDrop={acceptedFiles =>
                                          this.handleAcceptedDocs(acceptedFiles)
                                        }
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                              </div>
                                              <h4>Para adicionar arquivos arraste ou clique aqui.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div
                                          className="dropzone-previews mt-3"
                                          id="docs-previews"
                                        >
                                        {this.state.selectedDocs.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    {f.fileType === 'pdf' ? (
                                                      <i className="ri-file-search-fill" style={{fontSize:'40px', color:'#333'}}></i>
                                                    ) : (
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="40"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                    )}
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted fw-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{f.formattedSize}</strong>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                        </div>
                                    </Row>
                                    <Row>
                                      <Col xs={12}>
                                      {this.state.docsEditList?.length > 0 && <Label className="col-md-12 col-form-label">Documentos</Label>}
                                        {this.state.docsEditList?.length > 0 &&
                                          this.state.docsEditList.map((doc, index) => (
                                            <li style={{
                                              backgroundColor:'#cdcdcd',
                                              display:'inline-flex',
                                              alignItems:'center',
                                              gap:'10px',
                                              borderRadius:'15px',
                                              padding:'0 15px',
                                              cursor:'pointer',
                                              margin:'5px'
                                            }} key={index}
                                            onClick={() => {
                                              Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-doc/delete/' + doc.id, {},
                                              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                              const updatedDocs = this.state.docsEditList.filter(docs => docs.id !== doc.id);
                                              this.setState({ docsEditList: updatedDocs });
                                            }}
                                            >{doc.filename}<span><i className="ri-close-line" style={{fontSize:'18px'}}></i></span></li>
                                          ))}
                                          </Col>
                                    </Row>
                                  </Container>
                                <Container>
                                  <Row style={{margin:'10px 0'}}>
                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Vídeos do empreendimento</h5></Col>
                                  </Row>
                                  <Row>
                                    {/* <Col md={12}>
                                      <Input type="text" placeholder="Link vídeo" value={this.state.video} onChange={(e) => this.setState({video: e.target.value})}/>
                                    </Col> */}
                                    <Col xs={12}>
                                      <Row>
                                      {this.state.editedVideos?.map((video, index) => (
                                        <Col md={4} key={index} style={{margin:'8px 0'}}>
                                          <Input
                                            type="text"
                                            value={video.url}
                                            onChange={(e) => this.handleVideoUrlChange(index, e.target.value)}
                                          ></Input>
                                        </Col>
                                      ))}
                                      </Row>
                                    </Col>
                                    <Col xs={12}>
                                      <Button type="button" onClick={this.addVideoInput}>
                                        Adicionar vídeo
                                      </Button>
                                    </Col>
                                    {this.state.videos.map((video, index) => (
                                      <Col md={6} key={index} style={{marginTop:'20px'}}>
                                        <Input
                                          type="text"
                                          placeholder="Link vídeo"
                                          value={video}
                                          onChange={(e) => this.updateVideoInput(index, e.target.value)}
                                        />
                                      </Col>
                                    ))}
                                  </Row>
                                </Container>
                                </div>
                              </div>
                          </TabPane>
                      </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

            {/* Sucesso na atualização */}

            {this.state.update_success ? (
              <SweetAlert
                title="Empreendimento atualizado com sucesso!"
                timeout={4000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => {
                  this.setState({ update_error: false })
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000);
                }}
              ></SweetAlert>
            ) : null}
            {
            this.state.uploading ? (
              <div id="load" className="load" style={{display: 'flex', flexDirection: 'column'}}>
                <div className='container-background'>
                  <div className='container-uploading'>
                    <div className='container-phrase-uploading'>
                      {(() => {
                          if (this.state.uploadPercentage < 20) {
                            return(
                              <div className='phrase-text'>
                                  <img className='upload-image' src={Upload} alt='ícone fazendo um upload' /> 
                                  <div className='phrase-details'>
                                    <p>Fazendo upload do material</p><span className='dots'>{this.state.dots}</span>
                                  </div>
                              </div>
                            )
                        } else if (this.state.uploadPercentage < 40) {
                            return(
                              <div className='phrase-text'> 
                                <img className='upload-image' src={Corretores} alt='ícone corretores' /> 
                                <div className='phrase-details'>
                                  <p>Disponibilizando seu imóvel para milhares de corretores</p><span className='dots'>{this.state.dots}</span>
                                </div>
                              </div>
                            ) 
                        } else if (this.state.uploadPercentage < 60) {
                          return(
                            <div className='phrase-text'>
                              <img className='upload-image' src={Contrato} alt='ícone contrato parceria' />
                              <div className='phrase-details'> 
                                <p>Adicionando seus dados ao contrato de parceria</p><span className='dots'>{this.state.dots}</span>
                              </div>
                            </div>
                          )
                        } else if (this.state.uploadPercentage < 80) {
                          return(
                            <div className='phrase-text'>
                              <img className='upload-image' src={Cafezinho} alt='ícone passando cafezinho' /> 
                              <div className='phrase-details'> 
                                <p>Passando um cafezinho</p><span className='dots'>{this.state.dots}</span>
                              </div>
                            </div>
                          ) 
                        } else if (this.state.uploadPercentage < 100) {
                          return(
                            <div className='phrase-text'>
                                <img className='upload-image' src={Instante} alt='Só um instante' />
                                <div className='phrase-details'> 
                                  <p>Só mais um instante</p><span className='dots'>{this.state.dots}</span>
                                </div>
                            </div>
                          ) 
                        } else {
                          return(
                            <div className='phrase-text'>
                              <img className='upload-image' src={Sucesso} alt='ícone de cadastro com successo' />
                              <div className='phrase-details'> 
                                <p>Sucesso! Cadastro de imóvel concluído</p>
                              </div>
                            </div>
                          ) 
                        }
                      })()}
                    </div>
                    <Spinner className="me-2" color="success" style={{ width: '60px',height: '60px', fontSize: '1.3rem', marginTop: '4rem' }}/>
                    <p className='quantify-percentage'>{this.state.uploadPercentage}%</p>
                  </div>
                </div>
              </div>
            ) : (
            <div id="load" className="load">
              <Spinner className="me-2" color="success" />
            </div>
            )
          }

          {/* Falha na atualização */}

          {this.state.update_error ? (
            <SweetAlert
              title="A atualização falhou, revise os campos e tente novamente."
              timeout={4000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ update_error: false })}
            ></SweetAlert>
            ) : null}
      </div>
    );
  }
}

export default EditEnterpriseFields;