import styles from '../../styles/mobile/ImobileThirdMobile.module.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Icon from '@mdi/react';
import { mdiPencilRulerOutline, mdiCarBack, mdiBedKingOutline, mdiPencilRuler, mdiShareAll } from '@mdi/js';
import FooterThind from '../desktop/FooterThird';
import { useState, useEffect } from 'react';

const ImobileThirdMobile = () => {
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});

    useEffect(() => {
    if(localStorage.getItem('home_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('home_button_color')))
    }
    if(localStorage.getItem('home_button_text_color')){ 
      setColorTextButton(JSON.parse(localStorage.getItem('home_button_text_color')))
    }
  }, [])

  return(
    <section>
      <div>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className={styles.bg_item}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.bg_item}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.bg_item}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.bg_item}></div>
          </SwiperSlide>
        </Swiper>
      </div>
      
      <div>
        <div className={styles.bg_mobile}>
          <div style={{border: '1px solid #dadada'}}>
            <div className={styles.mobile_content}>
              <p className={styles.name_mobile}>Casa de alto padrão no condomínio Caledônia em Camboriú</p>

              <div className={styles.container_icons_mobile}>
                <span className={styles.icons_imobile}><Icon path={mdiBedKingOutline} size={.8} /> 3 suítes</span>
                <span className={styles.icons_imobile}><Icon path={mdiCarBack} size={.8} /> 2 vagas</span>
                <span className={styles.icons_imobile}><Icon path={mdiPencilRuler} size={.8} />127m</span>
              </div>

              <p className={styles.location_mobile}>Balneário Camboriú</p>

              <div>
                <p className={styles.price_mobile}>R$<span>4.000.000,00</span></p>
                <p className={styles.others}>Condomínio: R$1.100,00</p>
                <p className={styles.others}>IPTU: R$2.000,00</p>
              </div>

              <div className={styles.container_buttons_mobile}>
                <button 
                style={{
                  backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                  color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                }}
                >Quero mais informações</button>
                <button className={styles.share} 
                style={{
                  backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                  color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                }}>Compartilhar <Icon path={mdiShareAll} size={.8} /></button>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.bg_mobile}>
          <div style={{border: '1px solid #dadada', padding: '1rem'}}>
            <p className={styles.text_mobile}>Sobre o Imóvel</p>
            <p className={styles.text_mobile_about}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis beatae natus labore laboriosam, volupta
              tes maxime iure facere quidem cupiditate vitae alias magni perferendis at consequatur. Deleniti itaque provident magnam ad!
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis beatae natus labore laboriosam, volupta
              tes maxime iure facere quidem cupiditate vitae alias magni perferendis at consequatur. Deleniti itaque provident magnam ad!
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis beatae natus labore laboriosam, volupta
              tes maxime iure facere quidem cupiditate vitae alias magni perferendis at consequatur. Deleniti itaque provident magnam ad!
            </p>
          </div>
        </div>
      </div>


      <div className={styles.container_carousel}>
        <Swiper
          slidesPerView={1}
          spaceBetween={60}
          loop={true}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className={styles.container_imobile}>
              <div className={styles.bg_teste}></div>
              <div className={styles.container_config}>
                <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                <p className={styles.price}>R$<span>4.000.000,00</span></p>

                <div className={styles.container_config_imobile}>
                  <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                  <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                  <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                </div>
                <div className={styles.details}></div>
                <div className={styles.container_view}>
                  <p>Balneário Camboriú</p> 
                  <p className={styles.view} 
                  style={{
                    backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                    color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                  }}
                  >Ver mais</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container_imobile}>
              <div className={styles.bg_teste}></div>
              <div className={styles.container_config}>
                <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                <p className={styles.price}>R$<span>4.000.000,00</span></p>

                <div className={styles.container_config_imobile}>
                  <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                  <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                  <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                </div>
                <div className={styles.details}></div>
                <div className={styles.container_view}>
                  <p>Balneário Camboriú</p> 
                  <p className={styles.view}
                  style={{
                    backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                    color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                  }}
                  >Ver mais</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container_imobile}>
              <div className={styles.bg_teste}></div>
              <div className={styles.container_config}>
                <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                <p className={styles.price}>R$<span>4.000.000,00</span></p>

                <div className={styles.container_config_imobile}>
                  <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                  <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                  <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                </div>
                <div className={styles.details}></div>
                <div className={styles.container_view}>
                  <p>Balneário Camboriú</p> 
                  <p className={styles.view}
                  style={{
                    backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                    color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                  }}
                  >Ver mais</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container_imobile}>
              <div className={styles.bg_teste}></div>
              <div className={styles.container_config}>
                <p className={styles.name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>

                <p className={styles.price}>R$<span>4.000.000,00</span></p>

                <div className={styles.container_config_imobile}>
                  <span><Icon path={mdiBedKingOutline} size={.8} color={'#000'}/> 3 suítes</span>
                  <span><Icon path={mdiCarBack} size={.8} color={'#000'}/>3 vagas</span>
                  <span><Icon path={mdiPencilRulerOutline} size={.8} color={'#000'}/>152m</span>
                </div>
                <div className={styles.details}></div>
                <div className={styles.container_view}>
                  <p>Balneário Camboriú</p> 
                  <p className={styles.view} 
                    style={{
                      backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                      color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                    }}
                  >Ver mais</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}

export default ImobileThirdMobile;