import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
import 'swiper/css';
import "swiper/css/navigation";
import styles from '../styles/HighlightsMobile.module.css';
import { Navigation } from "swiper";
import Icon from '@mdi/react';
import { mdiBedKingOutline, mdiCar, mdiRulerSquare, mdiMapMarker } from '@mdi/js';

export default function HighlightsMobile({data}) {
  return (
    <section className={styles.teste}>
      <h1 className={styles.title}>Destaques</h1>

      <Swiper navigation={true} modules={[Navigation]} loop={true} className="mySwiper">
        <SwiperSlide>
          <div>
            <div className={styles.bg}></div>
            <div className={styles.container_immobile}>
              <div className={styles.title_immobile}>
                <p className={styles.immobile}>Imóvel Exemplo</p>
              </div>
              <div className={styles.config_immobile}>
                <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
              </div>
              <div className={styles.container_price}>
                  <p>R$4.000.000,00</p>
              </div>
              <div className={styles.view}>
                <div className={styles.container_location}>
                  <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                  <p className={styles.location}> Localização</p>
                </div>
                <p className={styles.view_immobile}>Ver imóvel</p> 
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className={styles.bg}></div>
            <div className={styles.container_immobile}>
              <div className={styles.title_immobile}>
                <p className={styles.immobile}>Imóvel Exemplo</p>
              </div>
              <div className={styles.config_immobile}>
                <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
              </div>
              <div className={styles.container_price}>
                  <p>R$4.000.000,00</p>
              </div>
              <div className={styles.view}>
                <div className={styles.container_location}>
                  <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                  <p className={styles.location}> Localização</p>
                </div>
                <p className={styles.view_immobile}>Ver imóvel</p> 
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className={styles.bg}></div>
            <div className={styles.container_immobile}>
              <div className={styles.title_immobile}>
                <p className={styles.immobile}>Imóvel Exemplo</p>
              </div>
              <div className={styles.config_immobile}>
                <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
              </div>
              <div className={styles.container_price}>
                  <p>R$4.000.000,00</p>
              </div>
              <div className={styles.view}>
                <div className={styles.container_location}>
                  <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                  <p className={styles.location}> Localização</p>
                </div>
                <p className={styles.view_immobile}>Ver imóvel</p> 
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className={styles.container_button}>
        <button>Ver todos os imóveis</button>
      </div>
    </section>
  );
}