import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register, { NewRegister } from "../pages/Authentication/Register";
import RegisterConstruction from "../pages/Authentication/RegisterConstruction";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/dashboard";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

// Pages Imóveis
import CatalogProperties from "../pages/CatalogProperties/CatalogProperties";
import CatalogPropertiesResult from "../pages/CatalogProperties/CatalogPropertiesResult";
import AddProperty from "../pages/AddProperty/Step1";
import editProperties from "../pages/editProperties/editProperties";
import editPropertiesFields from "../pages/editProperties/editPropertiesFields";
import SingleProperty from "../pages/SingleProperty/SingleProperty";

// Pages Component
import Chat from "../pages/Chat/Chat";

//Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/jquery-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";

//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

//Kanban Board
import KanbanBoard from "../pages/KanbanBoard/index";
import PropertyIntegration from '../pages/PropertyIntegration/PropertyIntegration';
import PropertyWishlist from '../pages/PropertyWishlist/PropertyWishlist';
import PartnershipList from '../pages/PartnershipList/PartnershipList';
import SinglePropertyPublic from '../pages/SingleProperty/SinglePropertyPublic';
import addProposal from '../pages/addProposal/addProposal';
import listProposal from '../pages/listProposal/listProposal';
import singleProposal from '../pages/singleProposal/singleProposal';
import EditUser from '../pages/EditUser/EditUser';
import policyUse from '../pages/Policy/policyUse';
import HomeIndex from '../pages/Home/index';
import RegisterThankYou from '../pages/Authentication/RegisterThankYou';
import CreatePassword from '../pages/Authentication/CreatePassword';
import editPropertiesAdm from '../pages/editPropertiesAdm/editPropertiesAdm';
import editUsersAdm from '../pages/editUsersAdm/editUsersAdm';
import listProposalAdm from '../pages/listProposalAdm/listProposalAdm';
import PartnershipListAdm from '../pages/PartnershipListAdm/PartnershipListAdm';
import SeuSiteFS from '../siteFS';
import editOutletAdm from '../pages/editOutletAdm/editOutletAdm';
import editDeleteAdm from '../pages/editDeleteAdm/editDeleteAdm';
import AnalyticsExport from '../pages/AnalyticsExport/AnalyticsExport';

import CheckoutMensagem from '../pages/Ecommerce/CheckoutMensagem';
import Construtor from "../siteFS/construtor";
import NewProperties from '../pages/Marketing/NovosImoveis/NewProperties';
import HomeCheckout from "../pages/Ecommerce/checkout/index";
import AddEnterprise from "../pages/AddEnterprise/Step1";
import AddUnits from "../pages/AddUnits/Step1";
import editEnterprises from "../pages/editEnterprises/editEnterprises";
import Units from "../pages/Units/Step1";
import EditEnterpriseFields from "../pages/EditEnterpriseFields/editEnterpriseFields";
// import EditUnits from "../pages/editUnits/editUnits";
// import EditUnitsSelected from "../pages/editUnits/editUnitsSelected";
// import EditUnitsFields from "../pages/EditUnitsFields/EditUnitsFields";
import AddEnterpriveV2 from "../pages/AddEnterprise/Step1v2";
import EnterpriseProfile from "../pages/AddEnterprise/EnterpriseProfile";
import EnterpriseAddress from "../pages/AddEnterprise/Address";
import EnterpriseInformation from "../pages/AddEnterprise/Information";
import EnterprisePayment from "../pages/AddEnterprise/Payment";
import EnterpriseBuilding from "../pages/AddEnterprise/Building";
import EnterpriseSkills from "../pages/AddEnterprise/Skills";
import EnterprisePhotos from "../pages/AddEnterprise/Photos";
import EnterprisePlans from "../pages/AddEnterprise/Plans";
import EnterpriseDocs from "../pages/AddEnterprise/Docs";
import EnterpriseVideos from "../pages/AddEnterprise/Videos";
import TypeProfile from "../pages/AddEnterprise/TypeProfile";
import TypeInformation from "../pages/AddEnterprise/TypeInformation";
import TypeSkills from "../pages/AddEnterprise/TypeSkills";
import TypePhotos from "../pages/AddEnterprise/TypePhotos";
import TypePlans from "../pages/AddEnterprise/TypePlans";
import TypeVideos from "../pages/AddEnterprise/TypeVideos";
import SingleEnterprise from "../pages/SingleEnterprise/SingleEnterprise";
import SingleUnits from "../pages/SingleUnits/SingleUnits";
import CatalogEnterprisesResult from "../pages/CatalogEnterprises/CatalogEnterprisesResult";
import CatalogConstructionResult from "../pages/CatalogEnterprises/CatalogConstructionResult";
import SingleUnitPublic from "../pages/SingleUnits/SingleUnitPublic";
import EditUnits from "../pages/AddEnterprise/EditUnits";
import EnterprisesAdminList from "../pages/AddEnterprise/enterprisesAdminList";
import CatalogYourProperties from "../pages/CatalogProperties/CatalogYourProperties";
import BrokersStaff from "../pages/brokerStaff";
import RealBrokerProfile from "../pages/brokerStaff/profile";
import CatalogYourEnterprises from "../pages/CatalogProperties/CatalogYourEnterprises";
import UsersOrigin from "../pages/usersOrigin/usersOrigin";
import { UpdateDocs } from '../pages/Authentication/UpdateDocs';
import PromoPage from '../pages/promoPage';
import { GoogleLogin } from '../pages/Authentication/GoogleLogin';
import SinglePropertyMeme from '../pages/SingleProperty/SinglePropertyMeme';
import { NewAddProperty } from '../pages/AddProperty/addProperty';
import CompleteRegister from '../pages/Authentication/CompleteRegister';
import AdmProducts from '../pages/admProducts';
import { RegisterStep2 } from '../pages/Authentication/Register/step2';
import { RegisterStep3 } from '../pages/Authentication/Register/step3';
import { ThankYou } from '../pages/Authentication/Register/thank-you';
import { RegisterStep4 } from '../pages/Authentication/Register/step4';
import { ThankYouConstruction } from '../pages/Authentication/Register/thank-you-construction';
import { AdmIndicators } from '../pages/admIndicators';
import { NewCheckout } from '../pages/Ecommerce/newCheckout';
import { CheckoutCart } from '../pages/Ecommerce/Cart';
import CompletePayment from '../pages/Ecommerce/completePayment';
import ConstructionIndication from '../pages/constructionIndication';
import { AdmOrders } from '../pages/admOrders';
import { ConstructionUsers } from '../pages/ConstructionUsers/ConstructionUsers';
import ContractPlan from "../pages/ContractPlan/contractPlan";
import AdmCoupons from "../pages/admCoupons";


const authProtectedRoutes = [

	//Kanban Board
	// { path: "/kanban-board", component: KanbanBoard },

	// Tables
	// { path: "/basic-tables", component: BasicTables },
	// { path: "/datatable-table", component: DatatableTables },
	// { path: "/responsive-table", component: ResponsiveTables },
	// { path: "/editable-table", component: EditableTables },

	// Ui
	// { path: "/ui-alerts", component: UiAlert },
	// { path: "/ui-buttons", component: UiButtons },
	// { path: "/ui-cards", component: UiCards },
	// { path: "/ui-carousel", component: UiCarousel },
	// { path: "/ui-dropdowns", component: UiDropdown },
	// { path: "/ui-general", component: UiGeneral },
	// { path: "/ui-grid", component: UiGrid },
	// { path: "/ui-images", component: UiImages },
	// { path: "/ui-lightbox", component: UiLightbox },
	// { path: "/ui-modals", component: UiModal },
	// { path: "/ui-progressbars", component: UiProgressbar },
	// { path: "/ui-sweet-alert", component: UiSweetAlert },
	// { path: "/ui-tabs-accordions", component: UiTabsAccordions },
	// { path: "/ui-typography", component: UiTypography },
	// { path: "/ui-video", component: UiVideo },
	// { path: "/ui-session-timeout", component: UiSessionTimeout },
	// { path: "/ui-rating", component: UiRating },
	// { path: "/ui-rangeslider", component: UiRangeSlider },
	// { path: "/ui-notifications", component: UiNotifications },
	// { path: "/ui-roundslider", component: UIRoundSlider },

	// Forms
	// { path: "/form-elements", component: FormElements },
	// { path: "/form-advanced", component: FormAdvanced },
	// { path: "/form-editors", component: FormEditors },
	// { path: "/form-mask", component: FormMask },
	// { path: "/form-file-upload", component: FormUpload },
	// { path: "/form-wizard", component: FormWizard },
	// { path: "/form-validation", component: FormValidations },
	// { path: "/form-xeditable", component: FormXeditable },

	//Utility
	// { path: "/starter", component: StarterPage },
	// { path: "/timeline", component: Timeline },
	// { path: "/faqs", component: FAQs },
	// { path: "/pricing", component: Pricing },

	//Icons
	// { path: "/icons-remix", component: RemixIcons },
	// { path: "/material-design", component: MaterialDesign },
	// { path: "/dripicons", component: DripiIcons },
	// { path: "/font-awesome-5", component: FontAwesome },

	// Maps
	// { path: "/google-maps", component: MapsGoogle },
	// { path: "/vector-maps", component: MapsVector },

	//Charts
	// { path: "/apex-charts", component: ChartApex },
	// { path: "/chartjs", component: ChartjsChart },
	// { path: "/charts-sparkline", component: SparklineChart },
	// { path: "/charts-knob", component: ChartsKnob },

	//Email
	// { path: "/email-inbox", component: EmailInbox },
	// { path: "/email-read", component: EmailRead },

	//Ecommerce
	
	// { path: "/ecommerce-products", component: EcommerceProducts },
	// { path: "/ecommerce-product-detail/:id", component: EcommerceProductDetail },
	// { path: "/ecommerce-orders", component: EcommerceOrders },
	// { path: "/ecommerce-customers", component: EcommerceCustomers },
	// { path: "/ecommerce-cart", component: EcommerceCart },
	// { path: "/ecommerce-checkout", component: EcommerceCheckout },
	// { path: "/ecommerce-shops", component: EcommerceShops },
	// { path: "/ecommerce-add-product", component: EcommerceAddProduct },  

	//chat
	// { path: "/chat", component: Chat },

	//calendar
	// { path: "/calendar", component: Calendar },
	{ path: "/agenda", component: Calendar },

	{ path: "/dashboard", component: Dashboard },

	//catálogo de imóveis
	// { path: "/imoveis", component: CatalogProperties },
	{ path: "/relatorios", component: AnalyticsExport },
	{ path: "/imoveis", component: CatalogPropertiesResult },
	{ path: "/seus-imoveis", component: CatalogYourProperties },
	{ path: "/seus-empreendimentos", component: CatalogYourEnterprises },

	{ path: "/empreendimentos", component: CatalogEnterprisesResult },

	// { path: "/novo-imovel", component: AddProperty },
	{ path: "/novo-imovel", component: NewAddProperty },
	{ path: "/novo-empreendimento", component: AddEnterprise },
	{ path: "/atualizar-unidades", component: EditUnits },

	{ path: "/adicionar-empreendimento", component: AddEnterpriveV2 },
	{ path: "/perfil-empreendimento", component: EnterpriseProfile },
	{ path: "/endereco-empreendimento", component: EnterpriseAddress },
	{ path: "/info-empreendimento", component: EnterpriseInformation },
	{ path: "/info-tipo-empreendimento", component: TypeInformation },
	{ path: "/pagamento-empreendimento", component: EnterprisePayment },
	{ path: "/andamento-empreendimento", component: EnterpriseBuilding },
	{ path: "/caracteristicas-empreendimento", component: EnterpriseSkills },
	{ path: "/caracteristicas-tipo-empreendimento", component: TypeSkills },
	{ path: "/fotos-empreendimento", component: EnterprisePhotos },
	{ path: "/fotos-tipo-empreendimento", component: TypePhotos },
	{ path: "/plantas-empreendimento", component: EnterprisePlans },
	{ path: "/plantas-tipo-empreendimento", component: TypePlans },
	{ path: "/anexos-empreendimento", component: EnterpriseDocs },
	{ path: "/videos-empreendimento", component: EnterpriseVideos },
	{ path: "/videos-tipo-empreendimento", component: TypeVideos },
	{ path: "/empreendimento-tipo", component: TypeProfile },
	{ path: "/construtora", component: CatalogConstructionResult },

	//Módulo imobiliárias
	{ path: "/time-de-corretores", component: BrokersStaff },
	{ path: "/time-corretor", component: RealBrokerProfile },
	

	{ path: "/empreendimento/:slug", component: SingleEnterprise },
	{ path: "/unidade/:slug", component: SingleUnits },

	{ path: "/editar-empreendimento", component: EditEnterpriseFields },
	{ path: "/unidades", component: Units },
	// { path: "/editar-unidades", component: EditUnits },
	// { path: "/editar-unidades-empreendimento", component: EditUnitsSelected },
	// { path: "/editar-unidade", component: EditUnitsFields },
	// { path: "/nova-unidade", component: AddUnits },
	{ path: "/editar-imoveis", component: editProperties },
	{ path: "/editar-empreendimentos", component: editEnterprises },
	{ path: "/integracoes", component: PropertyIntegration },
	{ path: "/editar-imovel", component: editPropertiesFields },
	{ path: "/imovel/:slug", component: SingleProperty },
	{ path: "/pedro-guaxinim", component: SinglePropertyMeme },
	{ path: "/favoritos", component: PropertyWishlist },
	{ path: "/parcerias", component: PartnershipList },
	{ path: "/usuarios-construtoras", component: ConstructionUsers },
	{ path: "/propostas", component: listProposal },
	{ path: "/proposta", component: singleProposal },
	{ path: "/criar-proposta", component: addProposal },
	{ path: "/editar-perfil", component: EditUser },
	{ path: "/seu-site-fs", component: SeuSiteFS },
	{ path: "/construtor", component: Construtor },

	//adm
	{path:"/administrar-imoveis", component: editPropertiesAdm},
	{path:"/administrar-usuarios", component: editUsersAdm},
	{path:"/administrar-propostas", component: listProposalAdm},
	{path:"/administrar-empreendimentos", component: EnterprisesAdminList},
	{path:"/administrar-parcerias", component: PartnershipListAdm},
	{path:"/administrar-outlet", component: editOutletAdm},
	{path:"/administrar-exclusoes", component: editDeleteAdm},
	{path:"/administrar-indicacoes", component: AdmIndicators},
	{path:"/administrar-cupons", component: AdmCoupons},
	{path:"/administrar-pedidos", component: AdmOrders},
	{path:"/origem-usuarios", component: UsersOrigin},
	{path:"/construtoras-indicadas", component: ConstructionIndication},
	{path:"/marketing", component: NewProperties},
	{path:"/produtos", component: AdmProducts},

	{ path: "/contrato-plano", component: ContractPlan },
	// this route should be at the end of all other routes
	// { path: "/dashboard", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/compartilhar-imovel", component: SinglePropertyPublic },
	{ path: "/compartilhar-unidade", component: SingleUnitPublic },
	{ path: "/recuperar-senha", component: ForgetPwd },
	{ path: "/enviar-documentos", component: UpdateDocs },
	{ path: "/reset-password", component: CreatePassword },

	{ path: "/registre-se", component: NewRegister },
	{ path: "/registro-passo-2", component: RegisterStep2 },
	{ path: "/registro-passo-3", component: RegisterStep3 },
	{ path: "/registro-passo-4", component: RegisterStep4 },
	{ path: "/agradecimento-cadastro", component: ThankYou },
	{ path: "/agradecimento-cadastro-construtora", component: ThankYouConstruction },

	{ path: "/completar-cadastro", component: CompleteRegister },
	{ path: "/cadastro-construtora", component: RegisterConstruction },
	{ path: "/obrigado-pelo-cadastro", component: RegisterThankYou },
	// { path: "/lock-screen", component: AuthLockScreen },
	{ path: "/politicas", component: policyUse },
	// { path: "/corretor/:slug", component: policyUse },
	// { path: "/imobiliaria/:slug", component: policyUse },


	// Authentication Inner
	// { path: "/auth-login", component: Login1 },
	// { path: "/auth-register", component: Register1 },
	// { path: "/auth-recoverpw", component: ForgetPwd1 },

	// { path: "/maintenance", component: Maintenance },
	// { path: "/comingsoon", component: CommingSoon },
	{ path: "/404", component: Error404 },
	{ path: "/500", component: Error500 },

	// { path: "/checkout/:url", component: HomeCheckout },

	{ path: "/compra-finalizada", component: CompletePayment },
	{ path: "/novo-checkout", component: NewCheckout },
	{ path: "/carrinho", component: CheckoutCart },

	{ path: "/checkout-sucesso/mensagem", component: CheckoutMensagem },
	{ path: "/promo", component: PromoPage},
	{ path: "/google/callback", component: GoogleLogin},
	{ path: "/home", component: HomeIndex },
	// { path: "/", exact: false, component: () => <Redirect to="/home" /> },
];


export { authProtectedRoutes, publicRoutes };
