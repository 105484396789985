import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import { Button, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import CreatableSelect from 'react-select/creatable';
import SweetAlert from "react-bootstrap-sweetalert";

const id = window.location.search.replace('?id=', '')

class EnterpriseSkills extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      selectedSkill:[],
      selectedOption: [],
      tagsOptions:[],
      tags:[],
      skillsList:'',
      skillsArray:[],
      }
      this.updateEnterprise = this.updateEnterprise.bind(this);
    }


    componentDidMount(){
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            const enterprise = response.data;
            const details = enterprise.details.map(item => item.detail);

            const skillsList = ["Academia", "Alarme", "Bar", "Bicicletário", "Boliche", "Brinquedoteca", "Circuito TV", "Elevador", "Entrada p/ banhistas e box de praia", "Espaço gourmet", "Espaço pet", "Espelho d'água", "Estar social", "Estúdio de pilates", "Guarita de segurança", "Hall de entrada decorado e mobiliado", "Heliponto", "Hidromassagem", "Hidromassagem na piscina", "Interfone", "Internet", "Jacuzzi", "Lounge", "Medidores de água, luz e gás individuais", "Painéis de energia solar", "Piscina", "Piscina adulta", "Piscina adulta com borda infinita", "Piscina infantil", "Piscina térmica", "Playground", "Pub", "Reaproveitamento de água", "Rooftop", "Sala de reunião", "Sala de games", "Sala de jogos", "Salão de festas", "Sauna", "Solarium", "Spa"];

            const selectedSkill = details.filter(detail => skillsList.includes(detail));
            const tags = details.filter(detail => !skillsList.includes(detail)).map(tag => ({ name: tag, value: tag }));

            this.setState({
                enterprise,
                selectedSkill,
                tags,
                selectedOption: tags.map(tag => ({ label: tag.name, value: tag.value }))
            });
         })
        })
        .catch(response =>{
            setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
            })  
    }

      updateEnterprise(event) {
        if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
            this.setState({error_auth:true})
        }else{
        document.getElementById('load').classList.add('active')
                            
        const dataEnterpriseSkills = new FormData();

        const combinedDetails = [
            ...this.state.selectedSkill.map(skill => ({ detail: skill })),
            ...this.state.tags.map(tag => ({ detail: tag.name }))
        ];
 
        combinedDetails.forEach((item, index) => {
            dataEnterpriseSkills.append(`detail[${index}][detail]`, item.detail);
            dataEnterpriseSkills.append(`detail[${index}][property_enterprise_id]`, id);
        });

        if(combinedDetails.length > 0){
        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-details', dataEnterpriseSkills, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
            document.getElementById('load').classList.remove('active')

            setTimeout(() => {
                window.open('./../perfil-empreendimento?id=' + id, '_parent')
            }, 100);
        })}
    }
    }

    handleInputChange = (e) => {
        const inputValue = e.target.value;
    
        const formattedValue = inputValue.replace(/[;.,]/g, '\n');
    
        this.setState({ skillsList: formattedValue });
    }

    handleCreate = async (inputValue) => {
        const { selectedOption } = this.state;
    
        try {
            const createResponse = await Axios.post(
                `${process.env.REACT_APP_API}/api/tag/`,
                { name: inputValue },
                { headers: { "Authorization": "Bearer " + localStorage.getItem('token') } }
            );
    
            await new Promise(resolve => setTimeout(resolve, 500));
    
            const fetchResponse = await Axios.post(
                `${process.env.REACT_APP_API}/api/tag/${inputValue}`,
                {},
                { headers: { "Authorization": "Bearer " + localStorage.getItem('token') } }
            );
    
            await new Promise(resolve => setTimeout(resolve, 500));
    
            const newTags = fetchResponse.data.filter(tag => tag?.name === inputValue).map(tag => ({
                name: tag.name,
                value: tag.id
            }));
    
            this.setState({
                selectedOption: [
                    ...selectedOption,
                    ...newTags.map(tag => ({ label: tag.name, value: tag.value }))
                ]
            });
    
            this.setState(state => ({
                tags: [...state.tags, ...newTags]
            }));
        } catch (error) {
            console.error(error);
        }
    };
    
    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
    
        const newTags = selectedOption
            .filter(option => !this.state.tags.some(tag => tag.value === option.value))
            .map(option => ({ name: option.label, value: option.value }));
    
            const removedTags = this.state.tags.filter(tag => !selectedOption.some(option => option.value === tag.value));

            this.setState(state => ({
                tags: state.tags.filter(tag => !removedTags.includes(tag)).concat(newTags)
            }));
    };

    loadOptions = async (inputValue, callback) => {
        try {
            const response = await Axios.post(
                `${process.env.REACT_APP_API}/api/tag/${inputValue}`,
                {},
                { headers: { "Authorization": "Bearer " + localStorage.getItem('token') } }
            );
    
            const options = response.data.map(item => ({
                value: item.id,
                label: item.name
            }));
    
            callback(options);
        } catch (error) {
            console.error(error);
        }
    };

    render(){
        const {enterprise } = this.state;

        const skillsList = ["Academia", "Alarme", "Bar", "Bicicletário", "Boliche", "Brinquedoteca", "Circuito TV", "Elevador", "Entrada p/ banhistas e box de praia", "Espaço gourmet", "Espaço pet", "Espelho d'água", "Estar social", "Estúdio de pilates", "Guarita de segurança", "Hall de entrada decorado e mobiliado", "Heliponto", "Hidromassagem", "Hidromassagem na piscina", "Interfone", "Internet", "Jacuzzi", "Lounge", "Medidores de água, luz e gás individuais", "Painéis de energia solar", "Piscina", "Piscina adulta", "Piscina adulta com borda infinita", "Piscina infantil", "Piscina térmica", "Playground", "Pub", "Reaproveitamento de água", "Rooftop", "Sala de reunião", "Sala de games", "Sala de jogos", "Salão de festas", "Sauna", "Solarium", "Spa"];

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                            <Container style={{marginTop:'30px'}}>
                                                <Row style={{margin:'10px 0'}}>
                                                <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Características do empreendimento</h5></Col>
                                                </Row>
                                                <Row>
                                                <Col md={12}>
                                                    <div className="check-list-container">
                                                        {skillsList.map((skill, index) => (
                                                            <div key={index} className="input-check-container">
                                                                <input
                                                                    type="checkbox"
                                                                    name={skill}
                                                                    id={skill}
                                                                    checked={this.state.selectedSkill.includes(skill)}
                                                                    onChange={() => {
                                                                        let updatedSkills;
                                                                        if (this.state.selectedSkill.includes(skill)) {
                                                                            updatedSkills = this.state.selectedSkill.filter(selected => selected !== skill);
                                                                        } else {
                                                                            updatedSkills = [...this.state.selectedSkill, skill];
                                                                        }
                                                                        this.setState({ selectedSkill: updatedSkills });
                                                                    }}
                                                                />
                                                                <label htmlFor={skill}>{skill}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Col>
                                                </Row>
                                                <Row style={{margin:'10px 0'}}>
                                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Outras características</h5></Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{paddingBottom: '20px'}}>
                                                        <CreatableSelect
                                                            value={this.state.selectedOption}
                                                            onChange={this.handleChange}
                                                            options={this.state.tagsOptions}
                                                            formatCreateLabel={(inputValue) => `Criar nova tag: ${inputValue}`}
                                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                                            onInputChange={(inputValue) => {
                                                                this.loadOptions(inputValue, (newOptions) => {
                                                                    this.setState({ tagsOptions: newOptions });
                                                                });
                                                            }}
                                                            onCreateOption={this.handleCreate}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            placeholder="Busque uma característica ou crie uma nova"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Container>
                                            <Container>
                                                <Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                                    <Col className="col-auto">
                                                        <Button className="btn-dark" onClick={() => {
                                                            setTimeout(() => {
                                                                window.open('./../perfil-empreendimento?id=' + id, '_parent')
                                                            }, 100);
                                                        }}>Voltar</Button>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={this.updateEnterprise}>Salvar</Button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                
            {this.state.error_auth ? (
                <SweetAlert
                    error
                    title='Ops'
                    onConfirm={() => this.setState({ error_auth: false })}
                >
                    Você não tem permissão para esta ação.
                </SweetAlert>
            ) : null}
            </div>
        )
    }

}

export default EnterpriseSkills;