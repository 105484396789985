import { useState, useEffect } from 'react';
// import {api} from '../../../Services/api';
import './styles.css';
import { Link } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';

function UsersCharts({items, model}) {

  let meses = {
    1: "Janeiro",
    2: "Fevereiro",
    3: "Março",
    4: "Abril",
    5: "Maio",
    6: "Junho",
    7: "Julho",
    8: "Agosto",
    9: "Setembro",
    10: "Outubro",
    11: "Novembro",
    12: "Dezembro"
};

var data = []

const [chart, setChart] = useState()

  useEffect(() => {
      if(items && model !== 'noArray'){
        data = items.map((item, index) => {
          let key = Object.keys(item)[0];
          let year = 2023;
          return {
              ano: year,
              mês: meses[key],
              users: item[key].length
          };
        })
      }
      if(items && model === 'noArray'){
        data = items.map((item, index) => {
          let key = Object.keys(item)[0];
          let year = 2023;
          return {
              ano: year,
              mês: meses[key],
              users: item[key]
          };
        })
      }
      const data2022 = data.filter(item => item.ano === 2022);
      const data2023 = data.filter(item => item.ano === 2023);

     setTimeout(() => {
        setChart(
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="mês" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* <Line type="monotone" dataKey="users" strokeWidth={3} data={data2022} stroke="#0F6E43" activeDot={{ r: 8 }} /> */}
              <Line type="monotone" dataKey="users" strokeWidth={3} data={data2023} stroke="#62c90e" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        )
     }, 1000);
  },[items])

  return (
      <div className="UsersCharts">
        {chart}
      </div>
    );
  }
  
export default UsersCharts;  