import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Import Charts
import SimpleBar from "simplebar-react";
import "./dashboard.scss";
import HorizontalCardNumbers from './HorizontalCardNumbers';

class Captations extends Component {
    state = {
        series: [42, 26, 15],
        options: {
            labels: ["Product A", "Product B", "Product C"],
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#5664d2', '#1cbb8c', '#eeb902'],

        }
    }
    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card>
                        <CardBody>
                            {/* <Dropdown className="float-end" isOpen={this.state.menu} toggle={() => this.setState({ menu: !this.state.menu })}>
                                <DropdownToggle tag="i" className="darrow-none card-drop" aria-expanded="false">
                                    <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">

                                    <DropdownItem href="">Sales Report</DropdownItem>

                                    <DropdownItem href="">Export Report</DropdownItem>

                                    <DropdownItem href="">Profit</DropdownItem>

                                    <DropdownItem href="">Action</DropdownItem>
                                </DropdownMenu>
                            </Dropdown> */}

                            <h4 className="card-title mb-4">Suas captações</h4>
                            <SimpleBar style={{ maxHeight: "350px", minHeight: "350px", overflow:'hidden auto'}}>
                                {this.props.imoveis?.map((imovel,index) => (
                                    <HorizontalCardNumbers imovel={imovel}/>
                                ))}
                            </SimpleBar>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default Captations;