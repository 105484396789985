import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import Select from "react-select";
import Flatpickr from "react-flatpickr"
import 'flatpickr/dist/l10n/pt';

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    Input,
    Label,
    FormGroup,
    InputGroup,
    Spinner,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import FullCalendar from "@fullcalendar/react"
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
//css
import "@fullcalendar/bootstrap/main.css"
import './../../assets/scss/custom/components/_calendar.scss'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    getEvents,
    addNewEvent,
    updateEvent,
    deleteEvent,
    getCategories,
} from "../../store/actions"
import DeleteModal from "./DeleteModal"
import Axios from 'axios'
import moment from "moment";


const scheduleHours = [
    {hour: 8, minute: '00'},
    {hour: 8, minute: '30'},
    {hour: 9, minute: '00'},
    {hour: 9, minute: '30'},
    {hour: 10, minute: '00'},
    {hour: 10, minute: '30'},
    {hour: 11, minute: '00'},
    {hour: 11, minute: '30'},
    {hour: 12, minute: '00'},
    {hour: 12, minute: '30'},
    {hour: 13, minute: '00'},
    {hour: 13, minute: '30'},
    {hour: 14, minute: '00'},
    {hour: 14, minute: '30'},
    {hour: 15, minute: '00'},
    {hour: 15, minute: '30'},
    {hour: 16, minute: '00'},
    {hour: 16, minute: '30'},
    {hour: 17, minute: '00'},
    {hour: 17, minute: '30'},
    {hour: 18, minute: '00'},
    {hour: 18, minute: '30'},
    {hour: 19, minute: '00'},
    {hour: 19, minute: '30'},
  ]

class CatalogProperties extends Component {
    constructor(props) {
        super(props)
        this.handleDateClick = this.handleDateClick.bind(this)
        this.calendarComponentRef = React.createRef()

        this.state = {
            breadcrumbItems: [
                { title: "Fast Sale", link: "/dashboard" },
                { title: "Agenda", link: "#" },
            ],
            calendarWeekends: true,
            modal: false,
            modalcategory: false,
            isDragBind: false,
            deleteModal: false,
            newEventDate: '',
            newEventDateFormated:'',
            newEventHour:'',
            newEventType:'',
            newEventPropertyId:null,
            newEventClientName:'',
            event: {},
            userDate:[],
            imoveis:[],
            imovel:'',
            totalPendente:'',
            totalConfirmado:'',
            totalCancelado:'',
            totalFinalizado:'',
            diaSelecionado:null,
            mesSelecionada:null,
            newStatus:null,
            typeUser:null,
            selectedRealEstate: [],
            selectedBroker: [],
            selectOptionsBroker: [],
            selectOptionsRealEstate: [],
            hoursOptions:null,
        }

        this.toggle = this.toggle.bind(this)
        this.togglecategory = this.togglecategory.bind(this)
        this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
        this.handleValidEventSubmitcategory = this.handleValidEventSubmitcategory.bind(this)
        this.createNewEvent = this.createNewEvent.bind(this)

        // category
        this.onDrag = this.onDrag.bind(this)
        this.onDrop = this.onDrop.bind(this)
    }

    async componentDidMount() {
        // document.getElementById('load').classList.add('active')
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .catch(response =>{
                localStorage.removeItem("history_url")
              setTimeout(() => {
                window.open("/login", '_parent')
              }, 300);
            })

            Axios.get(process.env.REACT_APP_API + '/api/auth/brokers-select',
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              for(var i = 0; response.data?.length > i; i++){
                this.state.selectOptionsBroker.push({
                  value: response.data[i].id,
                  label: response.data[i].name
                })
              }
             })
            Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-select',
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              for(var i = 0; response.data?.length > i; i++){
                this.state.selectOptionsRealEstate.push({
                  value: response.data[i].id,
                  label: response.data[i].name
                })
              }
            })

            const { onGetCategories } = [
                {
                  id: 1,
                  title: "New Theme Release",
                  type: "bg-success",
                },
                {
                  id: 2,
                  title: "My Event",
                  type: "bg-info",
                },
                {
                  id: 3,
                  title: "Meet Manager",
                  type: "bg-warning",
                },
                {
                  id: 4,
                  title: "Report Error",
                  type: "bg-danger",
                },
              ]
            new Draggable(document.getElementById("external-events"), {
                itemSelector: ".external-event",
            })
            
            const typeUserPromise = new Promise((resolve) => {
                if(localStorage.getItem('access') === 'A') {
                  this.setState({typeUser : "schedulings-all"}, () => {
                    resolve();
                  });
                } else {
                  this.setState({typeUser : "users-schedulings"}, () => {
                    resolve();
                  });
                }
              });

              typeUserPromise.then(async () => {
                try {
                  const response = await Axios.post(
                      process.env.REACT_APP_API + '/api/auth/' + this.state.typeUser,
                      {
                        type: localStorage.getItem('access'),
                        user_id: localStorage.getItem('id')
                      },
                      { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
                  )
                      
             const allData = []

             if(localStorage.getItem('access') === 'A') {
                for(var i = 0; i < response.data.length; i++){
                    allData.push(response.data[i])
                 }
             }else{
                for(var i = 0; i < response.data.other_shcedulings.length; i++){
                    for(var e = 0; e < response.data.other_shcedulings[i].schedulings.length; e++){
                        allData.push(response.data.other_shcedulings[i].schedulings[e])
                    }
                 }
             }

             

            // console.log(allData)

             const userDate = allData.concat(response.data.user_schedulings).map((event, index) => ({
               title: "(" + event?.properties?.sku + ") " + event?.properties?.title,
               status: event?.status,
               user_type: event?.broker_id ? "Corretor: " : event?.real_estate_id ? "Imobiliária: " : "Proprietário: ",
               immobile: event?.properties?.title,
               client: event?.client_name,
               sku: event?.properties?.sku,
               event_type: event?.event_type,
               initial_hour: event?.schedule_time,
               initial_date: event?.schedule_date,
               broker_name: event?.broker_id ? event?.brokers?.name : event?.real_estate_id ? event?.real_estates?.name : event?.owners?.name,
               property_id: event?.property_id,
               id: event?.id,
               classNames: event?.status === 0 ? 'bg-warning'
               : event?.status === 1 ? 'bg-primary' 
               : event?.status === 2 ? 'bg-danger' 
               : event?.status === 3 ? 'bg-info' 
               : 'bg-light' ,
               start: moment(`${event?.schedule_date} ${event?.schedule_time}`, "YYYY-MM-DD HH:mm:ss").toDate(),
               end: moment(event?.schedule_date).add(1, "hour").toDate(),
             }));
 
             this.setState({ userDate });
             this.setState({totalFinalizado: allData.concat(response.data.user_schedulings).filter(schedule => schedule?.status === 4).length})
             this.setState({totalConfirmado: allData.concat(response.data.user_schedulings).filter(schedule => schedule?.status === 1).length})
             this.setState({totalCancelado: allData.concat(response.data.user_schedulings).filter(schedule => schedule?.status === 2).length})
             this.setState({totalPendente: allData.concat(response.data.user_schedulings).filter(schedule => schedule?.status === 0).length})

            //  setTimeout(() => {
            //     document.getElementById('load').classList.remove('active')
            //  }, 300);
           } catch (error) {
             console.log(error);
           }
        })
           
           Axios.post(process.env.REACT_APP_API + '/api/public/property-filter-no-pagination', {workflow:['7']},
               { headers: { Authorization: 'Bearer ' + localStorage.getItem('token')}})
               .then(response => {
                   for(var i = 0; response.data.length > i; i++){
                       this.state.imoveis.push({
                         value: response.data[i].id,
                         label: response.data[i].sku + " - " + response.data[i].title
                       })
                     }
               })
      }
      

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { event, modal } = this.state
        if (prevState.modal !== modal && !modal && !isEmpty(event)) {
            setTimeout(() => {
                this.setState({ event: {}, isEdit: false })
            }, 500)
        }


        document.querySelector('.visitTypeBtnContainer')?.addEventListener('click', function(e) {
            for(var i =0; document.querySelectorAll('.visitTypeBtn').length > i; i++){
              document.querySelectorAll('.visitTypeBtn')[i].classList.remove('active')
            }
              e.target.classList.add('active')
          }, false);

          document.querySelector('.hour-container')?.addEventListener('click', function(e) {
            for(var i =0; document.querySelectorAll('.hour').length > i; i++){
              document.querySelectorAll('.hour')[i].classList.remove('active')
            }
              e.target.classList.add('active')
          }, false);
    }

    generateHoursOption() {
        let hoursOptions = null; // Inicialize como nulo
      
        if (parseInt(this.state.mesSelecionado) >= parseInt(moment(new Date()).format('MM'))) {
          if (parseInt(this.state.dataSelecionada) === parseInt(moment(new Date()).format('DD'))) {
            if (scheduleHours.filter(hour => parseInt(hour.hour) > (parseInt(moment(new Date()).format('HH')) + 3)).length > 0) {
              hoursOptions = scheduleHours
                .filter(hour => parseInt(hour.hour) > (parseInt(moment(new Date()).format('HH')) + 3))
                .map((hour, index) => (
                  <div
                    key={index}
                    className="hour"
                    onClick={() => this.setState({ newEventHour: hour.hour + ":" + hour.minute })}
                  >
                    {hour.hour}:{hour.minute}
                  </div>
                ));
            } else {
              hoursOptions = <p style={{ position: 'absolute' }}>Sem horários disponíveis para esta data.</p>;
            }
          } else if (parseInt(this.state.dataSelecionada) < parseInt(moment(new Date()).format('DD')) && parseInt(this.state.mesSelecionado) > parseInt(moment(new Date()).format('MM'))) {
              hoursOptions = scheduleHours.map((hour, index) => (
                <div
                  key={index}
                  className="hour"
                  onClick={() => this.setState({ newEventHour: hour.hour + ":" + hour.minute })}
                >
                  {hour.hour}:{hour.minute}
                </div>
              ));
          } else if (parseInt(this.state.dataSelecionada) < parseInt(moment(new Date()).format('DD'))) {
            hoursOptions = <p style={{ position: 'absolute' }}>Sem horários disponíveis para esta data.</p>;
          } else {
            hoursOptions = scheduleHours.map((hour, index) => (
              <div
                key={index}
                className="hour"
                onClick={() => this.setState({ newEventHour: hour.hour + ":" + hour.minute })}
              >
                {hour.hour}:{hour.minute}
              </div>
            ));
          }
        } else {
          hoursOptions = <p style={{ position: 'absolute' }}>Sem horários disponíveis para esta data.</p>;
        }
      
        // Defina hoursOptions no estado
        this.setState({ hoursOptions });
      }

    createNewEvent(){
        if(localStorage.getItem('access') === 'A'){
            if(this.state.selectedBroker){
                const newEvent = {
                    status: 0,
                    schedule_date:  this.state.newEventDateFormated,
                    client_name: this.state.newEventClientName,
                    schedule_time: this.state.newEventHour,
                    type: 'C',
                    user_id: this.state.selectedBroker.value,
                    property_id: this.state.newEventPropertyId,
                    event_type: this.state.newEventType
                }
                Axios.post(process.env.REACT_APP_API + '/api/auth/scheduling', newEvent,
                { headers: { Authorization: 'Bearer ' + localStorage.getItem('token')}})
                .then(response => setTimeout(() => {
                    window.location.reload()
                }, 300))
            }else{
                const newEvent = {
                    status: 0,
                    schedule_date:  this.state.newEventDateFormated,
                    client_name: this.state.newEventClientName,
                    schedule_time: this.state.newEventHour,
                    type: 'I',
                    user_id: this.state.selectedRealEstate.value,
                    property_id: this.state.newEventPropertyId,
                    event_type: this.state.newEventType
                }
                Axios.post(process.env.REACT_APP_API + '/api/auth/scheduling', newEvent,
                { headers: { Authorization: 'Bearer ' + localStorage.getItem('token')}})
                .then(response => setTimeout(() => {
                    window.location.reload()
                }, 300))
            }
        }else{
            const newEvent = {
                status: 0,
                schedule_date:  this.state.newEventDateFormated,
                client_name: this.state.newEventClientName,
                schedule_time: this.state.newEventHour,
                type: localStorage.getItem('access'),
                user_id: localStorage.getItem('id'),
                property_id: this.state.newEventPropertyId,
                event_type: this.state.newEventType
            }
            Axios.post(process.env.REACT_APP_API + '/api/auth/scheduling', newEvent,
            { headers: { Authorization: 'Bearer ' + localStorage.getItem('token')}})
            .then(response => setTimeout(() => {
                window.location.reload()
            }, 300))
        }

    }
    /**
     * Handling the modal state
     */
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }

    togglecategory() {
        this.setState(prevState => ({
            modalcategory: !prevState.modalcategory,
        }))
    }

    /**
     * Handling date click on calendar
     */
    handleDateClick = arg => {
        setTimeout(() => {
            this.generateHoursOption()
          }, 300);
        const date = arg['date'];
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const currectDate = new Date();
        const currentHour = currectDate.getHours();
        const currentMin = currectDate.getMinutes();
        const currentSec = currectDate.getSeconds();
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);
        const modifiedData = { ...arg, date: modifiedDate };

        this.setState({ selectedDay: modifiedData })
        this.setState({ newEventDate: moment(modifiedDate).format('DD/MM/YYYY, dddd.')})
        this.setState({ newEventDateFormated: moment(modifiedDate).format('YYYY-MM-DD')})
        this.setState({ dataSelecionada: moment(modifiedDate).format('DD')})
        this.setState({ mesSelecionado: moment(modifiedDate).format('MM')})
        this.toggle();
    }

    /**
     * Handling click on event on calendar
     */
    handleEventClick = arg => {
        const event = arg.event
        let user_type = '';
        let broker_name = '';
        let property_id = '';
        let event_type = '';
        let initial_hour = '';
        let initial_date = '';
        let client = '';
        let status = '';

        if (event.extendedProps && event.extendedProps.event_type) {
            event_type = event.extendedProps.event_type;
        }
        if (event.extendedProps && event.extendedProps.initial_hour) {
            initial_hour = event.extendedProps.initial_hour;
        }
        if (event.extendedProps && event.extendedProps.initial_date) {
            initial_date = event.extendedProps.initial_date;
        }
        if (event.extendedProps && event.extendedProps.user_type) {
            user_type = event.extendedProps.user_type;
        }
        if (event.extendedProps && event.extendedProps.broker_name) {
            broker_name = event.extendedProps.broker_name;
        }
        if (event.extendedProps && event.extendedProps.client) {
            client = event.extendedProps.client;
        }
        if (event.extendedProps && event.extendedProps.property_id) {
            property_id = event.extendedProps.property_id;
        }
        if (event.extendedProps && event.extendedProps.status) {
            status = event.extendedProps.status;
        }
        this.setState({
            event: {
                id: event.id,
                client: client,
                status: status,
                event_type: event_type,
                initial_hour: initial_hour,
                initial_date: initial_date,
                title: event.title,
                user_type: user_type,
                broker_name: broker_name,
                category: event.category,
                start: event.start,
                className: event.classNames,
                category: event.classNames[0],
                property_id: property_id,
                event_category: event.classNames[0],
            },
            isEdit: true,
        })
        this.toggle()
    }

    /**
     * Handling submit event on event form
     */
    handleValidEventSubmit = (e, values) => {

        const { onAddNewEvent, onUpdateEvent } = this.props
        const { isEdit, event, selectedDay } = this.state
        if (isEdit) {
            const updateEvent = {
                id: event.id,
                title: values.title,
                classNames: values.category + " text-white",
                start: event.start,
            }
            // update event
            onUpdateEvent(updateEvent)
        } else {
            const newEvent = {
                id: Math.floor(Math.random() * 100),
                title: values["title"],
                start: selectedDay ? selectedDay.date : new Date(),
                className: values.category + " text-white",
            }
            // save new event
            onAddNewEvent(newEvent)
        }

        this.setState({ selectedDay: null })
        this.toggle()
    }

    handleValidEventSubmitcategory = (event, values) => {
        const { onAddNewEvent } = this.props

        const newEvent = {
            id: Math.floor(Math.random() * 100),
            title: values["title_category"],
            start: new Date(),
            className: values.event_category ? values.event_category + " text-white" : "bg-danger text-white",
        }
        // save new event
        onAddNewEvent(newEvent)
        this.togglecategory()
    }

    /**
     * On delete event
     */
    handleDeleteEvent = () => {
        const { onDeleteEvent } = this.props
        const { event } = this.state

        Axios.post(process.env.REACT_APP_API + '/api/auth/scheduling-delete/' + event.id, {},
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            setTimeout(() => {
                window.location.reload()
            }, 500);
        })
        .catch(response => {
            setTimeout(() => {
                window.location.reload()
            }, 500);
        })
    }

    /**
     * On category darg event
     */
    onDrag = (event) => {
        event.preventDefault()
    }

    /**
     * On calendar drop event
     */
    onDrop = event => {
        const date = event['date'];
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const currectDate = new Date();
        const currentHour = currectDate.getHours();
        const currentMin = currectDate.getMinutes();
        const currentSec = currectDate.getSeconds();
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);

        const { onAddNewEvent } = this.props
        const draggedEl = event.draggedEl
        const modifiedData = {
            id: Math.floor(Math.random() * 100),
            title: draggedEl.innerText,
            start: modifiedDate,
            className: draggedEl.className,
        }
        onAddNewEvent(modifiedData)
    }

    render() {

          const locale = {
            weekdays: {
              shorthand: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
              longhand: [
                'Domingo',
                'Segunda-feira',
                'Terça-feira',
                'Quarta-feira',
                'Quinta-feira',
                'Sexta-feira',
                'Sábado'
              ]
            },
            months: {
              shorthand: [
                'Jan',
                'Fev',
                'Mar',
                'Abr',
                'Mai',
                'Jun',
                'Jul',
                'Ago',
                'Set',
                'Out',
                'Nov',
                'Dez'
              ],
              longhand: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
              ]
            },
            firstDayOfWeek: 0,
            rangeSeparator: ' até ',
            weekAbbreviation: 'Sem',
            scrollTitle: 'Rolagem para aumentar',
            toggleTitle: 'Clique para alternar',
            amPM: ['AM', 'PM'],
            yearAriaLabel: 'Ano',
            time_24hr: true
          };
        const { events, categories } = this.props;
        const { isEdit, deleteModal, userDate, selectedRealEstate, selectedBroker, selectOptionsBroker, selectOptionsRealEstate } = this.state;

        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteEvent}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content" id="Calendar">
                    <Container fluid={true}>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Agenda" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg={3}>
                                                <Button
                                                    color="primary"
                                                    className="font-16 btn-block btn btn-primary"
                                                    onClick={this.togglecategory}
                                                >
                                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                                        Agendar nova visita
                                                        </Button>

                                                <div id="external-events" className="mt-3">
                                                    {categories &&
                                                        categories?.map((category, i) => (
                                                            <div
                                                                key={i}
                                                                className={`${category?.type} external-event text-white p-1 mb-2`}
                                                                draggable
                                                                onDrag={event => this.onDrag(event, category)}
                                                            >
                                                                <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                                                                {category?.title}
                                                            </div>
                                                        ))}
                                                </div>

                                                <div className="mt-5 d-none d-xl-block">
                                                    <h5 className="text-center">Seus agendamentos</h5>
                                                    <Row>
                                                        <Card className="schedulingNumbers">
                                                            <h3>Pendentes</h3>
                                                            <h1>{this.state.totalPendente}</h1>
                                                        </Card>
                                                    </Row>
                                                    <Row>
                                                        <Card className="schedulingNumbers">
                                                            <h3>Confirmados</h3>
                                                            <h1>{this.state.totalConfirmado}</h1>
                                                        </Card>
                                                    </Row>
                                                    <Row>
                                                        <Card className="schedulingNumbers">
                                                            <h3>Cancelados</h3>
                                                            <h1>{this.state.totalCancelado}</h1>
                                                        </Card>
                                                    </Row>
                                                    <Row>
                                                        <Card className="schedulingNumbers">
                                                            <h3> Finalizados</h3>
                                                            <h1>{this.state.totalFinalizado}</h1>
                                                        </Card>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col className="col-lg-9">
                                                {/* fullcalendar control */}
                                                <FullCalendar
                                                    ref={this.calendarComponentRef}
                                                    plugins={[
                                                        BootstrapTheme,
                                                        dayGridPlugin,
                                                        interactionPlugin,
                                                    ]}
                                                    locale={ptBrLocale}
                                                    slotDuration={"00:15:00"}
                                                    handleWindowResize={true}
                                                    themeSystem="bootstrap"
                                                    events={userDate}
                                                    editable={true}
                                                    droppable={true}
                                                    selectable={true}
                                                    dateClick={this.handleDateClick}
                                                    eventClick={this.handleEventClick}
                                                    drop={this.onDrop}
                                                />

                                                {/* New/Edit event modal */}
                                                <Modal
                                                    isOpen={this.state.modal}
                                                    className={this.props.className}
                                                    id="event-modal"
                                                >
                                                    <ModalHeader toggle={this.toggle} tag="h4">
                                                        {!!isEdit ? "Agendamento de visita" : "Nova visita"}
                                                    </ModalHeader>
                                                    <ModalBody>
                                                    {!!isEdit ? 
                                                        <AvForm >
                                                            <Row form>
                                                                <Col className="col-12 mb-3">
                                                                    <Row className="scheduleEvent">
                                                                        <Col xs={12}>
                                                                            <div className="box-info">
                                                                            <label>Imóvel:</label>
                                                                            <p>{this.state.event.title}</p></div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div className="box-info">
                                                                            <label>{this.state.event.user_type}</label>
                                                                            <p>{this.state.event.broker_name}</p></div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div className="box-info">
                                                                            <label>Cliente:</label>
                                                                            <p>{this.state.event.client}</p></div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div className="box-info">
                                                                            <label>Data:</label>
                                                                            <p>{this.state.event.initial_date}</p></div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div className="box-info">
                                                                            <label>Horário:</label>
                                                                            <p>{this.state.event.initial_hour}</p></div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div className="box-info">
                                                                            <label>Tipo:</label>
                                                                            <p>{this.state.event.event_type === '1' ? 'Visita no local' : 'Visita videochamada' }</p></div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div className="box-info">
                                                                            <AvField
                                                                                type="select"
                                                                                name="category"
                                                                                label="Status:"
                                                                                value={
                                                                                    this.state.event
                                                                                        ? this.state.event.category
                                                                                        : "bg-primary"
                                                                                }
                                                                                onChange={(e) => {
                                                                                    if(e.target.value === 'bg-danger'){
                                                                                        this.setState({newStatus: 2})
                                                                                    }
                                                                                    if(e.target.value === 'bg-primary'){
                                                                                        this.setState({newStatus: 1})
                                                                                    }
                                                                                    if(e.target.value === 'bg-info'){
                                                                                        this.setState({newStatus: 3})
                                                                                    }
                                                                                    if(e.target.value === 'bg-light'){
                                                                                        this.setState({newStatus:4})
                                                                                    }
                                                                                    if(e.target.value === 'bg-warning'){
                                                                                        this.setState({newStatus: 0})
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {(this.state.event.status !== 2 && this.state.event.status !== 4) && <option value="bg-warning">Pendente</option>}
                                                                                {(this.state.event.status !== 2 && this.state.event.status !== 4) && <option value="bg-primary">Confirmado</option>}
                                                                                {(this.state.event.status !== 2 && this.state.event.status !== 4) && <option value="bg-info">Parceria</option>}
                                                                                {this.state.event.status !== 4 && <option value="bg-danger">Cancelado</option>}
                                                                                {this.state.event.status !== 2 && <option value="bg-light">Concluído</option>}
                                                                            </AvField></div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <div className="text-end">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-light me-2"
                                                                            onClick={this.toggle}
                                                                        >
                                                                            Fechar
                                                                        </button>
                                                                        {localStorage.getItem('level') !== '2' &&
                                                                        !!isEdit && (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger me-2"
                                                                                onClick={() =>
                                                                                    this.setState({ deleteModal: true })
                                                                                }
                                                                            >
                                                                                Excluir
                                                                            </button>
                                                                        )}
                                                                        {localStorage.getItem('level') !== '2' &&
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-success save-event"
                                                                            onClick={() => {
                                                                                const data = {
                                                                                    status: this.state.newStatus,
                                                                                    user_id: localStorage.getItem('id'),
                                                                                    access: localStorage.getItem('access'),
                                                                                    property_id: this.state.event.property_id
                                                                                }
                                                                                Axios.post(process.env.REACT_APP_API + '/api/auth/scheduling-update/' + this.state.event.id, data,
                                                                                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                                                 .then(response => 
                                                                                     {setTimeout(() => {
                                                                                      window.location.reload()
                                                                                    }, 500);}
                                                                                   )
                                                                            }}
                                                                        >
                                                                            Salvar
                                                                        </button>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                        :
                                                        <AvForm>
                                                            <Row form>


                                                                {localStorage.getItem('access') === 'A' &&
                                                                    <>
                                                                        <Col xs={12} style={{marginBottom:'20px'}}>
                                                                            <Label className="form-label">Corretor</Label>
                                                                            <Select
                                                                            value={selectedBroker}
                                                                            placeholder="Selecione"
                                                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                                                            onChange={(e) => this.setState({ selectedBroker: e})}
                                                                            options={selectOptionsBroker}
                                                                            classNamePrefix="select2-selection"
                                                                            />
                                                                        </Col>
                                                                        <Col xs={12} style={{marginBottom:'20px'}}>
                                                                            <Label className="form-label">Imobiliária</Label>
                                                                            <Select
                                                                            value={selectedRealEstate}
                                                                            placeholder="Selecione"
                                                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                                                            onChange={(e) => this.setState({ selectedRealEstate: e })}
                                                                            options={selectOptionsRealEstate}
                                                                            classNamePrefix="select2-selection"
                                                                            />
                                                                        </Col>
                                                                    </>
                                                                }

                                                                <Col className="col-12 mb-3">
                                                                    <div className="boxNewEvent"><b><p>Novo agendamento: {this.state.newEventDate}</p></b></div>
                                                                </Col>
                                                                <Col xs={12} style={{marginBottom:'20px'}}>
                                                                    <Label>Imóvel:</Label>
                                                                    <Select
                                                                    value={this.state.imovel}
                                                                    placeholder="Selecione"
                                                                    noOptionsMessage={(inputValue) => `Sem resultados`}
                                                                    onChange={(e) => {
                                                                        this.setState({ imovel: e })
                                                                        this.setState({newEventPropertyId: e.value})
                                                                    }}
                                                                    options={this.state.imoveis}
                                                                    classNamePrefix="select2-selection"
                                                                    />
                                                                </Col>
                                                                <Col xs={12} className="hourContainer">
                                                                    <Label>Horário:</Label>
                                                                    <div className={"hour-container" + " " + "active"} style={{minHeight:'40px'}}>
                                                                        {this.state.hoursOptions}
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <Label>Nome do cliente:</Label>
                                                                    <AvField
                                                                        name="clienteName"
                                                                        type="text"
                                                                        errorMessage="Campo obrigatório"
                                                                        validate={{
                                                                            required: { value: true },
                                                                        }}
                                                                        value={this.state.newEventClientName}
                                                                        onChange={(e) => this.setState({newEventClientName: e.target.value})}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{marginTop:'20px'}}>
                                                                <Col xs={12}>
                                                                    <Label>Tipo da visita:</Label>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <Row className="visitTypeBtnContainer">
                                                                        <Col xs={6}>
                                                                            <div className="visitTypeBtn" onClick={() => {
                                                                                this.setState({newEventType : 1})
                                                                            }}>
                                                                                Visita no local
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div className="visitTypeBtn" onClick={() => {
                                                                                this.setState({newEventType : 0})
                                                                            }}>
                                                                                Visita videochamada
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{marginTop:'20px'}}>
                                                                <Col>
                                                                    <div className="text-end">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-light me-2"
                                                                            onClick={this.toggle}
                                                                        >
                                                                            Cancelar
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-success save-event"
                                                                            onClick={this.createNewEvent}
                                                                        >
                                                                            Agendar
                                                                    </button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                        }
                                                    </ModalBody>
                                                </Modal>

                                                <Modal
                                                    isOpen={this.state.modalcategory}
                                                    toggle={this.togglecategory}
                                                    className={this.props.className}
                                                >
                                                    <ModalHeader toggle={this.togglecategory} tag="h4">
                                                        Nova visita
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        <AvForm>
                                                            <Row form>

                                                                {localStorage.getItem('access') === 'A' &&
                                                                    <>
                                                                        <Col xs={12} style={{marginBottom:'20px'}}>
                                                                            <Label className="form-label">Corretor</Label>
                                                                            <Select
                                                                            value={selectedBroker}
                                                                            placeholder="Selecione"
                                                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                                                            onChange={(e) => this.setState({ selectedBroker: e})}
                                                                            options={selectOptionsBroker}
                                                                            classNamePrefix="select2-selection"
                                                                            />
                                                                        </Col>
                                                                        <Col xs={12} style={{marginBottom:'20px'}}>
                                                                            <Label className="form-label">Imobiliária</Label>
                                                                            <Select
                                                                            value={selectedRealEstate}
                                                                            placeholder="Selecione"
                                                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                                                            onChange={(e) => this.setState({ selectedRealEstate: e })}
                                                                            options={selectOptionsRealEstate}
                                                                            classNamePrefix="select2-selection"
                                                                            />
                                                                        </Col>
                                                                    </>
                                                                }


                                                                <Col xs={12} style={{marginBottom:'20px'}}>
                                                                    <Label>Imóvel:</Label>
                                                                    <Select
                                                                    value={this.state.imovel}
                                                                    placeholder="Selecione"
                                                                    noOptionsMessage={(inputValue) => `Sem resultados`}
                                                                    onChange={(e) => {
                                                                        this.setState({ imovel: e })
                                                                        this.setState({newEventPropertyId: e.value})
                                                                    }}
                                                                    options={this.state.imoveis}
                                                                    classNamePrefix="select2-selection"
                                                                    />
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <FormGroup className="mb-4">
                                                                        <Label>Data</Label>
                                                                        <InputGroup>
                                                                        <Flatpickr
                                                                            className="form-control d-block"
                                                                            placeholder="Selecione um dia"
                                                                            options={{
                                                                            altInput: true,
                                                                            altFormat: "j, F, Y",
                                                                            dateFormat: "Y-m-d",
                                                                            }}
                                                                            locale= {locale}
                                                                            onChange={(e) => {
                                                                                this.setState({ newEventDateFormated: moment(e.toString()).format('YYYY-MM-DD')})
                                                                                this.setState({ dataSelecionada: moment(e.toString()).format('DD')})
                                                                                this.setState({ mesSelecionado: moment(e.toString()).format('MM')})
                                                                                setTimeout(() => {
                                                                                    this.generateHoursOption()
                                                                                  }, 300);
                                                                            }}
                                                                        />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xs={12} className="hourContainer">
                                                                    <Label>Horário:</Label>
                                                                    <div className={"hour-container" + " " + "active"} style={{minHeight:'40px'}}>
                                                                        {this.state.hoursOptions}
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <Label>Nome do cliente:</Label>
                                                                    <AvField
                                                                        name="clienteName"
                                                                        type="text"
                                                                        errorMessage="Campo obrigatório"
                                                                        validate={{
                                                                            required: { value: true },
                                                                        }}
                                                                        value={this.state.newEventClientName}
                                                                        onChange={(e) => this.setState({newEventClientName: e.target.value})}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{marginTop:'20px'}}>
                                                                <Col xs={12}>
                                                                    <Label>Tipo da visita:</Label>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <Row className="visitTypeBtnContainer">
                                                                        <Col xs={6}>
                                                                            <div className="visitTypeBtn" onClick={() => {
                                                                                this.setState({newEventType : 1})
                                                                            }}>
                                                                                Visita no local
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div className="visitTypeBtn" onClick={() => {
                                                                                this.setState({newEventType : 0})
                                                                            }}>
                                                                                Visita videochamada
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{marginTop:'20px'}}>
                                                                <Col>
                                                                    <div className="text-end">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-light me-2"
                                                                            onClick={this.toggle}
                                                                        >
                                                                            Cancelar
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-success save-event"
                                                                            onClick={this.createNewEvent}
                                                                        >
                                                                            Agendar
                                                                    </button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                    </ModalBody>
                                                </Modal>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    <div id="load" className="load">
                        <Spinner className="me-2" color="success" />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

CatalogProperties.propTypes = {
    categories: PropTypes.array,
    className: PropTypes.string,
    events: PropTypes.array,
    onAddNewEvent: PropTypes.func,
    onDeleteEvent: PropTypes.func,
    onGetCategories: PropTypes.func,
    onGetEvents: PropTypes.func,
    onUpdateEvent: PropTypes.func
}

const mapStateToProps = ({ Calendar }) => ({
    events: Calendar.events,
    categories: Calendar.categories,
})

const mapDispatchToProps = dispatch => ({
    onGetEvents: () => dispatch(getEvents()),
    onGetCategories: () => dispatch(getCategories()),
    onAddNewEvent: event => dispatch(addNewEvent(event)),
    onUpdateEvent: event => dispatch(updateEvent(event)),
    onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CatalogProperties)