import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Button, Row, Input, Badge, Spinner } from "reactstrap";
import { Link } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Axios from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';

class OnLineUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      userOnLineControl:[],
      userOnLineList:[],
      filtleredItems:[],
      userActive:[],
    }
  }

  componentDidMount(){
    document.getElementById('loadUserLoginOn').classList.add('active')
    Axios.get(process.env.REACT_APP_API + '/api/auth/dashboard/sessions',
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      for(var i = 0; i < response.data.sessions.length; i++){
        if(response.data.sessions[i]?.broker_id && !this.state.userOnLineControl.includes(response.data.sessions[i]?.broker?.name)){
          this.state.userOnLineControl.push(response.data.sessions[i]?.broker?.name)
          this.state.userOnLineList.push(response.data.sessions[i])
        }
         if(response.data.sessions[i]?.real_estate_id && !this.state.userOnLineControl.includes(response.data.sessions[i]?.real_estate?.name)){
          this.state.userOnLineControl.push(response.data.sessions[i]?.real_estate?.name)
          this.state.userOnLineList.push(response.data.sessions[i])
         }
         if(response.data.sessions[i]?.owner_id && !this.state.userOnLineControl.includes(response.data.sessions[i]?.owner?.name)){
          this.state.userOnLineControl.push(response.data.sessions[i]?.owner?.name)
          this.state.userOnLineList.push(response.data.sessions[i])
         }
        //  if(response.data.sessions[i]?.user_id && !userOnLineControl.includes(response.data.sessions[i]?.user?.name)){
        //    userOnLineControl.push(response.data.sessions[i]?.user?.name)
        //    userOnLineList.push(response.data.sessions[i])
        //  }
      }
      this.setState({userActive: this.state.userOnLineList})
      document.getElementById('loadUserLoginOn').classList.remove('active')
    })
}

  render() {
    const data = {
      columns: [
        {
          dataField: "profile_photo",
          text: "Foto"
        },
        {
          dataField: "name",
          text: "Nome"
        },
        {
          dataField: "premium",
          text: "Plano"
        },
        {
          dataField: "type",
          text: "Tipo"
        },
      ],
    };

    const items = []

    this.state.userActive.map((user, index) => {
        items.push({
          type: user?.broker_id ? <Badge className="bg-success me-1">Corretor</Badge> : user?.real_estate_id ? <Badge className="bg-success me-1">Imobiliária</Badge> : <Badge className="bg-info me-1">Proprietário</Badge>,
          premium: (user?.broker?.premium === 1 || user?.real_estate?.premium === 1) ? <Badge className="bg-success me-1">Premium</Badge> : (user?.broker?.premium === 2 || user?.real_estate?.premium === 2) ? <Badge className="bg-info me-1">Start</Badge> : <Badge className="bg-dark me-1">Free</Badge>,
          name: <a href={`/editar-perfil?id=${user?.broker_id ? user?.broker_id : user?.real_estate_id ? user?.real_estate_id : '/'}&type=${user?.broker_id ? 'C' : 'I'}`}><div>{user?.broker_id ? user?.broker?.name : user?.real_estate_id ? user?.real_estate?.name : user?.owner?.name}<br></br><small className="text-muted">{user?.broker_id ? user?.broker?.city : user?.real_estate_id ? user?.real_estate?.city : user?.owner?.city}</small></div></a>,
          nameFilter:user?.broker_id ? user?.broker?.name : user?.real_estate_id ? user?.real_estate?.name : user?.owner?.name ,
          profile_photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + (user?.broker_id ? user?.broker?.profile_photo : user?.real_estate_id ? user?.real_estate?.profile_photo : user?.owner?.profile_photo) + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'40px', height: '40px', borderRadius:'50%'}}></div>,
        })
    })

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: 'Todos' + " (" + items.length + ")", value: items.length
        }]
    };


    return (
      <React.Fragment>
        <Col lg={6}>
          <Card  style={{ minHeight: "560px", position:'relative' }}>
            <CardBody>
              {/* <Dropdown isOpen={this.state.menu} toggle={() => this.setState({ menu: !this.state.menu })} className="float-end">
                <DropdownToggle tag="i" className="arrow-none card-drop">
                  <i className="mdi mdi-dots-vertical"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                  <DropdownItem>Sales Report</DropdownItem>

                  <DropdownItem>Export Report</DropdownItem>

                  <DropdownItem>Profit</DropdownItem>

                  <DropdownItem>Action</DropdownItem>
                </DropdownMenu>
              </Dropdown> */}

              
              <Row style={{justifyContent:'flex-end', marginBottom: '10px', alignItems:'center'}}>
                <Col>
                  <h4 className="card-title">Usuários OnLine agora ({items.length})</h4>
                </Col>
                <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                  <Input
                  placeholder="Buscar por nome"
                  onChange={(e) => this.setState({filtleredItems: items.filter(contrato => contrato?.nameFilter?.toLowerCase().includes(e.target.value.toLowerCase()))})}
                  >
                  </Input>
                </Col>
              </Row>
              <div className="table-responsive">

              <BootstrapTable
                keyField='id'
                data={this.state.filtleredItems.length > 0 ? this.state.filtleredItems : items}
                columns={data.columns}
                // expandRow={expandRow}
                pagination={paginationFactory(options)}
                // selectRow={selectRow}
              />
              </div>

            </CardBody>
            <div id="loadUserLoginOn" className="load" style={{position:'absolute', zIndex:'99'}}>
                <Spinner className="me-2" color="success" />
            </div>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default OnLineUsers;