import './style.scss'
import { Button, Card, Col, Container, Row } from 'reactstrap';


function ImovelCardFS({imovel, premium, handleImovelClick, isSelected}) {

  const handleClick = () => {
    handleImovelClick(imovel);
  };

  const date = new Date()
  date.setDate(date.getDate() - 7)

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  
  return (
    <div id='ImovelCardFS'>
      <Card 
      className={`ImovelCard ${isSelected ? 'selected' : ''}`} 
      style={{ boxShadow:'0 0 8px rgba(0,0,0,0.3)' }} 
      onClick={handleClick}
      >
      <div className={isSelected ? 'cardSelect' : ''}>
        {
          isSelected && <p>Selecionado</p>
        }
      </div>
        <Container>
          <Row className="slider-row">
            <Row className="comissionContainer">
              {imovel.sell_contract_type === 'FS Imob (antigo)' &&
                    <>
                      <Col xs={3} className="until">Até</Col>
                      <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
                      <Col xs={6} className="comission">
                        <span className="fs">Contrato FS</span>
                      </Col>
                    </>
                  }
                  {imovel.sell_contract_type === 'FS Parc' &&
                    <>
                      <Col xs={12} className="comission">
                        <span className="parc">Parceria 50/50</span>
                      </Col>
                    </>
                    
                  }
                  {imovel.sell_contract_type === 'FS Parc (sc)' &&
                    <>
                      <Col xs={12} className="comission">
                        <span className="parc">Parceria 50/50</span>
                      </Col>
                    </>
                    
                  }
                  {(imovel.sell_contract_type === 'FS Corr'
                  || imovel.sell_contract_type === 'FS Imob') &&
                    <>
                      <Col xs={3} className="until">Até</Col>
                      <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
                      <Col xs={6} className="comission">
                        <span className="fs">Contrato FS</span>
                      </Col>
                    </>
                    
                  }
                  {imovel.sell_contract_type === 'FS Prop' &&
                    <>
                      <Col xs={3} className="until">Até</Col>
                      <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
                      <Col xs={6} className="comission">
                        <span className="fs">Contrato FS</span>
                      </Col>
                    </>
                  }
            </Row>
            <img  className='fotos-imoveis' src={imovel.photos} alt={imovel.title}></img>
          </Row>
          <Row>
            <div className='ap'>
              <p>{imovel?.sku}</p>
              {premium === 1 &&
                <h5 style={{fontSize:'12px'}}>
                  {
                    imovel?.address_properties?.enterprise && imovel?.address_properties?.enterprise !== "null" ? 
                      toTitleCase(imovel?.address_properties?.enterprise)
                      : <></>
                  }
                </h5>
              }
            </div>
          
            <h3 style={{height:'41px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{imovel?.title}</h3>
            <p><i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i>{imovel?.address_properties?.city}</p>
          </Row>
          <Row>
            <Col xs={10} style={{padding:'0'}}>
              <Row className="skills">
                <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number}</p></Col>
                <Col><i className="ri-car-line"></i><p>{imovel?.parking_spots}</p></Col>
                <Col><i className="ri-ruler-line"></i><p>{
                      (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                      (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                      (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                      }m²</p></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="line"></div>
          </Row>
          <Row>
            <Col xs={10} style={{padding:'0 10px 0 0'}}>
              {imovel?.sell_price && imovel?.sell_price !== 0 ?
                <div className="offer-container">
                  <div className="old-price"><span>R${(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                  <div className="offer-price"><small>R$</small><span>{(imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                </div>
              :
                <div className="offer-container">
                  <div className="offer-price"><small>R$</small><span>{(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                </div>
              }
            </Col>
          </Row>
        </Container>   
      </Card>
  </div>
  )
}

export default ImovelCardFS;