import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'
import Select from "react-select";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_editProperties.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
require('moment/locale/pt.js');

class UsersOrigin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasons: [],
      filterReasons: [],
      reasonsInactive: [],
      filterReasonsInactive: [],
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Origem de usuários", link: "#" },
    ],
    }
  }


  componentDidMount(){

    document.getElementById('load').classList.add('active')
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        if(localStorage.getItem('access') === 'A'){

          Axios.get(process.env.REACT_APP_API + '/api/auth/feedback-users',
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
              this.setState({reasons: resp.data})
              console.log(resp.data)
              document.getElementById('load').classList.remove('active')
           })

          }else{
            setTimeout(() => {
              window.open("/dashboard", '_parent')
            }, 300);
          }
    })
    .catch(response =>{
      localStorage.removeItem("history_url")
      setTimeout(() => {
        window.open("/login", '_parent')
      }, 300);
    })
  }



  render() {
     const data = {
       columns: [
         {
           dataField: "id",
           text: "Id"
         },
         {
          dataField: "date",
          text: "Data"
        },
        {
          dataField: "reason",
          text: "Origem"
        },
        {
          dataField: "user",
          text: "Usuário"
        },
        {
          dataField: "city",
          text: "Cidade"
        },
       ],
     };

    const {reasons, filterReasons} = this.state;

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos resultados', value: this.state.reasonsObject?.length
        }]

    };

    // const selectRow = {
    //   mode: 'checkbox',
    //   clickToSelect: true
    // };

    const reasonsObject = []

    {reasons?.sort(function(a,b){
       return new Date(b.created_at) - new Date(a.created_at)}).map(reason => {
       const novoReason = {
         id: reason?.id,
         date: moment(reason?.created_at).format("DD/MM/YYYY"),
         reason: reason?.response,
         user: reason?.user?.name,
         city: reason?.user?.city,
       }
        reasonsObject.push(novoReason);
     })}


    return (
        <div className="page-content" id="deleteImoveisAdm">
        <Container fluid={true}>
          <Breadcrumbs title="Origem de usuários" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  
                  <Row style={{justifyContent:'space-between', marginBottom: '20px'}}>
                    <Col className="col-auto searchOutletListContainer" style={{display:'flex', flexDirection:'row'}}>
                      <Input
                      className="searchOutletList"
                      style={{minWidth:'300px'}}
                      placeholder="Buscar resposta"
                      onChange={(e) => {
                        const filterReasons = reasonsObject.filter(
                          (reason) =>
                            reason?.reason?.toLowerCase().includes(e.target.value)
                        );
                      
                        this.setState({ filterReasons });
                      }}></Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                   <div style={{marginBottom:'15px'}}><h3 style={{fontSize:'16px'}}>Origem de usuários</h3></div>
                  <BootstrapTable
                    keyField='id'
                    data={filterReasons.length > 0 ? filterReasons : reasonsObject}
                    columns={data.columns}
                    // expandRow={expandRow}
                    pagination={paginationFactory(options)}
                    // selectRow={false}
                  />
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default UsersOrigin;