import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import './styles.scss';
import Axios from 'axios';

export const IndicateLink = () => {

    const [success, setSuccess] = useState(false)
    const [success2, setSuccess2] = useState(false)
    const [recurrence, setRecurrence] = useState('')
    const [product, setProduct] = useState('')
    const [productsList, setProductsList] = useState([])

    const link = `https://fastsaleimoveis.com.br/registre-se?aftype=` + localStorage.getItem('access') + '&afid=' + localStorage.getItem('id') + '&aforigin=comercial'
    const checkoutLink = `https://fastsaleimoveis.com.br/novo-checkout?aftype=` + localStorage.getItem('access') + '&afid=' + localStorage.getItem('id') + '&aforigin=comercial-checkout&product=' + product + '&recurrence=' + recurrence

    const copyToClipboard = (e) => {
        if(e === 'check'){
            navigator.clipboard.writeText(checkoutLink)
            .then(() => {
                setSuccess2(true)
            })
            .catch((err) => {
                console.error('Falha ao copiar: ', err);
            });
        }
        if(e === 'register'){
            navigator.clipboard.writeText(link)
            .then(() => {
                setSuccess(true)
            })
            .catch((err) => {
                console.error('Falha ao copiar: ', err);
            });
        }
    };

    useEffect(() => {
        Axios.get(process.env.REACT_APP_API + '/api/public/list-products')
        .then(response => {
          setProductsList(response.data)
        })
    }, [])

    return(
        <Card>
            <CardBody id='indicate-generator'>
                <Row>
                    <Col md={6}>
                        <label>Indicação de cadastro</label>
                        <div className="indicate-link-container">
                            <input value={link} disabled></input>
                            <button onClick={() => copyToClipboard('register')}><i className="ri-file-copy-line"></i></button>
                        </div>
                        {success &&
                            <small>Link copiado para a área de transferência!</small>
                        }
                    </Col>
                    <Col className="checkout-generator-link" md={6}>
                        <label>Indicação de plano (Checkout)</label>
                        <Row>
                            <Col md={6}>
                                <select name="origin" className="form-control" onChange={(e) => setProduct(e.target.value)}>
                                    <option value=''>Selecione um plano</option>
                                    {productsList.filter(items => items.name !== 'Free').map((item, index) => (
                                        <option key={index} value={item.id}>{item.name} - 
                                            {item.user_type === 'C' && ' Corretor'}
                                            {item.user_type === 'I' && ' Imobiliária'}
                                            {item.user_type === 'T' && ' Construtora'}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                            <Col md={6}>
                                <select name="origin" className="form-control" onChange={(e) => setRecurrence(e.target.value)}>
                                    <option value=''>Selecione a recorrência</option>
                                    <option value='month'>Mensal</option>
                                    <option value='year'>Anual</option>
                                </select>
                            </Col>
                        </Row>
                        {product !== '' && recurrence !== '' &&
                            <>
                                <div className="indicate-link-container">
                                    <input value={checkoutLink} disabled></input>
                                    <button onClick={() => copyToClipboard('check')}><i className="ri-file-copy-line"></i></button>
                                </div>
                                {success2 &&
                                    <small>Link copiado para a área de transferência!</small>
                                }
                            </>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}
