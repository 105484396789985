import { AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import { Button, Card, CardBody, Col, Container, Input, Label, Row, Spinner } from "reactstrap";
import CurrencyInput from "../../components/Inputs/CurrencyInput";
import SweetAlert from "react-bootstrap-sweetalert";

const id = window.location.search.replace('?id=', '')

class EnterprisePayment extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      paymentCondition: [],
      payment_items:[],
      toggleSwitch:true,
      }
      this.updateEnterprise = this.updateEnterprise.bind(this);
    }

    addInput = () => {
        this.setState({toggleSwitch:true})
        const newInput = { type: '', type_value: 'percent', value: '', currencyValue: '', parcels: '', observation: '' };
        this.setState((prevState) => ({
          paymentCondition: [...prevState.paymentCondition, newInput],
        }));
      };
      
      updateInput = (index, field, value) => {
        this.setState((prevState) => {
          const updatedInputs = [...prevState.paymentCondition];
          updatedInputs[index][field] = value;
          return { paymentCondition: updatedInputs };
        });
      };

    componentDidMount(){
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            this.setState({enterprise: response.data})

            Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-payment-conditions/' + id,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              this.setState({payment_items: response.data})
            })

         })
        })
        .catch(response =>{
            setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
            })
    }

      updateEnterprise(event) {
        if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
            this.setState({error_auth:true})
        }else{
        document.getElementById('load').classList.add('active')

        const dataPaymentCondition = new FormData();

        for(var i = 0; this.state.paymentCondition.length > i; i++){
            dataPaymentCondition.append(`condition[${i}][type]`, this.state.paymentCondition[i].type)
            dataPaymentCondition.append(`condition[${i}][type_value]`, this.state.paymentCondition[i].type_value)
            if(this.state.paymentCondition[i].value !== ''){
                dataPaymentCondition.append(`condition[${i}][value]`, this.state.paymentCondition[i].value)
            }
            if(this.state.paymentCondition[i].currencyValue !== ''){
                dataPaymentCondition.append(`condition[${i}][currency_value]`, parseInt(this.state.paymentCondition[i].currencyValue.replace('R$ ', '').replace(',', '').replace(/\./g,'')))
            }
            dataPaymentCondition.append(`condition[${i}][parcels]`, this.state.paymentCondition[i].parcels)
            dataPaymentCondition.append(`condition[${i}][observation]`, this.state.paymentCondition[i].observation)
            dataPaymentCondition.append(`condition[${i}][property_enterprise_id]`, id)
        }

        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-properties-payment', dataPaymentCondition, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
            document.getElementById('load').classList.remove('active')

            this.setState({paymentCondition : []})

            Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-payment-conditions/' + id,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              this.setState({payment_items: response.data})
            })
        })
    }
   }

handleInputChange = (e, index, field) => {
    const { name, value } = e.target || e;
    this.setState((prevState) => {
      const updatedUnits = [...prevState.payment_items];
      updatedUnits[index][field] = value;
      return { payment_items: updatedUnits };
    });
  };

   handleClick = (unit) => {

    const intValue = unit.type_value !== 'currency' ? null : parseInt(unit.currency_value.replace(/[^\d]/g, ''));
    const updatedUnit = { ...unit, currency_value: intValue };

    const updatedUnits = this.state.payment_items.map((u) =>
        u.id === unit.id ? { ...u, isLoading: true } : u
    );
    this.setState({ payment_items: updatedUnits });

    Axios.post(
        process.env.REACT_APP_API +
            '/api/auth/enterprise/enterprise-properties-payment/' +
            unit.id,
            updatedUnit,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
        .finally(() => {
            const updatedUnits = this.state.payment_items.map((u) =>
                u.id === unit.id ? { ...u, isLoading: false } : u
            );
            this.setState({ payment_items: updatedUnits });
        });
};

toggleTypeValue = (index) => {
    this.setState((prevState) => {
      const updatedItems = [...prevState.payment_items];
      const currentItem = updatedItems[index];
  
      // Alternar entre 'percent' e 'currency' com base no valor atual
      currentItem.type_value = currentItem.type_value === 'percent' ? 'currency' : 'percent';
  
      return { payment_items: updatedItems };
    });
  };

    render(){
        const {enterprise } = this.state;

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                            <Container style={{padding:'20px 20px 0', marginBottom: '15px'}}>
                                                <Row style={{margin:'10px 0'}}>
                                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Características da condição de pagamento</h5></Col>
                                                </Row>
                                                {this.state.payment_items.map((item, index) => (
                                                        <Row key={index}>
                                                            <Col className="col-auto" style={{paddingTop:'25px'}}>
                                                                {item.type === 'entrance' && <i className="ri-coins-line" style={{fontSize:'25px'}}></i>}
                                                                {item.type === 'reinforcement_6' && <i className="ri-exchange-dollar-line" style={{fontSize:'25px'}}></i>}
                                                                {item.type === 'reinforcement_12' && <i className="ri-exchange-dollar-line" style={{fontSize:'25px'}}></i>}
                                                                {item.type === 'parcels' && <i className="ri-currency-line" style={{fontSize:'25px'}}></i>}
                                                                {item.type === 'keys' && <i className="ri-key-2-line" style={{fontSize:'25px'}}></i>}
                                                            </Col>
                                                            <Col md={2}>
                                                            <Label>Tipo de condição</Label>
                                                            <select className="form-control" value={item.type} onChange={(e) => this.handleInputChange(e, index, 'type')}>
                                                                <option value={null}>Selecione</option>
                                                                <option value="entrance">Entrada</option>
                                                                <option value="reinforcement_6">Reforço Semestral</option>
                                                                <option value="reinforcement_12">Reforço Anual</option>
                                                                <option value="parcels">Parcelamento</option>
                                                                <option value="keys">Entrega de chaves</option>
                                                            </select>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <Label>{item.type_value === 'percent' ? 'Porcentagem' : 'Valor'}</Label>
                                                                <div className="custom-toggle" onClick={() => this.toggleTypeValue(index)}>
                                                                    <div className={`active-toggle ${item.type_value}`}></div>
                                                                </div>
                                                            </Col>
                                                            <Col md={2}>
                                                            
                                                            {item.type_value === 'currency' ?
                                                                <>
                                                                <Label>Valor</Label>
                                                                <CurrencyInput defaultValue={item.currency_value} onChange={(e) => this.handleInputChange(e, index, 'currency_value')}/>
                                                                </>
                                                            :
                                                            <>
                                                                <Label>Porcentagem</Label>
                                                                <Input
                                                                type="number"
                                                                placeholder=""
                                                                value={item.value}
                                                                onChange={(e) => this.handleInputChange(e, index, 'value')}
                                                                />
                                                            </>
                                                            }
                                                            </Col>
                                                            <Col md={2}>
                                                            <Label>Parcelas</Label>
                                                            <Input
                                                                type="number"
                                                                placeholder="Nº de parcelas"
                                                                value={item.parcels}
                                                                onChange={(e) => this.handleInputChange(e, index, 'parcels')}
                                                            />
                                                            </Col>
                                                            <Col md={2}>
                                                            <Label>Observações</Label>
                                                            <Input
                                                                type="text"
                                                                placeholder="Observações"
                                                                value={item.observation}
                                                                onChange={(e) => this.handleInputChange(e, index, 'observation')}
                                                            />
                                                            </Col>
                                                            <Col className="col-auto" style={{display:'flex', alignItems:'center'}}>
                                                                {item.isLoading ? (
                                                                    <Spinner className="me-2" color="primary" />
                                                                ) : (
                                                                    <Button
                                                                        onClick={() => {
                                                                            if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                                                                                this.setState({error_auth:true})
                                                                            }else{
                                                                                this.handleClick(item)
                                                                            }
                                                                        }}
                                                                        disabled={item.isLoading}
                                                                    >
                                                                        Salvar
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <div style={{
                                                                    height:'100%',
                                                                    width:'100%',
                                                                    display:'flex',
                                                                    alignItems:'center',
                                                                    justifyContent:'center'
                                                                }}>
                                                                    <i
                                                                    onClick={() => {
                                                                        if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                                                                            this.setState({error_auth:true})
                                                                        }else{
                                                                            Axios.post(
                                                                                process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-type-pay/delete/' + item.id,
                                                                                {},
                                                                                { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
                                                                                .then(response => {
                                                                                    Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-payment-conditions/' + id,
                                                                                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                                                    .then(response => {
                                                                                    this.setState({payment_items: response.data})
                                                                                    })
                                                                                })
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        fontSize:'20px',
                                                                        backgroundColor:'#cdcdcd',
                                                                        borderRadius: '5px',
                                                                        padding:'2px 5px',
                                                                        cursor:'pointer'
                                                                    }}
                                                                    className="ri-delete-bin-line"></i>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )) 
                                                }
                                                <Row style={{marginTop:'20px'}}>
                                                {this.state.paymentCondition.map((input, index) => (
                                                    <>
                                                    <Col className="col-auto" style={{paddingTop:'25px', minWidth:'50px'}}>
                                                        {input.type === 'entrance' && <i className="ri-coins-line" style={{fontSize:'25px'}}></i>}
                                                        {input.type === 'reinforcement_6' && <i className="ri-exchange-dollar-line" style={{fontSize:'25px'}}></i>}
                                                        {input.type === 'reinforcement_12' && <i className="ri-exchange-dollar-line" style={{fontSize:'25px'}}></i>}
                                                        {input.type === 'parcels' && <i className="ri-currency-line" style={{fontSize:'25px'}}></i>}
                                                        {input.type === 'keys' && <i className="ri-key-2-line" style={{fontSize:'25px'}}></i>}
                                                    </Col>
                                                    <Col md={2}>
                                                    <Label>Tipo de condição</Label>
                                                    <select className="form-control" value={input.type} onChange={(e) => this.updateInput(index, 'type', e.target.value)}>
                                                        <option value={null}>Selecione</option>
                                                        <option value="entrance">Entrada</option>
                                                        <option value="reinforcement_6">Reforço Semestral</option>
                                                        <option value="reinforcement_12">Reforço Anual</option>
                                                        <option value="parcels">Parcelamento</option>
                                                        <option value="keys">Entrega de chaves</option>
                                                    </select>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Label>{this.state.toggleSwitch ? 'Porcentagem' : 'Valor'}</Label>
                                                        <div className="custom-toggle" onClick={() => {
                                                            this.setState(prevState => ({
                                                                toggleSwitch: !prevState.toggleSwitch
                                                            }), () => {
                                                                this.updateInput(index, 'type_value', this.state.toggleSwitch ? 'percent' : 'currency');
                                                            })}}>
                                                            <div className={`active-toggle ${this.state.toggleSwitch ? 'percent' : 'currency'}`}></div>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                    
                                                    {input.type_value === 'currency' ?
                                                        <>
                                                        <Label>Valor</Label>
                                                        <CurrencyInput defaultValue={input.currencyValue} onChange={(e) => this.updateInput(index, 'currencyValue', e.target.value)}/>
                                                        </>
                                                    :
                                                    <>
                                                        <Label>Porcentagem</Label>
                                                        <Input
                                                        type="number"
                                                        placeholder=""
                                                        value={input.value}
                                                        onChange={(e) => this.updateInput(index, 'value', e.target.value)}
                                                        />
                                                    </>
                                                    }
                                                    </Col>
                                                    <Col md={2}>
                                                    <Label>Parcelas</Label>
                                                    <Input
                                                        type="number"
                                                        placeholder="Nº de parcelas"
                                                        value={input.parcels}
                                                        onChange={(e) => this.updateInput(index, 'parcels', e.target.value)}
                                                    />
                                                    </Col>
                                                    <Col md={3}>
                                                    <Label>Observações</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Observações"
                                                        value={input.observation}
                                                        onChange={(e) => this.updateInput(index, 'observation', e.target.value)}
                                                    />
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button style={{marginTop:'20px'}} className="btn-info" onClick={this.updateEnterprise}>Criar</Button>
                                                    </Col>
                                                    </>
                                                    ))}
                                                    {this.state.paymentCondition.length === 0 &&
                                                        <Col xs={12} style={{display:'flex', justifyContent: 'flex-start', margin:'0 0 20px'}}>
                                                            <Button type="button" onClick={() => {
                                                                if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                                                                    this.setState({error_auth:true})
                                                                }else{
                                                                    this.addInput()
                                                                }
                                                                }}>
                                                                Adicionar nova característica
                                                            </Button>
                                                        </Col>
                                                    }
                                                </Row>
                                            </Container>
                                            <Container>
                                                <Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                                    <Col className="col-auto">
                                                        <Button className="btn-dark" onClick={() => {
                                                            setTimeout(() => {
                                                                window.open('./../perfil-empreendimento?id=' + id, '_parent')
                                                            }, 100);
                                                        }}>Voltar</Button>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={() => {
                                                            setTimeout(() => {
                                                                window.open('./../perfil-empreendimento?id=' + id, '_parent')
                                                            }, 100);
                                                        }}>Salvar</Button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                {this.state.error_auth ? (
                <SweetAlert
                    error
                    title='Ops'
                    onConfirm={() => this.setState({ error_auth: false })}
                >
                    Você não tem permissão para esta ação.
                </SweetAlert>
            ) : null}
            </div>
        )
    }

}

export default EnterprisePayment;