import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import './styles.scss';
import { useState } from 'react';
import Logo from "../../../assets/images/logo-dark.png";
import { Button, Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import CepInput from './components/form/cepInput';
import SweetAlert from 'react-bootstrap-sweetalert';
import CpfInput from './components/form/cpfInput';
import CreciInput from './components/form/creciInput';
import Axios from 'axios';
import CnpjInput from './components/form/cnpjInput';

export const RegisterStep2 = ({userData, newCallback}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const user = userData?.userId || searchParams.get('user');
  const type = userData?.userType || searchParams.get('type');
  const token = userData?.token || searchParams.get('token');

  const [document, setDocument] = useState('')
  const [creci, setCreci] = useState('')
  const [cep, setCep] = useState('')
  const [origin, setOrigin] = useState('')
  const [otherOrigin, setOtherOrigin] = useState('')
  const [address, setAddress] = useState([])
  const [loading, setLoading] = useState(false)

  const [registerError, setRegisterError] = useState(false)
  
  const [creciIsExist, setCreciIsExist] = useState('0')
  const [cpfIsExist, setCpfIsExist] = useState('0')
  const [cnpjIsExist, setCnpjIsExist] = useState('0')

  const [owner, setOwner] = useState('')
  const [social, setSocial] = useState('')
  const [site, setSite] = useState('')
  const [instagram, setInstagram] = useState('')

  const history = useHistory()

  const handleCepChange = (value) => {
    setCep(value);
  };

  const handleAddressChange = (value) => {
    setAddress(value)
  };

  const handleCpfExists = (isExist) => {
    setCpfIsExist(isExist);
  };

  const handleCnpjExists = (isExist) => {
    setCnpjIsExist(isExist);
  };

  const handleCpfChange = (value) => {
    setDocument(value);
  };

  const handleCnpjChange = (value) => {
    setDocument(value);
  };

  const handleCreciChange = (value) => {
    setCreci(value);
  };

  const handleCreciExists = (isExist) => {
    setCreciIsExist(isExist);
  };

  const sendRegister = () => {
    setLoading(true)
    const url = type === 'C' ? 'brokers-lead-update/' : type === 'I' ? 'real-estates-lead-update/' : type === 'T' ? 'construction-lead-update/' : ''

    const data = new FormData()
    if(type === 'T'){
      data.append('status', 2)
    }else{
      data.append('status', 3)
    }
    if(type === 'C'){
      data.append('creci', creci)
      data.append('cpf', document.replace(/[.-]/g, ''))
    }
    if(type === 'I'){
      data.append('creci_j', creci)
      data.append('cnpj', document.replace(/[.-\/]/g, '').replace('-', ''))
    }
    if(type === 'T'){
      data.append('cnpj', document.replace(/[.-\/]/g, '').replace('-', ''))
      data.append('owner_name', owner)
      data.append('social_name', social)
      data.append('site', site)
      data.append('instagram', instagram)
    }
    if(address){
      data.append('street', address.logradouro)
      data.append('district', address.bairro)
      data.append('complement', address.complemento)
      data.append('city', address.cidade)
      data.append('state', address.estado)
    }
    
    Axios.post(
      process.env.REACT_APP_API + '/api/register/' + url + user,
      data,
      { headers: { Authorization: 'Bearer ' + token } }
    )
      .then((response) => {

        const originData = new FormData()
        originData.append('user_id', user)
        originData.append('user_type', type)
        if(origin === 'Outros'){
          originData.append('response', otherOrigin)
        }else{
          originData.append('response', origin)
        }

        Axios.post(
          process.env.REACT_APP_API + '/api/register/new-feedback',
          originData,
          { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
        ).then((resp => {
          if (newCallback) {
            newCallback(3)
          } else {
            history.push('/registro-passo-3?user=' + user + '&type=' + type + '&token=' + token)
          }
        }))

      })
      .catch((error) => {
        setLoading(false)
        setRegisterError(true)
      });
  }

  return(
      <div className="register-section">
        <div className="register-wrapper">
          {loading ? 
            <Spinner className="me-2" color="success" />
          :
          <Container>
            <Row>
              <Col className="logo-container"><img src={Logo} alt="Fast Sale"/></Col>
            </Row>
            <Row className="step-title">
              <Col xs={12}>
                <h1>Um pouco mais sobre você</h1>
                <h3>Passo 2 de {type === 'T' ? 4 : 3}</h3>
              </Col>
            </Row>
            <Row>
              {type === 'T' &&
                <Col xs={12}>
                  <fieldset>
                    <Label>Nome do responsável*</Label>
                    <Input type="text" value={owner} onChange={(e) => setOwner(e.target.value)} />
                  </fieldset>
                </Col>
              }
              <Col xs={12}>
                <fieldset>
                  <Label>CEP*</Label>
                  <CepInput onCepChange={handleCepChange} onEnderecoChange={handleAddressChange} />
                </fieldset>
              </Col>
              {type === 'C' &&
                <Col xs={12}>
                  <fieldset>
                    <Label>CPF*</Label>
                    <CpfInput onCpfChange={handleCpfChange} onExists={handleCpfExists}/>
                  </fieldset>
                </Col>
              }
              {type === 'T' &&
                <Col xs={12}>
                  <fieldset>
                    <Label>Razão Social*</Label>
                    <Input type="text" value={social} onChange={(e) => setSocial(e.target.value)} />
                  </fieldset>
                </Col>
              }
              {(type === 'I' || type === 'T') &&
                <Col xs={12}>
                  <fieldset>
                    <Label>CNPJ*</Label>
                    <CnpjInput onCnpjChange={handleCnpjChange} onExists={handleCnpjExists}/>
                  </fieldset>
                </Col>
              }
              {(type === 'I' || type === 'C') &&
                <Col xs={12}>
                  <fieldset>
                    <Label>{type === 'C' && 'CRECI'}{type === 'I' && 'CRECI-J'}</Label>
                    <CreciInput onCreciChange={handleCreciChange} onExists={handleCreciExists}/>
                  </fieldset>
                </Col>
              }
              {type === 'T' &&
                <Col xs={12}>
                  <fieldset>
                    <Label>Site da construtora</Label>
                    <Input type="text" placeholder="https://..." value={site} onChange={(e) => setSite(e.target.value)} />
                  </fieldset>
                </Col>
              }
              {type === 'T' &&
                <Col xs={12}>
                  <fieldset>
                    <Label>Instagram da construtora</Label>
                    <Input type="text" placeholder="@seu.usuário" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
                  </fieldset>
                </Col>
              }

              <Col xs={12}>
                <fieldset>
                  <Label>Como conheceu a Fast Sale?*</Label>
                  <Input type="select" onChange={(e) => setOrigin(e.target.value)}>
                      <option value=''>Selecione</option>
                      <option value='Google'>Google</option>
                      <option value='Instagram'>Instagram</option>
                      <option value='Indicação'>Indicação</option>
                      <option value='YouTube'>YouTube</option>
                      <option value='Notícias'>Notícias</option>
                      <option value='Outros'>Outros</option>
                  </Input>
                </fieldset>
              </Col>
              {origin === 'Outros' &&
                <Col xs={12}>
                  <fieldset>
                    <Input 
                      type="textarea" 
                      value={otherOrigin}
                      rows="3"
                      placeholder="Escreva como nos conheceu"
                      onChange={(e) => setOtherOrigin(e.target.value)}
                    />
                  </fieldset>
                </Col>
              }
            </Row>
            <Row className="send-container">
              {type === 'T' ?
                <Col xs={12}>
                  {(document === ''
                  || social === ''
                  || owner === ''
                  || cep === ''
                  || origin === '' || (origin === 'Outros' && otherOrigin === '')) ?
                      <Button disabled>Preencha todos os campos obrigatórios</Button>
                    :
                      <Button onClick={sendRegister}>Próximo</Button>
                  }
                </Col>              
              :
                <Col xs={12}>
                  {(document === ''
                  || creci === ''
                  || cep === ''
                  // || ((type === 'C' && cpfIsExist === '') || (type === 'I' && cnpjIsExist === ''))
                  // || creciIsExist === ''
                  || origin === '' || (origin === 'Outros' && otherOrigin === '')) ?
                      <Button disabled>Preencha todos os campos</Button>
                    :
                      <Button onClick={sendRegister}>Próximo</Button>
                  }
                </Col>
              }
            </Row>
          </Container>
          }
        </div>
  
        {registerError ? (
          <SweetAlert
            title="Seu cadastro falhou, fale com nosso suporte."
            showCloseButton={true}
            showConfirm={true}
            error
            onConfirm={() => {
              setTimeout(() => {
                window.open('https://wa.me/554741081865', '_blank')
              }, 0);
            }}
          ></SweetAlert>
        ) : null}
  
      </div>
  )
}