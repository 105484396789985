import styles from '../styles/Home.module.css';
import Lupa from '../assets/lupa.png';
import { useState } from 'react';
import ContentEditable from "react-contenteditable";
import { ChromePicker } from 'react-color';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useEffect } from 'react';
import Dropzone from 'react-dropzone';

const Home = () => {
  const Image1 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910160/imagens_site/01_scvymp.jpg'
  const Image2 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/02_xgiimb.jpg'
  const Image3 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/03_e9vkzl.jpg' 
  const Image4 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/04_xsg1ak.jpg'
  const Image5 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/05_ihhakf.jpg'
  const Image6 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/06_cczyec.jpg'
  const Image7 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/07_ferbmj.jpg'
  const Image8 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/08_xfpa1t.jpg'
  const Image9 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/09_nc7xre.jpg'
  const Image10 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/10_nv5ebk.jpg' 
  const Image11 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/11_czopvn.jpg'
  const Image12 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/12_m9zhdi.jpg'
  const Image13 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/13_p9suxq.jpg'
  const Image14 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/14_rs5kae.jpg'
  const Image15 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/15_h1nynp.jpg'  
  const Image16 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/16_jgg85v.jpg'
  const Image17 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/17_svhtnj.jpg'
  const Image18 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910163/imagens_site/18_siq9wr.jpg'
  const Image19 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910163/imagens_site/19_m5xuxr.jpg'
  const Image20 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/20_afgcus.jpg'
  const [bg, setBg] = useState()
  const [ homeTitle, setHomeTitle] = useState('Insira um título para seu site')
  const [ homeTitleEnabled, setHomeTitleEnabled] = useState(true)
  const [colorTitle, setColorTitle] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [showPicker, setShowPicker] = useState(false);
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [showPickerButton, setShowPickerButton] = useState(false);
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [showPickerTextButton, setShowPickerTextButton] = useState(false);
  const [modalLarge, setModalLarge] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState(null);
  const defaultImages = [
    { src: Image1 },
    { src: Image2 },
    { src: Image3 },
    { src: Image4 },
    { src: Image5 },
    { src: Image6 },
    { src: Image7 },
    { src: Image8 },
    { src: Image9 },
    { src: Image10 },
    { src: Image11 },
    { src: Image12 },
    { src: Image13 },
    { src: Image14 },
    { src: Image15 },
    { src: Image16 },
    { src: Image17 },
    { src: Image18 },
    { src: Image19 },
    { src: Image20 },
  ];

  function handleFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // converte o arquivo em uma string Base64
      const fileAsBase64String = reader.result.split(',')[1];

      // armazena a string Base64 no estado
      localStorage.setItem('home_banner', fileAsBase64String);
    });
    reader.readAsDataURL(file);
  }

  function isBase64(str) {
    try {
      atob(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const homeBanner = localStorage.getItem('home_banner');
    if (homeBanner && homeBanner.slice(0, 4) !== 'http') {
      if(localStorage.getItem('home_banner')){
        const fileAsBase64String = localStorage.getItem('home_banner');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
      
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
      
          const blob = new Blob([array], { type: 'application/octet-stream' });
      
          const file = new File([blob], 'nome-do-arquivo.extensão');
      
          setBg(file) ;
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      }
    } else {
      setBg(homeBanner);
    }
    
    if(localStorage.getItem('home_button_text_color')){
      setColorTextButton(JSON.parse(localStorage.getItem('home_button_text_color')))
    }
    if(localStorage.getItem('home_title_color')){
      setColorTitle(JSON.parse(localStorage.getItem('home_title_color')))
    }
    if(localStorage.getItem('home_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('home_button_color')))
    }
    if(localStorage.getItem('home_title')){
      setHomeTitle(localStorage.getItem('home_title'))
    }
  }, [])

  return(
    <header className={styles.container_header}
      style={localStorage.getItem('home_banner') && localStorage.getItem('home_banner').slice(0, 4) !== 'http' 
      ? {backgroundImage: 'url(' + (bg instanceof File ? URL.createObjectURL(bg) : bg) + ')', position:'relative'} 
      : {backgroundImage: `url(${bg})`}}>
      <div className={styles.actionIcons}>
        <label className="bg" onClick={() => setModalLarge(!modalLarge)}><span>Inserir imagem</span><i className="ri-image-add-fill"></i></label>
      </div>
      <div className={styles.titleContainer}>
        <ContentEditable
          html={homeTitle}
          className={styles.titleEdit}
          id='titleEdit'
          disabled={homeTitleEnabled}
          style={{
            color: `rgba(${colorTitle?.r}, ${colorTitle?.g}, ${colorTitle?.b}, ${colorTitle?.a})`,
          }}
          onChange={(e) => {
            const strippedText = e.target.value.replace(/<\/?[^>]+(>|$)/g, '');
            setHomeTitle(strippedText)
            localStorage.setItem('home_title', e.target.value)
          }}
          onClick={(e) => {
            if(homeTitle === 'Insira um título para seu site' && !homeTitleEnabled){
              setHomeTitle('')
              localStorage.setItem('home_title', '')
            }
          }}
        />
        <div className={styles.actionIcons}>
          <label className="title">
            <span onClick={() => {
              if(!homeTitleEnabled){
                setHomeTitleEnabled(true)
                if(homeTitle === ''){
                  setHomeTitle('Insira um título para seu site')
                  localStorage.setItem('home_title', 'Insira um título para seu site')
                }
              }else{
                setHomeTitleEnabled(false)
                setTimeout(() => {
                  document.getElementById('titleEdit').focus()
                  if(homeTitle === 'Insira um título para seu site'){
                    setHomeTitle('')
                    localStorage.setItem('home_title', '')
                  }
                }, 300);
              }
            }}>{homeTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
            <i className="ri-text" onClick={() => {
              if(!homeTitleEnabled){
                setHomeTitleEnabled(true)
                if(homeTitle === ''){
                  setHomeTitle('Insira um título para seu site')
                  localStorage.setItem('home_title', 'Insira um título para seu site')
                }
              }else{
                setHomeTitleEnabled(false)
                setTimeout(() => {
                  document.getElementById('titleEdit').focus()
                  if(homeTitle === 'Insira um título para seu site'){
                    setHomeTitle('')
                    localStorage.setItem('home_title', '')
                  }
                }, 300);
              }
            }}></i>
            <i className="ri-font-color" onClick={() => setShowPicker(!showPicker)}></i>
             {showPicker && (
              <div className={styles.colorPickerTitle}>
                <i className="ri-close-fill closeIcon" onClick={() => setShowPicker(!showPicker)}></i>
                <ChromePicker
                  color={colorTitle ?? {r: 0, g: 0, b: 0, a: 1}}
                  onChange={(e) => {
                    setColorTitle(e.rgb)
                    localStorage.setItem('home_title_color', JSON.stringify(e.rgb))
                  }}
                />
                </div>
              )}
          </label>
        </div>
        {/* <h1 className={styles.title} style={{color: titleColor}}>{title ? title : 'Insira um título para seu site'}</h1> */}
      </div>

      <div className={styles.container}>
        <div className={styles.container_select}>
          <select className={styles.select_form} name="" id="">
            <option value="">Comprar</option>
            {/* <option value="">Vender</option>
            <option value="">Alugar</option> */}
          </select>
        </div>

        <div className={styles.container_select}>
          <select className={styles.select_type} name="" id="">
            <option value="">Tipo</option>
            <option value="">Apartamento</option>
            <option value="">Casa</option>
            <option value="">Comercial</option>
            <option value="">Outros</option>
          </select>
        </div>
      </div>

      <div className={styles.container_search}>
        <img src={Lupa} alt="" />
        <input type="text" placeholder='Digite condomínio, região, bairro, cidade' />
      </div>

      <div className={styles.container_button}>
        <button style={{
          backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
          color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
        }}>Buscar</button>
        <div className={styles.actionIcons}>
          <label className="title">
            <i className="ri-brush-fill" onClick={() => {
              setShowPickerButton(!showPickerButton)
              setShowPickerTextButton(false)
              }}></i>
            <i className="ri-font-color" onClick={() => {
              setShowPickerTextButton(!showPickerTextButton)
              setShowPickerButton(false)
              }}></i>
              {showPickerButton && (
                <div className={styles.colorPickerButton}>
                  <i
                    className="ri-close-fill closeIcon"
                    onClick={() => setShowPickerButton(!showPickerButton)}
                  ></i>
                  <ChromePicker
                    color={colorButton ?? { r: 0, g: 0, b: 0, a: 1 }}
                    onChange={(e) => {
                      setColorButton(e.rgb);
                      localStorage.setItem("home_button_color", JSON.stringify(e.rgb));
                    }}
                  />
                </div>
              )}
              {showPickerTextButton && (
                <div className={styles.colorPickerButton}>
                  <i
                    className="ri-close-fill closeIcon"
                    onClick={() => setShowPickerTextButton(!showPickerTextButton)}
                  ></i>
                  <ChromePicker
                    color={colorTextButton ?? { r: 255, g: 255, b: 255, a: 1 }}
                    onChange={(e) => {
                      setColorTextButton(e.rgb);
                      localStorage.setItem("home_button_text_color", JSON.stringify(e.rgb));
                    }}
                  />
                </div>
              )}
          </label>
        </div>
      </div>

      <Modal
        size="lg"
        isOpen={modalLarge}
        toggle={() => {
          setModalLarge(!modalLarge);
        }}
      >
        <ModalHeader toggle={() => setModalLarge(!modalLarge)}>
            Escolha uma imagem para o banner        
        </ModalHeader>
        <ModalBody>
          <Dropzone
            onDrop={acceptedFiles => {
              setBg(acceptedFiles[0]);
              handleFileChange({ target: { files: acceptedFiles } });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                  </div>
                  <h4>Para adicionar uma imagem arraste ou clique aqui.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <p className={styles.title_options}>Ou escolha uma das imagens padrão:</p>
          <div className={styles.imageOptions}>
            {defaultImages.map((image, index) => (
              <label key={index} 
                onClick={() => {
                  setSelectedLabel(index); 
                  setModalLarge(false); 
                }} 
                className={selectedLabel === index ? styles.selected : ''}
              >
                <img src={image.src} alt={image.name} className={styles.imagePreview} />
                <input type="radio" name="imageOption" value={image.src} onChange={(e) => {localStorage.setItem('home_banner', e.target.value) ;setBg(e.target.value)}} />
              </label>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </header>
  )
}

export default Home