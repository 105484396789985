import Axios from 'axios';
import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardImg, Row, CardTitle, CardText, Button, Badge, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

//Simple bar
import './../../assets/scss/custom/components/_enterprisesDash.scss'
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import Placeholder from './../../assets/images/image-placeholder-350x350-1.png'

class EnterpriseProfileBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            construtora:[],
            modal_center:false,
            logo: null,
            title:'',
        }
        this.tog_center = this.tog_center.bind(this);
        this.createEnterprise = this.createEnterprise.bind(this);
    }

    componentDidMount(){
            Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions/'
                + (localStorage.getItem('access') === 'S' ? localStorage.getItem('construction_id') : localStorage.getItem('id')),
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                this.setState({construtora: response.data})
            })
    }

    tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center
        }));
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    createSlug(title) {
        let slug = title.toLowerCase().replace(/[^\w\s-]/g, '');
        slug = slug.replace(/\s+/g, '-');
        return slug;
    }

    optionchange(event) {
		var count = event.target.value.length;
		if (count > 0) {
			this.setState({ disbadge: true });
		} else {
			this.setState({ disbadge: false });
		}
		if (count > 99) {
			this.setState({ advanceclass: "badgecountextra" });
		} else {
			this.setState({ advanceclass: "badgecount" });
		}
		this.setState({ optioncount: event.target.value.length });
	}

    createEnterprise(event) {

        const data = new FormData()

        data.append('title', this.state.title)
        data.append('slug', this.createSlug(this.state.title))
        data.append('construction_id', localStorage.getItem('access') === 'S' ? localStorage.getItem('construction_id') : localStorage.getItem('id'))
        data.append('status', 0)
        data.append('workflow', 11)
        if(this.state.logo){
            data.append('logo', this.state.logo)
        }

         Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/new-enterprise',
            data, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            setTimeout(() => {
                window.open('./../perfil-empreendimento?id=' + response.data.data.id, '_parent')
            }, 300);
         })
         .catch(response => {
            this.setState({update_error: true})
         })
    }

    render() {
        return (
            <React.Fragment>
                <Col xs={12}>
                    <Card>
                        <CardBody style={{marginTop:'20px'}}>
                            <Row style={{justifyContent:'space-between'}}>
                                <Col className="col-auto" style={{
                                    display:'flex',
                                    alignItems:'center',
                                    gap:'20px',
                                }}>
                                    <div className="construction-log" style={{
                                        backgroundImage:'url(' + ((this.state.construtora?.profile_photo && this.state.construtora?.profile_photo !== '') ? this.state.construtora?.profile_photo : Placeholder) + ')',
                                    }} onClick={() =>{
                                        setTimeout(() => {
                                            window.open('/editar-perfil', '_parent')
                                        }, 100);
                                    }}></div>
                                    <div className="construction-name">
                                        Olá, {localStorage.getItem('access') === 'S' ? localStorage.getItem('user_name') : this.state.construtora?.name}
                                        {localStorage.getItem('access') === 'S' && <p style={{fontSize:'14px'}}>{this.state.construtora?.name}</p>}
                                    </div>
                                </Col>
                                <Col className="col-auto col-container-btn">
                                    {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') && 
                                    <div className="dash-btn-new-enterprise" onClick={() => {
                                        this.setState({modal_center: true})
                                    }}>
                                        <div className="dash-icon"><i className="ri-add-box-line"></i></div>
                                        <div className="dash-label"><span>Novo Empreendimento</span></div>
                                    </div>
                                    }
                                    {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') && 
                                    <div className="dash-btn-units" onClick={() => {
                                        setTimeout(() => {
                                            window.open('./../../atualizar-unidades', '_parent')
                                        }, 0);
                                    }}>
                                        <div className="dash-icon"><i className="ri-edit-box-line"></i></div>
                                        <div className="dash-label"><span>Editar Unidades</span></div>
                                    </div>
                                    }
                                    <div className="dash-btn-broker-view" onClick={() => {
                                        setTimeout(() => {
                                            window.open('./../../imoveis', '_parent')
                                        }, 0);
                                    }}>
                                        <div className="dash-icon"><i className="ri-user-search-line"></i></div>
                                        <div className="dash-label"><span>Veja como corretor</span></div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Modal
                    isOpen={this.state.modal_center}
                    toggle={this.tog_center}
                    size="lg"
                    centered={true}
                >
                    <ModalHeader toggle={() => this.setState({ modal_center: false })}>
                    Novo empreendimento
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{minWidth:'400px'}}>
                            <Col xs={12}>
                                <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                    <AvGroup>
                                        <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Nome do Empreendimento</Label>
                                        <AvField
                                            name="title"
                                            placeholder="Nome do empreendimento"
                                            type="text"
                                            maxLength="100"
                                            errorMessage="Campo obrigatório."
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            value={this.state.title}
                                            onChange={(e) => {
                                                this.optionchange(e)
                                                this.setState({title: e.target.value})
                                            }}
                                        />
                                        {this.state.disbadge ? (
                                            <span className={this.state.advanceclass}>
                                            <span className="badge badge-success">Você digitou {this.state.optioncount} de 100 caracteres permitidos.</span>
                                            </span>
                                        ) : null}
                                    </AvGroup>
                                    <div className="input-group" style={{marginTop:'10px'}}>
                                      <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Logo do Empreendimento</Label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="customFile"
                                            onChange={(e) => this.setState({logo: e.target.files[0]})}
                                        />
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                        <Row style={{gap:'0px', justifyContent:'flex-end', marginTop:'20px' }}>
                            <Col className="col-auto">
                                <Button onClick={() => this.setState({ modal_center: false })} className="btn-dark">Cancelar</Button>
                            </Col>
                            <Col className="col-auto">
                                {this.state.title !== '' ?
                                <Button onClick={this.createEnterprise}>Criar</Button>
                                :
                                <Button disabled>Criar</Button>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                {this.state.update_error ? (
                    <SweetAlert
                        title="Este nome de empreendimento já existe."
                        timeout={3000}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => this.setState({ update_error: false })}
                    ></SweetAlert>
                ) : null}
            </React.Fragment>
        );
    }
}

export default EnterpriseProfileBox;