import { useEffect, useState } from 'react';
import './styles.scss';
import { Col, Input, Label, Row } from 'reactstrap';
import Axios from 'axios';

export const FeedFilters = ({onSearch, data}) => {
    const [searchFilter,setSearchFilter] = useState('')
    const [categoryFilter,setCategoryFilter] = useState('')
    const [stateFilter,setStateFilter] = useState('')
    const [cityFilter,setCityFilter] = useState('')
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    useEffect(() => {
        if(data){
            Axios.get(process.env.REACT_APP_API + '/api/auth/list-user-orders-states',
            {headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                setStates(response.data.filter(state => state !== 'null'))
            })
        }
    }, [data])

    useEffect(() => {
        setCityFilter('')
        if(stateFilter !== ''){
            Axios.post(process.env.REACT_APP_API + '/api/auth/list-user-orders-cities', {state: stateFilter},
                {headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                    setCities(response.data.filter(city => city !== 'null'))
                })
        }else{
            setCities([])
        }
    }, [stateFilter])

    useEffect(() => {
        onSearch({
            search:searchFilter,
            state:stateFilter,
            city:cityFilter,
            category:categoryFilter
        })
    }, [searchFilter, stateFilter, cityFilter, categoryFilter])

    const clearFilters = () => {
        setSearchFilter('')
        setCategoryFilter('')
        setStateFilter('')
        setCityFilter('')
    }

    return(
        <div className="feed-filters">
            <fieldset className="feed-search-input">
                <input type="text" placeholder="Filtre por descrição" value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)}></input>
                <span><i className="ri-search-line"></i></span>
            </fieldset>
            <fieldset>
                <Label>Categoria</Label>
                <Input type="select" value={categoryFilter} placeholder="Categoria" onChange={(e) => setCategoryFilter(e.target.value)}>
                    <option value={0}>Todas</option>
                    <option value={12}>Apartamento</option>
                    <option value={14}>Casa</option>
                    <option value={34}>Comercial</option>
                    <option value={22}>Terreno</option>
                    <option value={35}>Outros</option>
                </Input>
            </fieldset>
            <fieldset>
                <Row>
                    <Col xs={4}>
                        <Label>Estado</Label>
                        <Input type="select" value={stateFilter} placeholder="Estado" onChange={(e) => setStateFilter(e.target.value)}>
                            <option value=''>Todos</option>
                            {states.sort((a, b) => a.localeCompare(b)).map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </Input>
                    </Col>
                    <Col xs={8}>
                        <Label>Cidade</Label>
                        <Input disabled={stateFilter !== '' ? false : true} type="select" value={cityFilter} placeholder="Cidade" onChange={(e) => setCityFilter(e.target.value)}>
                            <option value=''>Todas</option>
                            {cities.sort((a, b) => a.localeCompare(b)).map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </Input>
                    </Col>
                </Row>
            </fieldset>
            <span className="clear-filters" onClick={clearFilters}>Limpar Filtros</span>
        </div>
    )
}