import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Container, Spinner, Input, Button, Badge, Label } from "reactstrap";
import Axios from 'axios'

import Breadcrumbs from "../../components/Common/Breadcrumb"
import './../../assets/scss/custom/components/_editProperties.scss'
import './../../assets/scss/custom/components/_addProposal.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';

class listProposalAdm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imoveis: [],
      allImoveis: [],
      filterImoveis:[],
      imoveisResults:[],
      statusFilter:'Todos',
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Propostas", link: "#" },
    ],
    }
  }


  componentDidMount(){
    document.getElementById('load').classList.add('active')
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        if(localStorage.getItem('access') === 'A'){

        Axios.get(process.env.REACT_APP_API + '/api/auth/proposal',
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(resp => {
          this.setState({filterImoveis: resp.data})
          this.setState({allImoveis: resp.data}, () => {
            this.applyFilters();
         setTimeout(() => {
           document.getElementById('load').classList.remove('active')
         }, 300);
        })
        })

      }else{
        setTimeout(() => {
          window.open("/dashboard", '_parent')
        }, 300);
      }

      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }

  handleStatusChange = (event) => {
    this.setState({ statusFilter: event.target.value }, () => {
      this.applyFilters();
    });
  };

  applyFilters = () => {
    const { allImoveis, statusFilter } = this.state;
  
    let filteredImoveis = allImoveis;
  
    // Aplicar filtro de status
    if (statusFilter !== 'Todos') {
      filteredImoveis = filteredImoveis.filter((imovel) => imovel.status === parseInt(statusFilter));
    }else if (statusFilter === 'Todos') {
      filteredImoveis = allImoveis;
    }

    setTimeout(() => {
      this.setState({ imoveis: filteredImoveis, filterImoveis: filteredImoveis });
    }, 0);
  };


  render() {
    const { imoveis } = this.state;

    const propostasData = {
      columns: [
        {
          dataField: 'sku',
          text: 'Código'
        },
        {
          dataField: "one_photo",
          text: "Foto"
        },
        {
          dataField: "title",
          text: "Imóvel"
        },
        {
          dataField: "created_at",
          text: "Data"
        },
        {
          dataField: "status",
          text: "Status"
        },
        {
          dataField: "id_proposta",
          text: "Ver"
        },
      ],
    };

    const propostasItems = []

    this.state.imoveis.sort(function(a,b){
      return new Date(b.created_at) - new Date(a.created_at)}).map((proposta, index) => {
      propostasItems.push({
        sku: proposta.properties?.sku,
        title: proposta.properties?.title,
        slug: proposta.properties?.slug,
        created_at: moment(proposta?.created_at).format('DD/MM/YYYY'),
        one_photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + proposta?.properties?.one_photo?.small_image + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'50px', height: '50px', borderRadius:'5px'}}></div>,
        id_proposta: <i className="ri-eye-fill" style={{fontSize:'22px', cursor:'pointer'}} onClick={() => {
          localStorage.setItem('property_id_proposal', proposta.properties?.id)
          setTimeout(() => {
            window.open("/proposta?" + proposta?.id, '_parent')
          }, 300);
        }}></i>,
        status: proposta?.status === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
        : proposta?.status === 1 ? <Badge className="bg-info me-1 rounded-pill">Formalizada</Badge>
        : proposta?.status === 2 ? <Badge className="bg-danger me-1 rounded-pill">Cancelada</Badge>
        : proposta?.status === 3 ? <Badge className="bg-danger me-1 rounded-pill">Negada</Badge>
        : <Badge className="bg-success me-1 rounded-pill">Aceita</Badge>,
      })
    })

    const propostasOptions = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: 'Todos', value: imoveis.length
        }]
    };

    return (
        <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Propostas" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>  
                <CardBody>
                  <Row style={{marginBottom:'20px', justifyContent:'flex-end'}}>
                    <Col lg={3} md={6} xs={12} style={{display:'flex', justifyContent:'flex-end', gap:'10px', alignItems:'center'}}>
                      <Label>Status:</Label>
                      <select className="form-control" onChange={this.handleStatusChange}>
                        <option value="Todos">Todos</option>
                        <option value={0}>Pendentes</option>
                        <option value={1}>Formalizadas</option>
                        <option value={2}>Canceladas</option>
                        <option value={3}>Negadas</option>
                        <option value={4}>Aceitas</option>
                      </select>
                    </Col>
                    <Col lg={3} md={6} xs={12}  style={{display:'flex', flexDirection:'row'}} className="searchOutletListContainer">
                      <Input
                      className="searchOutletList"
                      placeholder="Buscar por código do imóvel"
                      onChange={(e) => this.setState({imoveisResults: propostasItems.filter(contrato => contrato?.sku?.toLowerCase().includes(e.target.value.toLowerCase()))})}
                      >
                      </Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                  <BootstrapTable
                    keyField='id'
                    data={ this.state.imoveisResults.length > 0 ? this.state.imoveisResults : propostasItems}
                    columns={propostasData.columns}
                    // expandRow={expandRow}
                    pagination={paginationFactory(propostasOptions)}
                    // selectRow={selectRow}
                  />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default listProposalAdm;