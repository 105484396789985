import {Component} from 'react'
import FiltersSection3 from './Section3'
import FiltersSection4 from './Section4'
import FiltersSection5 from './Section5'
import FiltersSection6 from './Section6'

import Select from "react-select";

import './../../assets/scss/custom/components/_filters.scss'
import { Button, Card, CardBody, CardHeader, Col, Collapse, Input, Label, Row } from 'reactstrap'
import Axios from 'axios'

class UnitsFilters extends Component {


  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      isCategoryOpen0 : true,
      isCategoryOpen1 : false,
      isCategoryOpen2 : false,
      isCategoryOpen3 : false,
      isCategoryOpen4 : false,
      isCategoryOpen5 : false,
      isCategoryOpen6 : false,
      moreFilters:false,
      citiesOptions:[],
      districtOptions:[],
      citySelected:[],
      districtSelected:[],
      moreFiltersIcon:'ri-add-line',
    }
    this.filtercategorytoggle0 = this.filtercategorytoggle0.bind(this)
    this.filtercategorytoggle1 = this.filtercategorytoggle1.bind(this)
    this.filtercategorytoggle2 = this.filtercategorytoggle2.bind(this)
    this.filtercategorytoggle3 = this.filtercategorytoggle3.bind(this)
    this.filtercategorytoggle4 = this.filtercategorytoggle4.bind(this)
    this.filtercategorytoggle5 = this.filtercategorytoggle5.bind(this)
    this.filtercategorytoggle6 = this.filtercategorytoggle6.bind(this)
  }

  filtercategorytoggle0() {
    if(this.state.isCategoryOpen0){
      this.setState({isCategoryOpen0: false})
    }
    if(!this.state.isCategoryOpen0){
      this.setState({isCategoryOpen0: true})
    }
  }

  filtercategorytoggle1() {
    if(this.state.isCategoryOpen1){
      this.setState({isCategoryOpen1: false})
    }
    if(!this.state.isCategoryOpen1){
      this.setState({isCategoryOpen1: true})
    }
  }

  filtercategorytoggle2() {
    if(this.state.isCategoryOpen2){
      this.setState({isCategoryOpen2: false})
    }
    if(!this.state.isCategoryOpen2){
      this.setState({isCategoryOpen2: true})
    }
  }

  filtercategorytoggle3() {
    if(this.state.isCategoryOpen3){
      this.setState({isCategoryOpen3: false})
    }
    if(!this.state.isCategoryOpen3){
      this.setState({isCategoryOpen3: true})
    }
  }

  filtercategorytoggle4() {
    if(this.state.isCategoryOpen4){
      this.setState({isCategoryOpen4: false})
    }
    if(!this.state.isCategoryOpen4){
      this.setState({isCategoryOpen4: true})
    }
  }

  filtercategorytoggle5() {
    if(this.state.isCategoryOpen5){
      this.setState({isCategoryOpen5: false})
    }
    if(!this.state.isCategoryOpen5){
      this.setState({isCategoryOpen5: true})
    }
  }

  filtercategorytoggle6() {
    if(this.state.isCategoryOpen6){
      this.setState({isCategoryOpen6: false})
    }
    if(!this.state.isCategoryOpen6){
      this.setState({isCategoryOpen6: true})
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

componentDidMount(){
  if(window.screen.width < 768){
    this.setState({isCategoryOpen0 : false})
    document.getElementById('accordion').style.display= 'none'
    document.getElementById('moreFilters').style.display= 'flex'
  }else{
    document.getElementById('accordion').style.display= 'block'
    document.getElementById('moreFilters').style.display= 'none'
  }

  Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/filter-options',
  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
  .then(response => {
      this.setState({citiesOptions: response.data.cities.filter(city => city.label !== null)})
  })
}

showDistricts(e){
  Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/city/' + e.value + '/districts',
  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
  .then(response => {
      this.setState({districtOptions: response.data.filter(district => district.label !== null)})
  })
}

  render(){
    return (
        <div className="Filters">
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div style={{display: 'flex',justifyContent: 'space-between' ,alignItems: 'center'}}>
                {this.props.onToogle ? (
                    <h5 className="mb-0">Filtros</h5>
                  ) : (
                    <h5 className="mb-0 none-text-filter">Filtros</h5>
                  )
                }
                {
                  this.props.onToogle ? (
                      <i className="ri-close-line" onClick={this.props.toogle} style={{cursor: "pointer", fontSize: "1.3rem"}}></i>
                    ) : (
                      <i className="ri-filter-2-fill" onClick={this.props.toogle} style={{cursor: "pointer", fontSize: "1.3rem"}}></i>
                  )
                }
              </div>
            </CardHeader>
        {this.props.onToogle &&
                    <CardBody>
                      <div id="moreFilters" className="moreFilters" onClick={() => {
                    if(this.state.moreFilters){
                      document.getElementById('accordion').style.display= 'none'
                      this.setState({moreFilters : false})
                      this.setState({moreFiltersIcon : 'ri-add-line'})
                    }else{
                      document.getElementById('accordion').style.display= 'block'
                      this.setState({moreFilters : true})
                      this.setState({moreFiltersIcon : 'ri-subtract-line'})
                    }
                      }}><p>Filtros</p><i className={this.state.moreFiltersIcon}></i></div>
                      
                      <div style={{margin:'20px 0'}}>
                        <Label className="form-label">Cidade</Label>
                        <Select
                            value={this.state.citySelected}
                            placeholder="Selecione"
                            noOptionsMessage={(inputValue) => `Sem resultados`}
                            onChange={(e) => {
                              this.setState({ citySelected: e })
                              this.showDistricts(e)
                              localStorage.setItem('filtro-cidades', [e.value])
                            }}
                            options={this.state.citiesOptions}
                            classNamePrefix="select2-selection"
                        />
                        <small style={{
                          textDecoration:'underline',
                          marginTop:'5px',
                          cursor:'pointer'
                        }} onClick={() => {
                          this.setState({citySelected: []})
                          setTimeout(() => {
                            this.sendSearch()
                          }, 300);
                          }}>Limpar</small>
                      </div>

                      <div style={{margin:'20px 0'}}>
                        <Label className="form-label">Bairro</Label>
                        <Select
                            value={this.state.districtSelected}
                            placeholder="Selecione"
                            noOptionsMessage={(inputValue) => `Selecione primeiro uma cidade`}
                            onChange={(e) => {
                              this.setState({ districtSelected: e })
                              localStorage.setItem('filtro-bairros', [e.value])
                            }}
                            options={this.state.districtOptions}
                            classNamePrefix="select2-selection"
                        />
                        <small style={{
                          textDecoration:'underline',
                          marginTop:'5px',
                          cursor:'pointer'
                        }} onClick={() => {
                          this.setState({districtSelected: []})
                          setTimeout(() => {
                            this.sendSearch()
                          }, 300);
                          }}>Limpar</small>
                      </div>

                      <div className="accordion ecommerce" id="accordion">
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingtwo">
                          <button
                          className={ this.state.isCategoryOpen2 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={this.filtercategorytoggle2}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-money-dollar-circle-fill font-size-16 align-middle me-2"></i>{" "}
                          Preço
                          </button>
                      </h2>
                      <Collapse
                          isOpen={this.state.isCategoryOpen2}
                          className="accordion-collapse"
                      >
                          <FiltersSection3 imoveis={this.props.imoveis}/>
                      </Collapse>
                      </div>
    
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                          <button
                          className={ this.state.isCategoryOpen4 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={this.filtercategorytoggle4}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-ruler-2-fill font-size-16 align-middle me-2"></i>{" "}
                          Área total
                          </button>
                      </h2>
    
                      <Collapse
                          isOpen={this.state.isCategoryOpen4}
                          className="accordion-collapse"
                      >
                          <FiltersSection4 imoveis={this.props.imoveis}/>
                      </Collapse>
                      </div>
    
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                          <button
                          className={ this.state.isCategoryOpen5 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={this.filtercategorytoggle5}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-hotel-bed-fill font-size-16 align-middle me-2"></i>{" "}
                          Quartos
                          </button>
                      </h2>
    
                      <Collapse
                          isOpen={this.state.isCategoryOpen5}
                          className="accordion-collapse"
                      >
                          <FiltersSection5/>
                      </Collapse>
                      </div>
    
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                          <button
                          className={ this.state.isCategoryOpen6 ? 'accordion-button' : 'accordion-button collapsed'}
                          onClick={this.filtercategorytoggle6}
                          data-bs-toggle="collapse"
                          >
                          <i className="ri-car-fill font-size-16 align-middle me-2"></i>{" "}
                          Garagens
                          </button>
                      </h2>
    
                      <Collapse
                          isOpen={this.state.isCategoryOpen6}
                          className="accordion-collapse"
                      >
                                    
                        <FiltersSection6/>
                      </Collapse>
                      </div>
                      <Row style={{justifyContent:'space-between'}}>
                        <Col className="col-auto" style={{marginTop:'10px'}}>
                            <Button type="button" color="light" onClick={() => {
                                localStorage.removeItem('paginaAtual')
                                this.props.clearFilter()
                                setTimeout(() => {
                                    if (this.props.redirectToImoveis) {
                                      const urlSection = this.props.urlSection ? this.props.urlSection : ''
                                      this.props.onUpdate(urlSection)
                                    } else {
                                      window.open("/seu-site-fs", '_parent')
                                    }
                                }, 300);
                            }}>Remover filtros</Button>
                        </Col>
                        <Col className="col-auto" style={{marginTop:'10px'}}>
                            <Button type="button" style={{color:'#fff'}} onClick={(e) => {
                                e.preventDefault();
                                localStorage.removeItem('paginaAtual')
                                const urlSection = this.props.urlSection ? this.props.urlSection : ''
                                this.props.onUpdate(urlSection)
                            }}>Aplicar</Button>
                        </Col>
                      </Row>
                      </div>
                    </CardBody>
        }

        </Card>
        </div>
      )
  }

}
export default UnitsFilters;