import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Button, Row, Input, Container } from "reactstrap";
import { Link } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Axios from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';

class ContractsParc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      accepted:[],
      receive:[],
      viewport: [
        {
          dataField: 'mobile',
          text: 'Contratos'
        },
      ],
      filtleredItems:[],
    }
  }

  componentDidMount(){

    if(localStorage.getItem('access') !== 'A'){
    Axios.post(process.env.REACT_APP_API + '/api/auth/prop-contracts-no-pag', {
      user_id: localStorage.getItem('id'),
      type: localStorage.getItem('access')
    }, 
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {this.setState({receive: response.data})})

    Axios.post(process.env.REACT_APP_API + '/api/auth/own-contracts-no-pag', {
      user_id: localStorage.getItem('id'),
      type: localStorage.getItem('access')
    }, 
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => this.setState({accepted: response.data}))
  }
  if(localStorage.getItem('access') === 'A'){
    
  }

  if(window.screen.width > 768){
    this.setState({viewport: [
      {
        dataField: 'sku',
        text: 'Código'
      },
      {
        dataField: "title",
        text: "Imóvel"
      },
      {
        dataField: "created_at",
        text: "Data"
      },
      {
        dataField: "profile_photo",
        text: ""
      },
      {
        dataField: "name",
        text: "Corretor"
      },
      {
        dataField: "id_parceria",
        text: "Baixar termo"
      },
    ]})
  }
}

  render() {
    const items = []

      this.state.receive.map((imovel, index) => {
        imovel.parcerias?.map((parceria, i) => {
          items.push({
            sku: imovel.sku,
            title: imovel.title,
            slug: imovel.slug,
            created_at: moment(parceria.created_at).format('DD/MM/YYYY'),
            created_at_compare: parceria.created_at,
            name: parceria.name,
            profile_photo: <div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + parceria.profile_photo + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'40px', height: '40px', borderRadius:'50%'}}></div>,
            id_parceria: <Button  onClick={() => {
              const axiosOptions = {
                responseType: 'arraybuffer',
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization": "Bearer " + localStorage.getItem('token')
                }
              }
              Axios.get(process.env.REACT_APP_API + '/api/auth/contract-pdf/' + parceria.id_parceria, axiosOptions)
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: 'application/octet-stream'
                })
                const filename = 'Parceria:' + imovel?.sku + '(' + parceria.name + ').pdf'
                saveAs(blob, filename)
              })
              .catch((e) => {

              })
        }}>Baixar</Button>,
          mobile:<Container style={{padding:'0'}}>
            <Row>
              <Col className="col-auto"><div style={{backgroundColor:'#f4f4f4', backgroundImage:'url(' + parceria.profile_photo + ')', backgroundSize:'cover', backgroundPosition:'center center', width:'60px', height: '60px', borderRadius:'5px'}}></div></Col>
              <Col>
                <Row>
                  <Col xs={12}>{moment(parceria.created_at).format('DD/MM/YYYY')}</Col>
                  <Col xs={12}>{parceria?.name}</Col>
                </Row>
              </Col>
            </Row>
            <Row style={{marginTop:'10px'}}>
              <Col>Imóvel: {imovel.sku}</Col>
            </Row>
            <Row>
              <Col>{imovel.title}</Col>
            </Row>
            <Row style={{marginTop:'10px'}}>
              <Col><Button  onClick={() => {
              const axiosOptions = {
                responseType: 'arraybuffer',
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization": "Bearer " + localStorage.getItem('token')
                }
              }
              Axios.get(process.env.REACT_APP_API + '/api/auth/contract-pdf/' + parceria.id_parceria, axiosOptions)
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: 'application/octet-stream'
                })
                const filename = 'Parceria:' + imovel?.sku + '(' + parceria.name + ').pdf'
                saveAs(blob, filename)
              })
              .catch((e) => {

              })
        }}>Baixar contrato</Button></Col>
            </Row>
          </Container>,
        })
      })
    })

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: 'Todos', value: items.length
        }]
    };


    return (
      <React.Fragment>
        <Col lg={12}>
          <Card style={{ minHeight: "575px" }}>
            <CardBody>
              {/* <Dropdown isOpen={this.state.menu} toggle={() => this.setState({ menu: !this.state.menu })} className="float-end">
                <DropdownToggle tag="i" className="arrow-none card-drop">
                  <i className="mdi mdi-dots-vertical"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                  <DropdownItem>Sales Report</DropdownItem>

                  <DropdownItem>Export Report</DropdownItem>

                  <DropdownItem>Profit</DropdownItem>

                  <DropdownItem>Action</DropdownItem>
                </DropdownMenu>
              </Dropdown> */}
              
              <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                <Col xs={12}>
                  <h4 className="card-title mb-4">Contratos de parceria (em seus imóveis)</h4>
                </Col>
                <Col xs={12} style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                  <Input
                  placeholder="Buscar por corretor"
                  onChange={(e) => this.setState({filtleredItems: items.filter(contrato => contrato.name.toLowerCase().includes(e.target.value.toLowerCase()))})}
                  >
                  </Input>
                </Col>
              </Row>
              <div className="table-responsive">

              <BootstrapTable
                keyField='id'
                data={this.state.filtleredItems.length > 0 ? this.state.filtleredItems.sort(function(a,b){
                  return new Date(b.created_at_compare) - new Date(a.created_at_compare)}) : items.sort(function(a,b){
                  return new Date(b.created_at_compare) - new Date(a.created_at_compare)})}
                columns={this.state.viewport}
                // expandRow={expandRow}
                pagination={paginationFactory(options)}
                // selectRow={selectRow}
              />
              </div>

            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default ContractsParc;