import React, { Component } from "react";
import { Row, Col, Button, Alert, Container, Label, Input, Card, Spinner } from "reactstrap";
import PasswordChecklist from "react-password-checklist";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { registerUser, registerUserFailed, apiError } from '../../store/actions';

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from 'axios';

// import images
import logodark from "../../assets/images/logo-dark.png";
import ReactSelect from 'react-select';
import Select from "react-select";
import Dropzone from 'react-dropzone';

import './../../assets/scss/custom/components/_register.scss';
import SweetAlert from 'react-bootstrap-sweetalert';
import Whats from '../../components/Whats/Whats';
import CnpjInput from '../Home/components/CnpjInput';
import EmailInput from '../Home/components/EmailInput';
import PhoneInput from '../Home/components/PhoneInput';

class RegisterConstruction extends Component {
    constructor(props) {
        super(props);
        this.validateRef = React.createRef();
        this.validateRef2 = React.createRef();
        this.state = {
            usuarioTipo: 'T',
            selectedCnpj:[],
            selectedRg:[],
            email: null,
            telefone: null,
            nome: null,
            cep: null,
            logradouro: null,
            numero: null,
            bairro: null,
            complemento: null,
            site:'',
            instagram:'',
            cidade:[],
            estado:[],
            pais:'Brasil',
            cidadeLista: [],
            estadoLista: [],
            senha:'',
            confirmacaoSenha:'',
            truePassword:false,
            social:null,
            cnpj:null,
            proprietario:null,
            creciJ:null,
            creci:null,
            creciPhoto:null,
            rgPhoto:null,
            steps:0,
            CnpjIsExist:'0',
            emailIsExist:'0',
            register_error:false,
            register_success:false,
            validPhoto:false,
            validCnpj:false,
            validEmail:false,
            enabledCity:true,
        }
    }

    componentDidMount(){
        Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(response => {
          const states = []
          for(var i = 0; response.data.length > i; i++){
            states.push({
              value: response.data[i].sigla,
              label: response.data[i].sigla
            })
          }
          this.setState({estadoLista: states})
        })
      }
    

    handleSelectState = estado => {
        this.setState({cidade: ''})
        this.setState({ estado: estado });
        Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + estado.value + '/municipios')
        .then(response => {
            const city = []
            for(var i = 0; response.data.length > i; i++){
                city.push({
                    label: response.data[i].nome,
                    value: response.data[i].nome
                })
            }
            this.setState({cidadeLista: city})
            this.setState({enabledCity: false})
        })
	};


  consultaCep = cepValue => {
    const cep = cepValue
     if (cep?.length !== 8) {
      this.setState({logradouro: ''});
      this.setState({bairro: ''});
      this.setState({estado: ''})
      this.setState({cidade: ''});
     }else{
       fetch('https://viacep.com.br/ws/' + cep + '/json/')
        .then((res) => res.json())
        .then((data) => {
            if (data.erro === true) {
              this.setState({logradouro: ''});
              this.setState({bairro: ''});
              this.setState({estado: ''})
              this.setState({cidade: ''});
            } else {
              this.setState({logradouro: data.logradouro});
              this.setState({bairro: data.bairro});
              this.setState({estado: {
                value:data.uf,
                label:data.uf
              }})
              this.handleSelectState({
                value:data.uf,
                label:data.uf
              })
              this.setState({cidade: {
                value:data.localidade,
                label:data.localidade
              }});
            }
          })
     }  
  }

  handleAcceptedDocs = files => {
    files.map(file => {
      let preview;
      if (file.type === 'application/pdf') {
        preview = null;  // Não temos pré-visualização para PDFs
      } else {
        preview = URL.createObjectURL(file);
      }
  
      return Object.assign(file, {
        preview,
        fileType: file.type,
        formattedSize: this.formatBytes(file.size)
      });
    });
  
    this.setState({ selectedCnpj: files });
  };

  handleAcceptedDocsRg = files => {
    files.map(file => {
      let preview;
      if (file.type === 'application/pdf') {
        preview = null;  // Não temos pré-visualização para PDFs
      } else {
        preview = URL.createObjectURL(file);
      }
  
      return Object.assign(file, {
        preview,
        fileType: file.type,
        formattedSize: this.formatBytes(file.size)
      });
    });
  
    this.setState({ selectedRg: files });
  };

      formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      };


      handlePhoneChange = (value) => {
        this.setState({validPhoto: false})
        this.setState({telefone: value});
      };

      handleCnpjChange = (value) => {
        this.setState({validCnpj: false})
        this.setState({cnpj: value});
      };

      handleCnpjExists = (isExist) => {
        this.setState({CnpjIsExist:isExist});
      };    

      handleEmailChange = (value) => {
        this.setState({validEmail: false})
        this.setState({email: value});
      };

      handleEmailExists = (isExist) => {
        this.setState({emailIsExist:isExist});
      };    


    render() {
        const {estadoLista, cidadeLista, cidade, estado} = this.state;
        return (
            <React.Fragment>
                <div id="construction-register">
                    <Container fluid className="p-0">
                        <Row className="g-0" style={{justifyContent: 'center'}}>
                            <Col lg={5} md={8}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="#" className="logo"><img src={logodark} height="20" alt="logo" /></Link>
                                                        </div>
                                                    {this.state.steps === 0 &&
                                                        <>
                                                            <h4 className="font-size-18 mt-4">Informações da construtora</h4>
                                                            <p className="text-muted">Dados errados podem reprovar o cadastro.</p>
                                                        </>
                                                    }
                                                    {this.state.steps === 1 &&
                                                        <>
                                                            <h4 className="font-size-18 mt-4">Endereço</h4>
                                                            <p className="text-muted">Endereço comercial completo.</p>
                                                        </>
                                                    }
                                                    {this.state.steps === 2 &&
                                                        <>
                                                            <h4 className="font-size-18 mt-4">Crie uma senha</h4>
                                                            <p className="text-muted">Atenção as regras de criação da senha.</p>
                                                        </>
                                                    }
                                                    {this.state.steps === 3 &&
                                                        <>
                                                            <h4 className="font-size-18 mt-4">Documentos</h4>
                                                            <p className="text-muted">Anexe fotos legíveis dos documentos.</p>
                                                        </>
                                                    }   
                                                    </div>

                                                    {this.state.steps === 0 &&
                                                        <AvForm className="cardBody"  ref={this.validateRef}>
                                                            <Container style={{paddingTop:'10px', paddingBottom:'10px'}}>
                                                                <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                                                <Col md="12">
                                                                    <Label>Nome da construtora *</Label>
                                                                    <AvField
                                                                        name="construtora"
                                                                        type="text"
                                                                        errorMessage="Campo obrigatório"
                                                                        validate={{
                                                                            required: { value: true },
                                                                        }}
                                                                        value={this.state.nome}
                                                                        onChange={(e) => this.setState({nome: e.target.value})}
                                                                    />
                                                                </Col>
                                                                <Col md="12">
                                                                    <Label>Razão social *</Label>
                                                                    <AvField
                                                                        name="social"
                                                                        type="text"
                                                                        errorMessage="Campo obrigatório"
                                                                        validate={{
                                                                            required: { value: true },
                                                                        }}
                                                                        value={this.state.social}
                                                                        onChange={(e) => this.setState({social: e.target.value})}
                                                                    />
                                                                </Col>
                                                                <Col md="12">
                                                                    <Label>CNPJ *</Label>
                                                                    <CnpjInput
                                                                        onCnpjChange={this.handleCnpjChange}
                                                                        onExists={this.handleCnpjExists}
                                                                    />
                                                                    {(this.state.validCnpj && this.state.cnpj !== '') &&
                                                                    <>
                                                                        <i className="ri-error-warning-line"></i>
                                                                        <small className="required-field">Campo obrigatório</small>
                                                                    </>
                                                                    }
                                                                </Col>
                                                                <Col md="12">
                                                                    <Label>Telefone *</Label>
                                                                    <PhoneInput
                                                                        phoneNumber={this.state.tefone}
                                                                        onPhoneChange={this.handlePhoneChange}
                                                                    />
                                                                    {(this.state.validPhoto && this.state.telefone !== '') &&
                                                                    <>
                                                                        <i className="ri-error-warning-line"></i>
                                                                        <small className="required-field">Campo obrigatório</small>
                                                                    </>
                                                                    }
                                                                </Col>
                                                                <Col md="12">
                                                                    <Label>E-mail *</Label>
                                                                    <EmailInput
                                                                        onEmailChange={this.handleEmailChange}
                                                                        onExists={this.handleEmailExists}
                                                                    />
                                                                    {(this.state.validEmail && this.state.email !== '') &&
                                                                    <>
                                                                        <i className="ri-error-warning-line"></i>
                                                                        <small className="required-field">Campo obrigatório</small>
                                                                    </>
                                                                    }
                                                                </Col>
                                                                <Col md="12">
                                                                    <Label>Site da construtora</Label>
                                                                    <AvField
                                                                        name="site"
                                                                        type="text"
                                                                        value={this.state.site}
                                                                        onChange={(e) => this.setState({site: e.target.value})}
                                                                    />
                                                                </Col>
                                                                <Col md="12">
                                                                    <Label>Instagram da construtora</Label>
                                                                    <AvField
                                                                        name="site"
                                                                        type="text"
                                                                        placeholder="@seu.usuario"
                                                                        value={this.state.instagram}
                                                                        onChange={(e) => this.setState({instagram: e.target.value})}
                                                                    />
                                                                </Col>
                                                                <Col md="12">
                                                                    <Label>Nome do responsável *</Label>
                                                                    <AvField
                                                                        name="proprietario"
                                                                        type="text"
                                                                        errorMessage="Campo obrigatório"
                                                                        validate={{
                                                                            required: { value: true },
                                                                        }}
                                                                        value={this.state.proprietario}
                                                                        onChange={(e) => this.setState({proprietario: e.target.value})}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            </Container>
                                                        </AvForm>
                                                    }
                                                    {this.state.steps === 1 &&
                                                        <AvForm className="cardBody"  ref={this.validateRef2}>
                                                            <Container>
                                                                <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                                                <Col md={12}>
                                                                        <Label htmlFor="cep" className="col-md-12 col-form-label">Cep</Label>
                                                                        <AvField
                                                                        name="cep"
                                                                        placeholder="Cep"
                                                                        type="text"
                                                                        errorMessage="Campo obrigatório."
                                                                        className="form-control"
                                                                        value={this.state.cep}
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={(e) => {
                                                                            this.consultaCep(e.target.value.replace('-', '').replace('.', ''))
                                                                            this.setState({cep: e.target.value})
                                                                        }}
                                                                        id="cep"
                                                                        />
                                                                    </Col>
                                                                    <Col xs={9}>
                                                                        <Label htmlFor="rua" className="col-md-12 col-form-label">Rua</Label>
                                                                        <AvField
                                                                        name="rua"
                                                                        placeholder="Rua, Avenida..."
                                                                        type="text"
                                                                        errorMessage="Campo obrigatório."
                                                                        className="form-control"
                                                                        value={this.state.logradouro}
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={(e) => {
                                                                            this.setState({logradouro: e.target.value})
                                                                        }}
                                                                        id="rua"
                                                                        />
                                                                    </Col>
                                                                    <Col xs={3}>
                                                                        <Label htmlFor="numero" className="col-md-12 col-form-label">Número</Label>
                                                                        <AvField
                                                                        name="numero"
                                                                        placeholder="Número"
                                                                        type="text"
                                                                        errorMessage="Campo obrigatório."
                                                                        className="form-control"
                                                                        value={this.state.numero}
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={(e) => {
                                                                            this.setState({numero: e.target.value})
                                                                        }}
                                                                        id="numero"
                                                                        />
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Label htmlFor="bairro" className="col-md-12 col-form-label">Bairro</Label>
                                                                        <AvField
                                                                        name="bairro"
                                                                        placeholder="Bairro"
                                                                        type="text"
                                                                        errorMessage="Campo obrigatório."
                                                                        className="form-control"
                                                                        value={this.state.bairro}
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={(e) => {
                                                                            this.setState({bairro: e.target.value})
                                                                        }}
                                                                        id="bairro"
                                                                        />
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Label htmlFor="complemento" className="col-md-12 col-form-label">Complemento</Label>
                                                                        <Input type="text" placeholder="Complemento" id="complemento" value={this.state.complemento} onChange={(e) => this.setState({complemento: e.target.value})} />
                                                                    </Col>
                                                                    <Col  md={6}>
                                                                        <Label htmlFor="estado" className="col-md-12 col-form-label">Estado</Label>
                                                                        <ReactSelect
                                                                        value={estado}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={({ inputValue: string }) => string | null }
                                                                        onChange={this.handleSelectState}
                                                                        options={estadoLista}
                                                                        classNamePrefix="select2-selection"
                                                                        />
                                                                        {/* <Input type="text" placeholder="Estado" id="estado" /> */}
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Label htmlFor="cidade" className="col-md-12 col-form-label">Cidade</Label>
                                                                        <Select
                                                                        value={cidade}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={({ inputValue: string }) => string | null }
                                                                        isDisabled={this.state.enabledCity}
                                                                        onChange={(e) => {
                                                                            this.setState({ cidade: e})
                                                                        }}
                                                                        options={cidadeLista}
                                                                        classNamePrefix="select2-selection"
                                                                        />
                                                                        {/* <Input type="text" placeholder="Cidade" id="cidade" /> */}
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <Label htmlFor="pais" className="col-md-12 col-form-label">País</Label>
                                                                        <AvField
                                                                        name="pais"
                                                                        placeholder="País"
                                                                        type="text"
                                                                        errorMessage="Campo obrigatório."
                                                                        className="form-control"
                                                                        value={this.state.pais}
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={(e) => {
                                                                            this.setState({pais: e.target.value})
                                                                        }}
                                                                        id="pais"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </AvForm>
                                                    }
                                                    {this.state.steps === 2 &&
                                                        <AvForm className="cardBody" >
                                                            <Container>
                                                                <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                                                    <Col xs={12}>
                                                                        <Label>Crie uma senha</Label>
                                                                        <Input type="password" value={this.state.senha} onChange={(e) => this.setState({ senha : e.target.value })}></Input>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <Label>Confirme sua senha</Label>
                                                                        <Input type="password" value={this.state.confirmacaoSenha} onChange={(e) => this.setState({ confirmacaoSenha : e.target.value })}></Input>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <PasswordChecklist
                                                                            rules={["minLength","specialChar","number","capital","match"]}
                                                                            minLength={8}
                                                                            iconSize={16}
                                                                            value={this.state.senha}
                                                                            valueAgain={this.state.confirmacaoSenha}
                                                                            onChange={(isValid) => {this.setState({truePassword: isValid})}}
                                                                            messages={{
                                                                                minLength: "Ao menos 8 caracteres.",
                                                                                specialChar: "Ao menos um caractere especial.",
                                                                                number: "Ao menos um número.",
                                                                                capital: "Ao menos uma letra maiúscula.",
                                                                                match: "Suas senhas coincidem.",
                                                                            }}
                                                                            />
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </AvForm>
                                                    }
                                                    {this.state.steps === 3 &&
                                                        <AvForm className="cardBody" >
                                                            <Container>
                                                                <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                                                <Dropzone
                                                                    onDrop={acceptedFiles =>
                                                                    this.handleAcceptedDocs(acceptedFiles)}
                                                                    accept="image/*"
                                                                    maxFiles={1}
                                                                    maxSize={5000000}
                                                                    multiple={false}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div className="dz-message needsclick" {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        <div className="mb-3">
                                                                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                                        </div>
                                                                        <h4 style={{fontSize:'18px'}}>Adicione aqui o cartão cnpj.</h4>
                                                                        </div>
                                                                    </div>
                                                                    )}
                                                                </Dropzone>
                                                                    <div
                                                                        className="dropzone-previews mt-3"
                                                                        id="auth-previews"
                                                                    >
                                                                    {this.state.selectedCnpj.map((f, i) => {
                                                                    return (
                                                                        <Card
                                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                        key={i + "-file"}
                                                                        >
                                                                        <div className="p-2">
                                                                            <Row className="align-items-center">
                                                                            <Col className="col-auto">
                                                                                {f.fileType === 'application/pdf' ? (
                                                                                <i className="ri-file-search-fill" style={{fontSize:'40px', color:'#333'}}></i>
                                                                                ) : (
                                                                                <img
                                                                                    data-dz-thumbnail=""
                                                                                    height="40"
                                                                                    className="avatar-sm rounded bg-light"
                                                                                    alt={f.name}
                                                                                    src={f.preview}
                                                                                />
                                                                                )}
                                                                            </Col>
                                                                            <Col>
                                                                                <Link
                                                                                to="#"
                                                                                className="text-muted fw-bold"
                                                                                >
                                                                                {f.name}
                                                                                </Link>
                                                                                <p className="mb-0">
                                                                                <strong>{f.formattedSize}</strong>
                                                                                </p>
                                                                            </Col>
                                                                            </Row>
                                                                        </div>
                                                                        </Card>
                                                                    );
                                                                    })}
                                                                    </div>
                                                                </Row>
                                                                <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                                                <Dropzone
                                                                    onDrop={acceptedFiles =>
                                                                    this.handleAcceptedDocsRg(acceptedFiles)}
                                                                    accept="image/*"
                                                                    maxFiles={1}
                                                                    maxSize={5000000}
                                                                    multiple={false}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div className="dz-message needsclick" {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        <div className="mb-3">
                                                                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                                        </div>
                                                                        <h4 style={{fontSize:'18px'}}>Adicione aqui uma foto do RG ou CNH do proprietário.</h4>
                                                                        </div>
                                                                    </div>
                                                                    )}
                                                                </Dropzone>
                                                                    <div
                                                                        className="dropzone-previews mt-3"
                                                                        id="auth-previews"
                                                                    >
                                                                    {this.state.selectedRg.map((f, i) => {
                                                                    return (
                                                                        <Card
                                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                        key={i + "-file"}
                                                                        >
                                                                        <div className="p-2">
                                                                            <Row className="align-items-center">
                                                                            <Col className="col-auto">
                                                                                {f.fileType === 'application/pdf' ? (
                                                                                <i className="ri-file-search-fill" style={{fontSize:'40px', color:'#333'}}></i>
                                                                                ) : (
                                                                                <img
                                                                                    data-dz-thumbnail=""
                                                                                    height="40"
                                                                                    className="avatar-sm rounded bg-light"
                                                                                    alt={f.name}
                                                                                    src={f.preview}
                                                                                />
                                                                                )}
                                                                            </Col>
                                                                            <Col>
                                                                                <Link
                                                                                to="#"
                                                                                className="text-muted fw-bold"
                                                                                >
                                                                                {f.name}
                                                                                </Link>
                                                                                <p className="mb-0">
                                                                                <strong>{f.formattedSize}</strong>
                                                                                </p>
                                                                            </Col>
                                                                            </Row>
                                                                        </div>
                                                                        </Card>
                                                                    );
                                                                    })}
                                                                    </div>
                                                                </Row>
                                                            </Container>
                                                        </AvForm>
                                                    }
                                                    <Container>
                                                        <Row style={{justifyContent:'space-between'}}>
                                                            <Col className="col-auto">
                                                                {this.state.steps !== 0 &&
                                                                    <Button type="button" color="dark"  onClick={() => {
                                                                        if(this.state.steps === 1){
                                                                            this.setState({steps: 0})
                                                                        }else if(this.state.steps === 2){
                                                                            this.setState({steps: 1})
                                                                        }else if(this.state.steps === 3){
                                                                            this.setState({steps: 2})
                                                                        }else{
                                                                            this.setState({steps: 3})
                                                                        }
                                                                    }}>Voltar</Button>
                                                                }
                                                            </Col>
                                                            <Col className="col-auto">
                                                                {(this.state.steps !== 2 && this.state.steps !== 3) &&
                                                                    <Button onClick={() => {
                                                                        if(this.state.steps === 0){
                                                                            if((this.state.nome === '' || !this.state.nome)
                                                                            || (this.state.social === '' || !this.state.social)
                                                                            || (this.state.cnpj === '' || !this.state.cnpj)
                                                                            || (this.state.proprietario === '' || !this.state.proprietario)
                                                                            || (this.state.telefone === '' || !this.state.telefone)
                                                                            || (this.state.email === '' || !this.state.email)){
                                                                                this.setState({validPhoto: true})
                                                                                this.setState({validEmail: true})
                                                                                this.setState({validCnpj: true})
                                                                                window.scrollTo(0,0)
                                                                                this.validateRef.current.handleSubmit()
                                                                            }else{
                                                                                this.setState({steps: 1})
                                                                                window.scrollTo(0,0)
                                                                            }
                                                                        }else if(this.state.steps === 1){
                                                                            if((this.state.cep === '' || !this.state.cep)
                                                                            || (this.state.logradouro === '' || !this.state.logradouro)
                                                                            || (this.state.numero === '' || !this.state.numero)
                                                                            || (this.state.cidade === '' || !this.state.cidade)
                                                                            || (this.state.estado === '' || !this.state.estado)
                                                                            || (this.state.bairro === '' || !this.state.bairro)
                                                                            || (this.state.pais === '' || !this.state.pais)){
                                                                                window.scrollTo(0,0)
                                                                                this.validateRef2.current.handleSubmit()
                                                                            }else{
                                                                                this.setState({steps: 2})
                                                                                window.scrollTo(0,0)
                                                                            }
                                                                        }else if(this.state.steps === 2){
                                                                            this.setState({steps: 3})
                                                                        }
                                                                    }}>Avançar</Button>
                                                                }
                                                                {(!this.state.usuarioTipo && this.state.steps === 0) &&
                                                                    <Button disabled>Avançar</Button>
                                                                }
                                                                {(!this.state.truePassword  && this.state.steps === 2) &&
                                                                    <Button disabled>Avançar</Button>
                                                                }
                                                                {(this.state.truePassword  && this.state.steps === 2) &&
                                                                    <Button onClick={() => this.setState({steps: 3})}>Avançar</Button>
                                                                }
                                                                {(this.state.steps === 3) ?
                                                                    (this.state.selectedCnpj
                                                                    && this.state.selectedRg
                                                                    ) ?
                                                                    <Button onClick={() => {
                                                                        document.getElementById('load').classList.add('active')
                                                                        const data = new FormData()
                                                                        data.append('name', this.state.nome)
                                                                        data.append('social_name', this.state.social)
                                                                        data.append('owner_name', this.state.proprietario)
                                                                        data.append('street', this.state.logradouro)
                                                                        data.append('number', this.state.numero)
                                                                        data.append('district', this.state.bairro)
                                                                        data.append('complement', this.state.complemento)
                                                                        data.append('city', this.state.cidade.value)
                                                                        data.append('state', this.state.estado.value)
                                                                        data.append('email', this.state.email)
                                                                        data.append('phone', this.state.telefone)
                                                                        data.append('site', this.state.site)
                                                                        data.append('instagram', this.state.instagram)
                                                                        data.append('country', this.state.pais)
                                                                        data.append('cep', this.state.cep)
                                                                        data.append('type', this.state.usuarioTipo)
                                                                        data.append('password', this.state.senha)
                                                                        data.append('photo_cnpj', this.state.selectedCnpj[0])
                                                                        data.append('status', 2)
                                                                        data.append('cnpj', this.state.cnpj.replace(/[.-\/]/g, '').replace('-', ''))
                                                                        data.append('photo_owner_rg', this.state.selectedRg[0])
                                                                        Axios.post(process.env.REACT_APP_API + '/api/register/construction', data,
                                                                        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                                        .then(response => {
                                                                            this.setState({register_success: true})
                                                                            document.getElementById('load').classList.remove('active')
                                                                            setTimeout(() => {
                                                                                window.open("/obrigado-pelo-cadastro", '_parent')
                                                                            }, 2500);
                                                                        })
                                                                        .catch(response => {
                                                                            this.setState({register_error: true})
                                                                            document.getElementById('load').classList.remove('active')
                                                                        })
                                                                    }}>Concluir</Button>
                                                                    :
                                                                    <Button disabled>Concluir</Button>
                                                                :
                                                                    <></>    
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                    <div className="mt-5 text-center">
                                                        <p>Fast Sale Imóveis</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col> */}
                        </Row>
                    </Container>
            {/* Sucesso na criação da proposta */}

            {this.state.register_success ? (
            <SweetAlert
                title="Cadastro realizado com sucesso!"
                timeout={3000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => this.setState({ register_success: false })}
                ></SweetAlert>
                ) : null}

            {/* Falha na criação da proposta */}

            {this.state.register_error ? (
                <SweetAlert
                title="Seu cadastro falhou, revise as informações e tente novamente."
                timeout={4000}
                showCloseButton={false}
                showConfirm={false}
                error
                onConfirm={() => this.setState({ register_error: false })}
                ></SweetAlert>
                ) : null}

                    <div id="load" className="load">
                        <Spinner className="me-2" color="success" />
                    </div>
                </div>
                <Whats/>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {

    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
}

export default connect(mapStatetoProps, { registerUser, apiError, registerUserFailed })(RegisterConstruction);
