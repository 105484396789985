import {useState, useEffect} from 'react';
import {Button, Label, Spinner} from 'reactstrap';
import Select from "react-select";
import Axios from 'axios';
import './styles.scss';
import SweetAlert from 'react-bootstrap-sweetalert';

export const IndicatorsModal = ({open, onClose, handleUser}) => {

  const [typeUser1, setTypeUser1] = useState('')
  const [typeUser2, setTypeUser2] = useState('')

  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)

  const [usersList, setUsersList] = useState([])
  const [affList, setAffList] = useState([])
  const [selectUser, setSelectUser] = useState([])
  const [selectAff, setSelectAff] = useState([])
  const [registerError, setRegisterError] = useState(false)

  useEffect(() => {
    if(typeUser1 !== ''){
      if(typeUser1 === 'A'){
        handleUsers('1')
      }
      if(typeUser1 === 'C'){
        handleBrokers('1')
      }
      if(typeUser1 === 'I'){
        handleRealEstates('1')
      }
      if(typeUser1 === 'T'){
        handleConstruction('1')
      }
    }
  }, [typeUser1])

  useEffect(() => {
    if(typeUser2 !== ''){
      if(typeUser2 === 'C'){
        handleBrokers('2')
      }
      if(typeUser2 === 'I'){
        handleRealEstates('2')
      }
      if(typeUser2 === 'T'){
        handleConstruction('2')
      }
    }
  }, [typeUser2])

  const handleUsers = (e) => {
    if(e === '1'){setLoading1(true)}
    if(e === '2'){setLoading2(true)}
    Axios.get(process.env.REACT_APP_API + '/api/auth/active-users',
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        if(e === '1'){
          setUsersList(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
          setLoading1(false)
        }
        if(e === '2'){
          setAffList(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
          setLoading2(false)
        }
      })
  }

  const handleBrokers = (e) => {
    Axios.get(process.env.REACT_APP_API + '/api/auth/brokers-select',
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        if(e === '1'){
          setUsersList(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
          setLoading1(false)
        }
        if(e === '2'){
          setAffList(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
          setLoading2(false)
        }
        })
  }

  const handleRealEstates = (e) => {
    Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-select',
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        if(e === '1'){
          setUsersList(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
          setLoading1(false)
        }
        if(e === '2'){
          setAffList(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
          setLoading2(false)
        }
      })
  }

  const handleConstruction = (e) => {
    Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions',
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        if(e === '1'){
          setUsersList(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
          setLoading1(false)
        }
        if(e === '2'){
          setAffList(response.data?.map(item => ({
            value: item.id,
            label: item.name
          })))
          setLoading2(false)
        }
      })
  }

  const handleCreate = () => {

    const data ={
      user_type:typeUser2,
      user_id:selectAff.value,
      affiliate_type:typeUser1,
      affiliate_id:selectUser.value,
      origin:'manual',
    }

    Axios.post(process.env.REACT_APP_API + '/api/register/affiliate-indicator', data,
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        handleUser()
        handleClose()
      }).catch(response => {
        setRegisterError(true)
      })
  }

  const handleClose = () => {
    setTypeUser1('')
    setTypeUser2('')
    onClose()
  }

  return(
    <>
      <div className={`indicators-modal ${open ? 'active' : ''}`}>
        <span className="indicators-close" onClick={handleClose}><i className="ri-close-line"></i></span>
        <div className="indicators-modal-content">
          <div className="modal-title">
              <h3>Criar relação</h3>
          </div>
          <div className="indicator-modal-body">
            <div><Label>Quem indicou:</Label></div>
            <div className="type-user-container">
              <div
                className={`type-user-button ${typeUser1 === 'A' ? 'active' : ''}`}
                onClick={() => setTypeUser1('A')}
              >Administrador</div>
              <div
                className={`type-user-button ${typeUser1 === 'C' ? 'active' : ''}`}
                onClick={() => setTypeUser1('C')}
              >Corretor</div>
              <div
                className={`type-user-button ${typeUser1 === 'I' ? 'active' : ''}`}
                onClick={() => setTypeUser1('I')}
              >Imobiliária</div>
              <div
                className={`type-user-button ${typeUser1 === 'T' ? 'active' : ''}`}
                onClick={() => setTypeUser1('T')}
              >Construtora</div>
            </div>
            <div style={{maxWidth:'300px', marginBottom:'15px'}}>
              {loading1 ?
                <Spinner/>
              :
              usersList.length > 0 &&
                <Select
                  value={selectUser}
                  placeholder="Selecione"
                  noOptionsMessage={(inputValue) => `Sem resultados`}
                  onChange={(e) => setSelectUser(e)}
                  options={usersList}
                  classNamePrefix="select2-selection"
                />
              }
            </div>
            <div><Label>Indicação:</Label></div>
            <div className="type-user-container">
              <div
                className={`type-user-button ${typeUser2 === 'C' ? 'active' : ''}`}
                onClick={() => setTypeUser2('C')}
              >Corretor</div>
              <div
                className={`type-user-button ${typeUser2 === 'I' ? 'active' : ''}`}
                onClick={() => setTypeUser2('I')}
              >Imobiliária</div>
              <div
                className={`type-user-button ${typeUser2 === 'T' ? 'active' : ''}`}
                onClick={() => setTypeUser2('T')}
              >Construtora</div>
            </div>
            <div style={{maxWidth:'300px', marginBottom:'15px'}}>
              {loading2 ?
                  <Spinner/>
                :
                affList.length > 0 &&
                  <Select
                    value={selectAff}
                    placeholder="Selecione"
                    noOptionsMessage={(inputValue) => `Sem resultados`}
                    onChange={(e) => setSelectAff(e)}
                    options={affList}
                    classNamePrefix="select2-selection"
                  />
              }
            </div>
          </div>
          <div className="indicator-modal-footer">
            <Button color={'dark'} onClick={handleClose}>Fechar</Button>
            <Button onClick={handleCreate}>Criar relação</Button>
          </div>
        </div>
      </div>
      <div onClick={handleClose} className={`indicators-modal-overlay ${open ? 'active' : ''}`}></div>

      {registerError ? (
        <SweetAlert
          title="O usuário selecionado já tem outro usuário relacionado."
          showCloseButton={true}
          showConfirm={true}
          error
          onConfirm={() => {
            setRegisterError(false)
          }}
        ></SweetAlert>
      ) : null}
    </>
  )
}