import styles from '../styles/Contracts.module.css';
import contracts from '../data/contracts';
import api from '../services/api';
import { useEffect, useState } from 'react';
import Icon from './../../../assets/images/Icons/value.png'

const Contract = ({ contract }) => {
  return (
    <div className={styles.container_contracts}>
      <img src={contract.src} alt={contract.alt} />
      <div className={styles.container_content}>
        <p className={styles.content_emphasis}>{contract.emphasis}</p>
        <p>{contract.text}</p>
      </div>
    </div>
  )
}

const Contracts = () => {
  const [properties, setProperties] = useState(0)
  const formattedValue = parseFloat(properties / 100000000000).toLocaleString("pt-BR", { maximumSignificantDigits: 2 }).toLocaleString("pt-BR", { minimumSignificantDigits: 1 });

  useEffect(() => {
    api
      .get('/api/public/get-count')
      .then((response) => {
        setProperties(response.data.properties);
      })
  }, [])

  const newContract = {
    src:Icon,
    alt:'',
    emphasis:`+ ${formattedValue}bi em imóveis`,
    text:'na sua pauta'
  }
  
  return(
    <section className={styles.contracts}>
      <div className={styles.contractsWrapper}>
        <Contract contract={newContract} />
        {contracts.map((contract, index) => (
          <Contract key={index} contract={contract} />
        ))}
      </div>
    </section>
  )
}

export default Contracts;
