import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, CardImg, Row, CardTitle, CardText, Badge } from "reactstrap";

import './../../assets/scss/custom/components/_horizontalCard.scss'
import './../../assets/scss/custom/components/_imovelCard.scss';

class HorizontalCardNumbers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }


    render() {
        return (
            <React.Fragment>
              <Link to={"../imovel/" + this.props.imovel?.slug + '?owner'} target="_parent">
                <div className="HorizontalCardNumbers" onClick={() => {
                  setTimeout(() => {
                    window.open("../imovel/" + this.props.imovel?.slug, '_parent')
                  }, 300);
                }}>
                    <Row className="no-gutters align-items-center">
                        <Col xs={5} style={{paddingRight:'0px'}}>
                          <Row>
                            <Col xs={5} style={{paddingLeft:'15px', paddingRight:'0px'}}>
                              <img style={{borderRadius:'5px', objectFit:'cover', width:'100%', height:'50px'}} src={this.props.imovel?.one_photo?.small_image}></img>
                            </Col>
                            <Col xs={7} style={{padding:'0 5px'}}>
                              <CardBody style={{padding:'0', justifyContent:'space-between', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                                <p className="propertyValueSku">{this.props.imovel.sku}</p>
                                <p className="propertyValueTitle">{this.props.imovel.title}</p>
                              </CardBody>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={7}>
                          <Row>
                            <Col className="propertyValueLegendCompartilhamentos">
                              <i className="ri-stackshare-line"></i>
                              <div className="propertyValueAnalytics">{this.props.imovel.compartilhamentos}</div>
                            </Col>
                            <Col className="propertyValueLegendParceria">
                              <i className="ri-file-copy-2-line"></i>
                              <div className="propertyValueAnalytics">{this.props.imovel.contratos_parceria}</div>
                            </Col>
                            <Col className="propertyValueLegendDownloads">
                              <i className="ri-download-2-line"></i>
                              <div className="propertyValueAnalytics">{this.props.imovel.downloads}</div>
                            </Col>
                            <Col className="propertyValueLegendPropostas">
                              <i className="ri-briefcase-line"></i>
                              <div className="propertyValueAnalytics">{this.props.imovel.propostas}</div>
                            </Col>
                          </Row>
                          <Row> 
                            {/* <Col className="propertyValueLegendAgendamentos">
                              <i className="ri-calendar-event-line"></i>
                              <div className="propertyValueAnalytics">{this.props.imovel.agendamentos}</div>
                            </Col> */}

                            {/* <Col className="propertyValueLegendIntegracoes">
                              <i className="ri-uninstall-line"></i>
                              <div className="propertyValueAnalytics">{this.props.imovel.integracoes}</div>
                            </Col> */}

                            {/* <Col className="propertyValueLegendConversas">
                              <i className="ri-chat-1-line"></i>
                              <div className="propertyValueAnalytics">{this.props.imovel.conversas}</div>
                            </Col> */}

                            {/* <Col className="propertyValueLegendVisitas">
                              <i className="ri-eye-fill"></i>
                              <div className="propertyValueAnalytics">{this.props.imovel.visitas}</div>
                            </Col> */}
                          </Row>
                        </Col>
                    </Row>
                </div>
              </Link>
            </React.Fragment>
        );
    }
}

export default HorizontalCardNumbers;