import styles from '../styles/ImobileSecond.module.css'
import Teste from '../assets/foto_fake.jpg';
import Icon from '@mdi/react';
import { mdiBedKingOutline, mdiCarBack, mdiRulerSquare, mdiShare, mdiMapMarker } from '@mdi/js';
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from 'swiper';
import ImobileSecondMobile from './ImobileSecondMobile';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ChromePicker } from 'react-color';
import ContentEditable from "react-contenteditable";
import Maps from '../../../pages/SingleProperty/Maps';


const ImobileSecond = () => {
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [showPickerButton, setShowPickerButton] = useState(false);
  const [showPickerTextButton, setShowPickerTextButton] = useState(false);
  const [ highTitle, setHighTitle] = useState('Destaques')
  const [ highTitleEnabled, setHighTitleEnabled] = useState(true)
  const [logo, setLogo] = useState();

  const [isMobile, setIsMobile] = useState(false);
  const isMobileQuery = useMediaQuery({ query: `(max-width: 767px)` });

  function handleLogoFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const fileAsBase64String = reader.result.split(',')[1];
      localStorage.setItem('nav_logo', fileAsBase64String);
    });
    reader.readAsDataURL(file);
  }

  function isBase64(str) {
    try {
      atob(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    setIsMobile(isMobileQuery);

    const navLogo = localStorage.getItem('nav_logo');
    if (navLogo && navLogo.slice(0, 4) !== 'http') {
      if (localStorage.getItem('nav_logo')) {
        const fileAsBase64String = localStorage.getItem('nav_logo');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
  
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
  
          const blob = new Blob([array], { type: 'application/octet-stream' });
  
          const file = new File([blob], 'nome-do-arquivo.extensão');
  
          setLogo(file);
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      }
    } else {
      setLogo(navLogo);
    }

    if(localStorage.getItem('imobile_second_button_text_color')){
      setColorTextButton(JSON.parse(localStorage.getItem('imobile_second_button_text_color')))
    }

    if(localStorage.getItem('imobile_second_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('imobile_second_button_color')))
    }

    if(localStorage.getItem('high_imobile_title')){
      setHighTitle(localStorage.getItem('high_imobile_title'))
    }
  }, [isMobileQuery]);

  if (isMobile) {
    return <ImobileSecondMobile />;
  }

  return(
    <>
      <div className={styles.container_logo}>
        {logo ? (
          <img src={(logo && logo.slice(0, 4) !== "http") ? URL.createObjectURL(logo) : logo}
            alt=""
            className={styles.logo}
          />
        ) : (
          <div className={styles.semLogo}>Sua logo</div>
        )}
        <label for="logo" className={styles.inputLogo}><i className="ri-camera-fill"></i></label>
        <input id="logo" name="logo" type="file" style={{display:'none'}} onChange={(e) => {setLogo(e.target.files[0]); handleLogoFileChange(e)}} />
      </div>    
      <section>
        <div className={styles.container_imgs}>
          <img src={Teste} alt="" />
          <img src={Teste} alt="" />
          <img src={Teste} alt="" />
        </div>
        <div className={styles.container_content}>
          <div className={styles.container_config}>
            <p className={styles.container_config_title}>Casa de alto padrão no condomínio Caledônia em Camboriú</p>
            <div className={styles.container_imobile}>
              <div>
                <p className={styles.config_icon}><Icon path={mdiBedKingOutline} size={.9} /> 3 suítes</p>
                <p className={styles.config_icon}><Icon path={mdiCarBack} size={.9} />2 vagas</p>
                <p className={styles.config_icon}><Icon path={mdiRulerSquare} size={.9} />127 m</p>
              </div>
              <div className={styles.container_location}>
                <Icon path={mdiMapMarker} size={.7}/>
                <p>Camboriú</p>
              </div>
            </div>
            <div>
              <p className={styles.price} >R$4.000.000,00</p>
            </div>
            <div className={styles.iptu}>
              <p>Condomínio: R$1.100,00</p>
              <p>IPTU: R$2.000,00</p>
            </div>
            <div className={styles.container_about}>
              <p className={styles.imobile}>Sobre o imóvel</p>

              <div>
                <p>- 3 Suítes (Sendo uma Master com Hidro)</p>
                <p>- 3 Banheiros</p>
                <p>- Lavabo</p>
                <p>- Churrasqueira</p>
                <p>- Área de Serviço</p>
                <p>- Living</p>
                <p>- Sala de Jantar</p>
                <p>- Sala de Jantar</p>
                <p>- Cozinha</p>
                <p>- Sacada Integrada</p>

                <p className={styles.characteristics}>Características do Empreendimento</p>

                <p>- Acessibilidade para Cadeirantes</p>
                <p>- Acessibilidade para Cadeirantes</p>
                <p>- Acessibilidade para Cadeirantes</p>
                <p>- Acessibilidade para Cadeirantes</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <button className={styles.information} style={{
              backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
              color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
            }}>Quero mais informações</button>
            </div>
            <div className={styles.actionIcons2}>
              <label className="title">
                <i className="ri-brush-fill" onClick={() => {
                  setShowPickerButton(!showPickerButton)
                  setShowPickerTextButton(false)
                  }}></i>
                <i className="ri-font-color" onClick={() => {
                  setShowPickerTextButton(!showPickerTextButton)
                  setShowPickerButton(false)
                  }}></i>
                {showPickerButton && (
                  <div className={styles.colorPickerButton}>
                    <i className="ri-close-fill closeIcon" onClick={() => setShowPickerButton(!showPickerButton)}></i>
                    <ChromePicker
                      color={colorButton ?? {r: 0, g: 0, b: 0, a: 1}}
                      onChange={(e) => {
                        setColorButton(e.rgb)
                        localStorage.setItem('imobile_second_button_color', JSON.stringify(e.rgb))
                      }}
                    />
                    </div>
                  )}
                  {showPickerTextButton && (
                  <div className={styles.colorPickerButton}>
                    <i className="ri-close-fill closeIcon" onClick={() => setShowPickerTextButton(!showPickerTextButton)}></i>
                    <ChromePicker
                      color={colorTextButton ?? {r: 0, g: 0, b: 0, a: 1}}
                      onChange={(e) => {
                        setColorTextButton(e.rgb)
                        localStorage.setItem('imobile_second_button_text_color', JSON.stringify(e.rgb))
                      }}
                    />
                    </div>
                  )}
              </label>
            </div>
            
            <div>
              <button className={styles.share} style={{
              backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
              color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
            }}>Compartilhe <Icon path={mdiShare} size={1} /></button>
            </div>

            <div className={styles.maps}>
              <Maps latI={-26.990299595751235} lngI={-48.638515207944316} zoomLevel={13}/>
            </div>
          </div>
        </div>

        <div>
          {/* <h2 className={styles.title_imobiles}>Imóveis semelhantes a este</h2> */}
          <div className={styles.titleContainer}>
            <ContentEditable
                html={highTitle}
                className={styles.titleEdit}
                id='highTitleEdit'
                disabled={highTitleEnabled}
                onChange={(e) => {
                  setHighTitle(e.target.value)
                  localStorage.setItem('high_imobile_title', e.target.value)
                }}
                onClick={(e) => {
                  if(highTitle === 'Destaques' && !highTitleEnabled){
                    setHighTitle('')
                    localStorage.setItem('high_imobile_title', '')
                  }
                }}
              />
            <div className={styles.actionIcons}>
              <label className="title">
                <span onClick={() => {
                  if(!highTitleEnabled){
                    setHighTitleEnabled(true)
                    if(highTitle === ''){
                      setHighTitle('Destaques')
                      localStorage.setItem('high_imobile_title', 'Destaques')
                    }
                  }else{
                    setHighTitleEnabled(false)
                    setTimeout(() => {
                      document.getElementById('highTitleEdit').focus()
                      if(highTitle === 'Destaques'){
                        setHighTitle('')
                        localStorage.setItem('high_imobile_title', '')
                      }
                    }, 300);
                  }
                }}>{highTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
                <i className="ri-text" onClick={() => {
                  if(!highTitleEnabled){
                    setHighTitleEnabled(true)
                    if(highTitle === ''){
                      setHighTitle('Destaques')
                      localStorage.setItem('high_imobile_title', 'Destaques')
                    }
                  }else{
                    setHighTitleEnabled(false)
                    setTimeout(() => {
                      document.getElementById('highTitleEdit').focus()
                      if(highTitle === 'Destaques'){
                        setHighTitle('')
                        localStorage.setItem('high_imobile_title', '')
                      }
                    }, 300);
                  }
                }}></i>
              </label>
            </div>
          </div>

          <div className={styles.container_carousel}>
          <Swiper 
            slidesPerView={3}
            spaceBetween={0}
            navigation={true} 
            modules={[Navigation]} 
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 1
              },
              759: {
                slidesPerView: 3
              }
            }}>
            <SwiperSlide>
              <div className={styles.container}>
                <div className={styles.immobile}>
                  <div className={styles.bg}></div>
                  <div className={styles.immobile_config}>
                    <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                    <div className={styles.immobile_options}>
                      <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 
                        3 suítes
                      </span>
                      <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                      <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9} /> 152m</span>
                    </div>
                    <div>
                      <span className={styles.immobile_price}>R$4.000.000,00</span>
                    </div>
                    <div className={styles.immobile_view}>
                      <div className={styles.details}></div>
                      <p style={{
                        backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                        color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                      }}>Ver imóvel</p> 
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container}>
                <div className={styles.immobile}>
                  <div className={styles.bg}></div>
                  <div className={styles.immobile_config}>
                    <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                    <div className={styles.immobile_options}>
                      <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 
                        3 suítes
                      </span>
                      <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                      <span className={styles.container_icons}><Icon path={mdiRulerSquare}  size={.9}/> 152m</span>
                    </div>
                    <div>
                      <span className={styles.immobile_price}>R$4.000.000,00</span>
                    </div>
                    <div className={styles.immobile_view}>
                      <div className={styles.details}></div>
                      <p style={{
                        backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                        color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                      }}>Ver imóvel</p> 
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container}>
                <div className={styles.immobile}>
                  <div className={styles.bg}></div>
                  <div className={styles.immobile_config}>
                    <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                    <div className={styles.immobile_options}>
                      <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 
                        3 suítes
                      </span>
                      <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                      <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9} /> 152m</span>
                    </div>
                    <div>
                      <span className={styles.immobile_price}>R$4.000.000,00</span>
                    </div>
                    <div className={styles.immobile_view}>
                      <div className={styles.details}></div>
                      <p style={{
                        backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                        color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                      }}>Ver imóvel</p> 
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.container}>
                <div className={styles.immobile}>
                  <div className={styles.bg}></div>
                  <div className={styles.immobile_config}>
                    <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                    <div className={styles.immobile_options}>
                      <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 
                        3 suítes
                      </span>
                      <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                      <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9} /> 152m</span>
                    </div>
                    <div>
                      <span className={styles.immobile_price}>R$4.000.000,00</span>
                    </div>
                    <div className={styles.immobile_view}>
                      <div className={styles.details}></div>
                      <p style={{
                        backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                        color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                      }}>Ver imóvel</p> 
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          </div>
        </div>
      </section>
    </>
  )
}

export default ImobileSecond;