import { Badge, Button, Card, CardBody, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row  } from "reactstrap";
import { useEffect, useState } from 'react';
import Axios from 'axios';
import Select from "react-select";
require('moment/locale/pt.js');

export const FeedModal = ({openModal, closeModal, editId, isEdit}) => {

    const [category, setCategory] = useState(0)
    const [districtOptions, setDistrictOptions] = useState([])
    const [citiesOptions, setCitiesOptions] = useState([])
    const [stateSelected, setStateSelected] = useState([])
    const [statesOptions, setStatesOptions] = useState([])
    const [citySelected, setCitySelected] = useState([])
    const [districtSelected, setDistrictSelected] = useState([])
    const [valueMax, setValueMax] = useState('')
    const [valueMin, setValueMin] = useState('')
    const [minRooms, setMinRooms] = useState(0)
    const [maxRooms, setMaxRooms] = useState(0)
    const [minGarage, setMinGarage] = useState(0)
    const [maxGarage, setMaxGarage] = useState(0)
    const [minArea, setMinArea] = useState(0)
    const [maxArea, setMaxArea] = useState(0)
    const [enterprise, setEnterprise] = useState('')
    const [count, setCount] = useState(0)
    const [modalType, setModalType] = useState('')
    const [orderId, setOrderId] = useState(0)
    const [type, setType] = useState('')
    const [otherType, setOtherType] = useState('')
    const [obs, setObs] = useState('')
    const [districtIsDisabled, setDistrictIsDisabled] = useState(true)
    const [cityIsDisabled, setCityIsDisabled] = useState(true)
    const [objective, setObjective] = useState('sell')

    
    useEffect(() => {
        if(stateSelected.value){
        Axios.get(process.env.REACT_APP_API + '/api/auth/get-cities-by-state/' + stateSelected.value, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        })
        .then(response => {
            const newCityOptions = response.data?.filter(cities => cities.city !== 'undefined')?.map(city => ({
            label: city.city,
            value: city.city
            }));
            setCitiesOptions(newCityOptions);
            setCityIsDisabled(false)
        });
        }
    }, [stateSelected]);
    
    useEffect(() => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/get-states', {
        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        })
        .then(response => {
        const newStateOptions = response.data.map(state => ({
            label: state.state,
            value: state.state
        }));
        setStatesOptions(newStateOptions);
        });
    }, []);

    useEffect(() => {
        if(citySelected.value){
        Axios.get(process.env.REACT_APP_API + '/api/auth/get-districts-by-city?city=' + citySelected.value, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        })
        .then(response => {
            const newDistrictOptions = response.data.map(district => ({
            label: district.district,
            value: district.district
            }));
            setDistrictOptions(newDistrictOptions);
            setDistrictIsDisabled(false)
        });
        }
    }, [citySelected])

    const handleChangePrice = (value) => {
        if(value){
            const valueOnlyDigits = value.toString().replace(/[^\d]/g, '');

            const integerPart = valueOnlyDigits.slice(0, -2);
            const decimalPart = valueOnlyDigits.slice(-2);
    
            let formattedValue = '';
            for (let i = 0; i < integerPart?.length; i++) {
            formattedValue += integerPart[i];
            if ((integerPart?.length - i - 1) % 3 === 0 && i !== integerPart?.length - 1) {
                formattedValue += '.';
            }
            }
            formattedValue = `R$ ${formattedValue},${decimalPart}`;
    
            return formattedValue;
        }
    };

    
    const handleCreate = () => {

        const url = isEdit ? `/api/auth/update-order/${editId}` : '/api/auth/create-order'
        const data = new FormData()
        if(localStorage.getItem('access') === 'C'){
            data.append('broker_id', localStorage.getItem('id'))
        }
        if(localStorage.getItem('access') === 'I'){
            data.append('real_estate_id', localStorage.getItem('id'))
        }
        data.append('category', category !== 0 ? category : null)
        data.append('district', districtSelected.value ? districtSelected.value : null)
        data.append('city', citySelected.value ? citySelected.value : null)
        data.append('state', stateSelected.value ? stateSelected.value : null)
        data.append('min_rooms', minRooms)
        data.append('max_rooms', maxRooms)
        data.append('max_parking', maxGarage)
        data.append('min_parking', minGarage)
        data.append('min_area', minArea)
        data.append('max_area', maxArea)
        data.append('obs', obs)
        data.append('objective', objective)
        data.append('high', type === 'Outro' ? otherType : type)
        data.append('min_price', valueMin !== '' && valueMin !== 0 && valueMin !== undefined ? parseInt(valueMin?.replace(/[R$.,]/g, '')) : 0)
        data.append('max_price', valueMax !== '' && valueMax !== 0 && valueMax !== undefined ? parseInt(valueMax?.replace(/[R$.,]/g, '')) : 0)
        data.append('enterprise', enterprise)
        data.append('status', 1)

        Axios.post(process.env.REACT_APP_API + url, data,
        {headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            setCount(prevState => prevState + 1)
            setCategory(0)
            setMinRooms(0)
            setMaxRooms(0)
            setMinGarage(0)
            setMaxGarage(0)
            setMinArea(0)
            setMaxArea(0)
            setEnterprise('')
            setObs('')
            setType('')
            setOtherType('')
            setObjective('sell')
            setDistrictSelected([])
            setCitySelected([])
            setStateSelected([])
            setValueMin(0)
            setValueMax(0)
            setOrderId(0)

            setTimeout(() => {
                if(localStorage.getItem('access') === 'A'){
                    window.location.reload()
                }else{
                    window.open('/dashboard', '_parent')
                }
            }, 300);
        })
    }

    const handleClear = () => {
        setCategory(0)
        setMinRooms(0)
        setMaxRooms(0)
        setMinGarage(0)
        setMaxGarage(0)
        setMinArea(0)
        setMaxArea(0)
        setObjective('sell')
        setEnterprise('')
        setDistrictSelected([])
        setCitySelected([])
        setStateSelected([])
        setValueMin(0)
        setValueMax(0)
        setOrderId(0)

        closeModal()
    }

    useEffect(() => {
        if(editId){
            handleUpdate()
        }
    }, [isEdit, editId])

    const handleUpdate = () => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/list-order/' + editId,
        {headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            setCategory(response.data.category?.id || 0)
            setMinRooms(response.data.min_rooms || 0)
            setMaxRooms(response.data.max_rooms || 0)
            setMinGarage(response.data.min_parking || 0)
            setMaxGarage(response.data.max_parking || 0)
            setMinArea(response.data.min_area || 0)
            setMaxArea(response.data.max_area || 0)
            setType(response.data.type || '')
            setOtherType(response.data.type || '')
            setObs(response.data.obs || '')
            setObjective(response.data.objective || 'sell')
            setEnterprise(response.data.enterprise || '')
            setDistrictSelected({label: response.data.district, value: response.data.district}, [])
            setCitySelected({label: response.data.city, value: response.data.city}, [])
            setStateSelected({label: response.data.state, value: response.data.state}, [])
            setValueMin(handleChangePrice(response.data.min_price || 0))
            setValueMax(handleChangePrice(response.data.max_price || 0))
            setOrderId(response.data.id)
        })
    }


    const apTypes = ['Frente Mar', 'Quadra Mar', 'Cobertura', 'Duplex', 'Garden', 'Loft', 'Kitnet/Studio', 'Apartamento Padrão', 'Penthouse', 'Outro']
    const houseTypes = ['Térrea', 'Sobrado', 'Geminada', 'Casa de Condomínio', 'Casa de Campo', 'Mansão', 'Chalé', 'Bangalô', 'Outro']
    const comTypes = ['Sala Comercial', 'Loja/Ponto Comercial', 'Galpão/Depósito/Armazém', 'Escritório', 'Prédio Inteiro', 'Sala de Reunião/Coworking', 'Loja em Shopping', 'Salão de Beleza', 'Outro']
    const terTypes = ['Terreno Residencial', 'Terreno Comercial', 'Terreno Industrial', 'Loteamento', 'Terreno Rural', 'Terreno para Incorporação', 'Terreno em Condomínio', 'Terreno de Esquina', 'Outro']
    const otherTypes = ['Sítio', 'Fazenda', 'Chácara', 'Haras', 'Área Logística', 'Área Industrial', 'Área Rural', 'Área para Desenvolvimento Imobiliário', 'Outro']

    return (
    <Modal
        isOpen={openModal}
        toggle={handleClear}
        size="xl"
        centered={true}
        scrollable={true}
    >
        <ModalHeader toggle={handleClear} style={{padding:'0 80px'}}>
            <h3 style={{fontSize:'18px'}}>Novo pedido de imóvel</h3>
        </ModalHeader>
        <ModalBody>
            <Row style={{gap:'20px'}}>
                <Col lg={7}>
                    <Label>Categoria</Label>
                    <div className="category-container">
                        <span
                            className={`category-item ${category === 12 ? 'active' : ''}`}
                            onClick={() => setCategory(12)}
                        >Apartamento</span>
                        <span
                            className={`category-item ${category === 14 ? 'active' : ''}`}
                            onClick={() => setCategory(14)}
                        >Casa</span>
                        <span
                            className={`category-item ${category === 34 ? 'active' : ''}`}
                            onClick={() => setCategory(34)}
                        >Comercial</span>
                        <span
                            className={`category-item ${category === 22 ? 'active' : ''}`}
                            onClick={() => setCategory(22)}
                        >Terreno</span>
                        <span
                            className={`category-item ${category === 35 ? 'active' : ''}`}
                            onClick={() => setCategory(35)}
                        >Outros</span>
                    </div>
                </Col>
                <Col lg={4}>
                    <Label>Objetivo</Label>
                    <div className="category-container">
                        <span
                            className={`category-item ${objective === 'sell' ? 'active' : ''}`}
                            onClick={() => setObjective('sell')}
                        >Compra</span>
                        <span
                            className={`category-item ${objective === 'rent' ? 'active' : ''}`}
                            onClick={() => setObjective('rent')}
                        >Aluguel</span>
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: '20px'}}>
                <Col md={6}>
                    {category === 12 &&
                    <>
                        <Label className="form-label">Tipo</Label>
                        <Input type="select" value={type} onChange={(e) => setType(e.target.value)}>
                            <option value={null}>Selecione</option>
                            {apTypes.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </Input>
                    </>    
                    }
                    {category === 14 &&
                    <>
                        <Label className="form-label">Tipo</Label>
                        <Input type="select" value={type} onChange={(e) => setType(e.target.value)}>
                            <option value={null}>Selecione</option>
                            {houseTypes.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </Input>
                    </>
                    }
                    {category === 34 &&
                    <>
                        <Label className="form-label">Tipo</Label>
                        <Input type="select" value={type} onChange={(e) => setType(e.target.value)}>
                            <option value={null}>Selecione</option>
                            {comTypes.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </Input>
                    </>
                    }
                    {category === 22 &&
                    <>
                        <Label className="form-label">Tipo</Label>
                        <Input type="select" value={type} onChange={(e) => setType(e.target.value)}>
                            <option value={null}>Selecione</option>
                            {terTypes.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </Input>
                    </>
                    }
                    {category === 35 &&
                    <>
                        <Label className="form-label">Tipo</Label>
                        <Input type="select" value={type} onChange={(e) => setType(e.target.value)}>
                            <option value={null}>Selecione</option>
                            {otherTypes.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </Input>
                    </>
                    }
                </Col>
                <Col md={6}>
                    {type === 'Outro' &&
                        <>
                            <Label className="form-label">Escreva o tipo</Label>
                            <Input type="text" value={otherType} onChange={(e) => setOtherType(e.target.value)}></Input>
                        </>
                    }
                </Col>
            </Row>

            <Row style={{marginTop: '20px'}}>
                <Col xs={12}>
                    <Label className="form-label">Descrição</Label>
                    <Input
                        type="text"
                        value={obs}
                        maxLength={100}
                        placeholder="Descreva brevemente o que procura"
                        onChange={(e) => setObs(e.target.value)}
                    ></Input>
                    <small>{obs?.length} / 100</small>
                </Col>
            </Row>

            <Row style={{marginTop: '20px'}}>
                <Col md={6}>
                    <Label className="form-label">Estado</Label>
                    <Select
                        value={stateSelected}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Sem resultados`}
                        onChange={(e) => {
                        localStorage.setItem('filtro-estados', e.value)
                        setStateSelected(e)
                        setCitySelected([])
                        setDistrictSelected([])
                        setCitiesOptions([])
                        setDistrictOptions([])
                        }}
                        options={statesOptions}
                        classNamePrefix="select2-selection"
                    />
                    <small style={{
                    textDecoration:'underline',
                    marginTop:'5px',
                    cursor:'pointer'
                    }} onClick={() => {
                    setStateSelected([])
                    setCitySelected([])
                    setDistrictSelected([])
                    setCitiesOptions([])
                    setDistrictOptions([])
                    setDistrictIsDisabled(true)
                    setCityIsDisabled(true)
                    setTimeout(() => {
                        localStorage.setItem('filtro-estados', '')
                    }, 300);
                    }}>Limpar</small>
                </Col>
                <Col md={6}>
                    <Label className="form-label">Cidade</Label>
                    <Select
                        value={citySelected}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Selecione um estado`}
                        isDisabled={cityIsDisabled}
                        onChange={(e) => {
                        localStorage.setItem('filtro-cidades', e.value)
                        setCitySelected(e)
                        setDistrictSelected([])
                        setDistrictOptions([])
                        }}
                        options={citiesOptions}
                        classNamePrefix="select2-selection"
                    />
                    <small style={{
                    textDecoration:'underline',
                    marginTop:'5px',
                    cursor:'pointer'
                    }} onClick={() => {
                    setCitySelected([])
                    setDistrictSelected([])
                    setDistrictOptions([])
                    setDistrictIsDisabled(true)
                    setTimeout(() => {
                        localStorage.setItem('filtro-cidades', '')
                    }, 300);
                    }}>Limpar</small>
                </Col>
            </Row>
            <Row style={{marginTop: '20px'}}>
                <Col md={6}>
                    <Label className="form-label">Bairro</Label>
                    <Select
                        value={districtSelected}
                        placeholder="Selecione"
                        noOptionsMessage={(inputValue) => `Selecione uma cidade`}
                        isDisabled={districtIsDisabled}
                        onChange={(e) => {
                        setDistrictSelected(e)
                        localStorage.setItem('filtro-bairros', e.value)
                        }}
                        options={districtOptions}
                        classNamePrefix="select2-selection"
                        disabled={true}
                    />
                    <small style={{
                    textDecoration:'underline',
                    marginTop:'5px',
                    cursor:'pointer'
                    }} onClick={() => {
                    setDistrictSelected([])
                    setTimeout(() => {
                        localStorage.setItem('filtro-bairros', '')
                    }, 300);
                    }}>Limpar</small>
                </Col>
                {category !== 22 &&
                    <Col md={6}>
                        <Label className="form-label" style={{display:'block'}}>Empreendimento</Label>
                        <Input
                            type="text"
                            value={enterprise}
                            onChange={(e) => setEnterprise(e.target.value)}
                            placeholder="Nome do empreendimento"
                        />
                    </Col>
                }
            </Row>

            <Row className="row-range-order">
                <Col className="col-range-order">
                    <Row>
                        <Col xs={12}><Label className="form-label" style={{color:'#3E5E3F'}}>Valor</Label></Col>
                        <Col xs={6}>
                            <Label className="form-label" style={{display:'block'}}>de</Label>
                            <Input
                                type="text"
                                value={valueMin}
                                inputMode="numeric"
                                onChange={(e) => setValueMin(handleChangePrice(e.target.value))}
                                placeholder="R$ 0,00"
                            />
                        </Col>
                        <Col xs={6}>
                            <Label className="form-label" style={{display:'block'}}>até</Label>
                            <Input
                                type="text"
                                value={valueMax}
                                inputMode="numeric"
                                onChange={(e) => setValueMax(handleChangePrice(e.target.value))}
                                placeholder="R$ 0,00"
                            />
                        </Col>
                    </Row>
                </Col>
                {category !== 22 && category !== 34 &&
                <Col className="col-range-order">
                    <Row>
                        <Col xs={12}><Label className="form-label" style={{color:'#3E5E3F'}}>Quantidade de quartos</Label></Col>
                        <Col xs={6}>
                            <Label className="form-label" style={{display:'block'}}>de</Label>
                            <Input
                                type="number"
                                value={minRooms}
                                onChange={(e) => setMinRooms(e.target.value)}
                            />
                        </Col>
                        <Col xs={6}>
                            <Label className="form-label" style={{display:'block'}}>até</Label>
                            <Input
                                type="number"
                                value={maxRooms}
                                onChange={(e) => setMaxRooms(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
                }
                {category !== 22 && category !== 34 &&
                <Col className="col-range-order">
                    <Row>
                        <Col xs={12}><Label className="form-label" style={{color:'#3E5E3F'}}>Vagas de garagem</Label></Col>
                        <Col xs={6}>
                            <Label className="form-label" style={{display:'block'}}>de</Label>
                            <Input
                                type="number"
                                value={minGarage}
                                onChange={(e) => setMinGarage(e.target.value)}
                            />
                        </Col>
                        <Col xs={6}>
                            <Label className="form-label" style={{display:'block'}}>até</Label>
                            <Input
                                type="number"
                                value={maxGarage}
                                onChange={(e) => setMaxGarage(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
                }
                <Col className="col-range-order">
                    <Row>
                        <Col xs={12}><Label className="form-label" style={{color:'#3E5E3F'}}>Tamanho (em m²)</Label></Col>
                        <Col xs={6}>
                            <Label className="form-label" style={{display:'block'}}>de</Label>
                            <Input
                                type="number"
                                value={minArea}
                                onChange={(e) => setMinArea(e.target.value)}
                            />
                        </Col>
                        <Col xs={6}>
                            <Label className="form-label" style={{display:'block'}}>até</Label>
                            <Input
                                type="number"
                                value={maxArea}
                                onChange={(e) => setMaxArea(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row style={{marginTop:'20px', justifyContent:'flex-end'}}>
                <Col className="col-auto" onClick={handleClear}><Button color="dark">Cancelar</Button></Col>
                {isEdit ?
                    <Col className="col-auto" onClick={handleCreate}><Button>Salvar atualizações</Button></Col>
                :
                    <Col className="col-auto" onClick={handleCreate}><Button>Criar pedido</Button></Col>
                }
            </Row>
        </ModalBody>
    </Modal>
    );
}