import './styles.css';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row, Button, Input } from 'reactstrap';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import {IndicatorsModal} from './components/indicatorsModal'
import { IndicatorListItem } from './components/indicatorListItem';
import { createTheme, Pagination, ThemeProvider } from '@mui/material';
import { IndicatorUpdateModal } from './components/indicatorUpdateModal';
import SweetAlert from 'react-bootstrap-sweetalert';

export const AdmIndicators = () => {

  const breadcrumbItems = [
    { title: "Fast Sale", link: "/dashboard" },
    { title: "Indicações", link: "#" },
  ];

  const [indicators, setIndicators] = useState([])
  const [filteredIndicators, setFilteredIndicators] = useState([]);
  const [displayedIndicators, setDisplayedIndicators] = useState([]);
  const [openModal, setOpenModal] = useState(false)
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [indicatorId, setIndicatorId] = useState('')
  const [success_dlg, setSuccess_dlg] = useState(false)
  const [error_dlg, setError_dlg] = useState(false)
  const [dynamic_title, setDynamic_title] = useState('')
  const [dynamic_description, setDynamic_description] = useState('')

  const [filters, setFilters] = useState({
    origin: '',
    user_name: '',
    user_type: '',
    affiliate_name: '',
    affiliate_type: '',
    user_premium: '',
  });

  useEffect(() => {
    if(localStorage.getItem('access') === 'A'){
      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          fetchIndicators();
        }).catch(response => {
          localStorage.removeItem("history_url")
          setTimeout(() => {
            window.open("/login", '_parent')
          }, 300);
        })
    }else{
      window.open("/dashboard")
    }
  }, []);

  const fetchIndicators = async () => {
    try {
      const response = await Axios.get(process.env.REACT_APP_API + '/api/auth/indicators-list', {
        headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
      });
      setIndicators(response.data);
      setFilteredIndicators(response.data); 
    } catch (error) {
      console.error('Error fetching indicators:', error);
    }
  };

  useEffect(() => {
    applyFilters();
    setPage(1)
  }, [filters, indicators]);

  useEffect(() => {
    applyPagination();
  }, [page, filteredIndicators]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const applyFilters = () => {
    const filtered = indicators.filter(indicator => {
      const indicatorOrigin = indicator.origin ? indicator.origin.toLowerCase() : '';
      const indicatorUserName = indicator.user && indicator.user.name ? indicator.user.name.toLowerCase() : '';
      const indicatorUserType = indicator.user_type ? indicator.user_type.toLowerCase() : '';
      const indicatorAffiliateName = indicator.affiliate && indicator.affiliate.name ? indicator.affiliate.name.toLowerCase() : '';
      const indicatorAffiliateType = indicator.affiliate_type ? indicator.affiliate_type.toLowerCase() : '';
      const indicatorUserPremium = indicator.user && indicator.user.premium ? indicator.user.premium.toString() : '';

      const filterOrigin = filters.origin.toLowerCase();
      const filterUserName = filters.user_name.toLowerCase();
      const filterUserType = filters.user_type.toLowerCase();
      const filterAffiliateName = filters.affiliate_name.toLowerCase();
      const filterAffiliateType = filters.affiliate_type.toLowerCase();
      const filterUserPremium = filters.user_premium.toString();

      return (
        (filterOrigin === '' || indicatorOrigin.includes(filterOrigin)) &&
        (filterUserName === '' || indicatorUserName.includes(filterUserName)) &&
        (filterUserType === '' || indicatorUserType.includes(filterUserType)) &&
        (filterAffiliateName === '' || indicatorAffiliateName.includes(filterAffiliateName)) &&
        (filterAffiliateType === '' || indicatorAffiliateType.includes(filterAffiliateType)) &&
        (filterUserPremium === '' || indicatorUserPremium == filterUserPremium)
      );
    });
    setFilteredIndicators(filtered);
  };

  const applyPagination = () => {
    const startIndex = (page - 1) * 20;
    const paginated = filteredIndicators.slice(startIndex, startIndex + 20);
    setDisplayedIndicators(paginated);
    setPages(Math.ceil(filteredIndicators.length / 20));
  };

  const theme = createTheme({
    palette: {
    verde: {
        main: '#3E5E3F',
        contrastText: '#fff',
        },
    },
  });

  const handleUpdate = (e) => {
    setIndicatorId(e)
    setOpenUpdateModal(true)
  }

  const handleDelete = (e) => {
    setIndicatorId(e)
    setOpenDeleteModal(true)
  }

  return(
    <div className="page-content">
      <Container fluid={true}>
        {breadcrumbItems &&
          <Breadcrumbs title="Indicações" breadcrumbItems={breadcrumbItems} />
        }
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row style={{justifyContent:'space-between', marginBottom:'30px', alignItems:'center', gap:'10px'}}>
                  <Col className="col-auto"><h3 style={{fontSize:'20px'}}>Indicação de usuários</h3></Col>
                  {localStorage.getItem('level') === '1' && <Col className="col-auto"><Button onClick={() => setOpenModal(true)}>Criar relação</Button></Col>}
                </Row>
                <Row className="mb-3 filters-list">
                  <Col lg={2} md={3}>
                    <select name="origin" className="form-control" value={filters.origin} onChange={handleFilterChange}>
                      <option value="">Todas origens</option>
                      <option value='comercial'>Comercial</option>
                      <option value='manual'>Manual</option>
                      <option value='comercial-checkout'>Com. Checkout</option>
                    </select>
                  </Col>
                  <Col lg={2} md={3}>
                    <Input
                      type="text"
                      name="affiliate_name"
                      value={filters.affiliate_name}
                      onChange={handleFilterChange}
                      placeholder="Nome do indicador"
                    />
                  </Col>
                  <Col lg={2} md={3}>
                    <select name="affiliate_type" className="form-control" value={filters.affiliate_type} onChange={handleFilterChange}>
                      <option value="">Todos indicadores</option>
                      <option value='C'>Corretor</option>
                      <option value='I'>Imobiliária</option>
                      <option value='T'>Construtora</option>
                      <option value='P'>Proprietário</option>
                      <option value='A'>Admin</option>
                    </select>
                  </Col>
                  <Col lg={2} md={3}>
                    <select name="user_type" className="form-control" value={filters.user_type} onChange={handleFilterChange}>
                      <option value="">Todos usuários</option>
                      <option value='C'>Corretor</option>
                      <option value='I'>Imobiliária</option>
                      <option value='T'>Construtora</option>
                      <option value='P'>Proprietário</option>
                      <option value='A'>Admin</option>
                    </select>
                  </Col>
                  <Col lg={2} md={3}>
                    <Input
                      type="text"
                      name="user_name"
                      value={filters.user_name}
                      onChange={handleFilterChange}
                      placeholder="Nome do usuário"
                    />
                  </Col>
                  <Col lg={2} md={3}>
                    <select name="user_premium" className="form-control" value={filters.user_premium} onChange={handleFilterChange}>
                      <option value="">Todos planos</option>
                      <option value={0}>Free</option>
                      <option value={1}>Premium</option>
                      <option value={2}>Start</option>
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <IndicatorListItem data={[]} title={true}/>
                    {displayedIndicators.map((item, index) => (
                      <IndicatorListItem key={index} data={item} onDelete={handleDelete} onUpdate={handleUpdate}/>
                    ))}
                  </Col>
                  <Col xs={12} className="result-total-indicators">
                    <span>{filteredIndicators.length} resultados encontrados.</span>
                    <ThemeProvider theme={theme}>
                      <Pagination
                          count={pages}
                          color="verde"
                          size="small" 
                          page={page}
                          style={{margin:'20px 0', display:'flex', justifyContent:'flex-end'}}
                          onChange={(event, value) => {
                            window.scrollTo(0,0)
                            setPage(value)
                          }}
                      ></Pagination>
                  </ThemeProvider>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <IndicatorsModal
        open={openModal}
        handleUser={fetchIndicators}
        onClose={() => setOpenModal(prevState => !prevState)}
      />

      <IndicatorUpdateModal
        open={openUpdateModal}
        indicatorId={indicatorId}
        handleUser={fetchIndicators}
        onClose={() => setOpenUpdateModal(prevState => !prevState)}
      />

      {openDeleteModal ? (
        <SweetAlert
          title="Atenção!"
          warning
          showCancel
          confirmBtnText="Tenho certeza"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() =>{
            Axios.post(process.env.REACT_APP_API + '/api/auth/indicator-delete/' +  indicatorId, {},
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              setDynamic_title("Deletada")
              setSuccess_dlg(true)
              setOpenDeleteModal(false)
              setDynamic_description("A indicação foi deletada com sucesso.")
            })
          }}
          onCancel={() => {
            setDynamic_title("Cancelada")
            setError_dlg(true)
            setOpenDeleteModal(false)
            setDynamic_description("A indicação ainda está cadastrado.")
          }}
        >
          <p className="alertText">Você tem certeza que deseja deletar esta indicação?</p>
        </SweetAlert>
      ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setSuccess_dlg(false )
              fetchIndicators()
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}

        {error_dlg ? (
          <SweetAlert
            error
            title={dynamic_title}
            onConfirm={() => setError_dlg(false)}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}

    </div>
  )
}