import Axios from 'axios';
import { useEffect, useState } from 'react';
import Select from "react-select";
import { Badge, Button, Col, Container, Input, Label, Row } from 'reactstrap';

function FiltersSection2({active, applyCount}) {

  const[location, setLocation] = useState('')
  const [locationList, setLocationList] = useState([])

  const [stateSelected, setStateSelected] = useState([])
  const [statesOptions, setStatesOptions] = useState([])

  const [citySelected, setCitySelected] = useState([])
  const [citiesOptions, setCitiesOptions] = useState([])

  const [districtSelected, setDistrictSelected] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])

  useEffect(() => {
    setCitySelected([])
    setDistrictSelected([])
  }, [applyCount])

  useEffect(() => {
    // if(localStorage.getItem('filtro-cidades')){
    //   setCitySelected({label: localStorage.getItem('filtro-cidades').split(','), value: localStorage.getItem('filtro-cidades').split(',')})
    //   Axios.get(process.env.REACT_APP_API + '/api/auth/get-districts-by-city?city=' + localStorage.getItem('filtro-cidades').split(','), {
    //     headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
    //   })
    //   .then(response => {
    //      const newDistrictOptions = response.data.map(district => ({
    //        label: district.district,
    //        value: district.district
    //      }));
    //      setDistrictOptions(newDistrictOptions);
    //   });
    // }
    // if(localStorage.getItem('filtro-bairros')){
    //   setDistrictSelected({label: localStorage.getItem('filtro-bairros').split(','), value: localStorage.getItem('filtro-bairros').split(',')})
    // }
}, []);

useEffect(() => {
  console.log(stateSelected.value)
  if(stateSelected.value){
    Axios.get(process.env.REACT_APP_API + '/api/auth/get-cities-by-state/' + stateSelected.value, {
      headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
    })
    .then(response => {
      const newCityOptions = response.data.map(city => ({
        label: city.city,
        value: city.city
      }));
      setCitiesOptions(newCityOptions);
    });
  }
}, [stateSelected]);

useEffect(() => {
  Axios.get(process.env.REACT_APP_API + '/api/auth/get-states', {
    headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
  })
  .then(response => {
    const newStateOptions = response.data.map(state => ({
      label: state.state,
      value: state.state
    }));
    setStatesOptions(newStateOptions);
  });
}, []);


  useEffect(() => {
    if(citySelected.value){
      Axios.get(process.env.REACT_APP_API + '/api/auth/get-districts-by-city?city=' + citySelected.value, {
        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
      })
      .then(response => {
        const newDistrictOptions = response.data.map(district => ({
          label: district.district,
          value: district.district
        }));
        setDistrictOptions(newDistrictOptions);
      });
    }
  }, [citySelected])


  return (
    <Container className="sectionFilter2">
      <Row>
        <Col xs={12} style={{marginTop:'10px'}}>
          <div>
            <Label className="form-label">Estado</Label>
            <Select
                value={stateSelected}
                placeholder="Selecione"
                noOptionsMessage={(inputValue) => `Sem resultados`}
                onChange={(e) => {
                  localStorage.setItem('filtro-estados', e.value)
                  setStateSelected(e)
                  setCitySelected([])
                  setDistrictSelected([])
                  setCitiesOptions([])
                  setDistrictOptions([])
                }}
                options={statesOptions}
                classNamePrefix="select2-selection"
            />
            <small style={{
              textDecoration:'underline',
              marginTop:'5px',
              cursor:'pointer'
            }} onClick={() => {
              setStateSelected([])
              setCitySelected([])
              setDistrictSelected([])
              setCitiesOptions([])
              setDistrictOptions([])
              setTimeout(() => {
                localStorage.setItem('filtro-estados', '')
              }, 300);
              }}>Limpar</small>
          </div>
          <div style={{margin:'20px 0'}}>
            <Label className="form-label">Cidade</Label>
            <Select
                value={citySelected}
                placeholder="Selecione"
                noOptionsMessage={(inputValue) => `Selecione um estado`}
                onChange={(e) => {
                  localStorage.setItem('filtro-cidades', e.value)
                  setCitySelected(e)
                  setDistrictSelected([])
                  setDistrictOptions([])
                }}
                options={citiesOptions}
                classNamePrefix="select2-selection"
            />
            <small style={{
              textDecoration:'underline',
              marginTop:'5px',
              cursor:'pointer'
            }} onClick={() => {
              setCitySelected([])
              setDistrictSelected([])
              setDistrictOptions([])
              setTimeout(() => {
                localStorage.setItem('filtro-cidades', '')
              }, 300);
              }}>Limpar</small>
          </div>
          <div style={{margin:'20px 0'}}>
            <Label className="form-label">Bairro</Label>
            <Select
                value={districtSelected}
                placeholder="Selecione"
                noOptionsMessage={(inputValue) => `Selecione uma cidade`}
                onChange={(e) => {
                  setDistrictSelected(e)
                  localStorage.setItem('filtro-bairros', e.value)
                }}
                options={districtOptions}
                classNamePrefix="select2-selection"
            />
            <small style={{
              textDecoration:'underline',
              marginTop:'5px',
              cursor:'pointer'
            }} onClick={() => {
              setDistrictSelected([])
              setTimeout(() => {
                localStorage.setItem('filtro-bairros', '')
              }, 300);
              }}>Limpar</small>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FiltersSection2;