import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER } from './actionTypes';
import { apiError, loginUserSuccessful, logoutUserSuccess } from './actions';

// AUTH related methods
import { postLogin } from '../../../helpers/fackBackend_Helper';
import { getFirebaseBackend } from '../../../helpers/firebase_helper';

//Initilize firebase
const fireBaseBackend = getFirebaseBackend();

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
        try {
            // if(process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            //     const response = yield call(fireBaseBackend.loginUser, user.username, user.password);
            //     yield put(loginUserSuccessful(response));
            // }
            // else {
                // console.log({username: user.username, password: user.password})
                const response = yield call(postLogin, process.env.REACT_APP_API + '/api/login', {
                    email: user.username, password: user.password
                });
                yield put(loginUserSuccessful(response));
            // }
            if(localStorage.getItem("history_url") && localStorage.getItem("history_url") !== '/home') {
                history.push(localStorage.getItem("history_url"));
            } else {
                history.push('/dashboard');
            }
        } catch (error) {
            // console.log(error)
            // yield put(apiError('Credenciais inválidas'));
            // console.log(error)
        }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        localStorage.removeItem("level");
        localStorage.removeItem("status");
        localStorage.removeItem("history_url")
        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const response = yield call(fireBaseBackend.logout);
            yield put(logoutUserSuccess(response));
        }

        history.push('/login');

    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* loginSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default loginSaga;