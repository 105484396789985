import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty } from "lodash"

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    ModalFooter,
    Input,
    PaginationLink,
    Badge,
    Label,
    Spinner,
    Collapse,
    CardHeader,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { createTheme, Pagination, ThemeProvider } from '@mui/material';

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import { Swiper, SwiperSlide } from "swiper/react";
//css
import "@fullcalendar/bootstrap/main.css"
import './../../assets/scss/custom/components/_catalogProperties.scss'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    getEvents,
    addNewEvent,
    updateEvent,
    deleteEvent,
    getCategories,
} from "../../store/actions"
import DeleteModal from "../CatalogEnterprises/DeleteModal"
import Axios from 'axios'
import ImovelCard from '../../components/ImovelCard/ImovelCard'

import 'swiper/css';
import "swiper/css/navigation";
// import "swiper/scrollbar.css"

import { Navigation } from "swiper"
import Filters from '../../components/Filters/Filters'
import { Link } from 'react-router-dom'
import AllMaps from '../CatalogEnterprises/AllMaps'
import EnterpriseCard from "../../components/ImovelCard/EnterpriseCard"
import EnterprisesFilters from "../../components/Filters/EnterprisesFilters"
import UnitsFilters from "../../components/Filters/UnitsFilters"
import UnitCard from "../../components/ImovelCard/UnitCard"

import EmBreve from './../../assets/images/Icons/breve.png';

import Todos from './../../assets/images/Icons/todos.png';
import Parceria from './../../assets/images/Icons/parceria.png';
import Unidades from './../../assets/images/Icons/unidades.png';
import Terceiros from './../../assets/images/Icons/terceiros.png';
import Empreendimentos from './../../assets/images/Icons/empreendimentos.png';
import Construtoras from './../../assets/images/Icons/construtoras.png';

const urlSection = window.location.search.replace('?', '')
const widthView = window.innerWidth;

class CatalogYourEnterprises extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: "Fast Sale", link: "/dashboard" },
                { title: "Imóveis", link: "#" },
            ],
            modal_scroll: false,
            visible: false,
            verifyPremium: 0,
            sortResults: localStorage.getItem('filtro-sort') ? localStorage.getItem('filtro-sort') : 'newest',
            searchContractType: localStorage.getItem('search-contract-type') ? localStorage.getItem('search-contract-type') : 'Todos os imóveis',
            imoveis: [],
            empreendimentos: [],
            construtoras:[],
            unidades:[],
            quantidadeResultados:'',
            parcerias:[],
            terceiros:[],
            favoritos:[],
            todos:[],
            modal_large: false,
            page: (widthView > 768 && localStorage.getItem('paginaAtual')) ? localStorage.getItem('paginaAtual') : 1,
            search: '',
            atualPage:1,
            dataFilter:[],
            oportunidade:[],
            imoveisCarregando:null,
            pages: 1,
            searchCity:'',
            searchConstruction:'',
            searchEnterprise:'',
            showFilter:{
                filter_lg: 6,
                filter_md: 6,
                filter_xl: 4,
                body_lg: 6,
                body_md: 6,
                body_xl: 8,
            },
            onToogle:true,
        }
        this.buttonRef = React.createRef();
        this.tog_scroll = this.tog_scroll.bind(this);
        this.tog_large = this.tog_large.bind(this);
    }

    tog_large() {
        this.setState(prevState => ({
            modal_large: !prevState.modal_large
        }));
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    tog_scroll() {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll
        }));
        // this.removeBodyCss();
    }

    show() {
        this.setState({ visible: true });
    }

    hide() {
        this.setState({ visible: false });
    }

    componentDidMount = () => {
        document.getElementById('load').classList.add('active')
        setTimeout(() => {
            window.scrollTo(0,0)
        }, 300);

        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {

                this.setState({verifyPremium: 1})
               

                setTimeout(() => {
                    if(urlSection === ''){
                        this.handleEnterprises();
                        if(widthView < 768){
                            window.addEventListener('scroll', this.onScroll);
                        }
                    }
                    if(urlSection === 'terceiros'){
                        this.handleTerceiros();
                        if(widthView < 768){
                            window.addEventListener('scroll', this.onScroll);
                        }
                    }
                }, 300);

                Axios.post(process.env.REACT_APP_API + '/api/auth/my-favorite', {
                type: localStorage.getItem('access'),
                user_id: localStorage.getItem('id')
                }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                    response.data.map((imovel, index) => {
                        this.state.favoritos.push(imovel.property_id)
                    })
                })

        })
        .catch(response =>{
            localStorage.removeItem("history_url")
                setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
        })

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
      }


      handleEnterprises = () => {

        document.getElementById('load').classList.add('active')
        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-filter/' + this.state.page,{
            city:this.state.searchCity,
            title:this.state.searchEnterprise,
            construction_id: localStorage.getItem('access') === 'S' ? localStorage.getItem('construction_id') : localStorage.getItem('id'),
        },
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            this.setState({quantidadeResultados: response.data.total})
            this.setState({empreendimentos: response.data.data})
            this.setState({pages: response.data.last_page})
            this.setState({imoveisCarregando: 'Nenhum resultado foi encontrado para sua busca. Tente remover alguns filtros.'})
            
            
            // this.setState({
            //     showFilter:{
            //         filter_lg: 0,
            //         filter_xl: 0,
            //         filter_md: 0,
            //         body_lg: 12,
            //         body_xl: 12,
            //         body_md: 12,
            //     }
            // })

            setTimeout(() => {
                document.getElementById('load').classList.remove('active')
            }, 300);

        })
    }

    handleUnits = () => {
        document.getElementById('load').classList.add('active')
        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-units-user/' + this.state.page,
         {
            localization: (localStorage.getItem('filtro-cidades') !== null && localStorage.getItem('filtro-cidades').length !== 0) ? localStorage.getItem('filtro-cidades').split(",") : 0,
            district: (localStorage.getItem('filtro-bairros') !== null && localStorage.getItem('filtro-bairros').length !== 0) ? localStorage.getItem('filtro-bairros').split(",") : 0,
            min_value:localStorage.getItem('min_price_range_filter') ? localStorage.getItem('min_price_range_filter') : '',
            max_value:localStorage.getItem('max_price_range_filter') ? localStorage.getItem('max_price_range_filter') : '',
            min_area:localStorage.getItem('min_area_range_filter') ? localStorage.getItem('min_area_range_filter') : '',
            max_area:localStorage.getItem('max_area_range_filter') ? localStorage.getItem('max_area_range_filter') : '',
            dorms_number: localStorage.getItem('filtro-quartos') !== null ? (localStorage.getItem('filtro-quartos') !== '' ? localStorage.getItem('filtro-quartos').split(",") : 0) : 0,
            parking_spots: localStorage.getItem('filtro-garagens') !== null ? (localStorage.getItem('filtro-garagens') !== '' ? localStorage.getItem('filtro-garagens').split(",") : 0) : 0,
            construction_id:localStorage.getItem('access') === 'S' ? localStorage.getItem('construction_id') : localStorage.getItem('id'),
            filter:decodeURI(this.state.sortResults),
         },
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            this.setState({unidades: response.data.data})
            this.setState({pages: response.data.last_page})
            this.setState({imoveisCarregando: 'Nenhum resultado foi encontrado para sua busca. Tente remover alguns filtros.'})
            this.setState({quantidadeResultados: response.data.total})
            setTimeout(() => {
                document.getElementById('load').classList.remove('active')
            }, 300);

        })
    }
    
    handleTerceiros = () => {
        document.getElementById('load').classList.add('active')
        // const input = document.getElementById("inputText");
        // input.addEventListener("keydown", this.handleKeyDown);

        const data = {
            categories: (localStorage.getItem('filtro-categoria') !== null && localStorage.getItem('filtro-categoria').length !== 0) ? localStorage.getItem('filtro-categoria').split(",") : 0,
            localization: (localStorage.getItem('filtro-cidades') !== null && localStorage.getItem('filtro-cidades').length !== 0) ? localStorage.getItem('filtro-cidades').split(",") : 0,
            dorms_number: localStorage.getItem('filtro-quartos') !== null ? (localStorage.getItem('filtro-quartos') !== '' ? localStorage.getItem('filtro-quartos').split(",") : 0) : 0,
            parking_spots: localStorage.getItem('filtro-garagens') !== null ? (localStorage.getItem('filtro-garagens') !== '' ? localStorage.getItem('filtro-garagens').split(",") : 0) : 0,
            real_state_financing:localStorage.getItem('filtro-negocio')?.includes('Financiamento Bancário') ? 1 : 0,
            accept_object_exchange:localStorage.getItem('filtro-negocio')?.includes('Permuta Objeto') ? 1 : 0,
            accept_properties:localStorage.getItem('filtro-negocio')?.includes('Permuta Imóvel') ? 1 : 0,
            min_value:localStorage.getItem('min_price_range_filter') ? localStorage.getItem('min_price_range_filter') : '',
            max_value:localStorage.getItem('max_price_range_filter') ? localStorage.getItem('max_price_range_filter') : '',
            min_area:localStorage.getItem('min_area_range_filter') ? localStorage.getItem('min_area_range_filter') : '',
            max_area:localStorage.getItem('max_area_range_filter') ? localStorage.getItem('max_area_range_filter') : '',
            filter:decodeURI(this.state.sortResults),
            search: localStorage.getItem('filter-search'),
            user_type:localStorage.getItem('access'),
            contract:'Construtoras',
            user_id:localStorage.getItem('id'),
            accept_car:localStorage.getItem('filtro-negocio')?.includes('Permuta Automóvel') ? 1 : 0
        }
        this.setState({dataFilter: data})
            Axios.post(process.env.REACT_APP_API + '/api/public/property-filter-user/?page=' + this.state.page, data, 
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                if(widthView < 768){
                    this.setState(prevState => ({
                        terceiros: [...prevState.terceiros, ...response.data.data],
                        page: prevState.page + 1,
                    }));
                }else{
                    this.setState({terceiros: response.data.data})
                    this.setState({pages: response.data.last_page})
                    this.setState({imoveisCarregando: 'Nenhum resultado foi encontrado para sua busca. Tente remover alguns filtros.'})
                }
                this.setState({quantidadeResultados: response.data.total})
                setTimeout(() => {
                    document.getElementById('load').classList.remove('active')
                }, 300);
        })
    }

    handleSearch = (city, construction, enterprise) => {
        this.setState({
            searchCity:city,
            searchConstruction:construction,
            searchEnterprise:enterprise
        })
        setTimeout(() => {
            if(urlSection === ''){
                this.handleEnterprises();
            }
            if(urlSection === 'construtoras'){
                this.handleConstructions();
            }
        }, 300);
     }
    

    toogleFilters() {
        if(this.state.showFilter.filter_lg === 6){
            this.setState({onToogle: false})
            this.setState({
                showFilter:{
                    filter_lg: 1,
                    filter_xl: 1,
                    filter_md: 1,
                    body_lg: 11,
                    body_xl: 11,
                    body_md: 11,
                }
            })
        }else{
            this.setState({onToogle: true})
            this.setState({
                showFilter:{
                    filter_lg: 6,
                    filter_md: 6,
                    filter_xl: 4,
                    body_lg: 6,
                    body_md: 6,
                    body_xl: 8,
                }
            })
        }
    }

    onScroll = () => {
        if (
          window.innerHeight + window.scrollY + 100 >= document.body.offsetHeight
        ) {
            if(urlSection === ''){
                this.handleEnterprises();
            }
            if(urlSection === 'terceiros'){
                this.handleTerceiros();
            }
        }
      }
    

    render() {
        const theme = createTheme({
            palette: {
            verde: {
                main: '#3E5E3F',
                contrastText: '#fff',
                },
            },
        });
        const {imoveis, sortResults, showFilter} = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Imóveis" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col md={showFilter.filter_md} lg={showFilter.filter_lg} xl={showFilter.filter_xl}>
                                {/* {urlSection === '' &&
                                    <UnitsFilters imoveis={imoveis} toogle={this.toogleFilters.bind(this)} onToogle={this.state.onToogle} premium={this.state.verifyPremium} redirectToImoveis={true}/>
                                } */}
                                {(urlSection === '') &&
                                    <EnterprisesFilters imoveis={imoveis} onSearch={this.handleSearch} toogle={this.toogleFilters.bind(this)} onToogle={this.state.onToogle} premium={this.state.verifyPremium} redirectToImoveis={true}/>
                                }
                                {(urlSection === 'terceiros') &&
                                    <Filters imoveis={imoveis} toogle={this.toogleFilters.bind(this)} onToogle={this.state.onToogle} premium={this.state.verifyPremium} redirectToImoveis={true}/>
                                }
                            </Col>

                            <Col md={showFilter.body_md} lg={showFilter.body_lg} xl={showFilter.body_xl}>
                            <Card>
                                <CardBody>
                                <div>

                                {(urlSection === 'unidades'
                                || urlSection === 'terceiros'
                                || urlSection === 'parceria'
                                || urlSection === '') &&
                                    <Row>
                                    <Col md="6">
                                    {(localStorage.getItem('filter-search') !== null
                                    && localStorage.getItem('filter-search') !== '') &&
                                    <Badge className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                        localStorage.removeItem('filter-search')
                                        window.location.reload()
                                        }}>{localStorage.getItem('filter-search')}<i className="ri-close-circle-line"></i></Badge>
                                    }
                                    {(localStorage.getItem('filtro-categoria') !== null
                                    && localStorage.getItem('filtro-categoria') !== '') &&
                                    localStorage.getItem('filtro-categoria')?.split(",").map((categoria, index) => (
                                    <Badge key={index} className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                        var categorias = localStorage.getItem('filtro-categoria')?.split(",")
                                        var index = categorias.indexOf(categoria)
                                        if (index > -1) {
                                            categorias.splice(index, 1)
                                        }
                                        localStorage.setItem('filtro-categoria', categorias)
                                        window.location.reload()
                                        }}>{categoria}<i className="ri-close-circle-line"></i></Badge>
                                    ))
                                    }
                                    {(localStorage.getItem('min_price_range_filter') !== null
                                    && localStorage.getItem('min_price_range_filter') !== '') &&
                                    <Badge className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                        localStorage.removeItem('min_price_range_filter')
                                        window.location.reload()
                                        }}>min.{(localStorage.getItem('min_price_range_filter') / 100)
                                        .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<i className="ri-close-circle-line"></i></Badge>
                                    }
                                    {(localStorage.getItem('max_price_range_filter') !== null
                                    && localStorage.getItem('max_price_range_filter') !== '') &&
                                    <Badge className="badge-soft-dark me-1 filterActive" onClick={() => {
                                        localStorage.removeItem('max_price_range_filter')
                                        window.location.reload()
                                        }}>max.{(localStorage.getItem('max_price_range_filter') / 100)
                                        .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<i className="ri-close-circle-line"></i></Badge>
                                    }
                                    {(localStorage.getItem('min_area_range_filter') !== null
                                    && localStorage.getItem('min_area_range_filter') !== '') &&
                                    <Badge className="badge-soft-dark me-1 filterActive" onClick={() => {
                                        localStorage.removeItem('min_area_range_filter')
                                        window.location.reload()
                                        }}>min.{localStorage.getItem('min_area_range_filter')} m²<i className="ri-close-circle-line"></i></Badge>
                                    }
                                    {(localStorage.getItem('max_area_range_filter') !== null
                                    && localStorage.getItem('max_area_range_filter') !== '') &&
                                    <Badge className="badge-soft-dark me-1 filterActive" onClick={() => {
                                        localStorage.removeItem('max_area_range_filter')
                                        window.location.reload()
                                        }}>max.{localStorage.getItem('max_area_range_filter')} m²<i className="ri-close-circle-line"></i></Badge>
                                    }
                                    {(localStorage.getItem('filtro-cidades') !== null
                                    && localStorage.getItem('filtro-cidades') !== '') &&
                                    localStorage.getItem('filtro-cidades')?.split(",").map((cidade, index) => (
                                    <Badge key={index} className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                        var cidades = localStorage.getItem('filtro-cidades')?.split(",")
                                        var index = cidades.indexOf(cidade)
                                        if (index > -1) {
                                            cidades.splice(index, 1)
                                        }
                                        localStorage.setItem('filtro-cidades', cidades)
                                        window.location.reload()
                                        }}>{cidade}<i className="ri-close-circle-line"></i></Badge>
                                    ))
                                    }
                                    {(localStorage.getItem('filtro-bairros') !== null
                                    && localStorage.getItem('filtro-bairros') !== '') &&
                                    localStorage.getItem('filtro-bairros')?.split(",").map((cidade, index) => (
                                    <Badge key={index} className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                        var bairros = localStorage.getItem('filtro-bairros')?.split(",")
                                        var index = bairros.indexOf(cidade)
                                        if (index > -1) {
                                            bairros.splice(index, 1)
                                        }
                                        localStorage.setItem('filtro-bairros', bairros)
                                        window.location.reload()
                                        }}>{cidade}<i className="ri-close-circle-line"></i></Badge>
                                    ))
                                    }
                                    {(localStorage.getItem('filtro-quartos') !== null
                                    && localStorage.getItem('filtro-quartos') !== '') &&
                                    localStorage.getItem('filtro-quartos')?.split(",").map((quarto, index) => (
                                    <Badge key={index} className="badge-soft-dark me-1 filterActive"  onClick={() => {
                                        var quartos = localStorage.getItem('filtro-quartos')?.split(",")
                                        var index = quartos.indexOf(quarto)
                                        if (index > -1) {
                                            quartos.splice(index, 1)
                                        }
                                        localStorage.setItem('filtro-quartos', quartos)
                                        window.location.reload()
                                        }}>{quarto} quartos<i className="ri-close-circle-line"></i></Badge>
                                    ))
                                    }
                                    {(localStorage.getItem('filtro-garagens') !== null
                                    && localStorage.getItem('filtro-garagens') !== '') &&
                                    localStorage.getItem('filtro-garagens')?.split(",").map((garagem, index) => (
                                    <Badge key={index} className="badge-soft-dark me-1 filterActive" onClick={() => {
                                        var garagens = localStorage.getItem('filtro-garagens')?.split(",")
                                        var index = garagens.indexOf(garagem)
                                        if (index > -1) {
                                            garagens.splice(index, 1)
                                        }
                                        localStorage.setItem('filtro-garagens', garagens)
                                        window.location.reload()
                                        }}>{garagem} vagas<i className="ri-close-circle-line"></i></Badge>
                                    ))
                                    }
                                    {(localStorage.getItem('filtro-negocio') !== null
                                    && localStorage.getItem('filtro-negocio') !== '') &&
                                    localStorage.getItem('filtro-negocio')?.split(",").map((negocio, index) => (
                                    <Badge key={index} className="badge-soft-dark me-1 filterActive" onClick={() => {
                                        var negocios = localStorage.getItem('filtro-negocio')?.split(",")
                                            var index = negocios.indexOf(negocio)
                                            if (index > -1) {
                                            negocios.splice(index, 1)
                                            }
                                        localStorage.setItem('filtro-negocio', negocios)
                                        window.location.reload()
                                        }}>{negocio}<i className="ri-close-circle-line"></i></Badge>
                                    ))
                                    }

                                    {((localStorage.getItem('filtro-cidades') && localStorage.getItem('filtro-cidades') !== '')
                                    || (localStorage.getItem('max_price_range_filter') && localStorage.getItem('max_price_range_filter') !== '')
                                    || (localStorage.getItem('max_price_range_filter') && localStorage.getItem('max_price_range_filter') !== '')
                                    || (localStorage.getItem('filtro-negocio') && localStorage.getItem('filtro-negocio') !== '')
                                    || (localStorage.getItem('filtro-categoria') && localStorage.getItem('filtro-categoria') !== '')
                                    || (localStorage.getItem('filter-search') && localStorage.getItem('filter-search') !== '')
                                    || (localStorage.getItem('filtro-quartos') && localStorage.getItem('filtro-quartos') !== '')
                                    || (localStorage.getItem('filtro-garagens') && localStorage.getItem('filtro-garagens') !== '')
                                    || (localStorage.getItem('min_area_range_filter') && localStorage.getItem('min_area_range_filter') !== '')
                                    || (localStorage.getItem('max_area_range_filter') && localStorage.getItem('max_area_range_filter') !== '')) &&
                                    <Badge className="badge-soft-dark me-1 filterActive" style={{backgroundColor:"black", color:'white'}} onClick={() => {
                                        localStorage.removeItem('filtro-cidades')
                                        localStorage.removeItem('max_price_range_filter')
                                        localStorage.removeItem('filtro-estados')
                                        localStorage.removeItem('min_price_range_filter')
                                        localStorage.removeItem('filtro-quartos')
                                        localStorage.removeItem('filtro-garagens')
                                        localStorage.removeItem('filtro-bairros')
                                        localStorage.removeItem('filtro-negocio')
                                        localStorage.setItem('filtro-sort', 'newest')
                                        localStorage.removeItem('min_area_range_filter')
                                        localStorage.removeItem('max_area_range_filter')
                                        localStorage.removeItem('filter-search')
                                        localStorage.removeItem('filtro-categoria')

                                        setTimeout(() => {
                                            window.location.reload()
                                        }, 100);
                                        }}>limpar filtros</Badge>
                                    }
                                    </Col>

                                    <Col className="sortContainer" md={6}>
                                        <Row style={{justifyContent:'flex-end', paddingRight: '15px', flexDirection: 'row', flexWrap:'wrap', alignItems: 'center', gap:'10px', width:'100%'}}>
                                            
                                            {(urlSection === '' || urlSection === 'parceria') &&
                                                <div style={{textAlign:'right', fontSize:'12px'}}>
                                                    {this.state.quantidadeResultados} resultados encontrados.
                                                </div>
                                            }
                                            <p className="col-auto">Ordenar por:</p>  
                                            <select value={sortResults} className="form-control" style={{width:'140px'}} onChange={(e) => {
                                                localStorage.setItem('filtro-sort', e.target.value)
                                                window.location.reload()
                                                }}>
                                                <option value="newest" selected>Mais novos</option>
                                                <option value="older">Mais antigos</option>
                                                <option value="lower-price">Menor preço</option>
                                                <option value="higger-price">Maior preço</option>
                                                <option value="lower-area">Menor área</option>
                                                <option value="higger-area">Maior área</option>
                                                {/* <option value="name">Ordem alfabética</option> */}
                                            </select>
                                        </Row>
                                    </Col>
                                    </Row>
                                    }
                                    {localStorage.getItem('access') === 'T' &&
                                    <Row style={{marginTop: '20px'}}>
                                        <Col className="tabList">
                                            <div className={"contractTabs"}>
                                            <div id="all" className={"tab outlet" + " " + (localStorage.getItem('search-contract-type') === 'Todos os imóveis' && 'active')} onClick={() => {                                         
                                                        localStorage.setItem('search-contract-type', 'Todos os imóveis')
                                                        localStorage.setItem('paginaAtual', '1')
                                                        localStorage.removeItem('filtro-cidades')
                                                        localStorage.removeItem('filtro-estados')
                                                        localStorage.removeItem('max_price_range_filter')
                                                        localStorage.removeItem('min_price_range_filter')
                                                        localStorage.removeItem('filtro-quartos')
                                                        localStorage.removeItem('filtro-garagens')
                                                        localStorage.removeItem('filtro-negocio')
                                                        localStorage.setItem('filtro-sort', 'newest')
                                                        localStorage.removeItem('min_area_range_filter')
                                                        localStorage.removeItem('max_area_range_filter')
                                                        localStorage.removeItem('filter-search')
                                                        localStorage.removeItem('filtro-categoria')
                                                        setTimeout(() => {
                                                            window.open(`${window.location.pathname}`, '_parent')
                                                        }, 100);
                                                    }}>
                                                        <div className="tab-icon" style={{backgroundImage:'url(' + Todos + ')'}}></div>
                                                        <span>Seus empreendimentos</span>
                                                    </div>

                                                <div id="fs" className={"tab fs" + " " + (localStorage.getItem('search-contract-type') === 'Terceiros' && 'active')} onClick={() => {                                               
                                                    localStorage.setItem('search-contract-type', 'Terceiros')
                                                    localStorage.setItem('paginaAtual', '1')
                                                    localStorage.removeItem('filtro-cidades')
                                                    localStorage.removeItem('max_price_range_filter')
                                                    localStorage.removeItem('filtro-estados')
                                                    localStorage.removeItem('min_price_range_filter')
                                                    localStorage.removeItem('filtro-quartos')
                                                    localStorage.removeItem('filtro-garagens')
                                                    localStorage.removeItem('filtro-negocio')
                                                    localStorage.setItem('filtro-sort', 'newest')
                                                    localStorage.removeItem('min_area_range_filter')
                                                    localStorage.removeItem('max_area_range_filter')
                                                    localStorage.removeItem('filter-search')
                                                    localStorage.removeItem('filtro-categoria')
                                                    setTimeout(() => {
                                                        window.open(`${window.location.pathname}?terceiros`, '_parent')
                                                    }, 100);
                                                }}>
                                                    <div className="tab-icon" style={{backgroundImage:'url(' + Terceiros + ')'}}></div>
                                                    <span>Seus imóveis de terceiros</span>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                    }

                                    {urlSection === '' ?
                                    this.state.empreendimentos?.length > 0 ?
                                        <Row className="catalogResult" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))', display:'grid', gap: '10px'}}>
                                            {this.state.empreendimentos.map((imovel, index) => (
                                                <Col key={index} style={{padding:'0', justifyContent:'center', display: 'flex', alignItems: 'center'}}>
                                                    <EnterpriseCard  imovel={imovel} favoritos={this.state.favoritos} premium={this.state.verifyPremium}/>
                                                </Col>
                                            ))}

                                            </Row>
                                        :
                                             <Row>
                                                 <Col style={{padding:'20px', textAlign:'center'}}>{this.state.imoveisCarregando}</Col>
                                             </Row>
                                        :
                                        <></>
                                    }


                                    {urlSection === 'terceiros' ?
                                    this.state.terceiros.length > 0 ?
                                        <Row className="catalogResult" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))', display:'grid', gap: '10px'}}>
                                        {this.state.terceiros.map((imovel, index) => (
                                                <Col key={index} style={{padding:'0', justifyContent:'center', display: 'flex', alignItems: 'center'}}>
                                                    <ImovelCard  imovel={imovel} favoritos={this.state.favoritos} premium={this.state.verifyPremium}/>
                                                </Col>
                                            ))
                                        }
                                            </Row>
                                        :
                                             <Row>
                                                 <Col style={{padding:'20px', textAlign:'center'}}>{this.state.imoveisCarregando}</Col>
                                             </Row>
                                        :
                                        <></>    
                                    }
                                    
                                    {/* {urlSection === '' ?
                                    this.state.unidades?.length > 0 ?
                                        <Row className="catalogResult" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))', display:'grid', gap: '10px'}}>
                                             {this.state.unidades.map((imovel, index) => (
                                                 <Col key={index} style={{padding:'0', justifyContent:'center', display: 'flex', alignItems: 'center'}}>
                                                     <UnitCard  imovel={imovel} favoritos={this.state.favoritos} premium={this.state.verifyPremium}/>
                                                 </Col>
                                             ))}

                                             </Row>
                                         :
                                              <Row>
                                                  <Col style={{padding:'20px', textAlign:'center'}}>{this.state.imoveisCarregando}</Col>
                                              </Row>
                                         :
                                         <></>
                                    // <div>Em breve</div>
                                    // :
                                    // <></>
                                    } */}


                                    {widthView > 768 &&
                                    <Row>
                                        <Col>
                                            <ThemeProvider theme={theme}>
                                                <Pagination
                                                count={this.state.pages}
                                                color="verde"
                                                size="small" 
                                                page={parseInt(this.state.page)}
                                                style={{margin:'20px 0', display:'flex', justifyContent:'flex-end'}}
                                                onChange={(event, value) => {
                                                    if(widthView > 768){
                                                        localStorage.setItem('paginaAtual', value)
                                                    }
                                                    this.setState({page: value})
                                                    setTimeout(() => {
                                                        if(urlSection === ''){
                                                            window.scrollTo(0,0)
                                                            this.handleEnterprises();
                                                        }
                                                        if(urlSection === 'terceiros'){
                                                            window.scrollTo(0,0)
                                                            this.handleTerceiros();
                                                        }
                                                    }, 300);
                                                }}
                                                />
                                            </ThemeProvider>
                                        </Col>
                                    </Row>
                                    }
                                </div>
                                </CardBody>
                            </Card>
                            </Col>
                        </Row>

                        {(localStorage.getItem('status') !== '3' && localStorage.getItem('status') !== '2') &&
                            <Button id="btnMap" className="btn-dark" style={{display:'flex', alignItems:'center', gap:'10px'}} onClick={() => {
                                this.setState({ modal_large: true })
                                document.getElementById('load').classList.add('active')
                            }}>
                                <i className="ri-map-pin-2-fill"></i>
                                Ver no Mapa
                            </Button>
                        }

                    </Container>

                    {/* PopUp Google Maps */}

                    <Modal
                        size="lg"
                        centered={true}
                        isOpen={this.state.modal_large}
                        toggle={this.tog_large}
                    >
                        <ModalHeader toggle={() => this.setState({ modal_large: false })}>
                        </ModalHeader>
                        {/* <ModalBody> */}
                            <AllMaps dataFilter={this.state.dataFilter} zoomLevel={13}/>
                        {/* </ModalBody> */}
                    </Modal>

                    <div id="load" className="load">
                        <Spinner className="me-2" color="success" />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default (CatalogYourEnterprises)
// export default connect(mapStateToProps, mapDispatchToProps)(Calender)