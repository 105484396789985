import Axios from 'axios';
import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import { Col, Card, CardBody, Button, Spinner, Row, CardHeader, Input, Label } from "reactstrap";
import Checkout from './Checkout';

class HomeCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkout: false,
        }
    }

    componentDidMount(){
        this.setState({checkout: <Checkout/>})
    }


    render() {

        return (
            <React.Fragment>
                <div>
                    {this.state.checkout}
                    {/* <div style={{
                        width:'100%',
                        height:'100vh',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        paddingBottom:'100px',
                        flexDirection:'column'
                    }}>
                        <div style={{
                            maxWidth:'300px',
                            marginBottom:'40px',
                            textAlign:'center'
                        }}>No momento nosso checkout se encontra em manutenção, entre em contato pelo nosso whatsapp.</div>
                        <Button onClick={() => {
                            setTimeout(() => {
                                window.open('https://wa.me/554741081865', '_blank')
                              }, 100);
                        }}>Chamar no Whats</Button>
                    </div>*/}
                </div> 
            </React.Fragment>
        );
    }
}

export default HomeCheckout;