import React, { Component } from "react";
import { Row, Col, Button, Container, Label, Input, Spinner } from "reactstrap";
import PasswordChecklist from "react-password-checklist";
import Axios from 'axios';
import { Link } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import Whats from '../../components/Whats/Whats';
import logodark from "../../assets/images/logo-dark.png";

class CreatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pass_success: false,
            pass_error: false,
            senha: '',
            confirmacaoSenha: '',
            truePassword: false,
        };
        this.handlePasswordValidation = this.handlePasswordValidation.bind(this);
    }

    handlePasswordChange = (e) => {
        this.setState({ senha: e.target.value }, this.handlePasswordValidation);
    };

    handleConfirmPasswordChange = (e) => {
        this.setState({ confirmacaoSenha: e.target.value }, this.handlePasswordValidation);
    };

    handlePasswordValidation = () => {
        const { senha, confirmacaoSenha } = this.state;

        // Verificar apenas quando ambas as senhas estiverem preenchidas para evitar chamadas desnecessárias.
        if (senha && confirmacaoSenha) {
            // Exemplo de lógica básica, podemos ajustar conforme o PasswordChecklist.
            const isValid = senha === confirmacaoSenha && senha.length >= 8;
            this.setState({ truePassword: isValid });
        } else {
            this.setState({ truePassword: false });
        }
    };

    handleSubmit = () => {
        document.getElementById('load').classList.add('active');
        const url = window.location.href;
        const searchParams = new URL(url).searchParams;

        const data = {
            token: searchParams.get("token"),
            email: searchParams.get("email"),
            user_type: searchParams.get("t"),
            password: this.state.senha,
        };

        Axios.post(process.env.REACT_APP_API + '/api/reset-password', data, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
        })
            .then(() => {
                this.setState({ pass_success: true });
                document.getElementById('load').classList.remove('active');
                setTimeout(() => {
                    window.open("/login", '_parent');
                }, 300);
            })
            .catch(() => {
                this.setState({ pass_error: true });
                document.getElementById('load').classList.remove('active');
            });
    };

    render() {
        const { senha, confirmacaoSenha, truePassword, pass_success, pass_error } = this.state;

        return (
            <React.Fragment>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <Link to="/dashboard" className="logo">
                                                        <img src={logodark} height="20" alt="logo" />
                                                    </Link>
                                                    <h4 className="font-size-18 mt-4">Cadastre uma nova senha</h4>
                                                    <p className="text-muted">Para criar uma nova senha, preencha os campos abaixo.</p>
                                                </div>

                                                <Container>
                                                    <Row style={{ gap: '10px 0', paddingBottom: '15px' }}>
                                                        <Col xs={12}>
                                                            <Label>Crie uma senha</Label>
                                                            <Input 
                                                                type="password" 
                                                                value={senha} 
                                                                onChange={this.handlePasswordChange} 
                                                            />
                                                        </Col>
                                                        <Col xs={12}>
                                                            <Label>Confirme sua senha</Label>
                                                            <Input 
                                                                type="password" 
                                                                value={confirmacaoSenha} 
                                                                onChange={this.handleConfirmPasswordChange} 
                                                            />
                                                        </Col>
                                                        <Col xs={12}>
                                                            <PasswordChecklist
                                                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                                                minLength={8}
                                                                iconSize={16}
                                                                value={senha}
                                                                valueAgain={confirmacaoSenha}
                                                                onChange={(isValid) => {
                                                                    // Atualizar `truePassword` apenas quando a validação for diferente do valor atual.
                                                                    if (this.state.truePassword !== isValid) {
                                                                        this.setState({ truePassword: isValid });
                                                                    }
                                                                }}
                                                                messages={{
                                                                    minLength: "Ao menos 8 caracteres.",
                                                                    specialChar: "Ao menos um caractere especial.",
                                                                    number: "Ao menos um número.",
                                                                    capital: "Ao menos uma letra maiúscula.",
                                                                    match: "Suas senhas coincidem.",
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col style={{ textAlign: 'center' }}>
                                                            <Button onClick={this.handleSubmit} disabled={!truePassword}>
                                                                Enviar
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Container>

                                                <div className="mt-5 text-center">
                                                    <p>Ainda não tem conta? <Link to="/registre-se" className="fw-medium text-primary"> Registre-se </Link></p>
                                                    <p>Fast Sale Imóveis</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                    <div id="load" className="load">
                        <Spinner className="me-2" color="success" />
                    </div>
                </Container>

                {pass_success && (
                    <SweetAlert
                        title="Senha alterada com sucesso!"
                        timeout={4000}
                        showCloseButton={false}
                        showConfirm={false}
                        success
                        onConfirm={() => this.setState({ pass_success: false })}
                    />
                )}

                {pass_error && (
                    <SweetAlert
                        title="A alteração falhou, tente novamente."
                        timeout={4000}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => this.setState({ pass_error: false })}
                    />
                )}

                <Whats />
            </React.Fragment>
        );
    }
}

export default CreatePassword;
