import styles from '../../styles/desktop/AboutThird.module.css';
import { useState, useEffect } from 'react';
import ContentEditable from "react-contenteditable";
import { useMediaQuery } from "react-responsive";
import AboutThirdMobile from '../mobile/AboutThirdMobile';

const AboutThird = ({data}) => {
  const [aboutMoreTog, setAboutMoreTog ] = useState(false)
  const [highTitle, setHighTitle] = useState('Sobre nós')
  const [highTitleEnabled, setHighTitleEnabled] = useState(true)
  const [isMobile, setIsMobile] = useState(false);
  const isMobileQuery = useMediaQuery({ query: `(max-width: 767px)` });

  useEffect(() => {
    if(localStorage.getItem('about_title')){
      setHighTitle(localStorage.getItem('about_title'))
    }

  }, []);

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery]);

  if (isMobile) {
    return <AboutThirdMobile data={data} />;
  }

  return(
    <section className={styles.container_about}>
        <div className={styles.container_details}>
          <div className={styles.details}></div>
        </div>

        <div>
          <div className={styles.about_people}>
            <img src={data?.profile_photo} alt=""  className={styles.people}/>
          <div>
          <div className={styles.titleContainer}>
            <ContentEditable
              html={highTitle}
              className={styles.titleEdit}
              id='aboutTitleEdit'
              disabled={highTitleEnabled}
              onChange={(e) => {
                setHighTitle(e.target.value)
                localStorage.setItem('about_title', e.target.value)
              }}
              onClick={(e) => {
                if(highTitle === 'Sobre nós' && !highTitleEnabled){
                  setHighTitle('')
                  localStorage.setItem('about_title', '')
                }
              }}
            />
          <div className={styles.actionIcons}>
            <label className="title">
              <span onClick={() => {
                if(!highTitleEnabled){
                  setHighTitleEnabled(true)
                  if(highTitle === ''){
                    setHighTitle('Sobre nós')
                    localStorage.setItem('about_title', 'Sobre nós')
                  }
                }else{
                  setHighTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('aboutTitleEdit').focus()
                    if(highTitle === 'Sobre nós'){
                      setHighTitle('')
                      localStorage.setItem('about_title', '')
                    }
                  }, 300);
                }
              }}>{highTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
              <i className="ri-text" onClick={() => {
                if(!highTitleEnabled){
                  setHighTitleEnabled(true)
                  if(highTitle === ''){
                    setHighTitle('Sobre nós')
                    localStorage.setItem('about_title', 'Sobre nós')
                  }
                }else{
                  setHighTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('aboutTitleEdit').focus()
                    if(highTitle === 'Sobre nós'){
                      setHighTitle('')
                      localStorage.setItem('about_title', '')
                    }
                  }, 300);
                }
              }}></i>
            </label>
          </div>
          </div>
            {!aboutMoreTog ?
              <p className={styles.text}><pre-line>{data?.about}</pre-line></p>
              :
              <p className={styles.textExpand}><pre-line>{data?.about}</pre-line></p>
            }
            <div className={styles.aboutMore} onClick={() => {
                setAboutMoreTog(!aboutMoreTog)
              }}>{!aboutMoreTog ? 'Ver mais' : 'Ver menos'}
            </div>
          </div>
          </div>
        </div>
    </section>
  )
}

export default AboutThird;