import './styles.scss';

import DefaultUser from './../../../../assets/images/default-user-icon.jpg';

export const CapterCard = ({data}) =>{
    const formatPhoneNumber = (phone) => {
        if (!phone) return '';
    
        const cleaned = phone.replace(/\D/g, '');
    
        if (cleaned.length === 11) {
            return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
        }
    
        return phone;
    };

    return(
        <>
        <div className="action-property-capter-card">
            <h3>Captador (a)</h3>
            <div className="capter-card">
                <img src={data.profile_photo ? data.profile_photo : DefaultUser}/>
                <div className="capter-card-content">
                    <h5>{data.name}</h5>
                    <p>Creci: <b>{data.creci ? data.creci : data.creci_j}</b></p>
                    {/* <p>{formatPhoneNumber(data.phone)}</p> */}
                </div>
            </div>
            
        </div>
        <small className="action-property-desc">Para mais informações utilize o botão "Contato com captador"</small>
        </>
    )
}