import moment from 'moment';
import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";

class NextSchedulings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }

    render() {
        const combinedArray = this.props?.scheduleReceive?.map((imovel) => {
            return imovel.future_schedulings.map((schedule) => {
              return {
                ...schedule,
                sku: imovel.sku,
                title: imovel.title
              }
            });
          }).flat().concat(this.props.scheduleSend?.map((schedule) => {
            return {
              ...schedule,
              sku: schedule.properties.sku,
              title: schedule.properties.title
            }
          }));

        return (

            <React.Fragment>
                <Col lg={4}>
                    <Card style={{ minHeight: "575px" }}>
                        <CardBody>
                            {/* <Dropdown className="float-end" isOpen={this.state.menu} toggle={() => this.setState({ menu: !this.state.menu })}>
                                <DropdownToggle tag="i" className="darrow-none card-drop" aria-expanded="false">
                                    <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">

                                    <DropdownItem href="">Sales Report</DropdownItem>

                                    <DropdownItem href="">Export Report</DropdownItem>

                                    <DropdownItem href="">Profit</DropdownItem>

                                    <DropdownItem href="">Action</DropdownItem>
                                </DropdownMenu>
                            </Dropdown> */}

                            <h4 className="card-title mb-4">Próximas visitas</h4>
                            {combinedArray?.length < 1 ?
                            <p>Você não tem visitas agendadas no momento.</p>
                            :
                            <SimpleBar style={{ maxHeight: "350px", minHeight: "350px", overflow: 'hidden auto' }}>
                                <ul className="list-unstyled activity-wid">
                                    {combinedArray?.sort((a, b) => {
                                    const dateA = moment(a.schedule_date, 'YYYY-MM-DD');
                                    const dateB = moment(b.schedule_date, 'YYYY-MM-DD');
                                    if (dateA.isBefore(dateB)) {return -1}
                                    if (dateA.isAfter(dateB)) {return 1}
                                    return 0;
                                    }).map((schedule, index) => (
                                    <li className="activity-list" key={index}>
                                        <div className="activity-icon avatar-xs">
                                            {schedule.properties && <span className="avatar-title bg-soft-info text-primary rounded-circle"><i className="ri-arrow-down-circle-fill" style={{color:'#333'}}></i></span>}
                                            {!schedule.properties && <span className="avatar-title bg-soft-primary text-primary rounded-circle"><i className="ri-arrow-up-circle-fill"></i></span>}
                                        </div>
                                        <div>
                                        <div>
                                            <h5 className="font-size-13">{schedule.schedule_date} | {schedule.schedule_time} {
                                                schedule.status === 0 ?
                                                <Badge className="bg-warning me-1 rounded-pill" style={{ color: '#333', marginBottom: '5px' }}>Pendente</Badge>
                                                : schedule.status === 1 ?
                                                <Badge className="bg-primary me-1 rounded-pill" style={{ color: '#fff', marginBottom: '5px' }}>Confirmada</Badge>
                                                : schedule.status === 2 ?
                                                <Badge className="bg-danger me-1 rounded-pill" style={{ color: '#fff', marginBottom: '5px' }}>Cancelado</Badge>
                                                : schedule.status === 3 ?
                                                <Badge className="bg-info me-1 rounded-pill" style={{ color: '#fff', marginBottom: '5px' }}>Parceria</Badge>
                                                : schedule.status === 4 &&
                                                <Badge className="bg-light me-1 rounded-pill" style={{ color: '#fff', marginBottom: '5px' }}>Concluído</Badge>
                                            }</h5>
                                        </div>
                                        <div>
                                            <p className="text-muted mb-0">({schedule.sku}) {schedule.title}</p>
                                        </div>
                                        </div>
                                    </li>
                                    ))}
                                </ul>
                                </SimpleBar>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default NextSchedulings;