import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Container, Spinner, Modal, ModalHeader, ModalBody, Label, Input, ModalFooter, Button } from "reactstrap";
import Axios from 'axios'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import './../../assets/scss/custom/components/_brokerTeam.scss'
import SweetAlert from 'react-bootstrap-sweetalert';
import NoPremium from '../../components/noPremium/noPremium';

class BrokersStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_center: false,
      brokerEmail:'',
      addBroker:[],
      realTeam:[],
      with_title:false,
      custom_div1: false,
      alert_confirm:false,
      confirm_both:false,
      success_dlg: false,
      dynamic_title: "",
      error_dlg:false,
      delete_id:null,
      dynamic_description: "",
      newInvite:false,
      verifyPremium:false,
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Corretores", link: "#" },
    ],
    };
    this.tog_center = this.tog_center.bind(this);
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  componentDidMount(){
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
          type:localStorage.getItem('access'),
          user_id:localStorage.getItem('id')
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          if(response.data.premium === 1) {
            this.setState({verifyPremium: true})
          } else {
            this.setState({verifyPremium: false})
          }
        })
        .catch(response => {
          this.setState({verifyPremium: false})
        })

        Axios.get(process.env.REACT_APP_API + '/api/auth/real_estate_teams/by_real_estate/' + localStorage.getItem('id'),
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            if(response.data?.realEstateTeams){
              this.setState({realTeam: response.data?.realEstateTeams})
              if(response.data?.realEstateTeams?.length < response.data?.realEstateTeams[0]?.real_estate?.brokers_count){
                this.setState({newInvite: true})
              }
            }else{
              if(0 < response.data?.realEstate?.brokers_count){
                this.setState({newInvite: true})
              }
            }

          })

      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }

  sumPrices(properties){
    if (!properties) {
      return 0;
    }
  
    const totalValue = properties.reduce((total, property) => total + property.sale_value, 0);
  
    return totalValue;
  }

  render() {
    return (
        <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Seu time de corretores" breadcrumbItems={this.state.breadcrumbItems} />
          {this.state.verifyPremium ?
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                  {this.state.realTeam?.map((member, index) => (
                    <Col key={index} className="col-auto" style={{position:'relative'}}>
                      <div className="status-add-broker">
                        {member.approved === 0 && <div className="pendente">Corretor pendente</div>}
                        {member.approved === 1 && <div className="ativo">Corretor ativo</div>}
                        {member.approved === 2 && <div className="negado">Convite negado</div>}
                      </div>
                      <div className="add-borker-card-member">
                        <div className="top-add-broker-card">
                          <div className="cover-add-broker-card" style={{backgroundImage:'url(' + member.broker.profile_photo + ')'}}></div>
                          <div className="name-add-broker-card">
                            <h3>{member.broker.name}</h3>
                            {member.approved === 1 ?
                              <Button size="sm" onClick={() => {
                                setTimeout(() => {
                                  window.open('./../../time-corretor?' + member.broker.id, '_parent')
                                }, 100);
                              }}><i className="ri-user-line align-middle me-1" style={{marginRight:'3px'}}></i>Ver perfil</Button>
                              :
                              <Button size="sm" disabled><i className="ri-user-line align-middle me-1" style={{marginRight:'3px'}}></i>Ver perfil</Button>
                            }
                          </div>
                        </div>
                        <div className="body-add-broker-card">
                          <div className="box">
                            <div className="col-box">
                              <p>Captações</p>
                              <h3>{member.approved === 1 ? member.broker?.properties?.length : '***'}</h3>
                            </div>
                            <div className="col-box">
                              <p>Valor Captações</p>
                              <h3><span>R$</span>{member.approved === 1 ? (this.sumPrices(member.broker?.properties) / 100)?.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '***'}</h3>
                            </div>
                          </div>
                          <div className="box">
                            <div className="col-box">
                              <p>Download de material</p>
                              <h3>{member.approved === 1 ? member.broker?.recent_downloaded?.length : '***'}</h3>
                            </div>
                            <div className="col-box">
                              <p>Contratos de parceria</p>
                              <h3>{member.approved === 1 ? member.broker?.accepted_contracts?.length : '***'}</h3>
                            </div>
                          </div>
                        </div>
                        <div className="footer-add-broker-card">
                            <span onClick={() => this.setState({confirm_both: true, delete_id: member.id})}><i className="ri-delete-bin-line"></i>Remover corretor</span>
                        </div>
                      </div>
                    </Col>
                  ))}
                  {this.state.newInvite ?
                    <Col className="col-auto">
                      <div className="add-borker-card" onClick={this.tog_center}>
                        <div className="add-broker-button">
                          <i className="ri-user-add-line"></i>
                          <p>Adicionar Corretor</p>
                        </div>
                      </div>
                    </Col>
                  :
                  <Col className="col-auto">
                    <div className="add-borker-card" onClick={() => {
                      setTimeout(() => {
                        window.open('https://wa.me/554741081865', '_blank')
                      }, 100);
                    }}>
                      <div className="add-broker-button">
                        <i className="ri-user-add-line"></i>
                        <p>Seu time está cheio</p>
                        <p style={{textAlign:'center'}}>Faça um upgrade e aumente seu time</p>
                      </div>
                    </div>
                  </Col>
                  }
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          :
            <NoPremium/>
          }
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>

        <Modal
            isOpen={this.state.modal_center}
            toggle={this.tog_center}
            centered={true}
          >
            <ModalHeader toggle={() => this.setState({ modal_center: false })}>
              Adicionar Corretor
            </ModalHeader>
            <ModalBody className="modal-add-broker">
              <Label>E-mail do corretor na Fast Sale</Label>
              <Input
                placeholder="E-mail do corretor"
                value={this.state.brokerEmail}
                onChange={(e) => this.setState({brokerEmail: e.target.value})}
              ></Input>
              <ModalFooter className="modal-add-broker-footer">
                <Button onClick={() => {
                  this.setState({ modal_center: false, brokerEmail: '' })
                  }} className="btn-dark">Cancelar</Button>
                {(this.state.brokerEmail !== '' && this.state.brokerEmail.includes('@')) ?
                  <Button onClick={() => {
                    Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-team-find-broker', {
                      email: this.state.brokerEmail,
                      },{headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                      .then(response => {
                        this.setState({addBroker: response.data.corretor, alert_confirm: true})
                      })
                      .catch(response => {
                        this.setState({with_title: true})
                      })
                  }}>Enviar convite</Button>
                :
                <Button disabled>Enviar convite</Button>
                }
              </ModalFooter>
            </ModalBody>
          </Modal>

          {this.state.with_title ? (
              <SweetAlert
                title="Corretor não encontrado"
                danger
                onConfirm={() => this.setState({ with_title: false })}
              >
                Verifique o e-mail e tente novamente.
              </SweetAlert>
            ) : null}

            {this.state.alert_confirm ? (
              <SweetAlert
              title={`Enviar convite para ${this.state.addBroker.name}`}
              warning
              showCancel
              confirmBtnText="Enviar convite"
              cancelBtnText="Cancelar"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="dark"
              onConfirm={() =>
                Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-team-create', {
                  broker_id: this.state.addBroker.id,
                  real_estate_id: localStorage.getItem('id'),
                  approved: 0
                  },{headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                  .then(response => {
                    this.setState({
                      custom_div1: true,
                      modal_center: false,
                      alert_confirm: false,
                      brokerEmail: ''
                    })
                  })
              }
              onCancel={() =>
                  this.setState({
                      alert_confirm: false,
                      modal_center: false,
                      brokerEmail: ''
                  })
              }
              >
              O corretor precisa aceitar o convite.
              </SweetAlert>
            ) : null}

            {this.state.custom_div1 ? (
              <SweetAlert
                  title="Convite enviado com sucesso."
                  timeout={2000}
                  showCloseButton={false}
                  showConfirm={false}
                  success
                  onConfirm={() => this.setState({ custom_div1: false })}
                ></SweetAlert>
            ) : null}


        {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>{
              Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-team-delete/' +  this.state.delete_id, {},
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({
                  confirm_both: false,
                  success_dlg: true,
                  dynamic_title: "Deletado",
                  dynamic_description: "Corretor desvinculado do time."
                })
              })
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "Este corretor ainda faz parte de seu time."
              })
            }
          >
            <p className="alertText">Você tem certeza que deseja desvincular este corretor?</p>
          </SweetAlert>
        ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => {
                this.setState({ success_dlg: false })
                window.location.reload()
              }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

          {this.state.error_dlg ? (
            <SweetAlert
              error
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ error_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}
      </div>
    );
  }
}

export default BrokersStaff;