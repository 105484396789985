import React from 'react';
import './styles.scss';
import ConstructionBg from './../../../assets/images/fundo_construtoras.jpg';
import ConstructionLogo from './../../../assets/images/logo_construtoras.png';

export const ConstructionBanner = () => {
  return(
    <div className="construction-banner-container" style={{backgroundImage: `url('${ConstructionBg}')`}}>
      <div className="construction-banner-image">
        <img src={ConstructionLogo}/>
      </div>
      <div className="construction-banner-content">
        <h2>Fast Sale para <b>construtoras.</b></h2>
        <a href="https://lp.fastsaleimoveis.com.br/construtoras" target="_blank"><button>Saber mais</button></a>
      </div>
    </div>
  )
}