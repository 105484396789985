import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import { Link } from "react-router-dom";
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, ModalBody, ModalHeader, Progress, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from "../../components/Inputs/CurrencyInput";
import Dropzone, { useDropzone } from 'react-dropzone';
import { Draggable } from "react-drag-reorder";
import EnterprisePhotosOrder from '../editProperties/enterprisePhotosOrder';
import EnterprisePlansOrder from "../editProperties/enterprisePlansOrder";

const id = window.location.search.replace('?id=', '')

class EnterpriseDocs extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      selectedDocs: [],
      docsEditList:[],
      deleteSuccess:false,
      errors:[],
      deleteError:false,
      count:0,
      progressBars: [],
      }
      this.updateEnterprise = this.updateEnterprise.bind(this);
    }


    componentDidMount(){
      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            this.setState({
                enterprise: response.data,
                docsEditList: response.data.docs
            })
         })
        })
         .catch(response =>{
          setTimeout(() => {
              window.open("/login", '_parent')
          }, 300);
          })    
    }


      formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      };

      
      handleAcceptedDocs = files => {
        const acceptedFiles = [];
        files.forEach(file => {
            if (file.size <= 10 * 1024 * 1024) {
                acceptedFiles.push(file);
            } else {
                this.setState(prevState => ({
                    errors: [...prevState.errors, { name: file.name, message: 'Tamanho excede 10MB' }]
                }));
            }
        });

        files.map(file => {
          let fileType;
          if (file.type === 'application/pdf') {
            fileType = 'pdf';
          } else {
            fileType = file.type;
          }
      
          return Object.assign(file, {
            fileType,
            formattedSize: this.formatBytes(file.size)
          });
        });
      
        this.setState({ selectedDocs: files });

        this.updateEnterprise()
      };

   updateEnterprise(event) {
      document.getElementById('load').classList.add('active')
      const { selectedDocs } = this.state;
      const totalPhotos = selectedDocs.length;
      this.setState({ progressBars: new Array(totalPhotos).fill(0) });

      if(this.state.selectedDocs !== undefined && this.state.selectedDocs.length > 0){

        selectedDocs.forEach((photo, index) => {
          const dataEnterpriseDocs = new FormData();
          dataEnterpriseDocs.append(`docs[0][file]`, photo);
          dataEnterpriseDocs.append(`docs[0][property_enterprise_id]`, id);

          setTimeout(() => {
            Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-docs', dataEnterpriseDocs, {
              headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
              onUploadProgress: event => {
                  const progress = Math.round(100 * event.loaded) / event.total;
                  const updatedProgressBars = [...this.state.progressBars];
                  updatedProgressBars[index] = progress;
                  this.setState({ progressBars: updatedProgressBars }, () => {
                    this.forceUpdate();
                });
              }
          }).then(response => {
              if (index === totalPhotos - 1) {
                  document.getElementById('load').classList.remove('active')

                  Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
                    headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                  }).then(response => {
                    this.setState({
                        enterprise: response.data,
                        docsEditList: response.data.docs
                    })
                    this.setState({count : this.state.count + 1})
                    this.setState({selectedDocs: []})
                  })
              }
          }).catch(error => {
            const updatedErrors = [...this.state.errors];
            updatedErrors[index] = { isError: true, message: 'Erro no envio da imagem' };
            this.setState({ errors: updatedErrors });
          });
          }, 500);

        });
      }
  }

    render(){
        const {enterprise} = this.state;

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                          <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                              <Row style={{margin:'10px 0'}}>
                                                <Col style={{padding:'0'}}>
                                                  <h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Documentos Anexos (PDF ou imagens)</h5>
                                                  <small>Estes documentos estarão disponíveis para os corretores fazerem download.</small>
                                                </Col>
                                              </Row>
                                              <Row style={{marginBottom: '20px'}}>
                                              {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') &&
                                                <Dropzone
                                                  onDrop={acceptedFiles =>
                                                    this.handleAcceptedDocs(acceptedFiles)
                                                  }
                                                >
                                                  {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                      <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                      >
                                                        <input {...getInputProps()} />
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>Para adicionar arquivos arraste ou clique aqui.</h4>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Dropzone>
                                                }
                                                <div
                                                    className="dropzone-previews mt-3"
                                                    id="docs-previews"
                                                  >
                                                  {this.state.selectedDocs.map((f, i) => {
                                                    return (
                                                      <Card
                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                        key={i + "-file"}
                                                      >
                                                        <div className="p-2">
                                                          <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                              {f.fileType === 'pdf' ? (
                                                                <i className="ri-file-search-fill" style={{fontSize:'40px', color:'#333'}}></i>
                                                              ) : (
                                                                <img
                                                                  data-dz-thumbnail=""
                                                                  height="40"
                                                                  className="avatar-sm rounded bg-light"
                                                                  alt={f.name}
                                                                  src={f.preview}
                                                                />
                                                              )}
                                                            </Col>
                                                            <Col>
                                                              <Link
                                                                to="#"
                                                                className="text-muted fw-bold"
                                                              >
                                                                {f.name}
                                                              </Link>
                                                              <p className="mb-0">
                                                                <strong>{f.formattedSize}</strong>
                                                                {this.state.errors[i] ? (
                                                                    <div style={{ color: 'red' }}>{this.state.errors[i].message}</div>
                                                                ) : (
                                                                    <Progress value={this.state.progressBars[i]}/>
                                                                )}
                                                              </p>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Card>
                                                    );
                                                  })}
                                                  </div>
                                              </Row>
                                              <Row>
                                                <Col xs={12}>
                                                {this.state.docsEditList?.length > 0 && <Label className="col-md-12 col-form-label">Documentos</Label>}
                                                  {this.state.docsEditList?.length > 0 &&
                                                    this.state.docsEditList.map((doc, index) => (
                                                      <li style={{
                                                        backgroundColor:'#cdcdcd',
                                                        display:'inline-flex',
                                                        alignItems:'center',
                                                        gap:'10px',
                                                        borderRadius:'15px',
                                                        padding:'0 15px',
                                                        cursor:'pointer',
                                                        margin:'5px'
                                                      }} key={index}
                                                      onClick={() => {
                                                        if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                                                          this.setState({error_auth:true})
                                                        }else{
                                                          Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-doc/delete/' + doc.id, {},
                                                            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                            const updatedDocs = this.state.docsEditList.filter(docs => docs.id !== doc.id);
                                                            this.setState({ docsEditList: updatedDocs });
                                                          }}
                                                        }
                                                      >{doc.filename}<span><i className="ri-close-line" style={{fontSize:'18px'}}></i></span></li>
                                                    ))}
                                                  </Col>
                                              </Row>
                                            </Container>
                                            <Container>
                                              <Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                                <Col className="col-auto">
                                                    <Button className="btn-dark" onClick={() => {
                                                        setTimeout(() => {
                                                            window.open('./../perfil-empreendimento?id=' + id, '_parent')
                                                        }, 100);
                                                    }}>Voltar</Button>
                                                </Col>
                                                <Col className="col-auto">
                                                    <Button onClick={() => {
                                                        setTimeout(() => {
                                                            window.open('./../perfil-empreendimento?id=' + id, '_parent')
                                                        }, 100);
                                                    }}>Salvar</Button>
                                                </Col>
                                              </Row>
                                            </Container>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                {this.state.error_auth ? (
                  <SweetAlert
                      error
                      title='Ops'
                      onConfirm={() => this.setState({ error_auth: false })}
                  >
                      Você não tem permissão para esta ação.
                  </SweetAlert>
              ) : null}
            </div>
        )
    }

}

export default EnterpriseDocs;