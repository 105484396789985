import { Component } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';

import './../../assets/scss/custom/components/_noPremium.scss';

class NoPremiumProperty extends Component {

  render(){
  return (
    <Row>
      <Col>
        <Card style={{height:'80vh'}}>
          <div id="noPremiumOverlay">
            <i className='ri-lock-2-fill'></i>
            <p>Esta imóvel está disponível apenas para assinantes.</p>
            <Button onClick={() => {
              setTimeout(() => {
                window.open("/home", '_parent')
              }, 300)
            }}>Quero assinar também!</Button>
            <Button style={{backgroundColor:"#888", border:'none'}} onClick={() => {
              setTimeout(() => {
                window.open("/imoveis", '_parent')
              }, 300)
            }}>Ver outros imóveis</Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
}

export default NoPremiumProperty;
