import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, NavItem, NavLink, Progress, TabContent, TabPane, Form, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'
import { AvField, AvForm } from 'availity-reactstrap-validation';

import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from 'classnames';
import './../../assets/scss/custom/components/_editProperties.scss'
import ReactSelect from 'react-select';
import Select from "react-select";
import Dropzone from 'react-dropzone';
import { merge } from 'react-dropzone-uploader';

import './../../assets/scss/custom/components/_addProposal.scss'
import CurrencyInput from '../../components/Inputs/CurrencyInput';
import SweetAlert from 'react-bootstrap-sweetalert';

class addProposal extends Component {
  constructor(props) {
    super(props);
    this.validateRef = React.createRef();
    this.validateRef2 = React.createRef();
    this.state = {
      inputs: [],
      inputsCar:[],
      imovel: [],
      user:[],
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 25,
      stateSelected: '',
      citySelected:'',
      statesAddress: [],
      cityAddress: [],
      selectedDoc:[],
      cep:'',
      logradouro:'',
      numero:'',
      bairro:'',
      complemento:'',
      pais:'Brasil',
      compradorNome:'',
      compradorNacionalidade:'',
      compradorEstadoCivil:'',
      compradorProfissao:'',
      compradorCpf:'',
      compradorRg:'',
      compradorTelefone:'',
      compradorEmail:'',
      validadeProposta:'',
      valorProposta:'',
      avista: null,
      parcelado: null,
      permutas: null,
      entrada:'',
      financiamento:'',
      numeroParcelas:'',
      parcelas:'',
      numeroReforcos:'',
      reforcos:'',
      numberReq:null,
      proposal_success:false,
      proposal_error:false,
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Criar proposta", link: "#" },
    ],
    }
    this.toggleTabProgress.bind(this);
  }

  handleAddInput = () => {
    const { inputs } = this.state;
    this.setState({
      inputs: [...inputs, {
        cep: '',
        logradouro:'',
        numero:'',
        bairro:'',
        complemento:'',
        cidade:[],
        estado:[],
        valor:'',
        listaCidades:[],
        documents:[],
        pais:'Brasil', }]
    });
  };

  handleRemoveInput(index) {
    const inputs = [...this.state.inputs];
    inputs.splice(index, 1);
    this.setState({ inputs });
  }

  handleAddCarInput = () => {
    const { inputsCar } = this.state;
    this.setState({
      inputsCar: [...inputsCar, {
        ano:'',
        modelo:'',
        km:'',
        valor:'',
        documents:[],
        photos:[], }]
    });
  };

  handleRemoveCarInput(index) {
    const inputsCar = [...this.state.inputsCar];
    inputsCar.splice(index, 1);
    this.setState({ inputsCar });
  }

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
        if (tab >= 1 && tab <= 4) {
            this.setState({
                activeTabProgress: tab
            });

            if (tab === 1) { this.setState({ progressValue: 25 }) }
            if (tab === 2) { this.setState({ progressValue: 50 }) }
            if (tab === 3) { this.setState({ progressValue: 75 }) }
            if (tab === 4) { this.setState({ progressValue: 100 }) }
        }
    }
}

removeBodyCss() {
  document.body.classList.add("no_padding");
}

  componentDidMount(){

    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/property/' + parseInt(localStorage.getItem('property_id_proposal')),
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          this.setState({imovel: response.data})

          if(localStorage.getItem('access') === 'C'){
            Axios.get(process.env.REACT_APP_API + '/api/auth/brokers/' + localStorage.getItem('id'),
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(resp => {
              this.setState({user: resp.data})
            })
          }
          if(localStorage.getItem('access') === 'I'){
            Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates/' + localStorage.getItem('id'),
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(resp => {
              this.setState({user: resp.data})
            })
          }

        })})
        .catch(response =>{
          localStorage.removeItem("history_url")
          setTimeout(() => {
            window.open("/login", '_parent')
          }, 300);
        })

    this.setState({ isComponentMounted: true });
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();

    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
    .then(response => {
      const states = []
      for(var i = 0; response.data.length > i; i++){
        states.push({
          value: response.data[i].sigla,
          label: response.data[i].nome
        })
      }
      this.setState({statesAddress: states})
    })
  }

  handleSelectState = stateSelected => {
		this.setState({ stateSelected });
    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + stateSelected.value + '/municipios')
    .then(response => {
      const city = []
      for(var i = 0; response.data.length > i; i++){
        city.push({
          label: response.data[i].nome,
          value: response.data[i].nome
        })
      }
      this.setState({cityAddress: city})
    })
	};


  consultaCep = cepValue => {
    const cep = cepValue
     if (cep?.length !== 8) {
      this.setState({logradouro: ''});
      this.setState({bairro: ''});
      this.setState({stateSelected: ''})
      this.setState({citySelected: ''});
     }else{
       fetch('https://viacep.com.br/ws/' + cep + '/json/')
        .then((res) => res.json())
        .then((data) => {
            if (data.erro === true) {
              this.setState({logradouro: ''});
              this.setState({bairro: ''});
              this.setState({stateSelected: ''})
              this.setState({citySelected: ''});
            } else {
              this.setState({logradouro: data.logradouro});
              this.setState({bairro: data.bairro});
              this.setState({stateSelected: {
                value:data.uf,
                label:data.uf
              }})
              this.setState({citySelected: {
                value:data.localidade,
                label:data.localidade
              }});
            }
          })
     }  
  }

  handleAcceptedDocs = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedDoc: files });
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  render() {
    const {imovel, statesAddress, cityAddress, citySelected, stateSelected, user} = this.state;
    return (
        <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Criar proposta" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Preencha todas as etapas para formalizar uma proposta</h4>
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                          <NavItem>
                              <NavLink className={classnames({ active: this.state.activeTabProgress === 1 })} onClick={() => { this.toggleTabProgress(1); }} >
                                  <span className="step-number">01</span>
                                  <span className="step-title">Comprador</span>
                              </NavLink>
                          </NavItem>
                          <NavItem>
                              <NavLink className={classnames({ active: this.state.activeTabProgress === 2 })} onClick={() => { this.toggleTabProgress(2); }} >
                                  <span className="step-number">02</span>
                                  <span className="step-title">Proposta</span>
                              </NavLink>
                          </NavItem>
                          <NavItem>
                              <NavLink className={classnames({ active: this.state.activeTabProgress === 3 })} onClick={() => { this.toggleTabProgress(3); }} >
                                  <span className="step-number">03</span>
                                  <span className="step-title">Revisar</span>
                              </NavLink>
                          </NavItem>
                          <NavItem>
                              <NavLink className={classnames({ active: this.state.activeTabProgress === 4 })} onClick={() => { this.toggleTabProgress(4); }} >
                                  <span className="step-number">04</span>
                                  <span className="step-title">Confirmar</span>
                              </NavLink>
                          </NavItem>
                      </ul>

                      <div id="bar" className="mt-4">
                          <Progress color="success" striped animated value={this.state.progressValue} />
                      </div>
                      <TabContent activeTab={this.state.activeTabProgress} className="twitter-bs-wizard-tab-content">
                          <TabPane tabId={1}>
                              <AvForm className="cardBody"  ref={this.validateRef}>
                                  <Container style={{paddingTop:'10px', paddingBottom:'10px'}}>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Informações do comprador</h5></Col>
                                    </Row>
                                    <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                      <Col lg="6">
                                        <Label>Nome completo:</Label>
                                        <AvField
                                            name="compradorNome"
                                            type="text"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.compradorNome}
                                            onChange={(e) => this.setState({compradorNome: e.target.value})}
                                        />
                                      </Col>
                                      <Col lg="3">
                                        <Label>Nacionalidade:</Label>
                                        <AvField
                                            name="compradorNacionalidade"
                                            type="text"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.compradorNacionalidade}
                                            onChange={(e) => this.setState({compradorNacionalidade: e.target.value})}
                                        />
                                      </Col>
                                      <Col lg="3">
                                        <Label>Estado Civil:</Label>
                                        <AvField
                                            name="compradorEstadoCivil"
                                            type="text"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.compradorEstadoCivil}
                                            onChange={(e) => this.setState({compradorEstadoCivil: e.target.value})}
                                        />
                                      </Col>
                                      <Col lg="4">
                                        <Label>Profissão:</Label>
                                        <AvField
                                            name="compradorProfissao"
                                            type="text"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.compradorProfissao}
                                            onChange={(e) => this.setState({compradorProfissao: e.target.value})}
                                        />
                                      </Col>
                                      <Col lg="4">
                                        <Label>CPF/CNPJ:</Label>
                                        <AvField
                                            name="compradorCpf"
                                            type="text"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.compradorCpf}
                                            onChange={(e) => this.setState({compradorCpf: e.target.value})}
                                        />
                                      </Col>
                                      <Col lg="4">
                                        <Label>RG:</Label>
                                        <AvField
                                            name="compradorRg"
                                            type="text"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.compradorRg}
                                            onChange={(e) => this.setState({compradorRg: e.target.value})}
                                        />
                                      </Col>
                                      <Col lg="6">
                                        <Label>Telefone:</Label>
                                        <AvField
                                            name="compradorTelefone"
                                            type="text"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.compradorTelefone}
                                            onChange={(e) => this.setState({compradorTelefone: e.target.value})}
                                        />
                                      </Col>
                                      <Col lg="6">
                                        <Label>E-mail:</Label>
                                        <AvField
                                            name="compradorEmail"
                                            type="text"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.compradorEmail}
                                            onChange={(e) => this.setState({compradorEmail: e.target.value})}
                                        />
                                      </Col>
                                  </Row>
                                </Container>
                                <Container>
                                  <Row style={{margin:'10px 0'}}>
                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Endereço do comprador</h5></Col>
                                  </Row>
                                  <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                  <Col md={3}>
                                        <Label htmlFor="cep" className="col-md-12 col-form-label">Cep</Label>
                                        <AvField
                                          name="cep"
                                          placeholder="Cep"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.cep}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.consultaCep(e.target.value.replace('-', '').replace('.', ''))
                                            this.setState({cep: e.target.value})
                                          }}
                                          id="cep"
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="rua" className="col-md-12 col-form-label">Rua</Label>
                                        <AvField
                                          name="rua"
                                          placeholder="Rua, Avenida..."
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.logradouro}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({logradouro: e.target.value})
                                          }}
                                          id="rua"
                                        />
                                      </Col>
                                      <Col md={1}>
                                        <Label htmlFor="numero" className="col-md-12 col-form-label">Número</Label>
                                        <AvField
                                          name="numero"
                                          placeholder="Número"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.numero}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({numero: e.target.value})
                                          }}
                                          id="numero"
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="bairro" className="col-md-12 col-form-label">Bairro</Label>
                                        <AvField
                                          name="bairro"
                                          placeholder="Bairro"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.bairro}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({bairro: e.target.value})
                                          }}
                                          id="bairro"
                                        />
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="complemento" className="col-md-12 col-form-label">Complemento</Label>
                                          <Input type="text" placeholder="Complemento" id="complemento" value={this.state.complemento} onChange={(e) => this.setState({complemento: e.target.value})} />
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="estado" className="col-md-12 col-form-label">Estado</Label>
                                        <ReactSelect
                                          value={stateSelected}
                                          placeholder="Selecione"
                                          noOptionsMessage={({ inputValue: string }) => string | null }
                                          onChange={this.handleSelectState}
                                          options={statesAddress}
                                          classNamePrefix="select2-selection"
                                        />
                                          {/* <Input type="text" placeholder="Estado" id="estado" /> */}
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="cidade" className="col-md-12 col-form-label">Cidade</Label>
                                        <Select
                                          value={citySelected}
                                          placeholder="Selecione"
                                          noOptionsMessage={({ inputValue: string }) => string | null }
                                          onChange={(e) => {
                                            this.setState({ citySelected: e})
                                          }}
                                          options={cityAddress}
                                          classNamePrefix="select2-selection"
                                        />
                                          {/* <Input type="text" placeholder="Cidade" id="cidade" /> */}
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="pais" className="col-md-12 col-form-label">País</Label>
                                        <AvField
                                          name="pais"
                                          placeholder="País"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          value={this.state.pais}
                                          validate={{ required: { value: true } }}
                                          onChange={(e) => {
                                            this.setState({pais: e.target.value})
                                          }}
                                          id="pais"
                                        />
                                      </Col>
                                  </Row>
                                </Container>
                                <Container>
                                  <Row style={{margin:'10px 0'}}>
                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Documento do comprador</h5></Col>
                                  </Row>
                                  <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                  <Dropzone
                                    onDrop={acceptedFiles =>
                                    this.handleAcceptedDocs(acceptedFiles)}
                                    accept="image/*"
                                    maxFiles={1}
                                    maxSize={5000000}
                                    multiple={false}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div className="dz-message needsclick" {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <div className="mb-3">
                                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                          </div>
                                          <h4>Adicione aqui um documento que comprove a identidade do comprador.</h4>
                                        </div>
                                      </div>
                                    )}
                                </Dropzone>
                                    <div
                                        className="dropzone-previews mt-3"
                                        id="auth-previews"
                                      >
                                        {this.state.selectedDoc.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted fw-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{f.formattedSize}</strong>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                  </Row>
                                </Container>
                              </AvForm>
                          </TabPane>
                          <TabPane tabId={2}>
                              <div>
                              <AvForm className="cardBody" ref={this.validateRef2}>
                                  <Container style={{paddingTop:'10px', paddingBottom:'10px'}}>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Condições da proposta</h5></Col>
                                    </Row>
                                    <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                      <Col lg="3">
                                        <Label>Validade da proposta (em dias):</Label>
                                        <AvField
                                            name="validadeProposta"
                                            type="number"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.validadeProposta}
                                            onChange={(e) => this.setState({validadeProposta: e.target.value})}
                                        />
                                      </Col>
                                      <Col lg="3">
                                        <Label>Valor total:</Label>
                                        <CurrencyInput defaultValue={this.state.valorProposta} onChange={(e) => this.setState({valorProposta: e.target.value})}/>
                                      </Col>
                                      <Col lg="2" style={{display:'flex', alignItems:'center'}}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="avista" 
                                        value={this.state.avista} onClick={(e) => this.setState({avista : e.target.checked})}/>
                                        <Label className="form-check-label" htmlFor="avista">
                                            À vista
                                        </Label>
                                      </Col>
                                      <Col lg="2" style={{display:'flex', alignItems:'center'}}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="parcelado" 
                                        value={this.state.parcelado} onClick={(e) => this.setState({parcelado : e.target.checked})}/>
                                        <Label className="form-check-label" htmlFor="parcelado">
                                            Parcelado
                                        </Label>
                                      </Col>
                                      <Col lg="2" style={{display:'flex', alignItems:'center'}}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="permutas" 
                                        value={this.state.permutas} onClick={(e) => this.setState({permutas : e.target.checked})}/>
                                        <Label className="form-check-label" htmlFor="permutas">
                                            Permutas
                                        </Label>
                                      </Col>
                                  </Row>
                                </Container>
                                {this.state.parcelado &&
                                  <Container style={{paddingTop:'10px', paddingBottom:'10px'}}>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Condições de parcelamento</h5></Col>
                                    </Row>
                                    <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                      <Col lg="3">
                                        <Label>Entrada:</Label>
                                        <CurrencyInput defaultValue={this.state.entrada} onChange={(e) => this.setState({entrada: e.target.value})}/>
                                      </Col>
                                      <Col lg="3">
                                        <Label>Valor de financiamento:</Label>
                                        <CurrencyInput defaultValue={this.state.financiamento} onChange={(e) => this.setState({financiamento: e.target.value})}/>
                                      </Col>
                                      <Col lg="3">
                                        <Label>Quantidade de parcelas:</Label>
                                        <AvField
                                            name="numeroParcelas"
                                            type="number"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.numeroParcelas}
                                            onChange={(e) => this.setState({numeroParcelas: e.target.value})}
                                        />
                                      </Col>
                                      <Col lg="3">
                                        <Label>Valor das parcelas:</Label>
                                        <CurrencyInput defaultValue={this.state.parcelas} onChange={(e) => this.setState({parcelas: e.target.value})}/>
                                      </Col>
                                      <Col lg="3">
                                        <Label>Quantidade de reforços:</Label>
                                        <AvField
                                            name="numeroReforcos"
                                            type="number"
                                            errorMessage="Campo obrigatório"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            value={this.state.numeroReforcos}
                                            onChange={(e) => this.setState({numeroReforcos: e.target.value})}
                                        />
                                      </Col>
                                      <Col lg="3">
                                        <Label>Valor dos reforços:</Label>
                                        <CurrencyInput defaultValue={this.state.reforcos} onChange={(e) => this.setState({reforcos: e.target.value})}/>
                                      </Col>
                                  </Row>
                                </Container>
                              }
                              {this.state.permutas &&
                                <Container style={{paddingTop:'10px', paddingBottom:'10px'}}>
                                  <Row style={{margin:'10px 0'}}>
                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Permutas</h5></Col>
                                  </Row>
                                  <Row>
                                    <Col className='col-auto'>
                                      <Button onClick={this.handleAddInput} style={{display:'flex', alignItems:'center', gap:'10px'}}><i className="ri-home-2-fill"></i>+ 1 imóvel como permuta</Button>
                                    </Col>
                                    <Col className='col-auto'>
                                      <Button className="btn-info" onClick={this.handleAddCarInput} style={{display:'flex', alignItems:'center', gap:'10px'}}><i className="ri-car-fill"></i>+ 1 automóvel como permuta</Button>
                                    </Col>
                                  </Row>
                                </Container>  
                              }
                                  {this.state.inputs.map((input, index) => (
                                    <Container style={{paddingTop:'20px', paddingBottom:'10px', position:'relative', borderColor:'#3E5E3F'}}>
                                    <i className="ri-delete-bin-line btn-danger" style={{
                                      color:'#fff',
                                      width:'45px',
                                      height:'45px',
                                      borderRadius:'5px',
                                      cursor:'pointer',
                                      position:'absolute',
                                      right:'20px',
                                      top:'20px',
                                      fontSize:'20px',
                                      display:'flex',
                                      alignItems:'center',
                                      justifyContent:'center'
                                    }} onClick={() => this.handleRemoveInput(index)}></i>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F', display:'flex', alignItems:'center', gap:'10px'}}><i className="ri-home-2-fill"></i>{index + 1}º imóvel para permuta</h5></Col>
                                    </Row>
                                    <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                      <Col lg="3">
                                        <Label>Valor do imóvel:</Label>
                                        <CurrencyInput
                                        defaultValue={input.valor || ''}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          this.setState(prevState => {
                                            const newInputs = [...prevState.inputs];
                                            newInputs[index].valor = newValue;
                                            return { inputs: newInputs };
                                          })}}
                                        />
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor={"cep" + index} className="col-md-12 col-form-label">Cep</Label>
                                        <AvField
                                          name={"cep" + index}
                                          placeholder="Cep"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={input.cep || ''}
                                          onChange={(e) => {
                                               if (e.target.value.length !== 8) {
                                                this.setState(prevState => {
                                                  const newInputs = [...prevState.inputs];
                                                  newInputs[index].cep = e.target.value;
                                                  newInputs[index].logradouro = '';
                                                  newInputs[index].bairro = '';
                                                  return { inputs: newInputs };
                                                })
                                               }else{
                                                 fetch('https://viacep.com.br/ws/' + e.target.value + '/json/')
                                                  .then((res) => res.json())
                                                  .then((data) => {
                                                      if (data.erro === true) {
                                                        this.setState(prevState => {
                                                          const newInputs = [...prevState.inputs];
                                                          newInputs[index].cep = e.target.value;
                                                          newInputs[index].logradouro = '';
                                                          newInputs[index].bairro = '';
                                                          return { inputs: newInputs };
                                                        })
                                                      } else {
                                                        this.setState(prevState => {
                                                          const newInputs = [...prevState.inputs];
                                                          newInputs[index].cep = e.target.value;
                                                          newInputs[index].logradouro = data.logradouro;
                                                          newInputs[index].bairro = data.bairro;
                                                          newInputs[index].estado = {value: data.uf, label: data.uf};
                                                          newInputs[index].cidade = {value: data.localidade, label: data.localidade};
                                                          return { inputs: newInputs };
                                                        })
                                                      }
                                                    })
                                               } 
                                          }}
                                          id={"cep" + index}
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor={"rua" + index} className="col-md-12 col-form-label">Rua</Label>
                                        <AvField
                                          name={"rua" + index}
                                          placeholder="Rua, Avenida..."
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={input.logradouro || ''}
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            this.setState(prevState => {
                                              const newInputs = [...prevState.inputs];
                                              newInputs[index].logradouro = newValue;
                                              return { inputs: newInputs };
                                            })}}
                                          id={"rua" + index}
                                        />
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor={"numero" + index} className="col-md-12 col-form-label">Número</Label>
                                        <AvField
                                          name={"numero" + index}
                                          placeholder="Número"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={input.numero || ''}
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            this.setState(prevState => {
                                              const newInputs = [...prevState.inputs];
                                              newInputs[index].numero = newValue;
                                              return { inputs: newInputs };
                                            })}}
                                          id={"numero" + index}
                                        />
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor={"bairro" + index} className="col-md-12 col-form-label">Bairro</Label>
                                        <AvField
                                          name={"bairro" + index}
                                          placeholder="Bairro"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={input.bairro || ''}
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            this.setState(prevState => {
                                              const newInputs = [...prevState.inputs];
                                              newInputs[index].bairro = newValue;
                                              return { inputs: newInputs };
                                            })}}
                                          id={"bairro" + index}
                                        />
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor="complemento" className="col-md-12 col-form-label">Complemento</Label>
                                          <Input type="text" placeholder="Complemento" id="complemento"
                                          value={input.complemento || ''}
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            this.setState(prevState => {
                                              const newInputs = [...prevState.inputs];
                                              newInputs[index].complemento = newValue;
                                              return { inputs: newInputs };
                                            })}}
                                          />
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor={"estado" + index} className="col-md-12 col-form-label">Estado</Label>
                                        <ReactSelect
                                          // value={stateSelected}
                                          placeholder="Selecione"
                                          noOptionsMessage={({ inputValue: string }) => string | null }
                                          onChange={this.handleSelectState}
                                          options={statesAddress}
                                          classNamePrefix="select2-selection"
                                          value={input.estado || ''}
                                          onChange={(e) => {
                                            const newValue = {value: e.value, label: e.value};
                                            this.setState(prevState => {
                                              const newInputs = [...prevState.inputs];
                                              newInputs[index].estado = newValue;
                                              return { inputs: newInputs };
                                            })
                                          
                                            Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + e.value + '/municipios')
                                            .then(response => {
                                              const city = []
                                              for(var i = 0; response.data.length > i; i++){
                                                city.push({
                                                  label: response.data[i].nome,
                                                  value: response.data[i].nome
                                                })
                                              }
                                              this.setState(prevState => {
                                                const newInputs = [...prevState.inputs];
                                                newInputs[index].listaCidades = city;
                                                return { inputs: newInputs };
                                              })
                                            })
                                            }}
                                          id={"estado" + index}
                                        />
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor={"cidade" + index} className="col-md-12 col-form-label">Cidade</Label>
                                        <Select
                                          placeholder="Selecione"
                                          noOptionsMessage={({ inputValue: string }) => string | null }
                                          options={input.listaCidades}
                                          classNamePrefix="select2-selection"
                                          value={input.cidade || ''}
                                          onChange={(e) => {
                                            const newValue = {value: e.value, label: e.value};
                                            this.setState(prevState => {
                                              const newInputs = [...prevState.inputs];
                                              newInputs[index].cidade = newValue;
                                              return { inputs: newInputs };
                                            })}}
                                          id={"cidade" + index}
                                        />
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor={"pais" + index} className="col-md-12 col-form-label">País</Label>
                                        <AvField
                                          name={"pais" + index}
                                          placeholder="País"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={input.pais || ''}
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            this.setState(prevState => {
                                              const newInputs = [...prevState.inputs];
                                              newInputs[index].pais = newValue;
                                              return { inputs: newInputs };
                                            })}}
                                          id={"pais" + index}
                                        />
                                      </Col>
                                      <Col xs={12}>
                                      <Dropzone
                                        onDrop={(files) =>
                                          {files.map(file =>
                                            Object.assign(file, {
                                              preview: URL.createObjectURL(file),
                                              formattedSize: this.formatBytes(file.size)
                                            })
                                          )
                                          const newValue = files;
                                          this.setState(prevState => {
                                            const newInputs = [...prevState.inputs];
                                            newInputs[index].documents = newValue;
                                            return { inputs: newInputs };
                                          })}
                                      }
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>Adicione aqui fotos do imóvel.</h4>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    <div
                                        className="dropzone-previews mt-3"
                                        id="auth-previews"
                                      >
                                        {input.documents?.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted fw-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{f.formattedSize}</strong>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                      </Col>
                                    </Row>
                                    </Container>
                                  ))}
                                  {this.state.inputsCar.map((inputCar, index) => (
                                    <Container style={{paddingTop:'20px', paddingBottom:'10px', position:'relative', borderColor:'#628adc'}}>
                                    <i className="ri-delete-bin-line btn-danger" style={{
                                      color:'#fff',
                                      width:'45px',
                                      height:'45px',
                                      borderRadius:'5px',
                                      cursor:'pointer',
                                      position:'absolute',
                                      right:'20px',
                                      top:'20px',
                                      fontSize:'20px',
                                      display:'flex',
                                      alignItems:'center',
                                      justifyContent:'center'
                                    }} onClick={() => this.handleRemoveCarInput(index)}></i>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F', display:'flex', alignItems:'center', gap:'10px'}}><i className="ri-car-fill"></i>{index + 1}º automóvel para permuta</h5></Col>
                                    </Row>
                                    <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                      <Col md={3}>
                                        <Label htmlFor={"modelo" + index} className="col-md-12 col-form-label">Modelo</Label>
                                        <AvField
                                          name={"modelo" + index}
                                          placeholder="Modelo do veículo"
                                          type="text"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={inputCar.modelo || ''}
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            this.setState(prevState => {
                                              const newInputs = [...prevState.inputsCar];
                                              newInputs[index].modelo = newValue;
                                              return { inputsCar: newInputs };
                                            })}}
                                          id={"modelo" + index}
                                        />
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor={"km" + index} className="col-md-12 col-form-label">Kilometragem (apenas números)</Label>
                                        <AvField
                                          name={"km" + index}
                                          placeholder="Kilometragem em km (apenas números)"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={inputCar.km || ''}
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            this.setState(prevState => {
                                              const newInputs = [...prevState.inputsCar];
                                              newInputs[index].km = newValue;
                                              return { inputsCar: newInputs };
                                            })}}
                                          id={"km" + index}
                                        />
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor={"ano" + index} className="col-md-12 col-form-label">Ano (apenas números)</Label>
                                        <AvField
                                          name={"ano" + index}
                                          placeholder="Ano do veículo"
                                          type="number"
                                          errorMessage="Campo obrigatório."
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          value={inputCar.ano || ''}
                                          onChange={(e) => {
                                            const newValue = e.target.value;
                                            this.setState(prevState => {
                                              const newInputs = [...prevState.inputsCar];
                                              newInputs[index].ano = newValue;
                                              return { inputsCar: newInputs };
                                            })}}
                                          id={"ano" + index}
                                        />
                                      </Col>
                                      <Col lg="3">
                                        <Label>Valor do veículo:</Label>
                                        <CurrencyInput
                                        defaultValue={inputCar.valor || ''}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          this.setState(prevState => {
                                            const newInputs = [...prevState.inputsCar];
                                            newInputs[index].valor = newValue;
                                            return { inputsCar: newInputs };
                                          })}}
                                        />
                                      </Col>
                                      <Col xs={12}>
                                      <Dropzone
                                        onDrop={(files) =>
                                          {files.map(file =>
                                            Object.assign(file, {
                                              preview: URL.createObjectURL(file),
                                              formattedSize: this.formatBytes(file.size)
                                            })
                                          )
                                          const newValue = files;
                                          this.setState(prevState => {
                                            const newInputs = [...prevState.inputsCar];
                                            newInputs[index].documents = newValue;
                                            return { inputsCar: newInputs };
                                          })}
                                      }
                                      accept="image/*"
                                      maxFiles={1}
                                      maxSize={5000000}
                                      multiple={false}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>Adicione aqui documento do veículo.</h4>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    <div
                                        className="dropzone-previews mt-3"
                                        id="auth-previews"
                                      >
                                        {inputCar.documents?.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted fw-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{f.formattedSize}</strong>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                      </Col>
                                      <Col xs={12}>
                                      <Dropzone
                                        onDrop={(files) =>
                                          {files.map(file =>
                                            Object.assign(file, {
                                              preview: URL.createObjectURL(file),
                                              formattedSize: this.formatBytes(file.size)
                                            })
                                          )
                                          const newValue = files;
                                          this.setState(prevState => {
                                            const newInputs = [...prevState.inputsCar];
                                            newInputs[index].photos = newValue;
                                            return { inputsCar: newInputs };
                                          })}
                                      }
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>Adicione aqui fotos do veículo.</h4>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    <div
                                        className="dropzone-previews mt-3"
                                        id="auth-previews"
                                      >
                                        {inputCar.photos?.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted fw-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{f.formattedSize}</strong>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                      </Col>
                                    </Row>
                                    </Container>
                                  ))}
                              </AvForm>
                              </div>
                          </TabPane>
                          <TabPane tabId={3}>
                              <div className="cardBody">
                                <Container style={{paddingTop:'20px', paddingBottom:'10px', position:'relative'}}>
                                    <Row>
                                      <Col xs={12}>
                                        <h2 style={{fontSize:'24px', textAlign:'center'}}>Formalização de proposta para o imóvel: {imovel.title} ({imovel.sku})</h2>
                                      </Col>
                                    </Row>
                                    <Row style={{marginTop:'30px'}}>
                                      <Col xs={12}>
                                          <h3 style={{fontSize:'20px'}}>Proposta para compra de imóvel</h3><br></br>
                                          <h5>Proponente comprador</h5>
                                          <p>{this.state.compradorNome}, {this.state.compradorNacionalidade}, {this.state.compradorEstadoCivil}, RG {this.state.compradorRg}, CPF {this.state.compradorCpf}, residente e domiciliado na {this.state.logradouro}, nº {this.state.numero}, {this.state.complemento}, bairro {this.state.bairro}, em {this.state.citySelected.value}/{this.state.stateSelected.value}, {this.state.pais}, telefone {this.state.compradorTelefone} e e-mail {this.state.compradorEmail}.</p>
                                          <br></br>
                                          <h5>Imóvel</h5>
                                          <p>Imóvel matrícula {this.state.imovel.registry}, {this.state.imovel.real_estate_registration}, {this.state.imovel.title}, {this.state.imovel.endorsed_measures && <>área privativa de {this.state.imovel.endorsed_measures} m²,</>} {this.state.imovel.build_measures && <>área construída de {this.state.imovel.build_measures} m²,</>} {this.state.imovel.terrain_measures && <>área total de {this.state.imovel.terrain_measures} m²,</>} com {this.state.imovel.dorms_number} quartos, {this.state.imovel.bathroom_number} banheiros e {this.state.imovel.parking_spots} vagas de garagem. Localizado na {this.state.imovel.address_properties?.street}, nº {this.state.imovel.address_properties?.number}, {this.state.imovel.address_properties?.complement}, bairro {this.state.imovel.address_properties?.district}, em {this.state.imovel.address_properties?.city}/{this.state.imovel.address_properties?.state}, {this.state.imovel.address_properties?.country}.</p>
                                          <br></br>
                                          {(this.state.imovel.sell_contract_type === 'FS Parc' || this.state.imovel.sell_contract_type === 'FS Parc (sc)') &&
                                            this.state.imovel.broker_id &&
                                              <>
                                                <h5>Corretor captador</h5>
                                                <p>{this.state.imovel.broker?.name}, brasileiro(a), corretor(a) de imóveis, CRECI {this.state.imovel.broker?.creci}, CPF {this.state.imovel.broker?.cpf}, residente e domiciliado na {this.state.imovel.broker?.street}, nº {this.state.imovel.broker?.number}, {this.state.imovel.broker?.complement}, bairro {this.state.imovel.broker?.district}, em {this.state.imovel.broker?.city}/{this.state.imovelbroker?.state}, {this.state.imovel.broker?.country}, telefone {this.state.imovel.broker?.phone} e e-mail {this.state.imovel.broker?.email}. </p>
                                              </>
                                          }

                                          {(this.state.imovel.sell_contract_type === 'FS Parc' || this.state.imovel.sell_contract_type === 'FS Parc (sc)') &&
                                            this.state.imovel.real_estate_id &&
                                              <>
                                                <h5>Imobiliária captadora</h5>
                                                <p>{this.state.imovel.real_estate?.name}, imobiliária, CRECIJ {this.state.imovel.real_estate?.creci_j}, CNPJ {this.state.imovel.real_estate?.cnpj}, localizada na {this.state.imovel.real_estate?.street}, nº {this.state.imovel.real_estate?.number}, {this.state.imovel.real_estate?.complement}, bairro {this.state.imovel.real_estate?.district}, em {this.state.imovel.real_estate?.city}/{this.state.imovel.real_estate?.state}, {this.state.imovel.real_estate?.country}, telefone {this.state.imovel.real_estate?.phone} e e-mail {this.state.imovel.real_estate?.email}. </p>
                                              </>
                                          }
                                          <br></br>
                                          <h5>Corretor de imóveis/imobiliária - Intermediador do negócio</h5>
                                          <p>{user.name}, brasileiro(a), corretor(a) de imóveis, CRECI {user.creci}, CPF {user.cpf}, residente e domiciliado na {user.street}, nº {user.number}, {user.complement}, bairro {user.district}, em {user.city}/{user.state}, {user.country} com o telefone {user.phone} e e-mail {user.email}. </p>
                                          <br></br>
                                          <h5>Plataforma Fast Sale</h5>
                                          <p><b>FAST SALE TECNOLOGIA LTDA</b>, pessoa jurídica de direito privado, inscrita no CNPJ nº 39.985.590/0001-94, com sede na Rua 1500, nº 820, Sala nº 2301, Ed. Sky Business Center, Centro, Balneário Camboriú/SC, CEP: 88.330.526, representada por <b>Renato Valerio Monteiro Alves</b>, brasileiro, solteiro, empresário, portador do RG nº 6.154.939-SSP/SC, inscrito no CPF sob nº 077.382.069-85, com mesmo endereço profissional, E-mail: contato@fastsaleimoveis.com.br - Telefones: (47) 2033-1116 / 99941-1891.</p>
                                          <br></br>
                                          <h5>Proposta</h5>
                                          <p>O <b>PROPONENTE COMPRADOR</b> apesenta a seguinte <b>PROPOSTA</b>:</p>
                                          <p><b>VALOR TOTAL DA PROPOSTA: R$ {(parseInt(this.state.valorProposta.replace('R$ ', '').replace(',', '').replace(/\./g,'')) / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</b></p>
                                          {this.state.avista && <p>A ser pago à vista</p>}
                                          {this.state.parcelado && <>
                                          <p>A ser pago parcelado
                                          {this.state.entrada && <> com uma entrada de R$ {(parseInt(this.state.entrada.replace('R$ ', '').replace(',', '').replace(/\./g,'')) / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</>} e {this.state.numeroParcelas} parcelas no valor de R$ {(parseInt(this.state.parcelas.replace('R$ ', '').replace(',', '').replace(/\./g,'')) / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}
                                          {this.state.reforcos && <> e {this.state.numeroReforcos} reforços no valor de R$ {(parseInt(this.state.reforcos.replace('R$ ', '').replace(',', '').replace(/\./g,'')) / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</>}
                                          </p>
                                          </>}
                                          <br></br>
                                          {this.state.permutas && <>
                                            <h5>Permutas:</h5>
                                            {this.state.inputs?.map((property, index) => (
                                              <><h5 key={index}><b>- 1 imóvel </b>, no valor de R$ {(parseInt(property?.valor.replace('R$ ', '').replace(',', '').replace(/\./g,'')) / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}, localizado na {property?.logradouro}, nº {property?.numero}, {property?.complemento}, bairro {property?.bairro}, em {property?.cidade.value}/{property?.estado.value}, {property?.pais}.</h5><br></br></>
                                            ))}
                                            {this.state.inputsCar?.map((car, index) => (
                                              <><h5 key={index}><b>- 1 automóvel </b>, {car?.modelo}, ano {car?.ano}, com {car?.km} de kilometragem, no valor de R$ {(parseInt(car?.valor.replace('R$ ', '').replace(',', '').replace(/\./g,'')) / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}.</h5><br></br></>
                                            ))}         
                                          </>}
                                          <p>A presente proposta de compra vigorará pelo prazo de <b>{this.state.validadeProposta} dias</b>, a contar de sua assinatura.</p>
                                          <p>Esta proposta submete-se ao disposto nos arts. 722 à 729 da Lei nº 10.406/02 (Código Civil-Capítulo XIII do Contrato de Corretagem), à Lei Federal nº 6.530/78, ao Decreto Federal nº 81.871/78, Resoluções nºs 326/92 e 458/95 do COFECI - Conselho Federal de Corretores de Imóveis: Contrato de Corretagem.</p>
                                        
                                          {(this.state.imovel.sell_contract_type === 'FS Prop'
                                          || this.state.imovel.sell_contract_type === 'FS Corr'
                                          || this.state.imovel.sell_contract_type === 'FS Imob') &&
                                            <p><b>Pagamentos: </b>As Partes, Comprador e Vendedor, declaram sob as penas da lei, estarem cientes e concordes, ao pagamento de 4% (quatro por cento) à título de remuneração da intermediação e corretagem do CORRETOR DE IMÓVEIS/IMOBILIÁRIA (4), 1% (um por cento) a titulo de remuneração do captador, e 1% (um por cento) à título de remuneração dos serviços da plataforma FAST SALE (5), na assinatura do aceite da presente proposta. Inclusive, estão cientes que deverão pagar as referidas remunerações nas seguintes circunstâncias:</p>
                                          }
                                          {this.state.imovel.sell_contract_type === 'FS Imob (antigo)' &&
                                            <p><b>Pagamentos: </b>As Partes, Comprador e Vendedor, declaram sob as penas da lei, estarem cientes e concordes, ao pagamento de 3% (três por cento) à título de remuneração da intermediação e corretagem do CORRETOR DE IMÓVEIS/IMOBILIÁRIA (4), 1% (um por cento) a título de remuneração do captador, 1% (um por cento) à título de remuneração da imobiliária do corretor captador e 1% (um por cento) à título de remuneração dos serviços da plataforma FAST SALE (5), na assinatura do aceite da presente proposta. Inclusive, estão cientes que deverão pagar as referidas remunerações nas seguintes circunstâncias:</p>
                                          }
                                          {this.state.imovel.sell_contract_type === 'FS Parc' &&
                                            <p><b>Pagamentos: </b>As Partes, Comprador e Vendedor, declaram sob as penas da lei, estarem cientes e concordes, ao pagamento de 6% (seis por cento) à título de remuneração da intermediação e corretagem dos CORRETORES (4 e 5), sendo 3% (três por cento) a título de remuneração do captador e 3% (três por cento) a título de remuneração do vendedor, na assinatura do aceite da presente proposta. Inclusive, estão cientes que deverão pagar as referidas remunerações nas seguintes circunstâncias:</p>
                                          }
                                          {this.state.imovel.sell_contract_type === 'FS Parc (sc)' &&
                                            <p><b>Pagamentos: </b>As Partes, Comprador e Vendedor, declaram sob as penas da lei, estarem cientes e concordes, ao pagamento de {this.state.imovel.commission_percentage_on_sale}% à título de remuneração da intermediação e corretagem dos CORRETORES (4 e 5), sendo metade a título de remuneração do captador e metade a título de remuneração do vendedor, na assinatura do aceite da presente proposta. Inclusive, estão cientes que deverão pagar as referidas remunerações nas seguintes circunstâncias:</p>
                                          }
                                          <li>a) Por arrependimento das partes (art. 725 do Código Civil). Caso haja arrependimento da venda e/ou desistência de efetivar a transação;</li>
                                          <li>b) A proposta seja recusada pelo Proprietário do Imóvel, e posteriormente, inicie ou efetive negociação do imóvel sem a participação do corretor de imóveis/imobiliária, inclusive por meio de familiares ou representantes, em valores e condições iguais ou diferentes;</li>
                                          <li>c) Caso a negociação se concretize após o prazo da presente contratação, por efeitos dos trabalhos do corretor de imóveis/imobiliária e da plataforma, independentemente de prazo, lhes serão devidas integralmente as remunerações, pela corretagem, nos termos do art. 727 do Código Civil, e pelos serviços da plataforma;</li>
                                          <li>d) Venda direta ou por intermédio de outrem;</li>
                                          <li>e) Negativa em honrar a venda em condições ajustadas previamente;</li>
                                          <li>f) Caso a venda venha a se concretizar sem a interveniência do corretor de imóveis/imobiliária, mas para comprador indicado anteriormente pelo mesmo.</li>
                                      </Col>
                                    </Row>
                                    </Container>
                                    <Container style={{paddingTop:'20px', paddingBottom:'10px', position:'relative'}}>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F', display:'flex', alignItems:'center', gap:'10px'}}>Anexo - Documento do comprador</h5></Col>
                                      </Row>
                                      <Row>
                                        {this.state.selectedDoc.map((f, i) => {
                                          return (
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                          </Col>
                                          )
                                        })}
                                      </Row>
                                    </Container>
                                    {this.state.inputs?.map((property, index) => (
                                    <Container style={{paddingTop:'10px', paddingBottom:'10px', position:'relative', borderColor:'#3E5E3F'}}>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F', display:'flex', alignItems:'center', gap:'10px'}}><i className="ri-home-2-fill"></i> Fotos do {index + 1}º imóvel</h5></Col>
                                      </Row>
                                      <Row>
                                        {property.documents.map((f, i) => {
                                          return (
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                          </Col>
                                          )
                                        })}
                                      </Row>
                                    </Container>
                                    ))}
                                    {this.state.inputsCar?.map((car, index) => (
                                    <Container style={{paddingTop:'10px', paddingBottom:'10px', position:'relative', borderColor:'#628adc'}}>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F', display:'flex', alignItems:'center', gap:'10px'}}><i className="ri-car-fill"></i>Documento e fotos do {index + 1}º automóvel</h5></Col>
                                      </Row>
                                      <Row>
                                        {car.documents.map((f, i) => {
                                          return (
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                          </Col>
                                          )
                                        })}
                                      </Row>
                                      <Row>
                                        {car.photos.map((f, i) => {
                                          return (
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                          </Col>
                                          )
                                        })}
                                      </Row>
                                    </Container>
                                    ))}
                              </div>
                          </TabPane>
                          <TabPane tabId={4}>
                              <div className="row justify-content-center">
                                  <Col lg="6">
                                      <div className="text-center">
                                          <div className="mb-4">
                                              <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                          </div>
                                          <div>
                                              <h5>Tudo certo!</h5>
                                              <div className="container-text-muted">
                                                <p className="text-muted">Confirme a proposta para que o contrato seja gerado</p>
                                              </div>
                                          </div>
                                          <div>
                                            <Button onClick={() => {
                                              document.getElementById('load').classList.add('active')
                                              const data = new FormData()
                                              data.append('name', this.state.compradorNome)
                                              data.append('nationality',this.state.compradorNacionalidade)
                                              data.append('marital_status',this.state.compradorEstadoCivil)
                                              data.append('occupation',this.state.compradorProfissao)
                                              data.append('cpf_cnpj',this.state.compradorCpf)
                                              data.append('rg',this.state.compradorRg)
                                              data.append('phone',this.state.compradorTelefone)
                                              data.append('email',this.state.compradorEmail)
                                              data.append('cep',this.state.cep)
                                              data.append('street',this.state.logradouro)
                                              data.append('number',this.state.numero)
                                              data.append('complement',this.state.complemento)
                                              data.append('district',this.state.bairro)
                                              data.append('city',this.state.citySelected.value)
                                              data.append('state',this.state.stateSelected.value)
                                              data.append('country',this.state.pais)
                                              data.append('doc', this.state.selectedDoc[0])
                                              Axios.post(process.env.REACT_APP_API + '/api/auth/buyers', data,
                                              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                              .then(response => {
                                                const data = new FormData()
                                                data.append('total_value', this.state.valorProposta ? this.state.valorProposta?.replace('R$ ', '').replace(',', '').replace(/\./g,'') : '')
                                                data.append('in_cash', this.state.avista ? 1 : 0)
                                                data.append('parcel', this.state.parcelado ? 1 : 0 )
                                                data.append('exchange', this.state.permutas ? 1 : 0)
                                                data.append('days', this.state.validadeProposta ? this.state.validadeProposta : '')
                                                data.append('entry', this.state.entrada ? this.state.entrada.replace('R$ ', '').replace(',', '').replace(/\./g,'') : '')
                                                // data.append('bank_financing', financing ? financing?.toString().replace(',', '').replace(/\./g, '') : '')
                                                data.append('qtd_parcels', this.state.numeroParcelas ? this.state.numeroParcelas : '')
                                                data.append('value_parcel', this.state.parcelas ? this.state.parcelas.replace('R$ ', '').replace(',', '').replace(/\./g,'') : '')
                                                data.append('qtd_reinforcements', this.state.numeroReforcos ? this.state.numeroReforcos : '')
                                                data.append('value_reinforcements', this.state.reforcos ? this.state.reforcos.replace('R$ ', '').replace(',', '').replace(/\./g,'') : '')
                                                data.append('buyer_id', response.data.buyer_id)
                                                // data.append('owner_propose_id', localStorage.getItem('proposal_owner_id'))
                                                data.append('type', localStorage.getItem('access'))
                                                data.append('user_id', localStorage.getItem('id'))
                                                data.append('property_id', imovel.id)
                                                Axios.post(process.env.REACT_APP_API + '/api/auth/proposals', data,
                                                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                .then(resp => {
                                                  const proposalId = resp.data.proposal_id;

                                                  let promises = [];
                                                  if(this.state.permutas && this.state.inputs.length > 0){
                                                    this.state.inputs.forEach((imovel, index) => {
                                                      const immobile = new FormData()
                                                      immobile.append('cep', imovel.cep ? imovel.cep : '')
                                                      immobile.append('street', imovel.logradouro ? imovel.logradouro : '')
                                                      immobile.append('number', imovel.numero ? imovel.numero : '')
                                                      immobile.append('complement', imovel.complemento ? imovel.complemento : '')
                                                      immobile.append('district', imovel.bairro ? imovel.bairro : '')
                                                      immobile.append('city', imovel.cidade.value ? imovel.cidade.value : '')
                                                      immobile.append('state', imovel.estado.value ? imovel.estado.value : '')
                                                      immobile.append('country', imovel.pais ? imovel.pais : '')
                                                      immobile.append('value', imovel.valor ? imovel.valor.replace('R$ ', '').replace(',', '').replace(/\./g,'') : '')
                                                      for(let i = 0; i < imovel.documents.length; i++){
                                                        immobile.append('photos[]', imovel.documents[i])
                                                      }
                                                      const promise = Axios.post(process.env.REACT_APP_API + '/api/auth/exchange-property/' + proposalId, immobile, { headers: { "Authorization": "Bearer " + localStorage.getItem('token') } });
                                                      promises.push(promise);
                                                    });
                                                  }
                                                  if(this.state.permutas && this.state.inputsCar.length > 0){
                                                    this.state.inputsCar.forEach((carro, index) => {
                                                      const car = new FormData()
                                                      car.append('model', carro.modelo ? carro.modelo : '')
                                                      car.append('km', carro.km ? carro.km : '')
                                                      car.append('year', carro.ano ? carro.ano : '')
                                                      car.append('value', carro.valor ? carro.valor.replace('R$ ', '').replace(',', '').replace(/\./g,'') : '')
                                                      car.append('doc', carro.documents[0])
                                                      for(let i = 0; i < carro.photos.length; i++){
                                                        car.append('photos[]', carro.photos[i])
                                                      }
                                                      const promise = Axios.post(process.env.REACT_APP_API + '/api/auth/exchange-car/' + proposalId, car, { headers: { "Authorization": "Bearer " + localStorage.getItem('token') } });
                                                      promises.push(promise);
                                                    });
                                                  }
                                                  if(this.state.inputsCar.length > 0 || this.state.inputs.length > 0){
                                                    Promise.all(promises)
                                                    .then(() => {
                                                      document.getElementById('load').classList.remove('active')
                                                      this.setState({ proposal_success: true })
                                                      setTimeout(() => {
                                                        window.open("/propostas", '_parent')
                                                      }, 500);
                                                    })
                                                    .catch(error => {
                                                      console.error(error);
                                                      this.setState({ proposal_error: true })
                                                    });
                                                  }else{
                                                    document.getElementById('load').classList.remove('active')
                                                    this.setState({ proposal_success: true })
                                                    setTimeout(() => {
                                                      window.open("/propostas", '_parent')
                                                    }, 500);
                                                  }
                                                })
                                              })
                                            }}>Confirmar proposta</Button>
                                          </div>
                                      </div>
                                  </Col>
                              </div>
                          </TabPane>
                      </TabContent>
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                          <li className={this.state.activeTabProgress === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTabProgress(this.state.activeTabProgress - 1); }}>Anterior</Link></li>
                          <li className={this.state.activeTabProgress === 4 ? "next disabled" : "next"}><Link to="#" onClick={() => {
                              if(this.state.activeTabProgress === 1){
                                if (this.state.isComponentMounted) {
                                  if(this.state.compradorNome === ''
                                  || this.state.compradorNacionalidade === '' 
                                  || this.state.compradorEstadoCivil === '' 
                                  || this.state.compradorProfissao === '' 
                                  || this.state.compradorCpf === ''
                                  || this.state.compradorTelefone === ''
                                  || this.state.compradorEmail === '' 
                                  || this.state.cep === '' 
                                  || this.state.logradouro === '' 
                                  || this.state.numero === '' 
                                  || this.state.bairro === '' 
                                  || this.state.pais === '' 
                                  || this.state.compradorRg === '' ){
                                    window.scrollTo(0,0)
                                    this.validateRef.current.handleSubmit()
                                  }else{
                                    window.scrollTo(0,0)
                                    this.toggleTabProgress(this.state.activeTabProgress + 1)
                                  }
                                }
                              }else if(this.state.activeTabProgress === 2){
                                if (this.state.isComponentMounted) {
                                  if(this.state.validadeProposta === ''
                                  || this.state.valorProposta === '' ){
                                    window.scrollTo(0,0)
                                    this.validateRef2.current.handleSubmit()
                                  }else{
                                    window.scrollTo(0,0)
                                    this.toggleTabProgress(this.state.activeTabProgress + 1)
                                  }
                                }
                              }else{
                                window.scrollTo(0,0)
                                this.toggleTabProgress(this.state.activeTabProgress + 1)
                              }
                            }}>Próximo</Link></li>
                      </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

         {/* Sucesso na criação da proposta */}

         {this.state.proposal_success ? (
            <SweetAlert
              title="Proposta criada com sucesso!"
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => this.setState({ proposal_success: false })}
            ></SweetAlert>
            ) : null}

          {/* Falha na criação da proposta */}

          {this.state.proposal_error ? (
            <SweetAlert
              title="A criação da proposta falhou, tente novamente."
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ proposal_error: false })}
            ></SweetAlert>
            ) : null}

        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default addProposal;