import styles from '../styles/HighlightsSecond.module.css';
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from 'swiper';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import SearchSecondMobile from './SearchSecondMobile';
import HeaderSecond from './HeaderSecond';
import Icon from '@mdi/react';
import { mdiBedKingOutline, mdiCarBack, mdiRulerSquare } from '@mdi/js';
import ContentEditable from "react-contenteditable";
import { ChromePicker } from 'react-color';

const HighlightsSecond = () => {
  const [ highSecondTitle, setHighSeconfTitle] = useState('Destaques')
  const [ highSecondTitleEnabled, setHighSecondTitleEnabled] = useState(true)
  const [colorSecondButton, setColorSecondButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [showSecondPickerButton, setSecondShowPickerButton] = useState(false);
  const [colorSecondTextButton, setSecondColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [showSecondPickerTextButton, setSecondShowPickerTextButton] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const isMobileQuery = useMediaQuery({ query: `(max-width: 767px)` });

  useEffect(() => {
    setIsMobile(isMobileQuery);

    if(localStorage.getItem('high_button_text_color')){
      setSecondColorTextButton(JSON.parse(localStorage.getItem('high_button_text_color')))
    }
    if(localStorage.getItem('high_button_color')){
      setColorSecondButton(JSON.parse(localStorage.getItem('high_button_color')))
    }
    if(localStorage.getItem('high_title')){
      setHighSeconfTitle(localStorage.getItem('high_title'))
    }

  }, [isMobileQuery]);
 
  return(
    <section>
      {isMobile ? <SearchSecondMobile /> : <HeaderSecond /> }

      {/* <h1 className={styles.title}>Destaques</h1> */}
      <div className={styles.titleContainer}>
        <ContentEditable
            html={highSecondTitle}
            className={styles.titleEdit}
            id='highTitleEdit'
            disabled={highSecondTitleEnabled}
            onChange={(e) => {
              setHighSeconfTitle(e.target.value)
              localStorage.setItem('high_title', e.target.value)
            }}
            onClick={(e) => {
              if(highSecondTitle === 'Destaques' && !highSecondTitleEnabled){
                setHighSeconfTitle('')
                localStorage.setItem('high_title', '')
              }
            }}
          />
          <div className={styles.actionIcons}>
            <label className="title">
              <span onClick={() => {
                if(!highSecondTitleEnabled){
                  setHighSecondTitleEnabled(true)
                  if(highSecondTitle === ''){
                    setHighSeconfTitle('Destaques')
                    localStorage.setItem('high_title', 'Destaques')
                  }
                }else{
                  setHighSecondTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('highTitleEdit').focus()
                    if(highSecondTitle === 'Destaques'){
                      setHighSeconfTitle('')
                      localStorage.setItem('high_title', '')
                    }
                  }, 300);
                }
              }}>{highSecondTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
              <i className="ri-text" onClick={() => {
                if(!highSecondTitleEnabled){
                  setHighSecondTitleEnabled(true)
                  if(highSecondTitle === ''){
                    setHighSeconfTitle('Destaques')
                    localStorage.setItem('high_title', 'Destaques')
                  }
                }else{
                  setHighSecondTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('highTitleEdit').focus()
                    if(highSecondTitle === 'Destaques'){
                      setHighSeconfTitle('')
                      localStorage.setItem('high_title', '')
                    }
                  }, 300);
                }
              }}></i>
            </label>
          </div>
      </div>

      <div className={styles.container_carousel}>
        <Swiper 
          slidesPerView={3}
          spaceBetween={40}
          navigation={true} 
          modules={[Navigation]} 
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            759: {
              slidesPerView: 3
            }
          }}>
          <SwiperSlide>
            <div className={styles.container}>
              <div className={styles.immobile}>
                <div className={styles.bg}></div>
                <div className={styles.immobile_config}>
                  <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                  <div className={styles.immobile_options}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 
                      3 suítes
                    </span>
                    <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9} /> 152m</span>
                  </div>
                  <div>
                    <span className={styles.immobile_price}>R$4.000.000,00</span>
                  </div>
                  <div className={styles.immobile_view}>
                    <div className={styles.details}></div>
                    <p style={{
                      backgroundColor:`rgba(${colorSecondButton?.r}, ${colorSecondButton?.g}, ${colorSecondButton?.b}, ${colorSecondButton?.a})`,
                      color: `rgba(${colorSecondTextButton?.r}, ${colorSecondTextButton?.g}, ${colorSecondTextButton?.b}, ${colorSecondTextButton?.a})`
                    }}>Ver imóvel</p> 
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container}>
              <div className={styles.immobile}>
                <div className={styles.bg}></div>
                <div className={styles.immobile_config}>
                  <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                  <div className={styles.immobile_options}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 3 suítes</span>
                    <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9} /> 152m</span>
                  </div>
                  <div>
                    <span className={styles.immobile_price}>R$4.000.000,00</span>
                  </div>
                  <div className={styles.immobile_view}>
                    <div className={styles.details}></div>
                    <p style={{
                      backgroundColor:`rgba(${colorSecondButton?.r}, ${colorSecondButton?.g}, ${colorSecondButton?.b}, ${colorSecondButton?.a})`,
                      color: `rgba(${colorSecondTextButton?.r}, ${colorSecondTextButton?.g}, ${colorSecondTextButton?.b}, ${colorSecondTextButton?.a})`
                    }}>Ver imóvel</p> 
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container}>
              <div className={styles.immobile}>
                <div className={styles.bg}></div>
                <div className={styles.immobile_config}>
                  <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                  <div className={styles.immobile_options}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9}/> 3 suítes</span>
                    <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9}/> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9}/> 152m</span>
                  </div>
                  <div>
                    <span className={styles.immobile_price}>R$4.000.000,00</span>
                  </div>
                  <div className={styles.immobile_view}>
                    <div className={styles.details}></div>
                    <p style={{
                      backgroundColor:`rgba(${colorSecondButton?.r}, ${colorSecondButton?.g}, ${colorSecondButton?.b}, ${colorSecondButton?.a})`,
                      color: `rgba(${colorSecondTextButton?.r}, ${colorSecondTextButton?.g}, ${colorSecondTextButton?.b}, ${colorSecondTextButton?.a})`
                    }}>Ver imóvel</p> 
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.container}>
              <div className={styles.immobile}>
                <div className={styles.bg}></div>
                <div className={styles.immobile_config}>
                  <p className={styles.immobile_name}>Apartamento Falcon Tower no centro de Balneário Camboriú com 3 suítes</p>
                  <div className={styles.immobile_options}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.9} /> 3 suítes</span>
                    <span className={styles.container_icons}><Icon path={mdiCarBack} size={.9} /> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.9} /> 152m</span>
                  </div>
                  <div>
                    <span className={styles.immobile_price}>R$4.000.000,00</span>
                  </div>
                  <div className={styles.immobile_view}>
                    <div className={styles.details}></div>
                    <p style={{
                      backgroundColor:`rgba(${colorSecondButton?.r}, ${colorSecondButton?.g}, ${colorSecondButton?.b}, ${colorSecondButton?.a})`,
                      color: `rgba(${colorSecondTextButton?.r}, ${colorSecondTextButton?.g}, ${colorSecondTextButton?.b}, ${colorSecondTextButton?.a})`
                    }}>Ver imóvel</p> 
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className={styles.container_button}>
          <button style={{
            backgroundColor:`rgba(${colorSecondButton?.r}, ${colorSecondButton?.g}, ${colorSecondButton?.b}, ${colorSecondButton?.a})`,
            color: `rgba(${colorSecondTextButton?.r}, ${colorSecondTextButton?.g}, ${colorSecondTextButton?.b}, ${colorSecondTextButton?.a})`
          }}>Ver todos os imóveis</button>
          <div className={styles.actionIcons}>
            <label className="title">
              <i className="ri-brush-fill" onClick={() => {
                setSecondShowPickerButton(!showSecondPickerButton)
                setSecondShowPickerTextButton(false)
                }}></i>
              <i className="ri-font-color" onClick={() => {
                setSecondShowPickerTextButton(!showSecondPickerTextButton)
                setSecondShowPickerButton(false)
                }}></i>
               {showSecondPickerButton && (
                <div className={styles.colorPickerButton}>
                  <i className="ri-close-fill closeIcon" onClick={() => setSecondShowPickerButton(!showSecondPickerButton)}></i>
                  <ChromePicker
                    color={colorSecondButton ?? { r: 0, g: 0, b: 0, a: 1 }}
                    onChange={(e) => {
                      setColorSecondButton(e.rgb)
                      localStorage.setItem('high_button_color', JSON.stringify(e.rgb))
                    }}
                  />
                  </div>
                )}
                {showSecondPickerTextButton && (
                <div className={styles.colorPickerButton}>
                  <i className="ri-close-fill closeIcon" onClick={() => setSecondShowPickerTextButton(!showSecondPickerTextButton)}></i>
                  <ChromePicker
                    color={colorSecondTextButton ?? { r: 0, g: 0, b: 0, a: 1 }}
                    onChange={(e) => {
                      setSecondColorTextButton(e.rgb)
                      localStorage.setItem('high_button_text_color', JSON.stringify(e.rgb))
                    }}
                  />
                  </div>
                )}
            </label>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HighlightsSecond;