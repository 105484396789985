import { Button, Col, Container, Input, Label, Row } from 'reactstrap';
import './styles.scss';
import { useEffect, useState } from 'react';
import Axios from 'axios';

export const NewProduct = ({open, onClose}) => {

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState(null)

  const [ticket, setTicket] = useState(0);
  const [pix, setPix] = useState(0);
  const [card, setCard] = useState(0);
  const [recurrenceMonth, setRecurrenceMonth] = useState(0);
  const [recurrenceThird, setRecurrenceThird] = useState(0);
  const [recurrenceSix, setRecurrenceSix] = useState(0);
  const [recurrenceYear, setRecurrenceYear] = useState(0);

  useEffect(() => {
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })

  }, [])

  const handleCreateProduct = () => {
    Axios.post(process.env.REACT_APP_API + '/api/auth/new-product', {
      name:name,
      description:description,
      price:price,
      status:0,
      ticket: ticket,
      pix: pix,
      card: card,
      recurrence_month: recurrenceMonth,
      recurrence_third: recurrenceThird,
      recurrence_six: recurrenceSix,
      recurrence_year: recurrenceYear,
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        console.log(response.data)
      })
      .catch(response =>{
        console.log(response.data)
      })
  }

  const handleCheckboxChange = (setter) => (event) => {
    setter(event.target.checked ? 1 : 0);
  };

 return(
  <>
  <div className={`new-product-modal ${open ? 'active' : ''}`}>
    <span className="new-product-close" onClick={onClose}><i className="ri-close-fill"></i></span>
    <Container>
      <Row>
        <Col>
          <h3>Adicionar novo produto</h3>
        </Col>
      </Row>
      <Row className="new-product-form">
        <Col xs={12}>
          <Label>Nome do produto</Label>
          <Input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
        </Col>
        <Col xs={12}>
          <Label>Descrição</Label>
          <Input type="textarea" value={description} onChange={(e) => setDescription(e.target.value)}/>
        </Col>
        <Col md={6}>
          <Label>Preço original <small>*sem descontos</small></Label>
          <Input type="number" value={price} onChange={(e) => setPrice(e.target.value)}/>
        </Col>
        <Row className="payments-rec">
          <Col className="check-box-payment-option">
            <Input id="ticket" type="checkbox" onChange={handleCheckboxChange(setTicket)} />
            <Label htmlFor='ticket'>Boleto</Label>
          </Col>
          <Col className="check-box-payment-option">
            <Input id="pix" type="checkbox" onChange={handleCheckboxChange(setPix)} />
            <Label htmlFor='pix'>Pix</Label>
          </Col>
          <Col className="check-box-payment-option">
            <Input id="card" type="checkbox" onChange={handleCheckboxChange(setCard)} />
            <Label htmlFor='card'>Cartão de crédito</Label>
          </Col>
        </Row>
        <Row className="payments-options">
          <Col className="check-box-payment-option">
            <Input id="recurrenceMonth" type="checkbox" onChange={handleCheckboxChange(setRecurrenceMonth)} />
            <Label htmlFor='recurrenceMonth'>Mensal</Label>
          </Col>
          <Col className="check-box-payment-option">
            <Input id="recurrenceThird" type="checkbox" onChange={handleCheckboxChange(setRecurrenceThird)} />
            <Label htmlFor='recurrenceThird'>Trimestral</Label>
          </Col>
          <Col className="check-box-payment-option">
            <Input id="recurrenceSix" type="checkbox" onChange={handleCheckboxChange(setRecurrenceSix)} />
            <Label htmlFor='recurrenceSix'>Semestral</Label>
          </Col>
          <Col className="check-box-payment-option">
            <Input id="recurrenceYear" type="checkbox" onChange={handleCheckboxChange(setRecurrenceYear)} />
            <Label htmlFor='recurrenceYear'>Anual</Label>
          </Col>
        </Row>
        <Row className='new-product-actions'>
          <Col className="col-auto"><Button onClick={onClose} color="light">Cancelar</Button></Col>
          <Col className="col-auto"><Button onClick={handleCreateProduct}>Criar produto</Button></Col>
        </Row>
      </Row>
    </Container>
  </div>
  <div className={`new-product-modal-overlay ${open ? 'active' : ''}`} onClick={onClose}></div>
  </>
 ) 
}