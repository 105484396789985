import { Col, Label, Input, Row, Container, Button, Spinner } from "reactstrap";
import './styles.scss';
import { useEffect, useState } from 'react';
import PasswordChecklist from "react-password-checklist";
import EmailInput from '../../../Home/components/EmailInput';
import SweetAlert from 'react-bootstrap-sweetalert';
import Axios from 'axios';

export const EditUserModal = ({open, onClose, selected, handleUpdate, noRole}) => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [truePassword, setTruePassword] = useState(false)
  const [emailIsExist, setEmailIsExist] = useState('0')
  const [type, setType] = useState('')
  const [loading, setLoading] = useState(false)
  const [registerError, setRegisterError] = useState(false)
  const [senhaAtual, setSenhaAtual] = useState('')
  const [senhaVerificada, setSenhaVerificada] = useState(false)
  const [errorPass, setErrorPass] = useState(false)


  useEffect(() => {
    if(open && selected){
      Axios.get(
        process.env.REACT_APP_API + '/api/auth/subusers/' + selected,
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
      ).then(response => {
        setEmail(response.data.email)
        setName(response.data.name)
        setType(response.data.role)
      })
    }
  }, [selected, open])

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail)
  };

  const handleEmailExists = (isExist) => {
    setEmailIsExist(isExist)
    console.log(isExist)
  };

  const handleCreate = () => {
    setLoading(true)
    const data = new FormData()
    data.append('name', name)
    if(password !== ''){
      data.append('password', password)
    }
    data.append('role', type)
    
    Axios.post(
      process.env.REACT_APP_API + '/api/auth/subusers/' + selected,
      data,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
    )
      .then((response) => {
        setLoading(false)
        onClose()
        handleClear()
        handleUpdate()
      })
      .catch((response) => {
        setRegisterError(true)
      })
      
  }

  const handleClear = () => {
    setName('')
    setEmail('')
    setPassword('')
    setConfirmPassword('')
    setTruePassword(false)
    setEmailIsExist('0')
    setType('')
    setSenhaVerificada(false)
    setSenhaAtual('')
  }

  return(
    <>
      <div className={`new-user-modal ${open ? 'active' : ''}`}>
        <span className="close-modal" onClick={onClose}><i className="ri-close-line"></i></span>

        <div className="new-user-modal-title">Editar usuário</div>
        {loading ? 
          <Spinner className="me-2" color="success" />
        :
        <>
        <div className="new-user-modal-body">
          <Container>
            <Row>
              <Col md={6}>
                <fieldset>
                  <Label>Nome</Label>
                  <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </fieldset>
              </Col>
              <Col md={6}>
                <fieldset>
                  <Label>Email</Label>
                  <Input value={email} disabled></Input>
                  {/* <EmailInput simpleVerification={true} value={email} onEmailChange={handleEmailChange} onExists={handleEmailExists}/> */}
                </fieldset>
              </Col>
            </Row>
            {!noRole &&
            <Row className="type-container">
              <Col xs={12}>
                <Label>Selecione a permissão</Label>
              </Col>
              <Col xs={12} md={4}>
                <div
                  className={`type-button ${type === 'view' ? 'active' : ''}`}
                  onClick={() => setType('view')}
                >
                  <i className="ri-eye-line"></i>Apenas visualizar
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div
                  className={`type-button ${type === 'admin' ? 'active' : ''}`}
                  onClick={() => setType('admin')}
                >
                  <i className="ri-edit-line"></i>Fazer edições
                </div>
              </Col>
            </Row>
            }
            {!senhaVerificada ?
              <Row className="new-user-modal-pass">
                <Col xs={12}>Alterar senha</Col>
                <Col md={6}>
                  <fieldset>
                    <Label>Senha atual</Label>
                    <Input 
                      type="password" 
                      value={senhaAtual}
                      onChange={(e) => setSenhaAtual(e.target.value)}
                      required
                    />
                  </fieldset>
                </Col>
                <Col md={6} style={{marginTop:'20px'}}>
                  <Button onClick={() => {
                      const data = {
                        type: 'S',
                        user_id: selected,
                        senha_atual: senhaAtual
                      }
                      Axios.post(process.env.REACT_APP_API + '/api/auth/password-verify', data,
                      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                      .then(response => {
                        setSenhaVerificada(true)
                      })
                      .catch(response => {
                        setErrorPass(true)
                      })
                  }}>Criar nova senha</Button>
                </Col>
              </Row>
            :
            <Row>
              <Col md={6}>
                <fieldset>
                  <Label>Crie sua senha</Label>
                  <Input 
                    type="password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </fieldset>
              </Col>
              <Col md={6}>
                <fieldset>
                  <Label>Confirme sua senha</Label>
                  <Input
                    type="password" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </fieldset>
              </Col>
              <Col xs={12}>
                <PasswordChecklist
                  rules={["minLength","specialChar","number","capital","match"]}
                  minLength={8}
                  iconSize={16}
                  value={password}
                  valueAgain={confirmPassword}
                  onChange={(isValid) => setTruePassword(isValid)}
                  messages={{
                      minLength: "Ao menos 8 caracteres.",
                      specialChar: "Ao menos um caractere especial.",
                      number: "Ao menos um número.",
                      capital: "Ao menos uma letra maiúscula.",
                      match: "Suas senhas coincidem.",
                  }}
                />
              </Col>
            </Row>
            }
          </Container>
        </div>
        <div className="new-user-modal-actions">
          <Button className="close-modal-button" onClick={onClose}>Cancelar</Button>
          {(type !== '' && name !== '') ?
            (senhaVerificada ?
              (truePassword ?
                <Button onClick={handleCreate}>Atualizar usuário</Button>
                :
                <Button disabled>Preencha todos os campos</Button>
              )
              :
              <Button onClick={handleCreate}>Atualizar usuário</Button>
            )
            :
            <Button disabled>Preencha todos os campos</Button>
          }
        </div>
        </>
        }
      </div>
      <div className={`new-user-modal-overlay ${open ? 'active' : ''}`} onClick={onClose}></div>

      {registerError ? (
        <SweetAlert
          title="Seu cadastro falhou, fale com nosso suporte."
          showCloseButton={true}
          showConfirm={true}
          error
          onConfirm={() => {
            setTimeout(() => {
              window.open('https://wa.me/554741081865', '_blank')
            }, 0);
          }}
        ></SweetAlert>
      ) : null}

      {errorPass ? (
        <SweetAlert
          title="Senha incorreta."
          showConfirm={true}
          error
          onConfirm={() => {
            setErrorPass(false)
          }}
        ></SweetAlert>
      ) : null}
    </>
  )
}