import './styles.scss';
import { Button } from 'reactstrap';

export const NoCompatibleProduct = ({open, onClose}) => {

  return(
    <>
      <div className={`compatible-modal ${open ? 'active' : ''}`}>
        <span className="close-modal" onClick={onClose}><i className="ri-close-fill "></i></span>
        <div className="title">
          <h3>Olá, {localStorage.getItem('user_name')}</h3>
        </div>
        <p><b>Você não pode comprar esse plano</b>, escolha um plano adequado para Corretor, Imobiliária ou Construtora.</p>
        <Button onClick={onClose}>Alterar plano</Button>
      </div>
      <div onClick={onClose} className={`compatible-modal-overlay ${open ? 'active' : ''}`}></div>
    </>
  )
}