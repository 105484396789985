import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import './styles.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { NewProduct } from './newProduct';
import { useState } from 'react';

export default function AdmProducts(){
  const [newProduct, setNewProduct] = useState(false)
  const breadcrumbItems = [
    { title: "Fast Sale", link: "/dashboard" },
    { title: "Produtos", link: "#" },
  ]

  return(
    <div className="page-content" id="produtos">
      <Container fluid={true}>
        <Breadcrumbs title="Gestão de produtos" breadcrumbItems={breadcrumbItems} />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Button onClick={() => setNewProduct(true)}>Criar produto</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <NewProduct open={newProduct} onClose={() => setNewProduct(prevState => !prevState)}/>
    </div>
  )
}