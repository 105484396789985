import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import { useEffect } from 'react';


const LocationPin = ({ }) => (
    <div className="raio"></div>
)



const Maps = ({ latI, lngI, zoomLevel }) => (
  latI ?
  < div className="map" id="singleMap">
    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_TOKEN }}
        defaultCenter={{ lat: parseFloat(latI), lng: parseFloat(lngI) }}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={parseFloat(latI)}
          lng={parseFloat(lngI)}
        />
      </GoogleMapReact>
    </div>
  </div >
  :
  <></>
)

export default Maps;