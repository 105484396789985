import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import './../../assets/scss/custom/components/_imovelCard.scss';
import { Button, Card, Col, Container, Modal, ModalBody, ModalHeader, Progress, Row } from 'reactstrap';
import Axios from 'axios';

function EnterpriseCard({imovel, premium, favoritos}) {
  const [favoritado, setFavoritado] = useState(favoritos.includes(imovel.id));
  const [modalCenter, setModalCenter] = useState(false)
  const [userPendent, setUserPendent] = useState(localStorage.getItem('status'))

  console.log(premium)

  const handleFavoritarClick = () => {
    setFavoritado(!favoritado); // alterna entre favoritado e não favoritado

    const endpoint = favoritado
      ? "/api/auth/favorite-delete"
      : "/api/auth/favorite";

    Axios.post(process.env.REACT_APP_API + endpoint, {
      user_id: parseInt(localStorage.getItem("id")),
      type: localStorage.getItem("access"),
      property_id: imovel.id,
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    })
  };

  const date = new Date()
  date.setDate(date.getDate() - 7)

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const [minRooms, setMinRooms] = useState('')
  const [maxRooms, setMaxRooms] = useState('')
  const [minSuites, setMinSuites] = useState('')
  const [maxSuites, setMaxSuites] = useState('')
  const [minGarage, setMinGarage] = useState('')
  const [maxGarage, setMaxGarage] = useState('')
  const [minBath, setMinBath] = useState('')
  const [maxBath, setMaxBath] = useState('')
  const [minPrivate, setMinPrivate] = useState('')
  const [maxPrivate, setMaxPrivate] = useState('')
  const [minTotal, setMinTotal] = useState('')
  const [maxTotal, setMaxTotal] = useState('')
  const [totalUnits, setTotalUnits] = useState('')
  const [minPrice, setMinPrice] = useState('')
  const [maxPrice, setMaxPrice] = useState('')

  // useEffect(() => {
  //   const enterprise = imovel;
  //   enterprise.types.forEach(type => {
  //     setMinRooms(Math.min(minRooms, type.rooms))
  //     setMaxRooms(Math.max(maxRooms, type.rooms))
  //     setMinSuites(Math.min(minSuites, type.suites))
  //     setMaxSuites(Math.max(maxSuites, type.suites))
  //     setMinGarage(Math.min(minGarage, type.garage))
  //     setMaxGarage(Math.max(maxGarage, type.garage))
  //     setMinBath(Math.min(minBath, type.bathrooms))
  //     setMaxBath(Math.max(maxBath, type.bathrooms))
  //     setMinPrivate(Math.min(minPrivate, type.private_area))
  //     setMaxPrivate(Math.max(maxPrivate, type.private_area))
  //     setMinTotal(Math.min(minTotal, type.total_area))
  //     setMaxTotal(Math.max(maxTotal, type.total_area))
  //   });

  //   let minPrice = null;
  //   let maxPrice = null;
  
  //   enterprise.types.forEach((type) => {
  //     type.units.forEach((unit) => {
  //       if (unit.price !== null) {
  //         if (minPrice === null || unit.price < minPrice) {
  //           minPrice = unit.price;
  //         }
          
  //         if (maxPrice === null || unit.price > maxPrice) {
  //           maxPrice = unit.price;
  //         }
  //       }
  //     });
  //   });
  
  
  //   setMinPrice(minPrice !== null ? minPrice : 0);
  //   setMaxPrice(maxPrice !== null ? maxPrice : 0);
  

  //   let totalUnits = 0;
  //   enterprise.types.forEach((type) => {
  //     totalUnits += type.units.length;
  //   });

  //   setTotalUnits(totalUnits)
  // },[imovel])
  

  return (
    <>
      {(userPendent !== '3' && userPendent !== '2') ?
      <Card className="ImovelCard EnterpriseCard" style={{boxShadow:'0 0 8px rgba(0,0,0,0.3)'}}>
        <Container>
        <Link to={"../empreendimento/" + imovel.slug} target="_parent">
          <div className="enterpriseCardcontainer">
          <Row className="slider-row">
              <div className="cover" style={{backgroundImage:'url(' + (premium == 1 ? imovel?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1)[0]?.medium_image : imovel?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1)[0]?.medium_image.replace('default', 'fs')) + ')'}}></div>
              <div style={{
                width:'40px',
                height:'40px',
                backgroundImage:`url('${imovel?.construction?.profile_photo}')`,
                backgroundPosition:'center center',
                backgroundSize: 'cover',
                backgroundColor:'#fff',
                borderRadius:'50%',
                position:'absolute',
                zIndex:'999',
                bottom:'15px',
                left:'5px'
              }}></div>
              <div style={{
                backgroundColor:'#fff',
                position:'absolute',
                zIndex:'998',
                bottom:'25px',
                left:'40px',
                borderRadius:'0 5px 5px 0',
                width: 'fit-content',
                fontSize:'12px'
              }}>{imovel?.construction?.name}</div>
          </Row>
          <Row className="enterpriseInfoContainer">
            <Col xs={12} style={{padding:0}}>
              <h3 style={{whiteSpace:'nowrap', height:'30px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{imovel?.title}</h3>
            </Col>
            <Col xs={6} style={{paddingLeft:0}}>
              <p style={{fontSize:'10px'}}><i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i>{imovel?.city}</p>
            </Col>
            <Col xs={6} style={{paddingRight:0}}>
              <div className="progress-container"><small>Andamento da obra</small><span>{imovel?.progress}%</span></div>
              <Progress color="success" className="animated-progess mb-4" value={imovel?.progress}></Progress>
            </Col>
          </Row>
          </div>
          </Link>  
        </Container>   
      </Card>
  :
  //Usuário sem documentos
<Card className="ImovelCard EnterpriseCard" style={{boxShadow:'0 0 8px rgba(0,0,0,0.3)'}}>
        <Container>
          <div className="enterpriseCardcontainer">
          <Row className="slider-row">
              <div className="cover" style={{backgroundImage:'url(' + imovel?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1)[0]?.medium_image + ')'}}></div>
              <div style={{
                width:'40px',
                height:'40px',
                backgroundImage:`url('${imovel?.construction?.profile_photo}')`,
                backgroundPosition:'center center',
                backgroundSize: 'cover',
                backgroundColor:'#fff',
                borderRadius:'50%',
                position:'absolute',
                zIndex:'999',
                bottom:'15px',
                left:'5px'
              }}></div>
              <div style={{
                backgroundColor:'#fff',
                position:'absolute',
                zIndex:'998',
                bottom:'25px',
                left:'40px',
                borderRadius:'0 5px 5px 0',
                width: 'fit-content',
                fontSize:'12px'
              }}>{imovel?.construction?.name}</div>
          </Row>
          <Row className="enterpriseInfoContainer">
            <Col xs={12} style={{padding:0}}>
              <h3 style={{height:'41px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{imovel?.title}</h3>
            </Col>
            <Col xs={6} style={{paddingLeft:0}}>
              <p style={{fontSize:'10px'}}><i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i>{imovel?.city}</p>
            </Col>
            <Col xs={6} style={{paddingRight:0}}>
              <div className="progress-container"><small>Andamento da obra</small><span>{imovel?.progress}%</span></div>
              <Progress color="success" className="animated-progess mb-4" value={imovel?.progress}></Progress>
            </Col>
          </Row>
          </div>
        </Container>   
      </Card>
    // <Card className="ImovelCard" style={{boxShadow:'0 0 8px rgba(0,0,0,0.3)'}}>
    //     <Container>
    //       <Row className="slider-row" >
    //           <Row className="comissionContainer">
    //             <Col xs={3} className="until">Até</Col>
    //             <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
    //             <Col xs={6} className="comission">
    //             {imovel.sell_contract_type === 'FS Imob (antigo)' &&
    //                 <span className="fs">Contrato FS</span>
    //               }
    //               {imovel.sell_contract_type === 'FS Parc' &&
    //                 <span className="parc">Parceria</span>
    //               }
    //               {imovel.sell_contract_type === 'FS Parc (sc)' &&
    //                 <span className="parc">Parceria</span>
    //               }
    //               {(imovel.sell_contract_type === 'FS Corr'
    //               || imovel.sell_contract_type === 'FS Imob') &&
    //                 <span className="fs">Contrato FS</span>
    //               }
    //               {imovel.sell_contract_type === 'FS Prop' &&
    //                 <span className="fs">Contrato FS</span>
    //               }
    //             </Col>
    //           </Row>
    //           <ImovelCardCarousel  photos={imovel?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1).slice(0,4)}/>
    //             <p style={{
    //               zIndex: '3', fontSize:'18px', position:'absolute', left:'0', top:'85px', color:'#fff', backgroundColor:'#3E5E3F', textAlign:'center'
    //             }}>Imóvel vendido!</p>
    //       </Row>
    //       <Row>
    //         <h3 style={{height:'41px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{imovel?.title}</h3>
    //         <p><i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i>{imovel?.address_properties?.city}</p>
    //       </Row>
    //       <Row>
    //         <Col xs={10} style={{padding:'0'}}>
    //           <Row className="skills">
    //             <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number}</p></Col>
    //             <Col><i className="ri-car-line"></i><p>{imovel?.parking_spots}</p></Col>
    //             <Col><i className="ri-ruler-line"></i><p>{
    //                   (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
    //                   (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
    //                   (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
    //                   }m²</p></Col>
    //           </Row>
    //         </Col>
    //       </Row> 
    //       <Row>
    //         <div className="line"></div>
    //       </Row>
    //       <Row>
    //         <Col xs={12} style={{padding:'0 10px 0 0'}}>
    //           {imovel?.sell_price && imovel?.sell_price !== 0 ?
    //             <div className="offer-container">
    //               <div className="old-price"><span>R${(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
    //               <div className="offer-price"><small>R$</small><span>{(imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
    //             </div>
    //           :
    //             <div className="offer-container">
    //               <div className="offer-price"><small>R$</small><span>{(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
    //             </div>
    //           }
    //         </Col>
    //       </Row>
    //     </Container>   
    //   </Card>
  }
    <Modal
        isOpen={modalCenter}
        toggle={() => setModalCenter(false)}
        centered={true}
    >
        <ModalHeader toggle={() => setModalCenter(false)}
        style={{backgroundColor:"#ed4c4b", minHeight:'120px'}}>
        <h3 style={{color:"#fff"}}>Atenção!</h3>
        </ModalHeader>
        <ModalBody style={{textAlign:'center'}}>
        <p>Valide seu cadastro para visualizar todas as informações do imóvel.</p>
        <br></br>
        <Button onClick={() => {
          window.open('./editar-perfil', '_parent')
        }}>Validar agora</Button>
        </ModalBody>
    </Modal>
  </>
  )
}

export default EnterpriseCard;