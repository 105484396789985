import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

const id = window.location.search.replace('?id=', '')

class EnterpriseInformation extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      descricao:'',
      comissao:'',
      matricula:'',
      area:0,
      units:0,
      floors:0,
      selectedUf: null,
            selectedCity: null,
      selectOptionsUf: [],
      statesAddress: [],
      selectOptionsCity: [],
      stateSelected: '',
      citySelected:'',
      cep:'',
      logradouro:'',
      complemento:'',
      atualCatalog:'',
      tour:'',
      numero:'',
      bairro:'',
      catalog:'',
      pais:'Brasil',
      newCatalog:false,
      }
      this.updateEnterprise = this.updateEnterprise.bind(this);
    }

    componentDidMount(){
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            this.setState({enterprise: response.data})

            this.setState({
                descricao:response.data.description,
                comissao:response.data.comission,
                matricula:response.data.register,
                area:response.data.area,
                floors:response.data.floors,
                units: response.data.units_number,
                tour: response.data.tour_360,
                atualCatalog: response.data.catalog
              })

              this.setState({
                cep: response.data.cep,
                complemento: response.data.complement,
                pais: response.data.country,
                bairro: response.data.district,
                numero: response.data.number,
                logradouro: response.data.street,
              })
    
              this.setState({stateSelected: {
                value:response.data.state,
                label:response.data.state
              }})
              this.setState({citySelected: {
                value:response.data.city,
                label:response.data.city
              }});

              Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados',)
              .then(response => {
                const states = []
                for(var i = 0; response.data.length > i; i++){
                  states.push({
                    value: response.data[i].sigla,
                    label: response.data[i].nome
                  })
                }
                this.setState({statesAddress: states})
              })
         })
        })
        .catch(response =>{
            setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
            }) 
    }

    consultaCep = cepValue => {
        const cep = cepValue
          if (cep?.length !== 8) {
          this.setState({logradouro: ''});
          this.setState({bairro: ''});
          this.setState({stateSelected: ''})
          this.setState({citySelected: ''});
          }else{
            fetch('https://viacep.com.br/ws/' + cep + '/json/')
            .then((res) => res.json())
            .then((data) => {
                if (data.erro === true) {
                  this.setState({logradouro: ''});
                  this.setState({bairro: ''});
                  this.setState({stateSelected: ''})
                  this.setState({citySelected: ''});
                } else {
                  this.setState({logradouro: data.logradouro});
                  this.setState({bairro: data.bairro});
                  this.setState({stateSelected: {
                    value:data.uf,
                    label:data.uf
                  }})
                  this.setState({citySelected: {
                    value:data.localidade,
                    label:data.localidade
                  }});
                }
              })
          }  
      }

      updateEnterprise(event) {
        if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
            this.setState({error_auth:true})
        }else{
        document.getElementById('load').classList.add('active')

        const data = new FormData();
        if(this.state.descricao){data.append('description',  this.state.descricao)}
        data.append('comission', this.state.comissao ? this.state.comissao : '')
        data.append('register', this.state.matricula ? this.state.matricula : '')
        data.append('area', this.state.area ? this.state.area : 0)
        data.append('floors', this.state.floors ? this.state.floors : 0)
        data.append('units_number', this.state.units ? this.state.units : 0)
        if(this.state.cep){data.append('cep', this.state.cep)}
        if(this.state.logradouro){data.append('street',  this.state.logradouro)}
        if(this.state.numero){data.append('number',  this.state.numero)}
        if(this.state.bairro){data.append('district',  this.state.bairro)}
        if(this.state.complemento){data.append('complement',  this.state.complemento)}
        if(this.state.stateSelected.value){data.append('state',  this.state.stateSelected.value)}
        if(this.state.citySelected.value){data.append('city',  this.state.citySelected.value)}
        data.append('tour_360', this.state.tour ? this.state.tour : '')
        data.append('catalog', this.state.catalog ? this.state.catalog : '')
        data.append('country', 'Brasil')

        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/'+ id, data, {
        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
            setTimeout(() => {
                window.open('./../perfil-empreendimento?id=' + id, '_parent')
            }, 100);
        })
    }
   }

    render(){
        const {enterprise, statesAddress, cityAddress, citySelected, stateSelected } = this.state;

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                            <Container style={{marginTop:'30px'}}>
                                                <Row style={{margin:'10px 0'}}>
                                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o empreendimento</h5></Col>
                                                </Row>
                                                <Row>
                                                {/* <Col md={3}>
                                                <AvGroup>
                                                    <Label className="col-md-12 col-form-label">Comissão (%)</Label>
                                                    <AvField
                                                    name="comissao"
                                                    placeholder="Comissão para venda (%)"
                                                    type="number"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.comissao}
                                                    onChange={(e) => {
                                                        this.setState({comissao: e.target.value})
                                                    }}
                                                    />
                                                    </AvGroup>
                                                </Col> */}
                                                <Col md={3}>
                                                <AvGroup>
                                                    <Label className="col-md-12 col-form-label">Incorporação</Label>
                                                    <AvField
                                                    name="matricula"
                                                    placeholder="Nº de incorporação"
                                                    type="text"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.matricula}
                                                    onChange={(e) => {
                                                        this.setState({matricula: e.target.value})
                                                    }}
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                <Col md={3}>
                                                <AvGroup>
                                                    <Label htmlFor="areaImovel" className="col-md-12 col-form-label">Área do terreno</Label>
                                                    <AvField
                                                    name="area"
                                                    placeholder="Área em m²"
                                                    type="number"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.area}
                                                    onChange={(e) => {
                                                        this.setState({area: e.target.value})
                                                    }}
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                <Col md={3}>
                                                <AvGroup>
                                                    <Label htmlFor="floorsImovel" className="col-md-12 col-form-label">Nº de andares</Label>
                                                    <AvField
                                                    name="floors"
                                                    placeholder="Quantidade de andares"
                                                    type="number"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.floors}
                                                    onChange={(e) => {
                                                        this.setState({floors: e.target.value})
                                                    }}
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <AvGroup>
                                                        <Label htmlFor="unitsImovel" className="col-md-12 col-form-label">Nº de unidades</Label>
                                                        <AvField
                                                        name="units"
                                                        placeholder="Quantidade de unidades"
                                                        type="number"
                                                        errorMessage="Campo obrigatório."
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        value={this.state.units}
                                                        onChange={(e) => {
                                                            this.setState({units: e.target.value})
                                                        }}
                                                        />
                                                        </AvGroup>
                                                    </Col>
                                                </Row>
                                            </Container>
                                            <Container>
                                                <Row style={{margin:'10px 0'}}>
                                                <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Descrição do empreendimento</h5></Col>
                                                </Row>
                                                <Row>
                                                <Col md={12}>
                                                <AvGroup>
                                                    <Label htmlFor="descricaoImovel" className="col-md-12 col-form-label">Descrição</Label>
                                                    <AvField
                                                    name="descricaoImovel"
                                                    placeholder={`Ex.: Bem-vindo ao nosso moderno e sofisticado prédio residencial. Localizado em uma área privilegiada da cidade, este edifício oferece uma vida luxuosa e conveniente. Com arquitetura contemporânea e design elegante, você encontrará uma combinação perfeita entre estilo e funcionalidade.\nO edifício é um verdadeiro oásis urbano, com amenidades de primeira classe, como uma piscina panorâmica no terraço, uma academia bem equipada e áreas de lazer ao ar livre. A segurança e a privacidade são prioridades, com acesso controlado e vigilância 24 horas.\nCada apartamento é projetado para oferecer o máximo de conforto e elegância. As unidades são espaçosas, com janelas amplas que permitem a entrada de luz natural, destacando os acabamentos de alta qualidade. As cozinhas são equipadas com aparelhos modernos e bancadas em pedra.\nO prédio está estrategicamente localizado perto de restaurantes gourmet, lojas de luxo e centros culturais. Além disso, oferece fácil acesso a transporte público e vias importantes da cidade. Viva o melhor da vida urbana em um ambiente que combina com seu estilo de vida.`}
                                                    type="textarea"
                                                    errorMessage="Campo obrigatório."
                                                    className="form-control"
                                                    rows="10"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.descricao}
                                                    onChange={(e) => this.setState({descricao: e.target.value})}
                                                    id="descricaoImovel"
                                                    />
                                                    </AvGroup>
                                                </Col>
                                                </Row>
                                                <Row>
                                                <Col md={12}>
                                                    <Label htmlFor="tour" className="col-md-12 col-form-label">Tour 360</Label>
                                                    <Input
                                                        name="tour"
                                                        placeholder="Link para Tour 360"
                                                        type="text"
                                                        value={this.state.tour}
                                                        onChange={(e) => {
                                                            this.setState({tour: e.target.value})
                                                        }}
                                                    />
                                                </Col>
                                                </Row>
                                                <Row>
                                                    {(this.state.atualCatalog && this.state.atualCatalog !== '') ?
                                                        <Col xs={12} style={{marginBottom:'20px'}}>
                                                            <a style={{textDecoration:'underline'}} href={this.state.atualCatalog} target="_blank" rel="noreferrer">
                                                                <div style={{
                                                                    display:'flex',
                                                                    flexDirection:'column',
                                                                    gap:'8px',
                                                                    alignItems:'center',
                                                                    justifyContent:'center',
                                                                    backgroundColor:'#f4f4f4',
                                                                    width:'140px',
                                                                    height:'100px',
                                                                    borderRadius:'5px'
                                                                }}>
                                                                    <i className="ri-file-text-line" style={{fontSize:'28px'}}></i>
                                                                    <span>Ver catálogo</span>
                                                                </div>
                                                            </a>
                                                            {this.state.newCatalog ?
                                                                <Col md={12}>
                                                                    <div className="input-group" style={{marginTop:'10px'}}>
                                                                    <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Catálogo (PDF até 50mb)</Label>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            id="customFile"
                                                                            onChange={(e) => this.setState({catalog: e.target.files[0]})}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                :
                                                                <Button style={{margin:'10px 0 0 35px'}} onClick={() => this.setState({newCatalog: true})}>Alterar</Button>
                                                            }
                                                        </Col>
                                                        :
                                                        <Col md={12}>
                                                            <div className="input-group" style={{marginTop:'10px'}}>
                                                            <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Catálogo (PDF)</Label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="customFile"
                                                                    onChange={(e) => this.setState({catalog: e.target.files[0]})}
                                                                />
                                                            </div>
                                                        </Col>
                                                    }
                                                </Row>
                                            </Container>
                                            <Container style={{marginTop:'30px'}}>
                                            <Row style={{margin:'10px 0'}}>
                                            <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Endereço</h5></Col>
                                            </Row>
                                            <Row>
                                            <Col md={4}>
                                                <Label htmlFor="cep" className="col-md-12 col-form-label">Cep</Label>
                                                <AvField
                                                name="cep"
                                                placeholder="Cep"
                                                type="text"
                                                errorMessage="Campo obrigatório."
                                                className="form-control"
                                                value={this.state.cep}
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    this.consultaCep(e.target.value.replace('-', '').replace('.', ''))
                                                    this.setState({cep: e.target.value})
                                                }}
                                                id="cep"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Label htmlFor="rua" className="col-md-12 col-form-label">Rua</Label>
                                                <AvField
                                                name="rua"
                                                placeholder="Rua, Avenida..."
                                                type="text"
                                                errorMessage="Campo obrigatório."
                                                className="form-control"
                                                value={this.state.logradouro}
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    this.setState({logradouro: e.target.value})
                                                }}
                                                id="rua"
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <Label htmlFor="numero" className="col-md-12 col-form-label">Número</Label>
                                                <AvField
                                                name="numero"
                                                placeholder="Número"
                                                type="text"
                                                errorMessage="Campo obrigatório."
                                                className="form-control"
                                                value={this.state.numero}
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    this.setState({numero: e.target.value})
                                                }}
                                                id="numero"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Label htmlFor="bairro" className="col-md-12 col-form-label">Bairro</Label>
                                                <AvField
                                                name="bairro"
                                                placeholder="Bairro"
                                                type="text"
                                                errorMessage="Campo obrigatório."
                                                className="form-control"
                                                value={this.state.bairro}
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    this.setState({bairro: e.target.value})
                                                }}
                                                id="bairro"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Label htmlFor="complemento" className="col-md-12 col-form-label">Complemento</Label>
                                                <Input type="text" placeholder="Complemento" id="complemento" value={this.state.complemento} onChange={(e) => this.setState({complemento: e.target.value})} />
                                            </Col>
                                            <Col md={4}>
                                                <Label htmlFor="estado" className="col-md-12 col-form-label">Estado</Label>
                                                <Select
                                                value={stateSelected}
                                                placeholder="Selecione"
                                                noOptionsMessage={(inputValue) => `Sem resultados`}
                                                onChange={this.handleSelectState}
                                                options={statesAddress}
                                                classNamePrefix="select2-selection"
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Label htmlFor="cidade" className="col-md-12 col-form-label">Cidade</Label>
                                                <Select
                                                value={citySelected}
                                                placeholder="Selecione"
                                                noOptionsMessage={(inputValue) => `Sem resultados`}
                                                onChange={(e) => {
                                                    this.setState({ citySelected: e})
                                                }}
                                                options={cityAddress}
                                                classNamePrefix="select2-selection"
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Label htmlFor="pais" className="col-md-12 col-form-label">País</Label>
                                                <AvField
                                                name="pais"
                                                placeholder="País"
                                                type="text"
                                                errorMessage="Campo obrigatório."
                                                className="form-control"
                                                value={this.state.pais}
                                                validate={{ required: { value: true } }}
                                                onChange={(e) => {
                                                    this.setState({pais: e.target.value})
                                                }}
                                                id="pais"
                                                />
                                            </Col>
                                            </Row>
                                        </Container>
                                            <Container>
                                                <Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                                    <Col className="col-auto">
                                                        <Button className="btn-dark" onClick={() => {
                                                            setTimeout(() => {
                                                                window.open('./../perfil-empreendimento?id=' + id, '_parent')
                                                            }, 100);
                                                        }}>Voltar</Button>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={this.updateEnterprise}>Salvar</Button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                    </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                
            {this.state.error_auth ? (
                <SweetAlert
                    error
                    title='Ops'
                    onConfirm={() => this.setState({ error_auth: false })}
                >
                    Você não tem permissão para esta ação.
                </SweetAlert>
            ) : null}
            </div>
        )
    }

}

export default EnterpriseInformation;