import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import './styles.scss';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import { useState } from 'react';
import Axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';

export const UpdateProfilePhoto = ({open, onClose, onSendImage}) => {
    const [selectedImage, setSelectedImage] = useState(null)
    const [sendError, setSendError] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSendPhoto = () => {
        setLoading(true)
        const data = new FormData()
        data.append('type', localStorage.getItem('access'))
        data.append('user_id', localStorage.getItem('id'))
        data.append('profile_photo', selectedImage)

        Axios.post(process.env.REACT_APP_API + '/api/auth/user-update', data,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => {
                onSendImage()
                onClose()
                setLoading(false)
            }).catch(res => {
                setSendError(true)
                setLoading(false)
            })
    }

    return(
        <>
        <Modal
            isOpen={open}
            toggle={onClose}
            size="xl"
            centered={true}
            scrollable={true}
        >
            <ModalHeader toggle={onClose} style={{padding:'0 80px'}}>
                {loading ? <h3 style={{fontSize:'18px'}}>Enviando...</h3> : <h3 style={{fontSize:'18px'}}>Ops</h3>}
            </ModalHeader>
            <ModalBody>
                {loading ? 
                    <Spinner className="me-2" color="success" />
                :
                    <>
                    <Label>Você precisa ter uma foto de perfil para fazer um pedido.</Label>

                    <AvForm className="cardBody needs-validation">
                        <div className="input-group" style={{marginTop:'10px'}}>
                            <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Foto de perfil (jgp, jpeg ou png)</Label>
                            <input
                                type="file"
                                className="form-control"
                                accept=".png, .jpg, .jpeg"
                                id="customFile"
                                onChange={(e) => setSelectedImage(e.target.files[0])}
                            />
                        </div>
                    </AvForm>

                    <Row style={{marginTop:'20px', justifyContent:'center'}}>
                        <Col className="col-auto" onClick={onClose}><Button color="dark">Cancelar</Button></Col>
                        {selectedImage ?
                            <Col className="col-auto" onClick={handleSendPhoto}><Button>Enviar</Button></Col>
                            :
                            <Col className="col-auto"><Button disabled>Enviar</Button></Col>
                        }
                    </Row>   
                    </>
                }
            </ModalBody>
        </Modal>
        
            {sendError ? (
                <SweetAlert
                    success
                    title={'Opds'}
                    onConfirm={() => {
                        setSendError(false)
                    }}
                >
                    <p>A foto não pode ser enviada, confira se o arquivo atende as características necessárias.</p>
                </SweetAlert>
            ) : null}
        </>
    )
}