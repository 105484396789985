import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, { Component, useRef } from "react";
import Axios from 'axios';
import { Link } from "react-router-dom";
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Progress, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import Flatpickr from "react-flatpickr"
import 'flatpickr/dist/l10n/pt';
import "flatpickr/dist/themes/airbnb.css";
import Dropzone, { useDropzone } from 'react-dropzone';
import { Draggable } from "react-drag-reorder";
import EnterprisePhotosOrder from "../editProperties/enterprisePhotosOrder";
import EnterpriseProgressPhotosOrder from "../editProperties/enterpriseProgressPhotosOrder";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

require('moment/locale/pt.js');

const id = window.location.search.replace('?id=', '')

class EnterpriseBuilding extends Component {
    constructor(props) {
      super(props);
      this.flatpickrRef = React.createRef();
      this.flatpickrRefStart = React.createRef();
      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      enterprise:[],
      previsao:'',
      andamento:'0',
      inicio:'',
      editedVideos:[],
      videos: [],
      enterpriseVideos:[],
      photosOrder:null,
      dragPhotos:'',
      photosArray:[],
      selectedDocs: [],
      docsEditList:[],
      selectedFiles: [],
      deleteSuccess:false,
      errors:[],
      deleteError:false,
      count:0,
      progressBars: [],
      deleteSuccessVideo:false,
      errorsVideo:[],
      deleteErrorVideo:false,
      countVideo:0,
      progressBarsVideo: [],
      sendImageError:false,

      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000
      }
      this.updateEnterprise = this.updateEnterprise.bind(this);
      this.updateVideos = this.updateVideos.bind(this);
      this.updatePositions = this.updatePositions.bind(this);
      this.deleteImage = this.deleteImage.bind(this);
      this.showToast.bind(this);
    }

    showToast(type, title, message = "") {
      var positionClass = "toast-bottom-right";
      
      toastr.options = {
        positionClass: positionClass,
        timeOut: this.state.timeOut,
        extendedTimeOut: this.state.extendedTimeOut,
        closeButton: true,
        progressBar: true,
        newestOnTop: true,
        showEasing: this.state.showEasing,
        hideEasing: this.state.hideEasing,
        showMethod: this.state.showMethod,
        hideMethod: this.state.hideMethod,
        showDuration: this.state.showDuration,
        hideDuration: this.state.hideDuration
      };
    
      if (type === "info") {
        toastr.info(message, title);
      } else if (type === "warning") {
        toastr.warning(message, title);
      } else if (type === "error") {
        toastr.error(message, title);
      } else {
        toastr.success(message, title);
      }
    }    

    componentDidMount(){
      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            this.setState({
                enterprise: response.data,
                docsEditList: response.data.progress_videos
            })

            this.setState({
                andamento: response.data.progress
              })

             this.setState({ previsao: response.data.delivery?.replace('-', '/').replace('-', '/') }, () => {
                 const flatpickr = this.flatpickrRef.current;
                 if (flatpickr) {
                     flatpickr.flatpickr?.setDate(response.data.delivery?.replace('-', '/').replace('-', '/'), true, 'd-m-Y');
                 }
             })
             this.setState({ inicio: response.data.start?.replace('-', '/').replace('-', '/') }, () => {
                const flatpickr = this.flatpickrRefStart.current;
                if (flatpickr) {
                    flatpickr.flatpickr?.setDate(response.data.start?.replace('-', '/').replace('-', '/'), true, 'd-m-Y');
                }
            })
         })
        })
        .catch(response =>{
            setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
            })   
    }

    removeImage(index) {
        const { selectedFiles } = this.state;
        selectedFiles.splice(index, 1);
        this.setState({ selectedFiles });
        this.setState({dragPhotos: null})
        setTimeout(() => {
          this.handleAcceptedFiles(this.state.selectedFiles);      
        }, 300);
      }

      formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      };

      handleFormData = (orders, update) => {
        const data = new FormData();
        for(let i = 0; i < update.length; i++){
          data.append('order[]', orders[i])
          data.append('id[]', update[i])
        }
        this.setState({ photosOrder: data });

        this.updatePositions()
      };
      
      handleAcceptedDocs = files => {
        files.map(file => {
          let fileType;
          if (file.type === 'application/pdf') {
            fileType = 'pdf';
          } else {
            fileType = file.type;
          }
      
          return Object.assign(file, {
            fileType,
            formattedSize: this.formatBytes(file.size)
          });
        });
      
        this.setState({ selectedDocs: files });

        this.updateVideos()
      };

      handleAcceptedFiles = files => {
        const acceptedFiles = [];
        files.forEach(file => {
            if (file.size <= 8 * 1024 * 1024) {
                acceptedFiles.push(file);
            } else {
                this.setState(prevState => ({
                    errors: [...prevState.errors, { name: file.name, message: 'Tamanho excede 8MB' }]
                }));
            }
        });

        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: this.formatBytes(file.size)
          })
        );
    
        this.setState({ selectedFiles: files }, () => {
          this.setState({photosArray: this.state.selectedFiles.map((file, index) => ({
            file: file,
            position: index
          }))});
        });

        this.updateEnterprise()
      };

   updateEnterprise(event) {
    document.getElementById('load').classList.add('active')
    const { photosArray } = this.state;
    const totalPhotos = photosArray.length;
    this.setState({ progressBars: new Array(totalPhotos).fill(0) });

    if(this.state.photosArray.length > 0){

      photosArray.forEach((photo, index) => {
        const dataEnterprisePhotos = new FormData();
        dataEnterprisePhotos.append(`photo[0][file]`, photo.file);
        dataEnterprisePhotos.append(`photo[0][position]`, photo.position);
        dataEnterprisePhotos.append(`photo[0][property_enterprise_id]`, id);

        setTimeout(() => {
          Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-progress-photos', dataEnterprisePhotos, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
            onUploadProgress: event => {
                const progress = Math.round(100 * event.loaded) / event.total;
                const updatedProgressBars = [...this.state.progressBars];
                updatedProgressBars[index] = progress;
                this.setState({ progressBars: updatedProgressBars }, () => {
                  this.forceUpdate();
              });
            }
        }).then(response => {
            if (index === totalPhotos - 1) {
                document.getElementById('load').classList.remove('active')

                Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
                  headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                }).then(response => {
                   this.setState({
                       enterprise: response.data,
                   })
                   this.setState({count : this.state.count + 1})
                   this.setState({photosArray : [], selectedFiles: []})
                })
            }
        }).catch(error => {
          const updatedErrors = [...this.state.errors];
          updatedErrors[index] = { isError: true, message: 'Erro no envio da imagem' };
          this.setState({ errors: updatedErrors });
          this.setState({sendImageError: true})
        });
        }, 500);

      });
    }
}

updateBuilding(){
  if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
    this.setState({error_auth:true})
  }else{
    document.getElementById('load').classList.add('active')
    const data = new FormData();
    data.append('delivery', this.state.previsao)
    data.append('start', this.state.inicio)
    if(this.state.andamento){
      data.append('progress', this.state.andamento)
    }

    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/'+ id, data, {
    headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
    }).then(response => {
      setTimeout(() => {
        window.open('./../perfil-empreendimento?id=' + id, '_parent');
      }, 0);
    }).catch(response => {
      setTimeout(() => {
        window.open('./../perfil-empreendimento?id=' + id, '_parent');
      }, 0);
    })
  }
}

   updatePositions(){
    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
      this.setState({error_auth:true})
    }else{
    document.getElementById('load').classList.add('active')
    const data = new FormData();
    data.append('delivery', this.state.previsao)
    data.append('start', this.state.inicio)
    if(this.state.andamento){
      data.append('progress', this.state.andamento)
    }

    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-update/'+ id, data, {
    headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
    }).then(response => {
      Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/update-progress-photos', this.state.photosOrder, {
        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
          document.getElementById('load').classList.remove('active')
          this.showToast('success', 'Fotos reordenadas com sucesso!')
        }).catch(response => {
          document.getElementById('load').classList.remove('active')
          this.showToast('error', 'Houve um problema com a ordenação, tente novamente ou informe o suporte.')
        })
    })
  }
  }


   updateVideos(event){
    document.getElementById('load').classList.add('active')
    const { selectedDocs } = this.state;
    const totalPhotos = selectedDocs.length;
    this.setState({ progressBars: new Array(totalPhotos).fill(0) });

    if(this.state.selectedDocs !== undefined && this.state.selectedDocs.length > 0){

      selectedDocs.forEach((photo, index) => {
        const dataEnterpriseDocs = new FormData();
        dataEnterpriseDocs.append(`docs[0][file]`, photo);
        dataEnterpriseDocs.append(`docs[0][property_enterprise_id]`, id);

        setTimeout(() => {
          Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/enterprise-progress-video-upload', dataEnterpriseDocs, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
            onUploadProgress: event => {
                const progress = Math.round(100 * event.loaded) / event.total;
                const updatedProgressBars = [...this.state.progressBarsVideo];
                updatedProgressBars[index] = progress;
                this.setState({ progressBarsVideo: updatedProgressBars }, () => {
                  this.forceUpdate();
              });
            }
        }).then(response => {
            if (index === totalPhotos - 1) {
                document.getElementById('load').classList.remove('active')

                Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
                  headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                }).then(response => {
                  this.setState({
                      enterprise: response.data,
                      docsEditList: response.data.progress_videos
                  })
                  this.setState({countVideo : this.state.countVideo + 1})
                  this.setState({selectedDocs: []})
                })
            }
        }).catch(error => {
          const updatedErrors = [...this.state.errorsVideo];
          document.getElementById('load').classList.remove('active')
          updatedErrors[index] = { isError: true, message: 'Erro no envio da imagem' };
          this.setState({ errorsVideo: updatedErrors });
        });
        }, 500);

      });
    }
   }

   deleteImage(photo_id) {
    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
      this.setState({error_auth:true})
    }else{
    Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-progress-photo/delete/' + photo_id, {},
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        this.setState({deleteSuccess: true})

        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/' + id, {
          headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
           this.setState({
               enterprise: response.data,
           })
           this.setState({count : this.state.count + 1})
        })
      })              
      .catch(response => {
        this.setState({deleteError: true})
      })
    }
   }

    render(){
        const {enterprise, dragPhotos } = this.state;

        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={enterprise?.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row justify-content-center">
                                        <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                            <Container>
                                                <Row style={{margin:'10px 0'}}>
                                                    <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre a obra</h5></Col>
                                                </Row>
                                                <Row>
                                                <Col md={3}>
                                                    <Label htmlFor="inicio" className="col-md-12 col-form-label">Início da obra</Label>
                                                    <InputGroup>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="Selecione um dia"
                                                        options={{
                                                        altInput: true,
                                                        altFormat: "j, F, Y",
                                                        dateFormat: "Y-m-d",
                                                        locale: 'pt'
                                                        }}
                                                        ref={this.flatpickrRefStart} 
                                                        onChange={(e) => {
                                                            this.setState({ inicio: moment(e.toString()).format('DD-MM-YYYY')})
                                                        }}
                                                    />
                                                    </InputGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <Label htmlFor="previsao" className="col-md-12 col-form-label">Previsão de entrega</Label>
                                                    <InputGroup>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="Selecione um dia"
                                                        defaultValue={this.state.previsao}
                                                        options={{
                                                        altInput: true,
                                                        altFormat: "j, F, Y",
                                                        dateFormat: "Y-m-d",
                                                        locale: 'pt'
                                                        }}
                                                        ref={this.flatpickrRef} 
                                                        onChange={(e) => {
                                                            this.setState({ previsao: moment(e.toString()).format('DD-MM-YYYY')})
                                                        }}
                                                    />
                                                    </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <AvGroup>
                                                    <Label htmlFor="andamento" className="col-md-12 col-form-label">Andamento da obra (%)</Label>
                                                    <Row>
                                                        <Col xs={9}>
                                                        <input type="range" value={this.state.andamento} className="mt-4 form-range" min="0" max="100" id="customRange2" 
                                                            onChange={(e) => {
                                                            this.setState({andamento: e.target.value})
                                                            }}/>
                                                        </Col>
                                                        <Col xs={3}>
                                                        <AvField
                                                            name="andamento"
                                                            placeholder="em %"
                                                            type="number"
                                                            errorMessage="Campo obrigatório."
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            value={this.state.andamento}
                                                            onChange={(e) => {
                                                            this.setState({andamento: e.target.value})
                                                            }}
                                                        />
                                                        </Col>
                                                    </Row>
                                                    </AvGroup>
                                                </Col>
                                                </Row>
                                            </Container>
                                    </AvForm>
                                    <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                        <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                            <Row style={{margin:'10px 0'}}>
                                                <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Fotos da obra</h5></Col>
                                            </Row>
                                            <Form>
                                              {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') &&
                                                <Dropzone
                                                accept={{
                                                    "image/*": [".png", ".jpeg", ".jpg"],
                                                }}
                                                onDrop={acceptedFiles =>
                                                    this.handleAcceptedFiles(acceptedFiles)
                                                }
                                                >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                    <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <div className="mb-3">
                                                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>Para adicionar fotos arraste ou clique aqui.</h4>
                                                        <small>Tamanho máximo do arquivo de 8 mb.</small>
                                                    </div>
                                                    </div>
                                                )}
                                                </Dropzone>
                                                }
                                                <div
                                                className="dropzone-previews mt-3"
                                                id="file-previews"
                                                >
                                                {this.state.selectedFiles.map((f, i) => 
                                                  <Col key={i + "-file"} id={f.name} >
                                                    <Card
                                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    >
                                                      <div className="p-2">
                                                          <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                              <img
                                                                data-dz-thumbnail=""
                                                                height="120"
                                                                className="avatar-sm rounded bg-light image-send"
                                                                alt={f.name}
                                                                src={f.preview}
                                                              />
                                                            </Col>
                                                            <Col>
                                                              <Link
                                                                to="#"
                                                                className="text-muted fw-bold"
                                                              >
                                                                {f.name}
                                                              </Link>
                                                              <p className="mb-0">
                                                                <strong>{f.formattedSize}</strong>
                                                                {this.state.errors[i] ? (
                                                                    <div style={{ color: 'red' }}>{this.state.errors[i].message}</div>
                                                                ) : (
                                                                    <Progress value={this.state.progressBars[i]}/>
                                                                )}
                                                              </p>
                                                            </Col>
                                                          </Row>
                                                      </div>
                                                    </Card>
                                                    </Col>
                                                )}
                                                </div>
                                                <EnterpriseProgressPhotosOrder
                                                  onFormDataChange={this.handleFormData}
                                                  imoveis={enterprise?.progress_photos}
                                                  count={this.state.count}
                                                  deleteImage={this.deleteImage}
                                                />
                                            </Form>
                                        </Container>
                                        </AvForm>
                                      <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                        <Container style={{paddingBottom:'20px', paddingTop:'20px'}}>
                                              <Row style={{margin:'10px 0'}}>
                                                <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Vídeos (Mp4 até 150mb)</h5></Col>
                                              </Row>
                                              <Row style={{marginBottom: '20px'}}>
                                              {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') &&
                                                <Dropzone
                                                 accept={{
                                                  "video/*": [".mp4"],
                                                }}
                                                  onDrop={acceptedFiles =>
                                                    this.handleAcceptedDocs(acceptedFiles)
                                                  }
                                                >
                                                  {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                      <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                      >
                                                        <input {...getInputProps()} />
                                                        <div className="mb-3">
                                                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                        </div>
                                                        <h4>Para adicionar vídeos arraste ou clique aqui.</h4>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Dropzone>
                                                }
                                                <div
                                                    className="dropzone-previews mt-3"
                                                    id="docs-previews"
                                                  >
                                                  {this.state.selectedDocs.map((f, i) => {
                                                    return (
                                                      <Card
                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                        key={i + "-file"}
                                                      >
                                                        <div className="p-2">
                                                          <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                              {f.fileType === 'pdf' ? (
                                                                <i className="ri-file-search-fill" style={{fontSize:'40px', color:'#333'}}></i>
                                                              ) : (
                                                                <img
                                                                  data-dz-thumbnail=""
                                                                  height="40"
                                                                  className="avatar-sm rounded bg-light"
                                                                  alt={f.name}
                                                                  src={f.preview}
                                                                />
                                                              )}
                                                            </Col>
                                                            <Col>
                                                              <Link
                                                                to="#"
                                                                className="text-muted fw-bold"
                                                              >
                                                                {f.name}
                                                              </Link>
                                                              <p className="mb-0">
                                                                <strong>{f.formattedSize}</strong>
                                                                {this.state.errors[i] ? (
                                                                    <div style={{ color: 'red' }}>{this.state.errorsVideo[i].message}</div>
                                                                ) : (
                                                                    <Progress value={this.state.progressBarsVideo[i]}/>
                                                                )}
                                                              </p>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Card>
                                                    );
                                                  })}
                                                  </div>
                                              </Row>
                                              <Row>
                                                <Col xs={12}>
                                                {this.state.docsEditList?.length > 0 && <Label className="col-md-12 col-form-label">Vídeos</Label>}
                                                  {this.state.docsEditList?.length > 0 &&
                                                    this.state.docsEditList.map((doc, index) => (
                                                      <li style={{
                                                        backgroundColor:'#f4f4f4',
                                                        display:'inline-flex',
                                                        flexDirection:'column',
                                                        alignItems:'center',
                                                        gap:'10px',
                                                        borderRadius:'15px',
                                                        padding:'10px 15px',
                                                        cursor:'pointer',
                                                        margin:'5px'
                                                      }} key={index}
                                                      >
                                                        <video width="200" controls>
                                                          <source src={doc.url} type="video/mp4"></source>
                                                          Seu navegador não suporte vídeos.
                                                        </video>
                                                        {doc.file_name}<span
                                                        onClick={() => {
                                                          if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                                                            this.setState({error_auth:true})
                                                          }else{
                                                            Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprise-progress-video/delete/' + doc.id, {},
                                                              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                              const updatedDocs = this.state.docsEditList.filter(docs => docs.id !== doc.id);
                                                              this.setState({ docsEditList: updatedDocs });
                                                          }
                                                        }}
                                                        style={{alignItems:'center', gap:'10px', display:'flex'}}><p>Excluir</p><i className="ri-close-line" style={{fontSize:'18px'}}></i></span></li>
                                                    ))}
                                                  </Col>
                                              </Row>
                                            </Container>
                                            <Container>
                                              <Row style={{margin:'20px 0', justifyContent:'space-between'}}>
                                                <Col className="col-auto">
                                                    <Button className="btn-dark" onClick={() => {
                                                        setTimeout(() => {
                                                            window.open('./../perfil-empreendimento?id=' + id, '_parent')
                                                        }, 100);
                                                    }}>Voltar</Button>
                                                </Col>
                                                <Col className="col-auto">
                                                  <Button  onClick={() => this.updateBuilding()}>Salvar</Button>
                                                </Col>
                                              </Row>
                                            </Container>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                              {/* Sucesso na atualização */}

              {this.state.deleteSuccess ? (
              <SweetAlert
                title="Imagem excluída"
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => this.setState({deleteSuccess: !this.state.deleteSuccess})}
              ></SweetAlert>
              ) : null}

            {/* Falha na atualização */}

            {this.state.deleteError ? (
              <SweetAlert
                title="A exclusão falhou, tente novamente."
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                error
                onConfirm={() => this.setState({deleteError: !this.state.deleteError})}
              ></SweetAlert>
              ) : null}

            {/* Falha envio de imagem */}

            {this.state.sendImageError ? (
              <SweetAlert
                title="Algumas imagens não puderam ser enviadas."
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                error
                onConfirm={() => {
                  document.getElementById('load').classList.remove('active')
                  this.setState({sendImageError: !this.state.sendImageError})
                }}
              ></SweetAlert>
              ) : null}

              {this.state.error_auth ? (
                  <SweetAlert
                      error
                      title='Ops'
                      onConfirm={() => this.setState({ error_auth: false })}
                  >
                      Você não tem permissão para esta ação.
                  </SweetAlert>
              ) : null}
            </div>
        )
    }

}

export default EnterpriseBuilding;