import { useState } from 'react';
import { useEffect } from 'react';
import './styles.css';

function LastDownloadOwnerTool({user}) {

  const [userHistory, setUserHistory] = useState([])

  useEffect(() => {
    setUserHistory(user.properties)
  },[user])

  return (
      <div className="LastDownloadOwnerTool">
        <div className="container" style={{padding:'0'}}>
          <div className="row tit">
            <div className="col">
              <div className="title">
                <h3>Últimos downloads</h3>
              </div>
            </div>
          </div>
            <div className="content" style={{marginTop:'10px'}}>
            {userHistory?.map((imovel) => (
              imovel?.recent_downloaded?.map((visita, index) => (
                <div className="row item" key={index}>
                  <div className="col">
                    <div className="immobile">
                        <div className="image" style={{backgroundImage:'url(' + imovel?.one_photo?.small_image + ')'}}></div>
                        <p style={{width:'100%'}}>{imovel?.title}</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="cover">
                      {visita?.broker_id ? visita?.broker?.name?.slice(0,1) + visita?.broker?.name?.split(' ').slice(1,2).toString().slice(0,1) : <></>}
                      {visita?.real_estate_id ? visita?.real_estate?.name?.slice(0,1) + visita?.real_estate?.name?.split(' ').slice(1,2).toString().slice(0,1) : <></>}
                    </div>
                  </div>
                </div>
              ))
            ))}
          </div>
        </div>
      </div>
    );
  }
  
export default LastDownloadOwnerTool;