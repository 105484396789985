import Axios from 'axios';
import { useEffect } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

export const FeedContract = ({contract, callback, data}) => {

  const handleAcceptContract = () => {
    const contract = new FormData();
    contract.append('property', data.id)
    if(localStorage.getItem('access') === 'C'){
      contract.append('send_broker_id', localStorage.getItem('id'))
    }
    if(localStorage.getItem('access') === 'I'){
      contract.append('send_real_estate_id', localStorage.getItem('id'))
    }
    if(data.real_estate_id){
      contract.append('receive_real_estate_id', data.real_estate_id)
    }
    if(data.broker_id){
      contract.append('receive_broker_id', data.broker_id)
    }

    Axios.post(process.env.REACT_APP_API + '/api/auth/contracts/check-existence', contract,
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {

      if(response.data.exists){
        callback(true)
      }else{
        Axios.post(process.env.REACT_APP_API + '/api/auth/contracts', contract,
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          callback(true)
        })
      }
    })
  }

  const property = (data.category && data.category.name) + ((data.high && data.high !== 'null') ? ', ' + data.high : '') + ((data.city && data.city !== 'null') ? ', ' + data.city : '') + ((data.district && data.district !== 'null') ? ', ' + data.district : '') + ((data.enterprise && data.enterprise !== 'null') ? ', ' + data.enterprise : '')

  return(
    <Modal
        isOpen={contract}
        toggle={() => callback(false)}
        size="xl"
        centered={true}
        scrollable={true}
    >
        <ModalHeader toggle={() => callback(false)} style={{padding:'0 80px'}}>
          <h3 style={{fontSize:'18px'}}>Termo de Parceria para Corretores Imobiliários</h3>
        </ModalHeader>
        <ModalBody>
           <Row>
            <p>Considerando que o Parceiro 1 é corretor representante de possível adquirente de um imóvel com características <b>{property}</b>, e que o Parceiro 2 é corretor representante de possível proprietário vendedor de um possível imóvel com as características mencionadas, as Partes manifestam o interesse mútuo em realizar a presente parceria para intermediação de compra e venda do imóvel.</p>
            <p>Pelo presente instrumento particular, os Corretores Parceiros abaixo designados, doravante denominados "Corretor Parceiro 1" e "Corretor Parceiro 2", respectivamente, têm entre si justo e contratado o seguinte termo de parceria:</p>
            <p>1.⁠ ⁠Objeto</p>
            <p>1.1 O presente termo tem por objeto estabelecer as condições para a futura parceria entre os Corretores Parceiros 1 e 2, visando à intermediação imobiliária de imóveis captados pelo Corretor Parceiro 1 e clientes interessados representados pelo Corretor Parceiro 2.</p>
            <p>2.⁠ ⁠Confidencialidade</p>
            <p>2.1 Os Corretores Parceiros 1 e 2 comprometem-se a manter sigilo sobre todas as informações trocadas entre si, seja por meio de comunicação verbal, escrita ou eletrônica, durante o curso da parceria.</p>
            <p>2.2 As informações fornecidas pelo Corretor Parceiro 1, relativas aos imóveis captados, e pelo Corretor Parceiro 2, referentes aos clientes interessados, serão tratadas como estritamente confidenciais e utilizadas exclusivamente para os fins desta parceria.</p>
            <p>3.⁠ ⁠Ética e Respeito à Parceria</p>
            <p>3.1 Os Corretores Parceiros 1 e 2 comprometem-se a agir com ética e respeito mútuo durante todo o processo de intermediação imobiliária.</p>
            <p>3.2 O Corretor Parceiro 2 concorda em não abordar diretamente o proprietário do imóvel captado pelo Corretor Parceiro 1, sem a devida autorização ou consentimento deste.</p>
            <p>4.⁠ ⁠Disposições Gerais</p>
            <p>4.1 O presente termo é celebrado digitalmente, sendo o aceite realizado por meio dos sistemas da plataforma.</p>
            <p>4.2 Este termo é regido pelas leis vigentes do Brasil e qualquer controvérsia decorrente deste será resolvida amigavelmente pelas partes, sendo necessário, recorrer-se-á ao foro da comarca de [cidade], excluindo-se qualquer outro, por mais privilegiado que seja. Ao aceitar digitalmente este termo, os Corretores Parceiros 1 e 2 declaram estar cientes e de acordo com todas as cláusulas aqui dispostas.</p>
            <p></p>
           </Row>
            <Row style={{marginTop:'20px', justifyContent:'flex-end'}}>
              <Col className="col-auto"><Button color="dark" onClick={() => callback(false)}>Cancelar</Button></Col>
              <Col className="col-auto"><Button onClick={handleAcceptContract}>Aceitar</Button></Col>
            </Row>
        </ModalBody>
    </Modal>
  )
}