import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Container, Spinner, Button, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'
import { saveAs } from 'file-saver';

import Breadcrumbs from "../../components/Common/Breadcrumb"
import './../../assets/scss/custom/components/_editProperties.scss'
import './../../assets/scss/custom/components/_addProposal.scss'
import SweetAlert from 'react-bootstrap-sweetalert';

class singleProposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposal:[],
      newStatus:null,
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Propostas", link: "/propostas" },
        { title: "Proposta", link: "#" },
    ],
    }
  }

  componentDidMount(){


    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {


        Axios.get(process.env.REACT_APP_API + '/api/auth/property/' + parseInt(localStorage.getItem('property_id_proposal')),
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {


            // const data = {
            //   access: localStorage.getItem('access'),
            //   user_id: localStorage.getItem('id'),
            //   property_id: localStorage.getItem('property_id_proposal')
            // }
            // Axios.post(process.env.REACT_APP_API + '/api/auth/validate-auth', data,
            // {headers:{"Authorization": "Bearer " + localStorage.getItem('token')} })
            // .then(response => { console.log(response)})
            const proposalId = window.location.search.replace('?', '');

            Axios.post(process.env.REACT_APP_API + '/api/auth/proposal-validate-user', {
              id: proposalId,
              user_id: localStorage.getItem('id'),
              type: localStorage.getItem('access')
            },
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              Axios.get(process.env.REACT_APP_API + '/api/auth/proposals/' + proposalId,
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({proposal: response.data})
                this.setState({newStatus: response.data.status})
  
                this.setState({breadcrumbItems: [
                  { title: "Fast Sale", link: "/dashboard" },
                  { title: "Propostas", link: "/propostas" },
                  { title: "ID " + response.data.id, link: "#" },
              ]})
              })
            })
            .catch(response =>{
              setTimeout(() => {
                window.open("/propostas", '_parent')
              }, 300);
            })

          
        })})
        .catch(response =>{
          localStorage.removeItem("history_url")
          setTimeout(() => {
            window.open("/login", '_parent')
          }, 300);
        })
  }


  render() {
    const {proposal} = this.state;
    return (
        <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={"Proposta Id " + proposal.id} breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                    <div className="cardBody">
                      <Container style={{padding: '10px'}}>
                        <Row style={{justifyContent:'flex-end', marginBottom:'15px'}}>
                          <Col className="col-auto" >
                            <Button onClick={() => {
                                const axiosOptions = {
                                    responseType: 'arraybuffer',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      "Authorization": "Bearer " + localStorage.getItem('token')
                                    }
                                  }
                                Axios.get(process.env.REACT_APP_API + '/api/auth/proposal-download/' + proposal.id,
                                axiosOptions)
                                .then(response => {
                                    const blob = new Blob([response.data], {
                                        type: 'application/octet-stream'
                                    })
                                    const filename = 'proposta.pdf'
                                    saveAs(blob, filename)
                                })
                            }}>Baixar proposta</Button>
                          </Col>
                        </Row>
                        <Row className="containerProposalAccept" style={{justifyContent:'flex-end'}}>
                          <Col className="proposalAcceptStatus col-auto">
                            <b>Situação:</b> {proposal.status === 0 ? <Badge className="bg-warning me-1 rounded-pill" style={{fontSize:'16px'}}>Pendente</Badge>
                              : proposal.status === 1 ? <Badge className="bg-info me-1 rounded-pill" style={{fontSize:'16px'}}>Formalizada</Badge>
                              : proposal.status === 2 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px'}}>Cancelada</Badge>
                              : proposal.status === 3 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px'}}>Negada</Badge>
                              : <Badge className="bg-success me-1 rounded-pill" style={{fontSize:'16px'}}>Aceita</Badge>}
                          </Col>
                          {(localStorage.getItem('access') === 'A' && localStorage.getItem('level') !== '2') &&
                            <Col className="col-auto">
                              <Row>
                                <Col>
                                  <select className="form-control" value={this.state.newStatus} onChange={(e) => this.setState({newStatus: e.target.value})}>
                                    <option value={0}>Pendente</option>
                                    <option value={1}>Formalizada</option>
                                    <option value={2}>Cancelada</option>
                                    <option value={3}>Negada</option>
                                    <option value={4}>Aceita</option>
                                  </select>
                                </Col>
                                <Col className="col-auto">
                                  <Button onClick={() => {
                                      document.getElementById('load').classList.add('active')
                                      Axios.post(process.env.REACT_APP_API + '/api/auth/proposal/' + proposal?.id, {status: this.state.newStatus},
                                      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                      .then(response => window.location.reload())
                                  }}>OK</Button>
                                </Col>
                              </Row>
                            </Col>
                          }


                          {(!(proposal?.properties?.broker_id !== parseInt(localStorage.getItem('id')) && proposal?.properties?.real_estate_id !== parseInt(localStorage.getItem('id'))) && proposal?.status === 0) &&
                            <>
                              <Col className="proposalAcceptSuccess">
                                <Button className="btn-success" onClick={() => {
                                  document.getElementById('load').classList.add('active')
                                  Axios.post(process.env.REACT_APP_API + '/api/auth/proposal/' + proposal?.id, {status: 4},
                                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                  .then(response => {
                                    this.setState({ proposal_success: true })
                                    document.getElementById('load').classList.remove('active')
                                    setTimeout(() => {
                                      window.location.reload()
                                    }, 1500);
                                  })
                                }}>Aceitar proposta</Button>
                              </Col>
                              <Col className="proposalAcceptDecline">
                                <Button className="btn-danger" onClick={() => {
                                  document.getElementById('load').classList.add('active')
                                  Axios.post(process.env.REACT_APP_API + '/api/auth/proposal/' + proposal?.id, {status: 3},
                                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                  .then(response => {
                                    this.setState({ proposal_error: true })
                                    document.getElementById('load').classList.remove('active')
                                    setTimeout(() => {
                                      window.location.reload()
                                    }, 1500);
                                  })
                                }}>Recusar proposta</Button>
                              </Col>
                            </>
                          }
                        </Row>
                      </Container>
                      <Container>
                        <Row style={{alignItems:'center', justifyContent:'flex-start', padding:'20px 0'}}>
                          <Col className="col-auto">
                            <img src={proposal?.properties?.one_photo?.medium_image} style={{
                              width:'100px',
                              objectFit: 'cover',
                              height:'100px',
                              border:'solid 1px #cdcdcd',
                              borderRadius:'5px'
                            }}></img>
                          </Col>
                          <Col className="col-auto">
                            <h2 style={{fontSize:'20px'}}>Proposta Nº {proposal?.id} imóvel:</h2>
                            <p><b>{proposal?.properties?.title} ({proposal?.properties?.sku})</b></p>
                          </Col>
                        </Row>
                      </Container>
                      <Container style={{paddingTop:'20px', paddingBottom:'10px', position:'relative'}}>
                          <Row style={{marginTop:'10px'}}>
                            <Col xs={12}>
                              <h3>Proposta para compra de imóvel</h3>
                              <br></br>
                              <h5>Proponente comprador</h5>
                              <p>{proposal?.buyers?.name}, {proposal?.buyers?.nationality}, {proposal?.buyers?.marital_status}, RG {proposal?.buyers?.rg}, CPF {proposal?.buyers?.cpf_cnpj}, residente e domiciliado na {proposal?.buyers?.street}, nº {proposal?.buyers?.number}, {proposal?.buyers?.complement}, bairro {proposal?.buyers?.district}, em {proposal?.buyers?.city}/{proposal?.buyers?.state}, {proposal?.buyers?.country}, telefone {proposal?.buyers?.phone} e e-mail {proposal?.buyers?.email}.</p>
                              <br></br>
                              <h5>Imóvel</h5>
                              <p>Imóvel matrícula {proposal?.properties?.registry}, {proposal?.properties?.real_estate_registration}, {proposal?.properties?.categories.name} no {proposal?.properties?.title}, {proposal?.properties?.endorsed_measures && <>área privativa de {proposal?.properties?.endorsed_measures} m²,</>} {proposal?.properties?.build_measures && <>área construída de {proposal?.properties?.build_measures} m²,</>} {proposal?.properties?.terrain_measures && <>área total de {proposal?.properties?.terrain_measures} m²,</>} com {proposal?.properties?.dorms_number} quartos, {proposal?.properties?.bathroom_number} banheiros e {proposal?.properties?.parking_spots} vagas de garagem. Localizado na {proposal?.properties?.address_properties?.street}, nº {proposal?.properties?.address_properties?.number}, {proposal?.properties?.address_properties?.complement}, bairro {proposal?.properties?.address_properties?.district}, em {proposal?.properties?.address_properties?.city}/{proposal?.properties?.address_properties?.state}, {proposal?.properties?.address_properties?.country}.</p>
                              <br></br>
                              {(proposal?.properties?.sell_contract_type === 'FS Parc' || proposal?.properties?.sell_contract_type === 'FS Parc (sc)') &&
                                proposal?.properties?.broker_id &&
                                  <>
                                    <h5>Corretor captador</h5>
                                    <p>{proposal?.properties?.broker?.name}, brasileiro(a), corretor(a) de imóveis, CRECI {proposal?.properties?.broker?.creci}, CPF {proposal?.properties?.broker?.cpf}, residente e domiciliado na {proposal?.properties?.broker?.street}, nº {proposal?.properties?.broker?.number}, {proposal?.properties?.broker?.complement}, bairro {proposal?.properties?.broker?.district}, em {proposal?.properties?.broker?.city}/{proposal?.properties?.broker?.state}, {proposal?.properties?.broker?.country}, telefone {proposal?.properties?.broker?.phone} e e-mail {proposal?.properties?.broker?.email}. </p>
                                  </>
                              }

                              {(proposal?.properties?.sell_contract_type === 'FS Parc' || proposal?.properties?.sell_contract_type === 'FS Parc (sc)') &&
                                proposal?.properties?.real_estate_id &&
                                  <>
                                    <h5>Imobiliária captadora</h5>
                                    <p>{proposal?.properties?.real_estate?.name}, imobiliária, CRECIJ {proposal?.properties?.real_estate?.creci_j}, CNPJ {proposal?.properties?.real_estate?.cnpj}, localizada na {proposal?.properties?.real_estate?.street}, nº {proposal?.properties?.real_estate?.number}, {proposal?.properties?.real_estate?.complement}, bairro {proposal?.properties?.real_estate?.district}, em {proposal?.properties?.real_estate?.city}/{proposal?.properties?.real_estate?.state}, {proposal?.properties?.real_estate?.country}, telefone {proposal?.properties?.real_estate?.phone} e e-mail {proposal?.properties?.real_estate?.email}. </p>
                                  </>
                              }
                              <br></br>
                              <h5>Corretor de imóveis/imobiliária - Intermediador do negócio</h5>
                              <p>{proposal?.brokers?.name}, brasileiro(a), corretor(a) de imóveis, CRECI {proposal?.brokers?.creci}, CPF {proposal?.brokers?.cpf}, residente e domiciliado na {proposal?.brokers?.street}, nº {proposal?.brokers?.number}, {proposal?.brokers?.complement}, bairro {proposal?.brokers?.district}, em {proposal?.brokers?.city}/{proposal?.brokers?.state}, {proposal?.brokers?.country} com o telefone {proposal?.brokers?.phone} e e-mail {proposal?.brokers?.email}. </p>
                              <br></br>
                              <h5>Plataforma Fast Sale</h5>
                              <p><b>FAST SALE TECNOLOGIA LTDA</b>, pessoa jurídica de direito privado, inscrita no CNPJ nº 39.985.590/0001-94, com sede na Rua 1500, nº 820, Sala nº 2301, Ed. Sky Business Center, Centro, Balneário Camboriú/SC, CEP: 88.330.526, representada por <b>Renato Valerio Monteiro Alves</b>, brasileiro, solteiro, empresário, portador do RG nº 6.154.939-SSP/SC, inscrito no CPF sob nº 077.382.069-85, com mesmo endereço profissional, E-mail: contato@fastsaleimoveis.com.br - Telefones: (47) 2033-1116 / 99941-1891.</p>
                              <br></br>
                              <h5>Proposta</h5>
                              <p>O <b>PROPONENTE COMPRADOR</b> apesenta a seguinte <b>PROPOSTA</b>:</p>
                              <p><b>VALOR TOTAL DA PROPOSTA: R$ {(proposal?.total_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</b></p>
                              {proposal?.in_cash === 1 && <p>A ser pago à vista</p>}
                              {proposal?.parcel === 1 && <>
                              <p>A ser pago parcelado
                              {proposal?.entry && <> com uma entrada de R$ {(proposal?.value_parcel / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</>} e {proposal?.qtd_parcels} parcelas no valor de R$ {(proposal?.value_parcel / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}
                              {proposal?.qtd_reinforcements && <> e {proposal?.qtd_reinforcements} reforços no valor de R$ {(proposal?.value_reinforcements / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</>}
                              {proposal?.bank_financing && <> e R$ {(proposal?.bank_financing / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})} como forma de financiamento bancário.</>}</p>
                              </>}
                              <br></br>
                              {proposal?.exchange === 1 && <>
                                <h5>Permutas:</h5>
                                {proposal?.exproperty?.map((property, index) => (
                                  <p key={index}><b>- 1 imóvel</b>, no valor de R$ {(property?.value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}, localizado na {property?.street}, nº {property?.number}, {property?.complement}, bairro {property?.district}, em {property?.city}/{property?.state}, {property?.country}.</p>
                                ))}
                                {proposal?.excar?.map((car, index) => (
                                  <p key={index}><b>- 1 automóvel</b>, {car?.model}, ano {car?.year}, com {car?.km} de kilometragem, no valor de R$ {(car?.value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}.</p>
                                ))}        
                              </>}
                              <br></br>
                              <p>A presente proposta de compra vigorará pelo prazo de <b>{proposal?.days} dias</b>, a contar de sua assinatura.</p>
                              <br></br>
                              <p>Esta proposta submete-se ao disposto nos arts. 722 à 729 da Lei nº 10.406/02 (Código Civil-Capítulo XIII do Contrato de Corretagem), à Lei Federal nº 6.530/78, ao Decreto Federal nº 81.871/78, Resoluções nºs 326/92 e 458/95 do COFECI - Conselho Federal de Corretores de Imóveis: Contrato de Corretagem.</p>
                            
                              {(proposal?.properties?.sell_contract_type === 'FS Prop'
                              || proposal?.properties?.sell_contract_type === 'FS Corr'
                              || proposal?.properties?.sell_contract_type === 'FS Imob') &&
                                <p><b>Pagamentos: </b>As Partes, Comprador e Vendedor, declaram sob as penas da lei, estarem cientes e concordes, ao pagamento de 4% (quatro por cento) à título de remuneração da intermediação e corretagem do CORRETOR DE IMÓVEIS/IMOBILIÁRIA (4), 1% (um por cento) a titulo de remuneração do captador, e 1% (um por cento) à título de remuneração dos serviços da plataforma FAST SALE (5), na assinatura do aceite da presente proposta. Inclusive, estão cientes que deverão pagar as referidas remunerações nas seguintes circunstâncias:</p>
                              }
                              {proposal?.properties?.sell_contract_type === 'FS Imob (antigo)' &&
                                <p><b>Pagamentos: </b>As Partes, Comprador e Vendedor, declaram sob as penas da lei, estarem cientes e concordes, ao pagamento de 3% (três por cento) à título de remuneração da intermediação e corretagem do CORRETOR DE IMÓVEIS/IMOBILIÁRIA (4), 1% (um por cento) a título de remuneração do captador, 1% (um por cento) à título de remuneração da imobiliária do corretor captador e 1% (um por cento) à título de remuneração dos serviços da plataforma FAST SALE (5), na assinatura do aceite da presente proposta. Inclusive, estão cientes que deverão pagar as referidas remunerações nas seguintes circunstâncias:</p>
                              }
                              {proposal?.properties?.sell_contract_type === 'FS Parc' &&
                                <p><b>Pagamentos: </b>As Partes, Comprador e Vendedor, declaram sob as penas da lei, estarem cientes e concordes, ao pagamento de 6% (seis por cento) à título de remuneração da intermediação e corretagem dos CORRETORES (4 e 5), sendo 3% (três por cento) a título de remuneração do captador e 3% (três por cento) a título de remuneração do vendedor, na assinatura do aceite da presente proposta. Inclusive, estão cientes que deverão pagar as referidas remunerações nas seguintes circunstâncias:</p>
                              }
                              {proposal?.properties?.sell_contract_type === 'FS Parc (sc)' &&
                                <p><b>Pagamentos: </b>As Partes, Comprador e Vendedor, declaram sob as penas da lei, estarem cientes e concordes, ao pagamento de {proposal?.properties?.commission_percentage_on_sale}% à título de remuneração da intermediação e corretagem dos CORRETORES (4 e 5), sendo metade a título de remuneração do captador e metade a título de remuneração do vendedor, na assinatura do aceite da presente proposta. Inclusive, estão cientes que deverão pagar as referidas remunerações nas seguintes circunstâncias:</p>
                              }
                              <li>a) Por arrependimento das partes (art. 725 do Código Civil). Caso haja arrependimento da venda e/ou desistência de efetivar a transação;</li>
                              <li>b) A proposta seja recusada pelo Proprietário do Imóvel, e posteriormente, inicie ou efetive negociação do imóvel sem a participação do corretor de imóveis/imobiliária, inclusive por meio de familiares ou representantes, em valores e condições iguais ou diferentes;</li>
                              <li>c) Caso a negociação se concretize após o prazo da presente contratação, por efeitos dos trabalhos do corretor de imóveis/imobiliária e da plataforma, independentemente de prazo, lhes serão devidas integralmente as remunerações, pela corretagem, nos termos do art. 727 do Código Civil, e pelos serviços da plataforma;</li>
                              <li>d) Venda direta ou por intermédio de outrem;</li>
                              <li>e) Negativa em honrar a venda em condições ajustadas previamente;</li>
                              <li>f) Caso a venda venha a se concretizar sem a interveniência do corretor de imóveis/imobiliária, mas para comprador indicado anteriormente pelo mesmo.</li>

                              <Container style={{margin:'30px 0 0'}}>
                                <Row style={{margin:'10px -10px'}}>
                                  <Col><h5>Anexos:</h5></Col>
                                </Row>
                              </Container>
                              <Card style={{marginTop:'10px'}}>
                                <Container>
                                  <Row style={{marginTop:'10px'}}>
                                    <Col xs={12}>
                                      <p><b>Documento do comprador:</b></p>
                                    </Col>
                                    <Col xl={2} lg={3} md={4} xs={6}>
                                      <a href={proposal?.buyers?.doc} target="_blank" rel="noreferrer"><img style={{
                                        maxWidth:'160px'
                                      }} src={proposal?.buyers?.doc}></img></a>
                                    </Col>
                                  </Row>
                                </Container>
                              </Card>
                              {proposal?.exproperty?.length > 0 &&
                                  proposal?.exproperty?.map((property, index) => (
                                    <Card key={index} style={{marginTop:'10px'}}>
                                      <Container>
                                        <Row style={{marginTop:'10px'}}>
                                          <Col xs={12}>
                                            <p><b>{index + 1}º imóvel de permuta:</b></p>
                                          </Col>
                                          {property?.exproperty_photos?.map((photo, i) => (
                                          <Col xl={2} lg={3} md={4} xs={12}>
                                            <a href={photo.url} target="_blank" rel="noreferrer"><img style={{
                                              width:'180px',
                                              objectFit: 'cover',
                                              height:'180px',
                                              border:'solid 1px #cdcdcd',
                                              borderRadius:'5px'
                                            }} src={photo.url}></img></a>
                                          </Col>
                                          ))}
                                        </Row>
                                      </Container>
                                    </Card>
                                  ))
                              }
                              {proposal?.excar?.length > 0 &&
                                  proposal?.excar?.map((property, index) => (
                                    <Card key={index} style={{marginTop:'10px'}}>
                                      <Container>
                                        <Row style={{marginTop:'10px'}}>
                                          <Col xs={12}>
                                            <p><b>{index + 1}º automóvel de permuta:</b></p>
                                          </Col>
                                          <Col xl={2} lg={3} md={4} xs={12}>
                                            <a href={property.doc} target="_blank" rel="noreferrer"><img style={{
                                              width:'180px',
                                              objectFit: 'cover',
                                              height:'180px',
                                              border:'solid 1px #cdcdcd',
                                              borderRadius:'5px'
                                            }} src={property.doc}></img></a>
                                          </Col>
                                          {property?.excar_photos?.map((photo, i) => (
                                          <Col xl={2} lg={3} md={4} xs={12}>
                                            <a href={photo.url} target="_blank" rel="noreferrer"><img style={{
                                              width:'180px',
                                              objectFit: 'cover',
                                              height:'180px',
                                              border:'solid 1px #cdcdcd',
                                              borderRadius:'5px'
                                            }} src={photo.url}></img></a>
                                          </Col>
                                          ))}
                                        </Row>
                                      </Container>
                                    </Card>
                                  ))
                              }
                            </Col>
                          </Row>
                      </Container>
                      <Container style={{padding: '10px'}}>
                        <Row className="containerProposalAccept">
                          <Col className="proposalAcceptStatus">
                            <b>Situação:</b> {proposal.status === 0 ? <Badge className="bg-warning me-1 rounded-pill" style={{fontSize:'16px'}}>Pendente</Badge>
                              : proposal.status === 1 ? <Badge className="bg-info me-1 rounded-pill" style={{fontSize:'16px'}}>Formalizada</Badge>
                              : proposal.status === 2 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px'}}>Cancelada</Badge>
                              : proposal.status === 3 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px'}}>Negada</Badge>
                              : <Badge className="bg-success me-1 rounded-pill" style={{fontSize:'16px'}}>Aceita</Badge>}
                          </Col>
                          {(!(proposal?.properties?.broker_id !== parseInt(localStorage.getItem('id')) && proposal?.properties?.real_estate_id !== parseInt(localStorage.getItem('id'))) && proposal?.status === 0) &&
                            <>
                              <Col className="proposalAcceptSuccess">
                                <Button className="btn-success" onClick={() => {
                                  document.getElementById('load').classList.add('active')
                                  Axios.post(process.env.REACT_APP_API + '/api/auth/proposal/' + proposal?.id, {status: 4},
                                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                  .then(response => {
                                    this.setState({ proposal_success: true })
                                    document.getElementById('load').classList.remove('active')
                                    setTimeout(() => {
                                      window.location.reload()
                                    }, 1500);
                                  })
                                }}>Aceitar proposta</Button>
                              </Col>
                              <Col className="proposalAcceptDecline">
                                <Button className="btn-danger" onClick={() => {
                                  document.getElementById('load').classList.add('active')
                                  Axios.post(process.env.REACT_APP_API + '/api/auth/proposal/' + proposal?.id, {status: 3},
                                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                  .then(response => {
                                    this.setState({ proposal_error: true })
                                    document.getElementById('load').classList.remove('active')
                                    setTimeout(() => {
                                      window.location.reload()
                                    }, 1500);
                                  })
                                }}>Recusar proposta</Button>
                              </Col>
                            </>
                          }
                        </Row>
                      </Container>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

         {/* Sucesso na criação da proposta */}

         {this.state.proposal_success ? (
            <SweetAlert
              title="Proposta aceita, você receberá um e-mail para formalizá-la."
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => this.setState({ proposal_success: false })}
            ></SweetAlert>
            ) : null}

          {/* Falha na criação da proposta */}

          {this.state.proposal_error ? (
            <SweetAlert
              title="Proposta negada, o comprador poderá fazer uma nova proposta."
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ proposal_error: false })}
            ></SweetAlert>
            ) : null}

        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default singleProposal;