import './styles.scss';
import BlackFriday from './../../../../../assets/images/banner_black_friday.png';
import BlackFridayLogo from './../../../../../assets/images/logo_black_friday.png';
import BlackFridayDetail from './../../../../../assets/images/detail_black_friday.png';

export const BannerBlackFriday = () => {
    return(
        <>
        <div className="banner-checkout-black-friday" style={{backgroundImage:`url('${BlackFriday}')`}}>
            <div className="home-black-friday-col">
                <img src={BlackFridayLogo}/>
                <img className="black-friday-detail" src={BlackFridayDetail}/>
            </div>
            <div className="home-black-friday-col">
                <b>30% OFF</b>
                <div className="coupon">Cupom <span>BLACK30</span></div>
                <p>válido para o plano anual até 29/11/2024.</p>
            </div>
        </div>
        </>
    )
}