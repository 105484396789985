import React, { useEffect, useState } from 'react';
import './styles.scss';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { Input, Label } from 'reactstrap';
import { NumberCardInput } from '../form/numberCardInput';
import { NameInput } from '../form/nameInput';
import { DateInput } from '../form/dateInput';
import { CvcInput } from '../form/cvcInput';
import { CpfInput } from '../form/cpfInput';
import { ParcelsInput } from '../form/parcelsInput';
import Axios from 'axios';

export const CreditCardForm = ({onSend, product, recurrence, coupon}) => {
  const [creditCard, setCreditCard] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });
  const [cpf, setCpf] = useState('')
  const [parcelsNumber, setParcelsNumber] = useState(1)
  const [discount, setDiscount] = useState(null)

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    
    setCreditCard((prev) => ({ ...prev, [name]: value }));
  }

  const handleInputFocus = (evt) => {
    setCreditCard((prev) => ({ ...prev, focus: evt.target.name }));
  }

  const handleSend = (e) => {
    e.preventDefault()
    onSend(creditCard, cpf, parcelsNumber)
  }

  const handleCpfChange = (e) => {
    setCpf(e.target.value)
  }

  const handleParcelsChange = (e) => {
    setParcelsNumber(e.target.value)
  }

  useEffect(() => {
    if(coupon){
      Axios.get(process.env.REACT_APP_API + '/api/coupon/discount-id/' + coupon)
      .then(resp => {
        setDiscount(parseInt(resp.data.discount))
      })
    }
  }, [coupon])

  return(
    <>
      <div className="credit-card-payment-title">
      <h3>Informações do cartão</h3>
    </div>
    <div className="credit-card-payment">
      <form onSubmit={(e) => handleSend(e)}>

        <NumberCardInput
          handleInputChange={handleInputChange}
          handleInputFocus={handleInputFocus}
        />
        <NameInput
          handleInputChange={handleInputChange}
          handleInputFocus={handleInputFocus}
        />

        <div className="credit-card-payment-row">
          <DateInput
            handleInputChange={handleInputChange}
            handleInputFocus={handleInputFocus}
          />
          <CvcInput
            handleInputChange={handleInputChange}
            handleInputFocus={handleInputFocus}
          />
        </div>
        <CpfInput
          handleInputChange={handleCpfChange}
        />
        <ParcelsInput
          handleInputChange={handleParcelsChange}
          product={product}
          recurrence={recurrence}
          discount={discount}
        />

        <button disabled={cpf !== '' && creditCard.number !== '' && creditCard.expiry !== ''
        && creditCard.cvc !== '' && creditCard.name !== '' ? false : true}>Finalizar pagamento</button>
      </form>
      <Cards
        number={creditCard.number}
        expiry={creditCard.expiry}
        cvc={creditCard.cvc}
        name={creditCard.name}
        focused={creditCard.focus}
      />
    </div>
    </>
  )
}