import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import styles from '../styles/ImobileMobile.module.css';
import Icon from '@mdi/react';
import { mdiShare, mdiBedKingOutline, mdiCar, mdiRulerSquare, mdiMapMarker } from '@mdi/js';
import Nav from './Nav'
import { useState, useEffect } from "react";
import { ChromePicker } from 'react-color';
import ContentEditable from "react-contenteditable";

export default function ImobileMobile({data}) {
  const userImage = localStorage.getItem('user_image');
  const userName = localStorage.getItem('user_name');
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [showPickerButton, setShowPickerButton] = useState(false);
  const [showPickerTextButton, setShowPickerTextButton] = useState(false);
  const [ highTitle, setHighTitle] = useState('Imóveis semelhantes a este')
  const [ highTitleEnabled, setHighTitleEnabled] = useState(true)

  useEffect(() => {
    if(localStorage.getItem('imobile_second_button_text_color')){
      setColorTextButton(JSON.parse(localStorage.getItem('imobile_second_button_text_color')))
    }
    if(localStorage.getItem('imobile_second_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('imobile_second_button_color')))
    }
    if(localStorage.getItem('high_imobile_title')){
      setHighTitle(localStorage.getItem('high_imobile_title'))
    }

  }, []);

  return (
    <>
      <Nav />
      <section id={styles.imobileMobile}>
        <Swiper navigation={true} modules={[Navigation]} loop={true} className="mySwiper">
          <SwiperSlide>
            <div className={styles.img}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.img}></div>
          </SwiperSlide>
          <SwiperSlide>
          <div className={styles.img}></div>
          </SwiperSlide>
        </Swiper>

        <div className={styles.scale_immobile}>
          <p className={styles.title}>Imóvel Exemplo</p>
          <div className={styles.container_imobile_config}>
            <div className={styles.config}>
              <div className={styles.container_config}>
                <Icon path={mdiBedKingOutline} size={.8} />
                <p>3</p>
              </div>
              <div className={styles.container_config}>
                <Icon path={mdiCar} size={.8} />
                <p>2</p>
              </div>
              <div className={styles.container_config}>
                <Icon path={mdiRulerSquare} size={.8} />
                <p>123.3m</p>
              </div>
            </div>
          </div>
          
          <div className={styles.container_location_name}>
            <Icon path={mdiMapMarker} size={.9}/>
            <p className={styles.location_name}> Localização</p>
          </div>

          <div className={styles.container_new_price}>
          <div className={styles.price}>
              <p>R$<b>4.000.000,00</b></p>
            </div>
            <div className={styles.hire}>
                <p>Condomínio: R$1.100,00</p>
                <p>IPTU: R$2.000,00</p>
            </div>
            <div className={styles.container_button}>
              <div className={styles.share}
              style={{
                backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
              }}>
                <p>Compartilhe</p>
                <Icon path={mdiShare} size={1.2}/>
              </div>

              <div
                style={{
                  backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                  color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                }}
              >Quero mais informações</div>
            </div>

              <div className={styles.actionIcons}>
                <label className="title">
                  <i className="ri-brush-fill" onClick={() => {
                    setShowPickerButton(!showPickerButton)
                    setShowPickerTextButton(false)
                    }}></i>
                  <i className="ri-font-color" onClick={() => {
                    setShowPickerTextButton(!showPickerTextButton)
                    setShowPickerButton(false)
                    }}></i>
                  {showPickerButton && (
                    <div className={styles.colorPickerButton}>
                      <i className="ri-close-fill closeIcon" onClick={() => setShowPickerButton(!showPickerButton)}></i>
                      <ChromePicker
                        color={colorButton ?? { r: 0, g: 0, b: 0, a: 1 }}
                        onChange={(e) => {
                          setColorButton(e.rgb)
                          localStorage.setItem('imobile_second_button_color', JSON.stringify(e.rgb))
                        }}
                      />
                      </div>
                    )}
                    {showPickerTextButton && (
                    <div className={styles.colorPickerButton}>
                      <i className="ri-close-fill closeIcon" onClick={() => setShowPickerTextButton(!showPickerTextButton)}></i>
                      <ChromePicker
                        color={colorTextButton ?? { r: 255, g: 255, b: 255, a: 1 }}
                        onChange={(e) => {
                          setColorTextButton(e.rgb)
                          localStorage.setItem('imobile_second_button_text_color', JSON.stringify(e.rgb))
                        }}
                      />
                      </div>
                    )}
                </label>
              </div>
          </div>

          <div className={styles.container_people}>
            <div>
              <img src={userImage} alt="" className={styles.people_img} />
            </div>
            <div>
              <p>{userName}</p>
              <p>CRECI: creci</p>
            </div>
          </div>

          <div className={styles.container_about}>
            <p className={styles.about}>Sobre o Imóvel</p>

            <div className={styles.config_about}>
              <p>- 3 Suítes (Sendo uma Master com Hidro)</p>
              <p>- 3 Banheiros</p>
              <p>- Lavabo</p>
              <p>- Churrasqueira</p>
              <p>- Área de Serviço</p>
              <p>- Living</p>
              <p>- Sala de Estar</p>
              <p>- Sala de Jantar</p>
              <p>- Cozinha</p>
              <p>- Sacada Integrada</p>

              <p className={styles.characteristics}>Características do Empreedimento</p>

              <p>- Acessibilidade para Cadeirantes</p>
              <p>- Elevadores com Gerador de Energia</p>
              <p>- Sistema de Segurança Integrado com Guarita</p>
              <p>- Sala de Banhistas</p>
              <p>- Box de Praia Individual</p>
            </div>
          </div> 
        </div> 

        <div>
          {/* <p className={styles.title_similar}>Imóveis semelhantes a este:</p> */}
          <div className={styles.titleContainer}>
            <ContentEditable
              html={highTitle}
              className={styles.titleEdit}
              id='highTitleEdit'
              disabled={highTitleEnabled}
              onChange={(e) => {
                setHighTitle(e.target.value)
                localStorage.setItem('high_imobile_title', e.target.value)
              }}
              onClick={(e) => {
                if(highTitle === 'Imóveis semelhantes a este' && !highTitleEnabled){
                  setHighTitle('')
                  localStorage.setItem('high_imobile_title', '')
                }
              }}
            />
            <div className={styles.actionIcons2}>
              <label className="title">
                <span onClick={() => {
                  if(!highTitleEnabled){
                    setHighTitleEnabled(true)
                    if(highTitle === ''){
                      setHighTitle('Imóveis semelhantes a este')
                      localStorage.setItem('high_imobile_title', 'Imóveis semelhantes a este')
                    }
                  }else{
                    setHighTitleEnabled(false)
                    setTimeout(() => {
                      document.getElementById('highTitleEdit').focus()
                      if(highTitle === 'Imóveis semelhantes a este'){
                        setHighTitle('')
                        localStorage.setItem('high_imobile_title', '')
                      }
                    }, 300);
                  }
                }}>{highTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
                <i className="ri-text" onClick={() => {
                  if(!highTitleEnabled){
                    setHighTitleEnabled(true)
                    if(highTitle === ''){
                      setHighTitle('Imóveis semelhantes a este')
                      localStorage.setItem('high_imobile_title', 'Imóveis semelhantes a este')
                    }
                  }else{
                    setHighTitleEnabled(false)
                    setTimeout(() => {
                      document.getElementById('highTitleEdit').focus()
                      if(highTitle === 'Imóveis semelhantes a este'){
                        setHighTitle('')
                        localStorage.setItem('high_imobile_title', '')
                      }
                    }, 300);
                  }
                }}></i>
              </label>
            </div>
          </div>

          <Swiper navigation={true} modules={[Navigation]} loop={true} className="mySwiper">
            <SwiperSlide>   
              <div>
                <div className={styles.bg}></div>
                <div className={styles.container_immobile}>
                  <div className={styles.title_immobile}>
                    <p className={styles.immobile}>Imóvel Exemplo</p>
                  </div>
                  <div className={styles.config_immobile}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                    <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
                  </div>
                  <div className={styles.container_price}>
                      <p>R$4.000.000,00</p>
                  </div>
                  <div className={styles.view}>
                    <div className={styles.container_location}>
                      <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                      <p className={styles.location}> Localização</p>
                    </div>
                    <p className={styles.view_immobile}>Ver imóvel</p> 
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className={styles.bg}></div>
                <div className={styles.container_immobile}>
                  <div className={styles.title_immobile}>
                    <p className={styles.immobile}>Imóvel Exemplo</p>
                  </div>
                  <div className={styles.config_immobile}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                    <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
                  </div>
                  <div className={styles.container_price}>
                      <p>R$4.000.000,00</p>
                  </div>
                  <div className={styles.view}>
                    <div className={styles.container_location}>
                      <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                      <p className={styles.location}> Localização</p>
                    </div>
                    <p className={styles.view_immobile}>Ver imóvel</p> 
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className={styles.bg}></div>
                <div className={styles.container_immobile}>
                  <div className={styles.title_immobile}>
                    <p className={styles.immobile}>Imóvel Exemplo</p>
                  </div>
                  <div className={styles.config_immobile}>
                    <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                    <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                    <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
                  </div>
                  <div className={styles.container_price}>
                      <p>R$4.000.000,00</p>
                  </div>
                  <div className={styles.view}>
                    <div className={styles.container_location}>
                      <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                      <p className={styles.location}> Localização</p>
                    </div>
                    <p className={styles.view_immobile}>Ver imóvel</p> 
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

      </section>
    </>
  );
}