import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';

const CreciInput = ({ onCreciChange, onExists }) => {
  const [creci, setCreci] = useState('');
  const [isExist, setIsExist] = useState('');

  const handleCreciChange = (value) => {
    onCreciChange(value)
    setCreci(value)
  };

  const handleBlur = () => {
    if(creci && creci !== ''){
      verifyCreci(creci);
    }
  };

  const verifyCreci = (creci) => {
    axios
      .post(`${process.env.REACT_APP_API}/api/creci/verify`, {
        creci: creci,
        user_type: 'B'
      })
      .then((response) => {
        if(response.data.exists === true){
          setIsExist('Creci já cadastrado.')
        }else{
          setIsExist('')
        }
        
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    onExists(isExist);
  }, [isExist]);

  return (
    <>
    <input 
      type="text" 
      value={creci}
      placeholder="Creci"
      onChange={(e) => handleCreciChange(e.target.value)}
      onBlur={handleBlur}
      required
    />
    {isExist !== '' && (
      <div style={{ textAlign: 'center' }}>
        <p style={{ color: 'red' }}>{isExist}<br></br>Faça login, ou utilize outro Creci.</p>
        <Button
          onClick={() => {
            window.open('./login', '_parent');
          }}
        >
          Fazer login
        </Button>
      </div>
    )}
    </>
  );
};

export default CreciInput;