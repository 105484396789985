import { useState } from 'react';
import { useEffect } from 'react';
// import { api } from '../../../Services/api';
import './styles.css';
import Axios from 'axios';

function SchedulingsOwner({user}) {

  const [userSchedule, setUserSchedule] = useState([])

  const[imoveis, setImoveis] = useState([])
  const [userId, setUserId] = useState(localStorage.getItem('id'))
  const[count, setCount] = useState(0)

  const today = new Date();
  today.setDate(today.getDate() - 1)

  useEffect(() => {
      Axios.post(process.env.REACT_APP_API + '/api/auth/user-properties/' + localStorage.getItem('id'), {
        type: localStorage.getItem('access')
      }, 
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(resp => {
          setImoveis(resp.data)
      })
  },[])

   useEffect(() => {
    if(count === 0 && user?.id){
      Axios.post(process.env.REACT_APP_API + '/api/auth/get-user/' + localStorage.getItem('id'), {
        type: localStorage.getItem('access')
      }, 
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
       .then(response => {
         setUserSchedule(response.data?.schedulings
          .filter(schedule => schedule?.schedule_date?.slice(6,7) !== '0')
          .filter(schedule => Date.parse(new Date(schedule?.schedule_date)?.toISOString()) > today.getTime()))
       })
       setCount(count => count + 1)
     }
   }, [user])

  return (
      <div className="SchedulingsOwner">
        <div className="container" style={{padding:'0'}}>
          <div className="row">
            <div className="col">
              <div className="title">
                <h3>Próximas visitas</h3>
                <div className="legenda" style={{marginTop:'10px'}}>
                  <p className="your">Suas visitas</p>
                  <p className="other">Visitas nos seus imóveis</p>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            {userSchedule?.length > 0 || imoveis?.length > 0 ?
            <>
              {userSchedule?.map((schedule, index) => (
                <div key={index} className="item-row" style={{backgroundColor:'#f4f4f4'}}>
                  <div className="col">{schedule?.schedule_date && schedule?.schedule_date?.split('-').slice(2,3) + '/' + schedule?.schedule_date?.split('-').slice(1,2)}</div>
                  <div className="col">{schedule?.properties?.title}</div>
                  <div className="col">
                    {schedule?.status === 0 && <div className="label pendente">Pendente</div>}
                    {schedule?.status === 1 && <div className="label confirmado">Confirmado</div>}
                    {schedule?.status === 2 && <div className="label cancelado">Cancelado</div>}
                    {schedule?.status === 3 && <div className="label parceria">Parceria</div>}
                    {schedule?.status === 4 && <div className="label concluido">Concluído</div>}
                  </div>
                </div>
              ))}            
              {imoveis?.map((imovel, i) => (
                imovel?.future_schedulings?.map((schedule, index) => (
                  <div key={index} className="item-row" style={{backgroundColor:'#c4dab9'}}>
                    <div className="col">{schedule?.schedule_date?.split('-').slice(2,3) + '/' + schedule?.schedule_date?.split('-').slice(1,2)}</div>
                    <div className="col">{imovel?.title}</div>
                    <div className="col">
                      {schedule?.status === 0 && <div className="label pendente">Pendente</div>}
                      {schedule?.status === 1 && <div className="label confirmado">Confirmado</div>}
                      {schedule?.status === 2 && <div className="label cancelado">Cancelado</div>}
                      {schedule?.status === 3 && <div className="label parceria">Parceria</div>}
                      {schedule?.status === 4 && <div className="label concluido">Concluído</div>}
                  </div>
                </div>
              ))
              ))
              }
            </>
              :
              <p>Você não tem nenhuma visita agendada para os próximos dias, anuncie seus imóveis para conseguir agendamentos.</p>
            }
          </div>
        </div>
      </div>
    );
  }
  
export default SchedulingsOwner;