import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import './styles.scss';
import { useState } from 'react';
import Logo from "../../../assets/images/logo-dark.png";
import { Button, Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import PasswordChecklist from "react-password-checklist";
import Axios from 'axios';

export const RegisterStep3 = ({userData, newCallback}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const user = userData?.userId || searchParams.get('user');
  const type = userData?.userType || searchParams.get('type');
  const token = userData?.token || searchParams.get('token');

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [truePassword, setTruePassword] = useState(false)

  const [registerError, setRegisterError] = useState(false)

  const history = useHistory()

  const sendRegister = () => {
    setLoading(true)
    const url = type === 'C' ? 'brokers-lead-update-pass/' : type === 'I' ? 'real-estates-lead-update-pass/' : type === 'T' ? 'construction-lead-update-pass/' : ''

    const data = {
      new_password: password
    }
    
    Axios.post(
      process.env.REACT_APP_API + '/api/register/' + url + user,
      data,
      { headers: { Authorization: 'Bearer ' + token } }
    )
      .then((response) => {
        if(type === 'T'){
          if (newCallback) {
            newCallback(4)
          } else {
            history.push('/registro-passo-4?user=' + user + '&type=' + type + '&token=' + token)
          }
        }else{
          if (newCallback) {
            newCallback(5)
          } else {
            history.push('/agradecimento-cadastro')
          }
        }
      })
      .catch((error) => {
        setLoading(false)
        setRegisterError(true)
      });
  }

  return(
      <div className="register-section">
        <div className="register-wrapper">
          {loading ? 
            <Spinner className="me-2" color="success" />
          :
          <Container>
            <Row>
              <Col className="logo-container"><img src={Logo} alt="Fast Sale"/></Col>
            </Row>
            <Row className="step-title">
              <Col xs={12}>
                <h1>Agora crie uma senha de acesso</h1>
                <h3>Passo 3 de {type === 'T' ? 4 : 3}</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <fieldset>
                  <Label>Crie sua senha</Label>
                  <Input 
                    type="password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </fieldset>
              </Col>
              <Col xs={12}>
                <fieldset>
                  <Label>Confirme sua senha</Label>
                  <Input
                    type="password" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </fieldset>
              </Col>
              <Col xs={12}>
                <PasswordChecklist
                  rules={["minLength","specialChar","number","capital","match"]}
                  minLength={8}
                  iconSize={16}
                  value={password}
                  valueAgain={confirmPassword}
                  onChange={(isValid) => setTruePassword(isValid)}
                  messages={{
                      minLength: "Ao menos 8 caracteres.",
                      specialChar: "Ao menos um caractere especial.",
                      number: "Ao menos um número.",
                      capital: "Ao menos uma letra maiúscula.",
                      match: "Suas senhas coincidem.",
                  }}
                />
              </Col>
            </Row>
            <Row className="send-container">
              <Col xs={12}>
                {!truePassword ?
                    <Button disabled>A senha ainda não cumpre as regras</Button>
                  :
                    <Button onClick={sendRegister}>{type === 'T' ? 'Próximo' : 'Finalizar'}</Button>
                }
              </Col>
            </Row>
          </Container>
          }
        </div>
  
        {registerError ? (
          <SweetAlert
            title="Seu cadastro falhou, fale com nosso suporte."
            showCloseButton={true}
            showConfirm={true}
            error
            onConfirm={() => {
              setTimeout(() => {
                window.open('https://wa.me/554741081865', '_blank')
              }, 0);
            }}
          ></SweetAlert>
        ) : null}
  
      </div>
  )
}