import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_editProperties.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
require('moment/locale/pt.js');

class editPropertiesAdm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imoveis: [],
      allImoveis: [],
      filterImoveis:[],
      search:'',
      confirm_both: false,
      success_dlg: false,
      error_dlg:false,
      dynamic_title: "",
      dynamic_description: "",
      delete_id:"",
      delete_sku:"",
      statusFilter:'Todos',
      workflowFilter:'Todos',
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Editar imóveis", link: "#" },
    ],
    }
  }


  componentDidMount(){
      // if(localStorage.getItem('access') === 'A'){
      //   api.post('public/property-filter-dash/?page=' + page, data)
      //   .then(response => {
      //     setAllImoveis(response.data.data)
      //     setPages(response.data.last_page)
      //   })
      // }else{
      //   setTimeout(() => {
        if(!localStorage.getItem('user_status')){
          localStorage.setItem('user_status', 'Todos')
        }
        // const data = {
        //   filter:decodeURI(localStorage.getItem('filtro-sort') ? localStorage.getItem('filtro-sort') : 'newest'),
        //   status:decodeURI(localStorage.getItem('filtro-status') ? localStorage.getItem('filtro-status') : 'Todos'),
        //   workflow:decodeURI(localStorage.getItem('filtro-workflow') ? localStorage.getItem('filtro-workflow') : 'Todos'),
        //   search: decodeURI(window.location.search.replace('?', '').replace(/-/g, ' ')),
        // }

        document.getElementById('load').classList.add('active')
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
          type: localStorage.getItem('access'),
          user_id: localStorage.getItem('id'),
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            
          // const data2 = {
          //   type: localStorage.getItem('access')
          // }
          if(localStorage.getItem('access') === 'A'){
            
            Axios.post(process.env.REACT_APP_API + '/api/public/property-filter-no-pagination-all' , {},
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(resp => {
              this.setState({filterImoveis: resp.data})
              this.setState({allImoveis: resp.data}, () => {
                this.applyFilters();

              //  this.setState({imoveis: resp.data})
              //  this.setState({allImoveis: resp.data})
              setTimeout(() => {
                document.getElementById('load').classList.remove('active')
              }, 300);
            })
            })

            }else{
              setTimeout(() => {
                window.open("/dashboard", '_parent')
              }, 300);
            }
    })
    .catch(response =>{
      localStorage.removeItem("history_url")
      setTimeout(() => {
        window.open("/login", '_parent')
      }, 300);
    })
  }


  handleStatusChange = (event) => {
    this.setState({ statusFilter: event.target.value }, () => {
      this.applyFilters();
    });
  };
  
  handleWorkflowChange = (event) => {
    this.setState({ workflowFilter: event.target.value }, () => {
      this.applyFilters();
    });
  };


  applyFilters = () => {
    const { allImoveis, statusFilter, workflowFilter } = this.state;
  
    let filteredImoveis = allImoveis;
  
    // Aplicar filtro de status
    if (statusFilter === '1') {
      filteredImoveis = filteredImoveis.filter((imovel) => imovel.status === 1);
    } else if (statusFilter === '0') {
      filteredImoveis = filteredImoveis.filter((imovel) => imovel.status === 0);
    }else if (statusFilter === 'Todos') {
      filteredImoveis = allImoveis;
    }
    
  
    // Aplicar filtro de workflow
    if (workflowFilter !== 'Todos') {
      filteredImoveis = filteredImoveis.filter((imovel) => imovel.workflow === parseInt(workflowFilter));
    }
    if (workflowFilter === 'Todos') {
      filteredImoveis = allImoveis;
    }

    setTimeout(() => {
      this.setState({ imoveis: filteredImoveis, filterImoveis: filteredImoveis });
    }, 0);
  };

  render() {
    const data = {
      columns: [
        {
          dataField: "status",
          text: "Status"
        },
        {
          dataField: 'sku',
          text: 'Código'
        },
        {
          dataField: "title",
          text: "Nome"
        },
        {
          dataField: "created_at",
          classes:"formatDate",
          text: "Cadastro"
        },
        {
          dataField: "captador",
          text: "Captador"
        },
        {
          dataField: "workflow",
          text: "WorkFlow"
        },
        // {
        //   dataField: "fsProperties",
        //   text: "Exibição"
        // },
        {
          dataField: "edit",
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => { 
              localStorage.setItem('edit_property_sku', row.sku)
              setTimeout(() => {
                 window.open("/editar-imovel?" + row.id , '_blank')
              }, 300);
             },
          },
          text: "Editar"
        },
        {
          dataField: "delete",
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => { 
              if(localStorage.getItem('level') !== '2'){
                this.setState({ confirm_both: true })
                this.setState({ delete_id: row.id })
                this.setState({ delete_sku: row.sku })
              }
            },
          },
          text: "Excluir"
        },
      ],
    };

    const {imoveis, allImoveis, filterImoveis} = this.state;

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos imóveis', value: imoveis.length
        }]

    };

    // const selectRow = {
    //   mode: 'checkbox',
    //   clickToSelect: true
    // };

    const imoveisObject = []

    {imoveis?.sort(function(a,b){
      return new Date(b.created_at) - new Date(a.created_at)}).map((imovel, index) => {
      const novoImovel = {
        id: imovel?.id,
        edit: <i className="ri-edit-line"></i>,
        delete: <i className="ri-delete-bin-6-line"></i>,
        sku: imovel?.sku,
        title: imovel?.title,
        created_at: moment(imovel?.created_at).format('DD/MM/YYYY'),
        captador: <>{imovel?.construction?.name && <p><b>Construtora:</b> {imovel?.construction?.name}</p>}{imovel?.broker?.name && <p><b>Corretor:</b> {imovel?.broker?.name}</p>}{imovel?.real_estate?.name && <p><b>Imobiliária:</b> {imovel?.real_estate?.name}</p>}{(imovel?.owner?.name && imovel?.owner?.name !== 'Proprietário não cadastrado') && <p><b>Proprietário:</b> {imovel?.owner?.name}</p>}</>,
        captadorBroker:imovel?.broker?.name,
        captadorRealEstate:imovel?.real_estate?.name,
        captadorOwner:imovel?.owner?.name,
        captadorConstruction:imovel?.construction?.name,
        fsProperties:<div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
          {imovel?.fs_property === 1 ? 
            <div style={{
              backgroundColor:'#3E5E3F',
              fontSize:'12px',
              fontWeight:'500',
              width:'80px',
              color:'#fff',
              height:'25px',
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              borderRadius:'5px',
              cursor:'pointer'}} onClick={(e) => {
                Axios.post(process.env.REACT_APP_API + '/api/auth/fs-property', {
                  property_id:imovel?.id,
                  user_id: imovel?.broker?.id ? imovel?.broker?.id : imovel?.real_estate?.id,
                  type: imovel?.broker?.id ? "C" : 'I',
                  fs_property: e.target.style.backgroundColor == 'rgb(62, 94, 63)' ? 0 : 1
                },
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                  .then(response => {
                    if(e.target.style.backgroundColor == 'rgb(62, 94, 63)'){
                      e.target.style.backgroundColor = '#cdcdcd'
                      e.target.style.color = '#888'
                    }else{
                      e.target.style.backgroundColor = '#3E5E3F'
                      e.target.style.color = '#fff'
                    }
                  })
              }}>Fast Sale</div>
            :
            <div style={{
              backgroundColor:'#cdcdcd',
              fontSize:'12px',
              fontWeight:'500',
              width:'80px',
              color:'#888',
              height:'25px',
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              borderRadius:'5px',
              cursor:'pointer'}} onClick={(e) => {
                Axios.post(process.env.REACT_APP_API + '/api/auth/fs-property', {
                  property_id:imovel?.id,
                  user_id: imovel?.broker?.id ? imovel?.broker?.id : imovel?.real_estate?.id,
                  type: imovel?.broker?.id ? "C" : 'I',
                  fs_property: e.target.style.backgroundColor == 'rgb(62, 94, 63)' ? 0 : 1
                },
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                  .then(response => {
                    if(e.target.style.backgroundColor == 'rgb(62, 94, 63)'){
                      e.target.style.backgroundColor = '#cdcdcd'
                      e.target.style.color = '#888'
                    }else{
                      e.target.style.backgroundColor = '#3E5E3F'
                      e.target.style.color = '#fff'
                    }
                  })
              }}>Fast Sale</div>
          }
        </div>,
        workflowEdit:
            <select className="form-control" value={imovel?.workflow} onChange={(e) =>{
              Axios.post(process.env.REACT_APP_API + '/api/auth/property-dash/' + imovel?.id, {'workflow': e.target.value},
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            }}>
              <option value={0}>Cadastro aguardando análise</option>
              <option value={7}>Aprovado</option>
              <option value={1}>Fotos reprovadas</option>
              <option value={2}>Autorização de venda inválida</option>
              <option value={3}>Informações do proprietário inválidas</option>
              <option value={4}>Informações do imóvel inválidas</option>
              <option value={5}>Imóvel já existe na plataforma</option>
              <option value={11}>Imóvel fora do padrão Fast Sale</option>
              <option value={8}>Imóvel vendido via Fast Sale</option>
              <option value={6}>Imóvel vendido (externo)</option>
              <option value={9}>Contrato cancelado</option>
              <option value={10}>Bloqueado por falta de pagamento</option>
              <option value={13}>Pendente atualização</option>
              <option value={14}>Bloqueado por falta de atualização</option>
            </select>,
        workflow: imovel?.workflow === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
        : imovel?.workflow === 1 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fotos</Badge>
        : imovel?.workflow === 2 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Aut. Venda</Badge>
        : imovel?.workflow === 3 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Prop.</Badge>
        : imovel?.workflow === 4 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.workflow === 11 ? <Badge className="bg-danger me-1 rounded-pill">Fora do padrão FS</Badge>
        : imovel?.workflow === 5 ? <Badge className="bg-dark me-1 rounded-pill">Repetido</Badge>
        : imovel?.workflow === 6 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (ext)</Badge>
        : imovel?.workflow === 8 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (fs)</Badge>
        : imovel?.workflow === 9 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.workflow === 10 ? <Badge className="badge-soft-dark rounded-pill me-1">Bloqueado</Badge>
        : imovel?.workflow === 13 ? <Badge className="bg-warning rounded-pill me-1">Pendente Atualização</Badge>
        : imovel?.workflow === 14 ? <Badge className="bg-danger rounded-pill me-1">Bloqueado Atualização</Badge>
        : imovel?.workflow === 20 ? <Badge className="badge-soft-dark rounded-pill me-1">Rascunho</Badge>
        : <Badge className="bg-success me-1 rounded-pill">Aprovado</Badge>,
        status: imovel?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>,
        slug: imovel?.slug
      }
      imoveisObject.push(novoImovel); 
    })}


    return (
        <div className="page-content" id="editarImoveisAdm">
        <Container fluid={true}>
          <Breadcrumbs title="Editar imóveis" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  
                  <Row style={{justifyContent:'flex-end', marginBottom:'15px', gap:'15px'}}>
                    <Col className="col-auto" style={{display:'flex', justifyContent:'flex-end', gap:'10px', alignItems:'center'}}>
                      <Label>Status:</Label>
                      <select className="form-control" onChange={this.handleStatusChange}>
                        <option value="Todos">Todos</option>
                        <option value={1}>Ativos</option>
                        <option value={0}>Inativos</option>
                      </select>
                    </Col>
                    <Col className="col-auto" style={{display:'flex', justifyContent:'flex-end', gap:'10px', alignItems:'center'}}>
                      <Label>Workflow:</Label>
                      <select className="form-control" onChange={this.handleWorkflowChange}>
                        <option value="Todos">Todos</option>
                        <option value={0}>Pendentes</option>
                        <option value={1}>Reprovado: Fotos</option>
                        <option value={2}>Reprovado: Aut. de Venda</option>
                        <option value={3}>Reprovado: Proprietário</option>
                        <option value={4}>Reprovado: Imóvel</option>
                        <option value={5}>Repetidos</option>
                        <option value={11}>Reprovado: Fora do padrão FS</option>
                        <option value={8}>Vendido (Fast Sale)</option>
                        <option value={6}>Vendido (externo)</option>
                        <option value={9}>Cancelados</option>
                        <option value={10}>Bloqueado: Falta de Pgto.</option>
                        <option value={7}>Aprovados</option>
                        <option value={13}>Pendente atualização</option>
                        <option value={14}>Bloqueado por falta de atualização</option>
                      </select>
                    </Col>
                    <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                      <Input
                      placeholder="Buscar código ou corretor"
                      onChange={(e) => this.setState({imoveis: filterImoveis.filter(imovel =>imovel?.sku?.toLowerCase().includes(e.target.value?.toLowerCase())
                      || imovel?.broker?.name?.toLowerCase().includes(e.target.value.toLowerCase())
                      || imovel?.real_estate?.name?.toLowerCase().includes(e.target.value.toLowerCase())
                      || imovel?.owner?.name?.toLowerCase().includes(e.target.value.toLowerCase())
                      || imovel?.construction?.name?.toLowerCase().includes(e.target.value.toLowerCase())
                      || imovel?.title?.toLowerCase().includes(e.target.value.toLowerCase())
                      || imovel?.address_properties?.enterprise?.toLowerCase().includes(e.target.value.toLowerCase())
                      )})}
                      ></Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">

                  <BootstrapTable
                    keyField='id'
                    data={imoveisObject }
                    columns={data.columns}
                    // expandRow={expandRow}
                    pagination={paginationFactory(options)}
                    // selectRow={false}
                  />
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>{
              Axios.post(process.env.REACT_APP_API + '/api/auth/property-delete/' +  this.state.delete_id, {},
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({
                  confirm_both: false,
                  success_dlg: true,
                  dynamic_title: "Deletado",
                  dynamic_description: "Seu imóvel foi deletado."
                })
              })
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "Seu imóvel ainda está cadastrado."
              })
            }
          >
            <p className="alertText">Você tem certeza que deseja deletar o imóvel <b>{this.state.delete_sku}</b>?</p>
          </SweetAlert>
        ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => {
                this.setState({ success_dlg: false })
                window.location.reload()
              }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

          {this.state.error_dlg ? (
            <SweetAlert
              error
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ error_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default editPropertiesAdm;