import React, { Component, useEffect, useState } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';

import "./styles.css";
import Axios from 'axios';

function Whats () {

  const [modal, setModal] = useState('');
  const [tipoUsuario, setTipoUsuario] = useState('');
  const [emailUsuario, setEmailUsuario] = useState('');
  const [telefoneUsuario, setTelefoneUsuario] = useState('');
  const [nomeUsuario, setNomeUsuario] = useState('');

  useEffect(() => {
    if(localStorage.getItem('contactMsg') === 'false'){
      document.querySelector('.wtp-text').style.display = "none";
      document.querySelector('.close-whats').style.display = "none";
    }
  }, [])


return(
  <>
    <div id="wtp-contact">
      <div id="wtp-link">
        <a href="https://wa.me/554741081865" target="_blank">
        <div className="wtp-contact">
          <div className="wtp-text">Fale com a Fast</div>
          <div className="wtp-photo"></div>
          <div className="wtp-status"></div>
        </div>
        </a>
      </div>
      <span class="material-symbols-rounded close-whats" onClick={(e) => {
        document.querySelector('.wtp-text').style.display = "none";
        localStorage.setItem('contactMsg', false)
        document.querySelector('.close-whats').style.display = "none";
      }}><i className="ri-close-fill"></i></span>
    </div>
    {modal &&
      <div className="modalForm">
        <form onSubmit={(e) => {
          e.preventDefault()
        }}>
          <Row>
            <Col xs={12} style={{textAlign:'right', paddingTop:'0', fontSize:'18px', cursor:'pointer'}}>
              <i className="ri-close-fill" onClick={() => {
                setModal(false)
              }}></i>
            </Col>
            <Col xs={12} style={{marginBottom:'10px'}}>
              <p style={{lineHeight:'1.2', fontWeight:'600'}}><b>Preencha o formulário para iniciar o atendimento.</b></p>
            </Col>
            <Col xs={12}>
              <Label>Nome</Label>
              <Input className="form-control" type="text" value={nomeUsuario} onChange={(e) => setNomeUsuario(e.target.value)}></Input>
            </Col>
            <Col xs={12}>
              <Label>E-mail</Label>
              <Input className="form-control" type="email" value={emailUsuario} onChange={(e) => setEmailUsuario(e.target.value)}></Input>
            </Col>
            <Col xs={12}>
              <Label>Whatsapp</Label>
              <Input className="form-control" type="text" value={telefoneUsuario} onChange={(e) => setTelefoneUsuario(e.target.value)}></Input>
            </Col>
            <Col xs={12}>
              <Label>Você é um(a)...</Label>
              <select className="form-control" value={tipoUsuario} onChange={(e) => setTipoUsuario(e.target.value)}>
                <option value={null}>Selecione uma opção</option>
                <option value="Corretor">Corretor</option>
                <option value="Imobiliária">Imobiliária</option>
                <option value="Proprietário">Proprietário</option>
                <option value="Construtora">Construtora</option>
              </select>
            </Col>
            <Col xs={12} style={{marginTop:'20px'}}>
              {(tipoUsuario && telefoneUsuario !== '' && nomeUsuario !== '' && emailUsuario !== '' && emailUsuario !== '') ?
              <a href="https://wa.me/554741081865" taget="_blank"><Button type="submit" style={{width:'100%'}}>Iniciar conversa</Button></a>
              :
              <Button style={{width:'100%'}} disabled>Preencha para continuar</Button>
              }
            </Col>
          </Row>
        </form>
      </div>
    }
  </>
)
}

export default Whats;