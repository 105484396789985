import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import './styles.scss';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import { CouponListItem } from './components/couponListItem';
import { NewCoupon } from './components/newCoupon';
import SweetAlert from 'react-bootstrap-sweetalert';
import { EditCoupon } from './components/editCoupon';

export default function AdmCoupons(){

    const breadcrumbItems = [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Cupons", link: "#" },
      ];

    const [coupons, setCoupons] = useState([])
    const [addCoupon, setAddCoupon] = useState(false)
    const [editCoupon, setEditCoupon] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [dynamic_title, setDynamic_title] = useState('')
    const [success_dlg, setSuccess_dlg] = useState(false)
    const [dynamic_description, setDynamic_description] = useState('')
    const [error_dlg, setError_dlg] = useState(false)
    const [couponId, setCouponId] = useState(null)

    useEffect(() => {
        if(localStorage.getItem('access') === 'A'){
          Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              fetchCoupons();
            }).catch(response => {
              setTimeout(() => {
                window.open("/login", '_parent')
              }, 300);
            })
        }else{
          window.open("/dashboard")
        }
      }, []);

      const fetchCoupons = async () => {
        try {
          const response = await Axios.get(process.env.REACT_APP_API + '/api/auth/list-coupons', {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
          });
          setCoupons(response.data);
        } catch (error) {
          console.error('Error fetching indicators:', error);
        }
      };

      const handleNewCoupon = () => {
        setAddCoupon(true)
      }

      const handleCallBackCreate = () => {
        setAddCoupon(false)
        setEditCoupon(false)
        fetchCoupons()
      }

    return(
      <div className="page-content">
        <Container fluid={true}>
          {breadcrumbItems &&
            <Breadcrumbs title="Cupons" breadcrumbItems={breadcrumbItems} />
          }
          <Row>
            <Col>
              <Card>
                <CardBody>
                    <Row style={{marginBottom:'10px', justifyContent:'flex-end'}}>
                        <Col className="col-auto">
                            <Button onClick={handleNewCoupon}>Criar cupom</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CouponListItem data={[]} title={true}/>
                            {coupons.map((item, index) => (
                                <CouponListItem
                                    key={index}
                                    data={item}
                                    onDelete={() => {
                                        setCouponId(item.id)
                                        setOpenDeleteModal(true)
                                    }}
                                    onEdit={() => {
                                        setCouponId(item.id)
                                        setEditCoupon(true)
                                    }}
                                />
                            ))}
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <NewCoupon open={addCoupon} onClose={handleCallBackCreate}/>
        <EditCoupon open={editCoupon} onClose={handleCallBackCreate} couponId={couponId}/>

        {openDeleteModal ? (
            <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>{
                Axios.post(process.env.REACT_APP_API + '/api/auth/delete-coupon/' +  couponId, {},
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                setDynamic_title("Deletada")
                setSuccess_dlg(true)
                setOpenDeleteModal(false)
                setDynamic_description("O cupom foi deletado com sucesso.")
                })
            }}
            onCancel={() => {
                setDynamic_title("Cancelada")
                setError_dlg(true)
                setOpenDeleteModal(false)
                setDynamic_description("O cupom ainda está cadastrado.")
            }}
            >
            <p className="alertText">Você tem certeza que deseja deletar este cupom?</p>
            </SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setSuccess_dlg(false )
              fetchCoupons()
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}

        {error_dlg ? (
          <SweetAlert
            error
            title={dynamic_title}
            onConfirm={() => setError_dlg(false)}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}

      </div>
    )
}