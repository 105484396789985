import styles from '../styles/ImobilesMobile.module.css';
import Icon from '@mdi/react';
import { mdiMagnify, mdiFilterOutline, mdiBedKingOutline, mdiCar, mdiRulerSquare, mdiMapMarker } from '@mdi/js';
import { useState, useEffect } from 'react';
import Apartament from '../assets/building_white.png';
import House from '../assets/house_white.png';
import Shop from '../assets/shop_white.png';
import Terrain from '../assets/terrain_white.png';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ContentEditable from "react-contenteditable";
import { ChromePicker } from 'react-color';

const ImobilesMobile = ({data}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedVacancies, setSelectedVacancies] = useState(null);
  const [selectedBedrooms, setSelectedBedrooms] = useState(null);
  const [bg, setBg] = useState()
  const [modalLarge, setModalLarge] = useState(false)
  const [imobilesTitle, setImobilesTitle] = useState('Insira um título para seu site')
  const [imobilesTitleEnabled, setImobilesTitleEnabled] = useState(true)
  const [colorTitle, setColorTitle] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [showPicker, setShowPicker] = useState(false);
  const [ highSecondTitle, setHighSecondTitle] = useState('Destaques')
  const [ highSecondTitleEnabled, setHighSecondTitleEnabled] = useState(true)

  function handleFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // converte o arquivo em uma string Base64
      const fileAsBase64String = reader.result.split(',')[1];

      // armazena a string Base64 no estado
      localStorage.setItem('imobiles_banner', fileAsBase64String);
    });
    reader.readAsDataURL(file);
  }

  function isBase64(str) {
    try {
      atob(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const selectCategory = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((item) => item !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const selectVacancy = (vacancy) => {
    setSelectedVacancies(selectedVacancies === vacancy ? null : vacancy);
  };

  const selectBedrooms = (bedrooms) => {
    setSelectedBedrooms(selectedBedrooms === bedrooms ? null : bedrooms);
  };

  useEffect(() => {
    const imobilesBanner = localStorage.getItem('imobiles_banner');
    if (imobilesBanner && imobilesBanner.slice(0, 4) !== 'http') {
      if(localStorage.getItem('imobiles_banner')){
        const fileAsBase64String = localStorage.getItem('imobiles_banner');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
      
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
      
          const blob = new Blob([array], { type: 'application/octet-stream' });
      
          const file = new File([blob], 'nome-do-arquivo.extensão');
      
          setBg(file) ;
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      }
    } else {
      setBg(localStorage.getItem('imobiles_banner'))
    }

    if(localStorage.getItem('imobiles_title_color')){
      setColorTitle(JSON.parse(localStorage.getItem('imobiles_title_color')))
    }

    if(localStorage.getItem('imobiles_title')){
      setImobilesTitle(localStorage.getItem('imobiles_title'))
    }

    if(localStorage.getItem('high_imobiles_second_title')){
      setHighSecondTitle(localStorage.getItem('high_imobiles_second_title'))
    }
  }, [])

  return(
    <section>
      <div className={styles.bg}
        style={localStorage.getItem('imobiles_banner') && localStorage.getItem('imobiles_banner').slice(0,4) !== 'http' ? {backgroundImage: 'url(' + (bg ? URL.createObjectURL(bg) : '') + ')', position:'relative'} : {backgroundImage: `url(${bg})`} }>
        <div className={styles.actionIcons}>
          <label className="bg" onClick={() => setModalLarge(!modalLarge)}><span>Inserir imagem</span><i className="ri-image-add-fill"></i></label>
        </div>

        <div className={styles.titleContainer}>
          <ContentEditable
            html={imobilesTitle}
            className={styles.titleEdit}
            id='titleEdit'
            disabled={imobilesTitleEnabled}
            style={{
              color: `rgba(${colorTitle?.r}, ${colorTitle?.g}, ${colorTitle?.b}, ${colorTitle?.a})`,
            }}
            onChange={(e) => {
              setImobilesTitle(e.target.value)
              localStorage.setItem('imobiles_title', e.target.value)
            }}
            onClick={(e) => {
              if(imobilesTitle === 'Insira um título para seu site' && !imobilesTitleEnabled){
                setImobilesTitle('')
                localStorage.setItem('imobiles_title', '')
              }
            }}
          />
          <div className={styles.actionIcons}>
            <label className="title">
              <span onClick={() => {
                if(!imobilesTitleEnabled){
                  setImobilesTitleEnabled(true)
                  if(imobilesTitle === ''){
                    setImobilesTitle('Insira um título para seu site')
                    localStorage.setItem('imobiles_title', 'Insira um título para seu site')
                  }
                }else{
                  setImobilesTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('titleEdit').focus()
                    if(imobilesTitle === 'Insira um título para seu site'){
                      setImobilesTitle('')
                      localStorage.setItem('imobiles_title', '')
                    }
                  }, 300);
                }
              }}>{imobilesTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
              <i className="ri-text" onClick={() => {
                if(!imobilesTitleEnabled){
                  setImobilesTitleEnabled(true)
                  if(imobilesTitle === ''){
                    setImobilesTitle('Insira um título para seu site')
                    localStorage.setItem('imobiles_title', 'Insira um título para seu site')
                  }
                }else{
                  setImobilesTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('titleEdit').focus()
                    if(imobilesTitle === 'Insira um título para seu site'){
                      setImobilesTitle('')
                      localStorage.setItem('imobiles_title', '')
                    }
                  }, 300);
                }
              }}></i>
              <i className="ri-font-color" onClick={() => setShowPicker(!showPicker)}></i>
              {showPicker && (
                <div className={styles.colorPickerTitle}>
                  <i className="ri-close-fill closeIcon" onClick={() => setShowPicker(!showPicker)}></i>
                  <ChromePicker
                    color={colorTitle ?? {r: 0, g: 0, b: 0, a: 1}}
                    onChange={(e) => {
                      setColorTitle(e.rgb)
                      localStorage.setItem('imobiles_title_color', JSON.stringify(e.rgb))
                    }}
                  />
                  </div>
                )}
            </label>
          </div>
          {/* <h1 className={styles.title} style={{color: titleColor}}>{title ? title : 'Insira um título para seu site'}</h1> */}
        </div>
        
      </div>
      <div className={styles.container_search}>
        <input type="text" placeholder='Digite o nome do imóvel, localização, emp...'/>
        <div className={styles.search}>
          <Icon path={mdiMagnify} size={.7} color={"#FFF"} />
        </div>
      </div>

      <div className={styles.titleContainer}>
        <ContentEditable
            html={highSecondTitle}
            className={styles.titleEdit}
            id='highTitleEdit'
            disabled={highSecondTitleEnabled}
            onChange={(e) => {
              setHighSecondTitle(e.target.value)
              localStorage.setItem('high_imobiles_second_title', e.target.value)
            }}
            onClick={(e) => {
              if(highSecondTitle === 'Destaques' && !highSecondTitleEnabled){
                setHighSecondTitle('')
                localStorage.setItem('high_imobiles_second_title', '')
              }
            }}
          />
          <div className={styles.actionIcons}>
            <label className="title">
              <span onClick={() => {
                if(!highSecondTitleEnabled){
                  setHighSecondTitleEnabled(true)
                  if(highSecondTitle === ''){
                    setHighSecondTitle('Destaques')
                    localStorage.setItem('high_imobiles_second_title', 'Destaques')
                  }
                }else{
                  setHighSecondTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('highTitleEdit').focus()
                    if(highSecondTitle === 'Destaques'){
                      setHighSecondTitle('')
                      localStorage.setItem('high_imobiles_second_title', '')
                    }
                  }, 300);
                }
              }}>{highSecondTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
              <i className="ri-text" onClick={() => {
                if(!highSecondTitleEnabled){
                  setHighSecondTitleEnabled(true)
                  if(highSecondTitle === ''){
                    setHighSecondTitle('Destaques')
                    localStorage.setItem('high_imobiles_second_title', 'Destaques')
                  }
                }else{
                  setHighSecondTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('highTitleEdit').focus()
                    if(highSecondTitle === 'Destaques'){
                      setHighSecondTitle('')
                      localStorage.setItem('high_imobiles_second_title', '')
                    }
                  }, 300);
                }
              }}></i>
            </label>
          </div>
      </div>

      <div className={styles.container_modal_filter}>
        <button onClick={openModal}>
          Filtrar
          <Icon path={mdiFilterOutline} size={.9} />
        </button>
        {modalIsOpen && (
          <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
              <div>
                <p>Categoria</p>
                <div className={styles.container_category}>
                  <div>
                    <div className={`${styles.category} ${selectedCategories.includes('apartamento') ? styles.selected : ''}`} 
                      onClick={() => selectCategory('apartamento')}>
                      <div className={styles.category_item}>
                        <img src={Apartament} alt="" />
                        <p>Apartamento</p>
                      </div>
                    </div>
                    <div className={`${styles.category} ${selectedCategories.includes('casa') ? styles.selected : ''}`} 
                      onClick={() => selectCategory('casa')}>
                      <div className={styles.category_item}> 
                        <img src={House} alt="" />
                        <p>Casa</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={`${styles.category} ${selectedCategories.includes('comercial') ? styles.selected : ''}`} 
                      onClick={() => selectCategory('comercial')}>
                      <div className={styles.category_item}>
                        <img src={Shop} alt="" />
                        <p>Comercial</p>
                      </div>
                    </div>
                    <div className={`${styles.category} ${selectedCategories.includes('outros') ? styles.selected : ''}`} 
                      onClick={() => selectCategory('outros')}>
                      <div className={styles.category_item}>
                        <img src={Terrain} alt="" />
                        <p>Outros</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.vacancies}>
                <p>Vagas</p>
                <div className={styles.numbers}>
                  <span className={selectedVacancies === 1 ? styles.selected : ''} onClick={() => selectVacancy(1)}>1</span>
                  <span className={selectedVacancies === 2 ? styles.selected : ''} onClick={() => selectVacancy(2)}>2</span>
                  <span className={selectedVacancies === 3 ? styles.selected : ''} onClick={() => selectVacancy(3)}>3</span>
                  <span className={selectedVacancies === 4 ? styles.selected : ''} onClick={() => selectVacancy(4)}>4</span>
                  <span className={selectedVacancies === 5 ? styles.selected : ''} onClick={() => selectVacancy(5)}>+5</span>
                </div>
              </div>

              <div className={styles.bedrooms}>
                <p>Vagas</p>
                <div className={styles.numbers}>
                  <span className={selectedBedrooms === 1 ? styles.selected : ''} onClick={() => selectBedrooms(1)}>1</span>
                  <span className={selectedBedrooms === 2 ? styles.selected : ''} onClick={() => selectBedrooms(2)}>2</span>
                  <span className={selectedBedrooms === 3 ? styles.selected : ''} onClick={() => selectBedrooms(3)}>3</span>
                  <span className={selectedBedrooms === 4 ? styles.selected : ''} onClick={() => selectBedrooms(4)}>4</span>
                  <span className={selectedBedrooms === 5 ? styles.selected : ''} onClick={() => selectBedrooms(5)}>+5</span>
                </div>
              </div>

              <div className={styles.value}>
                <p>Valor máximo</p>
                <input type="text" name="" id="" placeholder='R$0.000.000,00'/>
              </div>
              <div className={styles.container_buttons}>
                <button onClick={closeModal}>Fechar</button>
                <button onClick={closeModal}>Filtrar</button>
              </div>
            </div>
          </div>
        )}
      </div>
      
      <div >
        <div className={styles.padding_mobile}>
          <div className={styles.bg_second}></div>
          <div className={styles.container_immobile}>
            <div className={styles.title_immobile}>
              <p className={styles.immobile}>Imóvel Exemplo</p>
            </div>
            <div className={styles.config_immobile}>
              <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
              <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
              <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
            </div>
            <div className={styles.container_price_slide}>
                <p>R$4.000.000,00</p>
            </div>
            <div className={styles.view}>
              <div className={styles.container_location}>
                <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                <p className={styles.location}> Localização</p>
              </div>
              <p className={styles.view_immobile}>Ver imóvel</p> 
            </div>
          </div>
        </div>
        <div className={styles.padding_mobile}>
          <div className={styles.bg_second}></div>
          <div className={styles.container_immobile}>
            <div className={styles.title_immobile}>
              <p className={styles.immobile}>Imóvel Exemplo</p>
            </div>
            <div className={styles.config_immobile}>
              <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
              <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
              <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
            </div>
            <div className={styles.container_price_slide}>
                <p>R$4.000.000,00</p>
            </div>
            <div className={styles.view}>
              <div className={styles.container_location}>
                <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                <p className={styles.location}> Localização</p>
              </div>
              <p className={styles.view_immobile}>Ver imóvel</p> 
            </div>
          </div>
        </div>
        <div>
          <div className={styles.bg_second}></div>
          <div className={styles.container_immobile}>
            <div className={styles.title_immobile}>
              <p className={styles.immobile}>Imóvel Exemplo</p>
            </div>
            <div className={styles.config_immobile}>
              <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
              <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
              <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
            </div>
            <div className={styles.container_price_slide}>
                <p>R$4.000.000,00</p>
            </div>
            <div className={styles.view}>
              <div className={styles.container_location}>
                <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                <p className={styles.location}> Localização</p>
              </div>
              <p className={styles.view_immobile}>Ver imóvel</p> 
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        isOpen={modalLarge}
        toggle={() => {
          setModalLarge(!modalLarge);
        }}
      >
        <ModalHeader toggle={() => setModalLarge(!modalLarge)}>
            Escolha uma imagem para o banner        
        </ModalHeader>
        <ModalBody>
          <input id="bg" name="bg" type="file" onChange={(e) => {setBg(e.target.files[0]); handleFileChange(e)}} />
        </ModalBody>
      </Modal>
    </section>
  )
}

export default ImobilesMobile;