import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Container, Input, Badge, Spinner, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_editProperties.scss'

import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
require('moment/locale/pt.js');


class editEnterprises extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imoveis: [],
      allImoveis: [],
      search:'',
      confirm_both: false,
      success_dlg: false,
      error_dlg:false,
      update_error :false,
      dynamic_title: "",
      dynamic_description: "",
      delete_id:"",
      reason:'',
      reasonOther:'',
      viewport:[
        {
          dataField: "mobile",
          text: "Imóveis"
        },
      ],
      delete_sku:"",
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Editar empreendimentos", link: "#" },
    ],
    }
  }


  componentDidMount(){

        document.getElementById('load').classList.add('active')
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
          type: localStorage.getItem('access'),
          user_id: localStorage.getItem('id'),
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {

          const data2 = {
            type: localStorage.getItem('access')
          }
          Axios.post(process.env.REACT_APP_API + '/api/auth/user-properties/' + localStorage.getItem('id') , data2,
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            this.setState({imoveis: response.data})
            this.setState({allImoveis: response.data})
            setTimeout(() => {
              document.getElementById('load').classList.remove('active')
            }, 300);

            if(window.screen.width > 768){
              this.setState({viewport: 
                [
                  {
                    dataField: "status",
                    text: "Status"
                  },
                  {
                    dataField: "image",
                    text: "Foto"
                  },
                  {
                    dataField: "title",
                    text: "Nome"
                  },
                  {
                    dataField: "created_at",
                    classes:"formatDate",
                    text: "Cadastro"
                  },
                  {
                    dataField: "delivery",
                    text: "Entrega"
                  },
                  {
                    dataField: "progress",
                    text: "Andamento"
                  },
                  {
                    dataField: "workflow",
                    text: "WorkFlow"
                  },
                  {
                    dataField: "edit",
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => { 
                        localStorage.setItem('edit_enterprise_sku', row.sku)
                        setTimeout(() => {
                          window.open("/editar-empreendimento?" + row.id , '_parent')
                        }, 300);
                      },
                    },
                    text: "Editar"
                  },
                  {
                    dataField: "delete",
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => { 
                        this.setState({ confirm_both: true })
                        this.setState({ delete_id: row.id })
                        this.setState({ delete_sku: row.title })
                      },
                    },
                    text: "Excluir"
                  },
                ],
              })
            }

          })
      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }
  

  render() {

    const {imoveis, allImoveis, viewport} = this.state;

    const options = {
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos empreendimetos', value: imoveis.length
        }]

    };

    const imoveisObject = []

    {imoveis.map(imovel => {
      const novoImovel = {
        id: imovel?.id,
        edit: <i className="ri-edit-line"></i>,
        delete: <i className="ri-delete-bin-6-line"></i>,
        delivery: imovel?.delivery,
        title: imovel?.title,
        image:<img src={imovel?.photos?.filter(photo => photo.order === 0)[0]?.small_image} style={{
          objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
        }}></img>,
        created_at: moment(imovel?.created_at).format('DD/MM/YYYY'),
        progress: imovel?.progress + ' %',
        fsProperties:<div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
      </div>,
        workflow: imovel?.workflow === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
        : imovel?.workflow === 1 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fotos</Badge>
        : imovel?.workflow === 2 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Aut. Venda</Badge>
        : imovel?.workflow === 3 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Prop.</Badge>
        : imovel?.workflow === 4 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.workflow === 11 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fora do padrão</Badge>
        : imovel?.workflow === 5 ? <Badge className="bg-dark me-1 rounded-pill">Repetido</Badge>
        : imovel?.workflow === 6 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (ext)</Badge>
        : imovel?.workflow === 8 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (fs)</Badge>
        : imovel?.workflow === 9 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.workflow === 10 ? <Badge className="badge-soft-dark rounded-pill me-1">Bloqueado</Badge>
        : <Badge className="bg-success me-1 rounded-pill">Aprovado</Badge>,
        status: imovel?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>,
        slug: imovel?.slug,
        mobile: <Container style={{padding:'0'}}>
          <Row>
            <Col className="col-auto">Status: {imovel?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>}</Col>
            <Col style={{textAlign:'right'}}>{imovel?.workflow === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
            : imovel?.workflow === 1 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fotos</Badge>
            : imovel?.workflow === 2 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Aut. Venda</Badge>
            : imovel?.workflow === 3 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Prop.</Badge>
            : imovel?.workflow === 4 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
            : imovel?.workflow === 11 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fora do padrão</Badge>
            : imovel?.workflow === 5 ? <Badge className="bg-dark me-1 rounded-pill">Repetido</Badge>
            : imovel?.workflow === 6 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (ext)</Badge>
            : imovel?.workflow === 8 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (fs)</Badge>
            : imovel?.workflow === 9 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
            : imovel?.workflow === 10 ? <Badge className="badge-soft-dark rounded-pill me-1">Bloqueado</Badge>
            : <Badge className="bg-success me-1 rounded-pill">Aprovado</Badge>}</Col>
          </Row>
          <Row style={{marginTop:'15px'}}>
            <Col className="col-auto">
              <img src={imovel?.photos?.filter(photo => photo.order === 0)[0]?.small_image} style={{
                objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
              }}></img>
            </Col>
            <Col>
            <p style={{lineHeight:'1.2'}}>{imovel?.sku}</p>
            <small>{imovel?.title}</small>
            </Col>
          </Row>
          <Row style={{marginTop:'15px', backgroundColor:'#f4f4f4', borderRadius:'5px', alignItems:'center'}}>
            <Col xs={8}><span style={{fontSize:'12px', fontWeight:'500'}}>Ações:</span></Col>
            <Col style={{textAlign:'right', padding: '0 5px'}}><i className="ri-edit-line" onClick={() => {
               localStorage.setItem('edit_enterprise_sku', imovel.sku)
               setTimeout(() => {
                  window.open("/editar-empreendimento?" + imovel.id , '_parent')
               }, 300);
            }}></i></Col>
            <Col style={{textAlign:'right', padding: '0 5px'}}><i className="ri-delete-bin-6-line" onClick={() => {
              this.setState({ confirm_both: true })
              this.setState({ delete_id: imovel.id })
              this.setState({ delete_sku: imovel.sku })
            }}></i></Col>
          </Row>
        </Container>
      }
      imoveisObject.push(novoImovel); 
    })}


    return (
        <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Editar empreendimentos" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>

                  <h4 className="card-title mb-4">Seus empreendimentos cadastrados</h4>
                  <Row style={{justifyContent:'flex-end', marginBottom: '10px'}}>
                    <Col style={{display:'flex', flexDirection:'row', maxWidth: '240px'}}>
                      <Input
                      placeholder="Buscar código"
                      onChange={(e) => this.setState({imoveis: allImoveis?.filter(imovel => imovel.sku.toLowerCase().includes(e.target.value.toLowerCase()))})}></Input>
                    </Col>
                  </Row>
                  <div className="table-responsive">

                  <BootstrapTable
                    keyField='id'
                    data={imoveisObject}
                    columns={viewport}
                    pagination={paginationFactory(options)}
                    // selectRow={false}
                  />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>{
              Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-delete/' +  this.state.delete_id, {},
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({
                  confirm_both: false,
                  success_dlg: true,
                  dynamic_title: "Deletado",
                  dynamic_description: "Seu imóvel foi deletado."
                })
              })
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "Seu imóvel ainda está cadastrado."
              })
            }
          >
            <p className="alertText">Você tem certeza que deseja deletar o emprendimento <b>{this.state.delete_sku}</b>?</p>
          </SweetAlert>
        ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => {
                this.setState({ success_dlg: false })
                window.location.reload()
              }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

          {this.state.error_dlg ? (
            <SweetAlert
              error
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ error_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default editEnterprises;