import { useState } from 'react';
import { useEffect } from 'react';
import './styles.css';
import { Col } from 'reactstrap';

function LastViewOwnerTool({user}) {

  const [userHistory, setUserHistory] = useState([])

  useEffect(() => {
    setUserHistory(user.properties)
  },[user])

  return (
      <div className="LastViewOwnerTool">
        <div className="container" style={{padding:'0'}}>
          <div className="row tit">
            <div className="col">
              <div className="title">
                <h3>Corretores que visualizaram</h3>
              </div>
            </div>
          </div>
            <div className="content" style={{marginTop:'10px'}}>
            {userHistory?.map((imovel) => (
              imovel?.visit_histories?.map((visita, index) => (
                <div className="row item" key={index}>
                  <div className="col col-auto">
                    <div className="cover">
                      {visita?.broker_id && visita?.broker?.name?.slice(0,1) + visita?.broker?.name?.split(' ').slice(1,2).toString().slice(0,1)}
                      {visita?.real_estate_id && visita?.real_estate?.name?.slice(0,1) + visita?.real_estate?.name?.split(' ').slice(1,2).toString().slice(0,1)}
                    </div>
                  </div>
                  <div className="col">
                    <div className="immobile">
                        <p>{imovel?.title}</p>
                    </div>
                  </div>
                </div>
              ))
            ))}
          </div>
        </div>
      </div>
    );
  }
  
export default LastViewOwnerTool;