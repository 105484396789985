import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';

// Carousel images 
// import img1 from '../../../assets/images/small/img-1.jpg';
// import img2 from '../../../assets/images/small/img-2.jpg';
// import img3 from '../../../assets/images/small/img-3.jpg';

// const items = [
//     {
//         src: img1,
//         altText: 'Slide 1',
//         caption: 'Slide 1',

//     },
//     {
//         src: img2,
//         altText: 'Slide 2',
//         caption: 'Slide 2'
//     },
//     {
//         src: img3,
//         altText: 'Slide 3',
//         caption: 'Slide 3'
//     }
// ];

class Slidewithfade extends Component {

    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props?.photo?.length - 1 ? 0 : this.state.activeIndex + 1;
        // const nextIndex = this.state.activeIndex === props.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props?.photo?.length - 1 : this.state.activeIndex - 1;
        // const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex } = this.state;

        const slides = this.props?.photo?.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.medium_image} >
                    <img src={item.medium_image} className="d-block img-fluid" />
                </CarouselItem>
            );
        });

        return (
            <React.Fragment>
                {/* <Carousel
                    activeIndex={activeIndex}
                    fade={true}
                    next={this.next}
                    previous={this.previous}
                    > */}
                <Carousel
                    activeIndex={activeIndex}
                    fade={true}
                    next={this.next}
                    previous={this.previous}
                    interval={15000}
                    autoPlay={false}
                    >
                    <CarouselIndicators items={this.props.photo} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
            </React.Fragment>
        );
    }
}

export default Slidewithfade;   