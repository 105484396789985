import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty } from "lodash"

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    ModalFooter,
    Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import { Swiper, SwiperSlide } from "swiper/react";
//css
import "@fullcalendar/bootstrap/main.css"
import './../../assets/scss/custom/components/_catalogProperties.scss'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    getEvents,
    addNewEvent,
    updateEvent,
    deleteEvent,
    getCategories,
} from "../../store/actions"
import DeleteModal from "./DeleteModal"
import Axios from 'axios'
import ImovelCard from '../../components/ImovelCard/ImovelCard'

import 'swiper/css';
import "swiper/css/navigation";
// import "swiper/scrollbar.css"

import { Navigation } from "swiper"
import Filters from '../../components/Filters/Filters'

class CatalogProperties extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: "Fast Sale", link: "/dashboard" },
                { title: "Imóveis", link: "#" },
            ],
            modal_scroll: false,
            visible: false,
            search: '',
            allImoveisNoPremium: [],
            allImoveis: [],
            premiumImoveis: [],
            outletImoveis: [],
            fsImoveis: [],
            parceriaImoveis: []
        }
        this.buttonRef = React.createRef();
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.tog_scroll = this.tog_scroll.bind(this);
    }

    tog_scroll() {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll
        }));
        // this.removeBodyCss();
      }
      show() {
        this.setState({ visible: true });
      }
      hide() {
        this.setState({ visible: false });
      }
    
            // api.post('public/property-filter-no-pagination', data)
            // .then(response => {
            //   setResults(response.data)
            //   document.getElementById('load').classList.remove('active')
            // })

    componentDidMount = () => {
        const input = document.getElementById("inputText");
        input.addEventListener("keydown", this.handleKeyDown);
        const data = {
            categories: 0,
            localization: 0,
            dorms_number: 0,
            parking_spots: 0,
            real_state_financing: 0,
            accept_object_exchange: 0,
            accept_properties: 0,
            min_value: '',
            max_value: '',
            min_area: '',
            max_area: '',
            // filter:decodeURI(sortResults),
            workflow: ['7'],
            // contract:decodeURI(searchContractType),
            // search: decodeURI(window.location.search.replace('?', '').replace(/-/g, ' ')),
            accept_car: 0
        }

        Axios.get(process.env.REACT_APP_API + '/api/public/property-exclude-last30-days')
        .then(response => {
            this.setState({allImoveisNoPremium: response.data.data})
        })
        Axios.post(process.env.REACT_APP_API + '/api/public/property-search-exclude-last30-days/fs')
        .then(response => {
            this.setState({fsImoveis: response.data.data})
        })
        Axios.post(process.env.REACT_APP_API + '/api/public/property-search-exclude-last30-days/parc')
        .then(response => {
            this.setState({parceriaImoveis: response.data.data})
        })
        Axios.get(process.env.REACT_APP_API + '/api/public/property-last30-days')
        .then(response => {
            this.setState({premiumImoveis: response.data.data})
        })
        Axios.get(process.env.REACT_APP_API + '/api/auth/opportunity', {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            this.setState({outletImoveis: response.data})
        })
        Axios.post(process.env.REACT_APP_API + '/api/public/property-filter-no-pagination', data, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            this.setState({allImoveis: response.data})
        })
    }

    componentWillUnmount() {
        const input = document.getElementById("inputText");
        input.removeEventListener("keydown", this.handleKeyDown);
      }

    //  componentDidUpdate = () => {
    //      console.log(this.state.allImoveis)
    //  }
    handleKeyDown(event) {
        if (event.keyCode === 13) { // Verifica se a tecla pressionada é "Enter"
          event.preventDefault(); // Impede que a página recarregue
          if (this.buttonRef.current) { // Verifica se a referência do botão está definida
            setTimeout(() => {
                this.buttonRef.current.click(); // Dispara o clique no botão
            }, 300);
            console.log(this.buttonRef.current)
          }
        }
      }


    render() {
        const {allImoveisNoPremium, allImoveis, premiumImoveis, outletImoveis, parceriaImoveis, fsImoveis} = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Imóveis" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col style={{justifyContent:'flex-start', display:'flex', marginBottom: '10px', gap: '15px'}}>
                                <Button
                                    type="button"
                                    color="light" className="waves-effect waves-light filter-btn"
                                    onClick={this.tog_scroll}
                                    data-toggle="modal"
                                >
                                    <i className="ri-filter-2-line"></i>Filtros
                                </Button>
                                <Col className="searchField">
                                    <Input placeholder='Busque por cidade, código, título ou tag'
                                    id="inputText" 
                                    style={{border:'none', backgroundColor: '#fff'}}
                                    onChange={(e) => this.setState({search : e.target.value})}></Input>
                                    <button
                                        type="button"
                                        color="light" className="waves-effect waves-light search-btn"
                                        ref={this.buttonRef}
                                        onClick={(e) => {
                                            setTimeout(() => {
                                                window.open("/imoveis-resultado?" + this.state.search, '_parent')
                                            }, 300);
                                        }}
                                        data-toggle="modal"
                                    >
                                        <i className="ri-search-line"></i>
                                    </button>
                                </Col>
                            </Col>
                            <Col className="col-12">
                                <Card>
                                    <CardBody style={{padding:'0 12px'}}>

                                        <Row style={{marginTop:'30px'}}>
                                            <Col><Button className="btn-success" onClick={() => {
                                                localStorage.setItem('paginaAtual', '1')
                                                setTimeout(() => {
                                                    window.open("/imoveis-resultado?premium", '_parent')
                                                }, 300);
                                            }}>Ver imóveis premium</Button></Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-lg-12" style={{padding:'0'}}>
                                            <Swiper
                                            slidesPerView={"auto"}
                                            spaceBetween={0}
                                            navigation={true}
                                            modules={[Navigation]}
                                            className="SwiperImoveis"
                                            >
                                                {premiumImoveis.map((imovel, index) => (
                                                    <SwiperSlide><ImovelCard  imovel={imovel} premium={localStorage.getItem('high')}/></SwiperSlide>
                                                ))}
                                                <SwiperSlide><div className="moreAll" onClick={() => {
                                                localStorage.setItem('paginaAtual', '1')
                                                setTimeout(() => {
                                                    window.open("/imoveis-resultado?premium", '_parent')
                                                }, 300);
                                            }}>Ver imóveis premium</div></SwiperSlide>
                                            </Swiper>
                                            </Col>
                                        </Row>

                                        <Row style={{marginTop:'30px'}}>
                                            <Col><Button className="btn-dark" onClick={() => {
                                                localStorage.setItem('paginaAtual', '1')
                                                setTimeout(() => {
                                                    window.open("/imoveis-resultado?outlet", '_parent')
                                                }, 300);
                                            }}>Ver imóveis outlet</Button></Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-lg-12" style={{padding:'0'}}>
                                            <Swiper
                                            slidesPerView={"auto"}
                                            spaceBetween={0}
                                            navigation={true}
                                            modules={[Navigation]}
                                            className="SwiperImoveis"
                                            >
                                                {outletImoveis.map((imovel, index) => (
                                                    <SwiperSlide><ImovelCard  imovel={imovel} premium={localStorage.getItem('high')}/></SwiperSlide>
                                                ))}
                                                <SwiperSlide><div className="moreAll" onClick={() => {
                                                localStorage.setItem('search-contract-type', 'Todos Contratos')
                                                localStorage.setItem('paginaAtual', '1')
                                                setTimeout(() => {
                                                    window.open("/imoveis-resultado?outlet", '_parent')
                                                }, 300);
                                            }}>Ver imóveis outlet</div></SwiperSlide>
                                            </Swiper>
                                            </Col>
                                        </Row>

                                        <Row style={{marginTop:'30px'}}>
                                            <Col><Button className="btn-primary" onClick={() => {
                                                localStorage.setItem('search-contract-type', 'Contratos Fast Sale')
                                                localStorage.setItem('paginaAtual', '1')
                                                setTimeout(() => {
                                                    window.open("/imoveis-resultado", '_parent')
                                                }, 300);
                                            }}>Ver imóveis Fast Sale</Button></Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-lg-12" style={{padding:'0'}}>
                                            <Swiper
                                            slidesPerView={"auto"}
                                            spaceBetween={0}
                                            navigation={true}
                                            modules={[Navigation]}
                                            className="SwiperImoveis"
                                            >
                                                {fsImoveis.map((imovel, index) => (
                                                    <SwiperSlide><ImovelCard  imovel={imovel} premium={localStorage.getItem('high')}/></SwiperSlide>
                                                ))}
                                                <SwiperSlide><div className="moreAll" onClick={() => {
                                                localStorage.setItem('search-contract-type', 'Contratos Fast Sale')
                                                localStorage.setItem('paginaAtual', '1')
                                                setTimeout(() => {
                                                    window.open("/imoveis-resultado", '_parent')
                                                }, 300);
                                            }}>Ver imóveis Fast Sale</div></SwiperSlide>
                                            </Swiper>
                                            </Col>
                                        </Row>


                                        <Row style={{marginTop:'30px'}}>
                                            <Col><Button className="btn-warning" onClick={() => {
                                                localStorage.setItem('search-contract-type', 'Contratos Parceria')
                                                localStorage.setItem('paginaAtual', '1')
                                                setTimeout(() => {
                                                    window.open("/imoveis-resultado", '_parent')
                                                }, 300);
                                            }}>Ver imóveis parceria</Button></Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-lg-12" style={{padding:'0'}}>
                                            <Swiper
                                            slidesPerView={"auto"}
                                            spaceBetween={0}
                                            navigation={true}
                                            modules={[Navigation]}
                                            className="SwiperImoveis"
                                            >
                                                {parceriaImoveis.map((imovel, index) => (
                                                    <SwiperSlide><ImovelCard  imovel={imovel} premium={localStorage.getItem('high')}/></SwiperSlide>
                                                ))}
                                                <SwiperSlide><div className="moreAll" onClick={() => {
                                                localStorage.setItem('search-contract-type', 'Contratos Parceria')
                                                localStorage.setItem('paginaAtual', '1')
                                                setTimeout(() => {
                                                    window.open("/imoveis-resultado", '_parent')
                                                }, 300);
                                            }}>Ver imóveis parceria</div></SwiperSlide>
                                            </Swiper>
                                            </Col>
                                        </Row>

                                        <Row style={{marginTop:'30px'}}>
                                            <Col><Button className="btn-secondary" onClick={() => {
                                                localStorage.setItem('search-contract-type', 'Todos Contratos')
                                                localStorage.setItem('paginaAtual', '1')
                                                setTimeout(() => {
                                                    window.open("/imoveis-resultado", '_parent')
                                                }, 300);
                                            }}>Ver todos imóveis</Button></Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-lg-12" style={{padding:'0'}}>
                                            <Swiper
                                            slidesPerView={"auto"}
                                            spaceBetween={0}
                                            navigation={true}
                                            modules={[Navigation]}
                                            className="SwiperImoveis"
                                            >
                                                {allImoveisNoPremium.map((imovel, index) => (
                                                    <SwiperSlide><ImovelCard  imovel={imovel} premium={localStorage.getItem('high')}/></SwiperSlide>
                                                ))}
                                                <SwiperSlide><div className="moreAll" onClick={() => {
                                                localStorage.setItem('search-contract-type', 'Todos Contratos')
                                                localStorage.setItem('paginaAtual', '1')
                                                setTimeout(() => {
                                                    window.open("/imoveis-resultado", '_parent')
                                                }, 300);
                                            }}>Ver todos imóveis</div></SwiperSlide>
                                            </Swiper>
                                            </Col>
                                        </Row>
                                        
                                    </CardBody>
                                </Card>
                                <Modal
                                isOpen={this.state.modal_scroll}
                                toggle={this.tog_scroll}
                                scrollable={true}
                                >
                                    <ModalHeader toggle={() => this.setState({ modal_scroll: false })}>
                                        Aplicar filtros
                                    </ModalHeader>
                                    <ModalBody>
                                        <Filters imoveis={allImoveis}/>
                                    <ModalFooter>
                                    <Button type="button" color="light" onClick={() => {
                                        this.setState({ modal_scroll: false })
                                        localStorage.removeItem('filter-search')
                                        localStorage.removeItem('filtro-quartos')
                                        localStorage.removeItem('filtro-negocio')
                                        localStorage.removeItem('filtro-categoria')
                                        localStorage.removeItem('max_price_range_filter')
                                        localStorage.removeItem('min_price_range_filter')
                                        localStorage.removeItem('filtro-garagens')
                                        localStorage.removeItem('filtro-cidades')
                                        localStorage.removeItem('filtro-estados')
                                        localStorage.removeItem('min_area_range_filter')
                                        localStorage.removeItem('max_area_range_filter')
                                        } }>Remover filtros</Button>
                                    <Button type="button" color="primary" onClick={(e) => {
                                        e.preventDefault();
                                        setTimeout(() => {
                                            window.open("/imoveis-resultado", '_parent')
                                        }, 300);
                                        }}>Aplicar</Button>
                                    </ModalFooter>                                 
                                    </ModalBody>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default (CatalogProperties)