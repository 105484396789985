import styles from '../styles/Imobiles.module.css';
import Icon from '@mdi/react';
import { mdiMagnify, mdiBedKingOutline, mdiCar, mdiRulerSquare, mdiMapMarker } from '@mdi/js';
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Filter from './Filter';
import { useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import ImobilesMobile from './ImobilesMobile';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ContentEditable from "react-contenteditable";
import { ChromePicker } from 'react-color';
import Dropzone from 'react-dropzone';

const Imobiles = ({data}) => {
  const Image1 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910160/imagens_site/01_scvymp.jpg'
  const Image2 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/02_xgiimb.jpg'
  const Image3 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/03_e9vkzl.jpg' 
  const Image4 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/04_xsg1ak.jpg'
  const Image5 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/05_ihhakf.jpg'
  const Image6 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/06_cczyec.jpg'
  const Image7 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/07_ferbmj.jpg'
  const Image8 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/08_xfpa1t.jpg'
  const Image9 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/09_nc7xre.jpg'
  const Image10 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/10_nv5ebk.jpg' 
  const Image11 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/11_czopvn.jpg'
  const Image12 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/12_m9zhdi.jpg'
  const Image13 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910161/imagens_site/13_p9suxq.jpg'
  const Image14 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/14_rs5kae.jpg'
  const Image15 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/15_h1nynp.jpg'  
  const Image16 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/16_jgg85v.jpg'
  const Image17 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/17_svhtnj.jpg'
  const Image18 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910163/imagens_site/18_siq9wr.jpg'
  const Image19 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910163/imagens_site/19_m5xuxr.jpg'
  const Image20 = 'https://res.cloudinary.com/dgpzwt70q/image/upload/v1683910162/imagens_site/20_afgcus.jpg'
  const [bg, setBg] = useState()
  const [imobilesTitle, setImobilesTitle] = useState('Insira um título para seu site')
  const [imobilesTitleEnabled, setImobilesTitleEnabled] = useState(true)
  const [colorTitle, setColorTitle] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [showPicker, setShowPicker] = useState(false);
  const [modalLarge, setModalLarge] = useState(false)
  const [colorButton, setColorButton] = useState({r: 0, g: 0, b: 0, a: 1,});
  const [colorTextButton, setColorTextButton] = useState({r: 255, g: 255, b: 255, a: 1,});
  const [selectedLabel, setSelectedLabel] = useState(null);
  const defaultImages = [
    { src: Image1 },
    { src: Image2 },
    { src: Image3 },
    { src: Image4 },
    { src: Image5 },
    { src: Image6 },
    { src: Image7 },
    { src: Image8 },
    { src: Image9 },
    { src: Image10 },
    { src: Image11 },
    { src: Image12 },
    { src: Image13 },
    { src: Image14 },
    { src: Image15 },
    { src: Image16 },
    { src: Image17 },
    { src: Image18 },
    { src: Image19 },
    { src: Image20 },
  ];

  function handleFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // converte o arquivo em uma string Base64
      const fileAsBase64String = reader.result.split(',')[1];

      // armazena a string Base64 no estado
      localStorage.setItem('imobiles_banner', fileAsBase64String);
    });
    reader.readAsDataURL(file);
  }

  const [isMobile, setIsMobile] = useState(false);
  const isMobileQuery = useMediaQuery({ query: `(max-width: 767px)` });

  function isBase64(str) {
    try {
      atob(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const imobilesBanner = localStorage.getItem('imobiles_banner');
    if (imobilesBanner && imobilesBanner.slice(0, 4) !== 'http') {
      if(localStorage.getItem('imobiles_banner')){
        const fileAsBase64String = localStorage.getItem('imobiles_banner');
  
        if (isBase64(fileAsBase64String)) {
          const decodedFile = atob(fileAsBase64String);
      
          const array = new Uint8Array(decodedFile.length);
          for (let i = 0; i < decodedFile.length; i++) {
            array[i] = decodedFile.charCodeAt(i);
          }
      
          const blob = new Blob([array], { type: 'application/octet-stream' });
      
          const file = new File([blob], 'nome-do-arquivo.extensão');
      
          setBg(file) ;
        } else {
          console.error('Invalid base64 string:', fileAsBase64String);
        }
      }
    } else {
      setBg(localStorage.getItem('imobiles_banner'))
    }
    if(localStorage.getItem('imobiles_title_color')){
      setColorTitle(JSON.parse(localStorage.getItem('imobiles_title_color')))
    }
    if(localStorage.getItem('imobiles_title')){
      setImobilesTitle(localStorage.getItem('imobiles_title'))
    }
    if(localStorage.getItem('high_button_text_color')){
      setColorTextButton(JSON.parse(localStorage.getItem('high_button_text_color')))
    }
    if(localStorage.getItem('high_button_color')){
      setColorButton(JSON.parse(localStorage.getItem('high_button_color')))
    }
  }, [])


  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery]);

  if (isMobile) {
    return <ImobilesMobile />;
  }

  return(
    <section>
      <div className={styles.bg} 
        style={localStorage.getItem('imobiles_banner') && localStorage.getItem('imobiles_banner').slice(0,4) !== 'http' ? {backgroundImage: 'url(' + (bg ? URL.createObjectURL(bg) : '') + ')', position:'relative'} : {backgroundImage: `url(${bg})`} }>
        <div className={styles.actionIcons}>
          <label className="bg" onClick={() => setModalLarge(!modalLarge)}><span>Inserir imagem</span><i className="ri-image-add-fill"></i></label>
        </div>
        <div className={styles.titleContainer}>
          <ContentEditable
            html={imobilesTitle}
            className={styles.titleEdit}
            id='titleEdit'
            disabled={imobilesTitleEnabled}
            style={{
              color: `rgba(${colorTitle?.r}, ${colorTitle?.g}, ${colorTitle?.b}, ${colorTitle?.a})`,
            }}
            onChange={(e) => {
              setImobilesTitle(e.target.value)
              localStorage.setItem('imobiles_title', e.target.value)
            }}
            onClick={(e) => {
              if(imobilesTitle === 'Insira um título para seu site' && !imobilesTitleEnabled){
                setImobilesTitle('')
                localStorage.setItem('imobiles_title', '')
              }
            }}
          />
          <div className={styles.actionIcons}>
            <label className="title">
              <span onClick={() => {
                if(!imobilesTitleEnabled){
                  setImobilesTitleEnabled(true)
                  if(imobilesTitle === ''){
                    setImobilesTitle('Insira um título para seu site')
                    localStorage.setItem('imobiles_title', 'Insira um título para seu site')
                  }
                }else{
                  setImobilesTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('titleEdit').focus()
                    if(imobilesTitle === 'Insira um título para seu site'){
                      setImobilesTitle('')
                      localStorage.setItem('imobiles_title', '')
                    }
                  }, 300);
                }
              }}>{imobilesTitleEnabled ? 'Editar texto' : 'Concluir edição'}</span>
              <i className="ri-text" onClick={() => {
                if(!imobilesTitleEnabled){
                  setImobilesTitleEnabled(true)
                  if(imobilesTitle === ''){
                    setImobilesTitle('Insira um título para seu site')
                    localStorage.setItem('imobiles_title', 'Insira um título para seu site')
                  }
                }else{
                  setImobilesTitleEnabled(false)
                  setTimeout(() => {
                    document.getElementById('titleEdit').focus()
                    if(imobilesTitle === 'Insira um título para seu site'){
                      setImobilesTitle('')
                      localStorage.setItem('imobiles_title', '')
                    }
                  }, 300);
                }
              }}></i>
              <i className="ri-font-color" onClick={() => setShowPicker(!showPicker)}></i>
              {showPicker && (
                <div className={styles.colorPickerTitle}>
                  <i className="ri-close-fill closeIcon" onClick={() => setShowPicker(!showPicker)}></i>
                  <ChromePicker
                    color={colorTitle ?? {r: 0, g: 0, b: 0, a: 1}}
                    onChange={(e) => {
                      setColorTitle(e.rgb)
                      localStorage.setItem('imobiles_title_color', JSON.stringify(e.rgb))
                    }}
                  />
                  </div>
                )}
            </label>
          </div>
          {/* <h1 className={styles.title} style={{color: titleColor}}>{title ? title : 'Insira um título para seu site'}</h1> */}
        </div>

        {/* <h1>Nossos imóveis</h1> */}
      
      </div>
      <div className={styles.container_search}>
        <input type="text" placeholder='Digite o nome do imóvel, localização, empreendimento'/>
        <div className={styles.search}>
          <Icon path={mdiMagnify} size={1} color={'#FFF'} />
        </div>
      </div>

      <div className={styles.imobiles}>
        <div style={{marginLeft: '2rem'}}>
          <Filter />
        </div>
        
        <div>
          <div style={{display: 'flex', marginTop: '4rem', marginBottom: '-6rem'}}>
            <div>
                <div>
                  <div className={styles.bg_second}></div>
                    <div className={styles.container_immobile}>
                      <div className={styles.title_immobile}>
                        <p className={styles.immobile}>Imóvel Exemplo</p>
                      </div>
                      <div className={styles.config_immobile}>
                        <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                        <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                        <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
                      </div>
                      <div className={styles.container_price_slide}>
                        <p style={{
                            borderColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`
                        }}>R$4.000.000,00</p>
                      </div>
                      <div className={styles.view}>
                        <div className={styles.container_location}>
                          <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                          <p className={styles.location}> Localização</p>
                        </div>
                        <p className={styles.view_immobile}  style={{
                          backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                          color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                        }}>Ver imóvel</p> 
                    </div>
                  </div>
                </div> 
            </div>
            <div>
                <div>
                  <div className={styles.bg_second}></div>
                    <div className={styles.container_immobile}>
                      <div className={styles.title_immobile}>
                        <p className={styles.immobile}>Imóvel Exemplo</p>
                      </div>
                      <div className={styles.config_immobile}>
                        <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} style={{color: `rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`}}/>3 suítes</span>
                        <span className={styles.container_icons}><Icon path={mdiCar} size={.7} style={{color: `rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`}}/> 3 vagas</span>
                        <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} style={{color: `rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`}}/>152m</span>
                      </div>
                      <div className={styles.container_price_slide}>
                        <p style={{
                            borderColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`
                        }}>R$4.000.000,00</p>
                      </div>
                      <div className={styles.view}>
                        <div className={styles.container_location}>
                          <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                          <p className={styles.location}> Localização</p>
                        </div>
                        <p className={styles.view_immobile}  style={{
                          backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                          color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                        }}>Ver imóvel</p> 
                    </div>
                  </div>
                </div> 
            </div>
          </div>
          <div style={{display: 'flex', marginTop: '4rem'}}>
            <div>
                <div>
                  <div className={styles.bg_second}></div>
                    <div className={styles.container_immobile}>
                      <div className={styles.title_immobile}>
                        <p className={styles.immobile}>Imóvel Exemplo</p>
                      </div>
                      <div className={styles.config_immobile}>
                        <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                        <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                        <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
                      </div>
                      <div className={styles.container_price_slide}>
                        <p style={{
                            borderColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`
                        }}>R$4.000.000,00</p>
                      </div>
                      <div className={styles.view}>
                        <div className={styles.container_location}>
                          <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                          <p className={styles.location}> Localização</p>
                        </div>
                        <p className={styles.view_immobile}  style={{
                          backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                          color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                        }}>Ver imóvel</p> 
                    </div>
                  </div>
                </div> 
            </div>
            <div>
                <div>
                  <div className={styles.bg_second}></div>
                    <div className={styles.container_immobile}>
                      <div className={styles.title_immobile}>
                        <p className={styles.immobile}>Imóvel Exemplo</p>
                      </div>
                      <div className={styles.config_immobile}>
                        <span className={styles.container_icons}><Icon path={mdiBedKingOutline} size={.7} />3 suítes</span>
                        <span className={styles.container_icons}><Icon path={mdiCar} size={.7} /> 3 vagas</span>
                        <span className={styles.container_icons}><Icon path={mdiRulerSquare} size={.7} />152m</span>
                      </div>
                      <div className={styles.container_price_slide}>
                        <p style={{
                          borderColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`
                        }}>R$4.000.000,00</p>
                      </div>
                      <div className={styles.view}>
                        <div className={styles.container_location}>
                          <Icon path={mdiMapMarker} size={.7} className={styles.icon_maps}/>
                          <p className={styles.location}> Localização</p>
                        </div>
                        <p className={styles.view_immobile}  style={{
                          backgroundColor:`rgba(${colorButton?.r}, ${colorButton?.g}, ${colorButton?.b}, ${colorButton?.a})`,
                          color: `rgba(${colorTextButton?.r}, ${colorTextButton?.g}, ${colorTextButton?.b}, ${colorTextButton?.a})`
                        }}>Ver imóvel</p> 
                    </div>
                  </div>
                </div> 
            </div>
          </div>
        </div>
      </div>

      <div className={styles.fake_pages}>
        <span className={styles.arrow_page}>&#8592;</span>
        <span className={styles.page_now}>1</span>
        <span className={styles.page_next}>2</span>
        <span className={styles.arrow_page}>&#8594;</span>
      </div>


      <Modal
        size="lg"
        isOpen={modalLarge}
        toggle={() => {
          setModalLarge(!modalLarge);
        }}
      >
        <ModalHeader toggle={() => setModalLarge(!modalLarge)}>
            Escolha uma imagem para o banner        
        </ModalHeader>
        <ModalBody>
          <Dropzone
            onDrop={acceptedFiles => {
              setBg(acceptedFiles[0]);
              handleFileChange({ target: { files: acceptedFiles } });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                  </div>
                  <h4>Para adicionar uma imagem arraste ou clique aqui.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <p className={styles.title_options}>Ou escolha uma das imagens padrão:</p>
          <div className={styles.imageOptions}>
            {defaultImages.map((image, index) => (
              <label key={index} 
                onClick={() => {
                  setSelectedLabel(index); 
                  setModalLarge(false); 
                }} 
                className={selectedLabel === index ? styles.selected : ''}
              > 
                <img src={image.src} alt={image.name} className={styles.imagePreview} />
                <input type="radio" name="imageOption" value={image.src} onChange={(e) => {localStorage.setItem('imobiles_banner', e.target.value); setBg(e.target.value)}} />
              </label>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </section>
  )
}

export default Imobiles;