import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import Breadcrumbs from "../components/Common/Breadcrumb"
import About from './layout1/components/About';
import Footer from './layout1/components/Footer';
import Highlights from './layout1/components/Highlights';
import Home from './layout1/components/Home';
import Nav from './layout1/components/Nav';
import Imobiles from './layout1/components/Imobiles';
import Imobile from './layout1/components/Imobile'
import HighlightsSecond from './layout2/components/HighlightsSecond';
import AboutSecond from './layout2/components/AboutSecond';
import FooterSecond from './layout2/components/FooterSecond';
import ImobilesSecond from './layout2/components/ImobilesSecond';
import ImobileSecond from './layout2/components/ImobileSecond';
import SearchThird from './layout3/components/desktop/SearchThird';
import HighlightsThird from './layout3/components/desktop/HighlightsThird';
import AboutThird from './layout3/components/desktop/AboutThird';
import FooterThird from './layout3/components/desktop/FooterThird';
import ImobilesThird from './layout3/components/desktop/ImobilesThird';
import ImobileThird from './layout3/components/desktop/ImobileThird';
import Axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import './styles.scss';

import Layout1 from './../assets/images/layout1.jpeg';
import Layout2 from './../assets/images/layout2.jpeg';
import Layout3 from './../assets/images/layout3.jpeg';

// const FileType = require('file-type');

class Construtor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout1:'inactive',
      layout2:'inactive',
      layout3:'inactive',
      homeTitle: '',
      homeTitleColor:'',
      step:1,
      layoutSelected:null,
      background:'',
      colorTitle: "rgba(0, 194, 255, 0.78)",
      colorButton: "rgba(0, 194, 255, 0.78)",
			display_Title: false,
			display_Button: false,
      slug:'',
      user:[],
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Seu site FS", link: "#" },
    ],
      layoutIsValid: false,
      integration_error: false,
      layoutChecked: false,
      slugIsValid: false,
      siteIsPublished: false,
    }
    this.handleTitle = this.handleTitle.bind(this);
    this.handleButton = this.handleButton.bind(this);
  }

	handleTitle = () => {
		this.setState({ display_Title: !this.state.display_Title })
	};

  
  handleButton = () => {
		this.setState({ display_Button: !this.state.display_Button })
	};

  onSwatchHover_Title = (color) => {
		const format = "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")";
		this.setState({ colorTitle: format });
	}

  onSwatchHover_Button = (color) => {
		const format = "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")";
		this.setState({ colorButton: format });
	}

  getLayoutClass(layoutNumber) {
    return this.state.layoutSelected === layoutNumber ? 'active' : 'inactive';
  }  

  handleCheckButtonClick = () => {
    Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/verify-slug', { slug: this.state.slug }, {
      headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
    })
      .then(response => {
        this.setState({ slugIsValid: true });
      })
      .catch(error => {
        this.setState({ integration_error: true });
      });
  };
  
  disableInput = () => {
    this.setState({ inputDisabled: true });
  };

  handlePublishButtonClick = () => {
    document.getElementById('load').classList.add('active')
    
    if (this.state.layoutSelected) {
      const data = new FormData();
      data.append("layout", this.state.layoutSelected);
      data.append("slug", this.state.slug);
      data.append("user_id", localStorage.getItem('id'));
      data.append("type", localStorage.getItem('access'));
  
      if(localStorage.getItem('nav_logo')?.slice(0,4) !== 'http') {
        data.append('logo', this.convertImage(localStorage.getItem('nav_logo')));
      }

      if(localStorage.getItem('footer_logo')?.slice(0,4) !== 'http') {
        data.append('footer_logo', this.convertImage(localStorage.getItem('footer_logo')));
      }
      
      if(localStorage.getItem('home_banner')?.slice(0,4) !== 'http') {
        data.append('home_banner_image', this.convertImage(localStorage.getItem('home_banner')));
      }else{
        data.append('home_banner_image', localStorage.getItem('home_banner'));
      }

      if(localStorage.getItem('imobiles_banner')?.slice(0,4) !== 'http') {
        data.append('imobiles_logo_banner_image', this.convertImage(localStorage.getItem('imobiles_banner')));
      } else {
        data.append('imobiles_logo_banner_image', localStorage.getItem('imobiles_banner'));
      }

      if(localStorage.getItem('image_about')?.slice(0,4) !== 'http') {
        data.append('image_about', this.convertImage(localStorage.getItem('image_about')));
      } else {
        data.append('image_about', localStorage.getItem('image_about'));
      }
  
      data.append("home_title_text", localStorage.getItem("home_title")?.replace(/<div>|<\/div>|<br>/g, ''));
      data.append("home_title_color", localStorage.getItem("home_title_color"));
      data.append("home_button_background_color", localStorage.getItem("home_button_color"));
      data.append("home_button_color", localStorage.getItem("home_button_text_color"));
      data.append("home_title_section_text", localStorage.getItem("high_title")?.replace(/<div>|<\/div>|<br>/g, ''));
      data.append("home_button_section_background_color", localStorage.getItem("high_button_color"));
      data.append("home_button_section_color", localStorage.getItem("high_button_text_color"));
      data.append("home_title_about_text", localStorage.getItem("about_title")?.replace(/<div>|<\/div>|<br>/g, ''));
      data.append("home_text_footer_midia_color", localStorage.getItem("footer_color_text"));
      data.append("home_background_color_footer", localStorage.getItem("footer_color_bg"));
      data.append("home_link_instagram", localStorage.getItem("instagram"));
      data.append("home_link_youtube", localStorage.getItem("youtube"));
      data.append("home_link_facebook", localStorage.getItem("facebook"));
  
      data.append("imobiles_title_text", localStorage.getItem("imobiles_title")?.replace(/<div>|<\/div>|<br>/g, ''));
      data.append("imobiles_title_color", localStorage.getItem("imobiles_title_color"));
      data.append("imobiles_title_section_text", localStorage.getItem("high_imobiles_second_title")?.replace(/<div>|<\/div>|<br>/g, ''));
      data.append("imobiles_button_background_color", localStorage.getItem("high_button_color"));
      data.append("imobiles_button_color", localStorage.getItem("high_button_text_color"));
  
      data.append("imobile_button_text_color", localStorage.getItem("imobile_second_button_text_color"));
      data.append("imobile_button_background_color", localStorage.getItem("imobile_second_button_color"));
      data.append("imobile_title_section_text", localStorage.getItem("high_imobile_title"));

      if(localStorage.getItem("personal_page_config_id")) {
        data.append("personal_page_config_id", localStorage.getItem("personal_page_config_id"));
      }
      
      const url = process.env.REACT_APP_API + '/api/auth/personal-pages/update/' + localStorage.getItem('personal_page_id');

      Axios.post(url, data, {
        headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
      })
        .then(response => {
          console.log('DEU CERTO', response);
          this.setState({ integration_success: true, siteIsPublished: true });
          document.getElementById('load').classList.remove('active')
        })
        .catch(response => {
          console.log('DEU RUIM', response);
          document.getElementById('load').classList.remove('active')
        });
    } else {
      document.getElementById('load').classList.remove('active')
    }
  };

  convertImage = (image) => {
    if (image?.slice(0,4) !== 'http') {
      const comprimento = 10;
      // Caracteres possíveis para a string
      const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
      // Variável para armazenar a string gerada aleatoriamente
      let stringAleatoria = '';
  
      // Loop para gerar a string aleatória
      for (let i = 0; i < comprimento; i++) {
        stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
      }
  
      const fileAsBase64String = image;
      const decodedFile = atob(fileAsBase64String);
      const array = new Uint8Array(decodedFile.length);
      for (let i = 0; i < decodedFile.length; i++) {
        array[i] = decodedFile.charCodeAt(i);
      }
      const blob = new Blob([array], { type: 'image/png' });
      const file = new File([blob], stringAleatoria + '.png');
      return file;
    } else {
      return image;
    }
  }
  
  componentDidMount(){
    // document.getElementById('load').classList.add('active')
      if(localStorage.getItem('access') === 'C'){
        Axios.get(process.env.REACT_APP_API + '/api/auth/brokers/' + localStorage.getItem('id'),
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(resp => {
          this.setState({user : resp.data})
        })
      }
      if(localStorage.getItem('access') === 'I'){
        Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates/' + localStorage.getItem('id'),
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(resp => {
          this.setState({user : resp.data})
        })
      }

      const layout = localStorage.getItem('layout');
      if (layout) {
        this.setState({ layoutSelected: parseInt(layout, 10) });
      }
      const slug = localStorage.getItem('personal_page_slug');
      if (slug) {
        this.setState({ slug:slug });
      }
  }

  render() {
    return (
        <div className="page-content" id="pageConstructor">
        <Container fluid={true}>
          <Breadcrumbs title="Seu site FS" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody style={{padding: '1.25rem 0rem'}}>
                  <Row className="customRow" style={{paddingBottom:'20px', alignItems:'center', justifyContent:'space-between', padding: '0rem 1rem 1rem 1rem'}}>
                    <Col className="col-auto">
                      <h2 className="title_layout" style={{fontSize:'10px', fontWeight: 900, color: this.state.step === 1 ? '#3E5E3F' : '#ccc'}}>01.Escolha o layout</h2>
                    </Col>
                    <Col className="col-auto">
                      <h2 className="title_layout" style={{fontSize:'10px', fontWeight: 900, color: this.state.step === 2 ? '#3E5E3F' : '#ccc'}}>02.Configure sua página principal</h2>
                    </Col>
                    <Col className="col-auto">
                      <h2 className="title_layout" style={{fontSize:'10px', fontWeight: 900, color: this.state.step === 3 ? '#3E5E3F' : '#ccc'}}>03.Configure sua página de resultado</h2>
                    </Col>
                    <Col className="col-auto">
                      <h2 className="title_layout" style={{fontSize:'10px', fontWeight: 900, color: this.state.step === 4 ? '#3E5E3F' : '#ccc'}}>04.Configure sua página de imóvel único</h2>
                    </Col>
                    <Col className="col-auto">
                      <h2 className="title_layout" style={{fontSize:'10px', fontWeight: 900, color: this.state.step === 5 ? '#3E5E3F' : '#ccc'}}>05.Personalize a URL do seu site</h2>
                    </Col>

                    <Col className="col-auto">
                      <Row>
                        <Col xs={6}>
                          <Button onClick={() => {
                            if(this.state.step > 1) {
                              this.setState({step: this.state.step - 1})
                            }
                          }} disabled={this.state.step === 1}>Anterior</Button>
                        </Col>
                        <Col xs={6}>
                          <Button 
                            onClick={() => {
                              if(this.state.step < 5) {
                                this.setState({step: this.state.step + 1})
                              }
                            }}
                            disabled={!this.state.layoutSelected || this.state.step === 5}
                          >Próximo</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {this.state.step === 1 &&
                    <div className="containerLayoutsOptions" style={{maxWidth:'960px', margin:'auto'}}>
                      <div
                        onClick={() => {
                          this.setState({layout2: 'inactive', layout1: 'active', layout3: 'inactive', layoutSelected: 1})
                        }}
                        className={`layoutOption ${this.getLayoutClass(1)}`}
                        style={{cursor: 'pointer'}}
                      >
                        <div className="title">
                          <h3>Opção 1</h3>
                        </div>
                        <div className="body">
                          <img src={Layout1} alt='Exemplo layout 1' style={{height:'320px', width:'200px', objectFit:'cover', boxShadow:'0 0 5px rgba(0,0,0,0.3)', margin:'auto'}}></img>
                          {/* <a href="#">Pré visualizar</a> */}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          this.setState({layout2: 'inactive', layout1: 'active', layout3: 'inactive', layoutSelected: 2})
                        }}
                        className={`layoutOption ${this.getLayoutClass(2)}`}
                        style={{cursor: 'pointer'}}
                      >
                        <div className="title">
                          <h3>Opção 2</h3>
                        </div>
                        <div className="body">
                          <img src={Layout2} alt='Exemplo do layout 2' style={{height:'320px', width:'200px', objectFit:'cover', boxShadow:'0 0 5px rgba(0,0,0,0.3)', margin:'auto'}}></img>
                          {/* <a href="#">Pré visualizar</a> */}
                        </div>
                      </div>
                      <div 
                        onClick={() => {
                          this.setState({layout2: 'inactive', layout1: 'inactive', layout3: 'active', layoutSelected: 3})
                        }}
                        className={`layoutOption ${this.getLayoutClass(3)}`}
                        style={{cursor: 'pointer'}}
                      >
                        <div className="title">
                          <h3>Opção 3</h3>
                        </div>
                        <div className="body">
                          <img src={Layout3} alt='Exemplo do layout 3' style={{height:'320px', width:'200px', objectFit:'cover', boxShadow:'0 0 5px rgba(0,0,0,0.3)', margin:'auto'}}></img>
                          {/* <a href="#">Pré visualizar</a> */}
                        </div>
                      </div>
                    </div>
                  }

                  {this.state.step === 2 &&
                    this.state.layoutSelected === 1 &&
                    <>
                      <Container style={{position:'relative'}}>
                        <Row>
                          <Col className='customPadding' style={{boxShadow:'0 0 5px rgba(0,0,0,0.5', paddingTop:'5px', paddingBottom:'5px', borderRadius:'5px'}}>
                            <Nav data={this.state.user} />
                            <Home data={this.state.user} />
                            <Highlights data={this.state.user}/>
                            <About data={this.state.user}/>
                            <Footer data={this.state.user} />
                          </Col>
                        </Row>  
                      </Container>

                      <div className='container_buttons'>
                        <Button onClick={() => {
                            if(this.state.step === 2){
                              this.setState({step: 1})
                              window.scrollTo(0, 0)
                            }
                          }} 
                          disabled={this.state.step === 1}
                        >
                          Anterior
                        </Button>
                        <Button 
                          onClick={() => {
                            if(this.state.step === 2){
                              this.setState({step: 3})
                              window.scrollTo(0, 0)
                            }
                          }}
                        >
                          Próximo
                        </Button>
                      </div>
                    </>
                  }

                  {this.state.step === 3 &&
                    this.state.layoutSelected === 1 &&
                    <>
                      <Container style={{position:'relative'}}>
                        <Row>
                          <Col className='customPadding' style={{boxShadow:'0 0 5px rgba(0,0,0,0.5', paddingTop:'5px', paddingBottom:'5px', borderRadius:'5px'}}>
                            <Nav data={this.state.user} />
                            <Imobiles data={this.state.user} />
                            <Footer data={this.state.user} />
                          </Col>
                        </Row>  
                      </Container>

                      <div className='container_buttons'>
                        <Button onClick={() => {
                            if(this.state.step === 3){
                              this.setState({step: 2})
                              window.scrollTo(0, 0)
                            }
                          }} 
                          disabled={this.state.step === 1}
                        >
                          Anterior
                        </Button>
                        <Button 
                          onClick={() => {
                            if(this.state.step === 3){
                              this.setState({step: 4})
                              window.scrollTo(0, 0)
                            }
                          }}
                        >
                          Próximo
                        </Button>
                      </div>
                    </>
                  }

                  {this.state.step === 4 && 
                    this.state.layoutSelected === 1 &&
                    <>
                      <Container style={{position: 'relative'}}>
                        <Row>
                          <Col className='customPadding' style={{boxShadow:'0 0 5px rgba(0,0,0,0.5', paddingTop:'5px', paddingBottom:'5px', borderRadius:'5px'}}>
                            <Imobile data={this.state.user}/>
                            <Footer data={this.state.user} />
                          </Col>
                        </Row>  
                      </Container>
                      <div className='container_buttons'>
                        <Button onClick={() => {
                            if(this.state.step === 4){
                              this.setState({step: 3})
                              window.scrollTo(0, 0)
                            }
                          }} 
                        >
                          Anterior
                        </Button>
                        <Button 
                          onClick={() => {
                            if(this.state.step === 4) {
                              this.setState({step: 5})
                              window.scrollTo(0, 0)
                            }
                          }}
                          disabled={!this.state.layoutSelected || this.state.step === 5}
                        >
                          Próximo
                        </Button>
                      </div>
                    </>
                  }

                  {this.state.step === 2 &&
                    this.state.layoutSelected === 2 && 
                    <>
                      <Container style={{position: 'relative'}}>
                        <Row>
                          <Col className='customPadding' style={{boxShadow:'0 0 5px rgba(0,0,0,0.5', paddingTop:'5px', paddingBottom:'5px', borderRadius:'5px'}}>
                            <HighlightsSecond data={this.state.user} />
                            <AboutSecond data={this.state.user}/>
                            <FooterSecond data={this.state.user} />
                          </Col>
                        </Row>  
                      </Container>
                      <div className='container_buttons'>
                        <Button onClick={() => {
                            if(this.state.step === 2){
                              this.setState({step: 1})
                              window.scrollTo(0, 0)
                            }
                          }} 
                          disabled={this.state.step === 1}
                        >
                          Anterior
                        </Button>
                        <Button 
                          onClick={() => {
                            if(this.state.step === 2){
                              this.setState({step: 3})
                              window.scrollTo(0, 0)
                            }
                          }}
                        >
                          Próximo
                        </Button>
                      </div>
                    </>
                  }

                  {this.state.step === 3 &&
                    this.state.layoutSelected === 2 && 
                    <>
                      <Container style={{position: 'relative'}}>
                        <Row>
                          <Col className='customPadding' style={{boxShadow:'0 0 5px rgba(0,0,0,0.5', paddingTop:'5px', paddingBottom:'5px', borderRadius:'5px'}}>
                            <ImobilesSecond data={this.state.user} />
                            <FooterSecond data={this.state.user} />
                          </Col>
                        </Row>  
                      </Container>
                      <div className='container_buttons'>
                        <Button onClick={() => {
                            if(this.state.step === 3){
                              this.setState({step: 2})
                              window.scrollTo(0, 0)
                            }
                          }} 
                        >
                          Anterior
                        </Button>
                        <Button 
                          onClick={() => {
                            if(this.state.step === 3){
                              this.setState({step: 4})
                              window.scrollTo(0, 0)
                            }
                          }}
                        >
                          Próximo
                        </Button>
                      </div>
                    </>
                  }

                  {this.state.step === 4 &&
                    this.state.layoutSelected === 2 && 
                    <>
                      <Container style={{position: 'relative'}}>
                        <Row>
                          <Col className='customPadding' style={{boxShadow:'0 0 5px rgba(0,0,0,0.5', paddingTop:'5px', paddingBottom:'5px', borderRadius:'5px'}}>
                            <ImobileSecond data={this.state.user}/>
                            <FooterSecond data={this.state.user} />
                          </Col>
                        </Row>  
                      </Container>
                      <div className='container_buttons'>
                        <Button onClick={() => {
                            if(this.state.step === 4){
                              this.setState({step: 3})
                              window.scrollTo(0, 0)
                            }
                          }}
                        >
                          Anterior
                        </Button>
                        <Button 
                          onClick={() => {
                            if(this.state.step === 4) {
                              this.setState({step: 5})
                              window.scrollTo(0, 0)
                            }
                          }}
                          >
                          Próximo
                        </Button>
                      </div>
                    </>
                  }

                  {this.state.step === 2 &&
                    this.state.layoutSelected === 3 &&
                    <>
                      <Container style={{position:'relative'}}>
                        <Row>
                          <Col className='customPadding' style={{boxShadow:'0 0 5px rgba(0,0,0,0.5', paddingTop:'5px', paddingBottom:'5px', borderRadius:'5px'}}>
                            <SearchThird data={this.state.user} />
                            <HighlightsThird data={this.state.user} /> 
                            <AboutThird data={this.state.user}/>
                            <FooterThird data={this.state.user} />
                          </Col>
                        </Row>  
                      </Container>
                      <div className='container_buttons'>
                        <Button onClick={() => {
                          if(this.state.step === 2){
                            this.setState({step: 1})
                            window.scrollTo(0, 0)
                          }
                        }} disabled={this.state.step === 1}
                        >
                          Anterior
                        </Button>
                        <Button 
                          onClick={() => {
                            if(this.state.step === 2){
                              this.setState({step: 3})
                              window.scrollTo(0, 0)
                            }
                          }}
                        >
                          Próximo
                        </Button>
                      </div>
                    </>
                  }

                  {this.state.step === 3 &&
                    this.state.layoutSelected === 3 &&
                    <>
                      <Container style={{position:'relative'}}>
                        <Row>
                          <Col className='customPadding' style={{boxShadow:'0 0 5px rgba(0,0,0,0.5', paddingTop:'5px', paddingBottom:'5px', borderRadius:'5px'}}>
                            <ImobilesThird data={this.state.user} />
                            <Footer data={this.state.user} />
                          </Col>
                        </Row>  
                      </Container>
                      <div className='container_buttons'>
                        <Button onClick={() => {
                            if(this.state.step === 3){
                              this.setState({step: 2})
                              window.scrollTo(0, 0)
                            }
                          }}
                        >
                          Anterior
                        </Button>
                        <Button 
                          onClick={() => {
                            if(this.state.step === 3){
                              this.setState({step: 4})
                              window.scrollTo(0, 0)
                            }
                          }}
                        >
                          Próximo
                        </Button>
                      </div>
                    </>
                  }

                  {this.state.step === 4 &&
                    this.state.layoutSelected === 3 &&
                    <>
                      <Container style={{position:'relative'}}>
                        <Row>
                          <Col className='customPadding' style={{boxShadow:'0 0 5px rgba(0,0,0,0.5', paddingTop:'5px', paddingBottom:'5px', borderRadius:'5px'}}>
                            <ImobileThird data={this.state.user}/>
                            <Footer data={this.state.user} />
                          </Col>
                        </Row>  
                      </Container>
                      <div className='container_buttons'>
                        <Button onClick={() => {
                            if(this.state.step === 4){
                              this.setState({step: 3})
                              window.scrollTo(0, 0)
                            }
                          }}
                        >
                          Anterior
                        </Button>
                        <Button 
                          onClick={() => {
                            if(this.state.step === 4) {
                              this.setState({step: 5})
                              window.scrollTo(0, 0)
                            }
                          }}
                          disabled={!this.state.layoutSelected || this.state.step === 5}
                        >
                          Próximo
                        </Button>
                      </div>
                    </>
                  }

                  {this.state.step === 5 &&
                  <>
                    <Row>
                      <Col style={{marginTop:"15px", marginLeft: "15px"}}>
                        <Label>Complete o endereço:</Label>
                        <Row>
                          <Col className="col-auto"  style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <span>https://fs.fastsaleimoveis.com.br/</span>
                          </Col>
                          <Col className="col-auto">
                          <Input
                            onChange={(e) => this.setState({slug: e.target.value})}
                            value={this.state.slug}
                            placeholder="seu-site"
                            className="input_site"
                            style={{ border: "none", marginLeft: "-1.5rem", borderBottom: "solid 1px #cdcdcd" }}
                          />
                          </Col>
                          <Col >
                            <Button 
                              onClick={this.handleCheckButtonClick}
                              disabled={localStorage.getItem('personal_page_slug') === this.state.slug}
                            >
                              Verificar
                            </Button>
                          </Col>
                          <Row>
                            <div className="text_dominio_information">
                              <i className='ri-information-line'></i>
                              <p className='atention_link'>Caso possua um domínio registrado, você pode utilizá-lo, <b><a href="https://wa.me/554741081865" target="_blank" rel="noopener noreferrer">entre em contato</a></b> com nosso suporte para configurar.</p>
                            </div>
                          </Row>
                        </Row>
                        <Row>
                          { 
                            (this.state.slugIsValid || localStorage.getItem('personal_page_slug')) ? (
                              <>
                                <Row className="layout_valid">
                                  <Row>
                                    <div className="container_checked_layout">
                                      <i className='ri-check-line checked_layout'></i> <span className="aproved">Seu Site está pronto!</span>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Button
                                      className='button_public'
                                      onClick={this.handlePublishButtonClick}
                                      disabled={!localStorage.getItem('personal_page_slug') && !this.state.slugIsValid}
                                    >
                                      Publicar
                                    </Button>
                                    {this.state.integration_success ? (
                                      <SweetAlert
                                      success
                                      showConfirm={false}
                                      >
                                        <div>
                                          <p className='text_sweet_website'>Seu site tá ON!</p>
                                          <p className='text_sweet_website'>Parabéns, agora você tem um <br /> site para chamar de seu.</p>
                                          <div className='container_button_sweet'>
                                            <Button variant="light" onClick={() => this.setState({ integration_success: false })} >
                                              <a 
                                                className='link_website' 
                                                href={
                                                  (() => {
                                                    const url = `https://fs.fastsaleimoveis.com.br/${this.state.slug || localStorage.getItem('personal_page_slug')}`;
                                                    console.log("Gerando URL:", url);
                                                    return url;
                                                  })()
                                                }
                                                target="_blank" rel="noopener noreferrer"
                                              >
                                                Visitar site
                                              </a>
                                            </Button>
                                            <Button className='button_close_sweet' variant="light" onClick={() => this.setState({ integration_success: false })} >
                                              Fechar
                                            </Button>
                                          </div>
                                        </div>
                                      </SweetAlert>
                                    ) : null}
                                    {this.state.isPublishing && (
                                      <div id="load" className="load">
                                        <Spinner className="me-2" color="success" />
                                      </div>
                                    )}
                                  </Row>
                                </Row>
                              </>
                            ) : (
                              <p>Caminho já utilizado!</p>
                            )
                          }
                        </Row>
                      </Col>
                    </Row>
                    {
                      this.state.integration_error && (
                        <SweetAlert
                          showCloseButton={false}
                          showConfirm={true}
                          confirmBtnText="OK"
                          error
                          onConfirm={() => this.setState({ integration_error: false })}
                        >
                          <div>
                            <p className='fail_url'>Ops! Esta URL já está sendo usada!</p>
                          </div>
                        </SweetAlert>
                      )
                    }
                    <Row style={{marginTop:'30px', marginLeft: ".5rem", marginRight: ".5rem"}}>
                      <div className='atention'>
                        <i style={{color:"#FFFFFF", fontWeight: 900}} className="mdi mdi-alert-circle-outline me-3"></i>
                        <span className='atention_link' style={{color:"#FFFFFF", fontWeight: 900}}>Atenção</span>
                        <p>Lembre-se de adicionar imóveis em seu site, caso ainda não tenha feito, 
                          <a href="./seu-site-fs" target="_parent" style={{color:"#FFFFFF", fontWeight: 900}}> clique aqui</a>.
                        </p>
                        <p>Adicione imóveis a qualquer momento clicando no botão integrar na página do imóvel.</p>
                      </div>
                    </Row>
                  </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default Construtor;