import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, CardImg, Row, CardTitle, CardText, Badge } from "reactstrap";

import './../../assets/scss/custom/components/_horizontalCard.scss'
import './../../assets/scss/custom/components/_imovelCard.scss';

import Outlet from './../../assets/images/Icons/fogooutlet.png';

class HorizontalMiniCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }


    render() {
        return (
            <React.Fragment>
              <Link to={"/imovel/" + this.props.imovel?.slug} target="_blank">
                <span className="horizontalMiniCard">
                    <Row className="no-gutters align-items-center fix-margin">
                        <Col xs={3} className="fix-margin" style={{position:'relative'}}>
                          {this.props.imovel?.one_photo ?
                            <CardImg className="img-fluid img-horizontal-card" src={this.props.imovel?.one_photo?.medium_image} alt="Skote"/>
                            :
                            <CardImg className="img-fluid img-horizontal-card" src={this.props.imovel?.photos?.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1)[0]?.medium_image} alt="Skote" />
                          }
                          {this.props.imovel?.opportunity === 1 &&
                            <img className="outletIcon" src={Outlet}></img>
                          }
                        </Col>
                        <Col className="fix-margin" xs={9}>
                            <CardBody className="fix-margin" style={{padding:'0', justifyContent:'space-between', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                                <h3>{this.props.imovel?.sku} - {this.props.imovel?.title}</h3>
                            </CardBody>
                        </Col>
                    </Row>
                </span>
              </Link>
            </React.Fragment>
        );
    }
}

export default HorizontalMiniCard;