import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import Breadcrumbs from "../components/Common/Breadcrumb"
import Axios from 'axios';
import './styles.scss';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import SweetAlert from 'react-bootstrap-sweetalert';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoPremium from '../components/noPremium/noPremium';
import CatalogPropertiesResult from './AddProperties/CatalogPropertiesResultFS';

class SeuSiteFS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoritos: [],
      imoveis:[],
      allFavoritos: [],
      addPropertyId:'',
      deletePropertyId:null,
      verifyPremium:null,
      confirm_delete_all: false,
      user:[],
      slug:null,
      viewport: [
        {
          dataField: "mobile",
          text: "Imóveis"
        }
      ],
      search:'',
      confirm_both: false,
      success_dlg: false,
      error_dlg:false,
      dynamic_title: "",
      dynamic_description: "",
      delete_id:"",
      delete_sku:"",
      integration_error:false,
      integration_success:false,
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Seu site FS", link: "#" },
    ],
      page_id: 0,
      buttonText: "Editar layout",
      isLoadingImoveis: false,
    }
  }

  setLocalStorageVariables(config) {
    if(config?.logo) {
      localStorage.setItem('nav_logo', config?.logo);
    }

    if(config?.footer_logo) {
      localStorage.setItem('footer_logo', config?.footer_logo);
    }
    
    if(config?.home_banner_image) {
      localStorage.setItem('home_banner', config?.home_banner_image);
    }

    if(config?.imobiles_logo_banner_image) {
      localStorage.setItem('imobiles_banner', config?.imobiles_logo_banner_image);
    }

    if(config?.image_about) {
      localStorage.setItem('image_about', config?.image_about);
    }
  
    localStorage.setItem("home_title", config?.home_title_text ? config?.home_title_text : '');
    localStorage.setItem("home_title_color", config?.home_title_color ? config?.home_title_color : '');
    localStorage.setItem("home_button_color", config?.home_button_background_color ? config?.home_button_background_color : '');
    localStorage.setItem("home_button_text_color", config?.home_button_color ? config?.home_button_color : '');
    localStorage.setItem("high_title", config?.home_title_section_text ? config?.home_title_section_text : '');
    localStorage.setItem("high_button_color", config?.home_button_section_background_color ? config?.home_button_section_background_color : '');
    localStorage.setItem("high_button_text_color", config?.home_button_section_color ? config?.home_button_section_color : '');
    localStorage.setItem("about_title", config?.home_title_about_text ? config?.home_title_about_text : '');
    localStorage.setItem("footer_color_text", config?.home_text_footer_midia_color ? config?.home_text_footer_midia_color : '');
    localStorage.setItem("footer_color_bg", config?.home_background_color_footer ? config?.home_background_color_footer : '');
    localStorage.setItem("instagram", config?.home_link_instagram ? config?.home_link_instagram : '');
    localStorage.setItem("youtube", config?.home_link_youtube ? config?.home_link_youtube : '');
    localStorage.setItem("facebook", config?.home_link_facebook ? config?.home_link_facebook : '');
  
    localStorage.setItem("imobiles_title", config?.imobiles_title_text ? config?.imobiles_title_text : '');
    localStorage.setItem("imobiles_title_color", config?.imobiles_title_color ? config?.imobiles_title_color : '');
    localStorage.setItem("high_imobiles_second_title", config?.imobiles_title_section_text ? config?.imobiles_title_section_text : '');
    localStorage.setItem("imobiles_button_color", config?.imobiles_button_background_color ? config?.imobiles_button_background_color : '');
    localStorage.setItem("imobiles_button_text_color", config?.imobiles_button_color ? config?.imobiles_button_color : '');
  
    localStorage.setItem("imobile_second_button_text_color", config?.imobile_button_text_color ? config?.imobile_button_text_color : '');
    localStorage.setItem("imobile_second_button_color", config?.imobile_button_background_color ? config?.imobile_button_background_color : '');
    localStorage.setItem("high_imobile_title", config?.imobile_title_section_text ? config?.imobile_title_section_text : '');
  }

  adicionarImoveisSelecionados = (imoveis) => {
    document.getElementById('load').classList.add('active');
    const data = new FormData();
    
    imoveis.forEach(imovel => {
      data.append('properties[]', imovel)
    })
    
    data.append('personal_page_id', localStorage.getItem('your_page_id'));
    data.append('add_or_remove', 1);
    data.append('type', localStorage.getItem('access'));
    data.append('user_id', localStorage.getItem('id'));
    
    Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/add-property', data,
      { headers: { "Authorization": "Bearer " + localStorage.getItem('token') } })
      .then(response => {
        document.getElementById('load').classList.remove('active');
        this.setState({ integration_success: true });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(error => {
        document.getElementById('load').classList.remove('active');
        this.setState({ integration_error: true });
      });
  };

  deletarTodosOsImoveis = () => {
    this.setState({ confirm_delete_all: true });
  }

	componentDidMount(){
    this.setState({ isLoadingImoveis: true });
    
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {

        if(window.screen.width > 768){
          this.setState({viewport: [
            {
              dataField: "one_photo",
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => { 
                  setTimeout(() => {
                    window.open( 'imovel/' + row.slug , '_parent')
                  }, 300);
                },
              },
              text: "Foto"
            },
            {
              dataField: 'sku',
              text: 'Código'
            },
            {
              dataField: "title",
              text: "Nome"
            },
            {
              dataField: "sale_value",
              text: "Valor"
            },
            {
              dataField: "edit",
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => { 
                  setTimeout(() => {
                    window.open( 'imovel/' + row.slug , '_parent')
                  }, 300);
                },
              },
              text: "Ver"
            },
            {
              dataField: 'destaque',
              text:'Exibição'
            },
            {
              dataField: "delete",
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => { 
                  this.setState({ confirm_both: true })
                  this.setState({ delete_id: row.id })
                  this.setState({ delete_sku: row.sku })
                  this.setState({ deleted_channel: row.channel })
                  this.setState({ deletePropertyId: row.id })
                },
              },
              text: "Excluir"
            },
          ],
          })}

        Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/props-by-user-id', {
          type: localStorage.getItem('access'),
          user_id: localStorage.getItem('id')
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                this.setState({favoritos: response.data})
                this.setState({allFavoritos: response.data})
  
                document.getElementById('load').classList.remove('active')
            })
            .catch(response => {
              document.getElementById('load').classList.remove('active')
            })

            Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
              type:localStorage.getItem('access'),
              user_id:localStorage.getItem('id')
              }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              if(response.data.premium === 1) {
                this.setState({verifyPremium: true})
              } else {
                this.setState({verifyPremium: false})
              }
            })
            .catch(response => {
              this.setState({verifyPremium: false})
            })

      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
    
      Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/has-page', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id')
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          if (response.data.personal_page_config === null) {
            this.setState({ buttonText: "Criar layout" });
            localStorage.setItem('criar', 1);
            localStorage.setItem('personal_page_id', response.data.id);
          } else {
            this.setState({ page_id: response.data.id });
            localStorage.setItem("personal_page_config_id", response.data.personal_page_config.id);
            localStorage.setItem('layout', response.data.layout);
            this.setLocalStorageVariables(response.data.personal_page_config);
            localStorage.setItem('criar', 0);
            localStorage.setItem('personal_page_id', response.data.id);
            localStorage.setItem('personal_page_slug', response.data.slug);
            this.setState({slug: response.data.slug})
          }
        })
      
}

  render() {
    const {favoritos} = this.state;

    const options = {
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos imóveis', value: favoritos.length
        }]

    };


    const favoritosImoveisObject = []

    favoritos
    .filter(imovel => imovel.property_id && !isNaN(imovel.property_id))  
    .map(imovel => {
      const novoImovel = {
        id: imovel?.property_id,
        one_photo: <div style={{cursor: "pointer" ,backgroundImage:'url(' + imovel?.one_photo + ')', height:'60px', width: '60px', borderRadius:'5px', backgroundPosition:'center center', backgroundSize:'cover'}}></div>,
        edit: <i style={{cursor: "pointer"}} className="ri-eye-fill"></i>,
        delete: <i className="ri-delete-bin-6-line"></i>,
        sku: imovel?.sku,
        title: imovel?.title,
        channel: 1,
        destaque:<select  className="form-control" defaultValue={imovel?.destaque} onChange={(e) => {
          Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/update-destaque/' + imovel?.id, {
            destaque: e.target.value
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        }}>
          <option value={0}>Simples</option>
          <option value={1}>Destaque</option>
        </select>,
        created_at: moment(imovel?.created_at).format('DD/MM/YYYY'),
        sale_value: (imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2}),
        status: imovel?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>,
        slug: imovel?.slug,
        mobile:<Container style={{padding:'0'}}>
        <Row style={{marginTop:'15px'}}>
          <Col className="col-auto">
            <img src={imovel?.one_photo} alt='Imóvel' style={{
              objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
            }}></img>
          </Col>
          <Col>
          <p style={{lineHeight:'1.2'}}>{imovel?.sku}</p>
          <small>{imovel?.title}</small>
          <div style={{marginTop:'10px'}}><p style={{lineHeight:'1.2'}}>R$ {(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</p></div>
          </Col>
        </Row>
        <Row style={{marginTop:'15px', justifyContent:'flex-end'}}>
          <Col className="col-auto">
            <select  className="form-control" defaultValue={imovel?.destaque} onChange={(e) => {
                Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/update-destaque/' + imovel?.id, {
                  destaque: e.target.value
                }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              }}>
              <option value={0}>Simples</option>
              <option value={1}>Destaque</option>
            </select>
          </Col>
          <Col className="col-auto" style={{textAlign:'right'}}><i className="ri-eye-fill" onClick={() => {
              localStorage.setItem('edit_property_sku', imovel.sku)
                setTimeout(() => {
                  window.open("./../imovel/" + imovel?.slug , '_parent')
              }, 300);
            }}></i></Col>
          <Col className="col-auto" style={{textAlign:'right'}}><i className="ri-delete-bin-6-line" onClick={() => {
              Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/add-property', {
                properties: [imovel.id],
                personal_page_id:  parseInt(localStorage.getItem('your_page_id')),
                add_or_remove: 0
              },
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response =>{
                setTimeout(() => {
                  window.location.reload()
                }, 1000);
              })
          }}></i></Col>
        </Row>
      </Container>
      }
      return favoritosImoveisObject.push(novoImovel); 
    })

    return (
      <div className="page-content" id="pageConstructor">
          <Container fluid={true}>
            <Breadcrumbs title="Seu site FS" breadcrumbItems={this.state.breadcrumbItems} />
              {this.state.verifyPremium ?
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row style={{marginBottom:'20px'}}>
                          <Col className="col-auto" style={{display:'flex', flexDirection:'row', gap:'20px', alignItems:'center'}}>
                            <h5>Imóveis de seu site</h5><Button onClick={() =>{
                              setTimeout(() => {
                                window.open("https://fs.fastsaleimoveis.com.br/" + this.state.slug, "_blank")
                              }, 300);
                            }}>Ver site</Button>
                          </Col>
                          <Col style={{textAlign:'right'}}>

                            {favoritos.length > 0 ? (
                              <Button onClick={this.deletarTodosOsImoveis} className='button-delete-all'>
                                Excluir Todos
                              </Button> 
                              ) : <></>
                            }

                            <Button onClick={() => {
                              setTimeout(() => {
                                window.open('./construtor', '_parent')
                                }, 300);
                              }}>{this.state.buttonText}
                            </Button>
                          </Col>
                        </Row>

                        {favoritos.length < 1 ?
                          <p>Você ainda não adicionou nenhum imóvel sem seu site.</p>
                        :
                        <>
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField='id'
                              data={favoritosImoveisObject}
                              columns={this.state.viewport}
                              // expandRow={expandRow}
                              pagination={paginationFactory(options)}
                              // selectRow={false}
                            />
                          </div>
                        </>
                        }
                      </CardBody>
                    </Card>
                    <CatalogPropertiesResult adicionarImoveisSelecionados={this.adicionarImoveisSelecionados}/>
                  </Col>
                </Row>
                :
                <NoPremium/>
              }
          </Container>
          
          {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              {this.setState({
                confirm_both: false,
                success_dlg: true,
                dynamic_title: "Excluído",
                dynamic_description: "Seu imóvel foi excluído do seu site."
              })
                Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/add-property', {
                  properties: [this.state.deletePropertyId],
                  personal_page_id:  parseInt(localStorage.getItem('your_page_id')),
                  add_or_remove: 0
                },
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response =>{
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000);
                })
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "Seu imóvel ainda está no seu site."
              })
            }
          >
            <p className="alertText">Você tem certeza que deseja excluir: <b>{this.state.delete_sku}</b> do seu site?</p>
          </SweetAlert>
          ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => {
                this.setState({ success_dlg: false })
                setTimeout(() => {
                  window.location.reload()
                }, 300);
              }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

          {this.state.error_dlg ? (
            <SweetAlert
              error
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ error_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

           {/* Sucesso na integração */}

          {this.state.integration_success ? (
            <SweetAlert
              title="Imóvel adicionado ao seu site com sucesso"
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => this.setState({ integration_success: false })}
            ></SweetAlert>
          ) : null}

          {/* Falha na integração */}

          {this.state.integration_error ? (
            <SweetAlert
              title="O imóvel não pode ser adicionado em seu site, tente novamente."
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ integration_error: false })}
            ></SweetAlert>
          ) : null}

          {/* Deletar todos os imóveis */}

          {this.state.confirm_delete_all ? (
            <SweetAlert
              title="Atenção!"
              warning
              showCancel
              confirmBtnText="Tenho certeza"
              cancelBtnText="Cancelar"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                this.setState({ confirm_delete_all: false });
                document.getElementById('load').classList.add('active');
                const allPropertyIds = this.state.favoritos
                .filter(imovel => imovel.property_id && Number.isInteger(Number(imovel.property_id)))
                .map(imovel => imovel.property_id);
            
                Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages/add-property', {
                  properties: allPropertyIds,
                  personal_page_id:  parseInt(localStorage.getItem('your_page_id')),
                  add_or_remove: 0
                },
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response =>{
                  document.getElementById('load').classList.remove('active');
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000);
                })
              }}
              onCancel={() =>
                this.setState({
                  confirm_delete_all: false,
                })
              }
            >
              <p className="alertText">Tem certeza de que deseja excluir todos os imóveis? Esta ação não pode ser desfeita.</p>
            </SweetAlert>
          ) : null}

        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
    );
  }
}

export default SeuSiteFS;